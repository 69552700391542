import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Traveller} from '@tc-core/model/it/codegen/tbx/api/commons/traveller';
import {GuestDetailsHandlerService, SurfGuestDetailsUtil} from '@surf/surf-guest-details';
import {ApiPaxTypes} from '@surf/surf-components-core';
import {TC} from '@tc-core/util';
import DEFAULT_ADULT_AGE = TC.AGE_CONFIGS.DEFAULT_ADULT_AGE;
import DEFAULT_TEEN_AGE = TC.AGE_CONFIGS.DEFAULT_TEEN_AGE;
import DEFAULT_CHILD_AGE = TC.AGE_CONFIGS.DEFAULT_CHILD_AGE;
import DEFAULT_INFANT_MIN_MON_AGE = TC.AGE_CONFIGS.DEFAULT_INFANT_MIN_MON_AGE;

@Component({
  selector: 'surf-split-pax',
  templateUrl: './split-pax.component.html',
  styleUrls: ['./split-pax.component.css']
})
export class SurfSplitPaxComponent implements OnInit, OnChanges {
  @Input() travellers = [];
  @Input() title = '';
  @Input() defaultSelected = true;
  @Input() hasError = false;
  @Input() productType: string;
  @Input() paxSplitArray = [];
  @Input() paxSplitDisplayText;
  @Input() infantFair: boolean;
  @Input() chipsVisible: boolean;
  @Input() isDisabled: boolean;
  @Input() dynamicTravellerUpdate: boolean;
  @Input() disableWhenNotActive: boolean;
  @Input() emitWhenDisable = false;
  @Output() passengerSplitChangeEmit = new EventEmitter<any>();
  @Output() partyMixValidationValidationEmitter = new EventEmitter<{isError: boolean, errorMessage: string}>();

  visible: boolean;
  MAX_CHILD_AGE;

  constructor(
    public guestDetailsHandlerService: GuestDetailsHandlerService
  ) {
    this.MAX_CHILD_AGE = SurfGuestDetailsUtil.MAX_CHILD_AGE;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.travellers && this.dynamicTravellerUpdate) {
      if (!this.paxSplitArray || !(this.paxSplitArray && this.paxSplitArray.length > 0)) {
        this.setInitialPaxSplit(this.travellers);
      } else {
        this.updatePaxSplitArray(this.travellers);
      }
    }
  }

  ngOnInit() {
    this.setInitialPaxSplit(this.travellers);
    if (this.defaultSelected) {
      this.setPartyMixValidation(this.paxSplitArray, this.infantFair, this.productType);
    }
    if (!this.defaultSelected) {
      if (!this.paxSplitDisplayText) {
        this.paxSplitDisplayText = 'Select'
      }
    } else {
      this.paxSplitDisplayText = 'All Guests';
    }
  }

  /**
   * set initial passenger split array
   */
  setInitialPaxSplit(travellers: Traveller []){
    if (!this.paxSplitArray || !(this.paxSplitArray && this.paxSplitArray.length > 0)) {
      this.paxSplitArray = [];
    }

    if (this.paxSplitArray && this.paxSplitArray.length == 0) {
      travellers.forEach((traveller) => {
        const paxSplitObj = {code: '', name: '', paxType: '', age: 0, selected: false, apiTraveller:traveller, disabled: false};
        let name: string;
        if (traveller.profile.dummy || traveller.profile.firstName === 'TBA') {
          name = 'Guest ' + traveller.no;
        } else {
          name = traveller.profile.firstName + ' ' + traveller.profile.lastName;
        }
        switch (traveller.profile.type) {
          case 'A':
            paxSplitObj.code = 'A' + traveller.no;
            paxSplitObj.age = traveller.profile && traveller.profile.age ? traveller.profile && traveller.profile.age : DEFAULT_ADULT_AGE;
            paxSplitObj.name = name + ' (Adult)';
            paxSplitObj.paxType = ApiPaxTypes.A;
            if (this.defaultSelected) {
              paxSplitObj.selected = true;
            }
            if (this.infantFair) {
              paxSplitObj.disabled = true;
            }
            break;
          case 'C':
            if (traveller.profile.subType === 'T' || traveller.profile.age > this.MAX_CHILD_AGE) {
              paxSplitObj.code = 'T' + traveller.no;
              paxSplitObj.age = traveller.profile && traveller.profile.age ? traveller.profile && traveller.profile.age : DEFAULT_TEEN_AGE;
              paxSplitObj.name = name + ' (Teen)';
              paxSplitObj.paxType = ApiPaxTypes.T;
              if (this.defaultSelected) {
                paxSplitObj.selected = true;
              }
            } else {
              paxSplitObj.code = 'C' + traveller.no;
              paxSplitObj.age = traveller.profile && traveller.profile.age ? traveller.profile && traveller.profile.age : DEFAULT_CHILD_AGE;
              paxSplitObj.name = name + ' (Child)';
              paxSplitObj.paxType = ApiPaxTypes.C;
              if (this.defaultSelected) {
                paxSplitObj.selected = true;
              }
            }
            if (this.infantFair) {
              paxSplitObj.disabled = true;
            }
            break;
          case 'I':
            paxSplitObj.code = 'I' + traveller.no;
            paxSplitObj.age = traveller.profile && traveller.profile.age ?
              traveller.profile && traveller.profile.age : DEFAULT_INFANT_MIN_MON_AGE;
            paxSplitObj.name = name + ' (Infant)';
            paxSplitObj.paxType = ApiPaxTypes.I;
            if (this.defaultSelected) {
              paxSplitObj.selected = true;
            }
            break;
        }
        this.paxSplitArray.push(paxSplitObj);
      });
    }
  }

  /**
   * update split pax array when travellers update
   * @param travellers - updated travellers
   */
  updatePaxSplitArray(travellers: Traveller []) {
    if (this.paxSplitArray && this.paxSplitArray.length > 0) {
      const paxSplitArrayTemp: any[] = JSON.parse(JSON.stringify(this.paxSplitArray));
      if (paxSplitArrayTemp && this.disableWhenNotActive) {
        paxSplitArrayTemp.forEach(paxSplit => {
          paxSplit.disabled = true;
        });
      }
      this.paxSplitArray = [];
      travellers.forEach((traveller) => {
        const paxType = (traveller.profile.type === 'C') ?
          ((traveller.profile.subType === 'T'  || traveller.profile.age > this.MAX_CHILD_AGE) ? 'T' : 'C') : traveller.profile.type;
        const matchingPaxSplit = paxSplitArrayTemp.find(paxSplit => paxSplit.code === paxType + traveller.no);

        if (!matchingPaxSplit) {
          const paxSplitObj = {code: '', name: '', paxType: '', age: 0, selected: false, apiTraveller: traveller, disabled: false};
          let name: string;
          if (traveller.profile.dummy || traveller.profile.firstName === 'TBA') {
            name = 'Guest ' + traveller.no;
          } else {
            name = traveller.profile.firstName + ' ' + traveller.profile.lastName;
          }
          switch (traveller.profile.type) {
            case 'A':
              paxSplitObj.code = 'A' + traveller.no;
              paxSplitObj.age = traveller.profile && traveller.profile.age ?
                traveller.profile && traveller.profile.age :
                DEFAULT_ADULT_AGE;
              paxSplitObj.name = name + ' (Adult)';
              paxSplitObj.paxType = ApiPaxTypes.A;
              if (this.defaultSelected) {
                paxSplitObj.selected = true;
              }
              if (this.infantFair) {
                paxSplitObj.disabled = true;
              }
              break;
            case 'C':
              if (traveller.profile.subType === 'T'  || traveller.profile.age > this.MAX_CHILD_AGE) {
                paxSplitObj.code = 'T' + traveller.no;
                paxSplitObj.age = traveller.profile && traveller.profile.age ?
                  traveller.profile && traveller.profile.age :
                  DEFAULT_TEEN_AGE;
                paxSplitObj.name = name + ' (Teen)';
                paxSplitObj.paxType = ApiPaxTypes.T;
                if (this.defaultSelected) {
                  paxSplitObj.selected = true;
                }
              } else {
                paxSplitObj.code = 'C' + traveller.no;
                paxSplitObj.age = traveller.profile && traveller.profile.age ?
                  traveller.profile && traveller.profile.age :
                  DEFAULT_CHILD_AGE;
                paxSplitObj.name = name + ' (Child)';
                paxSplitObj.paxType = ApiPaxTypes.C;
                if (this.defaultSelected) {
                  paxSplitObj.selected = true;
                }
              }
              if (this.infantFair) {
                paxSplitObj.disabled = true;
              }
              break;
            case 'I':
              paxSplitObj.code = 'I' + traveller.no;
              paxSplitObj.age = traveller.profile && traveller.profile.age ?
                traveller.profile && traveller.profile.age : DEFAULT_INFANT_MIN_MON_AGE;
              paxSplitObj.name = name + ' (Infant)';
              paxSplitObj.paxType = ApiPaxTypes.I;
              if (this.defaultSelected) {
                paxSplitObj.selected = true;
              }
              break;
          }
          this.paxSplitArray.push(paxSplitObj);
        } else {
          if (this.infantFair && matchingPaxSplit.paxType !== ApiPaxTypes.I) {
            matchingPaxSplit.disabled = true;
          } else {
            matchingPaxSplit.disabled = false;
          }
          this.paxSplitArray.push(matchingPaxSplit);
        }
      });

      if (paxSplitArrayTemp && this.disableWhenNotActive) {
        paxSplitArrayTemp.forEach(paxSplitOld => {
          const matchingNewPaxSplit = this.paxSplitArray.find(paxSplitNew => paxSplitOld.code === paxSplitNew.code);
          if (!matchingNewPaxSplit) {
            this.paxSplitArray.push(paxSplitOld);
          }
        });
      }

      // sort paxSplitArray by traveller no
      this.paxSplitArray.sort((a, b) => a.apiTraveller.no - b.apiTraveller.no);
    }
  }

  passengerSplitChange(event) {
    this.passengerSplitChangeEmit.emit(event);
    this.setPartyMixValidation(event, this.infantFair, this.productType);
  }

  /**
   * to execute the passenger validations
   * @param: partyMix
   */
  setPartyMixValidation(partyMix: any [], infantFair: boolean, productType: string) {
    if (partyMix) {
      let returnValid = true;
      let errorMessage = '';

      // set the counts
      let a = 0;
      let t = 0;
      let c = 0;
      let i = 0;
      partyMix.forEach((obj) => {
        if (obj.selected) {
          switch (obj.paxType) {
            case ApiPaxTypes.A:
              a += 1;
              break;
            case ApiPaxTypes.T:
              t += 1;
              break;
            case ApiPaxTypes.C:
              c += 1;
              break;
            case ApiPaxTypes.I:
              i += 1;
              break;
          }
        }
      });

      // productType GEN
      if (productType === 'GEN') {
        // rule #01: if there's an infant, there should be an adult or a child at the least
        if (i > 0) {
          const isValid = a > 0 || t > 0 || c > 0;
          returnValid = returnValid && isValid;
          if (!isValid) {
            errorMessage = 'At least one adult/teen/child is mandatory with infant';
          }
        }

        // rule #02: if infantFair is true, at least one infant should be present
        /*if (infantFair) {
          const isValid = i > 0;
          returnValid = returnValid && isValid;
          if (!isValid) {
            errorMessage = 'At least one infant should be present';
          }
        }*/
      }

      if ( !returnValid ) {
        this.partyMixValidationValidationEmitter.emit({isError: !returnValid, errorMessage: !returnValid ? errorMessage : ''});
      }
    } else {
      // this.partyMixValidationValidationEmitter.emit({isError: false, errorMessage: ''});
    }
  }
}
