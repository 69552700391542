export class ModelStep {
  static isActive : string = "is-active";
  static notActive : string = "not-active";
  static isDone : string = "is-done";
}

export class StepHandler {
  grabPriceMethodComponent: string = "is-active";
  grabPNRComponent: string = "not-active";
}

export class CommonStringHandler {
  grabPriceMethodComponent: string;
  grabPNRComponent: string;
}
