<div [ngClass]="{'c-advance-search__content': !isDPSearch}">
  <ng-container *ngIf="!isServiceAddComponent; else elseblock">
  <div class="c-advance-search__content-top" *ngIf="!isDPSearch">
    <button class="a-btn a-btn--link c-pkg-search__back-btn" (click)="closeCriteriaBox()">
      <svg class="a-icon u-mr">
        <use xlink:href="#previous"></use>
      </svg>
    </button>
    <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Search activities for</h3>
    <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine your activity search</h3>
    <div class="c-advance-search__guests">
      <div class="m-form-item auto-pax-dropdown" (click)="scrollTo('#scroll')">
        <surf-pax-selection
          [(isFocused)]="guestComponentFocus"
          [paxSet]="defaultPaxSet"
          [product]="'FLT'"
          [inputWrapperView]="false"
          [maxAdult]="GEN_MAX_ADULT"
          [minAdult]="GEN_MIN_ADULT"
          [maxPaxCount]="GEN_MAX_PAX_COUNT"
          [maxChildren]="GEN_MAX_CHILD"
          [maxTeen]="GEN_MAX_TEEN"
          [maxInfants]="GEN_MAX_INFANTS"
          [maxInfantAge]="MAX_INFANT_AGE"
          [maxChildAge]="MAX_CHILD_AGE"
          [maxTeenAge]="MAX_TEEN_AGE"
          [parentScrollId]="windowScrollEnable ? 'scroll': null"
          [b2bView]="true"
          [roomViewSelected]="false"
          [infoDisabled]="true"
          [isTeenActive]="TEEN_PAX_ACTIVE"
          (paxSelection)="getPassengerSelection($event)"
          [tabIndex]="2"
          (currentOption)="getCurrentOption($event)">
        </surf-pax-selection>
      </div>
    </div>
    <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
      <svg class="a-icon o-modal__close-icon">
        <use xlink:href="#close"></use>
      </svg>
    </button>
  </div> <!--Content top : hidden in DP-->
  </ng-container>
  <ng-template #elseblock>
    <div class="c-advance-search__content-top">
      <button class="a-btn a-btn--link o-modal__inner-close" (click)="resetInputs()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
      <h3 class="t2 c-advance-search__title">Define your search for</h3>
      <!--    <span class="s5">Existing passenger combination will be applied to the newly adding component</span>-->
      <div class="c-advance-search__guests">
        <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
      </div>
    </div>
  </ng-template>
  <div class="c-advance-search__form" *ngIf="!isDPSearch">

    <div class="c-dp-search">
      <ng-container *ngIf="!isServiceAddComponent">
      <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}" [isDPSearch]="false"
                       [totalItemCount]="1" [isIntro]="false" [isRefine]="isRefine" [msg]="msg"
                       [disableDPSwitch]="DISABLE_DP_SWITCH"
                       [isDisabled]="DISABLE_DP_SWITCH"
                       (showBarPanelToggle)="moveToDP('pre')"></surf-dp-add-bar>
      </ng-container>
      <div class="c-dp-search__block c-dp-search__block--generic" [ngClass]="{'is-selected': (isSelected || !isDPSearch)}"
           (click)="selectComponent()" (clickOutside)="deselectComponent()">
        <!--  is-selected t-primary-->
        <div class="c-dp-search__action">
          <div class="c-dp-search__block-icon">
            <svg class="a-icon">
              <use xlink:href="#products--tickets"></use>
            </svg>
          </div>
        </div>

        <div class="c-dp-search__block-label">
          <svg class="a-icon u-rmr">
            <use xlink:href="#products--tickets"></use>
          </svg>
          <span class="lbl">Generic</span>
        </div>

        <div class="c-dp-search__form c-dp-search__form--generic">

          <div class="c-dp-search__form-item c-dp-search__form-item--location" id="{{windowScrollEnable ? 'scroll': ''}}">
            <div class="m-form-item auto-location-input" (click)="scrollTo('#scroll')">
              <surf-type-ahead
                (click)="showContent()"
                #location
                [id]="index"
                [label]="'Location*'"
                [dataList]="destinations"
                [filterType]="'strict'"
                [mappings]="['code', 'value']"
                [sortOrder]="'A2Z'"
                [autoFocus]="true"
                [focusInput]="initFocus"
                [selectedItem]="selectedDest"
                [paramName]="'ontotext'"
                [placeholder]="'Search city'"
                [bindFocusOut]="false"
                [selectAllOnEnter]="false"
                [isOnto]="true"
                [url]="locationUrl"
                [warningStyle]="'is-visited'"
                [warning]="locationWarning"
                [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                (onClickInside)="onClickInsideDestTypeAhead($event)"
                (onClickOutside)="onClickOutsideDestTypeAhead($event)"
                (returnedQuery)="returnedDestSearchQuery($event)"
                (returnedData)="returnDestination($event)"
                (keydown)="onKeyDown($event, 0)"
                [tabIndex]="1">
                <surf-type-ahead-content *ngIf="enableGenericRecommendation && enableRecommendLocations && showRecommendedContent && !activitySearchInitiated && !destSearchInitiated"
                                         [typeAheadData]="recommendingLocations"
                                         [typeAheadInfoText]="'Locations from this itinerary'"
                                         [selectedItem]="selectedDest"
                                         [available]="recommendingLocations?.length > 0"
                                         (setItem)="selectDestination($event)"
                >
                  <surf-type-ahead-item (click)="handleSuggestionSelect(item)" *ngFor="let item of recommendingLocations ;let i = index " >
                    <div class="c-search-selector__list-item">
                      <div class="c-dp-search__list-item auto-destinations" *ngIf="item?.itemType == 'CITY'" >
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.itemType" xlink:href="#map"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{item?.itemType == 'CITY' || item?.itemType === 40 ? item?.mainDetail : (item?.mainDetail | surfTextCase: 'title')}}</span>
                          <span class="s5 ts-muted ts-wet"><span *ngIf="true">{{item?.cityName + ', '}}</span>{{item?.countryName}}</span>
                        </div>
                        <span *ngIf="item?.itemType" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                      </div>
                    </div>

                  </surf-type-ahead-item>

                </surf-type-ahead-content>

                <surf-type-ahead-content
                  [typeAheadData]="filteredList"
                  [available]="filteredList?.length > 0"
                  [enableKeyStrokeIgnore]="true"
                  (setItem)="selectDestination($event)">

                  <ng-container *ngFor="let item of filteredList;let i = index">
                    <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 20">
                      <div class="c-dp-search__list-item search-ineligible auto-country-item">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.type != 'CITY' && item?.type != 40" xlink:href="#country"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                          <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                        </div>
                        <span *ngIf="item?.type != 'CITY' && item?.type != 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                      </div>

                    </surf-type-ahead-item>
                    <surf-type-ahead-item *ngIf="item?.type == 40">
                      <div class="c-dp-search__list-item auto-city-item" (click)="selectDestination(item)">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{item?.name}}</span>
                          <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                        </div>
                        <span *ngIf="item?.type == 'CITY' || item?.type == 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                      </div>
                    </surf-type-ahead-item>
                    <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                      <ng-template [ngIf]="item?.type == 20">  <!--then iterate its children (CITIES) as sub components-->
                        <div (click)="selectDestination( addParentToLocationGroup(child, item) )" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-cities" [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use xlink:href="#map"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                            <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{getCountry(item)}}</span>
                          </div>
                        </div>
                      </ng-template>
                    </surf-type-ahead-item>
                  </ng-container>
                </surf-type-ahead-content>
              </surf-type-ahead>
              <span *ngIf="destSearchInitiated && filteredList.length == 0" style="color: red;">No results found</span>
            </div>
          </div>

          <div class="c-dp-search__form-item auto-start-date">
            <surf-date-picker (click)="scrollTo('#scroll')"
                              [single_calendar]="true"
                              [inputLabel]="'Start Date*'"
                              [check_availability]="false"
                              [enablePreviousDates]="true"
                              [currentDateOfTimeZone]="calendarMinDate"
                              [reset]="resetCalendar"
                              [(isFocused)]="datePickerFocus"
                              [initial_dates]="initialDates"
                              [warningStyle]="'is-visited'"
                              [warning1]="dateWarning"
                              [hideErrorMsg]="hideErrorMsg"
                              [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : ''"
                              [isError]="validateDisabledDateSelection"
                              [validateDisabledDateSelection]="validateDisabledDateSelection"
                              (selected_dates)="getSelectedDate($event)"
            ></surf-date-picker>
          </div>
          <div class="c-dp-search__form-item c-dp-search__form-item--inverted">
            <div class="m-form-item" (click)="scrollTo('#scroll')">
              <div class="m-form-item__input auto-activity-type">
                <surf-ul-drop-down
                  [label]="'Activity Type'"
                  [items]="elementGroups"
                  [native]="true"
                  [select]=true
                  [warningStyle]="'is-visited'"
                  [warning]="typeWarning"
                  (singleObject)="selectElementGroup($event)">
                </surf-ul-drop-down>
              </div>
            </div>

          </div>

<!--          <div class="c-dp-search__form-item c-dp-search__form-item&#45;&#45;location">-->
<!--            <div class="m-form-item auto-activity-name-class" id="auto-activity-name" (click)="scrollTo('#scroll')">-->
<!--              <surf-type-ahead-->
<!--                [id]="'auto-activity-name'"-->
<!--                [label]="'Activity Name'"-->
<!--                [filterType]="'strict'"-->
<!--                [mappings]="['code', 'value']"-->
<!--                [sortOrder]="'A2Z'"-->
<!--                [autoFocus]="true"-->
<!--                [selectedItem]="selectedActivity"-->
<!--                [paramName]="'ontotext'"-->
<!--                [placeholder]="'Search or enter activity name'"-->
<!--                [bindFocusOut]="false"-->
<!--                [selectAllOnEnter]="false"-->
<!--                [isOnto]="true"-->
<!--                [url]="activityUrl"-->
<!--                [warningStyle]="'is-visited'"-->
<!--                [warning]="activityWarning"-->
<!--                [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"-->
<!--                (onClickInside)="onClickInsideActivityTypeAhead($event)"-->
<!--                (onClickOutside)="onClickOutsideActivityTypeAhead($event)"-->
<!--                (returnedQuery)="returnedActivitySearchQuery($event)"-->
<!--                (returnedData)="returnedActivity($event)"-->
<!--                [tabIndex]="3">-->

<!--                <surf-type-ahead-content-->
<!--                  [typeAheadData]="filteredActivityList"-->
<!--                  [available]="filteredActivityList?.length > 0"-->
<!--                  [enableKeyStrokeIgnore]="true"-->
<!--                  (setItem)="selectActivity($event)">-->

<!--                  <ng-container *ngFor="let item of filteredActivityList;let i = index">-->
<!--                    <surf-type-ahead-item>-->
<!--                      <div class="c-dp-search__list-item auto-city-item" (click)="selectActivity(item)">-->
<!--                        <svg class="a-icon c-dp-search__list-item-icon">-->
<!--                          <use xlink:href="#map"></use>-->
<!--                        </svg>-->
<!--                        <div class="m-info-set c-dp-search__list-item-main">-->
<!--                          <span class="s4 ts-strong">{{item?.name}}</span>-->
<!--                          <span class="s5 ts-muted ts-wet"><span *ngIf="item">{{item?.parent[0]?.name + ', '}}</span><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </surf-type-ahead-item>-->
<!--                  </ng-container>-->
<!--                </surf-type-ahead-content>-->
<!--              </surf-type-ahead>-->
<!--              <span *ngIf="activitySearchInitiated && filteredActivityList.length == 0" style="color: red;">No results found</span>-->
<!--            </div>-->
<!--          </div>-->


          <div class="c-dp-search__form-item c-dp-search__form-item--inverted">
            <div class="m-form-item" (click)="scrollTo('#scroll')">
              <div class="m-form-item__input">
                <surf-multi-field-input
                  [properties]="durationProperty"
                  [componentValue]="durationValues"
                  [title]="'Duration'"
                  [disabled]="!isDurationEnabledAll && isDurationDisabled"
                  [error]="durationError"
                  [msg]="'Invalid duration value'"
                  [warningStyle]="'is-visited'"
                  (emitCombinedOutEvent)="getDuration($event)">
                </surf-multi-field-input>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
          <svg class="a-icon">
            <use xlink:href="#close"></use>
          </svg>
        </div>


      </div>
      <ng-container *ngIf="!isServiceAddComponent">
      <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}" [totalItemCount]="1"
                       [isDPSearch]="false" [isIntro]="false" [isRefine]="isRefine" [msg]="msg"
                       [disableDPSwitch]="DISABLE_DP_SWITCH"
                       [isDisabled]="DISABLE_DP_SWITCH"
                       (showBarPanelToggle)="moveToDP('post')"></surf-dp-add-bar>
      </ng-container>
    </div>

  </div> <!--Generic criteria content : visible in Component flow-->

  <div class="c-dp-search__block c-dp-search__block--generic" [ngClass]="{'is-selected': (isSelected || !isDPSearch), '':index%2 == 1, 'valid-criteria': (validateInputs() && isDPSearch)}" *ngIf="isDPSearch"
       (click)="selectComponent()" (clickOutside)="deselectComponent()">
    <!--  is-selected t-primary-->
    <div class="c-dp-search__action">
      <label *ngIf="isAmendmentFlow && !isItemCloseable" class="a-checkbox c-dp-search__action-check">
        <input type="checkbox" class="a-checkbox__check" (change)="checkValue($event)" [checked]="!freeze">
        <span class="a-checkbox__label"></span>
      </label>
      <div class="c-dp-search__block-icon">
        <svg class="a-icon">
          <use xlink:href="#products--tickets"></use>
        </svg>
      </div>
    </div>

    <div class="c-dp-search__block-label">
      <svg class="a-icon u-rmr">
        <use xlink:href="#products--tickets"></use>
      </svg>
      <span class="lbl">Generic</span>
    </div>
    <div class="c-dp-search__form-wrap">
    <span class="a-loz a-loz--success c-dp-search__form-tag" *ngIf="isAmendmentFlow && isItemCloseable">Newly Added</span>
    <div class="c-dp-search__form c-dp-search__form--generic">
      <div class="c-dp-search__form-item c-dp-search__form-item--location" id="{{windowScrollEnable ? 'scroll': ''}}">
        <div class="m-form-item auto-location-input" (click)="scrollTo('#scroll')">
          <surf-type-ahead
            [id]="index"
            [label]="'Location*'"
            [dataList]="destinations"
            [filterType]="'strict'"
            [mappings]="['code', 'value']"
            [sortOrder]="'A2Z'"
            [selectedItem]="selectedDest"
            [paramName]="'ontotext'"
            [placeholder]="'Search city'"
            [bindFocusOut]="false"
            [selectAllOnEnter]="false"
            [isOnto]="true"
            [url]="locationUrl"
            [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
            [isDisabled]="freeze"
            [warningStyle]="'is-visited'"
            [warning]="locationWarning"
            (onClickInside)="onClickInsideDestTypeAhead($event)"
            (onClickOutside)="onClickOutsideDestTypeAhead($event)"
            (returnedQuery)="returnedDestSearchQuery($event)"
            (returnedData)="returnDestination($event)"
            (keydown)="onKeyDown($event, 0)"
            [tabIndex]="1">

              <surf-type-ahead-content
                [typeAheadData]="filteredList"
                [available]="filteredList?.length > 0"
                [enableKeyStrokeIgnore]="true"
                (setItem)="selectDestination($event)">

                <ng-container *ngFor="let item of filteredList;let i = index">
                  <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 20">
                    <div class="c-dp-search__list-item search-ineligible auto-country-item">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use *ngIf="item?.type != 'CITY' && item?.type != 40" xlink:href="#country"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main auto-destination-list">
                        <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                        <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                      </div>
                      <span *ngIf="item?.type != 'CITY' && item?.type != 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                    </div>

                  </surf-type-ahead-item>
                  <surf-type-ahead-item (click)="selectDestination(item)" *ngIf="item?.type == 40">
                    <div class="c-dp-search__list-item auto-city-item">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main auto-destination-list">
                        <span class="s4 ts-strong">{{item?.name}}</span>
                        <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                      </div>
                      <span *ngIf="item?.type == 'CITY' || item?.type == 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                    </div>

                  </surf-type-ahead-item>
                  <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                    <ng-template [ngIf]="item?.type == 20">  <!--then iterate its children (CITIES) as sub components-->
                      <div (click)="selectDestination( addParentToLocationGroup(child, item) )" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-cities" [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use xlink:href="#map"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                          <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{getCountry(item)}}</span>
                        </div>
                      </div>
                    </ng-template>
                  </surf-type-ahead-item>
                </ng-container>
              </surf-type-ahead-content>
            </surf-type-ahead>
            <span *ngIf="destSearchInitiated && filteredList.length == 0" style="color: red;">No results found</span>
          </div>
        </div>


      <div class="c-dp-search__form-item auto-start-date">
        <surf-date-picker (click)="scrollTo('#scroll')"
                          [single_calendar]="true"
                          [inputLabel]="'Date*'"
                          [check_availability]="false"
                          [enablePreviousDates]="true"
                          [currentDateOfTimeZone]="calendarMinDate"
                          [reset]="resetCalendar"
                          [initial_dates]="initialDates"
                          [disabled]="freeze"
                          [warningStyle]="'is-visited'"
                          [warning1]="dateWarning"
                          [hideErrorMsg]="hideErrorMsg"
                          [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : ''"
                          [isError]="validateDisabledDateSelection"
                          [validateDisabledDateSelection]="validateDisabledDateSelection"
                          (selected_dates)="getSelectedDate($event)"
                          [calendarStartDate]="dpCalEnableDate"
        ></surf-date-picker>
      </div>
      <div class="c-dp-search__form-item c-dp-search__form-item--inverted auto-activity-type">
        <surf-ul-drop-down [ngClass]="{'is-disabled': freeze}"
                           [label]="'Activity Type'"
                           [items]="(isDPSearch || isAmendmentFlow)? this.dpElementGroups[this.index] : elementGroups"
                           [native]="true"
                           [select]=true
                           [disabled]="freeze"
                           [warningStyle]="'is-visited'"
                           [warning]="typeWarning"
                           (singleObject)="selectElementGroup($event)">
        </surf-ul-drop-down>
      </div>
      <div class="c-dp-search__form-item c-dp-search__form-item--inverted">
        <div class="m-form-item" (click)="scrollTo('#scroll')">
          <div class="m-form-item__input">
            <surf-multi-field-input
              [properties]="durationProperty"
              [componentValue]="durationValues"
              [title]="isDurationDisabled ? 'Duration': 'Duration*'"
              [error]="durationError"
              [disabled]="!isDurationEnabledAll && isDurationDisabled"
              [msg]="'Invalid duration value'"
              [warningStyle]="'is-visited'"
              (emitCombinedOutEvent)="getDuration($event)">
            </surf-multi-field-input>
          </div>
        </div>
      </div>

      <div class="c-dp-search__form-item">
        <ng-container *ngIf="ENABLE_PAX_SPLIT && !freeze && isDPSearch && enablePaxSplit">
          <surf-multi-selector-drop-down [title]="'Split Passenger'"
                                         [dataList]="paxSplitArray"
                                         [inputDisplay]="paxSplitDisplayText"
                                         [isChipClosable]="true"
                                         [dropUP]="true"
                                         [inputType]="'Guest'"
                                         [isDisabled]="freeze"
                                         (selectionChange)="passengerSplitChange($event)"
                                         (emitClosedItem)="passengerSplitChange($event)"
          >
          </surf-multi-selector-drop-down>
        </ng-container>
      </div>
      <button class="a-link-btn a-link-btn--primary c-dp-search__add-link" (click)="enablePaxSplitDropdown()" *ngIf="ENABLE_PAX_SPLIT && isDPSearch && !freeze && !enablePaxSplit">
        Do you want to assign passengers?
        <span class="a-tool-tip a-tool-tip--top-left"
              [attr.data-tooltip]="'Will allow you to assign/split passengers into multiple components'">
              <svg class="a-icon u-rml">
              <use xlink:href="#info"></use>
            </svg>
            </span>
      </button>
    </div>

    </div>

    <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
      <svg class="a-icon">
        <use xlink:href="#close"></use>
      </svg>
    </div>


  </div>  <!--Generic criteria content : visible in DP flow-->

  <div class="c-advance-search__btn-wrapper" *ngIf="!isDPSearch">
    <div class="c-advance-search__search-info">
      <svg class="a-icon search-info--icon" *ngIf = "!isServiceAddComponent">
        <use xlink:href="#info"></use>
      </svg>
      <span *ngIf = "!isServiceAddComponent">Please select at least your <b>Location</b> and <b>Date </b> to continue search</span>
    </div>
    <div *ngIf="!isRefine" class="c-advance-search__search-action">
      <ng-container *ngIf="!isServiceAddComponent">
      <button (click)="resetInputs()" class="a-btn a-btn--default auto-btn-reset" [tabIndex]="4">Reset Search</button>
      </ng-container>
      <ng-container *ngIf="isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-btn-reset" [tabIndex]="4">Cancel</button>
      </ng-container>
      <button (click)="searchGenric(false)" class="a-btn a-btn--primary auto-btn-search" [tabIndex]="5"
              [ngClass]="{'is-disabled':!validateInputs()}">Search
      </button>
    </div>
    <div *ngIf="isRefine" class="c-advance-search__search-action">
      <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default auto-btn-cancel">Back to results</button>
      <button (click)="searchGenric(false)" class="a-btn a-btn--primary auto-btn-refine" [ngClass]="{'is-disabled':!validateInputs()}">Refine
        Search
      </button>
    </div>
  </div> <!--Button wrapper : hidden in DP-->

</div>
