import {
  AfterViewInit, ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {
  CALENDAR_VIEW,
  ConfigService,
  DataShareService,
  DestinationType,
  KeyCodes,
  OntologyResponse,
  PackageCriteria, SurfCalendarStartDateService,
  SurfCriteriaUtil, SurfFlightResultsService,
  SurfScrollDirective,
  SurfTextCasePipe, SurfTravellerEarliestDepartureDateService
} from '@surf/surf-components-core';
import {
  MultiFieldInputData,
  MultiFieldInputProperty,
  MultiFieldInputPropertyFactory
} from '@surf/surf-multi-field-input';
import { PaxAllocationItem } from '@surf/surf-pax-selection';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { DataServiceHandler } from '@tc-core/service/service-handlers';
import { TC } from '@tc-core/util';
import { ConfigLoader, DataKey, DataStore } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { Subscription } from 'rxjs';
import { EPPriceFilterType } from '../package-criteria.model';
import { TravellerInterest, ExternalCode, SOURCE_REFERENCE, TravellerInterestsService } from '@surf/surf-traveller-interests';
import {MasterDataStoreService, StoreConstants} from '@surf/surf-state-manage';
import {CabinClass} from '@tc-core/model/it/codegen/tbx/api/criteria/flight/cabin-class';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'surf-package-criteria',
  templateUrl: 'package-criteria.component.html'
})
export class PackageCriteriaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  constructor(private dataServiceHandler: DataServiceHandler,
              private commonService: DataShareService,
              private dataStore: DataStore,
              private configService: ConfigService,
              private  cd: ChangeDetectorRef,
              private renderer: Renderer2,
              public surfCriteriaUtil: SurfCriteriaUtil,
              private commonHelper: CommonHelper,
              private configLoader: ConfigLoader,
              private travellerInterestsService: TravellerInterestsService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService,
              protected masterDataStoreService: MasterDataStoreService,
              private surfFlightResultsService: SurfFlightResultsService,
              private route: ActivatedRoute,
              private elementRef:ElementRef) {
  }

  // set refine search
  @Input() isRefine = false;
  @Input() selectedPax: any;
  @Input() duration: number;
  // set window scrollable
  @Input() windowScrollEnable = true;
  // emit cancel refine event
  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Input() setRoomWise = false;
  @Input() paxTypeAges = [];
// enable add EP to existing cart/booking
  @Input() isServiceAddComponent = false;
  @Input() cartPaxSelection: any;
  @Input() triggerExternalSearch = '';
  @Input() selectedTravellerInterestsKeys: string[];
  @Input() cachingItemMap = new Map<string, number>();
  @Input() allowNgrxCaching = false;
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter();
  currentCabinClass = 'Pick your cabin';

  readonly EPPriceFilterType = EPPriceFilterType;
  roomAllocationVisible = false;
  subscription = new Subscription();
  FIELD_SEPERATOR = '~';
  AGE_SEPERATOR = '__';
  LESS_THAN_ONE = '< 1';
  MAX_PACKAGE_DURATION = 10;
  /*input values for pax selection component. Those values should be set internally once service is finalized*/
  MAX_CABIN_PAX_COUNT = 5;
  MAX_CHILD = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_TEEN = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_INFANTS = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_ROOMS = 9;
  MIN_ADULT = 1;
  MAX_ADULT = 10;
  MAX_INFANT_AGE = 23;
  MAX_CHILD_AGE = 12;
  MAX_TEEN_AGE = 17;
  TEEN_PAX_ACTIVE = true;
  MAX_PAX_COUNT = 40;
  DEFAULT_ADULT_AGE = 30;
  DEFAULT_OFFSET = 10;
  INITIAL_DURATION = 3;
  MIN_SEARCH_QUERY_LENGTH = 3;
  CALENDAR_RANGE = 2;
  DURATION_FIRST_DISPLAY = '--ANY--';
  DEFAULT_DURATION_VALUE = -1;
  EP_HOLIDAY_TYPES_COUNT = 10;
  DURATION_OFFSET = '0';
  MIN_MAX_PRICE_REGEX = /^[0-9]+$/;
  calendarMinDateDes: Date = new Date();
  calendarMinDateDep: Date = new Date();
  currentCabinDisplay = '';
  currentDepartureAirport = '';
  currentOccasion = '';
  calendarType;
  destinations = [];
  departureAirports;
  departureAirportsList: Array<{ code: string, value: string}>;
  filteredList = [];
  filteredAirportList = [];
  moduleConfigs: any;
  selectedDest: OntologyResponse = new OntologyResponse();
  selectedAirport: OntologyResponse = new OntologyResponse();
  destSearchInitiated = false;
  airportSearchInitiated = false;
  packageCriteria: PackageCriteria;
  keyParams: string;
  defaultPaxSet: Array<PaxAllocationItem> = [];
  currentPaxOption = 0;
  multiFieldProperties: MultiFieldInputProperty[] = [];
  multiFieldValues = [];
  durationError = false;
  resetCalendar = false;
  initialDates = [];
  packageSearchDays = [];
  daysFirstDisplay;
  monthCalendarLabel = 'Departure Month/Date*';
  monthCalendarPlaceholder = 'DD/MM/YYYY';
  calendarView = CALENDAR_VIEW.SINGLE_DATE_MONTH_VIEW;
  calendarRange = 3;
  cabinClassList: Array<{ code: string, value: string }>;
  occasions;
  occasionList: Array<{ code: string, value: string}>;
  holidayTypes = [];
  tempSelectedHolidayTypes = [];
  tempUnselectedHolidayTypes = [];
  selectedHolidayTypes = [];
  roomWiseSelected = false;
  defaultActiveCalendar =  CALENDAR_VIEW.SINGLE_MONTH_VIEW;
  focusInit = false;
  focusToDepartureAirport = false;
  focusToDepartureMonth = false;
  focusToDuration = false;
  subscriptions = new Subscription();
  user: User = null;
  readonly GDS_REF = 'AMD';
  experienceTypesList: any = [];
  readonly DEFAULT_EXPERIENCE_TYPE = 'None';
  durationProperty: MultiFieldInputProperty[];
  DURATION_CATEGORIES = {code: 'N', value: 'Nights', selected: true};
  durationValues = [];
  errorInPackageCode = false;
  isDateNotSelected = false;
  STATIC_DATA_URL_BASE = '/data/data-service/v2/static-data';
  STATIC_DATA_FETCH_SIZE = 20;
  departureUrl = '';
  airportUrl = '';
  CABIN_CLASS_URL = '/data/data-service/v2/cabin-classes';
  addChildToBodyIdMap = new Map<string, boolean>();
  minPriceDisplayValue: string;
  maxPriceDisplayValue: string;
  minPriceError = false;
  maxPriceError = false;
  minMaxPriceError = false;
  minMaxPriceErrorMsg = '';
  minMaxToolTipText = 'This is the minimum / maximum package\nprice for total number of guests';

  showLoadMoreHolidayTypes = false;
  paxDisplay: string;
  setTimeoutValue = 200;

  REC_ENABLE_FOR_EP_SEARCH = true;
  enableTravellerInterestsModal = false;
  selectedTravellerInterests: TravellerInterest[] = [];
  packageType="COMH"
  disableItemArray=[]

  getOccasionList;

  ngOnInit() {
    this.loadConfigs();
    if (!this.paxTypeAges || this.paxTypeAges.length === 0) {
      this.loadPaxTypeAges();
    } else {
      this.mapPaxSubTypeAges();
    }
    this.dataServiceHandler.getCabinClasses().subscribe((data) => {
      if (data) {
        this.cabinClassList = data.map(m => ( {
          code : m.code,
          value : m.name
        }));
        if (this.isRefine){
          if (this.packageCriteria && this.packageCriteria.flightOptions) {
            const cabinCode = this.packageCriteria.flightOptions.split(':')[1];
            const cabinClass = this.cabinClassList.find(c => c.code === cabinCode);
            this.currentCabinDisplay = cabinClass.value;
          }
        }

      }
    });
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    this.createExperienceList();
    if (this.isRefine) {
      this.route.queryParams.subscribe((params) => {
        this.packageType=params.pkgType
      })
      this.subscription.add(this.commonService.getBaseCriteria().subscribe(cri => {
        const surfTextCasePipe = new SurfTextCasePipe(this.configService);
        this.packageCriteria = cri as PackageCriteria;
        this.selectedDest.displayText = '';
        this.selectedAirport.displayText = '';
        if (this.packageCriteria && this.packageCriteria.destinationName
          && !this.packageCriteria.destinationName.includes('undefined') && this.packageCriteria.destinationParentCode
          && !this.packageCriteria.destinationParentCode.includes('undefined')) {
          this.selectedDest.displayText = this.packageCriteria.destinationName.trim() + ', ' + this.packageCriteria.destinationParentCode;
        } else {
          this.selectedDest.displayText = '';
        }
        if (this.packageCriteria && this.packageCriteria.departureAirportName
          && !this.packageCriteria.departureAirportName.includes('undefined') && this.packageCriteria.departureAirportCode
          && !this.packageCriteria.departureAirportCode.includes('undefined')) {
          this.selectedAirport.displayText = surfTextCasePipe.transform(this.packageCriteria.departureAirportName, 'title').trim();
          this.selectedAirport.displayText = this.selectedAirport.displayText + ' (' + this.packageCriteria.departureAirportCode + ')';
        } else {
          this.selectedAirport.displayText = '';
        }

        if (this.packageCriteria && this.packageCriteria.departureAirportCode) {
            const selectedAirport = this.departureAirportsList.find(airport => airport.code === this.packageCriteria.departureAirportCode);
            if (selectedAirport) {
              this.currentDepartureAirport = selectedAirport.value;
            } else {
              this.currentDepartureAirport = 'Airport not found';
            }
          }


        if (this.packageCriteria && this.packageCriteria.attributes) {
          const occasionCode = this.packageCriteria.attributes.split('~')[1];
          const occasion = this.occasionList.find(o => o.code === occasionCode);
          this.currentOccasion = occasion.value;
        }


        if (this.packageCriteria.maxPrice > -1) {
          this.maxPriceDisplayValue =this.packageCriteria.maxPrice ? this.packageCriteria.maxPrice.toString() :"";
        }
        if (this.packageCriteria.minPrice > -1) {
          this.minPriceDisplayValue = this.packageCriteria.minPrice ? this.packageCriteria.minPrice.toString() : "";
        }
      }));
    } else {
      if (!this.packageCriteria) {
        this.packageCriteria = new PackageCriteria();
      }
      this.setMultiFieldInputProperties();
    }

    this.subscription.add(this.commonService.getKeyParamString().subscribe(keyParams => {
      this.keyParams = keyParams;
    }));
    this.selectedDest.displayText = '';
    this.selectedAirport.displayText = '';

    // get holidayTypes
    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_WS_HOLIDAY_TYPES) !== null && this.cachingItemMap.get(StoreConstants.CACHE_WS_HOLIDAY_TYPES) > 0)) {
      this.getHolidayTypesFromCache(this.cachingItemMap.get(StoreConstants.CACHE_WS_HOLIDAY_TYPES));
    } else {
      this.subscription.add(this.dataServiceHandler.getWSHolidayTypes('all').subscribe(
        res => {
          if (res && res.length > 0) {
            this.loadHolidayTypes(res);
          }
        },
        error => {
          console.log('Error in getting HolidayTypes');
        }));
    }

    // set multi field properties
    this.multiFieldProperties.push(MultiFieldInputPropertyFactory.getInputProperties(true, 'Any nights', true));
    this.multiFieldValues.push(new MultiFieldInputData('', ''));

    this.multiFieldProperties.push(MultiFieldInputPropertyFactory.getDropdownProperties(false, '', true));
    this.multiFieldValues.push([
      new MultiFieldInputData('Days', 'Days', true),
      new MultiFieldInputData('Nights', 'Nights')
    ]);

    if (this.isRefine ) {
      this.setRefineSearchAttr();
      this.setMultiFieldInputProperties();
    }

    this.loadPackageSearchDayLst();
    this.departureUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=CITY~COUNTRY&userInput=[UI]&aggrLevel=NA&locale=en&matchType=CODE~NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;
    // this.airportUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=AIRPORT&userInput=[UI]&aggrLevel=CITY&locale=en&matchType=ALL&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;
  }



  ngOnChanges(changes: SimpleChanges): void {

    if (changes.setRoomWise) {
      this.roomWiseSelected = changes.setRoomWise.currentValue;
    }

    if (changes.selectedPax) {
      this.selectedPax = changes.selectedPax.currentValue;
      if (this.isRefine) {
        this.defaultPaxSet = this.selectedPax;
      }
      if (this.isServiceAddComponent) {
        const adults = this.selectedPax.reduce((acc, pax) => acc + pax.adult, 0);
        const child = this.selectedPax.reduce((acc, pax) => acc + pax.child.length, 0);
        const teen = this.selectedPax.reduce((acc, pax) => acc + pax.teen.length, 0);
        const infant = this.selectedPax.reduce((acc, pax) => acc + pax.infant.length, 0);
        this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      }
    }
    if (changes.cartPaxSelection && this.cartPaxSelection) {
      const adults = this.cartPaxSelection.reduce((acc, pax) => acc + pax.adult, 0);
      const child = this.cartPaxSelection.reduce((acc, pax) => acc + pax.child.length, 0);
      const teen = this.cartPaxSelection.reduce((acc, pax) => acc + pax.teen.length, 0);
      const infant = this.cartPaxSelection.reduce((acc, pax) => acc + pax.infant.length, 0);
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getGuestSelection(this.cartPaxSelection);
    }

    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === 'PKG') {
      this.searchHoliday(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }
  }

  ngAfterViewInit() {
    this.focusInit = true;
  }

  public ngOnDestroy(): void {
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }
  }


  private getHolidayTypesFromCache(timeout: number) {
    this.masterDataStoreService.getWSHolidayTypes(timeout).subscribe(
      res => {
        if (res && res.length > 0) {
          this.loadHolidayTypes(res);
        }
      },
      error => {
        console.log('Error in getting holiday types');
      });
  }
  loadPaxTypeAges() {
    this.subscriptions.add(
      this.dataServiceHandler.getPaxSubTypeAges().subscribe(
        res => {
          if (res == null || !(Array.isArray(res)) || res.length === 0) {
            console.log('Could not fetch Pax Type Ages');
            return;
          }

          this.paxTypeAges = res[0].paxTypes;
          this.mapPaxSubTypeAges();
        },
        error => {
          console.log('Error in getting pax sub type ages');
        }
      ));
  }
  private mapPaxSubTypeAges() {
    this.MAX_CHILD_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'C').age.upper / 12);
    this.MAX_TEEN_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'T').age.upper / 12);
    this.MAX_INFANT_AGE = this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
  }
  loadHolidayTypes(types) {
    if (this.REC_ENABLE_FOR_EP_SEARCH) {
      types.forEach(
        (item: any) => {
          const extCode = new ExternalCode();
          extCode.ref = SOURCE_REFERENCE.TBX;
          extCode.code = item.code;
          extCode.name = item.name;
          this.holidayTypes.push(extCode);
        }
      );
    } else {
      if (this.isRefine) {
        this.setSelectedHolidayTypes(this.packageCriteria.holidayType);
      }
      console.log('[ selected holiday types] ', this.selectedHolidayTypes);
      types.forEach(
        (item: any) => {
          if (this.selectedHolidayTypes.indexOf(item.code) > -1) {
            this.holidayTypes.push({code: item.code, name: item.name, description: item?.detail?.description, selected: true});
          } else {
            this.holidayTypes.push({code: item.code, name: item.name, description: item?.detail?.description, selected: false});
          }
        }
      );
      this.sortHolidayTypes();
    }
  }

  /**
   * package search date list
   */
  private loadPackageSearchDayLst() {

    this.daysFirstDisplay = this.DURATION_FIRST_DISPLAY;
    this.packageSearchDays.push({code : this.DEFAULT_DURATION_VALUE , value : this.DURATION_FIRST_DISPLAY });
    for (let i = 1 ; i <= this.MAX_PACKAGE_DURATION ; i++) {
      const selected = i == this.duration;
      this.packageSearchDays.push({code : i, value : i, selected});
    }
  }


  /**
   * set refine search attributes
   */
  private setRefineSearchAttr() {

    if (this.packageCriteria.startDate) {
      this.initialDates = [this.convertStrToDateObj(this.packageCriteria.startDate)];
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_DATE_VIEW;
    } else {
      const month = this.packageCriteria.startMonth;
      const year = this.packageCriteria.startYear;
      this.initialDates = [this.createDummyDateUsingMonthAndYear(year, month)];
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_MONTH_VIEW;
    }

    this.defaultPaxSet =  this.selectedPax;
    this.daysFirstDisplay = this.packageCriteria.nights ? this.packageCriteria.nights : this.DURATION_FIRST_DISPLAY;
  }

// load module configurations
  loadConfigs() {
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();
    this.occasions = this.moduleConfigs.find(m => m.code === 'SURF_B2B_DEFAULT_SPECIAL_OCCASIONS' )?.name;
    this.occasionList = this.occasions.split(',').map(pair => {
      const [code, value] = pair.split(':');
      return { code, value};
    });
    this.departureAirports =  this.moduleConfigs.find(m => m.code === 'SURF_B2B_DEFAULT_AIR_PORTS')?.name;
    this.departureAirportsList = this.departureAirports.split(',').map(pair => {
      const [code, value] = pair.split(':');
      return { code, value};
    });
    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'MIN_SEARCH_QUERY_LENGTH':
            this.MIN_SEARCH_QUERY_LENGTH = mc.name;
            break;
          case 'MAX_ROOMS':
            this.MAX_ROOMS = mc.name;
            break;
          case 'MIN_ADULT':
            this.MIN_ADULT = mc.name;
            break;
          case 'MAX_ADULT':
            this.MAX_ADULT = mc.name;
            break;
          case 'MAX_PAX_COUNT':
            this.MAX_PAX_COUNT = mc.name;
            break;
          case 'MAX_CABIN_PAX_COUNT':
            this.MAX_CABIN_PAX_COUNT = mc.name;
            break;
          case 'DEFAULT_ADULT_AGE':
            this.DEFAULT_ADULT_AGE = mc.name;
            break;
          case 'INITIAL_DURATION':
            this.INITIAL_DURATION = mc.name;
            break;
          case 'EP_DURATION_OFFSET':
            this.DURATION_OFFSET = mc.name;
            break;
          case 'DEFAULT_CALENDAR_OFFSET':
            this.DEFAULT_OFFSET = mc.name;
            break;
          case 'CALENDAR_RANGE':
            this.CALENDAR_RANGE = mc.name;
            break;
          case 'MAX_PACKAGE_DURATION':
            this.MAX_PACKAGE_DURATION = mc.name;
            break;
          case 'EP_HOLIDAY_TYPES_COUNT':
            this.EP_HOLIDAY_TYPES_COUNT = mc.name;
            break;
          case 'TEEN_PAX_ACTIVE':
            this.TEEN_PAX_ACTIVE = ((mc.name as string) === 'true');
            break;
          case 'DISABLED_PRODUCT_FLOWS':
            this.disableItemArray = mc.name.toString().split(',');
            break;
        }
      });
    }

    this.REC_ENABLE_FOR_EP_SEARCH = this.commonHelper.convertToBoolean(
      this.configLoader.getModuleConfig('REC_ENABLE_FOR_EP_SEARCH', TC.MODULE_NAME.SURF_B2B)
    );
  }


  // remove non-alphanumeric characters
  updateValue(list) {
    if (list && list.length > 0) {
      list.forEach(d => {
        d.value = d.name.toLowerCase().replace(/[^a-zA-Z0-9]/g, ' ');
      });
    }
  }

  // package criteria close
    closeCriteriaBox() {
    this.renderer.removeClass(document.body, 'u-unscrollable');
    this.removeModals('holiday-types-id-modal');
    this.removeModals('traveller-interests-modal');
    this.commonService.changeMessage('PKG_close');
    if (this.isRefine) {
      this.cancelRefineSearch();
    }
  }

  /**
   * change display value on click inside type ahead
   * @event event value
   * @selection DEST or AIR
   */
  onClickInsideTypeAhead(event, selection) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    if (selection === 'DEST') {
      if (this.packageCriteria.destinationType === DestinationType.CITY && this.packageCriteria.destinationCode) {
        this.selectedDest.displayText = this.packageCriteria.destinationName.trim();
      }
    } else if (selection === 'AIR') {
      if (this.packageCriteria.departureAirportCode) {
        this.selectedAirport.displayText = surfTextCasePipe.transform(this.packageCriteria.departureAirportName, 'title').trim();
      }
    }
  }

  /**
   * change display value on click outside type ahead
   * @event event value
   * @selection DEST or AIR
   */
  onClickOutsideTypeAhead(event, selection) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    if (selection === 'DEST') {
      if (this.packageCriteria.destinationType === DestinationType.CITY && this.packageCriteria.destinationCode) {
        this.selectedDest.displayText = this.packageCriteria.destinationName.trim() + ', ' + this.packageCriteria.destinationParentCode;
      }
      if (this.isRefine && this.packageCriteria.destinationType === DestinationType.COUNTRY) {
        this.selectedDest.displayText = surfTextCasePipe.transform(this.packageCriteria.destinationName, 'title').trim();
      }
    } else if (selection === 'AIR') {
      if (this.packageCriteria.departureAirportCode) {
        const airport = this.departureAirports.filter(air => air.code === this.packageCriteria.departureAirportCode);
        if (this.packageCriteria.departureAirportName && !this.packageCriteria.departureAirportName.includes('undefined')
        && this.packageCriteria.departureAirportCode && !this.packageCriteria.departureAirportCode.includes('undefined')) {
          this.selectedAirport.displayText = surfTextCasePipe.transform(this.packageCriteria.departureAirportName, 'title').trim();
          this.selectedAirport.displayText = this.selectedAirport.displayText + ' (' + this.packageCriteria.departureAirportCode + ')';
        } else {
          this.selectedAirport.displayText = '';
        }
      }
    }
  }

  /**
   * initiate destination and airport search
   * @event event value
   * @selection DEST or AIR
   */
  returnedSearchQuery(event, selection) {
    if (selection === 'DEST') {
      this.destSearchInitiated = event && event.length >= 3;
    } else if (selection === 'AIR') {
      this.airportSearchInitiated = event && event.length >= 3;
    }
  }

  /**
   * return search results
   * @data result data
   * @selection DEST or AIR
   */
  returnData(data, selection) {
    if (selection === 'DEST') {
      if (data && data.length > 0) {
        this.filteredList = data;
      } else {
        this.filteredList = [];
        if (this.packageCriteria) {
          this.packageCriteria.destinationCode = null;
          this.packageCriteria.destinationName = null;
          this.packageCriteria.destinationParentCode = null;
        }
      }
    } else if (selection === 'AIR') {
      if (data && data.length > 0) {
        this.filteredAirportList = data;
      } else {
        this.filteredAirportList = [];
        if (this.packageCriteria) {
          this.packageCriteria.departureAirportName = null;
          this.packageCriteria.departureAirportCode = null;
          this.packageCriteria.departureAirportParentCityCode = null;
          this.packageCriteria.departureAirportParentCountryCode = null;
        }
      }
    }
  }

  /**
   * apply selected values in destination and airport search
   * @data selected data
   * @selection DEST or AIR
   */
  // selectDestination(data, selection) {
  //   const surfTextCasePipe = new SurfTextCasePipe(this.configService);
  //   if (this.packageCriteria?.startDate ? false : ((this.packageCriteria?.startYear && this.packageCriteria?.startMonth) ? false : true)) {
  //     setTimeout(() => {
  //       this.focusToDepartureMonth = true;
  //       this.focusToDepartureAirport = false;
  //       this.focusToDuration = false;
  //     }, this.setTimeoutValue);
  //   }
  //   if (selection === 'DEST') {
  //     this.filteredList = [];
  //     this.calendarMinDateDes = new Date();
  //     for (let i = 0; i < data.attributes.length ; i++) {
  //       if (data.attributes[i].name === 'current_time') {
  //         this.calendarMinDateDes = new Date(data.attributes[i].value);
  //       }
  //     }
  //     this.surfCalendarStartDateService.updateMinDate(this.calendarMinDateDes, 'DP');
  //     if (data.type === 'CITY' || data.type === 40) {
  //       this.selectedDest.displayText = data.name + ', ' + this.getParent(data, 20).name;
  //       this.packageCriteria.destinationType = DestinationType.CITY;
  //       this.packageCriteria.destinationParentCode = this.getParent(data, 20).name;
  //     } else {
  //       this.selectedDest.displayText = data.name;
  //       this.packageCriteria.destinationType = DestinationType.COUNTRY;
  //     }
  //     this.selectedDest.displayText = data.type === 'CITY' || data.type === 40 ? this.selectedDest.displayText.trim() : surfTextCasePipe.transform(this.selectedDest.displayText, 'title').trim();
  //     this.destSearchInitiated = false;
  //     this.packageCriteria.destinationCode = data.code;
  //     this.packageCriteria.destinationName = data.name;
  //     this.commonService.updateBaseCriteria(this.packageCriteria);
  //   } else if (selection === 'AIR') {
  //     this.calendarMinDateDep = new Date();
  //     this.surfCalendarStartDateService.updateMinDate(this.calendarMinDateDep, 'DP');
  //
  //     if(data.type == 60){
  //       for (let i = 0; i < data.attributes.length ; i++) {
  //         if (data.attributes[i].name === 'current_time') {
  //           this.calendarMinDateDep = new Date(data.attributes[i].value);
  //           this.surfCalendarStartDateService.updateMinDate(this.calendarMinDateDep, 'DP');
  //         }
  //       }
  //     }else if(data.type == 40){
  //       let date: Date;
  //       let firstDate = true;
  //       for (let i = 0; i < data.children.length; i++) {
  //         for (let j = 0; j < data.children[i].attributes.length ; j++) {
  //           if (data.children[i].attributes[j].name === 'current_time') {
  //             date = new Date(data.children[i].attributes[j].value);
  //             if (firstDate) {
  //               this.calendarMinDateDep = new Date(date);
  //               this.surfCalendarStartDateService.updateMinDate(this.calendarMinDateDep, 'DP');
  //               firstDate = false;
  //             }
  //             if (date < this.calendarMinDateDep) {
  //               this.calendarMinDateDep = new Date(date);
  //               this.surfCalendarStartDateService.updateMinDate(this.calendarMinDateDep, 'DP');
  //             }
  //           }
  //         }
  //       }
  //     }
  //     this.filteredAirportList = [];
  //     // this.selectedAirport.displayText = surfTextCasePipe.transform(data.name, 'title').trim();
  //     this.selectedAirport.displayText = surfTextCasePipe.transform(data.name, 'title').trim();
  //     this.selectedAirport.displayText = this.selectedAirport.displayText + ' (' + data.code + ')';
  //     this.airportSearchInitiated = false;
  //     this.packageCriteria.departureAirportName = data.name;
  //     this.packageCriteria.departureAirportCode = data.code;
  //     this.packageCriteria.departureAirportParentCityCode = this.getParent(data, 40).code;
  //     this.packageCriteria.departureAirportParentCountryCode = this.getParent(data, 20).code;
  //     this.commonService.updateBaseCriteria(this.packageCriteria);
  //   }
  // }


  selectDestination(data, selection) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    if (this.packageCriteria?.startDate ? false : ((this.packageCriteria?.startYear && this.packageCriteria?.startMonth) ? false : true)) {
      setTimeout(() => {
        this.focusToDepartureMonth = true;
        this.focusToDepartureAirport = false;
        this.focusToDuration = false;
      }, this.setTimeoutValue);
    }
    if (selection === 'DEST') {
      this.filteredList = [];
      this.calendarMinDateDes = new Date();
      this.surfCalendarStartDateService.updateMinDate(this.calendarMinDateDes, 'DP');
      if (data.type === 'CITY' || data.type === 40) {
        this.selectedDest.displayText = data.name + ', ' + this.getParent(data, 20).name;
        this.packageCriteria.destinationType = DestinationType.CITY;
        this.packageCriteria.destinationParentCode = this.getParent(data, 20).name;
      } else {
        this.selectedDest.displayText = data.name;
        this.packageCriteria.destinationType = DestinationType.COUNTRY;
      }
      this.selectedDest.displayText = data.type === 'CITY' || data.type === 40 ? this.selectedDest.displayText.trim() : surfTextCasePipe.transform(this.selectedDest.displayText, 'title').trim();
      this.destSearchInitiated = false;
      this.packageCriteria.destinationCode = data.code;
      this.packageCriteria.destinationName = data.name;
      this.commonService.updateBaseCriteria(this.packageCriteria);
    } else if (selection === 'AIR') {
      this.calendarMinDateDep = new Date();
      this.surfCalendarStartDateService.updateMinDate(this.calendarMinDateDep, 'DP');

      this.filteredAirportList = [];
      this.selectedAirport.displayText = surfTextCasePipe.transform(data.name, 'title').trim() + ' (' + data.code + ')';
      this.airportSearchInitiated = false;
      this.packageCriteria.departureAirportName = data.name;
      this.packageCriteria.departureAirportCode = data.code;
      this.packageCriteria.departureAirportParentCityCode = this.getParent(data, 40).code;
      this.packageCriteria.departureAirportParentCountryCode = this.getParent(data, 20).code;
      this.commonService.updateBaseCriteria(this.packageCriteria);
    }
  }

  /**
   * get minimum calendar enable date from destination
   * current date and departure current date
   */
  getCalendarMinDate() {
    let resultData = new Date();
    if (this.selectedDest.displayText == '' && this.selectedAirport.displayText == '') {
      resultData = new Date();
    } else if (this.selectedDest.displayText == '') {
      resultData = this.calendarMinDateDep;
    } else if (this.selectedAirport.displayText == '') {
      resultData = this.calendarMinDateDes;
    } else {
      if (this.calendarMinDateDes < this.calendarMinDateDep) {
        resultData = this.calendarMinDateDes;
      }
      resultData = this.calendarMinDateDep;
    }
    this.surfCalendarStartDateService.updateMinDate(resultData, 'EP');
    return resultData;
  }
  /**
   * get parent country or city of given city or airport
   * @cityOrAirport city or Airport
   * @parentType COUNTRY or CITY
   */
  getParent(cityOrAirport, parentType): { code: string; name: string; } {
    const parent = {
      code: '',
      name: ''
    };
    if (cityOrAirport.parent && cityOrAirport.parent.length > 0) {
      cityOrAirport.parent.forEach(p => {
        if (p.type === parentType) {
          parent.name = p.name;
          parent.code = p.code;
        }
      });
    }
    return parent;
  }


  /**
   * scroll to given selector
   * @selector
   */
  scrollTo(selector) {
    if (this.windowScrollEnable) {
      const element = this.elementRef.nativeElement.querySelector(`${selector}`);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', duration: 300} as ScrollIntoViewOptions);
      }
    }
  }

  /**
   * get selected guest details
   * @$event
   */
  getGuestSelection($event: Array<PaxAllocationItem>) {
    if ($event != null && this.validateChildAges($event) && this.validateTeenAges($event)) {
      if (!this.packageCriteria) {
        this.packageCriteria = new PackageCriteria();
      }
      this.packageCriteria.adult = this.processAdultPaxAllocation($event);

      const children = this.processChildAllocation($event);
      const teens = this.processTeenAllocation($event);
      const infant = this.processInfantAllocation($event);
      if (children.CHD && children.CHD.length > 0) {
        this.packageCriteria.child = children.CHD;
      } else {
        this.packageCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.packageCriteria.teen = teens;
      } else {
        this.packageCriteria.teen = null;
      }
      this.packageCriteria.infant =  infant && infant.length > 0 ? infant : null;

      this.commonService.updateBaseCriteria(this.packageCriteria);
    } else {
      this.packageCriteria.adult = null;
      this.packageCriteria.child = null;
      this.packageCriteria.teen = null;
      this.packageCriteria.infant = null;
      this.commonService.updateBaseCriteria(this.packageCriteria);
    }
  }

  /**
   * validate child ages in a given pax
   * @selectedPax
   */
  validateChildAges(selectedPax: Array<PaxAllocationItem>) {
    return selectedPax.reduce((acc, pax) => acc && (pax.child.indexOf(-1) > -1 ? false : true), true);
  }
  /**
   * validate teen ages in a given pax
   * @selectedPax
   */
  validateTeenAges(selectedPax: Array<PaxAllocationItem>) {
    return selectedPax.reduce((acc, pax) => acc && (pax.teen.indexOf(-1) > -1 ? false : true), true);
  }
  /**
   * process adult pax value to create URL params
   * @paxArray
   */
  processAdultPaxAllocation(paxArray: Array<PaxAllocationItem>) {

    const defaultAdultAge = this.DEFAULT_ADULT_AGE;
    const fieldSeparator = '~';
    const roomSeparator = '__';

    let paxStr = '';

    for (let i = 0; i < paxArray.length; i++) {
      const paxItem = paxArray[i];
      if (i !== 0) {
        if (this.roomWiseSelected) {
          paxStr = paxStr + roomSeparator;
        }
      }
      paxStr = paxStr + paxItem.adult + fieldSeparator + defaultAdultAge + fieldSeparator + 'DOB';
      if (this.roomWiseSelected) {
        paxStr = paxStr + fieldSeparator + (i + 1);
      }
    }

    return paxStr;
  }
  /**
   * process teen pax value to create URL params
   * @paxArray
   */
  processTeenAllocation(paxArray: Array<PaxAllocationItem>) {

    let teen = '';

    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.teen.length > 0) {

        const teenAgeMap = {};
        paxRoom.teen.forEach(teenAge => {
          teenAgeMap[teenAge] ? teenAgeMap[teenAge]++ : teenAgeMap[teenAge] = 1;
        });

        for (const key in teenAgeMap) {
          const count = teenAgeMap[key] + '';
          teen += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
          if (this.roomWiseSelected) {
            teen += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          } else {
            teen += this.AGE_SEPERATOR;
          }
        }
      }
      roomNo++;
    });

    if (teen.length > 2) {
      if (this.roomWiseSelected) {
        teen = teen.slice(0, teen.length - 2);
      } else {
        teen = teen.slice(0, teen.length - 2);
      }
    }

    return teen;
  }
  /**
   * process child pax value to create URL params
   * @paxArray
   */
  processChildAllocation(paxArray: Array<PaxAllocationItem>) {

    const children = {
      INF: '',
      CHD: '',
    };

    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.child.length > 0) {

        const childAgeMap = {};
        paxRoom.child.forEach(childAge => {
          childAgeMap[childAge] ? childAgeMap[childAge]++ : childAgeMap[childAge] = 1;
        });

        for (const key in childAgeMap) {
          const count = childAgeMap[key] + '';
          if (key.trim() === this.LESS_THAN_ONE) {
            /*Infants*/
            children.INF += count + this.FIELD_SEPERATOR + 0 + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          } else {
            /*child*/
            children.CHD += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
            if (this.roomWiseSelected) {
              children.CHD += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
            } else {
              children.CHD += this.AGE_SEPERATOR;
            }
          }
        }
      }
      roomNo++;
    });

    if (children.INF.length > 2) {
      if (this.roomWiseSelected) {
        children.INF = children.INF.slice(0, children.INF.length - 2);
      } else {
        children.INF = children.INF.slice(0, children.INF.length - 2);
      }
    }
    if (children.CHD.length > 2) {
      if (this.roomWiseSelected) {
        children.CHD = children.CHD.slice(0, children.CHD.length - 2);
      } else {
        children.CHD = children.CHD.slice(0, children.CHD.length - 2);
      }
    }

    return children;
  }

  processInfantAllocation(paxArray: Array<PaxAllocationItem>) {

    let roomNo = 1;
    let inf = '';
    paxArray.forEach(paxRoom => {
      if (paxRoom.infant.length > 0) {

        const infantAgeMap = {};
        paxRoom.infant.forEach(childAge => {
          infantAgeMap[childAge] ? infantAgeMap[childAge]++ : infantAgeMap[childAge] = 1;
        });

        for (const key in infantAgeMap) {
          const count = infantAgeMap[key] + '';
          inf += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
          if (this.roomWiseSelected) {
            inf += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          } else {
            inf += this.AGE_SEPERATOR;
          }
        }
      }
      roomNo++;
    });

    if (inf.length > 2) {
      inf = inf.slice(0, inf.length - 2);
    }
    return inf;
  }


  /**
   * get selected months from calendar month
   * @$event
   */
  getMonthSelection($event) {
    if ($event !== null && $event[0] != '') {
      this.isDateNotSelected = false;
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_MONTH_VIEW;
      this.packageCriteria.startMonth = this.getMonthStr($event[0]);
      this.packageCriteria.startYear = this.getYearStr($event[0]);
      this.packageCriteria.startDate = null;
      this.commonService.updateBaseCriteria(this.packageCriteria);
    }
  }

  /**
   * get selected dates from calendar
   * @param $event
   */
  getDateSelection($event) {
    if ($event) {
      this.isDateNotSelected = false;
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_DATE_VIEW;
      this.packageCriteria.startDate = this.convertDateObjToStr($event[0]);
      this.packageCriteria.startMonth = null;
      this.packageCriteria.startYear = null;
      this.commonService.updateBaseCriteria(this.packageCriteria);
    } else {
      this.isDateNotSelected = true;
    }
  }
  /**
   * get duration value
   * @$event
   */
  getDuration($event) {
    if ($event !== null) {
      if (this.validateDuration($event[0].value)) {
        this.durationError = false;
        if ($event[1][0].selected) {
          this.packageCriteria.nights = 0;
          this.packageCriteria.days = $event[0].value;
          this.commonService.updateBaseCriteria(this.packageCriteria);
        } else if ($event[1][1].selected) {
          this.packageCriteria.days = 0;
          this.packageCriteria.nights = $event[0].value;
          this.commonService.updateBaseCriteria(this.packageCriteria);
        }
      } else {
        this.durationError = true;
        this.multiFieldProperties[0].error = this.durationError;
      }
    }
  }

  /**
   *
   */
  getDurationEx($event) {
    if ($event != null) {
      if (this.validateDuration($event[0].value)) {
        this.durationError = false;
        if ($event[0].value && $event[0].value !== '') {
          this.packageCriteria.nights = parseInt($event[0].value, 10);
        } else {
          this.packageCriteria.nights = 0;
        }
        this.commonService.updateBaseCriteria(this.packageCriteria);
      } else {
        this.packageCriteria.nights = 0;
        this.commonService.updateBaseCriteria(this.packageCriteria);
      }
    }
  }


  /**
   * validate duration input value
   * @duration
   */
  validateDuration(duration: string) {
    if (duration !== null) {
      const value = duration.toString().replace(/[^0-9]*/g, '');
      if (value === duration) {
        if (parseInt(duration, 10) > 0) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  /**
   * reset selected values
   * @isRefine?
   */
  resetInputs(isRefine?: boolean) {
    if (!isRefine) {
      this.packageCriteria.destinationType = null;
      this.packageCriteria.destinationCode = null;
      this.packageCriteria.destinationName = null;
      this.packageCriteria.destinationParentCode = null;
      this.packageCriteria.departureAirportCode = null;
      this.packageCriteria.departureAirportName = null;
      this.packageCriteria.departureAirportParentCityCode = null;
      this.packageCriteria.departureAirportParentCountryCode = null;
      this.packageCriteria.adult = '';
      this.packageCriteria.child = '';
      this.packageCriteria.teen = '';
      this.packageCriteria.startDate = null;
      this.packageCriteria.startMonth = null;
      this.packageCriteria.startYear = null;
      this.packageCriteria.days = 0;
      this.packageCriteria.nights = 0;
      this.packageCriteria.pkgCode = '';
      this.packageCriteria.minPrice = 0;
      this.packageCriteria.maxPrice = 0;
      this.setMultiFieldInputProperties();
      this.packageCriteria.holidayType = null;
      this.currentCabinDisplay = 'Pick your cabin';
      this.packageCriteria.flightOptions = null;
      this.currentDepartureAirport = 'Select Airport';
      this.currentOccasion = 'Are you celebrating?';
      this.packageCriteria.attributes = null;
      if (this.cabinClassList && this.cabinClassList.length) {
        this.cabinClassList = this.cabinClassList.map(item => ({
          ...item,
          selected: false
        }));
      }
      if (this.occasionList && this.occasionList.length) {
        this.occasionList = this.occasionList.map(item => ({
          ...item,
          selected: false
        }));
      }
      if (this.departureAirportsList && this.departureAirportsList.length) {
        this.departureAirportsList = this.departureAirportsList.map(item => ({
          ...item,
          selected: false
        }));
      }
      this.resetDisplayItems();
      this.commonService.updateBaseCriteria(this.packageCriteria);
      this.minMaxPriceErrorMsg='';
      this.minMaxPriceError=false;
    } else {
      this.cancelRefineSearch();
    }
    if (this.isServiceAddComponent) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }
  }

  /**
   * reset display values in each component
   */
  resetDisplayItems() {
    this.selectedDest.displayText = '';
    this.selectedAirport.displayText = '';
    this.minPriceDisplayValue = '';
    this.maxPriceDisplayValue = '';
    this.defaultPaxSet = [];
    this.multiFieldValues[0].code = '';
    this.multiFieldValues[0].value = '';
    this.multiFieldValues[1][0].selected = true;
    this.multiFieldValues[1][1].selected = false;
    this.resetCalendar = true;
    this.setRoomWise = true;
    if (this.REC_ENABLE_FOR_EP_SEARCH) {
      this.selectedTravellerInterests = [];
    } else {
      this.holidayTypes.forEach(holidayType => {
        holidayType.selected = false;
      });
      this.selectedHolidayTypes = [];
    }
    setTimeout(() => {
      this.resetCalendar = false;
      this.setRoomWise = false;
    }, 0);
  }

  /**
   * cancel refine search
   */
  cancelRefineSearch() {
    this.cancelRefine.emit(this.isRefine);
    // this.resetToOriginalRefineCriteria();
  }

  /**
   * pass selected values and redirect on search holiday click
   */
  searchHoliday(isExternal?) {
    if (this.validateInputs()) {
      const basePath = '/'; // this.isRefine ? window.location.origin + '/' : window.location.href;

      if (this.isServiceAddComponent) {
        const criteriaUrl = basePath + 'b2b-tailor-made/?' + this.keyParams + this.getHolidaySearchUrlParams();
        this.getUrlCriteria.emit({url: criteriaUrl, isExternal});
      } else {
        window.location.href = basePath + 'b2b-tailor-made/?' + this.keyParams + this.getHolidaySearchUrlParams();
      }
    }
  }

  /**
   * validate all component inputs
   */
  validateInputs() {
    let isAllInputsPresent = false;
    // if (this.packageCriteria.destinationCode && this.packageCriteria.destinationName && this.selectedDest.displayText &&
    //   this.packageCriteria.departureAirportCode && this.packageCriteria.departureAirportName && this.selectedAirport.displayText &&
    //   this.packageCriteria.adult && ( this.packageCriteria.startDate || ( this.packageCriteria.startYear && this.packageCriteria.startMonth) )) {
    //   isAllInputsPresent = true;
    // }
    if (!this.isDateNotSelected && this.packageCriteria.adult &&
      (this.packageCriteria.startDate || (this.packageCriteria.startYear && this.packageCriteria.startMonth))
      && (!this.errorInPackageCode && !(this.destSearchInitiated && this.filteredList.length == 0) &&
        !(this.airportSearchInitiated && this.filteredAirportList.length == 0)) && !this.durationError &&
      !this.minMaxPriceError) {
      isAllInputsPresent = true;
    }
    return isAllInputsPresent;
  }

  /**
   * create URL parameters
   */
  getHolidaySearchUrlParams() {
    // get adult guest related params
    let startDate;
    if (this.packageCriteria.startDate) {
      startDate =  this.packageCriteria.startDate;
    } else {
      startDate =  this.createDummyDateUsingMonthAndYear( this.packageCriteria.startYear , this.packageCriteria.startMonth) ;
    }

    if (this.packageCriteria.adult) {
      // this.packageCriteria.adult = this.surfCriteriaUtil.replaceDOB(this.packageCriteria.adult, startDate);
      this.packageCriteria.adult = this.packageCriteria.adult.replace(/DOB/g, ''); // removing DOB from adult
    }

     // get child guest related params
    let childParams = '';
    if (this.packageCriteria.teen && this.packageCriteria.teen.length > 0) {
      // this.packageCriteria.child = this.surfCriteriaUtil.replaceDOB(this.packageCriteria.child, startDate );
      this.packageCriteria.teen = this.packageCriteria.teen.replace(/DOB/g, ''); // remove DOB from child
      childParams = '&child=' + this.packageCriteria.teen;
    }
    if (this.packageCriteria.child && this.packageCriteria.child.length > 0) {
      // this.packageCriteria.teen = this.surfCriteriaUtil.replaceDOB(this.packageCriteria.teen, startDate );
      if (childParams.toString().includes('&child')) {
        childParams += '__' + this.packageCriteria.child.replace(this.packageCriteria.teen, '');
      } else {
        childParams += '&child=' + this.packageCriteria.child;

      }
    }
    if (this.packageCriteria.infant && this.packageCriteria.infant.length > 0) {
      // this.packageCriteria.infant = this.surfCriteriaUtil.replaceDOB(this.packageCriteria.infant, startDate , '', TravellerType.INFANT);
      this.packageCriteria.infant = this.packageCriteria.infant.replace(/DOB/g, ''); // remove DOB from infant
      this.packageCriteria.infant = this.packageCriteria.infant.replace(/NaN/g, ''); // remove DOB from infant
      this.packageCriteria.infant = this.packageCriteria.infant.replace('-1', ''); // remove -1 from infant
      childParams += '&infant=' + this.packageCriteria.infant;
    }

    // get duration related params
    let durationParam = '&durationOffset=' + this.DURATION_OFFSET;

    if (this.packageCriteria.nights) {
      durationParam += '&nights=' + this.packageCriteria.nights; // keeping this line since package-criteria component uses this to map duration in refine criteria
      durationParam += '&duration=' + this.packageCriteria.nights;
    }
    // get destination related params
    let queryStr = '&adult=' + this.packageCriteria.adult + childParams
      + durationParam + (this.packageCriteria.destinationType ? '&destinationType=' + this.packageCriteria.destinationType : '')
      + (this.packageCriteria.destinationCode ? '&destinationCode=' + this.packageCriteria.destinationCode : '')
      + (this.packageCriteria.destinationName ? '&destinationName=' + this.packageCriteria.destinationName : '')
      + '&isRoomWise=' + this.roomWiseSelected;

    if (this.packageCriteria.destinationCode && this.packageCriteria.destinationType == DestinationType.CITY) {
      queryStr += '&desCity=' + this.packageCriteria.destinationCode;
    } else if (this.packageCriteria.destinationCode && this.packageCriteria.destinationType == DestinationType.COUNTRY) {
      queryStr += '&desCountry=' + this.packageCriteria.destinationCode;
    }

    if (this.packageCriteria.startDate) {
      queryStr = queryStr + '&startDate=' + this.packageCriteria.startDate;
    } else {
      queryStr = queryStr + '&startMonth=' + this.packageCriteria.startMonth;
      queryStr = queryStr + '&startYear=' + this.packageCriteria.startYear;
    }

    if (this.packageCriteria.destinationParentCode) {
      queryStr += '&destinationParent=' + this.packageCriteria.destinationParentCode;
    }

    // get departure airport related params
    queryStr += (this.packageCriteria.departureAirportCode ? '&departureAirportCode=' + this.packageCriteria.departureAirportCode : '')
      + ((this.packageCriteria.departureAirportParentCityCode && this.packageCriteria.departureAirportCode) ? '&depAirport=' + this.packageCriteria.departureAirportCode : '&depCity=' + this.packageCriteria.departureAirportCode)
      + (this.packageCriteria.departureAirportName ? '&departureAirportName=' + this.packageCriteria.departureAirportName : '');

    if (this.packageCriteria.departureAirportParentCityCode) {
      queryStr += '&departureAirportParentCity=' + this.packageCriteria.departureAirportParentCityCode;
    }
    if (this.packageCriteria.departureAirportParentCountryCode) {
      queryStr += '&departureAirportParentCountry=' + this.packageCriteria.departureAirportParentCountryCode;
    }
    if (this.packageCriteria.pkgCode && this.packageCriteria.pkgCode !== '') {
      queryStr += '&keywordCode=' + this.packageCriteria.pkgCode;
    }
    if (this.packageCriteria.attributes && this.packageCriteria.attributes !== '') {
      queryStr += '&attributes=' + this.packageCriteria.attributes;
    }
    if (this.packageCriteria.flightOptions && this.packageCriteria.flightOptions !== '') {
      queryStr += '&flightOptions=' + this.packageCriteria.flightOptions;
    }
    if (this.packageCriteria.minPrice > 0) {
      queryStr += '&minPrice=' + this.packageCriteria.minPrice;
    }
    if (this.packageCriteria.maxPrice > 0) {
      queryStr += '&maxPrice=' + this.packageCriteria.maxPrice;
    }
    const holidayTypes = this.getHolidayTypesAsString();
    if ( holidayTypes && holidayTypes.length > 0) {
      queryStr += '&holidayType=' + holidayTypes;
    }

    if (this.REC_ENABLE_FOR_EP_SEARCH) {
      const travellerInterests = this.getTravellerInterestsAsString();
      if (travellerInterests && travellerInterests.length > 0) {
        queryStr += '&travellerInterests=' + travellerInterests;
      }
    }

    queryStr += '&pkgType=' + this.packageType;

    queryStr += '&expand=all&avail=true';

    return queryStr;
  }

  /**
   * return selected holidaytype codes as string
   */
  getHolidayTypesAsString() {

    try {
      if (this.REC_ENABLE_FOR_EP_SEARCH) {
        let holidayTypeStr = '';
        const tbxSelectedTravellerInterests = this.travellerInterestsService.fetchSelectedInterests(
          this.selectedTravellerInterests,
          SOURCE_REFERENCE.TBX
        );

        tbxSelectedTravellerInterests.forEach(tbxTravellerInterest => {
          holidayTypeStr += tbxTravellerInterest.code + '~';
        });

        if (holidayTypeStr.length > 1) {
          return holidayTypeStr.substring(0, holidayTypeStr.length - 1);
        }
      } else {
        let types = '';
        if (this.selectedHolidayTypes && this.selectedHolidayTypes.length > 0) {
          this.selectedHolidayTypes.forEach(code => types += code + '~');

          if (types.length > 1) {
            return types.substring(0, types.length - 1);
          }
        }
      }
    } catch (e) {
      console.log(' [ getHolidayTypesAsString ] error while converting fields to string', e);
    }
    return null;
  }

  /**
   * replace DOB with actual date of birth
   */
  replaceDOB(paxString): string {
    if (paxString) {
      let replacedPaxString = '';
      const travellers = paxString.split(this.AGE_SEPERATOR);
      if (travellers.length > 0) {
        travellers.forEach(pax => {
          const count = pax.split(this.FIELD_SEPERATOR)[0];
          const age = pax.split(this.FIELD_SEPERATOR)[1];
          // const dob = this.convertDateObjToStr(this.getDOB(age));
          // pax = pax.replace('DOB', dob);
          replacedPaxString += count + this.FIELD_SEPERATOR + age + this.AGE_SEPERATOR;
        });
      }
      return replacedPaxString.substring(0, replacedPaxString.length - 2);
    }
  }

  /**
   * return DOB according to the given age
   * @age
   */
  getDOB(age) {
    let today;
    let dob;
    today = new Date();
    if (today != null) {
      if (typeof age === 'number') {
        dob = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
      } else {
        if (age.trim() === this.LESS_THAN_ONE) {
          dob = new Date(today.getFullYear(), 0, 1);
        } else {
          dob = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
        }
      }
      return dob;
    }
  }

  /**
   * Convert javascript Date object to yyyy-MM-dd
   * @date
   */
  convertDateObjToStr(date ): string {
    let dateStr = '';
    if (date != null && date != '') {
      try {
        dateStr = date.getFullYear();

        dateStr = dateStr + '-';
        dateStr = dateStr + this.extractMonthFromDate(date);

        dateStr = dateStr + '-';

        if (date.getDate() <= 9) {
          dateStr += '0' + date.getDate();
        } else {
          dateStr += '' + date.getDate();
        }
      } catch (e) {
        console.error(e);
      }
      return dateStr;
    }
  }

  getMonthStr(date) {

    if (date != null && date != '') {
      try {
       return this.extractMonthFromDate(date);
      } catch (e) {
        console.error(e);
      }
      return '';
    }
  }

  private extractMonthFromDate(date) {
    let dateStr = '';
    if (date && date != '') {
      if (date.getMonth() < 9) {
        dateStr += '0' + (date.getMonth() + 1);
      } else {
        dateStr += '' + (date.getMonth() + 1);
      }
    }
    return dateStr;
  }


  private getYearStr(date) {
    let dateStr = '';
    if (date != null && date != '') {
      try {
        dateStr = date.getFullYear();
      } catch (e) {
        console.error(e);
      }
      return dateStr;
    }
  }

  convertStrToDateObj(str) {
    // "2019-08-01"
    if (str) {
      const dates = str.split('-');
      const  formattedDate = new Date(dates[0], dates[1] - 1, dates[2]);
      return formattedDate;
    }
    return  '';
  }

  /**
   * create dummy date to set moth date picker
   * @param year
   * @param month
   */
  createDummyDateUsingMonthAndYear(year, month) {
    if (year && month && year > 0 && month > 0) {
      return new Date(year, month - 1, 1);
    }
    return new Date();
  }

  getSelectedHolidayType(item) {
    const index = this.selectedHolidayTypes.indexOf(item.code);
    if ( index > -1 ) {
      if (this.showLoadMoreHolidayTypes) {
        this.tempUnselectedHolidayTypes.push(item.code);
      }
      item.selected = false;
      this.selectedHolidayTypes.splice(index, 1);
      this.sortHolidayTypes();
    } else {
      if (this.showLoadMoreHolidayTypes) {
        this.tempSelectedHolidayTypes.push(item.code);
      }
      item.selected = true;
      this.selectedHolidayTypes.push(item.code);
    }
    // console.log(this.selectedHolidayTypes);
    this.addHolidayTypeToPackageCriteria();
  }

  /**
   * set holiday types using url parm
   * @param holidayTypes
   */
  setSelectedHolidayTypes(holidayTypes) {

    if (holidayTypes && holidayTypes.length > 0 ) {
      const codes = holidayTypes.split('~');
      codes.forEach(code => this.selectedHolidayTypes.push(code));
    }
  }

  isRoomWiseSelected(event) {
    this.roomWiseSelected = event;
    if (!this.roomWiseSelected) {
      this.defaultPaxSet = [new PaxAllocationItem(2, [], [], [], [])];
    } /*else {
      this.defaultPaxSet = this.selectedPax;
    }*/
  }

  /**
   * handle key down
   * @param $event, component
   */
  onKeyPress($event, component) {
    if ($event.keyCode === KeyCodes.ENTER) {
      if (component === 0) {
        this.focusToDepartureMonth = true;
        this.focusToDepartureAirport = false;
        this.focusToDuration = false;
      }
      if (component === 1) {
        this.focusToDuration = true;
        this.focusToDepartureAirport = false;
        this.focusToDepartureMonth = false;
      }
      if (component === 2) {
        this.focusToDepartureAirport = true;
        this.focusToDepartureMonth = false;
        this.focusToDuration = false;
      }
    }
  }

  setPackageCode(value) {
    if (value && value !== '') {
      this.packageCriteria.pkgCode = value;
      this.errorInPackageCode = false;
    } else {
      this.packageCriteria.pkgCode = '';
      this.errorInPackageCode = false;
    }
    this.commonService.updateBaseCriteria(this.packageCriteria);
    console.log(this.commonService.getBaseCriteria());
  }

  setMinMaxPrice(event: any, type: EPPriceFilterType) {
    const value = event as string;
    let hasInputError = false;
    let isEmpty = false;
    let errorMsg = '';
    let logicalError = false;
    if (!value || value.trim().length == 0) {
      isEmpty = true;
    }
    if (!isEmpty) {
      hasInputError = !(value as string).match(this.MIN_MAX_PRICE_REGEX);
    }
    const displayAndActualPriceValues = this.getDisplayAndActualPriceValues(value, hasInputError, isEmpty);
    if (type == EPPriceFilterType.MIN_PRICE) {
      this.minPriceDisplayValue = displayAndActualPriceValues.priceDisplayValue;
      this.packageCriteria.minPrice = displayAndActualPriceValues.priceVal;
      this.minPriceError = hasInputError;
    } else if (type == EPPriceFilterType.MAX_PRICE) {
      this.maxPriceDisplayValue = displayAndActualPriceValues.priceDisplayValue;
      this.packageCriteria.maxPrice = displayAndActualPriceValues.priceVal;
      this.maxPriceError = hasInputError;
    }
    if (this.minPriceError && this.maxPriceError) {
      errorMsg = 'Enter valid min and max prices. Only integers are accepted';
    } else if (this.minPriceError) {
      errorMsg = 'Enter valid min price. Only integers are accepted';
    } else if (this.maxPriceError) {
      errorMsg = 'Enter valid max price. Only integers are accepted';
    } else if (this.hasPriceLogicalError()) {
      errorMsg = 'The max price should be greater than the min price';
      logicalError = true;
    }
    this.minMaxPriceErrorMsg = errorMsg;
    this.minMaxPriceError = this.minPriceError || this.maxPriceError || logicalError;
  }

  private hasPriceLogicalError(): boolean {
    if(this.packageCriteria.minPrice!=null && this.packageCriteria.maxPrice!=null){
      if(this.packageCriteria.minPrice>-1 && this.packageCriteria.maxPrice>-1){
        return  this.packageCriteria.maxPrice < 1 ||
          this.packageCriteria.maxPrice <= this.packageCriteria.minPrice;
      }else {
        return false
      }
    }else{
      return false;
    }

  }

  private getDisplayAndActualPriceValues(
    value: string, hasInputError: boolean, isEmpty: boolean): { priceDisplayValue: string, priceVal: number } {
    let priceDisplayValue: string;
    let priceVal: number;
    if (isEmpty) {
      priceDisplayValue = '';
      priceVal = -1;
    } else if (hasInputError) {
      priceDisplayValue = value;
      priceVal = -1;
    } else {
      priceDisplayValue = value;
      priceVal = parseInt(value);
    }
    return {priceDisplayValue, priceVal};
  }

  setHolidayLabel(value) {
    if (value && value !== '') {
      this.packageCriteria.holidayLabel = value;
    } else {
      this.packageCriteria.holidayLabel = '';
    }
  }

  createExperienceList() {
    this.dataServiceHandler
      .getExperienceTypes
      (this.user.userDetail.defaultCom.code, this.user.userDetail.defaultDivison.code, this.user.userDetail.defaultBrand.code, this.GDS_REF)
      .subscribe( res => {
          if (res) {
            this.experienceTypesList = res.data;
            if (res.data && res.data.length > 0) {
              this.experienceTypesList.forEach(experienceType => {
                experienceType.value = experienceType.name;
              });
            } else {
              this.experienceTypesList = [
                {
                  name: this.DEFAULT_EXPERIENCE_TYPE,
                  value: this.DEFAULT_EXPERIENCE_TYPE
                }
              ];
            }
          }
        },
        error => {
          console.log('Error in getting Experience types');
        });
  }

  setExperienceType(value) {
    this.packageCriteria.experienceType = value.name;
  }

  setMultiFieldInputProperties() {
    this.durationProperty = [];
    this.durationValues = [];
    this.durationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(true, 'Any No. of nights', false, false, 'number', '' , 'durationValue', ''));
    if (this.isRefine) {
      this.durationValues.push(new MultiFieldInputData('', this.packageCriteria.nights ? this.packageCriteria.nights.toString() : ''));
      // this.durationValues.push(new MultiFieldInputData('',''));
    } else {
      this.durationValues.push(new MultiFieldInputData('', ''));
    }

    this.durationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(true, '', false, true, 'text', '', 'durationType', ''));
    this.durationValues.push(new MultiFieldInputData(this.DURATION_CATEGORIES.code, this.DURATION_CATEGORIES.value));
  }

  handleInputFocusIn(event) {
    console.log(event);
  }


  loadMoreHolidayTypes() {
    this.renderer.addClass(document.body, 'u-unscrollable');
    this.rearrangeModals('holiday-types-id-modal');
    this.tempSelectedHolidayTypes = [];
    this.tempUnselectedHolidayTypes = [];
    this.showLoadMoreHolidayTypes = true;
  }

  handleAddHolidayType() {
    if (!this.isRefine) {
      this.renderer.removeClass(document.body, 'u-unscrollable');
    }
    // this.removeModals();
    this.showLoadMoreHolidayTypes = false;
    this.sortHolidayTypes();
    this.addHolidayTypeToPackageCriteria();
  }

  handleDepartureAirport(data) {
    this.packageCriteria.departureAirportCode = data.code;
    this.commonService.updateBaseCriteria(this.packageCriteria);
    this.currentDepartureAirport = data.value;
  }

  handleOccasion(data) {
    this.packageCriteria.attributes = 'specialOccasion~' + data.code;
    this.commonService.updateBaseCriteria(this.packageCriteria);
    this.currentOccasion = data.value;
  }

  handleCabinClass(data) {
    this.packageCriteria.flightOptions = 'CABIN_CLASS:' + data.code;
    this.commonService.updateBaseCriteria(this.packageCriteria);
    this.currentCabinDisplay = data.value;
  }

  handleCancelHolidayType() {
    if (!this.isRefine) {
      this.renderer.removeClass(document.body, 'u-unscrollable');
    }
    // this.removeModals();
    this.holidayTypes.forEach( holidayTypes => {
      if (this.tempSelectedHolidayTypes.indexOf(holidayTypes.code) > -1) {
        holidayTypes.selected = false;
      } else if (this.tempUnselectedHolidayTypes.indexOf(holidayTypes.code) > -1) {
        holidayTypes.selected = true;
      }
    });
    this.selectedHolidayTypes = this.selectedHolidayTypes.filter(item => this.tempSelectedHolidayTypes.indexOf(item) < 0);
    if (this.selectedHolidayTypes.length > 0) {
      this.holidayTypes.sort((type1, type2) => type2.selected - type1.selected);
    }
    this.showLoadMoreHolidayTypes = false;
    this.addHolidayTypeToPackageCriteria();
  }

  addHolidayTypeToPackageCriteria() {
    this.packageCriteria.holidayType = '';
    for (let index = 0; index < this.selectedHolidayTypes.length ; index++) {
      if (index !== this.selectedHolidayTypes.length - 1) {
        this.packageCriteria.holidayType += this.selectedHolidayTypes[index] + ',';
      } else {
        this.packageCriteria.holidayType += this.selectedHolidayTypes[index];
      }
    }
  }

  getCountry(city): string {
    let parentName = null;
    if (city.parent && city.parent.length > 0) {
      city.parent.forEach(p => {
        if (p.type == 20) {
          parentName = p.name;
        }
      });
    }
    return parentName;
  }

  addParentToAirport(airport, city) {
    const parent = [];
    if (city) {
      parent.push({code: city.code, name: city.name, type: city.type});  /*add city*/
    }
    if (city.parent && city.parent[0]) {
      parent.push({code: city.parent[0].code, name: city.parent[0].name, type: city.parent[0].type});  /*add country*/
    }
    airport.parent = parent;
    return airport;
  }

  rearrangeModals(id) {
      if (document.getElementById(id)) {
        document.body.appendChild(document.getElementById(id));
        this.addChildToBodyIdMap.set(id, true);
      }
  }

  removeModals(id) {
      if (document.getElementById(id) && this.addChildToBodyIdMap.get(id)) {
        document.body.removeChild(document.getElementById(id));
        this.addChildToBodyIdMap.set(id, false);
      }
  }

  /**
   * generate pax display string using pax counts
   * @param adult adult count
   * @param child children count
   * @param infant infant count
   * @param teen teen count
   */
  generateDisplayString(adult, child, infant, teen) {
    let str = '';

    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    return str;
  }

  /**
   * get selected Traveller Interests emitted from the surf-traveller-interests
   * @param event - list of selected Traveller Interests
   */
  getSelectedTravellerInterests(event: TravellerInterest[]) {
    if (event) {
      this.selectedTravellerInterests = event;
    }
  }

  /**
   * open surf-traveller-interests modal on add/update action
   */
  onAddOrUpdateTravellerInterests() {
    this.renderer.addClass(document.body, 'u-unscrollable');
    this.enableTravellerInterestsModal = true;
    this.rearrangeModals('traveller-interests-modal');
  }

  /**
   * receive modal close emit and close modal
   * @param event - boolean false
   */
  closeTravellerInterestModal(event) {
    this.renderer.removeClass(document.body, 'u-unscrollable');
    this.enableTravellerInterestsModal = event;
  }

  /**
   * get comma seperated traveller interests key from selected traveller interests
   */
  getTravellerInterestsAsString(): string {
    let travellerInterestString = '';
    this.selectedTravellerInterests.forEach(travellerInterest => {
      travellerInterestString += travellerInterest.key + ',';
    });

    if (travellerInterestString.length > 1) {
      return travellerInterestString.substring(0, travellerInterestString.length - 1);
    }

    return travellerInterestString;
  }

  /**
   * sort Holiday Types alphabetical order and set selected types first
   */
  sortHolidayTypes() {
    this.holidayTypes.sort((a, b) => a.name.localeCompare(b.name));
    if (this.selectedHolidayTypes.length > 0) {
      this.holidayTypes.sort((type1, type2) => type2.selected - type1.selected);
    }
  }

  handlePackageTypeChange(e){
    this.packageType=e.id
    this.packageCriteria.pkgType=this.packageType
    this.packageCriteria.flightOptions = null;
    this.packageCriteria.attributes = null;
    this.packageCriteria.pkgCode=null;
    this.packageCriteria.maxPrice=null;
    this.packageCriteria.minPrice=null;
  }
}
