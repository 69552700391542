import { Component, OnInit } from '@angular/core';
import {
  ConfigService,
  DataShareService,
  OntologyResponse,
  SurfAuthorizationService,
  SurfCalendarStartDateService,
  SurfCriteriaUtil, SurfStaticDataService, SurfTourUtilService,
  SurfTravellerEarliestDepartureDateService,
  SurfUtilService
} from '@surf/surf-components-core';
import { DataServiceHandler } from '@tc-core/service/service-handlers';
import { ConfigLoader, DataStore } from '@tc-core/util/framework';
import { TourCriteriaComponent } from '../tour-criteria/tour-criteria.component';

@Component({
  selector: 'surf-tour-criteria-dp',
  templateUrl: './tour-criteria-dp.component.html'
})
export class TourCriteriaDpComponent extends TourCriteriaComponent implements OnInit {

  constructor(
    protected commonService: DataShareService,
    protected dataServiceHandler: DataServiceHandler,
    protected surfStaticDataService: SurfStaticDataService,
    protected configService: ConfigService,
    protected surfUtilService: SurfUtilService,
    public surfCriteriaUtil: SurfCriteriaUtil,
    protected configLoader: ConfigLoader,
    protected surfCalendarStartDateService: SurfCalendarStartDateService,
    protected surfTourUtilService: SurfTourUtilService,
    protected datastore: DataStore,
    protected surfAuthorizationService: SurfAuthorizationService,
    protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService
  ) {
    super(
      commonService,
      dataServiceHandler,
      surfStaticDataService,
      configService,
      surfUtilService,
      surfCriteriaUtil,
      configLoader,
      surfCalendarStartDateService,
      surfTourUtilService,
      datastore,
      surfAuthorizationService,
      travellerEarliestDepartureDateService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
