<!--<div class="dashboard__main" *ngIf="loading;else elseBlock">
    <p>Loading...</p>
</div>-->

<div class="dashboard__main" *ngIf="!ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW">
  <div class="l-wrapper dashboard__main-wrapper">

    <!-- Booking summery -->
    <div class="c-booking-summery dashboard__summary">
      <div class="c-booking-summery__header">

        <div class="c-booking-summery__item t3">
          <small *ngIf="!isCustomDateRangeEnabled">
            {{ selectedDurationOptionHeadingTexts.section1 }}
            <span class="selected-period"
                  [ngClass]="{
                    'auto-last-quarter' : selectedDurationOption === defaultDurationOptions.QUARTER,
                    'auto-last-month' : selectedDurationOption === defaultDurationOptions.MONTH,
                    'auto-last-week' : selectedDurationOption === defaultDurationOptions.WEEK
                  }">
              {{ selectedDurationOptionHeadingTexts.section2 }}
            </span>
          </small>
          <span class="item-title">{{'Booking Summary' | surfTextCase:'title' }}</span>
        </div>

        <div *ngIf="!isCustomDateRangeEnabled" class="dashboard-search-filters__button">
          <div
            *ngFor="let item of defaultDurationOptions | keyvalue : durationOptionsSortingFn"
            class="dashboard-search-filters__items"
            [ngClass]="{
              'is-selected' : selectedDurationOption === item.value,
              'auto-quarter' : selectedDurationOption === defaultDurationOptions.QUARTER,
              'auto-month' : selectedDurationOption === defaultDurationOptions.MONTH,
              'auto-week' : selectedDurationOption === defaultDurationOptions.WEEK
             }"
            (click)="setDuration(item.value)">
            {{ getDurationOptionDisplayValue(item.value) | surfTextCase:'title'}}
          </div>
        </div>

        <div *ngIf="isCustomDateRangeEnabled" class="c-booking-stats__filter-bar">
          <div class="c-booking-stats__sort">
            <div class="m-form-field">
              <div class="m-form-field__label-wrap">
                <label class="m-form-field__label">Filter by</label>
              </div>
              <div class="m-form-field__field">
                <div class="m-form-field__field-item">
                  <select class="m-form-field__select"  (change)="onChangeDurationOption($event.target.value)">
                    <option
                      *ngFor="let item of defaultDurationOptions | keyvalue : durationOptionsSortingFn"
                      [selected]="selectedDurationOption === item.value"
                      [value]="item.value">
                      {{ getDurationDropdownDisplayValue(item.value) | surfTextCase:'sentence'}} ({{getDurationDropdownDisplayRange(item.value)}})
                    </option>
                    <option [value]="CUSTOM_DATE_RANGE_MODE">Custom ({{getDisplayDate(selectedStartDate)}} - {{getDisplayDate(selectedEndDate)}})</option>
                  </select>
                </div>
              </div>
            </div>
            <button *ngIf="selectedDateRange" (click)="onChangeDurationOption(CUSTOM_DATE_RANGE_MODE)"
                    class="a-btn a-btn--default a-tool-tip a-tool-tip--top c-booking-stats__sort-btn"
                    data-tooltip="Select custom date">
              <svg class="a-icon">
                <use xlink:href="#calendar"></use>
              </svg>
            </button>
          </div>
        </div>

      </div>
      <div class="c-booking-summery__ttv-wrapper">
        <div class="c-booking-summery__ttv">
          <ng-container  *ngIf="loading;else elseBlockprice">
            <div class="c-booking-summery__loading">Please wait...</div>
          </ng-container>
                              <ng-template #elseBlockprice>
                                <ng-template [ngIf]="highlightsMap.get(this.TTV)">
                                  <span class="c-booking-summery__type">{{display[TTV]| surfTextCase:'title' }}</span>
                                  <div class="c-booking-summery__value">
                                    <div class="c-booking-summery__items" *ngFor="let item of highlightsMap.get(this.TTV);let i=index" [id]="'ttv-'+i">
                                      {{highlightsMap.get(TTV)[i] | surfPricePipe:'':'':true }}<span class="currency-code">{{highlightsMap.get(bookingHighlights.SELLING_CURRENCY)[i] }}</span>
                                    </div>
                                  </div>
                                </ng-template>

                              </ng-template>


        </div>
<!--        <div SurfAuthorization [key]="permissionKeys.ACCESS_ALL_BOOKINGS" class="c-booking-summery__my-booking auto-retail-store-view-toggle">-->
<!--          <surf-toggle-->
<!--            [enableLabel]="toggleBtnTitle"-->
<!--            [disableLabel]="toggleBtnTitle"-->
<!--            [disabled]="disabled"-->
<!--            [active]="active"-->
<!--            (value)="toggleState($event)">-->
<!--          </surf-toggle>-->
<!--        </div>-->
      </div>
      <div class="c-booking-summery__details">


        <div *ngFor="let option of highlightOptionList;let i=index" [id]="'highlight-option'+i">
          <span class="c-booking-summery__sub-title" *ngIf="STATUS_OVERDUE === option.state">Overdue payments</span>
          <div (click)="emitSelectedOption(option.state)"
               [ngClass]="[
               selectedOption === option.state ? 'c-booking-summery__box is-active':'c-booking-summery__box',
                STATUS_OVERDUE === option.state?'c-booking-summery__box--warn':
                STATUS_UPCOMING === option.state?'c-booking-summery__box--success': 'c-booking-summery__box--info'
                ]">
            <div class="c-booking-summery__box-details">

            <ng-container  *ngIf="loading;else elseBlock0">
              <div class="c-booking-summery__loading">Please wait...</div>
            </ng-container>
              <ng-template #elseBlock0>
                <span class="c-booking-summery__value">{{getHighlightValue(option)}}</span>
              </ng-template>
              <span class="c-booking-summery__type">{{option.display}}</span>
              <span class="c-booking-summery__group" *ngIf="option.name === bookingHighlights.UPCOMING_PAYMENTS">{{upcomingPaymentSubText}}</span>
              <span class="c-booking-summery__group" *ngIf="option.name === bookingHighlights.OVERDUE_PAYMENTS">{{overduePaymentSubText}}</span>
            </div>
          </div>

        </div>
      </div>


      <div [ngSwitch]="selectedOption">

        <ng-template [ngSwitchCase]="STATUS_All_BOOKINGS_WITHOUT_QUOTE">
          <div class="c-booking-summery__mp-destination"
               *ngIf="highlightsMap.get(bookingHighlights.POPULAR_DESTINATION_BOOKING)">
            <div class="mp-destination-icon">
              <svg class="a-icon">
                <use xlink:href="../assets/svg/icons.svg#info"></use>
              </svg>
            </div>
            <div class="mp-destination-para">
              <span class="lbl">Most popular destination</span>
              <span class="mp-destination-lb">{{highlightsMap.get(bookingHighlights.POPULAR_DESTINATION_BOOKING)| surfTextCase:'title'}}</span>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="STATUS_All_PAID_BOOKINGS">
          <div class="c-booking-summery__mp-destination" *ngIf="highlightsMap.get(bookingHighlights.POPULAR_DESTINATION_BOOKING)">
            <div class="mp-destination-icon">
              <svg class="a-icon"><use xlink:href="../assets/svg/icons.svg#info"></use></svg>
            </div>
            <div class="mp-destination-para">
              <span class="lbl">Most popular destination</span>
              <span class="mp-destination-lb">{{highlightsMap.get(bookingHighlights.POPULAR_DESTINATION_BOOKING)| surfTextCase:'title'}}</span>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="STATUS_QUOTE">
        <div class="c-booking-summery__mp-destination" *ngIf="highlightsMap.get(bookingHighlights.POPULAR_DESTINATION_QUOTE)">
          <div class="mp-destination-icon">
            <svg class="a-icon"><use xlink:href="../assets/svg/icons.svg#info"></use></svg>
          </div>
          <div class="mp-destination-para">
            <span class="lbl">Most popular destination</span>
            <span class="mp-destination-lb">{{highlightsMap.get(bookingHighlights.POPULAR_DESTINATION_QUOTE) | surfTextCase:'title'}}</span>
          </div>
        </div>
      </ng-template>


      </div>

    </div>

    <div #tableElement [ngSwitch]="selectedOption">
      <ng-template [ngSwitchCase]="STATUS_All_BOOKINGS_WITHOUT_QUOTE">

        <surf-booking-search
                             [performSearch]="true"
                             [bookingHighlightsCriteria]="bookingCriteria"
                             [resultCount]="highlightsMap.get(bookingHighlights.TOTAL_BOOKINGS)"
                             [optionStatus]="STATUS_All_BOOKINGS_WITHOUT_QUOTE"
                             [listType]="LIST_VIEW_TYPE"
                             [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
                             [sortBy]="BOOKING_ID_DESC_SORT"
        ></surf-booking-search>
      </ng-template>

      <ng-template [ngSwitchCase]="STATUS_All_PAID_BOOKINGS">

        <surf-booking-search
          [performSearch]="true"
          [bookingHighlightsCriteria]="bookingCriteria"
          [resultCount]="highlightsMap.get(bookingHighlights.TOTAL_PAID_BOOKINGS)"
          [optionStatus]="STATUS_All_PAID_BOOKINGS"
          [listType]="LIST_VIEW_TYPE"
          [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
          [sortBy]="BOOKING_ID_DESC_SORT"
        ></surf-booking-search>
      </ng-template>


      <ng-template [ngSwitchCase]="STATUS_CONFIRMED">
        <surf-booking-search
                             [performSearch]="true"
                             [bookingHighlightsCriteria]="bookingCriteria"
                             [resultCount]="highlightsMap.get(bookingHighlights.TOTAL_BOOKINGS)"
                             [bookingStatus]="STATUS_CONFIRMED"
                             [listType]="LIST_VIEW_TYPE"
                             [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
                             [sortBy]="BOOKING_ID_DESC_SORT"
        ></surf-booking-search>
      </ng-template>

      <ng-template [ngSwitchCase]="STATUS_QUOTE">
        <surf-booking-search
                              [performSearch]="true"
                              [bookingHighlightsCriteria]="bookingCriteria"
                              [resultCount]="highlightsMap.get(bookingHighlights.QUOTES)"
                              [optionStatus]="STATUS_QUOTE"
                              [listType]="LIST_VIEW_TYPE"
                              [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
                              [sortBy]="BOOKING_ID_DESC_SORT"
        ></surf-booking-search>
      </ng-template>

      <ng-template [ngSwitchCase]="STATUS_CANCELLED">
        <surf-booking-search *ngIf="selectedOption === STATUS_CANCELLED" @slideDown
                             [bookingHighlightsCriteria]="bookingCriteria"
                             [bookingStatus]="STATUS_CANCELLED"
                             [performSearch]="true"
                             [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
                             [sortBy]="BOOKING_ID_DESC_SORT"
        ></surf-booking-search>
      </ng-template>

      <ng-template [ngSwitchCase]="STATUS_UPCOMING">
        <surf-booking-search
          [performSearch]="true"
          [bookingHighlightsCriteria]="paymentDueBookingCriteria"
          [resultCount]="highlightsMap.get(bookingHighlights.UPCOMING_PAYMENTS)"
          [optionStatus]="STATUS_UPCOMING"
          [bookingStatus]="STATUS_UPCOMING"
          [sortBy]="UPCOMING_PAYMENT_SORT"
          [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
          [listType]="LIST_VIEW_TYPE">
        </surf-booking-search>
      </ng-template>

      <ng-template [ngSwitchCase]="STATUS_OVERDUE">
        <surf-booking-search
          [performSearch]="true"
          [bookingHighlightsCriteria]="paymentOverDueBookingCriteria"
          [resultCount]="highlightsMap.get(bookingHighlights.OVERDUE_PAYMENTS)"
          [optionStatus]="STATUS_OVERDUE"
          [bookingStatus]="STATUS_OVERDUE"
          [sortBy]="UPCOMING_PAYMENT_SORT"
          [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
          [listType]="LIST_VIEW_TYPE">
        </surf-booking-search>
      </ng-template>

    </div>

  </div>
</div>

<ng-container *ngIf="ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW">
  <div class="dashboard__stats">
    <div class="l-wrapper dashboard__stats-wrapper">
      <div class="c-booking-stats">
        <div class="c-booking-stats__header">
          <div class="c-booking-stats__info-block">
            <div *ngIf="!isCustomDateRangeEnabled" class="c-booking-stats__info">
                <span  class="c-booking-stats__info-lbl">{{ selectedDurationOptionHeadingTexts.section1 }} </span>
                <span class="c-booking-stats__info-val">{{ selectedDurationOptionHeadingTexts.section2 }}</span>
            </div>
            <h4 class="t-4 c-booking-stats__title">{{'Booking Summary' | surfTextCase:'title' }}</h4>
          </div>
          <button class="a-btn a-btn--default c-booking-stats__back-btn"
                  (click)="goBackToHomeView()">
            <svg class="a-icon u-mr-xs">
              <use xlink:href="#long-arrow--back"></use>
            </svg>
            Back to home
          </button>
        </div>
        <div class="c-booking-stats__filter-bar">
          <div class="c-booking-stats__sort">
            <div *ngIf="!isCustomDateRangeEnabled" class="a-switch">
              <label class="a-switch__item" *ngFor="let item of defaultDurationOptions | keyvalue : durationOptionsSortingFn">
                <input
                  [checked]="selectedDurationOption === item.value"
                  class="a-switch__item-check"
                  name="statTime"
                  type="radio">
                <span class="a-switch__item-label"
                      (click)="setDuration(item.value)">
                  {{ getDurationOptionDisplayValue(item.value) | surfTextCase:'title'}}
                </span>
              </label>
            </div>
            <div *ngIf="isCustomDateRangeEnabled" class="m-form-field">
              <div class="m-form-field__label-wrap">
                <label class="m-form-field__label">Filter by</label>
              </div>
              <div class="m-form-field__field">
                <div class="m-form-field__field-item">
                  <select class="m-form-field__select"  (change)="onChangeDurationOption($event.target.value)">
                    <option
                      *ngFor="let item of defaultDurationOptions | keyvalue : durationOptionsSortingFn"
                      [selected]="selectedDurationOption === item.value"
                      [value]="item.value">
                      {{ getDurationDropdownDisplayValue(item.value) | surfTextCase:'sentence'}} ({{getDurationDropdownDisplayRange(item.value)}})
                    </option>
                    <option [value]="CUSTOM_DATE_RANGE_MODE">Custom ({{getDisplayDate(selectedStartDate)}} - {{getDisplayDate(selectedEndDate)}})</option>
                  </select>
                </div>
              </div>
              <span class="m-form-field__error">error</span>
            </div>
            <button *ngIf="isCustomDateRangeEnabled && selectedDateRange" (click)="onChangeDurationOption(CUSTOM_DATE_RANGE_MODE)"
                    class="a-btn a-btn--default a-tool-tip a-tool-tip--top c-booking-stats__sort-btn"
                    data-tooltip="Select custom date">
              <svg class="a-icon">
                <use xlink:href="#calendar"></use>
              </svg>
            </button>
          </div>
          <div SurfAuthorization [key]="permissionKeys.ACCESS_ALL_BOOKINGS" class="c-booking-stats__view">
            <div class="c-booking-stats__view-item" (click)="toggleState(false)">
              <label class="a-radio">
                <input [checked]="!active" class="a-radio__check" name="statType" type="radio"
                       value="My Bookings">
                <span class="a-radio__label">My bookings</span>
              </label>
            </div>
            <div class="c-booking-stats__view-item" (click)="toggleState(true)">
              <label class="a-radio">
                <input [checked]="active" class="a-radio__check" name="statType" type="radio" value="Retail">
                <span class="a-radio__label">Retail store view</span>
              </label>
            </div>
          </div>
        </div>
        <div class="c-booking-stats__summary">
          <div class="c-booking-stats__summary-wrapper">

            <div class="c-booking-summery__box c-booking-summery__box--total">
              <div class="c-booking-summery__box-details">
                <div class="c-booking-stats__data">
                  <ng-container  *ngIf="loading;else elseBlockTotal">
                    <span class="c-booking-stats__value c-inline-loader c-inline-loader--price">Loading...</span>
                  </ng-container>
                  <ng-template #elseBlockTotal>
                    <ng-container *ngIf="highlightsMap.get(this.TTV)">
                      <ng-container *ngFor="let item of highlightsMap.get(this.TTV);let i=index" style="">
                        <span class="c-booking-summery__value" [id]="'ttv-'+i">
                          {{(highlightsMap.get(TTV)[i] | surfThousandSuffixesPipe : 1) || 0 }}
                        </span>
                        <span class="c-booking-stats__unit">{{highlightsMap.get(bookingHighlights.SELLING_CURRENCY)[i] }}</span>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </div>
                <span class="c-booking-summery__type">{{display[TTV] | surfTextCase:'title' }}</span>
              </div>
            </div>

            <div *ngFor="let option of highlightOptionList;let i=index" [id]="'highlight-option'+i">
              <div (click)="emitSelectedOption(option.state)"
                   [ngClass]="[
                selectedOption === option.state ? 'c-booking-summery__box is-active':'c-booking-summery__box',
                STATUS_OVERDUE === option.state?'c-booking-summery__box--warn':
                STATUS_UPCOMING === option.state?'c-booking-summery__box--success': 'c-booking-summery__box--info'
                ]">
                <div class="c-booking-summery__box-details">
                  <ng-container  *ngIf="loading;else elseBlock0">
                    <span class="c-booking-stats__value c-inline-loader c-inline-loader--price">Loading...</span>
                  </ng-container>
                  <ng-template #elseBlock0>
                    <span class="c-booking-summery__value">{{getHighlightValue(option)}}</span>
                  </ng-template>
                  <span class="c-booking-summery__type">{{option.display | surfTextCase:'title'}}</span>
                  <span class="c-booking-summery__group" *ngIf="option.name === bookingHighlights.UPCOMING_PAYMENTS">{{upcomingPaymentSubText}}</span>
                  <span class="c-booking-summery__group" *ngIf="option.name === bookingHighlights.OVERDUE_PAYMENTS">{{overduePaymentSubText}}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div #tableElement class="dashboard__results" *ngIf="selectedOption">
    <div class="l-wrapper dashboard__results-wrapper">
      <!--Booking results table-->
      <div [ngSwitch]="selectedOption">
        <ng-template [ngSwitchCase]="STATUS_All_BOOKINGS_WITHOUT_QUOTE">

          <surf-booking-search
            [performSearch]="true"
            [bookingHighlightsCriteria]="bookingCriteria"
            [resultCount]="highlightsMap.get(bookingHighlights.TOTAL_BOOKINGS)"
            [optionStatus]="STATUS_All_BOOKINGS_WITHOUT_QUOTE"
            [listType]="LIST_VIEW_TYPE"
            [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
            [sortBy]="BOOKING_ID_DESC_SORT"
          ></surf-booking-search>
        </ng-template>

        <ng-template [ngSwitchCase]="STATUS_All_PAID_BOOKINGS">

          <surf-booking-search
            [performSearch]="true"
            [bookingHighlightsCriteria]="bookingCriteria"
            [resultCount]="highlightsMap.get(bookingHighlights.TOTAL_PAID_BOOKINGS)"
            [optionStatus]="STATUS_All_PAID_BOOKINGS"
            [listType]="LIST_VIEW_TYPE"
            [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
            [sortBy]="BOOKING_ID_DESC_SORT"
          ></surf-booking-search>
        </ng-template>


        <ng-template [ngSwitchCase]="STATUS_CONFIRMED">
          <surf-booking-search
            [performSearch]="true"
            [bookingHighlightsCriteria]="bookingCriteria"
            [resultCount]="highlightsMap.get(bookingHighlights.TOTAL_BOOKINGS)"
            [bookingStatus]="STATUS_CONFIRMED"
            [listType]="LIST_VIEW_TYPE"
            [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
            [sortBy]="BOOKING_ID_DESC_SORT"
          ></surf-booking-search>
        </ng-template>

        <ng-template [ngSwitchCase]="STATUS_QUOTE">
          <surf-booking-search
            [performSearch]="true"
            [bookingHighlightsCriteria]="bookingCriteria"
            [resultCount]="highlightsMap.get(bookingHighlights.QUOTES)"
            [optionStatus]="STATUS_QUOTE"
            [listType]="LIST_VIEW_TYPE"
            [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
            [sortBy]="BOOKING_ID_DESC_SORT"
          ></surf-booking-search>
        </ng-template>

        <ng-template [ngSwitchCase]="STATUS_CANCELLED">
          <surf-booking-search *ngIf="selectedOption === STATUS_CANCELLED" @slideDown
                               [bookingHighlightsCriteria]="bookingCriteria"
                               [bookingStatus]="STATUS_CANCELLED"
                               [performSearch]="true"
                               [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
                               [sortBy]="BOOKING_ID_DESC_SORT"
          ></surf-booking-search>
        </ng-template>

        <ng-template [ngSwitchCase]="STATUS_UPCOMING">
          <surf-booking-search
            [performSearch]="true"
            [bookingHighlightsCriteria]="paymentDueBookingCriteria"
            [resultCount]="highlightsMap.get(bookingHighlights.UPCOMING_PAYMENTS)"
            [optionStatus]="STATUS_UPCOMING"
            [bookingStatus]="STATUS_UPCOMING"
            [sortBy]="UPCOMING_PAYMENT_SORT"
            [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
            [listType]="LIST_VIEW_TYPE">
          </surf-booking-search>
        </ng-template>

        <ng-template [ngSwitchCase]="STATUS_OVERDUE">
          <surf-booking-search
            [performSearch]="true"
            [bookingHighlightsCriteria]="paymentOverDueBookingCriteria"
            [resultCount]="highlightsMap.get(bookingHighlights.OVERDUE_PAYMENTS)"
            [optionStatus]="STATUS_OVERDUE"
            [bookingStatus]="STATUS_OVERDUE"
            [sortBy]="UPCOMING_PAYMENT_SORT"
            [disableScheduleBasedBookingSearch]="disableScheduleBasedBookingSearch"
            [listType]="LIST_VIEW_TYPE">
          </surf-booking-search>
        </ng-template>

      </div>
    </div>
  </div>
</ng-container>
<div class="o-modal is-active" *ngIf="showRangeSelectionModal">

  <div class="o-modal__content c-range-selector_modal">

    <div class="o-modal__body">
      <div class="o-modal__inner-header">
        <button class="a-btn a-btn--link o-modal__inner-close" (click)="closeRangeSelectionModal()">
          <svg class="a-icon o-modal__close-icon">
            <use xlink:href="#close"></use>
          </svg>
        </button>
      </div>
      <div class="c-range-selector">
        <div class="c-range-selector__header">
          <h4 class="t-4 c-range-selector__title">Select custom date range</h4>
        </div>
        <div class="c-range-selector__content">
          <div class="c-range-selector__picker">
            <surf-calendar-range-picker
              [calendarId]="'custom_range_selection'"
              [checkAvailability]="false"
              [range]="range"
              [continuousDateRange]="true"
              [fixLeft]="false"
              [fixRight]="false"
              [enablePreviousDates]="true"
              [currentDateOfTimeZone]="currentDateOfTimeZone"
              [durationLabel]="''"
              [availableDatesArray]="available_dates"
              [typeCalender]="calendarType"
              [showDurationInDays] = "true"
              [initialDates]="selectedDateRangeInCalendar"
              (selectedDateRange)="getSelectedDateRange($event)"
              (selectedOnlyOneElement)="startDateSelected($event)">
            </surf-calendar-range-picker>
          </div>
        </div>
        <div class="o-modal__footer">
          <button class="a-btn a-btn--default u-mr" (click)="closeRangeSelectionModal()">Cancel</button>
          <button class="a-btn a-btn--primary" (click)="applySelectedDates(false)" [ngClass]="{'is-disabled' : onlyStartDateSelected}">Apply</button>
        </div>
      </div>
    </div>

  </div>

</div>
