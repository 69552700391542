import {animate, AnimationTriggerMetadata, state, style, transition, trigger} from '@angular/animations';


export const BOOKING_ANIMATIONS: Array<AnimationTriggerMetadata> = [
  trigger('slideDown', [
    transition(':enter', [
      style({opacity: 0}),
      animate('200ms cubic-bezier(0.35, 0, 0.25, 1)',
        style({opacity: 1})),

    ]),
    transition(':leave', [
      animate('200ms cubic-bezier(0.35, 0, 0.25, 1)',
        style({opacity: 0}))
    ])
  ]),
  trigger('slideUp', [
    state('hide',  style({
      height: 0, marginTop: 0, marginBottom: 0, overflow: 'hidden', opacity: 0
    })),
    state('show',  style({
      height: '*', marginTop: '*', marginBottom: '*', overflow: '*', opacity: 1
    })),
    transition('hide => show', [
      animate('200ms 400ms cubic-bezier(0.35, 0, 0.25, 1)')
    ]),
    transition('show => hide', [
      animate('200ms cubic-bezier(0.35, 0, 0.25, 1)')
    ])
  ])
];
