import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  DataShareService,
  SurfCalendarStartDateService,
  SurfCriteriaUtil, SurfErrorMessages,
  SurfTravellerEarliestDepartureDateService
} from '@surf/surf-components-core';

@Component({
  selector: 'surf-own-arrangements',
  templateUrl: './own-arrangements.component.html'
})
export class OwnArrangementsComponent implements OnInit, OnChanges {

  @Input() index: number;
  @Input() payloadObject: any; // TODO: Use Own Arrangement payload type
  @Input() isRefine;
  @Input() isItemCloseable = true;
  @Input() isServiceAddComponent = false;
  @Input() initialDateStr: string;
  @Input() disableStartDate: string;
  @Input() unfreeze = false;
  @Input() isAmendmentFlow = false;
  @Input() oldCriteriaItem;
  @Input() paxSplitArray = [];
  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isUnfreeze = new EventEmitter<boolean>();
  @Output() isPaxSplitChanged = new EventEmitter<boolean>();

  datePickerLabel = 'FROM TO DATES*';
  datePickerFocus = false;
  CALENDAR_RANGE = 2;
  calenderStartDate: Date;
  resetCalendar = false;
  initialDates = [];
  freeze: boolean; // enable/disable all input fields during amendment search
  nameWarning = false;
  departureDateWarning = false;
  returnDateWarning = false;
  descriptionWarning = false;
  enablePaxSplit = false;
  hideErrorMsg = true;
  validateDisabledDateSelection = false;
  SURF_ERROR_MESSAGES = SurfErrorMessages;
  constructor( protected commonService: DataShareService,
               public surfCriteriaUtil: SurfCriteriaUtil,
               protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
               protected surfCalendarStartDateService: SurfCalendarStartDateService) { }

  ngOnInit() {
    console.log(this.payloadObject);
    this.commonService.getCurrentMsg().subscribe(msg => {
      if (msg === 'triggerReset') {
        this.resetInputs();
      }
    });
    if (this.initialDateStr) {
      if (this.payloadObject && this.payloadObject.duration > 0) {
        this.setCalenderDates(this.initialDateStr, this.payloadObject.duration);
      } else {
        this.setCalenderDates(this.initialDateStr, 1);
        this.payloadObject.duration = 1;
        if (this.oldCriteriaItem && this.oldCriteriaItem.criItem) {
          this.oldCriteriaItem.criItem.duration = 1;
        }
      }
      this.payloadObject.departureDate = this.initialDateStr;
      if (this.oldCriteriaItem && this.oldCriteriaItem.criItem) {
        this.oldCriteriaItem.criItem.departureDate = this.initialDateStr;
      }
      this.validateInputsForDP();
    }
    if (this.disableStartDate) {
      this.calenderStartDate = new Date(this.disableStartDate);
      if (isNaN(this.calenderStartDate.getTime())) {
        this.calenderStartDate = new Date();
      }
    } else {
      this.calenderStartDate = new Date();
    }
    this.surfCalendarStartDateService.updateMinDate(this.calenderStartDate, 'OWN');
    if (this.isAmendmentFlow) {
      this.doCheckLastMinuteBookingDate(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unfreeze && this.unfreeze !== undefined) {
      this.freeze = !this.unfreeze;
    }
    if (changes['disableStartDate']) {
      if (!(changes['disableStartDate'].currentValue === null || changes['disableStartDate'].currentValue === undefined)) {
        this.calenderStartDate = new Date(changes['disableStartDate'].currentValue);
      }
      if (this.calenderStartDate !== undefined && (this.disableStartDate !== undefined && this.disableStartDate !== null)) {
        this.calenderStartDate = new Date(this.disableStartDate);
      }
      if (isNaN(this.calenderStartDate.getTime())) {
        this.calenderStartDate = new Date();
      }
    }
    if (changes.initialDateStr && changes.initialDateStr.currentValue) {
      let dateGap = 1;
      if (this.initialDates.length > 2) {
        dateGap = this.initialDates.length - 1;
      }
      this.setCalenderDates(changes.initialDateStr.currentValue, dateGap);
      this.checkDateChange();
    }
  }

  setName($event) {
    this.payloadObject.name = $event;
    this.validateInputsForDP();
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkNameChange();
    }
  }

  setDescription($event) {
    this.payloadObject.description = $event;
    this.validateInputsForDP();
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkDescriptionChange();
    }
  }

  /**
   * Upon clicking the reset button, all the input fields and corresponding
   * values in the criteria object will be reset
   */
  resetInputs(isRefine?: boolean) {
    if (!isRefine) {
      this.payloadObject.name = '';
      this.payloadObject.description = '';
    } else {
      this.cancelRefine.emit(this.isRefine);
    }
    if (this.isServiceAddComponent) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }
  }

  setSelectedDuration($event) {
    // Duration should be number of nights
    this.payloadObject.duration = $event - 1;
    if (this.payloadObject && this.payloadObject.departureDate) {
      this.travellerEarliestDepartureDateService
        .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.payloadObject.departureDate);
    }
  }

  setSelectedDateRange($event) {
    if ($event && $event[0] ) {
      this.payloadObject.departureDate = this.surfCriteriaUtil.convertDateObjToStr($event[0], true);
      this.validateInputsForDP();
      this.initialDates = $event;
      // let value = this.surfCriteriaUtil.convertDateObjToStr(new Date($event[0]), true);
      let value = this.surfCriteriaUtil.convertDateObjToStr(new Date($event[0]), true) + '~' + Math.round(($event[($event.length - 1)].getTime() - $event[0].getTime()) / (1000 * 3600 * 24)).toString() + '~';
      let toDate = new Date($event[($event.length - 1)]);
      value = value + this.surfCriteriaUtil.convertDateObjToStr(toDate, true);

      let changeObj = {
        index : this.index,
        productCode : 'OWA',
        changeType : 'DATE',
        value : value
      };
      this.commonService.updateChangeItem(changeObj);
    }
    if ($event && $event.length === 0) {
      this.payloadObject.departureDate = null;
      this.validateInputsForDP();
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkDateChange();
    }
    if (this.payloadObject && this.payloadObject.departureDate) {
      this.travellerEarliestDepartureDateService
        .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.payloadObject.departureDate);
    }
    if (this.isAmendmentFlow) {
      this.doCheckLastMinuteBookingDate(true);
    }
  }

  closeCriteriaBox() {
    this.commonService.changeMessage('GEN_close');
    if (this.isRefine) {
      this.cancelRefine.emit(this.isRefine);
    }
  }

  removeItem() {
    this.itemRemoved.emit(this.index);
  }

  validateInputsForDP() {
    if (this.payloadObject.name && this.payloadObject.departureDate) {
      this.isValid.emit(true);
    } else {
      this.isValid.emit(false);
    }
  }

  /**
   * set calendar initial dates based on the config values
   */
  setCalenderDates(startDateStr: any, nights: number) {
    if (startDateStr === undefined || nights === undefined){
      return;
    }
    this.initialDates = [];
    const startDate = new Date(startDateStr);
    this.initialDates.push(startDate);
    for (let i = 1; i <= nights; i++) {
      this.initialDates.push(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i));
    }
  }

  /**
   * handle freeze-unfreeze checkbox
   */
  checkValue(event) {
    this.unfreeze = event.target.checked;
    this.freeze = !this.unfreeze;
    this.isUnfreeze.emit(event.target.checked);
  }

  /**
   * set warning true and false with the Name input change, by comparing with the initial value.
   */
  checkNameChange() {
    this.nameWarning = !(this.oldCriteriaItem && this.oldCriteriaItem.criItem &&
      this.payloadObject.name === this.oldCriteriaItem.criItem.name);
  }

  /**
   * set warning true and false with the Description input change, by comparing with the initial value.
   */
  checkDescriptionChange() {
    let description = '';
    if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.description) {
      description = this.oldCriteriaItem.criItem.description;
    }
    this.descriptionWarning = !(this.oldCriteriaItem && this.oldCriteriaItem.criItem &&
      this.payloadObject.description === description);
  }

  /**
   * set warning true and false with the Date inputs change, by comparing with the initial values.
   */
  checkDateChange() {
    if (this.payloadObject && this.payloadObject.departureDate && this.oldCriteriaItem && this.oldCriteriaItem.criItem &&
      this.oldCriteriaItem.criItem.departureDate &&
      (this.payloadObject.departureDate.split('T')[0] === this.oldCriteriaItem.criItem.departureDate.split('T')[0])) {
      this.departureDateWarning = false;
      this.returnDateWarning = this.payloadObject.duration !== this.oldCriteriaItem.criItem.duration;
    } else if (this.payloadObject && this.payloadObject.departureDate && this.oldCriteriaItem && this.oldCriteriaItem.criItem &&
      this.oldCriteriaItem.criItem.departureDate) {
      this.departureDateWarning = true;
      this.returnDateWarning = true;
    }
  }

  passengerSplitChange(event) {
    if (event && event.length === this.paxSplitArray.length) {
      this.isPaxSplitChanged.emit(false);
    } else {
      this.isPaxSplitChanged.emit(true);
    }
  }

  /**
   * enable passenger split dropdown
   */
  enablePaxSplitDropdown() {
    this.enablePaxSplit = true;
  }

  doCheckLastMinuteBookingDate(isAmendmentOldItemDateSelection: boolean) {
    if (this.payloadObject.departureDate) {
      let checkingDate = new Date(this.payloadObject.departureDate);
      if (this.surfCalendarStartDateService.validateWithCheckingIsEarlierDate(new Date(), checkingDate, 'OWN')) {
        this.hideErrorMsg = true;
        this.validateDisabledDateSelection = false;
        this.surfCalendarStartDateService.lastMinuteBookingErrorSet.delete(this.index);
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = true;
        }
      } else {
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = false;
        }
        this.surfCalendarStartDateService.lastMinuteBookingErrorSet.add(this.index);
        this.hideErrorMsg = false;
        this.validateDisabledDateSelection = true;
      }
    }
  }
}
