import {FlightCriteria} from '@surf/surf-components-core';
import {FlightGETCriteria as FlightGetCriteriaTC} from '@tc-core/model/it/codegen/ui/search/criteria/flight-GET-criteria';
import {Traveller} from '@tc-core/model/it/codegen/tbx/api/commons/traveller';

export enum PNR_STAGES {
  DEFAULT = 0,
  GRAB = 1,
  CHANGE = 2,
  GRAB_COMPLETE = 3,
  TRAVELLER_COMPARISON = 4
}

export enum GRAB_PNR_TYPE {
  CRITERIA = 0,
  REFINE = 1,
  BOOKING = 2,
  DYNAMIC_PACKAGE = 3,
  REFRESH = 4,
  QUOTE = 5
}

export class PnrSector {
  reference: string;
  departureDateTime: string;
  departure: string;
  arrival: string;
  fees: Array<any>;
  from: PnrSectorLocation;
  to: PnrSectorLocation;
  itineraryOrder : number;
  combineWith : number;
  baggage : Array<any>
  arrivalDateTime: string;
}

export class PnrSectorLocation {
  airportName : string;
  cityCode: string;
  cityName: string;
  countryCode: string;
  countryName: string;
  regionCode: string;
}

/**
 * TODO: Remove this after tc-core model change
 */
export class FlightGrabPnrGETCriteria {
  cmp: string;
  div: string;
  pnr: string;
  privateFareType: string;
  gdsRef: string;
  brand: string;
  manBkgItm : boolean;
  pseudoCity: string;
  useGDSPriceRecord: boolean;
  bkgDate: string;
  validateWithBookedPNRs: boolean;
  journeyType: string;
  actions: Array<string>;
  expand: string;
  bkgId: number;
  productKey: string;
  channel: string;
  cliGrp: string;
  cliId: string;
}

/**
 * TODO: Remove this after tc-core model change
 */
export class FlightGETCriteria extends FlightGetCriteriaTC {
  pnrInfo: string;
}


export class PnrComponentOutput {
  flightGrabPnrCriteria: FlightGrabPnrGETCriteria;
  flightComponentCriteria: FlightCriteria;
  flightGETCriteria: FlightGETCriteria;
  pnrNumber: string;
  pnrStage: PNR_STAGES;
  passengerCount: number;
  multiValidatingCarriers: boolean;
  priceRecordsStored : boolean;
  grabPax : any;
  paramString : string;
  proceedToDP : boolean;
}

export enum PNR_CRITERIA_ACTION {
  GRAB = 'GRAB',
  REFRESH = 'REFRESH',
}


export class PnrTravellerDiff {
  // has matching traveller in booking or pnr vice versa
  hasMatch: boolean;
  hasPrimaryMatch: boolean;

  // primary fields - creates a new traveller only for flight item
  // title: boolean;
  firstName: boolean;
  lastName: boolean;
  age: boolean;
  dateOfBirth: boolean;

  // secondary fields - update existing fields of the matching traveller
  phoneNumber: boolean;
  freqFlyer: boolean;
  subType: boolean;

  // other fields used to give warning message
  title: boolean;
  middleName: boolean;
  email: boolean;
  ppNumber: boolean;
  ppCountry: boolean;
  ppExpiry: boolean;
  nationality: boolean;


  constructor(matchPrimary?: boolean) {
    this.hasMatch = matchPrimary;
    // this.title = matchPrimary;
    this.firstName = matchPrimary;
    this.lastName = matchPrimary;
    this.dateOfBirth = matchPrimary;
    this.age = matchPrimary;
  }
}

export class MatchingTraveller {
  pnrTraveller: Traveller;
  bkgTraveller: Traveller;
  diffs: PnrTravellerDiff;
}

export class FlightDetail {
  legNo: number;
  arrival: string;
  departure: string;
  flightNumber: string;
  departureTerminal: string;
  arrivalTerminal: string;
  baggage: string;
  airline: string;
  airlineOperator: string;
  isOutBound: boolean;
  airlineLocator: string;
  departureDateTime: string;
  arrivalDateTime: string;
  bookingClass: string;
  cabinClass: string;
  equipment = '-';
  fareFamily: string;
}
export enum TRAVELLER_MAP_SPLITTERS {
  MAIN_SPLITTER = '_',
  SUB_SPLITTER = ','
}

export enum PNR_MODALS {
  GRAB_PNR = 0,
  TRAVELLER_COMPARISON = 1,
  REFRESH_PNR_CHANGES = 2,
  PNR_ALREADY_EXISTS_IN_A_BOOKING = 3
}

export class Ancillary {
  sectorName: string;
  paxName: string;
  type: string;
  pnrValue: Value;
  bkgValue: Value;
  constructor() {
  }

}

export class Value {
  description: string;
  price: string;
  constructor(description: string, price:string) {
    this.description = description;
    this.price = price;
  }
}

export class PerPersonAncillaryTotal {
  paxName: string;
  bkgValue: string;
  pnrValue: string;
  type: string;
}
