import { Component } from '@angular/core';
import { TourCriteriaItem } from '@surf/surf-components-core';
import { PaxAllocationItem } from '@surf/surf-pax-selection';
import { TourCriteria as DPTourCriteria } from '@tc-core/model/it/codegen/tbx/api/criteria/tour/tour-criteria';
import { PaxAge } from '@tc-core/model/it/codegen/tbx/api/product/criteria/pax-age';
import {NameCodePair} from '@tc-core/model/it/codegen/tbx/api/v2/commons/name-code-pair';


export class DpCriteriaManageTour {



  TRAVELLER_TYPE_ADULT = 'A';
  TRAVELLER_TYPE_CHILD = 'C';
  TRAVELLER_TYPE_TEEN = 'T';
  TRAVELLER_TYPE_INFANT = 'I';

  /**
   * Map the grab tour response to a list of dp criteria objects and return
   * @param event - grab tour response
   * @param criteriaItemList - empty dp criteria item list
   * @param grabPax - temporary pax item set used to determine the guest detail string at the refine page (usage in html)
   * @param cachePaxSet - temporary pax item set
   * @param passengerAllocation - guest detail pax item object
   */
  mapFromGrabCriteria(event: any, criteriaItemList: any, grabPax:any, cachePaxSet:any, passengerAllocation:any) : any {


    if (criteriaItemList == undefined) {
      criteriaItemList = [];
    }

    let index = 0;
    event.forEach(pnrGrabItem => {

      let tourCriItem = new TourCriteriaItem();


      tourCriItem.startDate = pnrGrabItem.startDate;
      tourCriItem.supplier = pnrGrabItem.supplierId;
      tourCriItem.durationRange = pnrGrabItem.durationRange+ '~' + pnrGrabItem.durationRange;
      tourCriItem.itineraryName = pnrGrabItem.itineraryName;
      const sourceInfo = pnrGrabItem.sourceInfo.split('~');
      let sourceInfoDetails = new NameCodePair();
      sourceInfoDetails.name = sourceInfo[1];
      sourceInfoDetails.code = sourceInfo[0]
      let blockItemDetails = new NameCodePair();
      blockItemDetails.name = pnrGrabItem.blockedReason
      blockItemDetails.code = pnrGrabItem.isBlockedResult
      criteriaItemList.push({
        index: index,
        productCode: 'TOU',
        criItem: tourCriItem,
        gapWithChangedItem: 0,
        isGrab:true,
        valid: pnrGrabItem?.isBlockedResult? false:true,
        externalTourId:pnrGrabItem.externalTourId,
        grabbedTourId:pnrGrabItem.grabbedTourId,
        sourceInfo:sourceInfoDetails,
        blockDetail:blockItemDetails,
        surName:sourceInfo[sourceInfo.length -1]
      });

      index++;
    });

    //set grab passengers
    let a = 0;
    let c = [];
    let t = [];
    let cDOB = [];
    let tDOB = [];
    let i = [];
    let iDOB = [];
    let infSeatAssociation = [];

    if (event.length > 0 && event[0].travellers) {
      grabPax = event[0].travellers?.travellers;
      event[0].travellers?.travellers.forEach(grabPax => {
        if (grabPax.profile.type == 'A') {
          a++;
        } else if (grabPax.profile.type == 'C') {
          c.push(grabPax.profile.age);
          cDOB.push(grabPax.profile.dob);
        } else if (grabPax.profile.type == 'T') {
          t.push(grabPax.profile.age);
          tDOB.push(grabPax.profile.dob);
        } else if (grabPax.profile.type == 'I') {
          i.push(grabPax.profile.age);
          iDOB.push(grabPax.profile.dob);
          infSeatAssociation.push(grabPax.infantSeatRequired);
        }
      });
    }

    cachePaxSet = [];
    cachePaxSet = [new PaxAllocationItem(a, c, i, infSeatAssociation, t)];
    cachePaxSet[0].childDOB = cDOB;
    cachePaxSet[0].teenDOB = tDOB;
    cachePaxSet[0].infantDOB = iDOB;

    passengerAllocation = [new PaxAllocationItem(a, c, i, infSeatAssociation, t)];
    passengerAllocation[0].childDOB = cDOB;
    passengerAllocation[0].teenDOB = tDOB;
    passengerAllocation[0].infantDOB = iDOB;

    return {
      criteriaItemList : criteriaItemList,
      grabPax : grabPax,
      cachePaxSet : cachePaxSet,
      passengerAllocation : passengerAllocation
    };
  }
}

