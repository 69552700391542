<div [ngClass]="{'c-advance-search__content': !isDPSearch}">

  <ng-container *ngIf="!isServiceAddComponent; else elseblock">

  <div class="c-advance-search__content-top" *ngIf="!isDPSearch">        <!--Content top : hidden in DP-->
      <button class="a-btn a-btn--link c-pkg-search__back-btn" (click)="closeCriteriaBox()">
        <svg class="a-icon u-mr">
          <use xlink:href="#previous"></use>
        </svg>
      </button>

    <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Search accommodations for</h3>
    <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine your accommodation search for</h3>
    <div class="c-advance-search__guests">
      <div class="m-form-item auto-pax-count" (click)="scrollTo('#scroll')">
        <surf-pax-selection
          [(isFocused)]="guestComponentFocus"
          [paxSet]="defaultPaxSet"
          [product]="'HTL'"
          [inputWrapperView]="false"
          [maxAdult]="MAX_ADULT"
          [minAdult]="MIN_ADULT"
          [maxPaxCount]="MAX_PAX_COUNT"
          [maxCabinPaxCount]="MAX_CABIN_PAX_COUNT"
          [maxChildren]="MAX_CHILD"
          [maxTeen]="MAX_TEEN"
          [maxCabins]="MAX_ROOMS"
          [maxInfants]="MAX_INFANTS"
          [maxInfantAge]="MAX_INFANT_AGE"
          [maxChildAge]="MAX_CHILD_AGE"
          [maxTeenAge]="MAX_TEEN_AGE"
          [roomViewSelected]="setRoomWise"
          [b2bView]="true"
          [infoDisabled]="true"
          [parentScrollId]="windowScrollEnable ? 'scroll': null"
          [isTeenActive]="TEEN_PAX_ACTIVE"
          (paxSelection)="getPassengerSelection($event)"
          [tabIndex]="2"
          (currentOption)="getCurrentOption($event)"
          (isRoomWiseSelected)="isRoomWiseSelected($event)"
          (keydown)="onKeyDown($event, 1)">
        </surf-pax-selection>
      </div>

    </div>
    <ng-container *ngIf="!isServiceAddComponent">
      <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
    </ng-container>
  </div>                     <!--Content top : hidden in DP-->
  </ng-container>
  <ng-template #elseblock>
    <div class="c-advance-search__content-top" *ngIf="showHeader">
      <h3 class="t2 c-advance-search__title">Define your search for </h3>
      <div class="c-advance-search__guests">
        <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
        <!--    <span class="s5">Existing passenger combination will be applied to the newly adding component</span>-->
      </div>
      <button class="a-btn a-btn--link o-modal__inner-close" (click)="resetInputs()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
    </div>
  </ng-template>
  <div class="c-advance-search__form" *ngIf="!isDPSearch">  <!--Hotel criteria content : visible in Component flow-->

    <div class="c-dp-search">

      <!--Coponent Adding is not Allowed-->
      <ng-container *ngIf="!isServiceAddComponent">
        <surf-dp-add-bar
          [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}"
          [isDPSearch]="false"
          [isIntro]="false"
          [totalItemCount]="1"
          [msg]="msg"
          [isRefine]="isRefine"
          [disableDPSwitch]="DISABLE_DP_SWITCH"
          [isDisabled]="DISABLE_DP_SWITCH"
          (showBarPanelToggle)="moveToDP('pre')"></surf-dp-add-bar>
      </ng-container>
      <ng-container *ngFor="let geoLocationError of geoLocationAutoCompleteErrors">
        <div class="c-dp-search__msg-block" *ngIf="geoLocationError">
          <div class="c-msg-block c-msg-block--warning o-block">
            <div class="l-inline-block">
              <svg class="a-icon c-msg-block__info-icon">
                <use xlink:href="#exclamation"></use>
              </svg>
              <div class="c-msg-block__info">
                <div class="c-msg-block__info-txt cap-bold">{{'We are having trouble searching for locations, please wait ' + GEO_HTL_AUTO_SWITCH_RETRY_TIME + ' seconds while we try to reconnect.'}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="c-dp-search__block c-dp-search__block--hotel"
           [ngClass]="{'is-selected': (isSelected || !isDPSearch)}"
           (click)="selectComponent()" (clickOutside)="deselectComponent()">
        <!--  is-selected t-primary-->
        <div class="c-dp-search__action">
          <div class="c-dp-search__block-icon">
            <svg class="a-icon">
              <use xlink:href="#products--hotel"></use>
            </svg>
          </div>
        </div>

        <div class="c-dp-search__block-label">
          <svg class="a-icon u-rmr">
            <use xlink:href="#products--hotel"></use>
          </svg>
          <span class="lbl">Hotel</span>
        </div>

        <div class="c-dp-search__form c-dp-search__form--hotel">

          <div class="c-dp-search__form-item" id="{{windowScrollEnable ? 'scroll': ''}}">
            <div class="m-form-item auto-destination-input" (click)="scrollTo('#scroll')">
              <surf-type-ahead
                [id]="index"
                [label]="'destination*'"
                [dataList]="destinations"
                [filterType]="'strict'"
                [mappings]="['code', 'value']"
                [sortOrder]="'A2Z'"
                [selectedItem]="selectedDest"
                [paramName]="'ontotext'"
                [placeholder]="getHotelDestinationPlaceHolder()"
                [bindFocusOut]="false"
                [selectAllOnEnter]="false"
                [isOnto]="true"
                [autoFocus]="!isRefine"
                [url]="departureUrl"
                [isEnableGeoSearch]="GEO_ENABLE_FOR_HOTEL_SEARCH && !isGeoSearchSwitched"
                [externalSources]="externalSources"
                [context]="'HTL'"
                [mergeOrder]="['GOOGLE', 'TBX']"
                [focusInput]="focusInit"
                [warningStyle]="'is-visited'"
                [warning]="destinationWarning"
                [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                (onClickInside)="onClickInsideTypeAhead($event)"
                (onClickOutside)="onClickOutsideTypeAhead($event)"
                (returnedQuery)="returnedSearchQuery($event)"
                (returnedData)="returnDestination($event)"
                (keydown)="onKeyDown($event, 0)"
                (geoErrorList)="handleGeoLocationErrors($event)"
                [tabIndex]="1">

                <surf-type-ahead-content
                  [typeAheadData]="filteredList"
                  [available]="filteredList?.length > 0"
                  [enableKeyStrokeIgnore]="true"
                  (setItem)="selectDestination($event)">

                  <ng-container *ngFor="let item of filteredList;let i = index">
                    <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 20">
                      <div class="c-dp-search__list-item search-ineligible auto-country-item">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.type == 'COUNTRY' || item?.type == 20" xlink:href="#country"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main auto-destinations-list">
                          <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                          <span class="s5 ts-muted ts-wet">
                          {{item?.type == 80 ?surfCriteriaUtil.getCity(item)?.name + ', ': '' }}
                            <span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>
                            {{surfCriteriaUtil.getCountry(item)}}
                        </span>
                        </div>
                        <span *ngIf="item?.type == 'COUNTRY' || item?.type == 20" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                      </div>

                    </surf-type-ahead-item>
                    <surf-type-ahead-item (click)="selectDestination(item)" *ngIf="item?.type == 40 || item?.type == 80">
                      <div class="c-dp-search__list-item auto-city-item">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                          <use *ngIf="item?.type == 'HOTEL' || item?.type == 80" xlink:href="#products--hotel"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main auto-destinations-list">
                          <span class="s4 ts-strong">{{item?.type == 40 ? item?.name : (item?.name | surfTextCase: 'title')}}</span>
                          <span class="s5 ts-muted ts-wet">
                          {{item?.type == 80 ?surfCriteriaUtil.getCity(item)?.name + ', ': '' }}
                            <span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>
                            {{surfCriteriaUtil.getCountry(item)}}
                        </span>
                        </div>
                        <span *ngIf="item?.type == 'CITY' || item?.type == 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                        <span *ngIf="item?.type == 'HOTEL' || item?.type == 80" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Hotel</span>
                      </div>

                    </surf-type-ahead-item>
                    <surf-type-ahead-item *ngIf="item?.type == 200">
                      <div
                        (click)="selectDestination(item)"
                        class="c-dp-search__list-item">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use
                            xlink:href="#map"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                          <span class="s5 ts-muted ts-wet"><span *ngIf="item.secondaryName">{{item.secondaryName}}</span></span>
                        </div>
                        <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location</span>
                      </div>
                    </surf-type-ahead-item>
                    <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                      <ng-template [ngIf]="item?.type === 20">  <!--then iterate its children (CITIES) as sub components-->
                        <div (click)="selectDestination( addParentToLocationGroup(child, item) )" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-cities" [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use xlink:href="#map"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                            <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{getCountry(item)}}</span>
                          </div>
                        </div>
                      </ng-template>
                    </surf-type-ahead-item>
                  </ng-container>
                </surf-type-ahead-content>
              </surf-type-ahead>
              <span *ngIf="destSearchInitiated && filteredList.length == 0" style="color: red;">No results found</span>
            </div>
          </div>


          <div class="c-dp-search__form-item c-dp-search__form-item--inverted auto-hotel-dates">
            <surf-date-picker (click)="scrollTo('#scroll')"
                              [inputLabel]="datePickerLabel"
                              [(isFocused)]="datePickerFocus"
                              [calendar_start_date]="(isForEpToDpConversion && index === 0) ? calendarMinDateForInitialEpeToDpConversionCriteria : calendarMinDate"
                              [currentDateOfTimeZone]="calendarMinDate"
                              [enablePreviousDates]="true"
                              [reset]="resetCalendar"
                              [check_availability]="true"
                              [range]="CALENDAR_RANGE"
                              [initial_dates]="initialDates"
                              [warningStyle]="'is-visited'"
                              [warning1]="checkInWarning"
                              [warning2]="checkOutWarning"
                              (selected_duration)="getSelectedDuration($event)"
                              (selected_dates)="getSelectedDateRange($event)"
                              (keydown)="onKeyDown($event, 2)"
                              [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : ''"
                              [isError]="validateDisabledDateSelection"
                              [validateDisabledDateSelection]="validateDisabledDateSelection"
                              [tabIndex]="3">
            </surf-date-picker>

          </div>

          <ng-container *ngIf="isDPSearch == false && REC_ENABLE_FOR_HOTEL_SEARCH && enableRecommendations">
            <div class="c-dp-search__action-block" *ngIf="selectedTravellerInterests.length === 0">
              <div class="c-dp-search__action-text">
                <svg class="a-icon u-mr-xxs u-x1.25">
                  <use xlink:href="#info"></use>
                </svg>
                <span>Traveller preferences will make the results more personalized to your customer</span>
              </div>
              <button class="a-link-btn a-link-btn--secondary c-dp-search__action-link" (click)="onAddOrUpdateTravellerInterests()">Add
                Traveller Interests
              </button>
            </div>

            <div class="c-dp-search__action-block" *ngIf="selectedTravellerInterests.length > 0">
              <div class="c-dp-search__action-text">
                <svg class="a-icon u-mr-xxs u-x1.25 tc-success">
                  <use xlink:href="#checked"></use>
                </svg>
                <span>
                <ng-container *ngFor="let travellerInterest of selectedTravellerInterests; let i = index">
                  {{selectedTravellerInterests.length - 1 == i ? travellerInterest?.name : travellerInterest?.name + ', '}}
                </ng-container> selected
              </span>
              </div>
              <button class="a-link-btn a-link-btn--secondary c-dp-search__action-link" (click)="onAddOrUpdateTravellerInterests()">Change
                Traveller Interests
              </button>
            </div>
          </ng-container>

        </div>

        <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
          <svg class="a-icon">
            <use xlink:href="#close"></use>
          </svg>
        </div>


      </div>
      <div class="c-dp-search__msg-block" *ngIf="geoPlaceDetailsError">
        <div class="c-msg-block c-msg-block--warning o-block">
          <div class="l-inline-block">
            <svg class="a-icon c-msg-block__info-icon">
              <use xlink:href="#exclamation"></use>
            </svg>
            <div class="c-msg-block__info">
              <div class="c-msg-block__info-txt cap-bold">{{geoPlaceDetailsError}}</div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isServiceAddComponent">
        <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}" [disableDPSwitch]="DISABLE_DP_SWITCH"
                         [totalItemCount]="1" [isDPSearch]="false" [isIntro]="false" [msg]="msg" [isRefine]="isRefine"
                         [isDisabled]="DISABLE_DP_SWITCH"
                         (showBarPanelToggle)="moveToDP('post')"></surf-dp-add-bar>
      </ng-container>
    </div>

  </div>                    <!--Hotel criteria content : visible in Component flow-->


  <div class="c-dp-search__block c-dp-search__block--hotel"  *ngIf="isDPSearch"
       [ngClass]="{'is-selected': (isSelected || !isDPSearch),  '':index%2 == 1}"
       (click)="selectComponent()" (clickOutside)="deselectComponent()">               <!--Hotel criteria content : visible in DP flow-->
    <!--  is-selected t-primary-->
    <div class="c-dp-search__action">
      <label *ngIf="isAmendmentFlow && !isItemCloseable" class="a-checkbox c-dp-search__action-check">
        <input type="checkbox" class="a-checkbox__check" (change)="checkValue($event)" [checked]="!freeze">
        <span class="a-checkbox__label"></span>
      </label>
      <div class="c-dp-search__block-icon">
        <svg class="a-icon">
          <use xlink:href="#products--hotel"></use>
        </svg>
      </div>
    </div>

    <div class="c-dp-search__block-label">
      <svg class="a-icon u-rmr">
        <use xlink:href="#products--hotel"></use>
      </svg>
      <span class="lbl">Hotel</span>
    </div>
    <div class="c-dp-search__form-wrap">
    <span class="a-loz a-loz--success c-dp-search__form-tag" *ngIf="isAmendmentFlow && isItemCloseable">Newly Added</span>
    <div class="c-dp-search__form c-dp-search__form--hotel">
      <div class="c-dp-search__form-item" id="{{windowScrollEnable ? 'scroll': ''}}">
        <div class="m-form-item" (click)="scrollTo('#scroll')">
          <surf-type-ahead
            [id]="index"
            [label]="'destination*'"
            [dataList]="destinations"
            [filterType]="'strict'"
            [mappings]="['code', 'value']"
            [sortOrder]="'A2Z'"
            [selectedItem]="selectedDest"
            [paramName]="'ontotext'"
            [placeholder]="getHotelDestinationPlaceHolder()"
            [bindFocusOut]="false"
            [selectAllOnEnter]="false"
            [isOnto]="true"
            [url]="departureUrl"
            [isEnableGeoSearch]="GEO_ENABLE_FOR_HOTEL_SEARCH"
            [externalSources]="externalSources"
            [context]="'HTL'"
            [mergeOrder]="['GOOGLE', 'TBX']"
            [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
            [isDisabled]="freeze"
            [warningStyle]="'is-visited'"
            [warning]="destinationWarning"
            (onClickInside)="onClickInsideTypeAhead($event)"
            (onClickOutside)="onClickOutsideTypeAhead($event)"
            (returnedQuery)="returnedSearchQuery($event)"
            (returnedData)="returnDestination($event)"
            (keydown)="onKeyDown($event, 0)"
            [tabIndex]="1">

              <surf-type-ahead-content
                [typeAheadData]="filteredList"
                [available]="filteredList?.length > 0"
                (setItem)="selectDestination($event)"
                [enableKeyStrokeIgnore]="true"
              >

                <ng-container *ngFor="let item of filteredList;let i = index">
                  <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 20">
                    <div class="c-dp-search__list-item search-ineligible auto-country-item">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use *ngIf="item?.type == 'COUNTRY' || item?.type == 20" xlink:href="#country"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main">
                        <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                        <span class="s5 ts-muted ts-wet">
                          {{item?.type == 80 ?surfCriteriaUtil.getCity(item)?.name + ', ': '' }}
                          <span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>
                          {{surfCriteriaUtil.getCountry(item)}}
                        </span>
                      </div>
                      <span *ngIf="item?.type == 'COUNTRY' || item?.type == 20" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                    </div>
                  </surf-type-ahead-item>
                  <surf-type-ahead-item *ngIf="item?.type == 40 || item?.type == 80">
                    <div class="c-dp-search__list-item auto-city-item" (click)="selectDestination(item)">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                        <use *ngIf="item?.type == 'HOTEL' || item?.type == 80" xlink:href="#products--hotel"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main">
                        <span class="s4 ts-strong">{{item?.type == 40 ? item?.name : (item?.name | surfTextCase: 'title')}}</span>
                        <span class="s5 ts-muted ts-wet">
                          {{item?.type == 80 ?surfCriteriaUtil.getCity(item)?.name + ', ': '' }}
                          <span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>
                          {{surfCriteriaUtil.getCountry(item)}}
                        </span>
                      </div>
                      <span *ngIf="item?.type == 'CITY' || item?.type == 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                      <span *ngIf="item?.type == 'HOTEL' || item?.type == 80" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Hotel</span>
                    </div>
                  </surf-type-ahead-item>
                  <surf-type-ahead-item *ngIf="item?.type == 200">
                    <div
                      (click)="selectDestination(item)"
                      class="c-dp-search__list-item">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use
                          xlink:href="#map"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main">
                        <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                        <span class="s5 ts-muted ts-wet"><span *ngIf="item.secondaryName">{{item.secondaryName}}</span></span>
                      </div>
                      <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location</span>
                    </div>
                  </surf-type-ahead-item>
                  <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                    <ng-template [ngIf]="item?.type === 20">  <!--then iterate its children (CITIES) as sub components-->
                      <div (click)="selectDestination( addParentToLocationGroup(child, item) )" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-cities" [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use xlink:href="#map"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                          <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{getCountry(item)}}</span>
                        </div>
                      </div>
                    </ng-template>
                  </surf-type-ahead-item>
                </ng-container>
              </surf-type-ahead-content>
            </surf-type-ahead>
            <span *ngIf="destSearchInitiated && filteredList.length == 0" style="color: red;">No results found</span>
          </div>
        </div>


      <div class="c-dp-search__form-item c-dp-search__form-item--inverted">
        <surf-date-picker (click)="scrollTo('#scroll')"
                          [inputLabel]="datePickerLabel"
                          [(isFocused)]="datePickerFocus"
                          [currentDateOfTimeZone]="calendarMinDate"
                          [calendar_start_date]="(isForEpToDpConversion && index === 0) ? calendarMinDateForInitialEpeToDpConversionCriteria :getCalendarStartDate()"
                          [enablePreviousDates]="true"
                          [reset]="resetCalendar"
                          [check_availability]="true"
                          [range]="CALENDAR_RANGE"
                          [initial_dates]="initialDates"
                          [disabled]="freeze"
                          [warningStyle]="'is-visited'"
                          [warning1]="checkInWarning"
                          [warning2]="checkOutWarning"
                          (selected_duration)="getSelectedDuration($event)"
                          (selected_dates)="getSelectedDateRange($event)"
                          (keydown)="onKeyDown($event, 2)"
                          [tabIndex]="3"
                          [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : ''"
                          [isError]="validateDisabledDateSelection"
                          [validateDisabledDateSelection]="validateDisabledDateSelection">
        </surf-date-picker>

        </div>

        <div class="c-dp-search__form-item">
          <ng-container *ngIf="ENABLE_PAX_SPLIT && !freeze && isDPSearch && enablePaxSplit">
            <surf-multi-selector-drop-down [title]="'Split Passenger'"
                                           [dataList]="paxSplitArray"
                                           [inputDisplay]="paxSplitDisplayText"
                                           [isChipClosable]="true"
                                           [dropUP]="true"
                                           [inputType]="'Guest'"
                                           [isDisabled]="freeze"
                                           (selectionChange)="passengerSplitChange($event)"
                                           (emitClosedItem)="passengerSplitChange($event)"
            >
            </surf-multi-selector-drop-down>
          </ng-container>
        </div>
        <button class="a-link-btn a-link-btn--primary c-dp-search__add-link" (click)="enablePaxSplitDropdown()" *ngIf="ENABLE_PAX_SPLIT && isDPSearch && !freeze && !enablePaxSplit">
          Do you want to assign passengers?
          <span class="a-tool-tip a-tool-tip--top-left"
              [attr.data-tooltip]="'Will allow you to assign/split passengers into multiple components'">
              <svg class="a-icon u-rml">
              <use xlink:href="#info"></use>
            </svg>
          </span>
        </button>
      </div>

    </div>

    <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
      <svg class="a-icon">
        <use xlink:href="#close"></use>
      </svg>
    </div>

  </div>      <!--Hotel criteria content : visible in DP flow-->


  <div class="c-advance-search__btn-wrapper" *ngIf="!isDPSearch && showBtnWrapper">    <!--Button wrapper : hidden in DP-->
    <div class="c-advance-search__search-info" >
      <svg class="a-icon search-info--icon"  *ngIf = "!isServiceAddComponent">
        <use xlink:href="#info"></use>
      </svg>
      <span  *ngIf = "!isServiceAddComponent">Please select your <b>Destination</b> and <b>Check-in & Check-out dates</b> to continue search</span>
    </div>
    <div *ngIf="!isRefine" class="c-advance-search__search-action">
      <ng-container *ngIf="!isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-reset-btn" [tabIndex]="4">Reset Search</button>
      </ng-container>
      <ng-container *ngIf="isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-cancel-btn" [tabIndex]="4">Cancel</button>
      </ng-container>
      <button (click)="searchHotels(false)" class="a-btn a-btn--primary auto-search-btn" [tabIndex]="5"
              [ngClass]="{'is-disabled':!validateInputs()}">Search
      </button>
    </div>
    <div *ngIf="isRefine" class="c-advance-search__search-action">
      <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default auto-cancel-search-btn">Back to results</button>
      <button (click)="searchHotels(false)" class="a-btn a-btn--primary auto-refine-btn" [ngClass]="{'is-disabled':!validateInputs()}">Refine
        Search
      </button>
    </div>
  </div>                     <!--Button wrapper : hidden in DP-->

</div>

<ng-container *ngIf="REC_ENABLE_FOR_HOTEL_SEARCH && enableRecommendations">
  <surf-traveller-interests
    [enableTravellerInterestsModal]="enableTravellerInterestsModal"
    [extCodes]="holidayTypes"
    [selectedKeys]="selectedTravellerInterestsKeys ? selectedTravellerInterestsKeys : []"
    [resetCache]="!isRefine"
    (selectedInterests)="getSelectedTravellerInterests($event)"
    (emitModalClose)="closeTravellerInterestModal($event)"
  ></surf-traveller-interests>
</ng-container>
