<div class="c-advance-search">
  <div class="c-advance-search__content">
    <ng-container *ngIf="!isServiceAddComponent; else elseblock">
      <div class="c-advance-search__content-top">
        <button class="a-btn a-btn--default c-pkg-search__back-btn" (click)="closeCriteriaBox()">
          <svg class="a-icon u-mr">
            <use xlink:href="#previous"></use>
          </svg>
        </button>
        <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Search holiday packages for</h3>
        <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine your holiday package search for</h3>
        <div class="c-advance-search__guests">
          <div class="m-form-item auto-pax-count" (click)="scrollTo('#scroll')">
            <surf-pax-selection
              [paxSet]="defaultPaxSet"
              [b2bView]="true"
              [isMandatory]="true"
              [infoDisabled]="true"
              [inputWrapperView]="false"
              [roomViewSelected]="setRoomWise"
              [product]="'HTL'"
              [maxAdult]="MAX_ADULT"
              [minAdult]="MIN_ADULT"
              [maxPaxCount]="MAX_PAX_COUNT"
              [maxCabinPaxCount]="MAX_CABIN_PAX_COUNT"
              [maxInfants]="MAX_INFANTS"
              [maxInfantAge]="MAX_INFANT_AGE"
              [maxChildAge]="MAX_CHILD_AGE"
              [maxChildren]="MAX_CHILD"
              [maxTeenAge]="MAX_TEEN_AGE"
              [maxTeen]="MAX_TEEN"
              [maxCabins]="MAX_ROOMS"
              [roomAllocationVisible]="roomAllocationVisible"
              [parentScrollId]="windowScrollEnable ? 'scroll': null"
              [isTeenActive]="TEEN_PAX_ACTIVE"
              (paxSelection)="getGuestSelection($event)"
              (isRoomWiseSelected)="isRoomWiseSelected($event)"
              [tabIndex]="2">
            </surf-pax-selection>
          </div>
        </div>

        <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
          <svg class="a-icon o-modal__close-icon">
            <use xlink:href="#close"></use>
          </svg>
        </button>
      </div>
    </ng-container>

    <ng-template #elseblock>
      <div class="c-advance-search__content-top">
        <button class="a-btn a-btn--link o-modal__inner-close" (click)="resetInputs()">
          <svg class="a-icon o-modal__close-icon">
            <use xlink:href="#close"></use>
          </svg>
        </button>
        <h3 class="t2 c-advance-search__title">Define your search for</h3>
        <div class="c-advance-search__guests">
          <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
        </div>
      </div>
    </ng-template>

    <div class="c-dp-search__block c-dp-search__block--package is-selected">
      <div class="c-dp-search__action">
        <div class="c-dp-search__block-icon">
          <svg class="a-icon">
            <use xlink:href="#products--package"></use>
          </svg>
        </div>
      </div>

<!--      <div class="c-dp-search__block-label">-->
<!--        <svg class="a-icon u-rmr">-->
<!--          <use xlink:href="#products&#45;&#45;package"></use>-->
<!--        </svg>-->
<!--        <span class="lbl">Package</span>-->
<!--      </div>-->
      <div class="c-dp-search__form c-dp-search__form--package">
        <div class="c-dp-search__action-block">
          <div class="c-passenger-form__option-item">
            <surf-radiobutton
                    [group]="'group1'"
                    [selected]="packageType=='COMH'"
                    name="comh"
                    id="COMH"
                    (status)="handlePackageTypeChange($event)"
                    [lable]="'Complete Holiday'">
            </surf-radiobutton>
          </div>
          <div class="c-passenger-form__option-item " [ngClass]="{'u-disabled': disableItemArray.includes('EPKG')}">
            <surf-radiobutton
                    [group]="'group1'"
                    [lable]="'Elite Packages'"
                    name="epkg"
                    id="EPKG"
                    [selected]="packageType=='EPKG'"
                    (status)="handlePackageTypeChange($event)">
            </surf-radiobutton>
          </div>
        </div>


        <div class="c-dp-search__form-item" id="{{windowScrollEnable ? 'scroll1': ''}}">
          <div class="m-form-item" (click)="scrollTo('#scroll1')">
            <div class="m-form-field auto-destination">
              <label class="m-form-field__label">Destination</label>
              <surf-type-ahead
                [dataList]="destinations"
                [filterType]="'strict'"
                [mappings]="['code', 'value']"
                [sortOrder]="'A2Z'"
                [selectedItem]="selectedDest"
                [paramName]="'ontotext'"
                [placeholder]="'Any destination'"
                [bindFocusOut]="false"
                [selectAllOnEnter]="false"
                [autoFocus]="!isRefine"
                [isOnto]="true"
                [focusInput]="focusInit"
                [enableToolTip]="true"
                [url]="departureUrl"
                [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                (onClickInside)="onClickInsideTypeAhead($event, 'DEST')"
                (onClickOutside)="onClickOutsideTypeAhead($event, 'DEST')"
                (returnedQuery)="returnedSearchQuery($event, 'DEST')"
                (returnedData)="returnData($event, 'DEST')"
                (keydown)="onKeyPress($event, 0)"
                (focusout)="onClickOutsideTypeAhead($event, 'DEST')">

                <surf-type-ahead-content
                  [typeAheadData]="filteredList"
                  [available]="filteredList?.length > 0"
                  (setItem)="selectDestination($event, 'DEST')">

                  <surf-type-ahead-item (click)="selectDestination(item, 'DEST')" *ngFor="let item of filteredList">
                    <div class="c-dp-search__list-item" *ngIf="item?.type == 40 || item?.type == 20">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                        <use *ngIf="item?.type != 'CITY' && item?.type != 40" xlink:href="#country"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main auto-destinations-list">
                        <span class="s4 ts-strong">{{item?.type == 'CITY' || item?.type == 40 ? item?.name : (item?.name | surfTextCase: 'title')}}</span>
                        <span class="s5 ts-muted ts-wet">
                          {{item?.type == 80 ?surfCriteriaUtil.getCity(item)?.name + ', ': '' }}
                          <span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>
                          {{surfCriteriaUtil.getCountry(item)}}
                        </span>
                      </div>
                      <span *ngIf="item?.type == 'CITY' || item?.type == 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                      <span *ngIf="!(item?.type == 'CITY' || item?.type == 40)" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                    </div>
                  </surf-type-ahead-item>
                </surf-type-ahead-content>
              </surf-type-ahead>
              <span *ngIf="destSearchInitiated && filteredList.length == 0" style="color: red;">Please enter valid destination</span>
              <span class="m-form-field__error">error</span>
            </div>
          </div>
        </div>

        <div class="c-dp-search__form-group c-dp-search__form-group--2-1">
          <div class="c-dp-search__form-item c-dp-search__form-item--desc">
            <div class="m-form-item auto-dep-date" (click)="scrollTo('#scroll1')">
              <surf-date-picker
                [range]="calendarRange"
                [calendarType]="calendarView"
                [inputLabel]="monthCalendarLabel"
                [enablePreviousDates]="true"
                [currentDateOfTimeZone]="getCalendarMinDate()"
                [(isFocused)]="focusToDepartureMonth"
                [inputPlaceholder]="monthCalendarPlaceholder"
                [initial_dates]="initialDates"
                [reset]="resetCalendar"
                (keydown)="onKeyPress($event, 2)"
                [defaultActiveCalendar]="defaultActiveCalendar"
                (selected_months)="getMonthSelection($event)"
                (selected_dates)="getDateSelection($event)">
              </surf-date-picker>
            </div>
          </div>
          <div class="c-dp-search__form-item c-dp-search__form-item--desc">
            <div class="m-form-field surf-multi-input auto-duration">
              <div class="m-form-field__label-wrap">
                <label class="m-form-field__label">Duration</label>
              </div>
              <surf-multi-field-input
                [properties]="durationProperty"
                [componentValue]="durationValues"
                [error]="durationError"
                [msg]="'Invalid duration value'"
                (emitIdOnFocusIn)="handleInputFocusIn($event)"
                (emitCombinedOutEvent)="getDurationEx($event)">
              </surf-multi-field-input>
              <span class="m-form-field__error">error</span>
            </div>
          </div>
        </div>


        <div class="c-dp-search__form-item c-dp-search__form-item--desc">
          <div class="c-dp-search__form-item auto-cabin-class-dropdown">
            <div class="m-form-field">
              <surf-ul-drop-down
                      [label]="'Departure Airport'"
                      [labelStyle]="'m-form-field__label'"
                      [items]="departureAirportsList"
                      [firstDisplay]="currentDepartureAirport ? currentDepartureAirport : 'Select Airport'"
                      [triggerType]="'button'"
                      [listStyle]="'m-form-field__field'"
                      [required]="true"
                      [native]="true"
                      [select]=true
                      (singleObject)="handleDepartureAirport($event)"
              >
              </surf-ul-drop-down>

            </div>
          </div>
        </div>

        <div *ngIf="packageType=='COMH'" class="c-dp-search__form-group">
          <div class="c-dp-search__form-item auto-cabin-class-dropdown">
            <div class="m-form-field">
              <surf-ul-drop-down
                      [label]="'Cabin Class'"
                      [labelStyle]="'m-form-field__label'"
                      [items]="cabinClassList"
                      [firstDisplay]="currentCabinDisplay ? currentCabinDisplay : 'Pick your cabin'"
                      [triggerType]="'button'"
                      [listStyle]="'m-form-field__field'"
                      [required]="true"
                      [native]="true"
                      [select]=true
                      (singleObject)="handleCabinClass($event)"
              >
              </surf-ul-drop-down>
            </div>
          </div>
          <div class="c-dp-search__form-item auto-cabin-class-dropdown">
            <div class="m-form-field">
              <surf-ul-drop-down
                      [label]="'Occasion'"
                      [labelStyle]="'m-form-field__label'"
                      [items]="occasionList"
                      [firstDisplay]="currentOccasion ? currentOccasion : 'Are you celebrating?'"
                      [triggerType]="'button'"
                      [listStyle]="'m-form-field__field'"
                      [required]="true"
                      [native]="true"
                      [select]=true
                      (singleObject)="handleOccasion($event)"
              >
              </surf-ul-drop-down>
            </div>
          </div>
        </div>


        <div *ngIf="packageType=='EPKG'" class="c-dp-search__form-group ">
          <div class="c-dp-search__form-item c-dp-search__form-item--desc">
            <div class="m-form-item auto-pkg-code">
              <div class="m-form-field">
                <surf-input
                  [disabled]="false"
                  [intype]="'text'"
                  [inputVal]="packageCriteria.pkgCode"
                  [label]="'Package Code'"
                  (value)="setPackageCode($event)"
                >
                </surf-input>
                <span class="m-form-field__error">error</span>
              </div>
            </div>
          </div>
          <div class="c-dp-search__form-item c-dp-search__form-item--desc">
            <div class="m-form-field surf-multi-input" [ngClass]="{'has-error': minMaxPriceError}">
              <div class="m-form-field__label-wrap">
                <label class="m-form-field__label">Min / Max Package Price
                  <span class="a-tool-tip m-form-field__tool-tip"
                        [attr.data-tooltip]="minMaxToolTipText">
                    <svg
                      class="a-icon" xmlns="http://www.w3.org/2000/svg">
                      <use xlink:href="#info"></use>
                    </svg>
                  </span>
                </label>
              </div>
              <div class="m-form-field__field">
                <div class="m-form-field__field-item auto-min-price">
                  <surf-input
                    [disabled]="false"
                    [wrapperView]="false"
                    [intype]="'text'"
                    [maxlength]="10"
                    [placeholder]="'Min price'"
                    [inputVal]="minPriceDisplayValue"
                    (dynamicVal)="setMinMaxPrice($event, EPPriceFilterType.MIN_PRICE)"
                  >
                  </surf-input>
                </div>
                <span class="m-form-field__separator"></span>
                <div class="m-form-field__field-item auto-max-price">
                  <surf-input
                    [disabled]="false"
                    [wrapperView]="false"
                    [placeholder]="'Max price'"
                    [intype]="'text'"
                    [maxlength]="10"
                    [inputVal]="maxPriceDisplayValue"
                    (dynamicVal)="setMinMaxPrice($event, EPPriceFilterType.MAX_PRICE)"
                  >
                  </surf-input>
                </div>

              </div>
              <span class="m-form-field__error">{{minMaxPriceErrorMsg}}</span>
            </div>
          </div>
        </div>

<!--        <ng-container *ngIf="REC_ENABLE_FOR_EP_SEARCH; else elseBlock">-->
<!--          <div class="c-dp-search__action-block" *ngIf="selectedTravellerInterests.length === 0">-->
<!--            <div class="c-dp-search__action-text">-->
<!--              <svg class="a-icon u-mr-xxs u-x1.25">-->
<!--                <use xlink:href="#info"></use>-->
<!--              </svg>-->
<!--              <span>Traveller preferences will make the results more personalized to your customer</span>-->
<!--            </div>-->
<!--            <button class="a-link-btn a-link-btn&#45;&#45;secondary c-dp-search__action-link" (click)="onAddOrUpdateTravellerInterests()">Add-->
<!--              Traveller Interests-->
<!--            </button>-->
<!--          </div>-->

<!--          <div class="c-dp-search__action-block" *ngIf="selectedTravellerInterests.length > 0">-->
<!--            <div class="c-dp-search__action-text">-->
<!--              <svg class="a-icon u-mr-xxs u-x1.25 tc-success">-->
<!--                <use xlink:href="#checked"></use>-->
<!--              </svg>-->
<!--              <span>-->
<!--                <ng-container *ngFor="let travellerInterest of selectedTravellerInterests; let i = index">-->
<!--                  {{selectedTravellerInterests.length - 1 == i ? travellerInterest?.name : travellerInterest?.name + ', '}}-->
<!--                </ng-container> selected-->
<!--              </span>-->
<!--            </div>-->
<!--            <button class="a-link-btn a-link-btn&#45;&#45;secondary c-dp-search__action-link" (click)="onAddOrUpdateTravellerInterests()">Change-->
<!--              Traveller Interests-->
<!--            </button>-->
<!--          </div>-->
<!--        </ng-container>-->

<!--        <ng-template #elseBlock>-->
<!--          <div class="c-dp-search__types">-->
<!--            <ng-container *ngIf="holidayTypes && holidayTypes.length >0">-->
<!--              <span class="c-dp-search__type-title u-hr">Holiday Types</span>-->

<!--              <div class="c-search-by-tags">-->
<!--                <ng-template ngFor let-type [ngForOf]="holidayTypes" let-i="index">-->
<!--                <span-->
<!--                  *ngIf="i < EP_HOLIDAY_TYPES_COUNT"-->
<!--                  surfTooltip-->
<!--                  [tooltipPosition]="'bottom'"-->
<!--                  [attr.title]="type?.description?type.description:type.name"-->
<!--                  class="a-loz a-loz&#45;&#45;outline/mono c-dp-search__type"-->
<!--                  [ngClass]="{'is-selected': type.selected}"-->
<!--                  (click)="getSelectedHolidayType(type)"-->
<!--                  [id]="'holiday-type-'+i"> {{type.name}}</span>-->
<!--                </ng-template>-->
<!--                <span *ngIf="holidayTypes?.length > EP_HOLIDAY_TYPES_COUNT "-->
<!--                  class="a-loz a-loz&#45;&#45;neutral/light c-dp-search__type"-->
<!--                  (click)="loadMoreHolidayTypes()">+ More Items</span>-->
<!--              </div>-->
<!--            </ng-container>-->

<!--          </div>-->
<!--        </ng-template>-->

        <!-- This section is not implemented in the new design. If needed, introduce new Html wrappers along with the existing surf components -->
        <div class="c-pkg-search__form-group" *ngIf="false">
          <div class="c-pkg-search__form-item">
            <div class="m-form-field">
              <label class="m-form-field__label">Select Type</label>
              <surf-ul-drop-down   ui-data-component="country"
                                   [firstDisplay]="daysFirstDisplay"
                                   [native]="true"
                                   [items]="experienceTypesList"
                                   [select]=true
                                   [listStyle]="'container'"
                                   (singleObject)="setExperienceType($event)"
              >
              </surf-ul-drop-down>
              <span class="m-form-field__error">error</span>
            </div>
          </div>
          <div class="c-pkg-search__form-item">
            <div class="m-form-field">
              <label class="m-form-field__label">Star Rating</label>
              <surf-ul-drop-down   ui-data-component="country"
                                   [firstDisplay]="daysFirstDisplay"
                                   [native]="true"
                                   [items]="packageSearchDays"
                                   [select]=true
                                   [listStyle]="'container'"
                                   (singleObject)="getDurationEx($event)"
              >
              </surf-ul-drop-down>
              <span class="m-form-field__error">error</span>
            </div>
          </div>
          <div class="c-pkg-search__form-item">
            <div class="m-form-field">
              <label class="m-form-field__label">Holiday Label</label>
              <surf-input
                [disabled]="false"
                [inputVal]="packageCriteria.holidayLabel"
                (value)="setHolidayLabel($event)"
              >
              </surf-input>
              <span class="m-form-field__error">error</span>
            </div>
          </div>

        </div>

      </div>

    </div>


    <div class="c-advance-search__btn-wrapper">
      <div class="c-advance-search__search-info">
        <svg class="a-icon search-info--icon"><use xlink:href="#info"></use></svg>
        <span>Please select at least your <strong>Departure Month/Date</strong> to continue the search.</span>
      </div>
      <div class="c-advance-search__search-action" *ngIf="!isRefine">
        <ng-container *ngIf="isServiceAddComponent">
          <button (click)="resetInputs()" class="a-btn a-btn--default auto-btn-reset">Cancel</button>
        </ng-container>
        <ng-container *ngIf="!isServiceAddComponent">
          <button (click)="resetInputs()" class="a-btn a-btn--default auto-btn-reset">Reset Search</button>
        </ng-container>
        <button (click)="searchHoliday(false)" class="a-btn a-btn--primary auto-btn-search" [ngClass]="{'is-disabled':!validateInputs()}">Search</button>
      </div>
      <div class="c-advance-search__search-action" *ngIf="isRefine">
        <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default auto-btn-cancel">Back to results</button>
        <button (click)="searchHoliday(false)" class="a-btn a-btn--primary auto-btn-refine" [ngClass]="{'is-disabled':!validateInputs()}">Refine Holiday</button>
      </div>
    </div>

  </div>
</div>

<div class="o-modal" [ngClass]="{'is-active': showLoadMoreHolidayTypes}" id="holiday-types-id-modal">

  <div class="o-modal__content c-search-by-tags_modal">

    <div class="o-modal__body">

      <h3 class="t2">Select holiday types</h3>
      <div class="text-muted u-mb">You can select multiple types to define your holiday</div>

      <div class="c-search-by-tags">
        <ng-template ngFor let-type [ngForOf]="holidayTypes" let-i="index">
          <span
            surfTooltip
            [attr.title]="type?.description?type.description:type.name"
            [ngClass]="type.selected?'a-loz a-loz--primary c-search-by-tags__item' :'a-loz a-loz--light c-search-by-tags__item'"
                (click)="getSelectedHolidayType(type)" [id]="'holiday-type-'+i"> {{type.name}}</span>
        </ng-template>
      </div>

      <div class="o-modal__footer">
        <button class="a-btn a-btn--trinity u-mr" (click)="handleCancelHolidayType()">Cancel</button>
        <button class="a-btn a-btn--primary" (click)="handleAddHolidayType()">Add</button>
      </div>
    </div>
  </div>

</div>

<ng-container *ngIf="REC_ENABLE_FOR_EP_SEARCH">
<!--  <surf-traveller-interests-->
<!--    [enableTravellerInterestsModal]="enableTravellerInterestsModal"-->
<!--    [extCodes]="holidayTypes"-->
<!--    [selectedKeys]="selectedTravellerInterestsKeys ? selectedTravellerInterestsKeys : []"-->
<!--    [resetCache]="!isRefine"-->
<!--    (selectedInterests)="getSelectedTravellerInterests($event)"-->
<!--    (emitModalClose)="closeTravellerInterestModal($event)"-->
<!--  ></surf-traveller-interests>-->
</ng-container>
