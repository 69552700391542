<div [ngClass]="{'c-advance-search__content': !isDPSearch}">
  <ng-container *ngIf="!isServiceAddComponent; else elseblock">
  <div class="c-advance-search__content-top" *ngIf="!isDPSearch">
    <button class="a-btn a-btn--link c-pkg-search__back-btn" (click)="closeCriteriaBox()">
      <svg class="a-icon u-mr">
        <use xlink:href="#previous"></use>
      </svg>
    </button>

    <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Search cars for</h3>
    <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine your car search</h3>
    <div class="c-advance-search__guests">
      <div class="m-form-item auto-car-pax" (click)="scrollTo('#scroll')">
        <surf-pax-selection
          [(isFocused)]="guestComponentFocus"
          [paxSet]="defaultPaxSet"
          [product]="'FLT'"
          [inputWrapperView]="false"
          [maxAdult]="CAR_MAX_ADULT"
          [minAdult]="CAR_MIN_ADULT"
          [maxPaxCount]="CAR_MAX_PAX_COUNT"
          [maxCabinPaxCount]="CAR_MAX_CABIN_PAX_COUNT"
          [maxChildren]="CAR_MAX_CHILD"
          [maxTeen]="CAR_MAX_TEEN"
          [maxInfants]="CAR_MAX_INFANTS"
          [maxInfantAge]="MAX_INFANT_AGE"
          [maxChildAge]="MAX_CHILD_AGE"
          [maxTeenAge]="MAX_TEEN_AGE"
          [maxCabins]="MAX_ROOMS"
          [parentScrollId]="windowScrollEnable ? 'scroll': null"
          [b2bView]="true"
          [roomViewSelected]="false"
          [infoDisabled]="true"
          [isTeenActive]="TEEN_PAX_ACTIVE"
          (paxSelection)="getPassengerSelection($event)"
          [tabIndex]="2"
          (currentOption)="getCurrentOption($event)"
          (keydown)="onKeyDown($event, 1)">
        </surf-pax-selection>
      </div>
    </div>
    <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
      <svg class="a-icon o-modal__close-icon">
        <use xlink:href="#close"></use>
      </svg>
    </button>
  </div> <!--Content top : hidden in DP-->
  </ng-container>
  <ng-template #elseblock>
    <div class="c-advance-search__content-top">
      <button class="a-btn a-btn--link o-modal__inner-close" (click)="resetInputs()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
    <h3 class="t2 c-advance-search__title">Define your search for</h3>
<!--    <span class="s5">Existing passenger combination will be applied to the newly adding component</span>-->
    <div class="c-advance-search__guests">
    <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
    </div>
    </div>
  </ng-template>
  <div class="c-advance-search__form" *ngIf="!isDPSearch">

    <div class="c-dp-search">
      <ng-container *ngIf="!isServiceAddComponent">
      <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}"
                       [msg]="msg" [totalItemCount]="1" [isDPSearch]="false" [isIntro]="false" [isRefine]="isRefine"
                       [disableDPSwitch]="DISABLE_DP_SWITCH"
                       [isDisabled]="DISABLE_DP_SWITCH"
                       (showBarPanelToggle)="moveToDP('pre')"></surf-dp-add-bar>
      </ng-container>
      <div class="c-dp-search__block c-dp-search__block--car" [ngClass]="{'is-selected': (isSelected || !isDPSearch), 'valid-criteria': validateInputs() && isDPSearch}"
           (click)="selectComponent()" (clickOutside)="deselectComponent()">
        <!--  is-selected t-primary-->
        <div class="c-dp-search__action">
          <div class="c-dp-search__block-icon">
            <svg class="a-icon">
              <use xlink:href="#products--car"></use>
            </svg>
          </div>
        </div>

        <div class="c-dp-search__block-label">
          <svg class="a-icon u-rmr">
            <use xlink:href="#products--car"></use>
          </svg>
          <span class="lbl">Car</span>
        </div>

        <div class="c-dp-search__form c-dp-search__form--car" >

          <div class="c-dp-search__form-item c-dp-search__form-item--location" [class.c-dp-search__form-item--extended]="CSG_VAN_CRITERIA_ENABLE">
            <div class="c-dp-search__form-item" id="{{windowScrollEnable ? 'scroll': ''}}">
              <div class="m-form-item">
                <div class="m-form-item__input auto-locations">
                  <surf-multi-field-input
                    (click)="scrollTo('#scroll')"
                    [enableToolTip]="true"
                    [typeAheadAvailable]="true"
                    [useEnterFocus]="true"
                    [inputFocus]="true"
                    [focusInit]="true"
                    (isLastElement)="ShowLastElement($event)"
                    [properties]="pickUpDropOffProperties"
                    [componentValue]="pickUpDropOffValues"
                    [onChange]="true"
                    [msg]="typeAheadErrorMsg"
                    [isSecondInput]="isSecondInputError"
                    [userCaption]="userCaption"
                    [error]="isFilterListError"
                    [focusingIndex] = "focusingIndex"
                    [showTypeAheadContent]="showTypeAheadContent"
                    [warningStyle]="'is-visited'"
                    (keyup)="onKeyDown($event, 0)"
                    (emitIdOnFocusIn)="handleFocusIn($event)"
                    (emitFilteredList)="getDestinations($event)"
                    (emitOnChangeEvent)="clearFields($event)"
                  >

                    <surf-type-ahead-content
                      *ngIf="enableDropOffRecommendation && showDropOffRecommendations && !isDPSearch"
                      [typeAheadData]="recommendingDropOffData"
                      [typeAheadInfoText]="'Suggested drop off for your pickup'"
                      [isPressEsc]="returnedProperty && returnedProperty.id === 'PICKUP' ? pressEscPickup : returnedProperty && returnedProperty.id === 'DROP' ? pressEscPickup :null"
                      [available]="recommendingDropOffData?.length > 0"
                      [selectedItem]="returnedProperty && returnedProperty.id === 'PICKUP' ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === 'DROP' ? previousSelectedDropCriteria :null"
                      (setItem)="handleSelection($event, true)"
                      [enableKeyStrokeIgnore]="true"
                    >
                      <ng-container *ngFor="let item of recommendingDropOffData;let i = index">
                        <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 40 || item?.type == 140 ">
                          <!-- ng content here-->
                          <div
                            class="c-dp-search__list-item search-ineligible auto-city-item"
                          ><!--item?.type == 60 || item?.type == 70-->
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                              <use
                                *ngIf="item?.type == 'CAR_LOCATION_GROUP' || item?.type == 140"
                                xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                            </div>
                            <span
                              *ngIf="item?.type == 'CITY' || item?.type == 40"
                              class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                            <span
                              *ngIf="item?.type == 'CAR_LOCATION_GROUP' || item?.type == 140"
                              class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location Group</span>
                          </div>

                        </surf-type-ahead-item>
                        <surf-type-ahead-item *ngIf="item?.type == 70 || item?.type == 90">
                          <div
                            (click)="handleSelection(item, true)"
                            class="c-dp-search__list-item">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use
                                xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                            </div>
                            <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Car Location</span>
                          </div>
                        </surf-type-ahead-item>
                        <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                          <ng-template [ngIf]="item?.type == 40">  <!--then iterate its children (AIRPORTS) as sub components-->
                            <div (click)="handleSelection( addParentToLocationGroup(child, item), true )" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-airports" [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                              <svg class="a-icon c-dp-search__list-item-icon">
                                <use xlink:href="#map"></use>
                              </svg>
                              <div class="m-info-set c-dp-search__list-item-main">
                                <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                                <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{getCountry(item)}}</span>
                              </div>
                            </div>
                          </ng-template>

                        </surf-type-ahead-item>
                      </ng-container>

                    </surf-type-ahead-content>

                    <surf-type-ahead-content
                      *ngIf="showTypeAheadContent"
                      [typeAheadData]="filteredDestinations"
                      [isPressEsc]="returnedProperty && returnedProperty.id === 'PICKUP' ? pressEscPickup : returnedProperty && returnedProperty.id === 'DROP' ? pressEscPickup :null"
                      [selectedItem]="returnedProperty && returnedProperty.id === 'PICKUP' ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === 'DROP' ? previousSelectedDropCriteria :null"
                      [available]="filteredDestinations?.length > 0"
                      (setItem)="handleSelection($event)"
                      [enableKeyStrokeIgnore]="true"
                    >
                      <ng-container *ngFor="let item of filteredDestinations;let i = index">
                        <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 40 || item?.type == 140 ">
                          <!-- ng content here-->
                          <div
                            class="c-dp-search__list-item search-ineligible auto-city-item"
                            ><!--item?.type == 60 || item?.type == 70-->
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                              <use
                                *ngIf="item?.type == 'CAR_LOCATION_GROUP' || item?.type == 140"
                                xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                            </div>
                            <span
                              *ngIf="item?.type == 'CITY' || item?.type == 40"
                              class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                            <span
                              *ngIf="item?.type == 'CAR_LOCATION_GROUP' || item?.type == 140"
                              class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location Group</span>
                          </div>

                        </surf-type-ahead-item>
                        <surf-type-ahead-item *ngIf="item?.type == 70 || item?.type == 90">
                          <div
                            (click)="handleSelection(item)"
                            class="c-dp-search__list-item">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use
                                xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                            </div>
                            <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Car Location</span>
                          </div>
                        </surf-type-ahead-item>
                        <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                          <ng-template [ngIf]="item?.type == 40">  <!--then iterate its children (AIRPORTS) as sub components-->
                            <div (click)="handleSelection( addParentToLocationGroup(child, item) )" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-airports" [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                              <svg class="a-icon c-dp-search__list-item-icon">
                                <use xlink:href="#map"></use>
                              </svg>
                              <div class="m-info-set c-dp-search__list-item-main">
                                <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                                <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{getCountry(item)}}</span>
                              </div>
                            </div>
                          </ng-template>

                        </surf-type-ahead-item>
                      </ng-container>

                    </surf-type-ahead-content>

                  </surf-multi-field-input>
                </div>
              </div>

            </div>
          </div>

          <div class="c-dp-search__form-item auto-cabin-class-dropdown">
<!--            <div class="m-form-field">-->
<!--              <surf-ul-drop-down-->
<!--                [label]="'Type'"-->
<!--                [labelStyle]="'m-form-field__label'"-->
<!--                [items]="carContractTypes"-->
<!--                [placeholder]="'Class'"-->
<!--                [triggerType]="'button'"-->
<!--                (click) = "handleContractTypeDropDownClick($event)"-->
<!--                [listStyle]="'m-form-field__field'"-->
<!--                [firstDisplay]="selectedContractType"-->
<!--                [required]="true"-->
<!--                [native]="true"-->
<!--                [select]=true-->
<!--                [reset]="false"-->
<!--                [disabled]="false"-->
<!--                [warningStyle]="'is-visited'"-->
<!--                [warning]="typeWarning"-->
<!--                (singleObject)="handleContractTypeSelection($event)">-->
<!--              </surf-ul-drop-down>-->
<!--            </div>-->
            <div class="c-dp-search__form-item">
              <div class="m-form-item">
                <div class="m-form-item__input">
                  <surf-ul-drop-down
                    [showToolTip]="true"
                    [toolTipIcon]="'info'"
                    [toolTipText]="driverAgeToolTipText"
                    [label]="this.DRIVER_AGE_LABEL"
                    [items]="driverValidAgesDropDownList"
                    [native]="true"
                    [select]=true
                    [warningStyle]="'is-visited'"
                    [warning]="ageWarning"
                    [firstDisplay]="selectedDriverAge"
                    [dropdownId]="'auto-driver-age'"
                    (singleObject)="selectDriverAge($event)">
                  </surf-ul-drop-down>
                </div>
              </div>
            </div>
          </div>

          <div class="c-dp-search__form-group c-dp-search__form-group--extended">
            <div class="c-dp-search__form-item auto-from-date">
              <surf-date-picker
                (click)="scrollTo('#scroll')"
                [single_calendar]="true"
                [inputLabel]="'Pick-up date & time*'"
                [check_availability]="false"
                [isError]="isInvalidTime || validateDisabledDateSelection"
                [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : 'Invalid Time'"
                [hideErrorMsg]="hideErrorMsg"
                [skipInnerInputErrorValidations]="skipInnerInputErrorValidations"
                [validateDisabledDateSelection]="validateDisabledDateSelection"
                [enablePreviousDates]="true"
                [currentDateOfTimeZone]="calendarMinDate"
                [reset]="resetPickupCalendar"
                [isFocused]="firstDatePickerFocus"
                (selected_dates)="getSelectedDateTime($event, true)"
                [initial_dates]="pickUpInitialDate"
                [timeEnable]="true"
                [timeInputs]="false"
                [useDefaultLocale]="false"
                [selectedMeridian]="defaultPickUpMeridian"
                [selectedMin]="defaultPickUpTimesMinutes"
                [selectedHr]="defaultPickUpTimesHours"
                [warningStyle]="'is-visited'"
                [warning1]="pickUpDateWarning"
              ></surf-date-picker>
            </div>
            <div class="c-dp-search__form-item c-dp-search__form-item--inverted auto-to-date">
              <surf-date-picker
                (click)="scrollTo('#scroll')"
                [single_calendar]="true"
                [inputLabel]="'Drop-off date & time*'"
                [check_availability]="false"
                [reset]="resetdropCalendar"
                [calendarStartDate]="selectedPickupDate"
                [initial_dates]="dropOffInitialDate"
                [isError]="isInvalidTime || validateDisabledDateSelection || isPickUpTimeLaterThanDropOffTime"
                [invalidErrorMsg]="getDropOffInvalidErrorMessage()"
                [hideErrorMsg]="hideErrorMsg && !isPickUpTimeLaterThanDropOffTime"
                [skipInnerInputErrorValidations]="skipInnerInputErrorValidations"
                [validateDisabledDateSelection]="validateDisabledDateSelection"
                [isFocused]="secondDatePickerFocus"
                (selected_dates)="getSelectedDateTime($event, false)"
                [timeEnable]="true"
                [timeInputs]="false"
                [useDefaultLocale]="false"
                [selectedMeridian]="defaultDropOffMeridian"
                [selectedMin]="defaultDropOffTimesMinutes"
                [selectedHr]="defaultDropOffTimesHours"
                [warningStyle]="'is-visited'"
                [warning1]="dropOffDateWarning"
                [enablePreviousDates]="true"
                [currentDateOfTimeZone]="dropOffCurrentDateOfTimeZone"
              ></surf-date-picker>
            </div>
          </div>



        </div>

        <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
          <svg class="a-icon">
            <use xlink:href="#close"></use>
          </svg>
        </div>


      </div>
      <ng-container *ngIf="!isServiceAddComponent">
      <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}" [msg]="msg" [totalItemCount]="1"
                       [isDPSearch]="false" [isIntro]="false" [isRefine]="isRefine"
                       [disableDPSwitch]="DISABLE_DP_SWITCH"
                       [isDisabled]="DISABLE_DP_SWITCH"
                       (showBarPanelToggle)="moveToDP('post')"></surf-dp-add-bar>
      </ng-container>
    </div>

  </div> <!--Car criteria content : visible in Component flow-->


  <div class="c-dp-search__block c-dp-search__block--car" [ngClass]="{'is-selected': (isSelected || !isDPSearch), 'valid-criteria': (validateInputs() && isDPSearch)}" *ngIf="isDPSearch"
       (click)="selectComponent()" (clickOutside)="deselectComponent()">
    <!--  is-selected t-primary-->
    <div class="c-dp-search__action">
      <label *ngIf="isAmendmentFlow && !isItemCloseable" class="a-checkbox c-dp-search__action-check">
        <input type="checkbox" class="a-checkbox__check" (change)="checkValue($event)" [checked]="!freeze">
        <span class="a-checkbox__label"></span>
      </label>
      <div class="c-dp-search__block-icon">
        <svg class="a-icon">
          <use xlink:href="#products--car"></use>
        </svg>
      </div>
    </div>

    <div class="c-dp-search__block-label">
      <svg class="a-icon u-rmr">
        <use xlink:href="#products--car"></use>
      </svg>
      <span class="lbl">Car</span>
    </div>
    <div class="c-dp-search__form-wrap">
    <span class="a-loz a-loz--success c-dp-search__form-tag" *ngIf="isAmendmentFlow && isItemCloseable">Newly Added</span>
    <div class="c-dp-search__form c-dp-search__form--car" >
      <div class="c-dp-search__form-item c-dp-search__form-item--location" [class.c-dp-search__form-item--extended]="CSG_VAN_CRITERIA_ENABLE">
        <div class="c-dp-search__form-item" id="{{windowScrollEnable ? 'scroll': ''}}">
          <div class="m-form-item">
            <div class="m-form-item__input auto-locations">
              <surf-multi-field-input
                (click)="scrollTo('#scroll')"
                [enableToolTip]="true"
                [typeAheadAvailable]="true"
                [useEnterFocus]="true"
                [inputFocus]="true"
                [focusInit]="true"
                (isLastElement)="ShowLastElement($event)"
                [properties]="pickUpDropOffProperties"
                [componentValue]="pickUpDropOffValues"
                [onChange]="false"
                [msg]="typeAheadErrorMsg"
                [userCaption]="userCaption"
                [error]="isFilterListError"
                [showTypeAheadContent]="showTypeAheadContent"
                [disabled]="freeze"
                [warningStyle]="'is-visited'"
                (keyup)="onKeyDown($event, 0)"
                (emitIdOnFocusOut)="emitOutside()"
                (emitFilteredList)="getDestinations($event)"
              >

                  <surf-type-ahead-content
                    *ngIf="showTypeAheadContent"
                    [typeAheadData]="filteredDestinations"
                    [isPressEsc]="returnedProperty && returnedProperty.id === 'PICKUP' ? pressEscPickup : returnedProperty && returnedProperty.id === 'DROP' ? pressEscPickup :null"
                    [selectedItem]="returnedProperty && returnedProperty.id === 'PICKUP' ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === 'DROP' ? previousSelectedDropCriteria :null"
                    [available]="filteredDestinations?.length > 0"
                    (setItem)="handleSelection($event)"
                    [enableKeyStrokeIgnore]="true"
                  >
                    <ng-container *ngFor="let item of filteredDestinations;let i = index">
                      <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 40 || item?.type == 140">
                        <!-- ng content here-->
                        <div
                          class="c-dp-search__list-item search-ineligible auto-location-list"
                          ><!--item?.type == 60 || item?.type == 70-->
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                            <use
                              *ngIf="item?.type == 'CAR_LOCATION_GROUP' || item?.type == 140"
                              xlink:href="#map"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                            <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                          </div>
                          <span
                            *ngIf="item?.type == 'CITY' || item?.type == 40"
                            class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                          <span
                            *ngIf="item?.type == 'CAR_LOCATION_GROUP' || item?.type == 140"
                            class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location Group</span>
                        </div>

                      </surf-type-ahead-item>
                      <surf-type-ahead-item *ngIf="item?.type == 70 || item?.type == 90">
                        <div
                          (click)="handleSelection(item)"
                          class="c-dp-search__list-item">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use
                              xlink:href="#map"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                            <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                          </div>
                          <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Car Location</span>
                        </div>
                      </surf-type-ahead-item>
                      <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                        <ng-template [ngIf]="item?.type == 40">  <!--then iterate its children (AIRPORTS) as sub components-->
                          <div (click)="handleSelection( addParentToLocationGroup(child, item) )" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-airports" [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{getCountry(item)}}</span>
                            </div>
                          </div>
                        </ng-template>

                      </surf-type-ahead-item>
                    </ng-container>

                  </surf-type-ahead-content>

                </surf-multi-field-input>
              </div>
            </div>

          </div>
        </div>

<!--      <div class="c-dp-search__form-item auto-cabin-class-dropdown" *ngIf="CSG_VAN_CRITERIA_ENABLE">-->
<!--        <div class="m-form-field">-->
<!--          <surf-ul-drop-down-->
<!--            [label]="'Type'"-->
<!--            [labelStyle]="'m-form-field__label'"-->
<!--            [items]="carContractTypes"-->
<!--            [placeholder]="'Class'"-->
<!--            [triggerType]="'button'"-->
<!--            (click) = "handleContractTypeDropDownClick($event)"-->
<!--            [listStyle]="'m-form-field__field'"-->
<!--            [firstDisplay]="selectedContractType"-->
<!--            [required]="true"-->
<!--            [native]="true"-->
<!--            [select]=true-->
<!--            [reset]="false"-->
<!--            [disabled]="freeze"-->
<!--            [warningStyle]="'is-visited'"-->
<!--            [warning]="typeWarning"-->
<!--            (singleObject)="handleContractTypeSelection($event)">-->
<!--          </surf-ul-drop-down>-->
<!--        </div>-->
      <div class="c-dp-search__form-item auto-cabin-class-dropdown">
      <div class="c-dp-search__form-item auto-driver-age">
          <div class="m-form-item">
            <div class="m-form-item__input">
              <surf-ul-drop-down
                [showToolTip]="true"
                [toolTipIcon]="'info'"
                [toolTipText]="driverAgeToolTipText"
                [label]="this.DRIVER_AGE_LABEL"
                [items]="driverValidAgesDropDownList"
                [native]="true"
                [select]=true
                [disabled]="freeze"
                [warningStyle]="'is-visited'"
                [warning]="ageWarning"
                [firstDisplay]="selectedDriverAge"
                [dropdownId]="'auto-driver-age'"
                (singleObject)="selectDriverAge($event)">
              </surf-ul-drop-down>
            </div>
          </div>
        </div>
      </div>


      <div class="c-dp-search__form-group c-dp-search__form-group--extended">
        <div class="c-dp-search__form-item auto-from-date">
          <surf-date-picker
            (click)="scrollTo('#scroll')"
            [single_calendar]="true"
            [inputLabel]="'Pick-up date & time*'"
            [check_availability]="false"
            [isError]="isInvalidTime || validateDisabledDateSelection"
            [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : 'Invalid Time'"
            [hideErrorMsg]="hideErrorMsg"
            [skipInnerInputErrorValidations]="skipInnerInputErrorValidations"
            [validateDisabledDateSelection]="validateDisabledDateSelection"
            [reset]="resetPickupCalendar"
            [enablePreviousDates]="true"
            [currentDateOfTimeZone]="calendarMinDate"
            (selected_dates)="getSelectedDateTime($event, true)"
            [initial_dates]="pickUpInitialDate"
            [timeEnable]="true"
            [timeInputs]="false"
            [selectedMeridian]="defaultPickUpMeridian"
            [selectedMin]="defaultPickUpTimesMinutes"
            [selectedHr]="defaultPickUpTimesHours"
            [disabled]="freeze"
            [warningStyle]="'is-visited'"
            [warning1]="pickUpDateWarning"
            [calendarStartDate]="dpCalEnableDate"
          ></surf-date-picker>

        </div>
        <div class="c-dp-search__form-item c-dp-search__form-item--inverted auto-to-date">
          <surf-date-picker
            (click)="scrollTo('#scroll')"
            [single_calendar]="true"
            [inputLabel]="'Drop-off date & time*'"
            [check_availability]="false"
            [reset]="resetdropCalendar"
            [calendarStartDate]="selectedPickupDate"
            [initial_dates]="dropOffInitialDate"
            [isError]="isInvalidTime || validateDisabledDateSelection || isPickUpTimeLaterThanDropOffTime"
            [invalidErrorMsg]="getDropOffInvalidErrorMessage()"
            [hideErrorMsg]="hideErrorMsg && !isPickUpTimeLaterThanDropOffTime"
            [skipInnerInputErrorValidations]="skipInnerInputErrorValidations"
            [validateDisabledDateSelection]="validateDisabledDateSelection"
            [isFocused]="secondDatePickerFocus"
            (selected_dates)="getSelectedDateTime($event, false)"
            [timeEnable]="true"
            [timeInputs]="false"
            [selectedMeridian]="defaultDropOffMeridian"
            [selectedMin]="defaultDropOffTimesMinutes"
            [selectedHr]="defaultDropOffTimesHours"
            [disabled]="freeze"
            [warningStyle]="'is-visited'"
            [warning1]="dropOffDateWarning"
            [enablePreviousDates]="true"
            [currentDateOfTimeZone]="dropOffCurrentDateOfTimeZone"
          ></surf-date-picker>

        </div>

      </div>

        <div class="c-dp-search__form-item">
          <ng-container *ngIf="ENABLE_PAX_SPLIT && !freeze && isDPSearch && enablePaxSplit">
            <surf-multi-selector-drop-down [title]="'Split Passenger'"
                                           [dataList]="paxSplitArray"
                                           [inputDisplay]="paxSplitDisplayText"
                                           [isChipClosable]="true"
                                           [dropUP]="true"
                                           [inputType]="'Guest'"
                                           [isDisabled]="freeze"
                                           (selectionChange)="passengerSplitChange($event)"
                                           (emitClosedItem)="passengerSplitChange($event)"
            >
            </surf-multi-selector-drop-down>
          </ng-container>
        </div>
        <button class="a-link-btn a-link-btn--primary c-dp-search__add-link" (click)="enablePaxSplitDropdown()" *ngIf="ENABLE_PAX_SPLIT && isDPSearch && !freeze && !enablePaxSplit">
          Do you want to assign passengers?
          <span class="a-tool-tip a-tool-tip--top-left"
              [attr.data-tooltip]="'Will allow you to assign/split passengers into multiple components'">
                <svg class="a-icon u-rml">
                <use xlink:href="#info"></use>
              </svg>
            </span>
        </button>
      </div>

    </div>

    <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
      <svg class="a-icon">
        <use xlink:href="#close"></use>
      </svg>
    </div>


  </div>  <!--Car criteria content : visible in DP flow-->


  <div class="c-advance-search__btn-wrapper" *ngIf="!isDPSearch">
    <div class="c-advance-search__search-info">
      <svg class="a-icon search-info--icon" *ngIf = "!isServiceAddComponent">
        <use xlink:href="#info"></use>
      </svg>
      <span *ngIf = "!isServiceAddComponent">Please select <b>Pick-up & Drop-off details </b> to continue search</span>
    </div>
    <div *ngIf="!isRefine" class="c-advance-search__search-action">
      <ng-container *ngIf="isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-cancel-btn" [tabIndex]="4">Cancel</button>
      </ng-container>
      <ng-container *ngIf="!isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-btn-reset" [tabIndex]="4">Reset Search</button>
      </ng-container>
      <button (click)="searchCars(false)" class="a-btn a-btn--primary auto-btn-search" [tabIndex]="5"
              [ngClass]="{'is-disabled':!validateInputs()}">Search
      </button>

    </div>
    <div *ngIf="isRefine" class="c-advance-search__search-action">
      <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default auto-btn-cancel">Back to results</button>
      <button (click)="searchCars(false)" class="a-btn a-btn--primary auto-btn-refine" [ngClass]="{'is-disabled':!validateInputs()}">Refine
        Search
      </button>
    </div>
  </div> <!--Button wrapper : hidden in DP-->


</div>
