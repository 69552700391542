import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment.app';
// import "@angular/compiler";
if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    var ajax = new XMLHttpRequest();
    var svgSpriteUrl = '../assets/svg/icons.svg';
    ajax.open("GET", svgSpriteUrl, true);
    ajax.send();
    ajax.onload = function (e) {
      var div = document.createElement("div");
      div.className = 'u-hidden-visually';
      div.innerHTML = ajax.responseText;
      document.body.insertBefore(div, document.body.childNodes[0]);
    };
  })
  .catch(err => console.error(err));
