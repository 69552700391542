import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { SurfFlightCriteriaModule } from '@surf/surf-flight-criteria';
import { SurfGrabPnrModule } from '@surf/surf-grab-pnr';
import { SurfGrabTourModule } from '@surf/surf-grab-tour';
import {SurfTypeAheadModule} from '@surf/surf-type-ahead';
import {SurfTextCasePipeModule} from '@surf/surf-components-core';
import {SurfPaxSelectionModule} from '@surf/surf-pax-selection';
import {SurfDatePickerModule} from '@surf/surf-date-picker';
import {SurfMultiFieldInputModule} from '@surf/surf-multi-field-input';
import { SurfOwnArrangementsModule } from '@surf/surf-own-arrangements';
import {DPCriteriaComponent} from './dp-criteria/dp-criteria.component';
import {SurfHotelCriteriaModule} from '@surf/surf-hotel-criteria';
import {SurfCarCriteriaModule} from '@surf/surf-car-criteria';
import {SurfTourCriteriaModule} from '@surf/surf-tour-criteria';
import {SurfTransferCriteriaModule} from '@surf/surf-transfer-criteria';
import {SurfDPAddBarModule} from '@surf/surf-dp-add-bar';
import {SurfGenericCriteriaModule} from '@surf/surf-generic-criteria';
import {SurfMessageBoxModule} from '@surf/surf-message-box';
import {SurfDropDownModule} from '@surf/surf-drop-down';
import {SurfInputModule} from "@surf/surf-input";
import {SurfDpTemplateModule} from '@surf/surf-dp-template';

@NgModule({
  declarations: [DPCriteriaComponent],
  imports: [
    CommonModule,
    SurfTypeAheadModule,
    SurfTextCasePipeModule,
    SurfPaxSelectionModule,
    SurfDatePickerModule,
    SurfMultiFieldInputModule,
    SurfHotelCriteriaModule,
    SurfCarCriteriaModule,
    SurfTourCriteriaModule,
    SurfFlightCriteriaModule,
    SurfTransferCriteriaModule,
    SurfGenericCriteriaModule,
    SurfDPAddBarModule,
    SurfMessageBoxModule,
    SurfDropDownModule,
    SurfGrabPnrModule,
    SurfOwnArrangementsModule,
    SurfInputModule,
    SurfDpTemplateModule,
    SurfGrabTourModule
  ],
  exports: [DPCriteriaComponent]
})
export class SurfDPCriteriaModule { }
