import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SurfMessageBoxModule } from '@surf/surf-message-box';
import { SurfMultiSelectorDropDownModule } from '@surf/surf-multi-selector-drop-down';
import { SurfPageLoaderModule } from '@surf/surf-page-loader';
import { GrabTourComponent } from './grab-tour/grab-tour.component';
import {SurfInputModule} from '@surf/surf-input';
import {SurfDropDownModule} from '@surf/surf-drop-down';
import {SurfComponentsCoreModule} from '@surf/surf-components-core';
import {SurfGetPaxDisplayTextPipe} from './pipe/surf-get-pax-display-text.pipe';
import {SurfGrabTourDurationStringPipe} from './pipe/surf-grab-tour-duration-string.pipe';

@NgModule({
  declarations: [GrabTourComponent, SurfGetPaxDisplayTextPipe, SurfGrabTourDurationStringPipe],
    imports: [
        CommonModule,
        SurfInputModule,
        SurfDropDownModule,
        SurfMessageBoxModule,
        SurfPageLoaderModule,
        SurfComponentsCoreModule,
        SurfMultiSelectorDropDownModule
    ],
  exports: [GrabTourComponent]
})
export class SurfGrabTourModule { }
