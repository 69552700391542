import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {B2bDashboardComponent} from './modules/b2b-dashboard/b2b-dashboard.component';
import {AuthGuard} from './auth.guard';

const routes: Routes = [
  // {path: '', component: B2bDashboardComponent, canActivate: [AuthGuard]},
  {path: '', component: B2bDashboardComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
