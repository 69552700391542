import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {
  DataShareService,
  DateTimeFormat,
  FLIGHT_SEARCH_SEGMENT_SPLITTERS,
  FlightComponentCriteria,
  FlightCriteria,
  SurfAuthorizationService,
  SurfCityPipe,
  SurfAirlinePipe,
  SurfStaticDataService,
  SurfScrollDirective,
  SurfActionAlertService
} from '@surf/surf-components-core';
import { Traveller } from '@tc-core/model/it/codegen/tbx/api/commons/traveller';
import { TravellerContact } from '@tc-core/model/it/codegen/tbx/api/commons/traveller-contact';
import { Product } from '@tc-core/model/it/codegen/tbx/api/product/product';
import { TbxKeyControlParameter } from '@tc-core/model/it/codegen/tbx/api/tbx-key-control-parameter';
import { TBXRequestWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-request-wrapper';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {CartServiceHandler, DataServiceHandler, FlightSearchServiceHandler} from '@tc-core/service/service-handlers';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { Subscription } from 'rxjs';
import { GrabPnrBooking } from './grab-pnr-booking';
import {
  Ancillary,
  FlightDetail,
  FlightGETCriteria,
  FlightGrabPnrGETCriteria,
  GRAB_PNR_TYPE,
  PerPersonAncillaryTotal,
  PNR_CRITERIA_ACTION,
  PNR_MODALS,
  PNR_STAGES,
  PnrComponentOutput,
  PnrSector, Value
} from './grab-pnr.models';
import {TC} from '@tc-core/util';
import PRODUCT_STATUS = TC.PRODUCT_STATUS;
import {MultiFieldInputData, MultiFieldInputPropertyFactory, MultiFieldInputProperty} from '@surf/surf-multi-field-input';
import {ModelStep, StepHandler} from "../models/modal-step.model";
import { PermissionKeys } from '../security/permission-constants';
import grabPnrOperations from '../security/grab-pnr-operations.json';
import { Flight } from '@tc-core/model/it/codegen/tbx/api/product/flight/flight';
import {NameValuePair} from "@tc-core/model/it/codegen/tbx/api/cart/name-val-pair";
import {Segment} from "@tc-core/model/it/codegen/tbx/api/product/flight/segment";
import {FareInfo} from "@tc-core/model/it/codegen/tbx/api/product/flight/fare-info";
import {SurfClientTimezoneService} from '@surf/surf-components-core';
import {MasterDataStoreService, StoreConstants} from '@surf/surf-state-manage';

@Component({
  selector: 'surf-grab-pnr',
  templateUrl: './grab-pnr.component.html'
})
export class GrabPnrComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() grabType: GRAB_PNR_TYPE;
  @Input() reset: boolean;
  @Input() keyControls: TbxKeyControlParameter;
  @Input() selectedPnrNumber: string;
  @Input() bkgId: number;
  @Input() productKey: string;
  @Input() booking: any;
  @Input() isDP = false;
  @Input() isManual = false;
  @Input() flightProduct : Flight;
  @Input() isRefine = false;
  @Input() stageChangeTrigger: PNR_STAGES;
  @Input() isBkgPage: boolean = false;
  @Input() travellers: Array<Traveller>;
  @Input() cachingItemMap = new Map<string, number>();
  @Input() allowNgrxCaching = false;
  @Input() crsCode: string;
  @Input() selectedGDS = '';
  @Output() stageChange: EventEmitter<PNR_STAGES> = new EventEmitter();
  @Output() pnrComponentOutput: EventEmitter<PnrComponentOutput> = new EventEmitter();
  @Output() flightPnrResultOutput: EventEmitter<any> = new EventEmitter();// there is no model for Pnr Results
  @Output() showManualItemOutput: EventEmitter<Boolean> = new EventEmitter();

  @HostBinding('class') classes = 'c-grab-pnr';
  // Key params helpers
  moduleConfigs: Array<{code: string, name: string}>;
  user: any;

  private subscription: Subscription;
  resultsNotFound = false;
  isLoaded = false;

  stage: PNR_STAGES;
  flightCriteria: FlightGETCriteria = new FlightGETCriteria();
  flightGrabPnrCriteria: FlightGrabPnrGETCriteria = new FlightGrabPnrGETCriteria();
  flightComponentCriteria: FlightCriteria;
  flightGrabPnrResult: any; // model not found in tc-core
  passengerCount: number;


  flightGDS;
  journeyType = 'Grab-PNR';
  pnrCriteriaAction = PNR_CRITERIA_ACTION.GRAB;
  validateVsOldBookings = true;
  tempPnrNumber: string;
  pnrValidationRegExp = /^[a-zA-Z0-9_]*$/;
  regexForBaggageValidation =  /(^(((0|[1-9]\d*)(\.\d+)?\s?(pc|PC|k|K|kg|KG|p|P)|^\s?nil\s?|^\s?NIL\s?|^\s?Nil\s?)|(((\d*)\s?(pc|PC|p|P))(\s(at|AT)\s((\d*)\s?(kg|KG|k|K))\s(each|EACH))?)))$/;
  childTravellerUpperAgeLimit: number = 11;
  externalError = false;
  disableInputs = false;
  disableGrbBtn = true;
  inputElementId = 'flight-criteria-grab-number';
  isValidPnrInput: boolean;
  invalidErrorMsg: string;
  invalidErrorMsgDefault = 'Invalid PNR number';

  grabPnrBookingModule: GrabPnrBooking;
  showGrabPnrModal: boolean;
  showTravellerChangesModal: boolean;
  showRefreshChangesModal: boolean;
  showApplyChangesModal: boolean;
  isContinueBtnTrigger = false;
  // noRefreshChanges: boolean;
  refreshPaxChanged: boolean;
  flightProductUpdate : Flight;
  PNR_STAGES = PNR_STAGES;
  GRAB_PNR_TYPE = GRAB_PNR_TYPE;
  ENABLE_SURF_MC_GRAB;
  DATE_FORMAT = DateTimeFormat.ADVANCED_DATE_SHORT_FORMAT;
  DATE_TIME_FORMAT = DateTimeFormat.ADVANCED_DATE_WITH_TIME_SHORT_MONTH_TIME_FORMAT;
  NORMAL_REFRESH = 'NORMAL_REFRESH';
  ADVANCE_REFRESH_SELECTED = 'ADVANCE_REFRESH_SELECTED';
  ADVANCE_REFRESH_SELECTED_ALL = 'ADVANCE_REFRESH_SELECTED_ALL';
  GRAB_PNR_MODAL_ID = 'grab-pnr--pnr-id-modal';
  GRAB_PNR_ERROR_MODAL_ID = 'grab-pnr-error-modal';
  DP_GRAB_PNR_WARNING_MODAL_ID = 'dp-grab-pnr-warning-modal';
  PNR_ALREADY_EXISTS_IN_A_BOOKING_MODAL_ID = 'pnr-already-exists-in-a-booking-modal';
  DP_GRAB_SWITCH_WARNING_MODAL_ID = 'dp-grab-switch-warning-modal';
  TRAVELLER_COMPARISON_MODAL_ID = 'grab-pnr--traveller-comparison-modal';
  REFRESH_COMPARISON_MODAL_ID = 'refresh-pnr--comparison-modal';
  MANUAL_REFRESH_COMPARISON_MODAL_ID = 'manual-refresh-pnr--comparison-modal';
  APPLY_CHANGES_SUCCESS_MODAL_ID = 'refresh-pnr--apply-success-modal';
  APPLY_CHANGES_ERROR_MODAL_ID = 'refresh-pnr--apply-error-modal';
  UPDATE_TRAVELLER_WARNING_MODAL_ID = 'grab-pnr-traveller-update-warning';
  MANUAL_REFRESH_COMPARISON_MODAL_TCP_ID = 'refresh-pnr--comparison-modal-tpc';
  // APPLY_CHANGES_NO_CHANGE_MODAL_ID = 'refresh-pnr--apply-no-change-modal';

  SectorTypeNameList = ['DES', 'DEP', 'FNO', 'DEPTRM', 'ARRTRM', 'BAG', 'AIRLOC', 'DEPDATE', 'ARRDATE', 'BKGCLS', 'EQUIP', 'OPAIR', 'AIR', 'CABCLS', 'FDIC'];
  selectedSectorList: NameValuePair[] = [];
  itemWiseRefreshChanges = [];
  flightDetailMap = new Map<string, FlightDetail[]>();
  samePnrGrabProductList: Flight[] = [];
  airlinePipe: SurfAirlinePipe;
  cityPipe: SurfCityPipe;
  refreshChangeList = [];
  refreshPaxChanges: any;
  refreshPaxChangeKeys: any;
  refreshFlightInfoChanges: any;
  refreshSectorInfoChanges: any;
  refreshSectorInfoChangeKeys: any;
  grabError = false;
  refreshError = false;
  refreshErrorMsg: string;
  grabErrorMsg: string;
  showLoader = false;
  loaderTitle = '';
  showPNRisSameDialog = false;
  isDPCriteriaExist = false;
  showDPGrabWarning = false;
  refreshPNRTitleChanged :boolean = false; //check whether if there is any Title change in Passenger Comparision in Refresh PNR
  updateBookingObserver: Subscription = new Subscription();
  usePNRPriceRecord = true;
  showDPGrabSwitchWarning = false;
  userProceedsToDP = false;
  tempPnrComponentOutput: PnrComponentOutput;
  refreshApplyNotAllowed = false;
  advanceFlowSegmentSelected = false;
  activeFlightTab: string;
  refreshUpdateAvailable = false;
  PNRExistsInAnotherBookingMessageVisible = false;
  PNR_EXISTS_IN_DIFFERENT_BOOKING_OR_QUOTE = 'This PNR exists in Quote/Booking ';
  surfModalDescription = null;
  pnrProperty: MultiFieldInputProperty[] = [];
  pnrValues = [];
  GDS_CATEGORIES;
  defaultGDS;
  isSabre = false;
  isMismatchDetailWarning = false;
  disablePNRPriceRecord = false;
  showManualItemConfig = false;
  paxChangesOtherThanPaxTitlesAfterRefreshPNR : boolean = false;
  paxTitleChangesAfterRefreshPNR : boolean = false;
  paxPriceChangesAfterRefreshPNR = false;
  hasFlightOrSectorInfoChangesAfterRefreshPNR : boolean = false;
  updateAvailableMessage = false;
  ancillaryChangesAfterRefresh : boolean = false;
  ticketChangesAfterRefresh : boolean = false;
  dummyString = 'DUMMY';
  paxTypesNames:
    Array<{code: string, name: string}> = [
    {code: 'A', name: 'Adult'},
    {code: 'T', name: 'Teen'},
    {code: 'C', name: 'Child'},
    {code: 'I', name: 'Infant'},
  ];
  isContinueButtonLoaderActive = false;
  stepHandler = new StepHandler();
  isGrabWithPrice : boolean = true;
  isAdvanceRefreshFlow = false;
  permissionKeys;
  isGrabPnrActive = true;
  isRefreshPnrActive = true;
  TPC_SPECIFIC_LABEL_ENABLED: boolean = false;
  ENHANCED_REFRESH_TPC_GDS_LIST: string[] = [];
  grabBtnLabel: string;
  changePNRBtnLabel: string;

  crsReference: string;

  ancillaries: Ancillary[] = [];
  ancillaryByPaxName: Map<string, Ancillary[]> = new Map<string, Ancillary[]>();
  perPersonAncillaryTotalList: PerPersonAncillaryTotal[] = [];
  ticketNumbers: string[] = [];
  ticketMessages: string[] = [];

  isDecodeAncillaryMethodExecuted = false;

  ancillaryTotalPrice: any = {};

  isSectorToggleOn: boolean = false;
  isAncillaryPassengerToggleOn = true;
  isPassengerToggleOn: boolean = false;
  orderId: string;

  sectorHighlightMap: Map<number, boolean> = new Map();

  flightDetailsText = 'Flight details';
  enhancedRefreshFlightSectorChangesAvailable = false;

  randomID: number;
  tpcEnableDivAndBrandMap = new Map<string, string[]>();

  pnrWarningMessage: string;
  toggleAncillaryCollapseButton = false;
  ENHANCED_REFRESH_FLOW_NO_CHANGE_DEFAULT_VIEW = false;
  FLT_PAX_CONTACT_TP_MAPPING_DISABLED_CRS: string[] = [];


  constructor(private flightSearchServiceHandler: FlightSearchServiceHandler,
              private cartServiceHandler: CartServiceHandler,
              private dataStore: DataStore,
              private commonHelper: CommonHelper,
              private renderer: Renderer2,
              private surfClientTimezoneService: SurfClientTimezoneService,
              protected surfStaticDataService: SurfStaticDataService,
              private dataServiceHandler: DataServiceHandler,
              protected commonService: DataShareService,
              private surfAuthorizationService: SurfAuthorizationService,
              protected configLoader: ConfigLoader,
              private masterDataStoreService: MasterDataStoreService,
              private surfActionAlertService: SurfActionAlertService,
  ) {
    this.cityPipe = new SurfCityPipe(surfStaticDataService);
    this.airlinePipe = new SurfAirlinePipe(dataServiceHandler);
  }

  ngOnInit() {
    this.setAuthenticationParameters();
    // set init values for steps
    this.stepHandler.grabPriceMethodComponent = ModelStep.isActive;
    this.stepHandler.grabPNRComponent = ModelStep.notActive;
    // surf permissions
    this.stage = this.stage ? this.stage : PNR_STAGES.DEFAULT;
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    this.setKeyParams();
    switch (this.grabType) {
      case GRAB_PNR_TYPE.CRITERIA:
        break;
      case GRAB_PNR_TYPE.REFINE:
        this.initRefineType();
        break;
      case GRAB_PNR_TYPE.BOOKING:
        this.initBookingType();
        break;
      case GRAB_PNR_TYPE.REFRESH:
        this.initRefresh();
        break;
      case GRAB_PNR_TYPE.QUOTE:
        this.disablePNRPriceRecord = true;
        this.initBookingType();
        break;
    }



    this.commonService.getCurrentMsg().subscribe(msg => {
      if (msg === 'dpCriteriaExist') {
        this.isDPCriteriaExist = true;
      }
      if (msg === 'dpCriteriaDoesNotExist') {
        this.isDPCriteriaExist = false;
      }
    });

    this.getGDS();
    this.renameTPCLabels();
    this.pnrValues = [];
    this.pnrProperty.push(MultiFieldInputPropertyFactory.getDropdownProperties(false, '', true, false, 'button', 'gdsType', ''));
    this.pnrValues.push([]);
    this.pnrProperty.push(MultiFieldInputPropertyFactory.getInputProperties(true, '', false, this.disableInputs, 'text', this.pnrValidationRegExp, 'pnrValue', ''));
    this.pnrProperty[1].errorMessage = this.invalidErrorMsgDefault;
    this.pnrProperty[1].requiredMessage = 'Enter valid PNR number';
    this.pnrValues.push(new MultiFieldInputData('', this.tempPnrNumber));

    this.randomID = Date.now() + Math.random();
    this.assignPnrExistMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reset && !changes.reset.isFirstChange() && this.reset) {
      this.removePnrSelection();
    }
    if (changes['stageChangeTrigger'] && changes['stageChangeTrigger'].currentValue &&
      changes['stageChangeTrigger'].currentValue == PNR_STAGES.GRAB_COMPLETE) {
      this.stage = PNR_STAGES.GRAB_COMPLETE;
    }
  }

  ngAfterViewInit(): void {
    this.rearrangeModals();
    this.getGDS();
  }

  ngOnDestroy(): void {
    this.removeModals();
  }
  /**
   * Setting up the authentication parameters from the permission framework
   */
  setAuthenticationParameters() {
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(grabPnrOperations);
    const mbiFltGrabPnrPermission = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.MBI_FLT_GRAB_PNR);
    const fltGrabPnrPermission = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.FLT_GRAB_PNR);
    const fltRefreshPnrPermission = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.SURF_FLIGHT_CARD_REFRESH_PNR);
    const mbiFltSearchRefreshPnrPermission = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.MBI_FLIGHT_SEARCH_REFRESH_PNR);
    if ((mbiFltGrabPnrPermission && mbiFltGrabPnrPermission.allowed) || (fltGrabPnrPermission && fltGrabPnrPermission.allowed)) {
      this.isGrabPnrActive = true;
    } else {
      this.isGrabPnrActive = false;
    }
    if ( fltRefreshPnrPermission && fltRefreshPnrPermission.allowed ) {
      this.isRefreshPnrActive = true;
    } else {
      this.isRefreshPnrActive = false;
    }
    if ( this.isManual && this.flightProduct ) {
      (mbiFltSearchRefreshPnrPermission && mbiFltSearchRefreshPnrPermission.allowed) ? this.isRefreshPnrActive = true : this.isRefreshPnrActive = false;
    }
  }
  // load module configurations
  loadConfigs() {
    if (this.moduleConfigs && this.moduleConfigs.length) {
      const config = this.moduleConfigs.find(conf => (conf.code === '' +
        'DEFAULT_GDS'));
      if (config && config.name) {
        this.defaultGDS = config.name;
        this.flightGDS = this.defaultGDS;
        if (config.name === 'SAB') {
          this.isSabre = true;
          this.usePNRPriceRecord = true;
        }
      }
      if (this.selectedGDS && this.selectedGDS !== '') {
        this.defaultGDS = this.selectedGDS;
        this.flightGDS = this.defaultGDS;
      }
      if (this.configLoader.getModuleConfig('TPC_SPECIFIC_LABEL_ENABLED', TC.MODULE_NAME.SURF_B2B)) {
        this.TPC_SPECIFIC_LABEL_ENABLED = this.configLoader.getModuleConfig('TPC_SPECIFIC_LABEL_ENABLED', TC.MODULE_NAME.SURF_B2B).toUpperCase() === 'TRUE';
      }
      if (this.configLoader.getModuleConfig('ENHANCED_REFRESH_FLOW_NO_CHANGE_DEFAULT_VIEW', TC.MODULE_NAME.SURF_B2B)) {
        this.ENHANCED_REFRESH_FLOW_NO_CHANGE_DEFAULT_VIEW = this.configLoader.getModuleConfig('ENHANCED_REFRESH_FLOW_NO_CHANGE_DEFAULT_VIEW', TC.MODULE_NAME.SURF_B2B).toUpperCase() === 'TRUE';
      }
      if (this.configLoader.getModuleConfig('TPC_SPECIFIC_LABEL_ENABLED', TC.MODULE_NAME.SURF_B2B)) {
        let value = this.configLoader.getModuleConfig('ENHANCED_REFRESH_PNR_MODAL_ENABLING_PRODUCT', TC.MODULE_NAME.SURF_B2B);
        this.ENHANCED_REFRESH_TPC_GDS_LIST = value.split(",");
      }
      if (this.configLoader.getModuleConfig('FLT_PAX_CONTACT_TP_MAPPING_DISABLED_CRS', TC.MODULE_NAME.SURF_B2B)) {
        const value = this.configLoader.getModuleConfig('FLT_PAX_CONTACT_TP_MAPPING_DISABLED_CRS', TC.MODULE_NAME.SURF_B2B).toUpperCase();
        this.FLT_PAX_CONTACT_TP_MAPPING_DISABLED_CRS = value.split(',').map(str => str.trim());
      }
      this.loadConfigForBaggageRefExStr();
      this.loadConfigForDivisionAndBrand()
    }
  }
  // get GDS details
  getGDS() {
    this.loadConfigs();
    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_GDS) !== null && this.cachingItemMap.get(StoreConstants.CACHE_GDS) > 0)) {
      this.getGDSFromCache(this.cachingItemMap.get(StoreConstants.CACHE_GDS));
    } else {
      this.dataServiceHandler.getGds().subscribe(res => {
        if (res && Array.isArray(res)) {
          this.mapGDSData(res);
        }
      });
    }
  }

  private getGDSFromCache(timeout: number) {
      this.masterDataStoreService.getGds(timeout).subscribe(
        res => {
          if (res) {
            this.mapGDSData(res);
          }
        },
        error => {
          console.log('Error in getting gds');
        });
  }
  private mapGDSData(res: any[]) {
    this.GDS_CATEGORIES = res;
    this.pnrValues[0] = [];
    this.GDS_CATEGORIES.forEach(category => {
      let selected = false;
      if (category.code === this.defaultGDS) {
        selected = true;
      }
      if( this.isValidGDS( category.name ) ) {
        this.pnrValues[0].push(new MultiFieldInputData(category.code, category.name, selected));
      }
    });
  }

  /**
   * If refine search && PNR selected from the criteria set pnr parameters
   */
  initRefineType() {
    if (this.selectedPnrNumber && this.selectedPnrNumber.length > 0) {
      this.stage = PNR_STAGES.GRAB_COMPLETE;
      this.isValidPnrInput = true;
    }
  }

  /**
   * If Booking PNR grab
   */
  initBookingType() {
    this.grabPnrBookingModule = new GrabPnrBooking();
    this.grabPnrBookingModule.bkgTravellers = this.clone(this.travellers);
  }

  initRefresh() {
    if (this.flightProduct) {
      this.setFlightListDetails();
    }
  }

  onClickGrab(): void {
    this.validateVsOldBookings = true;
    if (this.isDPCriteriaExist) {
      this.showDPGrabWarning = true;
    } else {
      this.handleStageChange(PNR_STAGES.GRAB);
      this.changeModalVisibility(PNR_MODALS.GRAB_PNR, true);
    }
  }
  getGrabPriceMethod(event : boolean){
    if(this.isGrabWithPrice != event){
      this.invalidErrorMsg = '';
      this.externalError = false;
    }
    this.isGrabWithPrice = event;
  }
  processStepCompletion(event : string){
    this.stepHandler.grabPriceMethodComponent = event;
    if(event === ModelStep.isActive){
      this.stepHandler.grabPNRComponent = ModelStep.notActive;
      this.disableInputs = true;
      this.disableGrbBtn =true;
    }else if (event === ModelStep.isDone){
      this.stepHandler.grabPNRComponent = ModelStep.isActive;
      this.disableInputs = false;
      this.disableGrbBtn =false;
    }

  }
  initiateDPGrab(event) {
    if (event == 'confirm') {
      this.showDPGrabWarning = false;
      this.handleStageChange(PNR_STAGES.GRAB);
      this.changeModalVisibility(PNR_MODALS.GRAB_PNR, true);
    } else if (event == 'cancel') {
      this.showDPGrabWarning = false;
    }
  }

  onClickRefresh(isRefreshOnSame = false): void {
    console.log('refresh pnr');
    if (this.isEnhancedRefreshFlow()) {
      this.isDecodeAncillaryMethodExecuted = false;
    }
    this.refreshError = false;
    this.isContinueBtnTrigger = false;
    this.selectedSectorList = [];
    this.refreshErrorMsg = '';
    this.loaderTitle = this.TPC_SPECIFIC_LABEL_ENABLED? 'Refreshing PNR / Order' : 'Refreshing PNR'
    this.showLoader = true;
    this.initSearch();
    if (isRefreshOnSame) {
      let product;

      if(!this.isDP){
        product = this.booking.products.find(product => product.detail? (product.detail.crsReference === this.tempPnrNumber.toUpperCase()): false);
      }
      else{
        if(this.isValidDpBooking()){
          product = this.booking.products[0].products.find(product => product.detail? (product.detail.crsReference === this.tempPnrNumber.toUpperCase()): false);
        }
      }
      if (product) {
        this.setRefreshPnrParameters(product.productKey);
      }
    } else {
      this.setRefreshPnrParameters();
    }

    if (this.flightGrabPnrCriteria && this.flightGrabPnrCriteria.productKey &&
      this.flightGrabPnrCriteria.productKey.split('~')[1]) {
      this.activeFlightTab = this.flightGrabPnrCriteria.productKey.split('~')[1];
    } else {
      this.activeFlightTab = '1';
    }

    this.subscription.add(
      this.flightSearchServiceHandler.getGrabPNRDetails(this.flightGrabPnrCriteria).subscribe((result) => {
        if (result instanceof TcApiError || result instanceof TcHttpError) {
          this.showLoader = false;
          this.onRefreshFails(result, 'refresh');
          this.disableInputs = false;
          this.subscription.unsubscribe();
        } else if (result) {
          this.showLoader = false;
          if (result.status && result.status.message) {
            const str = result.status.message;
            const isValidToApply = str.indexOf('Ticket numbers will be updated');
            if (isValidToApply > -1) {
              this.updateAvailableMessage = true;
            } else {
              this.updateAvailableMessage = false;
            }
          } else {
            this.updateAvailableMessage = true;
          }
          this.onRefreshSuccess(result);
          this.disableInputs = false;
          this.subscription.unsubscribe();
        }
      }));
  }
  onClickChange(): void {
    if (this.isDPCriteriaExist) {
      this.showDPGrabWarning = true;
    } else {
      this.validateVsOldBookings = true;
      this.tempPnrNumber = this.selectedPnrNumber;
      this.handleStageChange(PNR_STAGES.CHANGE);
      this.changeModalVisibility(PNR_MODALS.GRAB_PNR, true);
    }
  }

  onClickRemove(): void {
    this.updateAvailableMessage = false;
    this.removePnrSelection();
    this.emitComponentCriteria();
  }

  /**
   * remove previously selected PNR
   */
  removePnrSelection(): void {
    this.flightComponentCriteria = null;
    this.flightGrabPnrResult = null;
    this.tempPnrNumber = '';
    this.selectedPnrNumber = '';
    this.passengerCount = 0;
    if (this.pnrValues?.length > 0) {
      this.pnrValues[1] = (new MultiFieldInputData('', this.tempPnrNumber));
    }
    this.setGrabPnrParameters();
    this.handleStageChange(PNR_STAGES.DEFAULT);
  }

  /**
   * On click cancel button if there is a search initiated cancel the particular API subscription
   */
  onClickCancel(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.resetError();
    this.disableInputs = false;
    this.changeModalVisibility(PNR_MODALS.GRAB_PNR, false);
  }

  /**
   * Check if a entered PNR id is valid and if valid populate the criteria
   * @see <server>/flight-search/docs - /v2/products/pnr-details
   */
  onClickSearchPNR(switchingToDP?: boolean): void {
    this.isContinueButtonLoaderActive = true;
    if (switchingToDP) {
      this.loaderTitle = 'Switching to DP flow';
      this.showLoader = true;
    }
    this.resetError();
    if (!this.isValidPnrNumber() || this.disableInputs) {
      this.isContinueButtonLoaderActive = false;
      return;
    }
    this.initSearch();
    this.flightGrabPnrCriteria.actions = [];
    this.flightGrabPnrCriteria.actions.push(PNR_CRITERIA_ACTION.GRAB);
    this.setGrabPnrParameters();
    this.subscription.add(
      this.flightSearchServiceHandler.getGrabPNRDetails(this.flightGrabPnrCriteria).subscribe( (result) => {
        if (this.commonHelper.dataValidity(result)) {
          if (!this.isGrabWithPrice && !this.hasValidPaxCombination(result)) {
            this.isLoaded = true;
            this.resultsNotFound = true;
            this.externalError = true;
            this.disableInputs = false;
            this.showLoader = false;
            this.invalidErrorMsg = 'Need atleast one adult in PNR to proceed';
            this.validateVsOldBookings = true;
            this.changeModalVisibility(PNR_MODALS.GRAB_PNR, true);
            this.isContinueButtonLoaderActive = false;
          } else {
            this.showLoader = false;
            this.onResultsHandler(result);
            this.isContinueButtonLoaderActive = false;
          }
        } else if (result instanceof TcApiError || result instanceof TcHttpError) {
          this.showLoader = false;
          this.onResultsErrorHandler(result);
          this.isContinueButtonLoaderActive = false;
        }
      }));
  }

  hasValidPaxCombination(result): boolean {
    let isValid = false;
    if (result?.data && result.data[0]?.passengers) {
      result.data[0].passengers.forEach(passenger => {
        if (passenger?.profile?.type === 'A') {
          isValid = true;
        }
      });
    }
    return isValid;
  }

  /**
   * On PNR id input value change - reset external error
   * @param event - the change event from surf-input
   */
  onValueChange(event): void {
    this.resetError();
    this.tempPnrNumber = event[1];
    if (event[0]) {
      this.flightGDS = event[0].code;
      this.assignPnrExistMessage();
      this.isSabre = (event[0].code === 'SAB');
      if (this.isSabre) {
        this.usePNRPriceRecord = true;
      }
    }
  }

  /**
   * Introduced a Grab PNR component stage & this use this function to change it
   * @see PNR_STAGES
   * @param stage - change to stage
   */
  handleStageChange(stage: PNR_STAGES): void {
    this.stage = stage;
    this.stageChange.emit(stage);
  }

  /**
   * Set Key params from module config
   */
  setKeyParams(): void {
    if (this.moduleConfigs) {
      const configTypeMap = new Map<string, string>();
      configTypeMap.set('BOOKING_TYPE', 'bkgType');
      configTypeMap.set('CLIENT_TYPE', 'cliType');
      this.moduleConfigs.forEach( (config) => {
        if (configTypeMap.has(config.code)) {
          this.flightCriteria[configTypeMap.get(config.code)] = config.name;
        }

        if ('ENABLE_SURF_MC_GRAB' == config.code ) {
          this.ENABLE_SURF_MC_GRAB = (typeof config.name == 'string') ?
            (config.name && config.name.toLowerCase() == 'true') :
            config.name;
        }
      });
    }
    if (this.user) {
      // flightCriteria
      this.flightCriteria.userId = this.user.id;
      this.flightCriteria.username = this.user.userSummary.username;
      this.flightCriteria.journeyType = this.journeyType;

      if (this.dataStore.get(DataKey.emulating).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
        const emulatingTC = this.dataStore.get(DataKey.emulatingTCProfile).getValue();
        this.flightCriteria.cmp = emulatingTC.controls.cmp;
        this.flightCriteria.div = emulatingTC.controls.div;
        this.flightCriteria.brand = emulatingTC.controls.brand;
        this.flightCriteria.channel = emulatingTC.controls.channel;
        this.flightCriteria.cur = emulatingTC.controls.cur;
        this.flightCriteria.cliGrp = emulatingTC.controls.cliGrp;
        this.flightCriteria.cliId = emulatingTC.summary.id;
        this.flightCriteria.srcCountry = emulatingTC.summary.address.country;
      } else if (this.dataStore.get(DataKey.tradeClient).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
        const tradeClient = this.dataStore.get(DataKey.tradeClient).getValue();
        this.flightCriteria.cmp = tradeClient.controls.cmp;
        this.flightCriteria.div = tradeClient.controls.div;
        this.flightCriteria.brand = tradeClient.controls.brand;
        this.flightCriteria.channel = tradeClient.controls.channel;
        this.flightCriteria.cur = tradeClient.controls.cur;
        this.flightCriteria.cliGrp = tradeClient.controls.cliGrp;
        this.flightCriteria.cliId = tradeClient.summary.id;
        this.flightCriteria.srcCountry = tradeClient.summary.address.country;
      } else if (!this.dataStore.get(DataKey.keyControls).getValue()) {
        this.flightCriteria.cmp = this.user.userDetail.defaultCom.code;
        this.flightCriteria.div = this.user.userDetail.defaultDivison.code;
        this.flightCriteria.brand = this.user.userDetail.defaultBrand.code;
        this.flightCriteria.channel = this.user.userDetail.defaultChannel.code;
        this.flightCriteria.cur = this.user.userDetail.defaultCurrency.code;
        this.flightCriteria.cliGrp = this.user.clientGroup;
        this.flightCriteria.cliId = this.user.clientId;
      }

      // flightGrabPnrCriteria
      if (this.dataStore.get(DataKey.emulating).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
        const emulatingTC = this.dataStore.get(DataKey.emulatingTCProfile).getValue()
        this.flightGrabPnrCriteria.cmp = emulatingTC.controls.cmp;
        this.flightGrabPnrCriteria.div = emulatingTC.controls.div;
      } else if (!this.dataStore.get(DataKey.keyControls).getValue()) {
        this.flightGrabPnrCriteria.cmp = this.user.userDetail.defaultCom.code;
        this.flightGrabPnrCriteria.div = this.user.userDetail.defaultDivison.code;
      }
      this.flightGrabPnrCriteria.actions = new Array<string>(this.pnrCriteriaAction);
      // this.flightGrabPnrCriteria.journeyType = this.journeyType;
      this.flightGrabPnrCriteria.expand = 'all';
    }

    // from booking or cart
    if (this.dataStore.get(DataKey.keyControls).getValue()) {
      const keyControls = this.dataStore.get(DataKey.keyControls).getValue();

      // flightCriteria
      this.flightCriteria.cmp = keyControls.cmp;
      this.flightCriteria.div = keyControls.div;
      this.flightCriteria.brand = keyControls.brand;
      this.flightCriteria.channel = keyControls.channel;
      this.flightCriteria.cur = keyControls.cur;
      this.flightCriteria.cliGrp = keyControls.cliGrp;
      this.flightCriteria.cliId = keyControls.cliId;
      this.flightCriteria.srcCountry = keyControls.srcCountry;

      // flightGrabPnrCriteria
      this.flightGrabPnrCriteria.cmp = keyControls.cmp;
      this.flightGrabPnrCriteria.div = keyControls.div;
    }
  }

  /**
   * On pnr-details result
   * @param result - pnr-detail result
   */
  onResultsHandler(result: any): void {
    this.onClickCancel();
    this.isLoaded = true;
    this.resultsNotFound = true;
    this.externalError = false;

    if (result && result.data && result.data[0] && this.isGKSectorsAvailable(result.data[0].sectors) && this.isGrabWithPrice) {
      this.grabError = true;
      this.grabErrorMsg = 'GK sectors found, please contact wholesale';
      return;
    }

    if (result && result.data && result.data[0] && result.data[0].multiValidatingCarriers && this.isGrabWithPrice) {
      if (this.isDP) {
        /*If the DP flow - if the MC grab is disabled by a config, do not allow to proceed*/
        if (!this.ENABLE_SURF_MC_GRAB) {
          this.showDPGrabSwitchWarning = true;
        } else {
          this.prepareGrabResults(result);
        }
      } else {
        /*If the component flow - Show the warning for user to inform that multiple validating carriers found and that
         * he should switch to DP flow to continue*/
        if (!this.userProceedsToDP) {
          this.showDPGrabSwitchWarning = true;
        } else {
          /*Continue switching to DP*/
          const msg = 'DP_Switch';
          this.prepareGrabResults(result, true);
          this.commonService.updateShareObj(this.tempPnrComponentOutput);
          this.showLoader = false;
          this.commonService.changeMessage(msg);

          /*remove the page loader if it is still in the body*/
          try {
            if (document.getElementsByClassName('c-page-loader--cart is-visible') &&
              document.getElementsByClassName('c-page-loader--cart is-visible')[0]) {
              document.body.removeChild(document.getElementsByClassName('c-page-loader--cart is-visible')[0]);
            }
          } catch (e) {
          }
        }
      }
    } else {
      this.prepareGrabResults(result);
    }
  }


  isGKSectorsAvailable(sectors: any): boolean {
    let isGKSectorFound = false;
    if (sectors && sectors.length > 0) {
      sectors.forEach(sector => {
        if (sector.statusCode == 'GK') {
          isGKSectorFound = true;
        }
      });
    }
    return isGKSectorFound;
  }

  prepareGrabResults(result: any, dpSwitch?: boolean) {
    this.selectedPnrNumber = this.tempPnrNumber;
    if (result.data && result.data.length !== 0) {
      this.flightGrabPnrResult = result.data[0];
      this.flightPnrResultOutput.emit(this.flightGrabPnrResult);
      this.setPassengerDetails();
      this.setRefineHelperParams();
      if(!this.isGrabWithPrice && !this.isBkgPage){
        this.showManualItemOutput.emit(true);
      }else{
        this.setFlightComponentCriteria();
      }
      if (this.grabType === GRAB_PNR_TYPE.BOOKING || this.grabType === GRAB_PNR_TYPE.QUOTE ) {
        this.onResultHandlerBooking();
      } else {
        this.handleStageChange(PNR_STAGES.GRAB_COMPLETE);
      }
      this.emitComponentCriteria(dpSwitch);
    }
  }

  /**
   * booking page grab result handler
   * traveller matching & displaying the pnr detils
   */
  onResultHandlerBooking() {
    this.grabPnrBookingModule.matchTravellers();
    this.handleStageChange(PNR_STAGES.TRAVELLER_COMPARISON);
    this.changeModalVisibility(PNR_MODALS.TRAVELLER_COMPARISON, true);
  }

  /**
   * On pnr-details error
   * @param error - pnr-detail error
   */
  onResultsErrorHandler(error: any): void {
    this.isLoaded = true;
    this.resultsNotFound = true;
    this.externalError = true;
    this.disableInputs = false;
    if (error instanceof TcApiError) {
      error = error as TcApiError;
      const errorMessageTmp = error.tcResultError && error.tcResultError.message ?
        error.tcResultError.message : this.invalidErrorMsgDefault;
      this.checkIfPnrExistInAnotherBookings(errorMessageTmp);
    } else if (error instanceof TcHttpError) {
      error = error as TcHttpError<any>;
      const errorMessageTmp = error.httpResponse &&
      error.httpResponse.error &&
      error.httpResponse.error.error &&
      error.httpResponse.error.error.message ?
        error.httpResponse.error.error.message : this.invalidErrorMsgDefault;
      this.checkIfPnrExistInAnotherBookings(errorMessageTmp);
    } else {
      this.invalidErrorMsg = 'Server error';
      this.validateVsOldBookings = true;
      this.changeModalVisibility(PNR_MODALS.GRAB_PNR, true);
    }
  }

  checkIfPnrExistInAnotherBookings(errorMessageTmp) {
    /**
     * checking whether  error equals 'PNR existing in a different booking / Quote'
     * errorMessage.search returns zero if the errorMessage begins with regEx
     */
    const errorMessage = errorMessageTmp;
    const regEx = this.PNR_EXISTS_IN_DIFFERENT_BOOKING_OR_QUOTE;
    const n = errorMessage.search(regEx);
    if (n === 0) {
      this.onClickCancel(); // close grab PNR message box
      this.surfModalDescription = errorMessage + '. \nDo you wish to continue?';
      this.openPNRExistsInBookingMessage();
    } else {
      this.invalidErrorMsg = errorMessageTmp;
      this.validateVsOldBookings = true;
      this.changeModalVisibility(PNR_MODALS.GRAB_PNR, true);
    }
  }

  findTravellerContact(contacts: TravellerContact[], type: string): any {
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      if (contact.contactType === type) {
        if (contact.value == null) {
          return "";
        } else {
          return "" + contact.value;
        }
      }
    }
  }

  findTravellerChange(type: string, travellerName: string): any {
    let value;
    this.travellers.forEach(traveller => {
      const bookingTravellerName = traveller.profile.title  + traveller.profile.firstName  + traveller.profile.lastName;
      const trimmedName = travellerName.replace(/\s+/g, "")
      if(bookingTravellerName === trimmedName  ) {
        if (type === 'EMAIL') {
          value = this.findTravellerContact(traveller.profile.addresses[0].contacts, 'E-mail');
        } else if (type === 'TP') {
          value = this.findTravellerContact(traveller.profile.addresses[0].contacts, 'Telephone');
        } else if (type === 'DOB') {
          value = traveller.profile.dob;
        }
      }
    })
    return value;
  }

  getBookingTravellerChange(changes: any[], type: string): any {
    const filteredChange = changes.find(change => change.type === type && (change?.bkgVal || change?.changed));
    return filteredChange ? (filteredChange.bkgVal !== undefined ? filteredChange.bkgVal : '') : undefined;
  }

  getPNRTravellerName(changes: any[], type: string): any {
    const filteredChange = changes.find(change => change.type === type && (change?.pnrVal || change?.changed));
    return filteredChange ? (this.decodePassengerName(filteredChange.pnrVal) !== undefined ? this.decodePassengerName(filteredChange.pnrVal) : undefined) : undefined;
  }

  decodePassengerName(paxName: string) {
    return paxName.replace("/", "");
  }

  isBookingTravellerChangeAvailable(changes: any[], type: string): any {
    const filteredChange = changes.find(change => change.type === type && (change?.bkgVal || change?.changed));
    return filteredChange ? (filteredChange.bkgVal? true : true): false;
  }

  getPNRTravellerChange(changes: any[], type: string): any {
    const filteredChange = changes.find(change => change.type === type && (change?.pnrVal || change?.changed));
    return filteredChange ? (filteredChange.pnrVal !== undefined ? filteredChange.pnrVal : '') : undefined;
  }

  isPNRTravellerChangeAvailable(changes: any[], type: string): any {
    const filteredChange = changes.find(change => change.type === type && (change?.pnrVal || change?.changed));
    return filteredChange ? (filteredChange.pnrVal? true : true) : false;
  }

  isHighlightAvailable(changes: any[], type: string): any {
    const filteredChange = changes.find(change => change.type === type && change?.changed);
    return filteredChange ? (filteredChange.pnrVal? true : true) : false;
  }

  isAncillaryDiffAvailable(pnr: string, bkg:string): boolean {
    return pnr !== bkg;
  }

  isEnhancedRefreshFlow(): boolean {
    return ( this.ENHANCED_REFRESH_TPC_GDS_LIST.includes(this.crsCode.toUpperCase()) &&  !this.isManual );
  }

  isNewPassenger (changes: any[], type: string): boolean {
    const pax = changes.find(change => change.type === type &&  change?.changed && change.bkgVal === '');
    if(pax){
      return true;
    } else {
      return false;
    }
  }

  // convert booking dae format to the correct format
  convertDateFormat(dateStr: string): string {
    const [year, month, day] = dateStr.split('-');
    return `${day}/${month}/${year}`;
  }

  // toggle the collapse button for ancillary and passenger cards
  collapseChanges(type: string) {
    switch(type) {
      case 'ancillary':
        this.isAncillaryPassengerToggleOn = !this.isAncillaryPassengerToggleOn;
        break;
      case 'passenger':
        this.isPassengerToggleOn = !this.isPassengerToggleOn;
        break;
      default:
        // Handle an invalid type
        break;
    }
  }

  /**
   * checking for similar sectors even when the sector number is same
   * @param keys
   * @param key
   */
  foundSimilarSector(keys: any[], key: string): boolean {
    let found = false;
    keys.forEach(oldKey => {
      if (key.trim().split('~')[1] == oldKey.trim().split('~')[1]) {
        found = true;
      }
    })
    return found;

  }

  // toggle collapse for the flight sector cards
  collapseSelctor(index: number) {
    this.sectorHighlightMap.set(index, !this.sectorHighlightMap.get(index));
  }

  onRefreshSuccess(result: any) {

    if(this.isEnhancedRefreshFlow()) {
      this.setFlightListDetails();
    }

    /*Now there are no ...noRefresh changes scenario -> we are showing the comparison modal always, unless any error occurs*/
    if (result.data != undefined && result.data[0] != undefined && result.data[0].length > 0) {
      this.isAdvanceRefreshFlow = false;
      this.refreshApplyNotAllowed = this.isRefreshAllowed(result.data[0]);

      this.itemWiseRefreshChanges = [];

      /*There are changes to apply*/
      // this.noRefreshChanges = false;
      this.changeModalVisibility(PNR_MODALS.REFRESH_PNR_CHANGES, true);

      /*Get warning messages*/
      if (result.status !== undefined && result.status.message !== undefined && result.status.message !== '') {
        const msg = result.status.message.replace('\n', ''); // FC-34862: remove new lines from message
        this.refreshChangeList = msg.split('|');
      }

      /*Get changed attributes*/
      let no = 1;
      let hasAnyRefreshPNRTitleChanges: boolean = false;
      result.data[0].forEach(refreshedFltItem => {

        let itemNo = no;
        const attrs = refreshedFltItem.attributes;

        if (refreshedFltItem.productKey && refreshedFltItem.productKey.split('~')) {
          itemNo = refreshedFltItem.productKey.split('~')[1];
        }

        try {
          attrs.sort((a, b) => (Number(a.name.split('_')[1]) > Number(b.name.split('_')[1])) ? 1 : ((Number(b.name.split('_')[1]) > Number(a.name.split('_')[1])) ? -1 : 0));
        } catch (e) {
          console.log('Error while sorting refresh attributes');
        }

        /*process flight info and pax changes*/
        let refreshFlightInfoChanges = [];
        let refreshSectorInfoChanges = new Map();
        let updatedPNRRoutes: Map<any, any>;
        let refreshPaxChanges = new Map();

        // this.refreshPaxChanged = !(attrs != undefined && attrs.length == 0) && this.hasChangedAttributes(attrs);
        let refreshPaxChanged = false;
        if( !this.isManual ){
          refreshPaxChanged = !(attrs != undefined && attrs.length == 0) && this.hasChangedAttributes(attrs, itemNo);
        }
        if (!this.isAdvanceRefreshFlow) {
          this.isAdvanceRefreshFlow = attrs.find( attrsItem => attrsItem.name === 'ADVANCE_REFRESH_PNR' && attrsItem.value === 'TRUE' );
        }
        if (this.isAdvanceRefreshFlow) {
          let sectorCountGDS = this.getSectorCountInGDS( attrs);
          const sectorCountBooking = this.flightDetailMap.get(refreshedFltItem.productKey).length;
          attrs.forEach(attr => {
              const name = attr.name.split('_')[0];
              const type = attr.name.split('_')[2];
              if (name === 'SEC' && type === 'BAG') {
                attr.value = "";
              }
              if (name === 'SEC' && type === 'FDIC') {
                attr.value = "NONE";// make gds side flight direction unknown
              }
          })
          if (sectorCountGDS <= sectorCountBooking) {
            attrs.forEach(attr => {
              const name = attr.name.split('_')[0];
              const num = attr.name.split('_')[1];
              const type = attr.name.split('_')[2];
              if (name === 'SEC') {
                attr.value = this.getSectorValueByTypeName(this.flightDetailMap.get(refreshedFltItem.productKey)[Number(num) - 1], type) +  '~_~' + attr.value;
              }
            });
            if (sectorCountGDS < sectorCountBooking) {
                while (sectorCountGDS !== sectorCountBooking) {
                sectorCountGDS = sectorCountGDS + 1;
                this.generateSectorAttributes( sectorCountGDS, attrs, refreshedFltItem.productKey);
              }
            }

          } else if (sectorCountGDS > sectorCountBooking) {
            attrs.forEach(attr => {
              const name = attr.name.split('_')[0];
              const num = attr.name.split('_')[1];
              const type = attr.name.split('_')[2];
              if (name === 'SEC') {
                if (Number(num) <= sectorCountBooking) {
                  attr.value = this.getSectorValueByTypeName(this.flightDetailMap.get(refreshedFltItem.productKey)[Number(num) - 1], type) +  '~_~' + attr.value;
                } else {
                  attr.value =  this.dummyString + '~_~' + attr.value ;
                }
              }
            });
          }
        }

        if( !this.isManual ) {
          // decode ancillaries if available
          this.decodeAncillaries(attrs);
          // decode ticket details if avaiable
          this.decodeTicketDetails(attrs);
        }
        attrs.forEach(attr => {
          const name = attr.name.split('_')[0];
          const num = attr.name.split('_')[1];
          const type = attr.name.split('_')[2];

          let bkgVal = attr.value.split('~_~')[0];
          let pnrVal = attr.value.split('~_~')[1];

          if (name === 'PAX') {
            const changed = attr.name.split('_')[3] === 'C';
            const paxChangeItemNo = attr.name.split('_')[4];
            const paxType = this.getPaxTypeName(attr.name.split('_')[5]);

            if (type == 'CST' || type == 'PRC') {
              if (paxChangeItemNo != null && (paxChangeItemNo == itemNo)) {
                this.addToPaxChanges(refreshPaxChanges, num, type, bkgVal, pnrVal, changed, paxType);
              }
            } else {
              this.addToPaxChanges(refreshPaxChanges, num, type, bkgVal, pnrVal, changed, paxType);
            }

          } else if (name === 'SEC') {
            const typeName = this.getSectorChangeTypeName(type);
            if( ( typeName === 'Operating Airline' || typeName === 'Airline' ) && !this.isManual )
            {
              return;// this compare only for manual item
            }
            const sectorDesc = attr.name.split('_')[3];
            let secKey = num + '~' + sectorDesc;

            if (type == 'WS' && pnrVal == 'R') {
              secKey += '~R';
            }
            if (type == 'WS' && pnrVal == 'A') {
              secKey += '~A';
            }

            if (type === 'DEPDATE' || type === 'ARRDATE') {
              if (bkgVal.split('T')[1] && bkgVal.split('T')[1].length == 5) {
                bkgVal += ':00';
              }
              if (pnrVal.split('T')[1] && pnrVal.split('T')[1].length == 5) {
                pnrVal += ':00';
              }
            }

            if (type != 'VL') {
              let matchingKey: string;
              refreshSectorInfoChanges.forEach((value, key) => {
                if (key.includes(sectorDesc)) {
                  matchingKey = key;
                }
              });

              const sectorChange = {
                'type': type,
                'typeName': typeName,
                'bkgVal': bkgVal,
                'pnrVal': pnrVal,
                'sectorDesc': sectorDesc
              };

              if (!matchingKey) {
                const sectorChanges = [];
                sectorChanges.push(sectorChange);
                refreshSectorInfoChanges.set(secKey, sectorChanges);
              } else {
                refreshSectorInfoChanges.get(matchingKey).push(sectorChange);
              }
            }
          }
          else if (name === 'TTL'){
            const ttlItemNo = attr.name.split('_')[1];
            if (ttlItemNo == no) {
              refreshFlightInfoChanges.push({
                'type': 'TTL',
                'typeName': 'Ticketing deadline',
                'bkgVal': bkgVal,
                'pnrVal': pnrVal
              });
            }
          }
        });


// Adding all the non changed sector header names to the refreshSectorInfoChanges list in order to display in the enhanced refresh UI
        if(this.isEnhancedRefreshFlow()) {
          this.enhancedRefreshFlightSectorChangesAvailable = refreshSectorInfoChanges.size !==0;
          let flightDetailList = this.flightDetailMap.get(refreshedFltItem.productKey);

          // adding new entry to the refreshSectorInfoChanges map if the sector key is missing.
          for (let i = 0; i < flightDetailList.length; i++) {
            let key = '' + (i+1) + '~'+flightDetailList[i].departure + '-' + flightDetailList[i].arrival;
            if(!refreshSectorInfoChanges.has(key)) {
              let keys = Array.from(refreshSectorInfoChanges.keys())
              if (!this.foundSimilarSector(keys, key)) {
                refreshSectorInfoChanges.set(key, []);
              }
            }
          }

          // sorting the sector map according to the sector number
          const sortedSectorKeys = Array.from(refreshSectorInfoChanges.entries()).sort();
          refreshSectorInfoChanges = new Map(sortedSectorKeys);
        }

        updatedPNRRoutes = this.setRefreshSectorNewRoutes(refreshSectorInfoChanges);
        this.processNewlyAddedOrRemovedSectors(refreshSectorInfoChanges);
        console.log(refreshSectorInfoChanges);

        if (this.isEnhancedRefreshFlow()) {
          refreshSectorInfoChanges.forEach((value: any, key: string) => {
            // if there is a dep or des change in a sector it will be considered as a newly added sector.
            if(this.isSectorChangeAvailable(value, 'Destination') || this.isSectorChangeAvailable(value, 'Departure')) {

              let sectorKeyName = key.trim().split('~')[0] + '~' + updatedPNRRoutes.get(key) + '~A';
              refreshSectorInfoChanges.delete(key);
              refreshSectorInfoChanges.set(sectorKeyName, []);
              let flightDetailList = this.flightDetailMap.get(refreshedFltItem.productKey);

              // getting the missing sectors from the booking using a filter
              this.flightDetailMap.set(refreshedFltItem.productKey , this.flightDetailMap.get(refreshedFltItem.productKey).filter((flight) => flight.departure != key.trim().split('~')[1].trim().split('-')[0] &&
                  flight.arrival != key.trim().split('~')[1].trim().split('-')[1]));

            }
          });

          // sorting the sector map according to the sector number
          const sortedSectorKeys = Array.from(refreshSectorInfoChanges.entries()).sort();
          refreshSectorInfoChanges = new Map(sortedSectorKeys);

          updatedPNRRoutes = this.setRefreshSectorNewRoutes(refreshSectorInfoChanges);
        }
        this.refreshUpdateAvailable = false;
        this.paxChangesOtherThanPaxTitlesAfterRefreshPNR = false;
        this.paxTitleChangesAfterRefreshPNR = false;
        this.paxPriceChangesAfterRefreshPNR = false;
        this.hasFlightOrSectorInfoChangesAfterRefreshPNR = false;

        for (const key of refreshPaxChanges.keys()) {
          let bkgNameVal = '';
          let pnrNameVal = '';
          let paxType = '';
          let haveAtLeastOneChangePerPax = false;
          for (const change of refreshPaxChanges.get(key)) {
            if(change.changed){
              haveAtLeastOneChangePerPax = true;
              if (change.type !== 'TITLE' && change.type !== 'PRC' && change.type !== 'CST') {
                this.paxChangesOtherThanPaxTitlesAfterRefreshPNR = true;
              } else if (change.type === 'PRC' || change.type === 'CST') {
                this.paxPriceChangesAfterRefreshPNR = true;
              } else {
                this.paxTitleChangesAfterRefreshPNR = true;
              }
            }
            if (change.type === 'TITLE' || change.type === 'FIRSTNAME' || change.type === 'LASTNAME') {
              bkgNameVal = bkgNameVal + (change.bkgVal ? (change.bkgVal + ' ') : '');
              if(this.isEnhancedRefreshFlow()) {
                if(change.type === 'TITLE' && change.pnrVal && change.pnrVal == 'Master') {
                  pnrNameVal = pnrNameVal + (change.pnrVal ? ( 'Mstr' + ' ') : '');
                } else {
                  pnrNameVal = pnrNameVal + (change.pnrVal ? ( change.pnrVal + ' ') : '');
                }

              } else {
                pnrNameVal = pnrNameVal + (change.pnrVal ? (( change.changed ? ( change.pnrVal + '_C' ) : change.pnrVal ) + ' |') : '');
              }
            }

            if(change.changed && change.type === 'TITLE' && change.paxType && change.paxType !== this.paxTypesNames[3].name){
              hasAnyRefreshPNRTitleChanges = true;
            }

            if(change.paxType){
              paxType = change.paxType;
            }
          }

          if (!haveAtLeastOneChangePerPax && !this.isEnhancedRefreshFlow()) {
            refreshPaxChanges.delete(key);
          }

          if (refreshPaxChanges.get(key)) {
            refreshPaxChanges.get(key).unshift({
              type: 'NAME',
              bkgVal: bkgNameVal,
              pnrVal: pnrNameVal,
              changed: null,
              paxType: paxType
            });
          }
        }

        for (const key of refreshPaxChanges.keys()) {
          let pnrNameValue;
          let oldtravellerName;
          for (const change of refreshPaxChanges.get(key)) {
            if (change.type === 'NAME' && change.bkgVal) {
              pnrNameValue = change.bkgVal;

              if (pnrNameValue != undefined && this.travellers && this.travellers.length > 0) {
                this.travellers.forEach(traveller => {
                  oldtravellerName = traveller.profile.title + ' ' + traveller.profile.firstName + ' ' + traveller.profile.lastName;

                  if (pnrNameValue == oldtravellerName) {
                    change.paxType = traveller.profile.type;
                    change.isLead = traveller.lead;
                  }
                });
              }
            }
          }
        }

        let refreshPaxChangeKeys = Array.from(refreshPaxChanges.keys());
        let refreshSectorInfoChangeKeys = Array.from(refreshSectorInfoChanges.keys());

        let flightInfoChanged = refreshFlightInfoChanges.length > 0;
        let sectorInfoChanged = refreshSectorInfoChangeKeys.length > 0;

        this.itemWiseRefreshChanges.push({
          refreshFlightInfoChanges : refreshFlightInfoChanges,
          refreshSectorInfoChanges : refreshSectorInfoChanges,
          updatedPNRRoutes : updatedPNRRoutes,
          refreshPaxChanges : refreshPaxChanges,
          refreshPaxChangeKeys: refreshPaxChangeKeys,
          refreshSectorInfoChangeKeys: refreshSectorInfoChangeKeys,
          refreshPaxChanged : refreshPaxChanged,
          flightInfoChanged : flightInfoChanged,
          sectorInfoChanged : sectorInfoChanged,
          active : false,
          itemNo : itemNo,
          name : "Flight " + itemNo,
          content : "Flight " + itemNo + " content"
        });

        no++;
      });
      this.refreshPNRTitleChanged = hasAnyRefreshPNRTitleChanges;
      // if (this.itemWiseRefreshChanges && this.itemWiseRefreshChanges[0]) {
      //   this.itemWiseRefreshChanges[0].active = true;
      // }

      if(this.isEnhancedRefreshFlow()) {
        this.itemWiseRefreshChanges.forEach(item => {
          if (this.activeFlightTab == item.itemNo) {
            item.active = true;
          }
          if (item.refreshPaxChanged) {
            this.refreshUpdateAvailable = true;
            this.refreshPaxChanged = true;
          } else {
            this.refreshPaxChanged = false;
          }
        })
        if(this.ancillaryChangesAfterRefresh || this.enhancedRefreshFlightSectorChangesAvailable || this.ticketChangesAfterRefresh) {
          this.refreshUpdateAvailable = true;
        }
      } else {
        this.itemWiseRefreshChanges.forEach(item => {
          if (this.activeFlightTab == item.itemNo) {
            item.active = true;
          }
          if (
              (item.refreshSectorInfoChangeKeys && item.refreshSectorInfoChangeKeys.length > 0) ||   /*any sector wise change available*/
              (item.refreshFlightInfoChanges && item.refreshFlightInfoChanges.length > 0) ||        /*any general flight change available*/
              item.refreshPaxChanged) {                                                             /*any pax change available*/
            this.refreshUpdateAvailable = true;
          }
          if (
              (item.refreshSectorInfoChangeKeys && item.refreshSectorInfoChangeKeys.length > 0) ||   /*any sector wise change available*/
              (item.refreshFlightInfoChanges && item.refreshFlightInfoChanges.length > 0) ) {    /*any flight info change available*/
            this.hasFlightOrSectorInfoChangesAfterRefreshPNR = true;
          }
        });
      }

    } else {
      this.refreshError = true;
      this.refreshErrorMsg = this.TPC_SPECIFIC_LABEL_ENABLED? 'Error occurred in refreshing PNR / Order.' : 'Error occurred in refreshing PNR.';
    }

    if (this.isEnhancedRefreshFlow() && this.itemWiseRefreshChanges) {
      for (let i = 0; i < this.itemWiseRefreshChanges?.[0].refreshSectorInfoChangeKeys.length; i++) {
        this.sectorHighlightMap.set(i, false);
      }
    }

  }

  /**
   *  checking if the total price is changed
   * @param bkgValue
   * @param pnrValue
   */
  isThereTotalPriceChange(bkgValue: string, pnrValue: string): boolean {
    return bkgValue !== pnrValue;
  }

  /**
   * decode ancillaires, per-person price and the total price of the ancillaries
   * @param attribites
   * @see findTravellerName
   * @see decodeAncillarySector
   * @see isAnyDifferenceInPerPersonPrice
   * @see decodeAncillarySector
   * @see groupAncillariesByPaxName
   */
  decodeAncillaries(attribites: any) {

    if (!this.isDecodeAncillaryMethodExecuted) {
      this.ancillaries = [];
      this.perPersonAncillaryTotalList = [];
      this.ancillaryByPaxName.clear();
      attribites.forEach(attr => {
        const attributeName = attr.name.split('_')[0];
        if (attributeName === 'ANC') {
          // ancillaries
          if (!attr.name.split('_')[5] || (attr.name.split('_')[5] && attr.name.split('_')[5] !== 'PRICE' && attr.name.split('_')[5] !== 'TOTAL')) {
            // enabling apply button when ancillary changes are available
            this.ancillaryChangesAfterRefresh = true;
            const ancillary = new Ancillary();
            const paxNumber = attr.name.split('_')[2];
            //find the relevant passenger to the ancillary from the booking
            ancillary.paxName = this.findTravellerName(paxNumber);
            ancillary.sectorName = this.decodeAncillarySector(attr.name.split('_')[3]);
            const bkgVal = attr.value.split('~_~')[0];
            ancillary.bkgValue = new Value(bkgVal.split('-_-')[0], bkgVal.split('-_-')[1]);
            const pnrVal = attr.value.split('~_~')[1];
            ancillary.pnrValue = new Value(pnrVal.split('-_-')[0], pnrVal.split('-_-')[1]);
            ancillary.type = attr.name.split('_')[4];
            this.ancillaries.push(ancillary);
            // per-person price
          } else if (attr.name.split('_')[5] !== 'TOTAL') {

            const perPriceAncillaryTotal = new PerPersonAncillaryTotal();
            const paxNumber = attr.name.split('_')[2];
            // find the relevant passenger to the per-person price name code pair
            perPriceAncillaryTotal.paxName = this.findTravellerName(paxNumber);
            // find the relavant passenger type
            perPriceAncillaryTotal.type = this.findPassengerType(paxNumber);
            const bkgVal = attr.value.split('~_~')[0];
            perPriceAncillaryTotal.bkgValue = bkgVal;
            const pnrVal = attr.value.split('~_~')[1];
            perPriceAncillaryTotal.pnrValue = pnrVal;
            this.perPersonAncillaryTotalList.push(perPriceAncillaryTotal);
          } else {
            if(this.isThereTotalPriceChange(attr.value.split('~_~')[0], attr.value.split('~_~')[1])) {
              this.ancillaryChangesAfterRefresh = true;
            }
            // total price
            this.ancillaryTotalPrice = {
              bkgValue: attr.value.split('~_~')[0],
              pnrValue: attr.value.split('~_~')[1]
            }
          }
        }
      });

      // enabling per person collapse button
      this.isAnyDifferenceInPerPersonPrice(this.perPersonAncillaryTotalList);

      // grouping ancillaries by passenger name
      this.groupAncillariesByPaxName()
      // running the decoding method only once
      this.isDecodeAncillaryMethodExecuted = !this.isDecodeAncillaryMethodExecuted;

    }
    }
  // enable collapse if there is no difference in any Per-person price.
  isAnyDifferenceInPerPersonPrice(perPerson: PerPersonAncillaryTotal[]) {
    perPerson.forEach(item => {
      if(item?.pnrValue && item?.bkgValue && item?.bkgValue === item?.pnrValue) {
        this.toggleAncillaryCollapseButton = true;
      }
    })
  }

  /**
   * modify the sector information if there are more than one sector in one ancillary.
   * @param sector
   */
  decodeAncillarySector(sector: string) :string {
    if(sector.includes('|')) {
      const sectorsList = sector.split('|');
      let modofiedSector = '';
      sectorsList.forEach(sector => {
        modofiedSector += sector.split('-')[0] + ' - ';
      })
      modofiedSector += sectorsList[sectorsList.length - 1].split('-')[1];
      return modofiedSector;
    } else {
      return sector.split('-')[0] + ' - ' + sector.split('-')[1];
    }
  }

  /**
   * decode ticket numbers and the messages from the name code pair values
   * @param attributes
   */
  decodeTicketDetails(attributes: any) {
    this.ticketMessages = [];
    this.ticketNumbers = [];
    attributes.forEach(attr => {
      const attributeName = attr.name.split('_')[0];
      if (attributeName === 'TICKET') {
        this.ticketChangesAfterRefresh = true;
        if (!this.ticketNumbers.includes(attr.value)) {
          this.ticketNumbers.push(attr.value);
        }

      } else if (attributeName === 'TICKETMSG') {
        if (!this.ticketMessages.includes(attr.value)) {}
        this.ticketMessages.push(attr.value);
      }
    })
  }

  //method to group ancillaries by passenger name
  groupAncillariesByPaxName() {

    this.ancillaries.forEach(ancillary => {
      let paxName = ancillary.paxName;
      if(!this.ancillaryByPaxName.has(paxName)) {
        this.ancillaryByPaxName.set(paxName, []);
      }
      this.ancillaryByPaxName.get(paxName).push(ancillary);
    })
  }
  /**
   * find the passenger name matches the paxNumber from the booking
   * @param paxNumber
   */
  findTravellerName(paxNumber: number): string {
    let travellerName;
    this.travellers.forEach(traveller => {
      if (paxNumber == traveller.no) {
        travellerName =  traveller.profile.title + ' ' + traveller.profile.firstName + ' ' + traveller.profile.lastName;
      }
    });
    return travellerName;
  }

  /**
   * find the passenger type (Adult / Child / Infant) to display icon
   * @param paxNumber
   */
  findPassengerType(paxNumber: number): string {
    let paxType;
    this.travellers.forEach(traveller => {
      if(paxNumber == traveller.no) {
        paxType = traveller.profile.type;
        if(paxType == 'A'){
          paxType = 'Adult';
        }
        if(paxType == 'C'){
          paxType = 'Child';
        }
        if(paxType == 'I'){
          paxType = 'Infant';
        }
      }
    });
    return paxType;
  }

  generateSectorAttributes(sectorNumber: number, attrs: any, productKey: string ) {
    const directionKey = '_' + this.flightDetailMap.get(productKey)[sectorNumber - 1].departure + '-' + this.flightDetailMap.get(productKey)[sectorNumber - 1].arrival;
    this.SectorTypeNameList.forEach( type => {
      const newAttar = new NameValuePair('SEC_' + sectorNumber + '_' + type + directionKey,this.getSectorValueByTypeName(this.flightDetailMap.get(productKey)[sectorNumber - 1], type) + '~_~' + this.dummyString);
      attrs.push(newAttar);
    });
  }
  /**
   * get Sector Count In GDS
   * @param attrs
   */
  getSectorCountInGDS( attrs: any): number {
     let sectorCount = 0;
     attrs.forEach(attr => {
          const name = attr.name.split('_')[0];
          const num = attr.name.split('_')[1];
          if (name === 'SEC' && sectorCount < Number(num)) {
            sectorCount = Number(num);
          }
     });
     return sectorCount;
  }
  /**
   * process details of newly added or removed sectors
   * @param refreshSectorInfoChanges: initial changes list
   */
  processNewlyAddedOrRemovedSectors(refreshSectorInfoChanges: Map<any, any>) {
    try {
      refreshSectorInfoChanges.forEach((value, sectorKey) => {
        const sectorKeySplit = sectorKey.split('~');
        if (sectorKeySplit.length === 3 && (sectorKeySplit[2] === 'A' || sectorKeySplit[2] === 'R') && value && value.length > 0) {
          const sector = value.find(sectorChange => sectorChange.type === 'WS');

          const displayGroup1 = {
            depDate: '',
            depTerminal: '',
            arrDate: '',
            arrTerminal: ''
          };

          const displayGroup2 = {
            bookingClass: '',
            baggage: '',
            flightNumber: '',
            airlineLocator: '',
            equipment: ''
          };

          value.forEach(sectorChange => {
            switch (sectorChange.type) {
              case 'DEPDATE':
                displayGroup1.depDate = sectorChange;
                break;
              case 'DEPTRM':
                displayGroup1.depTerminal = sectorChange;
                break;
              case 'ARRDATE':
                displayGroup1.arrDate = sectorChange;
                break;
              case 'ARRTRM':
                displayGroup1.arrTerminal = sectorChange;
                break;
              case 'BKGCLS':
                displayGroup2.bookingClass = sectorChange;
                break;
              case 'BAG':
                displayGroup2.baggage = sectorChange;
                break;
              case 'FNO':
                displayGroup2.flightNumber = sectorChange;
                break;
              case 'AIRLOC':
                displayGroup2.airlineLocator = sectorChange;
                break;
              case 'EQUIP':
                displayGroup2.equipment = sectorChange;
                break;
            }
          });

          sector.displayGroup1 = displayGroup1;
          sector.displayGroup2 = displayGroup2;

          const newVal = [];
          newVal.push(sector);
          refreshSectorInfoChanges.set(sectorKey, newVal);
        }
      });
    } catch (e) {
      console.log('Error while processing newly added PNR sector details: ' + e);
    }
  }

  /**
   * set new routes of the PNR sectors
   * @param refreshSectorInfoChanges: initial changes list
   */
  setRefreshSectorNewRoutes(refreshSectorInfoChanges: Map<any, any>): Map<any, any> {
    const updatedPNRRoutes = new Map();
    try {
      refreshSectorInfoChanges.forEach((value, key) => {
        const bookingRoute = key.split('~')[1];
        const bookingDep = bookingRoute.split('-')[0];
        const bookingDes = bookingRoute.split('-')[1];
        let pnrDep = '';
        let pnrDes = '';

        value.forEach(sectorChange => {
          if (sectorChange.type === 'DEP') {
            pnrDep = sectorChange.pnrVal;
          } else if (sectorChange.type === 'DES') {
            pnrDes = sectorChange.pnrVal;
          }
        });

        const newRoute = (pnrDep ? pnrDep : bookingDep) + '-' + (pnrDes ? pnrDes : bookingDes);
        updatedPNRRoutes.set(key, newRoute);
      });
    } catch (e) {
      console.log('Error while setting refresh PNR new routes: ' + e);
    }

    return updatedPNRRoutes;
  }

  addToPaxChanges(refreshPaxChanges, num, type, bkgVal, pnrVal, changed, paxType) {
    let key = num + ' (' + paxType + ')';
    if (refreshPaxChanges.get(key) === undefined) {
      const paxChanges = [];
      paxChanges.push({
        'type': type,
        'bkgVal': bkgVal,
        'pnrVal': pnrVal,
        'changed': changed,
        'paxType': paxType
      });
      refreshPaxChanges.set(key, paxChanges);
    } else {
      refreshPaxChanges.get(key).push({
        'type': type,
        'bkgVal': bkgVal,
        'pnrVal': pnrVal,
        'changed': changed,
        'paxType': paxType
      });
    }
  }

  onRefreshFails(error, stage){
    // this.changeModalVisibility(PNR_MODALS.REFRESH_PNR_CHANGES, true);
    this.refreshError = true;
    if (error && error.tcResultError && error.tcResultError.message && error.tcResultError.message.length > 0) {
      this.refreshErrorMsg = error.tcResultError.message;
    }
    else if (error && error.httpResponse && error.httpResponse.error && error.httpResponse.error.error && error.httpResponse.error.error.message) {
      this.refreshErrorMsg = error.httpResponse.error.error.message;
    }
    else {
      if (stage === 'refresh') {
        this.refreshErrorMsg = this.TPC_SPECIFIC_LABEL_ENABLED? 'Error occurred in refreshing PNR / Order.' : 'Error occurred in refreshing PNR.';
      } else if (stage === 'apply') {
        this.refreshErrorMsg = this.TPC_SPECIFIC_LABEL_ENABLED? 'Error occurred in applying PNR / Order details to the booking.' : 'Error occurred in applying PNR details to the booking.';
      }
    }

    if (this.refreshErrorMsg.includes('NO MATCH FOR RECORD LOCATOR') || this.refreshErrorMsg.includes('SECURED PNR')) {
      this.refreshErrorMsg = 'No match for record locator.';
    }
    else if (this.refreshErrorMsg.includes('INVALID RECORD LOCATOR')) {
      this.refreshErrorMsg = 'Invalid record locator.';
    }
  }

  hasChangedAttributes(attrList, itemNo): boolean {
    let found = false;
    attrList.forEach(atr => {
      if (
        (!(atr.name.split('_')[2] == 'CST' || atr.name.split('_')[2] == 'PRC') && atr.name.split('_')[3] && atr.name.split('_')[3] === 'C')
        ||
        ((atr.name.split('_')[2] == 'CST' || atr.name.split('_')[2] == 'PRC') && (atr.name.split('_')[4] == itemNo))
      ) {
        found = true;
      }
    });
    return found;
  }

  /**
   * Check if entered pnr is empty
   */
  isValidPnrNumber(): boolean {
    if (!this.tempPnrNumber || this.tempPnrNumber.length <= 0 || !this.isValidPnrInput) {
      this.externalError = true;
      return false;
    }
    else if (!this.booking) {
      if (this.booking && (this.booking.products.findIndex(product => product.detail? (product.detail.crsReference === this.tempPnrNumber.toUpperCase() && product.summary.productStatus !== PRODUCT_STATUS.CANCELLED): false) > -1)) {
        this.showPNRisSameDialog = true;
        return false;
      }
      /*For DP*/
      else if (this.isValidDpBooking() &&
        (this.booking.products[0].products.findIndex(product => product.detail? (product.detail.crsReference === this.tempPnrNumber.toUpperCase() && product.summary.productStatus !== PRODUCT_STATUS.CANCELLED): false) > -1) ){
        this.isDP = true;
        this.showPNRisSameDialog = true;
        return false;
      }
    }
    return true;
  }

  isValidDpBooking(): boolean {
    return this.booking &&
      this.booking.products &&
      this.booking.products[0] &&
      this.booking.products[0].productCode == 'TMD' &&
      this.booking.products[0].products &&
      this.booking.products[0].products.length > 0;
  }

  /**
   * set PNR params for pnr-details and flight products calls
   * @see <server>/flight-search/docs - /v2/products
   * @see <server>/flight-search/docs - /v2/products/pnr-details
   * [pnr~privateFareType~gdsRef~pseudoCity~useGDSPriceRecord~bookingDate~validateWithBookedPNRs]
   * [0  ~1              ~2     ~3         ~4                ~5          ~6                     ]
   */
  setGrabPnrParameters(): void {
    const params = new Array(7);
    params[0] = this.tempPnrNumber;
    params[2] = this.flightGDS;
    params[4] = true;
    params[6] = this.validateVsOldBookings;
    const paramString = params.join('~');
    this.flightCriteria.pnrInfo = paramString;
    this.flightGrabPnrCriteria.gdsRef = this.flightGDS;

    if (this.dataStore.get(DataKey.emulating).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
      const emulatingTC = this.dataStore.get(DataKey.emulatingTCProfile).getValue();
      this.flightGrabPnrCriteria.cmp = emulatingTC.controls.cmp;
      this.flightGrabPnrCriteria.div = emulatingTC.controls.div;
      this.flightGrabPnrCriteria.brand = emulatingTC.controls.brand;
      this.flightGrabPnrCriteria.channel = emulatingTC.controls.channel;
      this.flightGrabPnrCriteria.cliGrp = emulatingTC.controls.cliGrp;
      this.flightGrabPnrCriteria.cliId = emulatingTC.summary.id;
    } else if (!this.dataStore.get(DataKey.keyControls).getValue()) {
      this.flightGrabPnrCriteria.cmp = this.user.userDetail.defaultCom.code;
      this.flightGrabPnrCriteria.div = this.user.userDetail.defaultDivison.code;
      this.flightGrabPnrCriteria.brand = this.user.userDetail.defaultBrand.code;
      this.flightGrabPnrCriteria.channel = this.user.userDetail.defaultChannel.code;
      this.flightGrabPnrCriteria.cliGrp = this.user.clientGroup;
      this.flightGrabPnrCriteria.cliId =  this.user.clientId;
    }

    // from booking or cart
    if (this.dataStore.get(DataKey.keyControls).getValue()) {
      const keyControls = this.dataStore.get(DataKey.keyControls).getValue();
      this.flightGrabPnrCriteria.brand = keyControls.brand;
      this.flightGrabPnrCriteria.cmp = keyControls.cmp;
      this.flightGrabPnrCriteria.div = keyControls.div;
      this.flightGrabPnrCriteria.channel = keyControls.channel;
      this.flightGrabPnrCriteria.cliGrp = keyControls.cliGrp;
    }
    this.flightGrabPnrCriteria.pnr = this.tempPnrNumber;
    this.flightGrabPnrCriteria.useGDSPriceRecord = this.usePNRPriceRecord;

    if(this.isGrabWithPrice){
      this.flightGrabPnrCriteria.manBkgItm = !(this.isGrabWithPrice)
      this.flightGrabPnrCriteria.validateWithBookedPNRs = this.validateVsOldBookings;
    }else{
      this.flightGrabPnrCriteria.manBkgItm = !(this.isGrabWithPrice)
      this.flightGrabPnrCriteria.validateWithBookedPNRs = false;
    }
  }

  /**
   * set PNR params for Refresh PNR
   * @see <server>/flight-search/docs - /v2/products
   * @see <server>/flight-search/docs - /v2/products/pnr-details
   * [actions, bkgId, productKey, cmp, div]
   */
  setRefreshPnrParameters(productKey?: string): void {
    this.flightGrabPnrCriteria.actions = [];
    this.flightGrabPnrCriteria.actions.push(PNR_CRITERIA_ACTION.REFRESH);
    this.flightGrabPnrCriteria.bkgId = this.bkgId;
    this.flightGrabPnrCriteria.brand = this.user.userDetail.defaultBrand.code;
    if (this.dataStore.get(DataKey.emulating).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
      const emulatingTC = this.dataStore.get(DataKey.emulatingTCProfile).getValue()
      this.flightGrabPnrCriteria.cmp = emulatingTC.controls.cmp;
      this.flightGrabPnrCriteria.div = emulatingTC.controls.div;
      this.flightGrabPnrCriteria.brand = emulatingTC.controls.brand;
    } else if (!this.dataStore.get(DataKey.keyControls).getValue()) {
      this.flightGrabPnrCriteria.cmp = this.user.userDetail.defaultCom.code;
      this.flightGrabPnrCriteria.div = this.user.userDetail.defaultDivison.code;
      this.flightGrabPnrCriteria.brand = this.user.userDetail.defaultBrand.code;
    }

    // from booking or cart
    if (this.dataStore.get(DataKey.keyControls).getValue()) {
      const keyControls = this.dataStore.get(DataKey.keyControls).getValue();
      this.flightGrabPnrCriteria.brand = keyControls.brand;
      this.flightGrabPnrCriteria.cmp = keyControls.cmp;
      this.flightGrabPnrCriteria.div = keyControls.div;
    }
    if (productKey) {
      this.flightGrabPnrCriteria.productKey = productKey;
    } else {
      this.flightGrabPnrCriteria.productKey = this.productKey;
    }
  }

  /**
   * create new FlightComponentCriteria for surf-flight-criteria component
   * @see setFlightComponentCriteria
   * @see PnrSector
   * @param sector - PnrSector
   */
  createNewLeg(sector: PnrSector): FlightComponentCriteria {
    const leg = new FlightComponentCriteria();
    if (sector && sector.departureDateTime && sector.from && sector.to) {
      leg.departureDate = sector.departureDateTime.split('T')[0];

      leg.departureAirportCode = sector.departure;
      leg.departureAirportName = sector.from.airportName;
      leg.departureAirportCityCode = sector.from.cityCode;
      leg.departureAirportCity = sector.from.cityName;
      leg.departureAirportCountry = sector.from.countryCode;
      leg.departureAirportCountryName = sector.from.countryName;

      leg.arrivalAirportCode = sector.arrival;
      leg.arrivalAirportName = sector.to.airportName;
      leg.arrivalAirportCity = sector.to.cityName;
      leg.arrivalAirportCityCode = sector.to.cityCode;
      leg.arrivalAirportCountry = sector.to.countryCode;
      leg.arrivalAirportCountryName = sector.to.countryName;

      leg.combineWith = sector.combineWith;
    }
    return leg;
  }

  /**
   * Clear subscriptions before search initiation
   * @see onClickSearchPNR
   */
  initSearch(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = new Subscription();
    this.isLoaded = false;
    this.resultsNotFound = false;
    this.dataStore.set(DataKey.grabPNRDetails, null);
    if (this.grabType !== GRAB_PNR_TYPE.REFRESH) {
      this.doDisableInputs();
    }
  }

  doDisableInputs(): void {
    this.disableInputs = true;
  }

  /**
   * Output the grabbed PNR criteria
   * @see PnrComponentOutput
   */
  emitComponentCriteria(dpSwitch?: boolean): void {
    const pnrComponentOutput = new PnrComponentOutput();
    pnrComponentOutput.flightComponentCriteria = this.flightComponentCriteria;
    pnrComponentOutput.flightGETCriteria = this.flightCriteria;
    pnrComponentOutput.flightGrabPnrCriteria = this.flightGrabPnrCriteria;
    pnrComponentOutput.pnrNumber = this.selectedPnrNumber;
    pnrComponentOutput.pnrStage = this.stage;
    pnrComponentOutput.passengerCount = this.passengerCount;
    if(this.flightGrabPnrResult){
      pnrComponentOutput.grabPax = this.flightGrabPnrResult.passengers;
      pnrComponentOutput.multiValidatingCarriers = this.flightGrabPnrResult.multiValidatingCarriers;
      pnrComponentOutput.priceRecordsStored = this.flightGrabPnrResult.priceRecordsStored;
      pnrComponentOutput.proceedToDP = this.userProceedsToDP;
      this.tempPnrComponentOutput = pnrComponentOutput;
      if(!dpSwitch){
        this.pnrComponentOutput.emit(pnrComponentOutput);
      }
    }
  }

  /**
   * Focus PNR id input at modal animation end
   */
  setFocusInput(): void {
    setTimeout( () => {
      const el = document.getElementById(this.inputElementId) as HTMLInputElement;
      if(el){
        el.focus();
        el.setSelectionRange(0, el.value.length);
      }
    }, 150);
  }

  /**
   * create FlightComponentCriteria for each flight sector
   * @see onResultsHandler
   * @see FlightComponentCriteria
   */
  setFlightComponentCriteria(): void {
    const sectors = this.flightGrabPnrResult.sectors as Array<PnrSector>;
    const criteria = new FlightCriteria();
    criteria.legs = new Array<FlightComponentCriteria>();
    if (sectors && sectors.length > 0) {
      sectors.forEach((sector) => {
        const leg = this.createNewLeg(sector);
        criteria.legs.push(leg);
      });
    }
    this.flightComponentCriteria = criteria;
  }

  /**
   * set searchSegments for flight-refine-search component to populate criteria
   */
  setRefineHelperParams() {
    const sectors = this.flightGrabPnrResult.sectors as Array<PnrSector>;
    const segmentArray = sectors.map( (sector) => {
      return this.createSearchSegment(sector);
    });
    this.flightCriteria.searchSegments = segmentArray.join(FLIGHT_SEARCH_SEGMENT_SPLITTERS.LEG_SPLITTER) as string;
  }

  /**
   * Create search segment object from refine search
   * [departureDate,departureTime,departureAirport,destinationAirport,flightType,airline,cabinClass,connectionPoints]
   */
  createSearchSegment(sector: PnrSector): string {
    let segment = '';
    const splitter = FLIGHT_SEARCH_SEGMENT_SPLITTERS.SEGMENT_SPLITTER;
    segment += sector.departureDateTime.split('T')[0];
    segment += splitter.repeat(2) + sector.departure;
    segment += splitter + sector.arrival + splitter.repeat(4);
    return segment;
  }

  /**
   * Set passenger/guest count
   * surf-pax-selection component is will be hidden and guest count is displayed in readonly mode
   */
  setPassengerDetails(): void {
    const passengers = this.flightGrabPnrResult.passengers as Array<any>;
    this.passengerCount = (passengers && passengers.length > 0) ? passengers.length : 0;
    if (this.grabType === GRAB_PNR_TYPE.BOOKING || this.grabType === GRAB_PNR_TYPE.QUOTE) {
      this.grabPnrBookingModule.pnrTravellers = this.clone(passengers);
    }
  }

  setValidInput(event) {
    this.isValidPnrInput = event;
  }

  resetError(): void {
    this.externalError = false;
    this.invalidErrorMsg = this.invalidErrorMsgDefault;
  }

  onClickCancelTraveller() {
    this.initBookingType();
    this.onClickRemove();
    this.changeModalVisibility(PNR_MODALS.TRAVELLER_COMPARISON, false);
  }

  /**
   * if the user accept in pnr comparison view
   * check other attributes in booking traveller and pnr traveller are matching
   * if matching warning will be displayed
   * else continue
   */
  onClickAcceptTravellerChanges() {
    // check for changes and give warning if there are changes
    this.checkMismatch();
    if(!this.isGrabWithPrice && !this.isMismatchDetailWarning){
      this.showManualItemConfig = true;
      this.onClickCancelTraveller();
      this.showManualItemOutput.emit(this.showManualItemConfig);
      return
    }
    if (this.isMismatchDetailWarning === false) {
      this.onClickContinueTraveller('confirm');
    }
  }

  onClickContinueTraveller(event) {
    if ( this.grabType === GRAB_PNR_TYPE.QUOTE ) {
      this.flightCriteria.quote = true;
    }
    if (event === 'confirm') {
      if (this.userProceedsToDP &&  ( this.grabType === GRAB_PNR_TYPE.BOOKING || this.grabType === GRAB_PNR_TYPE.QUOTE)) {
        /*Booking grab for multi validating carriers - should navigate to tailor made result page*/
        if (this.tempPnrComponentOutput) {
          this.tempPnrComponentOutput.paramString = this.grabPnrBookingModule.getDPParamString(
            this.flightCriteria,
            this.passengerCount,
            this.bkgId
          );

          this.pnrComponentOutput.emit(this.tempPnrComponentOutput);
        }
      } else {
        /*Booking grab for same validating carriers - should navigate to flight component result page*/
        if(!this.isGrabWithPrice){
          this.showManualItemConfig = true;
          this.showManualItemOutput.emit(this.showManualItemConfig);
          this.isMismatchDetailWarning = false;
        }else{
          this.grabPnrBookingModule.continueToFlightResults(this.flightCriteria, this.passengerCount, this.bkgId);
        }
      }
    } else if (event === 'cancel') {
      this.isMismatchDetailWarning = false;
      this.onClickCancelTraveller();
    }
  }

  clone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  checkMismatch() {
    for ( let i = 0; i < this.grabPnrBookingModule.matchingTravellers.length ; i++) {
      // for matching travellers check other attributes have a difference
      if ( this.grabPnrBookingModule.matchingTravellers[i].diffs.hasMatch === true) {
        if ( Object.keys(this.grabPnrBookingModule.matchingTravellers[i].diffs).filter(d => this.grabPnrBookingModule.matchingTravellers[i].diffs[d] === false ).length >= 1 ) {
          this.changeModalVisibility(PNR_MODALS.TRAVELLER_COMPARISON, false);
          // give a warning if there are changes
          this.isMismatchDetailWarning = true;
          break;
        }
      }
    }
  }

  getTelephoneNumber(traveller: Traveller) {
    const phone = this.grabPnrBookingModule.getTravellerContact(traveller, 'Telephone' );
    if (phone != null) {
      // FC-35870: Need to remove '+' value here due to a inconsistency in sabre and amadeus grab flows
      return (phone && phone.value && phone.value.length > 0) ? phone.value.replace('+', '') : 'N/A';
    }
    return 'N/A';
  }

  isHidePhoneNumber() {
    return this.FLT_PAX_CONTACT_TP_MAPPING_DISABLED_CRS.includes(this.crsCode.toUpperCase());
  }

  /**
   * check both travellers emails are there
   */
  checkEmail(bkgTraveller: Traveller, pnrTraveller: Traveller ) {

    let email = this.grabPnrBookingModule.getTravellerContact(bkgTraveller, 'E-mail' );
    let pnrEmail = this.grabPnrBookingModule.getTravellerContact(pnrTraveller, 'E-mail' );

    // if both emails are empty return false
    if ((!pnrEmail || !pnrEmail.value || pnrEmail.value.length === 0) && (!email || !email.value || email.value.length === 0)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Return the email of the traveller
   */
  getEmail(traveller: Traveller) {
    const email = this.grabPnrBookingModule.getTravellerContact(traveller, 'E-mail' );
    if ( email != null) {
      return (email && email.value && email.value.length > 0) ? email.value : 'N/A';
    }
    return 'N/A';
  }
  /**
   * This is to fix the UI issue which happens
   * when the modals are not being added in the body level
   * this will append modals in this component to the HTML <body></body>
   */
  rearrangeModals() {
    this.getModalIdList().forEach((modalId) => {
      if (document.getElementById((modalId + this.randomID))) {
        document.body.appendChild(document.getElementById((modalId + this.randomID)));
      }
    });
  }

  removeModals() {
    this.getModalIdList().forEach((modalId) => {
      if (document.getElementById((modalId + this.randomID))) {
        document.body.removeChild(document.getElementById((modalId + this.randomID)));
      }
    });
  }

  getModalIdList(): any {
    const modalsIds = [];
    modalsIds.push(this.GRAB_PNR_MODAL_ID);
    modalsIds.push(this.GRAB_PNR_ERROR_MODAL_ID);
    modalsIds.push(this.DP_GRAB_PNR_WARNING_MODAL_ID);
    modalsIds.push(this.PNR_ALREADY_EXISTS_IN_A_BOOKING_MODAL_ID);
    modalsIds.push(this.DP_GRAB_SWITCH_WARNING_MODAL_ID);
    modalsIds.push(this.TRAVELLER_COMPARISON_MODAL_ID);
    modalsIds.push(this.REFRESH_COMPARISON_MODAL_ID);
    modalsIds.push(this.MANUAL_REFRESH_COMPARISON_MODAL_ID);
    modalsIds.push(this.MANUAL_REFRESH_COMPARISON_MODAL_TCP_ID);
    modalsIds.push(this.APPLY_CHANGES_SUCCESS_MODAL_ID);
    modalsIds.push(this.APPLY_CHANGES_ERROR_MODAL_ID);
    modalsIds.push(this.UPDATE_TRAVELLER_WARNING_MODAL_ID);
    // modalsIds.push(this.APPLY_CHANGES_NO_CHANGE_MODAL_ID);

    return modalsIds;
  }

  /**
   * child infant teen travellers
   * @param traveller - pnr traveller
   */
  getPaxTypeDisplayText(traveller: Traveller): string {
    if (traveller && traveller.profile && traveller.profile.type ) {
      const type = this.paxTypesNames.find((item) => (item.code === traveller.profile.type));
      if (type && type.code === this.paxTypesNames[2].code && traveller.profile.age > this.childTravellerUpperAgeLimit) {
        return this.paxTypesNames[1].name;
      }
      return type.name;
    }
    return '';
  }

  /**
   * show hide modals
   * @see PNR_MODALS
   * @param modalType - type
   * @param display - whether to show or hide modal
   */
  changeModalVisibility(modalType: PNR_MODALS, display: boolean) {
    switch (modalType) {
      case PNR_MODALS.GRAB_PNR:
        this.showGrabPnrModal = display;
        this.setFocusInput();
        break;
      case PNR_MODALS.TRAVELLER_COMPARISON:
        this.showTravellerChangesModal = display;
        break;
      case PNR_MODALS.PNR_ALREADY_EXISTS_IN_A_BOOKING:
        this.PNRExistsInAnotherBookingMessageVisible = display;
        break;
      case PNR_MODALS.REFRESH_PNR_CHANGES:
        this.showRefreshChangesModal = display;
        break;
    }
    if (display) {
      this.renderer.addClass(document.body, 'is-itinerary-modal-active');
    } else {
      this.renderer.removeClass(document.body, 'is-itinerary-modal-active');
    }
    this.surfActionAlertService.triggerGlobalAlertEventHandler({
      event: 'HIDE_DOM',
      hideDOM: this.showGrabPnrModal
    });
  }

  closeRefreshPNRModal() {
    this.updateAvailableMessage = false;
    this.updateAvailableMessage = false;
    this.changeModalVisibility(PNR_MODALS.REFRESH_PNR_CHANGES, false);
  }
  applyPNRChangesAdvanceFlow(refreshType: string) {
    if (this.ADVANCE_REFRESH_SELECTED_ALL === refreshType) {
      this.selectedSectorList = [];
      this.itemWiseRefreshChanges.forEach( (flight,flightIndex) => {
        flight.refreshSectorInfoChangeKeys.forEach( (sectorItem,index) => {
          this.selectedSectorList.push( new NameValuePair(index,flightIndex) )
        });
      })
    }
    this.isContinueBtnTrigger = true;
    let valid = this.checkValidationErrorInSelected();
    if (!valid) {
      return;
    }
    this.applyPNRChanges(refreshType)
  }

  applyPNRChanges(refreshType: string){
    this.updateAvailableMessage = false;
    this.changeModalVisibility(PNR_MODALS.REFRESH_PNR_CHANGES, false);
    this.showLoader = true;
    this.loaderTitle = this.TPC_SPECIFIC_LABEL_ENABLED? 'Applying PNR / Order changes to the booking' : 'Applying PNR changes to the booking';

    const requestWrapper = new TBXRequestWrapper();
    const keyControls = new TbxKeyControlParameter();

    if (this.dataStore.get(DataKey.emulating).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
      const emulatingTC = this.dataStore.get(DataKey.emulatingTCProfile).getValue()
      keyControls.cmp = emulatingTC.controls.cmp;
      keyControls.div = emulatingTC.controls.div;
      keyControls.brand = emulatingTC.controls.brand;
      keyControls.channel = emulatingTC.controls.channel;
      keyControls.cur = emulatingTC.controls.cur;
      keyControls.cliGrp = emulatingTC.controls.cliGrp;
      keyControls.cliId = emulatingTC.summary.id;
    } else if (!this.dataStore.get(DataKey.keyControls).getValue()) {
      keyControls.cmp = this.user.userDetail.defaultCom.code;
      keyControls.div = this.user.userDetail.defaultDivison.code;
      keyControls.brand = this.user.userDetail.defaultBrand.code;
      keyControls.channel = this.user.userDetail.defaultChannel.code;
      keyControls.cur = this.user.userDetail.defaultCurrency.code;
      keyControls.cliGrp = this.user.clientGroup;
      keyControls.cliId = this.user.clientId;
    }

    // from booking or cart
    if (this.dataStore.get(DataKey.keyControls).getValue()) {
      const keyControlSet = this.dataStore.get(DataKey.keyControls).getValue();
      keyControls.cmp = keyControlSet.cmp;
      keyControls.div = keyControlSet.div;
      keyControls.brand = keyControlSet.brand;
      keyControls.channel = keyControlSet.channel;
      keyControls.cur = keyControlSet.cur;
      keyControls.cliGrp = keyControlSet.cliGrp;
      keyControls.cliId = keyControlSet.cliId;
    }
    keyControls.userId = this.user.id;
    keyControls.username = this.user.userSummary.username;

    let dummyProduct = new Product();
    dummyProduct.productCode = 'FLT';

    requestWrapper.keyControls = keyControls;
    if (this.ADVANCE_REFRESH_SELECTED === refreshType) {
      this.createSectorFromPNR(this.ADVANCE_REFRESH_SELECTED,this.selectedSectorList[0].value)
      requestWrapper.payload = this.flightProductUpdate;
    } else if (this.ADVANCE_REFRESH_SELECTED_ALL === refreshType) {
      this.createSectorFromPNR(this.ADVANCE_REFRESH_SELECTED_ALL,this.selectedSectorList[0].value)
      requestWrapper.payload = this.flightProductUpdate;
    } else {
      requestWrapper.payload = dummyProduct;
    }

    const params = new HttpParams()
      .set('bkgSource', 'TC')
      .set('itemSource', 'TC')
      .set('bkgId', String(this.bkgId))
      .set('actions', (this.ADVANCE_REFRESH_SELECTED === refreshType || this.ADVANCE_REFRESH_SELECTED_ALL === refreshType)?'ADVANCED_REFRESH_FROM_EXTERNAL_SOURCE':'REFRESH_FROM_EXTERNAL_SOURCE')
      .set('confirm', String(true))
      .set('expand', 'all');

    this.dataStore.set(DataKey.cart, null);
    this.updateBookingObserver = new Subscription();
    this.updateBookingObserver.add(this.cartServiceHandler.updateCart(String(-1), this.isAdvanceRefreshFlow ? this.flightProductUpdate.productKey:this.productKey, requestWrapper, params).subscribe(
      result => {
        if (result instanceof TcApiError || result instanceof TcHttpError) {
          this.showLoader = false;
          this.onRefreshFails(result, 'apply');
          this.updateBookingObserver.unsubscribe();
          this.showApplyChangesModal = false;
        } else if (result) {
          if( this.selectedSectorList.length > 0 && (this.ADVANCE_REFRESH_SELECTED === refreshType || this.ADVANCE_REFRESH_SELECTED_ALL === refreshType) ) {
            this.applyPNRChanges(refreshType)
          } else {
            this.showLoader = false;
            this.updateBookingObserver.unsubscribe();
            this.showApplyChangesModal = true;
          }
        }
    }));
  }

  getNameChanges(name: string) {
    if (name === undefined) {
      name = '';
    }
    return name.split('|');
  }

  hasChange(value: string) {
    if (value === undefined) {
      value = '';
    }

    return value.trim().split('_')[1] === 'C';
  }

  getValue(value: string): string {
    if (value === undefined) {
      value = '';
    }
    return value.split('_')[0];
  }

  getDate(value: string): string {
    if (value === undefined || value === '') {
      return '';
    }
    return value.split('/')[2] + '-' + value.split('/')[1] + '-' + value.split('/')[0];
  }

  reloadPage(){
    this.showApplyChangesModal = false;
    location.reload();
  }

  getPaxType(refreshPaxChanges):string {
    if(refreshPaxChanges && refreshPaxChanges.length > 0){
      for (const change of refreshPaxChanges) {
        if (change.type === 'NAME' && change.paxType) {
          if(change.paxType == 'A'){
            return ' / Adult';
          }
          if(change.paxType == 'C'){
            return ' / Child';
          }
          if(change.paxType == 'I'){
            return ' / Infant';
          }
        }
      }
    }
    return '';
  }

  setFlightListDetails(){
    this.samePnrGrabProductList = [];
    this.flightDetailMap.clear();
    this.surfClientTimezoneService.shoppingCart.products.forEach(product => {
        if (product.productCode === 'FLT' && product.detail?.crsCode === this.flightProduct.detail?.crsCode
            && product.detail?.crsReference === this.flightProduct.detail?.crsReference) {
          this.samePnrGrabProductList.push(product);
        }
        if (product.productCode === 'TMD') {
          product.products.forEach(productTMD => {
          if (productTMD.productCode === 'FLT' && productTMD.detail?.crsCode === this.flightProduct.detail?.crsCode
                  && productTMD.detail?.crsReference === this.flightProduct.detail?.crsReference) {
                this.samePnrGrabProductList.push(productTMD);
              }
          });
        }
        if(product?.detail?.crsReference)
        {
          this.orderId = product?.detail?.crsReference;
        }
        // setting order id when the booking is DP
      if(product?.productCode && product?.productCode === 'TMD')
      {
        if(product?.products && !product?.products.isEmpty) {
          product?.products.forEach(product => {
            if (product.productCode === 'FLT' && product?.detail?.crsReference)
            {
              this.orderId = product?.detail?.crsReference;
            }
          })
        }
      }
    });
    this.samePnrGrabProductList.forEach( grabFlight => {
        let flightDetailList:FlightDetail[] = [];
        let segmentList = new Array<any>();
        grabFlight.fareInfo.forEach( fareInfo => {
          fareInfo.flightOptions[0].segments.forEach( (segment,index) => {
            let segmentDetails =
              {
                ...segment,
                ...fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[index]
              }
            segmentList.push( segmentDetails );
          })
        })
        segmentList.forEach( fareInfo => {
            let flightDetail = new FlightDetail();
            if ( fareInfo.baggage instanceof Array ){
              if (fareInfo?.baggage && fareInfo.baggage?.length > 0 && fareInfo.baggage[0]?.bagWeight && fareInfo.baggage[0]?.weightUnit) {
                flightDetail.baggage = fareInfo.baggage[0]?.bagWeight + fareInfo.baggage[0]?.weightUnit
              } else if (fareInfo?.baggage && fareInfo.baggage?.length > 0 && fareInfo.baggage[0]?.bagCount) {
                flightDetail.baggage = fareInfo.baggage[0]?.bagCount + 'PC';
              } else if (fareInfo?.baggage && fareInfo.baggage?.length > 0 && fareInfo.baggage[0]) {
                flightDetail.baggage = "";
              } else {
                flightDetail.baggage = fareInfo?.baggage;
              }
            } else {
              flightDetail.baggage = fareInfo?.baggage;
            }
            flightDetail.bookingClass = fareInfo?.bookingClass;
            flightDetail.cabinClass = fareInfo?.cabinClass;
            flightDetail.isOutBound = fareInfo?.outbound;
            flightDetail.legNo = fareInfo?.legNo;
            flightDetail.airlineLocator = fareInfo?.airlineLocator;
            flightDetail.arrivalDateTime = fareInfo?.arrivalDateTime;
            flightDetail.departureDateTime = fareInfo?.departureDateTime;
            flightDetail.flightNumber = fareInfo?.flightNumber;
            flightDetail.airline = fareInfo?.marketingAirline;
            flightDetail.airlineOperator = fareInfo?.operatingAirline;
            flightDetail.arrival = fareInfo?.arrival;
            flightDetail.departure = fareInfo?.departure;
            flightDetail.departureTerminal = fareInfo?.depTerminal;
            flightDetail.arrivalTerminal = fareInfo?.desTerminal;
            if(fareInfo?.fareFamily) {
              flightDetail.fareFamily = fareInfo?.fareFamily;
            }
            if(  fareInfo?.equipment && fareInfo?.equipment.trim().length > 0 ){
              flightDetail.equipment = fareInfo?.equipment;
            }
            flightDetailList.push( flightDetail );
          })
        this.flightDetailMap.set(grabFlight.productKey,flightDetailList);

    })

  }
  createSectorFromPNR(refreshType: string,flightNo: string) {
    let tempFareInfo: Array<FareInfo> = [];
    this.flightProductUpdate = this.samePnrGrabProductList[flightNo];
    this.flightProductUpdate.fareInfo.forEach( fareInfo => {
      tempFareInfo.push(fareInfo);
    })
    let tempFlightProduct = JSON.parse(JSON.stringify(this.flightProductUpdate));
    this.selectedSectorList.filter( sector => sector.value === flightNo).forEach( sectorDetails => {
        if(tempFareInfo.find(fareInfo => fareInfo.searchSegmentIndex === Number(sectorDetails.name))) {
          const secKeyOriginal = this.itemWiseRefreshChanges[sectorDetails.value]?.refreshSectorInfoChangeKeys[sectorDetails.name];
          const sectorAttrListOriginal: any[] = this.itemWiseRefreshChanges[sectorDetails.value]?.refreshSectorInfoChanges?.get(secKeyOriginal);
          if (sectorAttrListOriginal[0]?.pnrVal === this.dummyString) {
            tempFlightProduct.fareInfo.splice( tempFlightProduct.fareInfo.findIndex( fareInfo => fareInfo.searchSegmentIndex === Number(sectorDetails.name)), 1);
          }
        } else {
          let fareInfo = JSON.parse(JSON.stringify(this.flightProductUpdate.fareInfo[0]));
          fareInfo.searchSegmentIndex = Number(sectorDetails.name);
          fareInfo.selectedFare.flightOptionDetails[0].key = '';
          fareInfo.selectedFare.selectedFlightOptionDetail.key = '';
          fareInfo.selectedFare.flightOptionDetails[0].segmentDetails[0].legNo = Number(sectorDetails.name) +1;
          fareInfo.selectedFare.flightOptionDetails[0].segmentDetails[0].sectorNo = Number(sectorDetails.name);
          fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[0].legNo = Number(sectorDetails.name) +1;
          fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[0].sectorNo = Number(sectorDetails.name);
          fareInfo.flightOptions[0].segments[0].itineraryOrder = Number(sectorDetails.name) +1;
          tempFlightProduct.fareInfo.push(fareInfo);
        }
    });
    this.flightProductUpdate = tempFlightProduct;
    this.selectedSectorList.filter( sector => sector.value === flightNo).forEach( sectorDetails => {
      this.flightProductUpdate.fareInfo.forEach( fareInfo => {
        fareInfo.flightOptions[0].segments.forEach( (segment,index) => {
          if(fareInfo.searchSegmentIndex === Number(sectorDetails.name)){
            const secKeyOriginal = this.itemWiseRefreshChanges[sectorDetails.value]?.refreshSectorInfoChangeKeys[sectorDetails.name];
            const sectorAttrListOriginal: any[] = this.itemWiseRefreshChanges[sectorDetails.value]?.refreshSectorInfoChanges?.get(secKeyOriginal);
            fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[index].bookingClass = sectorAttrListOriginal.find( attr => attr.type === "BKGCLS" ).pnrVal;
            fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[index].cabinClass = sectorAttrListOriginal.find( attr => attr.type === "CABCLS" ).pnrVal;
            fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[index].baggage = sectorAttrListOriginal.find( attr => attr.type === "BAG" ).pnrVal !== this.dummyString ? sectorAttrListOriginal.find( attr => attr.type === "BAG" ).pnrVal : 'NIL';
            fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[index].outbound = sectorAttrListOriginal.find( attr => attr.type === "FDIC" ).pnrVal === 'O';
            segment.airlineLocator = sectorAttrListOriginal.find( attr => attr.type === "AIRLOC" ).pnrVal;
            segment.arrivalDateTime = sectorAttrListOriginal.find( attr => attr.type === "ARRDATE" ).pnrVal;
            segment.departureDateTime = sectorAttrListOriginal.find( attr => attr.type === "DEPDATE" ).pnrVal;
            segment.flightNumber = sectorAttrListOriginal.find( attr => attr.type === "FNO" ).pnrVal;
            segment.marketingAirline = sectorAttrListOriginal.find( attr => attr.type === "AIR" ).pnrVal;
            segment.operatingAirline = sectorAttrListOriginal.find( attr => attr.type === "OPAIR" ).pnrVal;
            segment.arrival = sectorAttrListOriginal.find( attr => attr.type === "DES" ).pnrVal;
            segment.departure = sectorAttrListOriginal.find( attr => attr.type === "DEP" ).pnrVal;
            segment.depTerminal = sectorAttrListOriginal.find( attr => attr.type === "DEPTRM" ).pnrVal;
            segment.desTerminal = sectorAttrListOriginal.find( attr => attr.type === "ARRTRM" ).pnrVal;
          }
        })
      })
    });
    this.reorderFlightProductFareInfo();
    this.selectedSectorList = this.selectedSectorList.filter( sector => sector.value !== flightNo);
  }

  /**
   * reorder Flight Product Fare Info -----> rule --> manualy grab FLT should have only one segment in one fare info only.
   */
  reorderFlightProductFareInfo() {
    this.flightProductUpdate.fareInfo.forEach( (fareInfo,fareInfoIndex) => {
        fareInfo.selectedFare.flightOptionDetails[0].segmentDetails[0].sectorNo = fareInfoIndex; //only one Item should be in the list and this is fixed
        fareInfo.selectedFare.flightOptionDetails[0].segmentDetails[0].legNo = fareInfoIndex+1;
        fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[0].sectorNo = fareInfoIndex;
        fareInfo.selectedFare.selectedFlightOptionDetail.segmentDetails[0].legNo = fareInfoIndex+1;
        fareInfo.flightOptions[0].segments[0].itineraryOrder = fareInfoIndex+1;
    });
  }

  /**
   * get Sector Value By Type Name
   * @param flightDetail
   * @param type
   */
  getSectorValueByTypeName(flightDetail: FlightDetail, type: string): string {
   switch (type) {
      case 'DES':
        return flightDetail.arrival;
      case 'DEP':
        return flightDetail.departure;
      case 'FNO':
        return flightDetail.flightNumber;
      case 'DEPTRM':
        return flightDetail.departureTerminal;
      case 'ARRTRM':
        return flightDetail.arrivalTerminal;
      case 'BAG':
        return flightDetail.baggage;
      case 'AIRLOC':
        return flightDetail.airlineLocator;
      case 'DEPDATE':
        return flightDetail.departureDateTime;
      case 'ARRDATE':
        return flightDetail.arrivalDateTime;
      case 'WS':
        return 'Whole Sector';
      case 'BKGCLS':
        return flightDetail.bookingClass;
      case 'EQUIP':
        return flightDetail.equipment;
      case 'OPAIR':
        return flightDetail.airlineOperator;
      case 'AIR':
        return flightDetail.airline;
      case 'CABCLS':
        return flightDetail.cabinClass;
      case 'FDIC':
        return flightDetail.isOutBound? 'O':'I';
     case 'FFAMILY':
       return flightDetail.fareFamily;
    }
  }
  getSectorChangeTypeName(type): string {
    switch (type) {
      case 'DES':
        return 'Destination';
      case 'DEP':
        return 'Departure';
      case 'FNO':
        return 'Flight Number';
      case 'DEPTRM':
        return 'Departure Terminal';
      case 'ARRTRM':
        return 'Arrival Terminal';
      case 'BAG':
        return 'Baggage';
      case 'AIRLOC':
        return 'Airline Locator';
      case 'DEPDATE':
        return 'Departure Date & Time';
      case 'ARRDATE':
        return 'Arrival Date & Time';
      case 'WS':
        return 'Whole Sector';
      case 'BKGCLS':
        return 'Booking Class';
      case 'CABCLS':
        return 'Cabin Class';
      case 'FDIC':
        return 'Flight Direction';
      case 'EQUIP':
        return 'Equipment';
      case 'OPAIR':
        return 'Operating Airline';
      case 'AIR':
        return 'Airline';
      case 'FFAMILY':
        return 'Fare Family'
      // case 'VL':
      //   return 'VENDOR LOCATOR';
    }
  }

  isSelectedItem(sectorNo: number, flightNo: number) {
      return this.selectedSectorList.find( sector => Number(sector.name) === sectorNo && Number(sector.value) === flightNo );
  }
  /**
   *
   * @param sectorNo
   * @param flightNo
   */
  selectSectorForRefresh(sectorNo: number, flightNo: number) {
    const itemFound = this.selectedSectorList.find( sector => Number(sector.name) === sectorNo && Number(sector.value) === flightNo );
    if (itemFound) {
       this.selectedSectorList.splice(this.selectedSectorList.indexOf(itemFound), 1);
    } else {
       this.selectedSectorList.push( new NameValuePair(sectorNo,flightNo) )
    }
    // Sort the sectors numbers in ascending order:
    this.selectedSectorList.sort( (itemOne, itemTwo) => {return Number(itemOne.name)-Number(itemTwo.name)});
  }

  /**
   * up and down fuction control method
   * @param sectorNo
   * @param flightNo
   * @param isUp
   */
  downUpSector(sectorNo: number, flightNo: number, isUp: boolean) {
    const secKeyOriginal = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChangeKeys[sectorNo];
    const sectorAttrListOriginal: any[] = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChanges?.get(secKeyOriginal);
    let sectorAttrListTemp: any[];
    let secKeyTemp: string;
    if (isUp) {
      secKeyTemp = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChangeKeys[sectorNo - 1];
      sectorAttrListTemp = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChanges?.get(secKeyTemp);
    } else {
      secKeyTemp = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChangeKeys[sectorNo + 1];
      sectorAttrListTemp = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChanges?.get(secKeyTemp);
    }
    sectorAttrListTemp.forEach( sectorItemTemp => {
      sectorAttrListOriginal.forEach( sectorItemOriginal => {
        if (sectorItemTemp.type === sectorItemOriginal.type) {
          sectorItemTemp.bkgVal = sectorItemOriginal.bkgVal;
          sectorItemOriginal.bkgVal = this.dummyString;
        }
      });
    });
    this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChanges?.set(secKeyOriginal,sectorAttrListOriginal)
    this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChanges?.set(secKeyTemp,sectorAttrListTemp)
  }

  setBaggageValue(value: any,sectorNo: number,flightNo: number){
    const secKeyOriginal = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChangeKeys[sectorNo];
    const sectorAttrListOriginal: any[] = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChanges?.get(secKeyOriginal);

    sectorAttrListOriginal.forEach( sectorItemOriginal => {
          if ('BAG' === sectorItemOriginal.type) {
            sectorItemOriginal.pnrVal = value;
          }
      });
  }

  checkValidationErrorInSelected(){
     let valid = true;
     let sectorNo = 0;
     this.selectedSectorList.forEach( sector => {
       this.itemWiseRefreshChanges.forEach( (itemFlight,flightIndex) => {
          if (Number(sector.value) === flightIndex) {
            itemFlight.refreshSectorInfoChangeKeys.forEach( (sectorKey,sectorIndex) => {
             const sectorAttrListOriginal: any[] = this.itemWiseRefreshChanges[flightIndex]?.refreshSectorInfoChanges?.get(sectorKey);
             if (Number(sector.name) === sectorIndex) {
               sectorAttrListOriginal.forEach( sectorItemOriginal => {
                  if ('BAG' === sectorItemOriginal.type) {
                      const result = (new RegExp(this.regexForBaggageValidation)).test(sectorItemOriginal.pnrVal);
                      if ((!result || sectorItemOriginal.pnrVal === '') && sectorItemOriginal.pnrVal !== this.dummyString) {
                           valid = false;
                           sectorNo = Number(sector.name);
                      }
                  }
                  if ('FDIC' === sectorItemOriginal.type) {
                      if (sectorItemOriginal.pnrVal === 'NONE') {
                           sectorNo = Number(sector.name);
                           valid = false;
                      }
                  }
               });
             }
         })
          }
       })
     })
     if(!valid){
       this.scrollTo("SEGMENT-"+ (sectorNo+1));
     }
     return valid;
  }

  /**
   *
   * @param sectorNo
   * @param flightNo
   * @param type
   */
  hasError(sectorNo:number,flightNo:number,type:string): boolean{
    let valid = true;

    if (this.isSelectedItem(sectorNo,flightNo) ) {
        let sectorKey = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChangeKeys[sectorNo];
        const sectorAttrListOriginal: any[] = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChanges?.get(sectorKey);
        sectorAttrListOriginal.forEach( sectorItemOriginal => {
          if ('Baggage' === type) {
            const result = (new RegExp(this.regexForBaggageValidation)).test(sectorItemOriginal.pnrVal);
            if ((!result || sectorItemOriginal.pnrVal === '') && sectorItemOriginal.pnrVal !== this.dummyString) {
              valid =  false;
            }
          }
          if ('Flight Direction' === type) {
            if (sectorItemOriginal.pnrVal === 'NONE' || sectorItemOriginal.pnrVal === this.dummyString) {
              valid = false;
            }
          }
        });
    }
    return !valid;
  }

  /**
   *
   * @param sectorInfo
   * @param typeName
   */
  isChangeAvilable(sectorInfo : any , typeName : string ){
    return sectorInfo.find( item => item?.typeName === typeName && item.bkgVal !== item.pnrVal);
  }

  /**
   * Checking if there is a sector change
   * @param sectorInfo
   * @param typeName
   */
  isSectorChangeAvailable(sectorInfo : any , typeName : string ){
    return sectorInfo.find( item => item?.typeName === typeName);
  }


  /**
   *
   * @param sectorInfo
   * @param typeName
   */
  getSectorAttribute(sectorInfo : any , typeName : string ){
    return sectorInfo.find( item => item?.typeName === typeName);
  }

  onClickRadioBtn(value: any,sectorNo: number,flightNo: number) {
    if(this.isAdvanceRefreshFlow){
        const secKeyOriginal = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChangeKeys[sectorNo];
        const sectorAttrListOriginal: any[] = this.itemWiseRefreshChanges[flightNo]?.refreshSectorInfoChanges?.get(secKeyOriginal);
        sectorAttrListOriginal.forEach( sectorItemOriginal => {
          if ('FDIC' === sectorItemOriginal.type) {
                sectorItemOriginal.pnrVal = value;
          }
        });
    }
  }
  /**
   * scroll to given selector
   * @selector
   */
  scrollTo(selectorId) {
      let sector = document.getElementById(selectorId);
      sector.scrollIntoView();
  }
  getPaxTypeName(type): string {
    switch (type) {
      case 'A':
        return 'Adult';
      case 'C':
        return 'Child';
      case 'I':
        return 'Infant';
      default:
        return 'Adult';
    }
  }

  formatDate(date) {
    if (date == null || date == undefined) {
      return '';
    }
    return date.split('-')[0] + ' ' + date.split('-')[1] + ' ' + date.split('-')[2];
  }

  onClickOkPNRisSameDialog() {
    this.showPNRisSameDialog = false;
    this.showGrabPnrModal = false;
    this.onClickRefresh(true);
  }

  checkValue(event) {
    this.usePNRPriceRecord = event.target.checked;
  }

  grabAndSwitchToDP(event) {
    if (event == 'confirm') {
      this.showDPGrabSwitchWarning = false;
      /*grab again*/
      this.userProceedsToDP = true;
      this.onClickSearchPNR(true);
    } else if (event == 'cancel') {
      this.showDPGrabSwitchWarning = false;
    }
  }

  onFlightItemTabClick(index) {
    this.itemWiseRefreshChanges.forEach(item => {
      item.active = false;
    });
    this.itemWiseRefreshChanges[index].active = true;
  }

  isRefreshAllowed(itemList): boolean {
    let refreshApplyNotAllowed = false;
    itemList.forEach(item => {
      item.attributes.forEach(attr => {
        if (attr.name == 'STATUS' &&
          (attr.value == 2 || attr.value == 3 || attr.value == 4 || attr.value == 5 || attr.value == 6)) {
          refreshApplyNotAllowed = true;
        }
      });
    });

    return refreshApplyNotAllowed;
  }

  handlePNRExistsInAnotherBooking($event: {}) {
    if ($event === 'confirm') {
      this.closePNRExistsInBookingMessage();
      this.validateVsOldBookings = false;
      this.onClickSearchPNR();
      this.loaderTitle = 'Loading Results';
      this.showLoader = true;
    } else if ($event === 'cancel') {
      this.closePNRExistsInBookingMessage();
    }
  }

  openPNRExistsInBookingMessage() {
    this.changeModalVisibility(PNR_MODALS.PNR_ALREADY_EXISTS_IN_A_BOOKING, true);
  }

  closePNRExistsInBookingMessage() {
    this.validateVsOldBookings = true;
    this.changeModalVisibility(PNR_MODALS.PNR_ALREADY_EXISTS_IN_A_BOOKING, false);
  }

  renameTPCLabels(): void {
    this.grabBtnLabel = this.TPC_SPECIFIC_LABEL_ENABLED ? 'Grab Flight' : 'Grab PNR ';
    this.changePNRBtnLabel = this.TPC_SPECIFIC_LABEL_ENABLED ? 'Change PNR / Trip ID' : 'Change PNR';
    this.pnrWarningMessage =  this.TPC_SPECIFIC_LABEL_ENABLED ? 'PNR / Trip ID exists in another booking' : 'PNR exists in another booking';
  }
  loadConfigForBaggageRefExStr() {
    try {
      const baggageRegExStr = this.configLoader.getModuleConfig('SURF_BAGGAGE_FIELD_VALIDATION', TC.MODULE_NAME.SURF_B2B);
      if( baggageRegExStr && baggageRegExStr  !==  '' ) {
        this.regexForBaggageValidation = new RegExp(baggageRegExStr);
      }
    } catch ( e ) {
      console.error('Error in loading module config for baggage regex ' , e);
    }
  }

  /**
   * Loading the Divisions and brands into the local map for the TPC GDS enable
   * @private
   */
  private loadConfigForDivisionAndBrand() {
    try {
      const tpcDivBrandsStr = this.configLoader.getModuleConfig('TPC_GRAB_ENABLE_DIVISIONS_AND_BRANDS', TC.MODULE_NAME.SURF_B2B);
      const COMMA_SPLITTER = ',';
      const TILDA_SPLITTER = '~';
      const UNDERSCORE_SPLITTER = '_';
      if( tpcDivBrandsStr && tpcDivBrandsStr.trim() !== '' ) {
        this. tpcEnableDivAndBrandMap.clear();
        const divisionsVsBrandStr = tpcDivBrandsStr.split (COMMA_SPLITTER );
        divisionsVsBrandStr.forEach( divisionsBrandsElement => {
          const divisionsVsBrands = divisionsBrandsElement.split(TILDA_SPLITTER);
          if( divisionsVsBrands && divisionsVsBrands.length === 2 ) {
           const divisions = divisionsVsBrands[0].split( UNDERSCORE_SPLITTER );
           const brands = divisionsVsBrands[1].split( UNDERSCORE_SPLITTER );
           divisions.forEach( div => {
             const mapBrandsForDiv = this.tpcEnableDivAndBrandMap.get( div );
             if ( mapBrandsForDiv ) {
               brands.forEach( brand => {
                 if( !( brands.includes( brand ) ) ) {
                   mapBrandsForDiv.push( brand );
                 }
               });
             } else {
               this.tpcEnableDivAndBrandMap.set( div, brands );
             }
           });
          }
        });
      }
    } catch ( e ) {
      console.error('Error in loading module config TPC_GRAB_ENABLE_DIVISIONS_AND_BRANDS ' , e);
    }
  }

  private isValidGDS(gdsName) {
    if ( gdsName && 'TPCONNECTS' === gdsName.toUpperCase() ) {
      return this.validateUserBrandAndDivWithConfiguredBrandAndDiv();
    } else {
      return true;
    }
  }

  /**
   * Validate the Division and brand according to the cconfigured values
   * @private
   */
  private validateUserBrandAndDivWithConfiguredBrandAndDiv() {
    const userBrand = this.flightCriteria?.brand;
    const anyStr = 'any';
    const mappedBrandsForUserDivision = this.tpcEnableDivAndBrandMap.get( this.flightCriteria?.div );
    const mappedBrandsForAnyDivision = this.tpcEnableDivAndBrandMap.get(anyStr );
    let isValidGDSForTPC = false;
    if ( ( mappedBrandsForUserDivision && mappedBrandsForUserDivision.includes( userBrand ) ) || ( mappedBrandsForAnyDivision && mappedBrandsForAnyDivision.includes( userBrand ) )
      || ( mappedBrandsForUserDivision && mappedBrandsForUserDivision.includes( anyStr ) ) ||( mappedBrandsForAnyDivision && mappedBrandsForAnyDivision.includes( anyStr ) ) )
    {
      isValidGDSForTPC = true;
    }
    return isValidGDSForTPC;
  }

  private assignPnrExistMessage() {
    if( this.flightGDS === 'TPC' ) {
      this.PNR_EXISTS_IN_DIFFERENT_BOOKING_OR_QUOTE = this.TPC_SPECIFIC_LABEL_ENABLED ? 'This PNR/Trip ID exists in Quote/Booking ' : 'This PNR exists in Quote/Booking ';
    } else {
      this.PNR_EXISTS_IN_DIFFERENT_BOOKING_OR_QUOTE = 'This PNR exists in Quote/Booking ';
    }
  }

  getErrorTitle(): string {
    let errorMsg =  'Refresh PNR failed';
    if( this.crsCode === 'TPC' &&   this.TPC_SPECIFIC_LABEL_ENABLED && this.isManual ) {
      errorMsg =  'Refresh Order failed';
    }
    return errorMsg;
  }

  checkIfEachTravellerHasPrimaryMatch (): boolean {
    return this.grabPnrBookingModule?.matchingTravellers?.every((item) => {
      return item?.pnrTraveller && item?.bkgTraveller;
    });
  }
}
