import {NgModule} from '@angular/core';
import {PackageCriteriaComponent} from './package-criteria/package-criteria.component';
import {CommonModule} from '@angular/common';
import {SurfTypeAheadModule} from '@surf/surf-type-ahead';
import {SurfComponentsCoreModule, SurfCriteriaUtil, SurfTextCasePipeModule} from '@surf/surf-components-core';
import {SurfPaxSelectionModule} from '@surf/surf-pax-selection';
import {SurfDatePickerModule} from '@surf/surf-date-picker';
import {SurfMultiFieldInputModule} from '@surf/surf-multi-field-input';
import {SurfDropDownModule} from "@surf/surf-drop-down";
import {SurfInputModule} from "@surf/surf-input";
import {SurfTravellerInterestsModule} from '@surf/surf-traveller-interests';
import {SurfRadioButtonModule} from "@surf/surf-radiobutton";

@NgModule({
  declarations: [PackageCriteriaComponent],
    imports: [
        CommonModule,
        SurfTypeAheadModule,
        SurfTextCasePipeModule,
        SurfPaxSelectionModule,
        SurfDatePickerModule,
        SurfMultiFieldInputModule,
        SurfDropDownModule,
        SurfInputModule,
        SurfTravellerInterestsModule,
        SurfComponentsCoreModule,
        SurfRadioButtonModule
    ],
  exports: [PackageCriteriaComponent],
  providers:[SurfCriteriaUtil]
})
export class SurfPackageCriteriaModule { }
