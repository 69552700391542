import {NgModule, OnInit} from '@angular/core';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import {CommonModule} from "@angular/common";
import {SurfDropDownModule} from '@surf/surf-drop-down';
import {SurfComponentsCoreModule, SurfScriptOperationsUtil} from '@surf/surf-components-core';

@NgModule({
  declarations: [SearchPanelComponent],
  imports: [
    CommonModule,
    SurfDropDownModule,
    SurfComponentsCoreModule
  ],
  providers: [
    SurfScriptOperationsUtil
  ],
  exports: [SearchPanelComponent]
})
export class SurfSearchPanelModule{}
