import {ModuleConfigEnum} from './module-config-enum';
import {ModuleConfigOtherParametersEnum} from './module-config-other-parameters-enum';

export abstract class AbstractModuleConfigParser {
  public isValid: boolean;
  public parsedData: any;

  constructor(moduleConfig: Map<ModuleConfigEnum, string>, otherParametersMap: Map<ModuleConfigOtherParametersEnum, any>) {
    this.generateConfigData(moduleConfig, otherParametersMap);
  }

  public abstract generateConfigData(moduleConfig: Map<ModuleConfigEnum, string>, otherParametersMap: Map<ModuleConfigOtherParametersEnum, any>): void;

  /**
   * Split given string and return result array
   * @param stringToSplit
   * @param splitPrm
   */
  splitData(stringToSplit, splitPrm) {
    let splitDataArray = [];
    try {
      if (stringToSplit.indexOf(splitPrm) >= 0) {
        splitDataArray = stringToSplit.split(splitPrm);
      } else {
        splitDataArray.push(stringToSplit);
      }
    } catch (error) {
      this.isValid = false;
    }
    return splitDataArray;
  }
}
