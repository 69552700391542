import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
  SurfPerformanceLogService,
  SurfDeviceDetectorService,
  SurfAuthenticationService, SurfUtilService
} from '@surf/surf-components-core';
import {ActivatedRoute } from '@angular/router';
import {  DataKey, DataStore } from '@tc-core/util/framework';
import { CookieService as NGXCoockieServive} from 'ngx-cookie-service';
import {User} from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { AppService } from './app.service';
import * as FullStory from '@fullstory/browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  host: {'class': 'u-block'}
})
export class AppComponent implements AfterViewInit, OnInit {

  loginSuccess = false;
  user: User = null;
  activeMessageBox = false;
  LOGOUT_URL = "/gateway/authorize/logout";
  constructor(private surfPerformanceLogService: SurfPerformanceLogService,
              private deviceService: SurfDeviceDetectorService,
              private route: ActivatedRoute,
              private cookieService: NGXCoockieServive,
              private authenticationService: SurfAuthenticationService,
              private dataStore: DataStore,
              private appService: AppService,
              private surfUtilService: SurfUtilService) {

    // surfUtilService.initFullStory();
    surfUtilService.setSessionData();
  }

  ngAfterViewInit(): void {
    try {
      const fullTime = Date.now() - window['timerStart'];
      this.surfPerformanceLogService.pageRenderTime = fullTime;
    } catch (e) {
    }
  }

  ngOnInit(): void {
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    if (!this.user || !this.user.b2bUser) {
      this.activeMessageBox = true;
    } else {
      this.loginSuccess = true;
    }
    if (this.appService.getBrand()) {
      const cssUrl = '/assets/brands/' + this.appService.getBrand() + '/brand.css';
      // this.setDynamicStyle(cssUrl);
    }
  }

  setDynamicStyle(cssURL: string) {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'css-styling';
    style.rel = 'stylesheet';
    style.href = `${cssURL}`;
    head.appendChild(style);
  }

  handleError(event) {
    if (event === 'cancel') {
      this.activeMessageBox = false;
      this.authenticationService.logoutUser(this.LOGOUT_URL, null, this.appService.DOMAIN_IDP_MAPPING);
    }
  }
}
