import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse, HttpErrorResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  SurfDeviceDetectorService,
  SurfAPIPerformanceLog,
  SurfPerformanceLogService,
  SurfPerformanceLogType,
  SurfAuthenticationService,

} from '@surf/surf-components-core';
import {CookieService} from 'ngx-cookie-service';
import {AppService} from "./app.service";

@Injectable()
export class B2bDashboardInterceptor implements HttpInterceptor {

  activeRequests = 0;
  statTime: any = new Date().getTime();
  endTime: any;
  username: string = null;
  userSessionToken: string = null;
  tokenCookieName = 'X-rst-session';
  usernameCookieName = 'X-rst-username';

  constructor(private deviceService: SurfDeviceDetectorService,
              private surfPerformanceLogService: SurfPerformanceLogService,
              private authenticationService: SurfAuthenticationService,
              private cookieService: CookieService,
              private appService: AppService) {
    this.surfPerformanceLogService.startTime = this.statTime;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   /* if (this.activeRequests === 0) {
      this.endTime = new Date().getTime();
      this.surfPerformanceLogService.endTime = this.endTime;
    }
    this.activeRequests++;*/

    const startTimestamp = new Date().getTime();
    if ( this.cookieService.get(this.tokenCookieName) ) {
      this.userSessionToken =  this.cookieService.get(this.tokenCookieName);
    }
    if (this.cookieService.get(this.usernameCookieName) ) {
      this.username = this.cookieService.get(this.usernameCookieName);
    }
    if (this.appService.getXsrfTokenVal()) {
      req = req.clone({ headers: req.headers.set('X-XSRF-TOKEN', this.appService.getXsrfTokenVal()) });
    }

    return next.handle(req).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            if (req.url.indexOf("admin-service/v2/module-config") > 0){
              this.appService.userLogout();
            }
          }
        }
      )
    );
  }
}
