import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'surfGetPaxDisplayTextPipe'
})
export class SurfGetPaxDisplayTextPipe implements PipeTransform{
  paxTypesNames:
    Array<{code: string, name: string}> = [
    {code: 'A', name: 'Adult'},
    {code: 'T', name: 'Teen'},
    {code: 'C', name: 'Child'},
    {code: 'I', name: 'Infant'},
  ];
  transform(traveller: any, childTravellerUpperAgeLimit): string {
    if (traveller && traveller.profile && traveller.profile.type ) {
      const type = this.paxTypesNames.find((item) => (item.code === traveller.profile.type));
      if (type && type.code === this.paxTypesNames[2].code && traveller.profile.age > childTravellerUpperAgeLimit) {
        return this.paxTypesNames[1].name;
      }
      return type.name;
    }
    return '';
  }
}
