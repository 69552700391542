<ng-container *ngIf="loginSuccess">
  <router-outlet></router-outlet>
</ng-container>

<surf-message-box [title]="'Access Denied'" [cancelLabel]="'Back to login'" [active]="activeMessageBox"
                  [hasConfirmButon]="false"
                  [svgName]="'exclamation'" [type]="'error'" (clickButton)="handleError($event)">
  <div class="surf-msg-box__hint">You are not authorized to use this application</div>
  <span>Please contact system support for further assistance.</span>
</surf-message-box>

