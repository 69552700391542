import { SurfGuestDetailsUtil } from '@surf/surf-guest-details';
import {Traveller} from '@tc-core/model/it/codegen/tbx/api/commons/traveller';
import {TRAVELLER_MAP_SPLITTERS} from './grab-pnr.models';

export class GrabPnrUtil  {

  static paxTypesNames: Array<string> = ['A', 'T', 'C', 'I'];

  /**
   * Creates a unique key for a traveller which consist of <title>#<firstName>#<lastName>#<paxType>
   *     eg: MRS#KATE#PERERA#A
   * @param traveller - traveller object
   */
  static getUniqueTravellerKey(traveller: Traveller): string {
    if (traveller && traveller.profile) {
      let key = '';
      key += traveller.profile.title ? traveller.profile.title.toUpperCase() : '';
      key += traveller.profile.firstName ? traveller.profile.firstName.toUpperCase() : '';
      key += traveller.profile.middleName ? traveller.profile.middleName.toUpperCase() : '';
      key += traveller.profile.lastName ? traveller.profile.lastName.toUpperCase() : '';
      /*
      For Flights teen is considered as adult. But in sabre grab result the teen will be loaded as it is.
      So below fix is done to set teen as adult always in travellerMapping attribute
      */
      key += traveller.profile.type ? (this.getPaxType(traveller) === 'T' ? 'A' : traveller.profile.type.toUpperCase()) : '';
      return key;
    }
    return null;
  }

  /**
   * child infant teen travellers
   * @param traveller - pnr traveller
   */
  static getPaxType(traveller: Traveller): string {
    if (traveller && traveller.profile && traveller.profile.type) {
      const type = this.paxTypesNames.find((item) => (item === traveller.profile.type));
      if (type === this.paxTypesNames[2] && traveller.profile.age > SurfGuestDetailsUtil.MAX_CHILD_AGE) {
        return this.paxTypesNames[1];
      }
      return type;
    }
    return '';
  }

  /**
   * In flight results page this method will be used to update the (PNR flight result) traveller numbers
   * @see addResultsPageHelperParam
   * @param travellers - pnr travellers
   * @param helperParam - string which is created by addResultsPageHelperParam() method
   */
  static updatePnrTravellerNumbers(travellers: Array<Traveller>, helperParam: string): void {
    if (helperParam && helperParam.length > 0) {
      const travellerMatches = helperParam.split(TRAVELLER_MAP_SPLITTERS.MAIN_SPLITTER);
      travellerMatches.forEach((matchString) => {
        const numberKeyPair  = matchString.split(TRAVELLER_MAP_SPLITTERS.SUB_SPLITTER);
        const finallyFoundYou = travellers.find((traveller) => (this.getUniqueTravellerKey(traveller) === numberKeyPair[1]));
        if (finallyFoundYou) {
          finallyFoundYou.no = parseInt(numberKeyPair[0], 10);
        }
      });
    }
  }
}
