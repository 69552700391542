import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';
import {TC} from '@tc-core/util';
import {ModuleConfigEnum} from '../util/moduleConfigParsers/module-config-enum';
import {ModuleConfigOtherParametersEnum} from '../util/moduleConfigParsers/module-config-other-parameters-enum';
import {ModuleConfigParserFactory} from '../util/factories/module-config-parser-factory';
import {ProductType} from '@surf/surf-components-core';
import {CommonHelper} from '@tc-core/util/helpers';
import {User} from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';

@Component({
  selector: 'surf-dp-template',
  templateUrl: './dp-template.component.html'
})
export class DpTemplateComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'c-dp-search__templates';

  @Input() isActive = false;

  @Output() selectedItemEvent: EventEmitter<Map<number, string>> = new EventEmitter<Map<number, string>>();

  selectedItemMap = new Map<number, string>();
  dpTemplateLabelMap = new Map<any, any>();
  moduleConfigsAndDataMap = new Map<ModuleConfigEnum, string>();
  otherParameterDataMap = new Map<ModuleConfigOtherParametersEnum, string>();
  DP_TEMPLATES_ENABLED = false;
  DP_TEMPLATE_DEFINITIONS = '';
  DP_TEMPLATE_ELIGIBLE_DIV_BRANDS = '';
  labelList: any[] = [];
  user: User ;
  division = '';
  brand = '';
  constructor(private configLoader: ConfigLoader, private commonHelper: CommonHelper, private dataStore: DataStore) {
  }

  ngOnInit() {
    this.loadBrandDivisions();
    this.loadConfigs();
    this.createLabelList();
  }

  /**
   * load brand and divisions from user
   */
  loadBrandDivisions() {
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    if (this.user && this.user.userDetail && (this.user.userDetail.defaultBrand || this.user.userDetail.defaultDivison)) {
      this.brand = this.user.userDetail.defaultBrand.code;
      this.division = this.user.userDetail.defaultDivison.code;
    }
  }
  /**
   * create label list object to populate label items with icons
   */

  createLabelList() {
    this.dpTemplateLabelMap = ModuleConfigParserFactory.getModuleConfigParsedResult(
      ModuleConfigEnum.DP_TEMPLATES_ENABLE, this.moduleConfigsAndDataMap, this.otherParameterDataMap).parsedData;
    this.dpTemplateLabelMap.forEach((value, key) => {
      const combinationList: any[] = this.convertCombinationStringToArray(value.combination);
      const templateData = {
        title: value.title,
        combination: value.combination,
        combinationArray: combinationList
      };
      this.labelList.push(templateData);
    });
    this.sanitize();
  }

  /**
   * remove unwanted items from the labelList and clean.
   */
  sanitize() {
    if (this.labelList && this.labelList.length > 0) {
      this.labelList.forEach(labelItem => {
        if (labelItem && labelItem.combinationArray && labelItem.combinationArray.length > 0) {
          const lastItem = labelItem.combinationArray[labelItem.combinationArray.length - 1];
          if (lastItem && lastItem.isReturnFlight) {
            labelItem.combinationArray.pop();
            labelItem.combinationArray.pop();
          }
        }
      });
    }
  }

  /**
   * check whether the current item is a return flight
   * @param combinationList - converted combination array
   * @param currentItem - current converting item
   */
  checkIsReturnFlight(combinationList: any[], currentItem: string) {
    if (currentItem && currentItem !== ProductType.FLT) {
      return false;
    }
    const filteredFlightProducts: any[] = combinationList.filter((item: any) => {
      return (item.product === ProductType.FLT);
    });

    return filteredFlightProducts && (filteredFlightProducts.length % 2) === 1 ? true : false;
  }

  /**
   * In the label creation second phase, need convert the combination string to object array. For the UI usages.
   * @param combinationString - string need to converte as array
   */
  convertCombinationStringToArray(combinationString: string): string[] {
    const combinationFinalArray: any[] = [];
    const combinationSplitData = combinationString ? combinationString.split(',') : [];

    combinationSplitData.forEach((element, index) => {
      const item: any = {};
      // item.isReturnFlight = index === 0 ? false : this.checkIsReturnFlight(combinationFinalArray, element);
      item.isReturnFlight = false;
      item.icon = this.getIconName(element);
      item.product = element;
      item.isPlusIcon = false;
      combinationFinalArray.push(item);

      if (index < combinationSplitData.length - 1) {
        const itemPlus: any = {};
        itemPlus.isReturnFlight = false;
        itemPlus.icon = '#plus';
        itemPlus.isPlusIcon = true;
        itemPlus.product = 'plus';
        combinationFinalArray.push(itemPlus);
      }
    });
    return combinationFinalArray;
  }

  /**
   * get icon name by product code
   * @param productCode - product code to get icon name
   */
  getIconName(productCode: string) {
    let iconName = '';
    switch (productCode) {

      case ProductType.HTL:
        iconName = '#products--hotel';
        break;

      case ProductType.FLT:
        iconName = '#products--flight';
        break;

      case ProductType.GEN:
        iconName = '#products--tickets';
        break;

      case ProductType.CAR:
        iconName = '#products--car';
        break;

      case ProductType.OWA:
        iconName = '#products--ownarrangment';
        break;

      case ProductType.TRS:
        iconName = '#products--bus';
        break;
      default:
        iconName = 'plus';
    }
    return iconName;
  }

  /**
   * load module configs
   */
  loadConfigs() {
    this.DP_TEMPLATES_ENABLED = this.commonHelper.convertToBoolean(
      this.configLoader.getModuleConfig('DP_TEMPLATES_ENABLED', TC.MODULE_NAME.SURF_B2B)
    );
    this.DP_TEMPLATES_ENABLED = true;
    if (this.DP_TEMPLATES_ENABLED) {
      this.DP_TEMPLATE_DEFINITIONS = this.configLoader.getModuleConfig('DP_TEMPLATE_DEFINITIONS', TC.MODULE_NAME.SURF_B2B);
      this.DP_TEMPLATE_ELIGIBLE_DIV_BRANDS = this.configLoader.getModuleConfig('DP_TEMPLATE_ELIGIBLE_DIV_BRANDS', TC.MODULE_NAME.SURF_B2B);

      this.moduleConfigsAndDataMap.set(ModuleConfigEnum.DP_TEMPLATE_DEFINITIONS, this.DP_TEMPLATE_DEFINITIONS);
      this.moduleConfigsAndDataMap.set(ModuleConfigEnum.DP_TEMPLATE_ELIGIBLE_DIV_BRANDS, this.DP_TEMPLATE_ELIGIBLE_DIV_BRANDS);

      this.otherParameterDataMap.set(ModuleConfigOtherParametersEnum.BRAND, this.brand);
      this.otherParameterDataMap.set(ModuleConfigOtherParametersEnum.DIVISION, this.division);
    }
  }

  /*
   * trigger the output event when clicking on a specific label item
   * @param item
   */
  selectItem(item: any) {
    this.selectedItemMap = new Map<number, string>();
    const combinationItemCodeList = item && item.combination ? item.combination.split(',') : [];
    combinationItemCodeList.forEach((combinationItemElement, index) => {
      this.selectedItemMap.set(index, combinationItemElement);
    });
    this.selectedItemEvent.emit(this.selectedItemMap);
  }

  ngOnDestroy(): void {
    this.isActive = true;
    this.labelList = [];
    this.selectedItemMap = new Map<number, string>();
  }
}
