<div class="c-advance-search__content c-pkg-search">
  <div *ngIf="!isServiceAddComponent" class="c-advance-search__content-top">
    <button class="a-btn a-btn--link c-pkg-search__back-btn" (click)="closeCriteriaBox()">
      <svg class="a-icon u-mr"><use xlink:href="#previous"></use></svg>
    </button>
    <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Search hotels and resorts</h3>
    <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine your hotel search</h3>
    <button class="a-btn a-btn--link o-modal__inner-close" (click)="closeCriteriaBox()">
      <svg class="a-icon o-modal__close-icon">
        <use xlink:href="#close"></use>
      </svg>
    </button>
  </div>

  <div class="c-advance-search__content-wrap">

    <div class="c-advance-search__search-wrap u-mb+" id="{{windowScrollEnable ? 'scroll': ''}}">
      <!-- first row -------------- -->
      <div class="c-advance-search__search-box" (click)="scrollTo('#scroll')">
        <surf-type-ahead
          [label]="'destination*'"
          [dataList]="destinations"
          [filterType]="'strict'"
          [mappings]="['code', 'value']"
          [sortOrder]="'A2Z'"
          [selectedItem]="selectedDest"
          [paramName]="'ontotext'"
          [placeholder]="getHotelDestinationPlaceHolder()"
          [bindFocusOut]="false"
          [selectAllOnEnter]="false"
          [isOnto]="false"
          [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
          (onClickInside)="onClickInsideTypeAhead($event)"
          (onClickOutside)="onClickOutsideTypeAhead($event)"
          (returnedQuery)="returnedSearchQuery($event)"
          (returnedData)="returnDestination($event)"
          (keydown)="onKeyDown($event, 0)"
          [tabIndex]="1">

          <surf-type-ahead-content
            [typeAheadData]="filteredList"
            [available]="filteredList?.length > 0"
            (setItem)="selectDestination($event)">

                <surf-type-ahead-item (click)="selectDestination(item)" *ngFor="let item of filteredList">

                  <div class="c-dp-search__list-item">
                    <svg class="a-icon c-dp-search__list-item-icon">
                      <use *ngIf="item?.type == 'CITY'" xlink:href="#map"></use>
                      <use *ngIf="item?.type != 'CITY'" xlink:href="#country"></use>
                    </svg>
                    <div class="m-info-set c-dp-search__list-item-main">
                      <span class="s4 ts-strong">{{item?.type === 'CITY' ? item?.value : (item?.value | surfTextCase: 'title')}}</span>
                      <span class="s5 ts-muted ts-wet">{{surfCriteriaUtil.getCountry(item)}}</span>
                    </div>
                    <span *ngIf="item?.type == 'CITY'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                    <span *ngIf="item?.type != 'CITY'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                  </div>

                </surf-type-ahead-item>
              </surf-type-ahead-content>
            </surf-type-ahead>
          </div>

    <div class="c-pkg-search__form" >
      <div class="c-pkg-search__form-item" (click)="scrollTo('#scroll')">
        <surf-pax-selection
          [(isFocused)]="guestComponentFocus"
          [paxSet]="defaultPaxSet"
          [product]="'HTL'"
          [maxAdult]="MAX_ADULT"
          [minAdult]="MIN_ADULT"
          [maxPaxCount]="MAX_PAX_COUNT"
          [maxCabinPaxCount]="MAX_CABIN_PAX_COUNT"
          [maxChildren]="MAX_CHILD"
          [maxTeen]="MAX_TEEN"
          [maxInfants]="MAX_INFANTS"
          [maxInfantAge]="MAX_INFANT_AGE"
          [maxChildAge]="MAX_CHILD_AGE"
          [maxTeenAge]="MAX_TEEN_AGE"
          [maxCabins]="MAX_ROOMS"
          [roomViewSelected]="setRoomWise"
          [parentScrollId]="windowScrollEnable ? 'scroll': null"
          [isTeenActive]="TEEN_PAX_ACTIVE"
          (paxSelection)="getPassengerSelection($event)"
          [tabIndex]="2"
          (currentOption)="getCurrentOption($event)"
          (keydown)="onKeyDown($event, 1)">
        </surf-pax-selection>
      </div>

      <div class="c-pkg-search__form-item c-pkg-search__form-item--inverted" (click)="scrollTo('#scroll')">
        <surf-date-picker
          [calendarStartDate]="calendarMinDate"
          [inputLabel]="datePickerLabel"
          [(isFocused)]="datePickerFocus"
          [reset]="resetCalendar"
          [check_availability]="true"
          [range]="CALENDAR_RANGE"
          [initial_dates]="initialDates"
          [hideErrorMsg]="hideErrorMsg"
          [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : ''"
          [isError]="validateDisabledDateSelection"
          [validateDisabledDateSelection]="validateDisabledDateSelection"
          (selected_duration)="getSelectedDuration($event)"
          (selected_dates)="getSelectedDateRange($event)"
          (keydown)="onKeyDown($event, 2)"
          [tabIndex]="3">
        </surf-date-picker>
      </div>

    </div>

  </div>

  <div class="c-advance-search__btn-wrapper">
    <div class="c-advance-search__search-info"><svg class="a-icon search-info--icon"><use xlink:href="#info"></use></svg> <span>Please select at least your <b>Check-in & Check-out dates</b> and <b>Destination</b> to continue search</span></div>
    <div *ngIf="!isRefine" class="c-advance-search__search-action">
      <button (click)="resetInputs()" class="a-btn a-btn--default" [tabIndex]="4">Reset Search</button>
      <button (click)="searchHotels(false)" class="a-btn a-btn--primary" [tabIndex]="5"[ngClass]="{'is-disabled':!validateInputs()}">Search Hotels</button>
    </div>
    <div *ngIf="isRefine" class="c-advance-search__search-action">
      <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default">Back to results</button>
      <button (click)="searchHotels(false)" class="a-btn a-btn--primary" [ngClass]="{'is-disabled':!validateInputs()}">Refine Search</button>
    </div>
  </div>

</div>
