import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CruiseCriteriaComponent} from '../cruise-criteria/cruise-criteria.component';
import {ConfigService, DataShareService, SurfCriteriaUtil} from '@surf/surf-components-core';
import {ConfigLoader} from '@tc-core/util/framework';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {SurfCruiseCriteriaService} from '../surf-cruise-criteria.service';
import {MasterDataStoreService} from '@surf/surf-state-manage';

@Component({
  selector: 'surf-cruise-criteria-dp',
  templateUrl: './cruise-criteria-dp.component.html'
})
export class CruiseCriteriaDpComponent extends CruiseCriteriaComponent implements OnInit {
  range = false;
  message: boolean;
  @Output() inactive: EventEmitter<any> = new EventEmitter();

  constructor(protected commonService: DataShareService,
              protected dataServiceHandler: DataServiceHandler,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected configLoader: ConfigLoader,
              protected surfCruiseCriteriaService: SurfCruiseCriteriaService,
              protected masterDataStoreService: MasterDataStoreService) {
    super(commonService, dataServiceHandler, configService, surfCriteriaUtil, configLoader,
      surfCruiseCriteriaService, masterDataStoreService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.surfCruiseCriteriaService.sharedMessage.subscribe(message => this.message = message);
    this.inactive.emit(true);
    if (this.minDurationValues && this.minDurationValues[0]?.value) {
      this.range = true;
    }
  }

  addRange() {
    this.range = true;
    this.inactive.emit(false);
    this.surfCruiseCriteriaService.nextMessage(false);
    this.durationValues[0].value = '';
    this.cruiseCriteria.nights = 0;
  }

  removeRange() {
    this.range = false;
    this.inactive.emit(true);
    this.surfCruiseCriteriaService.nextMessage(true);
    this.maxDurationValues[0].value = '';
    this.minDurationValues[0].value = '';
    this.durationValues[0].value = '';
    // this.resetInputs();
  }
}
