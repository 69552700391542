import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef} from '@angular/core';
import {BOOKING_ANIMATIONS} from './booking-summary.animation';
import {DataServiceHandler, ServiceHandler} from '@tc-core/service/service-handlers';
import {DataShareService, SurfAuthorizationService, SurfCriteriaUtil} from '@surf/surf-components-core';
import {ConfigLoader, DataKey, DataStore, QueryParamsService} from '@tc-core/util/framework';
import {User} from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import {BookingGETCriteria} from '@tc-core/model/it/codegen/tbx/api/criteria/booking/booking-GET-criteria';
import {Observable, of, Subscription} from 'rxjs';
import {DataLoader} from '@tc-core/service/api';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import { PermissionKeys } from '../security/permission-constants';
import bookingSummaryOperations from '../security/booking-summary-operations.json';
import {TC} from '@tc-core/util';
import { CALENDARTYPE } from '@surf/surf-calendar';

@Component({
  selector: 'surf-booking-summary',
  templateUrl: 'booking-summary.component.html',
  animations: BOOKING_ANIMATIONS
})
export class BookingSummaryComponent implements OnInit {
  @Output() goToDashboardDetailsView = new EventEmitter<void>();
  @Output() onToggleRetailStoreViewStatus = new EventEmitter<boolean>();
  @Output() onSelectedDurationChanged  = new EventEmitter<void>();
  @ViewChild('tableElement') tableElement: ElementRef;

  STATUS_NONE = 0;
  STATUS_All_BOOKINGS = 1;
  STATUS_All_BOOKINGS_WITHOUT_QUOTE = 2;
  STATUS_All_PAID_BOOKINGS = 3;
  STATUS_CONFIRMED = 1000;
  STATUS_CANCELLED = 2000;
  STATUS_QUOTE = 200;
  STATUS_UPCOMING = 4;
  STATUS_OVERDUE = 5;


  LIST_VIEW_TYPE = 1000; // booking list view type
  LIST_SELECTION_TYPE = 2000; // booking list selection type

  UPCOMING_PAY_DUE_PERIOD = 7;

  UPCOMING_PAYMENT_SORT = 'UP~PAYMENT_DUE_DATE';
  BOOKING_ID_DESC_SORT = '-BOOKING_ID';

  selectedOption;
  noOfWeeks;
  bookingStartDate: Date;
  bookingEndDate: Date = new Date();

  bookingCriteria;
  paymentDueBookingCriteria;
  paymentOverDueBookingCriteria;
  startDate;
  quote;
  initilized = 0;
  highlightsMap;
  user: User ;
  highlightsObserver: Subscription;
  upcomingPaymentHighlights$: Subscription;
  overDuePaymentHighlights$: Subscription;
  overDueBookingExtractAttribute = 'upcoming_payments';

  bookingHighlights = {
    TOTAL_BOOKINGS : 'total_bookings',
    TOTAL_PAID_BOOKINGS : 'total_paid_bookings',
    CONFIRMED : 'confirmed_bookings',
    QUOTES : 'quote_bookings',
    CANCELLED : 'cancelled_bookings',
    UPCOMING_PAYMENTS : 'upcoming_payments',
    OVERDUE_PAYMENTS : 'total_bookings',
    TRANSACTION_VALUE  : 'transaction_value',
    TOTAL_PRICE : 'total_price',
    SELLING_CURRENCY  : 'currency',
    POPULAR_DESTINATION_BOOKING : 'popular_destination_booking',
    POPULAR_DESTINATION_PAID_BOOKING : 'popular_destination_paid_booking',
    POPULAR_DESTINATION_QUOTE : 'popular_destination_quote' ,
    ZERO_PRICED_CANCELLED_BOOKINGS : 'zero_prized_cancelled_booking' ,
  };

  display = {
    TOTAL_BOOKINGS : 'ALL BOOKINGS',
    TOTAL_PAID_BOOKINGS : 'BOOKINGS',
    CONFIRMED : 'CONFIRMED',
    CANCELLED : 'CANCELLED',
    QUOTES : 'QUOTES',
    UPCOMING_PAYMENTS : 'UPCOMING PAYMENTS',
    TRANSACTION_VALUE  : 'Total transaction value',
    TOTAL_PRICE : 'Total transaction value',
    OVERDUE_PAYMENTS: 'OVERDUE BOOKINGS'
  };

  TOTAL_BOOKINGS = 'TOTAL_BOOKINGS';
  TOTAL_PAID_BOOKINGS = 'TOTAL_PAID_BOOKINGS';
  CONFIRMED = 'CONFIRMED';
  QUOTES = 'QUOTES';
  CANCELLED = 'CANCELLED';
  UPCOMING_PAYMENTS = 'UPCOMING_PAYMENTS';
  TOTAL_PRICE = 'TOTAL_PRICE';
  TRANSACTION_VALUE = 'TRANSACTION_VALUE';
  OVERDUE_PAYMENTS = 'OVERDUE_PAYMENTS';

  loading = true;
  disabled = false;
  active = false;
  dashboardConfigs ;
  moduleConfigs;
  previousUpPaymentValue;
  previousOverDuePaymentValue;
  paymentDueToDate: Date;
  paymentDueDateFrom: Date;
  dateTimeInUserTimeZone;
  readonly GET_USER_TIME_ZONE_ENDPOINT = '/admin/admin-service/v2/logged-in-user/time-zone-info';
  headerTokenName = 'X-rst-session';
  headerUserName = 'X-rst-username';
  highlightOptionList = [];
  TTV;
  toggleBtnTitle = 'Retail store view';
  upcomingPaymentSubText = 'week';
  overduePaymentSubText = 'week';
  overdueBookingFromDate = new Date();
  overdueBookingToDate = new Date();
  subscriptions = new Map<string, Subscription>();
  disableScheduleBasedBookingSearch = false;
  BACKDATED_NUMBER_OF_MONTHS = 3;  // PaymentDueDate is always set to this amount of months back for ‘upcoming payments’ tile
  permissionKeys;

  readonly ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW = this.configLoader.getModuleConfig('ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW', TC.MODULE_NAME.SURF_B2B) === 'TRUE';

  readonly defaultDurationOptions = {
    QUARTER : '1',
    MONTH : '2',
    WEEK : '3'
  };
  selectedDurationOption = this.defaultDurationOptions.MONTH;
  selectedDurationOptionHeadingTexts = {section1 : '', section2 : ''};

  readonly supportedHighlightDateRangeModes = {
      DEFAULT : 'DEFAULT',
      LAST_X_DAYS : 'LAST_X_DAYS',
      CURRENT_PERIOD : 'CURRENT_PERIOD'
  };
  readonly CUSTOM_DATE_RANGE_MODE = 'CUSTOM';
  highlightDateRangeMode = '';
  isCustomDateRangeEnabled = false;
  showRangeSelectionModal = false;
  selectedDateRange;
  selectedEndDate: Date = new Date();
  selectedStartDate: Date = new Date(this.selectedEndDate.getFullYear(), this.selectedEndDate.getMonth() - 1, this.selectedEndDate.getDate());
  onlyStartDateSelected = false;
  selectedDates = [];
  available_dates = [];
  range = 4;
  currentDateOfTimeZone: Date = new Date();
  calendarType = CALENDARTYPE.ALL_DATES;
  selectedDateRangeInCalendar = [];

  constructor(private dataServiceHandler: DataServiceHandler,
              protected configLoader: ConfigLoader,
              private commonService: DataShareService,
              private dataStore: DataStore,
              private queryParamsService: QueryParamsService,
              private dataLoader: DataLoader,
              private serviceHandler: ServiceHandler,
              private http: HttpClient,
              private cookieService: CookieService,
              private surfAuthorizationService: SurfAuthorizationService) { }

  ngOnInit() {
    // surf permissions
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(bookingSummaryOperations);
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();

    this.loadModuleConfigs();
    this.initHighlightDateRangeConfigs();

    try {
      if (this.dashboardConfigs) {
       const configs: [any] = this.dashboardConfigs.split(',');
       configs.forEach(
         c => {
           let s ;
           if (c == this.TOTAL_BOOKINGS) {
             s =  {name : this.bookingHighlights[c] , state : this.STATUS_All_BOOKINGS_WITHOUT_QUOTE , display : this.display.TOTAL_BOOKINGS};
           }
           if (c == this.TOTAL_PAID_BOOKINGS) {
             s =  {name : this.bookingHighlights[c] , state : this.STATUS_All_PAID_BOOKINGS , display : this.display.TOTAL_PAID_BOOKINGS};
           }
           if (c == this.CONFIRMED) {
             s =  {name : this.bookingHighlights[c] , state : this.STATUS_CONFIRMED ,  display : this.display.CONFIRMED};
           }
           if (c == this.CANCELLED) {
             s =  {name : this.bookingHighlights[c] , state : this.STATUS_CANCELLED ,  display : this.display.CANCELLED};
           }
           if (c == this.QUOTES) {
             s =  {name : this.bookingHighlights[c] , state : this.STATUS_QUOTE,  display : this.display.QUOTES};
           }
           if (c == this.UPCOMING_PAYMENTS) {
             s =  {name : this.bookingHighlights[c] , state : this.STATUS_UPCOMING,  display : this.display.UPCOMING_PAYMENTS};
           }
           if (c == this.OVERDUE_PAYMENTS) {
             s =  {name : this.bookingHighlights[c] , state : this.STATUS_OVERDUE ,  display : this.display.OVERDUE_PAYMENTS};
           }
           if (s) {
             this.highlightOptionList.push(s);
           }
         }
       );
      } else {
        this.setDefault();
      }
    } catch (e) {
      this.setDefault();
    }

    this.selectedOption = this.STATUS_NONE;

    this.bookingCriteria = this.getInitialCriteria();
    // this.bookingCriteria = initialCriteria;
    this.selectedDateRangeInCalendar = this.getDatesInRange(this.selectedStartDate, this.selectedEndDate);
    this.setDuration(this.selectedDurationOption);
  }

  /**
   * load module configurations.
   */
  loadModuleConfigs() {
    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'DASHBOARD_HIGHLIGHTS':
            this.dashboardConfigs = mc.name;
            break;

          case  'DASHBOARD_TTV' :
            this.TTV = mc.name;
            break;

          case  'BKG_UPCOMING_PAYMENT_DURATION' :
            if (mc.name === undefined || mc.name === 0) {
              this.UPCOMING_PAY_DUE_PERIOD = 7;
            } else {
              this.UPCOMING_PAY_DUE_PERIOD = mc.name;
            }
            break;
        }
      });
    }

    // check config for disabling payment schedule due date based booking search.
    if (this.configLoader.getModuleConfig('DISABLE_PAY_SCHEDULE_BASED_BOOKING_SEARCH', TC.MODULE_NAME.SURF_B2B)) {
      this.disableScheduleBasedBookingSearch = this.configLoader.getModuleConfig('DISABLE_PAY_SCHEDULE_BASED_BOOKING_SEARCH',
        TC.MODULE_NAME.SURF_B2B).toUpperCase() === 'TRUE';
    }
  }

  getMainHighlights(criteria, getUpPayment, getOverduePayments) {
    // this.highlights$ = new Subscription();
    this.dataStore.set(DataKey.bookingHighlights, null);
    if (this.highlightsObserver && !this.highlightsObserver.closed) {
      this.highlightsObserver.unsubscribe();
      this.highlightsObserver = null;
    }
    if (getOverduePayments && getUpPayment) {
      Promise.all([this.getBookingMainHighlights(criteria),
      this.getUpcomingAndOverDuePaymentsNew(criteria.bookedUser === null, false),
      this.getUpcomingAndOverDuePaymentsNew(criteria.bookedUser === null, true)]).then(r => {
        this.terminateLoading();
      });
    } else if (getOverduePayments) {
      Promise.all([this.getBookingMainHighlights(criteria),
      this.getUpcomingAndOverDuePaymentsNew(criteria.bookedUser === null, false)]).then(r => {
        this.highlightsMap.set(this.bookingHighlights.UPCOMING_PAYMENTS, this.previousUpPaymentValue);
        this.terminateLoading();
      });
    } else if (getUpPayment) {
      Promise.all([this.getBookingMainHighlights(criteria),
      this.getUpcomingAndOverDuePaymentsNew(criteria.bookedUser === null, true)]).then(r => {
        this.terminateLoading();
      });
    } else {
      Promise.all([this.getBookingMainHighlights(criteria)]).then(r => {
          this.terminateLoading();
      });
    }
  }

  getBookingMainHighlights(criteria) {
    return new Promise((resolve, reject) => {
      this.highlightsObserver =
      this.serviceHandler.getBookingServiceHandlerService().bookingHighlights(criteria, true).subscribe(results => {
          if (results) {
            this.highlightsObserver.unsubscribe();
            this.dataStore.set(DataKey.bookingHighlights, null);
            if (results?.data?.length > 0) {
              this.extractResults(results?.data, false, false);
              this.initilized++;
            }
            resolve(true);
          } else if (results instanceof TcApiError || results instanceof TcHttpError) {
            this.highlightsObserver.unsubscribe();
            resolve(true);
            this.terminateLoading();
          }
        }
      );
    });
  }

 getCurrentDateTimeWithTimeZone(timeZone: string): string {
    const now = new Date();

    const isoString = now.toISOString().split('.')[0] + 'Z';

    const timeZoneString = `[${timeZone}]`;

    return isoString + timeZoneString;

  }
  getUpcomingAndOverDuePaymentsNew(storeView, isUpComingType: boolean) {
    return new Promise((resolve, reject) => {
      const paymentCriteria = isUpComingType ? this.getUpcomingPaymentCriteria(storeView) : this.getOverduePaymentCriteria(storeView);
      if (isUpComingType) {
        this.paymentDueBookingCriteria = paymentCriteria;
        this.upcomingPaymentHighlights$ = new Subscription();
      } else {
        this.paymentOverDueBookingCriteria = paymentCriteria;
        this.overDuePaymentHighlights$ = new Subscription();
      }

      this.dataStore.set(DataKey.bookingHighlights, null);
      if (isUpComingType) {
        if (this.subscriptions.get(this.UPCOMING_PAYMENTS) && !this.subscriptions.get(this.UPCOMING_PAYMENTS).closed) {
          this.subscriptions.get(this.UPCOMING_PAYMENTS).unsubscribe();
        }
        this.subscriptions.set(this.OVERDUE_PAYMENTS, null);
        this.subscriptions.set(this.UPCOMING_PAYMENTS, new Subscription());
      } else {
        if (this.subscriptions.get(this.OVERDUE_PAYMENTS) && !this.subscriptions.get(this.OVERDUE_PAYMENTS).closed) {
          this.subscriptions.get(this.OVERDUE_PAYMENTS).unsubscribe();
        }
        this.subscriptions.set(this.OVERDUE_PAYMENTS, new Subscription());
        this.subscriptions.set(this.UPCOMING_PAYMENTS, null);
      }
      this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS).add(
        this.serviceHandler.getBookingServiceHandlerService().bookingHighlights(paymentCriteria, true).subscribe(
          results => {
            if (results) {
              this.dataStore.set(DataKey.bookingHighlights, null);
              if (results?.data?.length > 0) {
                if (this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS) &&
                  !this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS).closed) {
                  this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS).unsubscribe();
                }
                this.extractResults(results?.data, isUpComingType, !isUpComingType);
                this.initilized++;
                // this.terminateLoading();
              } else {
                if (this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS) &&
                  !this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS).closed) {
                  this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS).unsubscribe();
                }
                if (!isUpComingType) {
                  this.highlightsMap.set(this.bookingHighlights.OVERDUE_PAYMENTS, 0);
                  this.previousOverDuePaymentValue = 0;
                } else if (isUpComingType) {
                  this.highlightsMap.set(this.bookingHighlights.UPCOMING_PAYMENTS, 0);
                  this.previousUpPaymentValue = 0;
                }
                this.initilized++;
              }
              resolve(true);
            } else if (results instanceof TcApiError || results instanceof TcHttpError) {
              if (this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS) &&
                !this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS).closed) {
                this.subscriptions.get(isUpComingType ? this.UPCOMING_PAYMENTS : this.OVERDUE_PAYMENTS).unsubscribe();
              }
              this.dataStore.set(DataKey.bookingHighlights, null);
              // this.terminateLoading();
              resolve(true);
            }
          }));

    });
  }
  getUpcomingPayments(storeView, isUpComingType: boolean) {
    const paymentCriteria = isUpComingType ? this.getUpcomingPaymentCriteria(storeView) : this.getOverduePaymentCriteria(storeView);
    if (isUpComingType) {
      this.paymentDueBookingCriteria = paymentCriteria;
      this.upcomingPaymentHighlights$ = new Subscription();
    } else {
      this.paymentOverDueBookingCriteria = paymentCriteria;
      this.overDuePaymentHighlights$ = new Subscription();
    }
    this.dataStore.set(DataKey.bookingHighlights, null);
    (isUpComingType ? this.upcomingPaymentHighlights$ : this.overDuePaymentHighlights$).add(
      this.serviceHandler.getBookingServiceHandlerService().bookingHighlights(paymentCriteria).subscribe(
        results => {
          if (results) {
            if (results.length > 0) {
              this.extractResults(results, isUpComingType, !isUpComingType);
              this.initilized++;
              if (isUpComingType) {
                this.upcomingPaymentHighlights$.unsubscribe();
              } else {
                this.overDuePaymentHighlights$.unsubscribe();
              }
              this.dataStore.set(DataKey.bookingHighlights, null);
              this.terminateLoading();
            }
          } else if (results instanceof TcApiError || results instanceof TcHttpError) {
            if (isUpComingType) {
              this.upcomingPaymentHighlights$.unsubscribe();
            } else {
              this.overDuePaymentHighlights$.unsubscribe();
            }
            this.dataStore.set(DataKey.bookingHighlights, null);
            this.terminateLoading();
          }
        }));
  }

  terminateLoading() {
    this.dataStore.set(DataKey.bookingHighlights, null);
    if (this.highlightsMap.size > 0) {
      setTimeout(() => {
        this.loading = false;
      }, 200);
    }
  }

  setDefault() {
    this.highlightOptionList.push({name : this.bookingHighlights[this.TOTAL_BOOKINGS] , state : this.STATUS_All_BOOKINGS_WITHOUT_QUOTE , display : this.display.TOTAL_BOOKINGS});
    this.highlightOptionList.push({name : this.bookingHighlights[this.QUOTES] , state : this.STATUS_QUOTE,  display : this.display.QUOTES});
    this.highlightOptionList.push({name : this.bookingHighlights[this.UPCOMING_PAYMENTS] , state : this.STATUS_UPCOMING, display : this.display.UPCOMING_PAYMENTS});
    this.highlightOptionList.push({name : this.bookingHighlights[this.OVERDUE_PAYMENTS] , state : this.STATUS_OVERDUE, display : this.display.OVERDUE_PAYMENTS});
    this.TTV = this.TRANSACTION_VALUE;
  }

  extractResults(data: [any], upcomingPaymentHighlights: boolean, overduePaymentHighlights: boolean) {
   const gg = this.dataStore.get(DataKey.bookingHighlights).getValue();
   if (this.highlightsMap === undefined) {
      this.highlightsMap = new Map();
    }

   if (data.length > 0) {
      data.forEach(item => {
        if (overduePaymentHighlights) {
          /** FC-44290 Overdue booking count taken from attribute upcomming_payments in the relevant highlight API response
           * and the count keep in the highlightMap with total_bookings attribute name**/
          if (item.name.includes( this.overDueBookingExtractAttribute)) {
            const zeroPricedCnxBookingCount = this.getZeroPricedCancelledBookingCount(data);
            let itemValue = ((parseInt(item.value.toString(), 10)) - zeroPricedCnxBookingCount);
            itemValue = ((itemValue < 0) ? 0 : itemValue);
            this.highlightsMap.set(this.bookingHighlights.OVERDUE_PAYMENTS, itemValue.toString());
            this.previousOverDuePaymentValue = itemValue.toString();
          }
        } else if (upcomingPaymentHighlights) {
          if (item.name.includes(this.bookingHighlights.UPCOMING_PAYMENTS)) {
            const zeroPricedCnxBookingCount = this.getZeroPricedCancelledBookingCount(data);
            let itemValue = ((parseInt(item.value.toString(), 10)) - zeroPricedCnxBookingCount);
            itemValue = ((itemValue < 0) ? 0 : itemValue);
            this.highlightsMap.set(item.name, itemValue.toString());
            this.previousUpPaymentValue = itemValue.toString();
          }
        } else {
          if (item.name.includes(this.bookingHighlights[this.TTV])) {
            const arr = item.name.split('__');
            if (arr.length == 2) {
              if (!this.highlightsMap.get(this.TTV)) {
                this.highlightsMap.set(this.TTV, []);
                this.highlightsMap.set(this.bookingHighlights.SELLING_CURRENCY, []);
              }
              this.highlightsMap.get(this.TTV).push(Math.round(item.value * 100) / 100);
              this.highlightsMap.get(this.bookingHighlights.SELLING_CURRENCY).push(arr[1]);

            }
          } else if (!(item.name.includes(this.bookingHighlights.UPCOMING_PAYMENTS) ||
            item.name.includes(this.bookingHighlights.OVERDUE_PAYMENTS))) {
            this.highlightsMap.set(item.name, item.value);
          } else if (item.name.includes(this.bookingHighlights.TOTAL_BOOKINGS)) {
            this.highlightsMap.set(this.TOTAL_BOOKINGS, item.value);
          }
        }
      });
    }
  }

  /**
   * return zero prized cancelled booking count from data list
   * @param data - data list from highlights api response
   * @return number
   */
  getZeroPricedCancelledBookingCount(data): number {
    let count = 0;
    if (data && data.length > 0) {
      const filteredItem = data.find(item => item.name === this.bookingHighlights.ZERO_PRICED_CANCELLED_BOOKINGS);
      if (filteredItem) {
        count = parseInt(filteredItem.value.toString(), 10);
      }
    }
    return count;
  }

  /**
   * return initial criteria with default parameters
   */
  getInitialCriteria(): BookingGETCriteria {
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    const bookingEndDate = this.getDefaultEndDate();
    const bookingStartDate = this.getDefaultStartDate();
    if (this.user) {
      return this.createCriteria(
        this.user.clientId,
        this.user.clientGroup,
        this.user.userSummary.username,
        this.convertDateObjToStr(bookingStartDate),
        this.convertDateObjToStr(bookingEndDate)
      );
    }
  }

  getUpcomingPaymentCriteria(storeView): BookingGETCriteria {
    let paymentDueDateFrom;
    let paymentDueDateTo;

    if (this.disableScheduleBasedBookingSearch) {
      paymentDueDateFrom = new Date(this.paymentDueDateFrom);
      paymentDueDateTo = this.paymentDueToDate;
    } else {
      paymentDueDateFrom = new Date(this.paymentDueDateFrom);
      paymentDueDateFrom.setMonth(paymentDueDateFrom.getMonth() - this.BACKDATED_NUMBER_OF_MONTHS);
      paymentDueDateTo = this.paymentDueToDate;
    }
    if (this.user) {
      let username = null;
      if (!storeView) {
        username = this.user.userSummary.username;
      }
      return this.createCriteria(this.user.clientId, this.user.clientGroup, username, null, null,
        this.convertDateObjToStr(paymentDueDateFrom), this.convertDateObjToStr(paymentDueDateTo));
    }
  }

  getOverduePaymentCriteria(storeView): BookingGETCriteria {
    //const paymentDueToDate = new Date(this.overdueBookingToDate);
    //paymentDueToDate.setDate(paymentDueToDate.getDate() - 1);

    const paymentDueDateFrom = this.overdueBookingFromDate;
    const paymentDueDateTo = this.overdueBookingToDate;
    //paymentDueDateTo.setDate(this.overdueBookingToDate.getDate() - 1);
    if (this.user) {
      let username = null;
      if (!storeView) {
        username = this.user.userSummary.username;
      }
      const bookingCriteria: BookingGETCriteria = this.createCriteria(this.user.clientId, this.user.clientGroup, username, null, null,
        this.convertDateObjToStr(paymentDueDateFrom), this.convertDateObjToStr(paymentDueDateTo));

      this.updateSearchCriteria(bookingCriteria, this.convertDateObjToStr(paymentDueDateFrom),
        this.convertDateObjToStr(paymentDueDateTo), true);
      return bookingCriteria;
    }
  }

  emitSelectedOption(option) {
    console.log(this.paymentDueBookingCriteria);
    console.log(this.paymentOverDueBookingCriteria);
    if (option === this.STATUS_OVERDUE) {
      const paymentDueFromDate = this.overdueBookingFromDate;
      const paymentDueToDate = new Date(this.overdueBookingToDate);
      //paymentDueToDate.setDate(paymentDueToDate.getDate() - 1);
      this.updateSearchCriteria(this.paymentOverDueBookingCriteria, this.convertDateObjToStr(paymentDueFromDate),
        this.convertDateObjToStr(paymentDueToDate), true);
    }
    if (this.selectedOption === option) {
      // toggle selection
      this.selectedOption = this.STATUS_NONE;
    } else {
      this.selectedOption = this.STATUS_NONE;
      this.selectedOption = option;
      setTimeout(() => {
        this.tableElement.nativeElement.scrollIntoView();
      }, 0);
    }
  }

  getDefaultStartDate(): Date {
    const currentDate = new Date();
    return new Date( currentDate.getFullYear(), currentDate.getMonth() - 1 , currentDate.getDate());
  }

  getDefaultEndDate(): Date {
    const currentDate = new Date();
    return new Date( currentDate.getFullYear(), currentDate.getMonth()  , currentDate.getDate() + 1);
  }

  /**
   * Convert javascript Date object to yyyy-MM-dd
   * @param date
   */
  convertDateObjToStr(date ): string {
    let dateStr = '';
    if (date != null) {
      try {
        dateStr = date.getFullYear();

        dateStr = dateStr + '-';
        if (date.getMonth() < 9) {
          dateStr += '0' + (date.getMonth() + 1);
        } else {
          dateStr += '' + (date.getMonth() + 1);
        }

        dateStr = dateStr + '-';

        if (date.getDate() <= 9) {
          dateStr += '0' + date.getDate();
        } else {
          dateStr += '' + date.getDate();
        }
      } catch (e) {
        console.error(e);
      }
      return dateStr;
    }
  }

  toggleState(event) {
    this.selectedOption = this.STATUS_NONE;
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    const bookingEndDate = this.bookingCriteria.bkgDateTo;
    const bookingStartDate = this.bookingCriteria.bkgDateFrom;
    if (event == true) {
      this.active = true;
      this.refresh(this.createCriteria(this.user.clientId, this.user.clientGroup, null, bookingStartDate, bookingEndDate), true, true);
    } else {
      this.active = false;
      this.refresh(this.createCriteria(this.user.clientId, this.user.clientGroup, this.user.userSummary.username, bookingStartDate, bookingEndDate), true, true);
    }
    this.onToggleRetailStoreViewStatus.emit(this.active);
  }

  convertStrToDateObj(str) {
    if (str) {
      const dates = str.split('-');
      const  formattedDate = new Date(dates[0], dates[1] - 1, dates[2]);
      return formattedDate;
    }
    return  new  Date();
  }

  setDuration(option?: string, dateRange?: {from: Date, to: Date}) {
    this.paymentDueToDate = new Date();
    this.paymentDueDateFrom = new Date();

    this.bookingEndDate = new Date();

    this.overdueBookingFromDate = new Date();
    this.overdueBookingToDate = new Date();

    this.getUserTimeZoneInformation(this.user.userSummary.username, this.cookieService.get(this.headerTokenName))
      .subscribe( result => {
        console.log(result);
        if (result && result.data && result.data[0]) {
          this.dateTimeInUserTimeZone = result.data[0].currentDateTimeWithTimeZone;
        } else {
          this.dateTimeInUserTimeZone = undefined;
        }
        if (this.dateTimeInUserTimeZone !== undefined) {
          this.bookingEndDate = this.convertStrToDateObj(this.dateTimeInUserTimeZone.split('T')[0]);
          this.overdueBookingToDate = this.convertStrToDateObj(this.dateTimeInUserTimeZone.split('T')[0]);
        }
        this.selectedDurationOption = option;
        this.selectedOption = this.STATUS_NONE;
        this.selectedDateRange = dateRange;
        if (option) {
          switch (this.highlightDateRangeMode) {
            case this.supportedHighlightDateRangeModes.DEFAULT:
            case this.supportedHighlightDateRangeModes.LAST_X_DAYS:
              switch (option) {
                case this.defaultDurationOptions.WEEK:
                  this.paymentDueToDate.setDate(this.paymentDueToDate.getDate() + 7);
                  this.bookingStartDate = new Date(this.bookingEndDate.getFullYear(), this.bookingEndDate.getMonth(), this.bookingEndDate.getDate() - 7);
                  this.overdueBookingFromDate = new Date(this.overdueBookingToDate.getFullYear(), this.overdueBookingToDate.getMonth(), this.bookingEndDate.getDate() - 7);
                  break;
                case this.defaultDurationOptions.MONTH:
                  this.paymentDueToDate.setMonth(this.paymentDueToDate.getMonth() + 1);
                  this.bookingStartDate = new Date(this.bookingEndDate.getFullYear(), this.bookingEndDate.getMonth() - 1, this.bookingEndDate.getDate());
                  this.overdueBookingFromDate = new Date(this.overdueBookingToDate.getFullYear(), this.overdueBookingToDate.getMonth() - 1, this.overdueBookingToDate.getDate());
                  break;
                case this.defaultDurationOptions.QUARTER:
                  this.paymentDueToDate.setMonth(this.paymentDueToDate.getMonth() + 3);
                  this.bookingStartDate = new Date(this.bookingEndDate.getFullYear(), this.bookingEndDate.getMonth() - 3, this.bookingEndDate.getDate());
                  this.overdueBookingFromDate = new Date(this.overdueBookingToDate.getFullYear(), this.overdueBookingToDate.getMonth() - 3, this.overdueBookingToDate.getDate());
                  break;
                default:
                  break;
              }
              break;
            case this.supportedHighlightDateRangeModes.CURRENT_PERIOD:
              //this.paymentDueToDate = this.getLastDateOfPeriod(option, this.paymentDueToDate);
              this.bookingStartDate = this.getFirstDateOfPeriod(option, this.bookingEndDate);
              this.overdueBookingFromDate = this.getFirstDateOfPeriod(option, this.overdueBookingToDate);
              this.paymentDueDateFrom = this.getFirstDateOfPeriod(option, this.paymentDueToDate);
              break;
            default :
              break;
          }
        } else if (dateRange) {
          this.bookingStartDate = new Date(dateRange.from);
          this.bookingEndDate = new Date(dateRange.to);
          this.overdueBookingFromDate = new Date(dateRange.from);
          this.overdueBookingToDate = new Date(dateRange.to);
          this.paymentDueDateFrom = new Date(dateRange.from);
          this.paymentDueToDate = new Date(dateRange.to);
        }
        this.upcomingPaymentSubText = this.getUpcomingPaymentSubText(option, dateRange);
        this.overduePaymentSubText = this.getOverduePaymentSubText(option, dateRange);
        this.selectedDurationOptionHeadingTexts = this.getSelectedDurationOptionHeadingTexts(option, dateRange);
        this.refresh(this.createCriteria(this.bookingCriteria.cliId, this.bookingCriteria.cliGrp, this.bookingCriteria.bookedUser,
            this.convertDateObjToStr(this.bookingStartDate), this.convertDateObjToStr(this.bookingEndDate)), true, true);

      }, error => {
        console.log( error);
      });
    this.onSelectedDurationChanged.emit();
  }

  refresh(bookingCriteria: BookingGETCriteria, refreshUpPayment: boolean, refreshOverduePayment: boolean) {
    this.loading = true;
    this.bookingCriteria = bookingCriteria;

    this.highlightsMap = new Map();
    this.getMainHighlights(bookingCriteria, true, true);
    // if (refreshOverduePayment) {
    //   this.updateSearchCriteria(bookingCriteria, this.convertDateObjToStr(this.overdueBookingFromDate), this.convertDateObjToStr(this.overdueBookingToDate), true);
    //   this.getMainHighlights(bookingCriteria, false, true);
    // } else {
    //   this.updateSearchCriteria(bookingCriteria);
    //   this.getMainHighlights(bookingCriteria, true, false);
    // }
  }

  updateSearchCriteria(bookingCriteria: BookingGETCriteria, paymentDueDateFrom?: string, paymentDueDateTo?: string, isOverdue?: boolean) {
    if ((paymentDueDateFrom && paymentDueDateTo) && isOverdue) {
      if (this.disableScheduleBasedBookingSearch) {
        bookingCriteria.balanceDueDateFrom = paymentDueDateFrom;
        bookingCriteria.balanceDueDateTo = paymentDueDateTo;
        bookingCriteria.depositDueDateFrom = paymentDueDateFrom;
        bookingCriteria.depositDueDateTo = paymentDueDateTo;
      } else {
        bookingCriteria.payDuDtFrm = paymentDueDateFrom;
        bookingCriteria.payDuDtTo = paymentDueDateTo;
      }
    }
    if (!isOverdue) {
      bookingCriteria.balanceDueDateFrom = undefined;
      bookingCriteria.balanceDueDateTo = undefined;
      bookingCriteria.depositDueDateFrom = undefined;
      bookingCriteria.depositDueDateTo = undefined;
      bookingCriteria.payDuDtFrm = undefined;
      bookingCriteria.payDuDtTo = undefined;
    }
    if ((bookingCriteria && bookingCriteria.bkgDateFrom) && isOverdue) {
      delete bookingCriteria.bkgDateFrom;
    }
    if ((bookingCriteria && bookingCriteria.bkgDateTo) && isOverdue) {
      delete bookingCriteria.bkgDateTo;
    }
  }

  createCriteria(cliId: number, cliGrp: string, userName: string, bkgDateFrom?: string, bkgDateTo?: string,
                 paymentDueDateFrom?: string, paymentDueDateTo?: string): BookingGETCriteria {
    const bookingCriteria = new BookingGETCriteria();

    bookingCriteria.cliId = cliId;
    bookingCriteria.cliGrp = cliGrp;
    bookingCriteria.bookedUser = userName;
    bookingCriteria.cmp = this.getCompnay();
    bookingCriteria.brand = this.getBrand();
    bookingCriteria.div = this.getDivision();
    bookingCriteria.cur = this.getCurrency();
    bookingCriteria.quote = true;

    // When Emulating some values in key controls are replaced
    if (this.dataStore.get(DataKey.emulating) && this.dataStore.get(DataKey.emulatingTCProfile).getValue() ) {
      bookingCriteria.cmp = this.dataStore.get(DataKey.emulatingTCProfile).getValue().controls.cmp;
      bookingCriteria.div = this.dataStore.get(DataKey.emulatingTCProfile).getValue().controls.div;
      bookingCriteria.brand = this.dataStore.get(DataKey.emulatingTCProfile).getValue().controls.brand;
      bookingCriteria.channel = this.dataStore.get(DataKey.emulatingTCProfile).getValue().controls.channel;
      bookingCriteria.cliGrp = this.dataStore.get(DataKey.emulatingTCProfile).getValue().controls.cliGrp;
      bookingCriteria.cur = this.dataStore.get(DataKey.emulatingTCProfile).getValue().controls.cur;
      bookingCriteria.cliId = this.dataStore.get(DataKey.emulatingTCProfile).getValue().id;
      bookingCriteria.srcCountry = this.dataStore.get(DataKey.emulatingTCProfile).getValue().summary.address.country;
    }

    if (bkgDateFrom) {
      bookingCriteria.bkgDateFrom = bkgDateFrom;
    }
    if (bkgDateFrom) {
      bookingCriteria.bkgDateTo = bkgDateTo;
    }
    if (paymentDueDateFrom && paymentDueDateTo) {
      // the paymentDueDateFrom and paymentDueDateTo values are calculated based on the this.disableScheduleBasedBookingSearch flag.
      if (this.disableScheduleBasedBookingSearch) {
        bookingCriteria.balanceDueDateFrom = paymentDueDateFrom;
        bookingCriteria.balanceDueDateTo = paymentDueDateTo;
        bookingCriteria.depositDueDateFrom = paymentDueDateFrom;
        bookingCriteria.depositDueDateTo = paymentDueDateTo;
      } else {
        bookingCriteria.payDuDtFrm = paymentDueDateFrom;
        bookingCriteria.payDuDtTo = paymentDueDateTo;
      }
    }

    bookingCriteria.search = undefined;
    bookingCriteria.tax = undefined;
    bookingCriteria.pkgValidation = undefined;


    return bookingCriteria;
  }

  getCompnay() {
    return this.user.userDetail.defaultCom.code;
  }

  getBrand() {
    return this.user.userDetail.defaultBrand.code;
  }

  getDivision() {
    return this.user.userDetail.defaultDivison.code;
  }

  /**
   * get and map default currency to the booking criteria
   */
  getCurrency() {
    if (this.user && this.user.userDetail && this.user.userDetail.defaultCurrency
      && this.user.userDetail.defaultCurrency.code) {
      return this.user.userDetail.defaultCurrency.code;
    }
    return '';
  }

  // getUserTimeZoneInformation( username: string, session: string): Observable<any>  {
  //   let headersConfig: HttpHeaders = new HttpHeaders();
  //   headersConfig = headersConfig.append(this.headerUserName, username);
  //   headersConfig = headersConfig.append(this.headerTokenName, session);
  //
  //   return this.http.get(this.GET_USER_TIME_ZONE_ENDPOINT, { headers: headersConfig});
  // }


  getUserTimeZoneInformation(username: string, session: string): Observable<any> {
    const mockedResponse = {
      status: {
        code: 1,
        message: 'SUCCESS'
      },
      keyControls: {
        cliId: 0,
        userId: 0
      },
      data: [
        {
          userTimeZone: 'Europe/London',
          currentDateTimeWithTimeZone: this.getCurrentDateTimeWithTimeZone('Europe/London')
        }
      ],
      version: 'v2.0'
    };

    return of(mockedResponse);
    console.log('Mock Response', mockedResponse);
  }

  goBackToHomeView(): void {
    this.goToDashboardDetailsView.emit();
  }

  initHighlightDateRangeConfigs() {
    const config = this.configLoader.getModuleConfig('DASHBOARD_HIGHLIGHTS_DATE_RANGE_MODES', TC.MODULE_NAME.SURF_B2B);
    const configArray = config.split(',', 2).map(item => item.trim());
    this.highlightDateRangeMode = configArray.filter(item => item !== this.CUSTOM_DATE_RANGE_MODE)[0];
    if (!this.highlightDateRangeMode) {
      this.highlightDateRangeMode = this.supportedHighlightDateRangeModes.DEFAULT;
    }
    this.isCustomDateRangeEnabled = configArray.indexOf(this.CUSTOM_DATE_RANGE_MODE) !== -1;
  }

  getUpcomingPaymentSubText(durationOption?: string, dateRange?: {from: Date, to: Date}): string {
    let upcomingPaymentSubText = '';
    if (durationOption) {
      switch (this.highlightDateRangeMode) {
        case this.supportedHighlightDateRangeModes.DEFAULT:
          upcomingPaymentSubText = `For upcoming ${this.getDurationOptionDisplayValue(durationOption)}`;
          break;
        case this.supportedHighlightDateRangeModes.LAST_X_DAYS:
          upcomingPaymentSubText = `For next ${this.getDurationOptionDayCount(durationOption)} days`;
          break;
        case this.supportedHighlightDateRangeModes.CURRENT_PERIOD:
          upcomingPaymentSubText = `For this ${this.getDurationOptionDisplayValue(durationOption)}`;
          break;
        default :
          break;
      }
    } else if (dateRange) {
      upcomingPaymentSubText = `From ${this.getDisplayDate(dateRange.from)} - ${this.getDisplayDate(dateRange.to)}`;
    }
    return upcomingPaymentSubText;
  }

  getOverduePaymentSubText(durationOption?: string, dateRange?: {from: Date, to: Date}): string {
    let overduePaymentSubText = '';
    if (durationOption) {
      switch (this.highlightDateRangeMode) {
        case this.supportedHighlightDateRangeModes.DEFAULT:
          overduePaymentSubText = `Overdue last ${this.getDurationOptionDisplayValue(durationOption)}`;
          break;
        case this.supportedHighlightDateRangeModes.LAST_X_DAYS:
          overduePaymentSubText = `Overdue last ${this.getDurationOptionDayCount(durationOption)} days`;
          break;
        case this.supportedHighlightDateRangeModes.CURRENT_PERIOD:
          overduePaymentSubText = `Overdue this ${this.getDurationOptionDisplayValue(durationOption)}`;
          break;
        default :
          break;
      }
    } else if (dateRange) {
      const overduePaymentStartDate = new Date(dateRange.from.getFullYear(), dateRange.from.getMonth(), dateRange.from.getDate());
      overduePaymentSubText = `From ${this.getDisplayDate(overduePaymentStartDate)} - ${this.getDisplayDate(dateRange.to)}`;
    }
    return overduePaymentSubText;
  }

  getSelectedDurationOptionHeadingTexts(durationOption?: string, dateRange?: {from: Date, to: Date}): {section1: string, section2: string} {
      let section1 = '';
      let section2 = '';
      if (durationOption) {
        switch (this.highlightDateRangeMode) {
          case this.supportedHighlightDateRangeModes.DEFAULT:
            section1 = 'In the';
            section2 = `last ${this.getDurationOptionDisplayValue(durationOption)}`;
            break;
          case this.supportedHighlightDateRangeModes.LAST_X_DAYS:
            section1 = 'In';
            section2 = `last ${this.getDurationOptionDayCount(durationOption)} days`;
            break;
          case this.supportedHighlightDateRangeModes.CURRENT_PERIOD:
            section1 = 'In';
            section2 = `this ${this.getDurationOptionDisplayValue(durationOption)}`;
            break;
          default :
            break;
        }
      } else {
        section1 = 'From';
        section2 = `${this.getDisplayDate(dateRange.from)} - ${this.getDisplayDate(dateRange.to)}`;
      }
      return {
        section1,
        section2
      };
  }

  getDurationOptionDayCount(durationOption: string): number {
    let days;
    switch (durationOption) {
      case this.defaultDurationOptions.WEEK:
        days = 7;
        break;
      case this.defaultDurationOptions.MONTH:
        days = 30;
        break;
      case this.defaultDurationOptions.QUARTER:
        days = 90;
        break;
      default:
        break;
    }
    return days;
  }

  getDurationOptionDisplayValue(durationOption: string): string {
    let displayText;
    switch (durationOption) {
      case this.defaultDurationOptions.WEEK:
        displayText = 'week';
        break;
      case this.defaultDurationOptions.MONTH:
        displayText = 'month';
        break;
      case this.defaultDurationOptions.QUARTER:
        displayText = 'quarter';
        break;
      default:
        break;
    }
    return displayText;
  }

  getDurationDropdownDisplayValue(durationOption: string): string {
    let durationText;
    switch (this.highlightDateRangeMode) {
      case this.supportedHighlightDateRangeModes.DEFAULT:
        durationText = `Last ${this.getDurationOptionDisplayValue(durationOption)}`;
        break;
      case this.supportedHighlightDateRangeModes.LAST_X_DAYS:
        durationText = `Last ${this.getDurationOptionDayCount(durationOption)} days`;
        break;
      case this.supportedHighlightDateRangeModes.CURRENT_PERIOD:
        durationText = this.getDurationOptionDisplayValue(durationOption);
        break;
      default :
        break;
    }
    return durationText;
  }

  getDurationDropdownDisplayRange(durationOption: string): string {
    let toDate = new Date(), fromDate = new Date();
    switch (this.highlightDateRangeMode) {
      case this.supportedHighlightDateRangeModes.DEFAULT:
      case this.supportedHighlightDateRangeModes.LAST_X_DAYS:
        switch (durationOption) {
          case this.defaultDurationOptions.WEEK:
            fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - 7);
            break;
          case this.defaultDurationOptions.MONTH:
            fromDate = new Date(toDate.getFullYear(), toDate.getMonth() - 1, toDate.getDate());
            break;
          case this.defaultDurationOptions.QUARTER:
            fromDate = new Date(toDate.getFullYear(), toDate.getMonth() - 3, toDate.getDate());
            break;
          default:
            break;
        }
        break;
      case this.supportedHighlightDateRangeModes.CURRENT_PERIOD:
        fromDate = this.getFirstDateOfPeriod(durationOption, toDate);
        break;
      default :
        break;
    }
    return `${this.getDisplayDate(fromDate)} - ${this.getDisplayDate(toDate)}`;
  }

  durationOptionsSortingFn(): number {
    return 0;
  }

  getLastDateOfPeriod(periodOption: string, date: Date) {
    let lastDate: Date;
    switch (periodOption) {
      case this.defaultDurationOptions.WEEK:
        lastDate = this.getLastDateOfCurrentWeek(date);
        break;
      case this.defaultDurationOptions.MONTH:
        lastDate = this.getLastDateOfCurrentMonth(date);
        break;
      case this.defaultDurationOptions.QUARTER:
        lastDate = this.getLastDateOfCurrentQuarter(date);
        break;
      default:
        break;
    }
    return lastDate;
  }

  getFirstDateOfPeriod(periodOption: string, date: Date) {
    let firstDate: Date;
    switch (periodOption) {
      case this.defaultDurationOptions.WEEK:
        firstDate = this.getFirstDateOfCurrentWeek(date);
        break;
      case this.defaultDurationOptions.MONTH:
        firstDate = this.getFirstDateOfCurrentMonth(date);
        break;
      case this.defaultDurationOptions.QUARTER:
        firstDate = this.getFirstDateOfCurrentQuarter(date);
        break;
      default:
        break;
    }
    return firstDate;
  }

  getFirstDateOfCurrentWeek(date: Date): Date {
    const currentDate = new Date(date);
    const currentDay = currentDate.getDay();
    const diff = currentDate.getDate() - currentDay + (currentDay == 0 ? -6 : 1);
    return new Date(currentDate.setDate(diff));
  }

  getFirstDateOfCurrentMonth(date: Date): Date {
    const currentDate = new Date(date);
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  }

  getFirstDateOfCurrentQuarter(date: Date): Date {
    const currentDate = new Date(date);
    const currentQuarter = Math.floor(currentDate.getMonth() / 3 + 1);
    return new Date(currentDate.getFullYear(), 3 * (currentQuarter - 1), 1);
  }

  getLastDateOfCurrentWeek(date: Date): Date {
    const currentDate = new Date(date);
    const firstDateOfWeek = this.getFirstDateOfCurrentWeek(currentDate);
    return new Date(firstDateOfWeek.setDate(firstDateOfWeek.getDate() + 6));
  }

  getLastDateOfCurrentMonth(date: Date): Date {
    const currentDate = new Date(date);
    return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  }

  getLastDateOfCurrentQuarter(date: Date): Date {
    const currentDate = new Date(date);
    const firstDateOfQuarter = this.getFirstDateOfCurrentQuarter(currentDate);
    return new Date(firstDateOfQuarter.getFullYear(), firstDateOfQuarter.getMonth() + 3, 0);
  }

  onChangeDurationOption(durationOption) {
    switch (durationOption) {
      case this.CUSTOM_DATE_RANGE_MODE:
        this.setAvailableDates(new Date());
        this.toggleRangeSelectionModal(true);
        break;
      default:
        this.setDuration(durationOption);
        break;
    }
  }

  toggleRangeSelectionModal(status) {
    this.showRangeSelectionModal = status;
  }

  applySelectedDates(rangeSelectionStatus: boolean) {
    this.selectedStartDate = new Date(this.selectedDateRangeInCalendar[0]);
    this.selectedEndDate = new Date(this.selectedDateRangeInCalendar[this.selectedDateRangeInCalendar.length - 1]);
    this.setDuration(null, {from : this.selectedStartDate, to : this.selectedEndDate});
    this.toggleRangeSelectionModal(false);
  }

  closeRangeSelectionModal() {
    this.selectedDateRangeInCalendar = this.getDatesInRange(this.selectedStartDate, this.selectedEndDate);
    this.setDuration(null, {from : this.selectedStartDate, to : this.selectedEndDate});
    this.toggleRangeSelectionModal(false);
  }

  getSelectedDateRange(selectedDates) {
    this.selectedDateRangeInCalendar = selectedDates;
    this.onlyStartDateSelected = false;
  }

  startDateSelected(event) {
    this.selectedDateRangeInCalendar = [];
    this.onlyStartDateSelected = true;
  }

  getDisplayDate(date: Date) {
    return `${date.toLocaleDateString('en-us', {day: 'numeric'})} ${date.toLocaleDateString('en-us', { month: 'short' })} ${date.toLocaleDateString('en-us', { year: 'numeric'})}`;
  }

  /**
   * generate available dates array for availability calendar
   * @param startDate
   */
  setAvailableDates(startDate: Date) {
    this.available_dates = [];
    const range = +this.range * 365;
    for (let i = 0; i < range; i++) {
      this.available_dates.push(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i));
    }
  }

  /**
   * generate dates between two different dates
   * @param startDate
   * @param endDate
   */
  getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  getHighlightValue(option) {
    let value;
    switch (option.state) {
      case this.STATUS_All_PAID_BOOKINGS :
        value = this.highlightsMap.get(this.TOTAL_BOOKINGS);
        break;
      default :
        value = this.highlightsMap.get(option.name);
        break;
    }
    return value;
  }
}
