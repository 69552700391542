<surf-multi-selector-drop-down
  [title]="title"
  [dataList]="paxSplitArray"
  [inputDisplay]="paxSplitDisplayText"
  [chipsVisible]="chipsVisible"
  [hasError]="hasError"
  [isDisabled]="isDisabled"
  [dropUP]="true"
  [inputType]="'Guest'"
  [tailingTextForSelection]="''"
  [emitWhenDisableCheckBox]="emitWhenDisable"
  (selectionChange)="passengerSplitChange($event)"
  (emitClosedItem)="passengerSplitChange($event)"
>
</surf-multi-selector-drop-down>

