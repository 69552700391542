import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SurfPaginationInstance} from '@surf/surf-pagination';
import {ClientGETCriteria} from '@tc-core/model/it/codegen/ui/search/criteria/client-GET-criteria';
import {DataKey, DataStore} from '@tc-core/util/framework';
import {Subscription} from 'rxjs';
import { Client } from '@tc-core/model/it/codegen/tbx/api/client/client';
import {ClientSearchServiceHandler} from '@tc-core/service/service-handlers';
import {CommonHelper} from '@tc-core/util/helpers';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TC} from "@tc-core/util";

@Component({
  selector: 'surf-client-search-modal',
  templateUrl: './surf-client-search-modal.component.html',
})
export class SurfClientSearchModalComponent implements OnInit {

  @Output() openAndCloseSurfClientSearchModal = new EventEmitter<string>();
  @Output() selectClient = new EventEmitter<string>();

  clientResultsAvailable = false;
  clientResultArray = [];
  paginationInstance: SurfPaginationInstance;
  paginationInstanceName = 'ClientResultPagination';
  DEFAULT_RESULTS_COUNT_PER_PAGE = 5;
  page = 1;
  fName = ''; // value in the search input
  cliRef = ''; // value in the search input
  SEARCH_SOURCE = 'TBX';
  TRADE = 'TRADE';
  tradeClientSearchInitiated = false;
  tradeClientSearchLoading = false;
  selectedTradeClient = null;
  clientSearchSubscription: Subscription = new Subscription();
  resultsNotFound = false;
  isEmpty = true;
  isEmptyInput = true;
  inputVal = '';
  ADDRESS_TEXT_CASE = 'title';

  constructor(
    private dataStore: DataStore,
    private clientSearchServiceHandler: ClientSearchServiceHandler,
    private commonHelper: CommonHelper
  ) { }

  ngOnInit() {
  }

  /**
   * handle Surf-Client-Search-Modal
   * @param: $event
   */
  handleSurfClientSearchModal($event) {
    this.resetClientResults();
    this.openAndCloseSurfClientSearchModal.emit($event);
  }

  /**
   * resetting client result set
   */
  resetClientResults() {
    this.clientResultArray = [];
    this.fName = '';
    this.inputVal = '';
    this.isEmptyInput = true;
    this.resultsNotFound = false;
    this.cliRef = '';
    this.selectedTradeClient = null;
    this.clientResultsAvailable = false;
  }

  searchClient() {
    this.searchMatchingProfile();
    this.paginationInstance = {
      id: this.paginationInstanceName,
      itemsPerPage: this.DEFAULT_RESULTS_COUNT_PER_PAGE,
      currentPage: this.page
    };
  }

  /**
   * Triggers the client profile search API call
   */
  private searchMatchingProfile() {
    this.clientResultArray = [];

    const clientGetCriteria = new ClientGETCriteria();
    clientGetCriteria.cliType = this.TRADE;
    clientGetCriteria.fName = this.fName; // currently only the first name is used
    clientGetCriteria.cliRef = this.cliRef;
    clientGetCriteria.searchSource = this.SEARCH_SOURCE;
    clientGetCriteria.limit = 100;

    // resetting unwanted default parameters
    clientGetCriteria.cliId = null;
    clientGetCriteria.userId = null;
    clientGetCriteria.quote = null;
    clientGetCriteria.tax = null;
    clientGetCriteria.isUpdated = null;
    clientGetCriteria.search = null;
    clientGetCriteria.offset = null;

    this.dataStore.set(DataKey.clients, null);
    this.tradeClientSearchInitiated = true;
    this.tradeClientSearchLoading = true;

    // @ts-ignore
    this.clientSearchSubscription = this.clientSearchServiceHandler.searchClients(clientGetCriteria).subscribe(
      result => {
        if (this.commonHelper.dataValidity(result)) {
          this.resultsNotFound = false;
          this.clientResultArray = result;
          this.tradeClientSearchLoading = false;
          this.clientResultsAvailable = true;
          this.clientSearchSubscription.unsubscribe();
        } else if ((result instanceof TcHttpError) || (result instanceof TcApiError) || (result && result.length === 0)) {
          this.resultsNotFound = true;
          this.tradeClientSearchLoading = false;
          this.clientResultsAvailable = false;
          this.clientSearchSubscription.unsubscribe();
        }
      }
    );
  }

  /**
   * select Trade Client
   * @param: client
   */
  selectClientOption(client: any) {
    this.resetClientResults();
    this.handleSurfClientSearchModal('close');
    this.selectClient.emit(client);
  }

  onPageChange(event) {
    this.paginationInstance.currentPage = event;
  }

  /**
   * set First Name
   * @param: $event
   */
  setFName($event: {}) {
    this.isEmptyInput = false;
    this.fName = $event.toString();
  }

  /**
   * set Client Ref
   * @param: $event
   */
  setCliRef($event: {}) {
    this.cliRef = $event.toString();
  }
}

