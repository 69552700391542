import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import {
  BaseCriteria,
  Configurations,
  DataShareService,
  FRONT_END_LOG_WRAPPERS,
  FRONT_END_LOG_WRAPPERS_CONTEXT,
  LOG_WRAPPERS,
  LOG_WRAPPERS_CONTEXT,
  SessionStorage,
  SurfC1RedirectEventModel,
  SurfClientSupportService,
  SurfCountryPipe,
  SurfCriteriaUtil,
  SurfCustomLog,
  SurfDatePipe,
  SurfDeviceDetectorService, SurfExternalProductCriteriaEventService,
  SurfHealthCheckService,
  SurfLoggerService, SurfLoginSessionExternalizableDataEnum,
  SurfPageLoadPerformanceLog,
  SurfTabDataExternalizableEnum,
  SurfRedirectEventProceedDataParametersEnum,
  SurfRedirectUrlCaptureServiceService,
  SurfPerformanceLogService, SurfStaticDataService,
  SurfUtilService,
  ExperimentalFeatureService
} from '@surf/surf-components-core';
import {MasterDataStoreService, StoreConstants} from '@surf/surf-state-manage';
import {PaxAllocationItem} from '@surf/surf-pax-selection';
import {User} from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import {LogLevel, LogWrapper} from '@tc-core/model/it/codegen/ui/framework/log/log-wrapper';
import {LoggerService} from '@tc-core/service/helpers/logging';
import {ClientSearchServiceHandler, DataServiceHandler} from '@tc-core/service/service-handlers';
import {TC} from '@tc-core/util';
import {ConfigLoader, LogStore} from '@tc-core/util/framework';
import {DataKey, DataStore} from '@tc-core/util/framework/data-store.service';
import {environment} from '../../../environments/environment.prod';
import {ClientGETCriteria} from '@tc-core/model/it/codegen/ui/search/criteria/client-GET-criteria';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {Subscription} from 'rxjs';
import {CommonHelper} from '@tc-core/util/helpers';
import {AppService} from '../../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {KeyControlParameter} from '@tc-core/model/it/codegen/tbx/api/v2/key-control-parameter';
import moment from 'moment';
import {PnrComponentOutput} from '@surf/surf-grab-pnr/public-api';

declare function initializeP2PWidget({userDetails, themeCode});
declare function initializeP2PWidgetManually({userDetails, mode});
declare function initializeP2PWidgetManually({userDetails, eventHandlers, themeCode, skill});
declare const Chat: any;

@Component({
  selector: 'surf-b2b-dashboard',
  templateUrl: 'b2b-dashboard.component.html',
  host: {'class': 'l-page'}
})
export class B2bDashboardComponent implements OnInit, AfterViewInit, AfterContentInit {
  readonly PRODUCT_CODES = TC.Product;
  activeSearchFlow: string;
  version = environment.version;
  isHealchCheckPassed = false;
  healthCheckUrl = 'this.is.mock.url.com/healthcheck';
  user: User = null;
  greetingTest: string = null;
  brands = [];
  currencies = [];
  // keyControlsForGrabPNR: TbxKeyControlParameter;
  moduleConfigs: any;
  baseCriteria: BaseCriteria;
  baseHTLCriteria: BaseCriteria;
  baseFLTCriteria: BaseCriteria;
  baseTRSCriteria: BaseCriteria;
  baseCARCriteria: BaseCriteria;
  baseCRUCriteria: BaseCriteria;
  baseGENCriteria: BaseCriteria;
  defBrand = {code: null, name: null};
  defCurrency = {code: null, name: null};
  defaultResultsCount = 40;
  triggerDropDownChange = false;
  showSearchBar = true;
  userAgent: string;
  activeMBIProductCode: string;
  // Emulation related
  activeEmulationWarning = false;
  countryPipe = new SurfCountryPipe(this.dataServiceHandler);
  subscriptions: Subscription[] = [];

  isSurfClientSearchModalActive = false;
  emulationInProgress = false;
  tokenCookieName = 'X-rst-session';
  EmulationWarningText =  'You are about to emulate to an agency outside your own. ' +
    'Any quote or booking will be created in that agency. Do you wish to continue?';
  TRADE_CLIENT_OWNERSHIP_TYPE = 'TRADE_CLIENT_OWNERSHIP_TYPE';
  userTypeDef = {
    Retail: 'Retail',
    Support: 'Support',
    Content: 'Content',
    External: 'External'
  };
  emulatingTradeClient = null;
  emulatingTradeClientId = null;
  originalUserTradeClient = null;
  originalUserTradeClientKey = 'originalUserTradeClientKey';
  emulating = 'emulating';
  emulatingTCProfile = 'emulatingTCProfile';
  clientSearchSubscription: Subscription = new Subscription();
  showManualItemConfigStr:string;
  showManualItemConfig:boolean = false;
  showManualItemConfigPnr:boolean = false;
  flightGrabPnrResult : any ;// replace with model after model make
  pnrComOutput :PnrComponentOutput;
  bkgId = '';
  activeToaster = false;
  chatBotThemeCodeMap = '';
  chatBotSkillsMap = '';
  chatBotTenant = '';
  activeSessionBar: boolean;
  sessionBarData: any;
  handler: SurfC1RedirectEventModel;
  allowNgrxCaching = false;
  cachingItemMap = new Map<string, number>();
  paxSubTypeAges: any[];
  h2hSystems: any[];

  STORE_CONSTANTS = StoreConstants;

  enableDashboardDetailView : boolean = false;
  readonly ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW = this.configLoader.getModuleConfig('ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW', TC.MODULE_NAME.SURF_B2B) === "TRUE";
  isBookingSearchInputFocussed : boolean = true;

  constructor(private dataShare: DataShareService,
              private surfPerformanceLogService: SurfPerformanceLogService,
              private deviceService: SurfDeviceDetectorService,
              private healthCheckServiice: SurfHealthCheckService,
              private dataServiceHandler: DataServiceHandler,
              private dataStore: DataStore,
              private logStore: LogStore,
              public surfCriteriaUtil: SurfCriteriaUtil,
              private surfStaticDataService: SurfStaticDataService,
              private appService: AppService,
              private surfLoggerService: SurfLoggerService,
              private loggerService: LoggerService,
              private clientSearchServiceHandler: ClientSearchServiceHandler,
              private commonHelper: CommonHelper,
              private configLoader: ConfigLoader,
              private route: ActivatedRoute,
              private router: Router,
              private cookieService: CookieService,
              private surfUtilService: SurfUtilService,
              private surfRedirectUrlCaptureServiceService: SurfRedirectUrlCaptureServiceService,
              private surfClientSupportService: SurfClientSupportService,
              private surfExternalProductCriteriaEventService: SurfExternalProductCriteriaEventService,
              private masterDataStoreService: MasterDataStoreService,
              private experimentalFeatureService: ExperimentalFeatureService
  ) {
  }

  ngOnInit(): void {
    // SurfDatePipe.fixedLocale = 'en-GB';
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    localStorage.setItem('previous-page', '');
    localStorage.setItem('search-results-string', '');
    SurfDatePipe.fixedLocale = this.surfUtilService.validateObject(this.user.userDetail.defaultDivison.detail, 'locale') ?
      this.user.userDetail.defaultDivison.detail.locale : '';
    this.resetSessionStorage();
    this.showManualItemConfigStr = this.configLoader.getModuleConfig(Configurations.ENABLE_MANUAL_BOOKING_ITEMS, TC.MODULE_NAME.SURF_B2B);
    this.showManualItemConfig = (this.showManualItemConfigStr == "true");

    // for Emulation
    this.emulatingTradeClientId = sessionStorage.getItem(this.emulatingTCProfile);

    // sessionStorage.setItem(this.originalUserTradeClientKey, String(this.user.clientId));
    // this.dataStore.set(DataKey.tradeClient, this.user.clientId);
    // this.getTradeClient(this.user.clientId);
    /*if (sessionStorage.getItem(this.emulating) && sessionStorage.getItem(this.emulatingTCProfile)) {
      console.log(sessionStorage.getItem(this.emulating));
      console.log(sessionStorage.getItem(this.emulatingTCProfile));
    }*/

    this.dataShare.getBaseCriteria().subscribe(cri => {
      this.baseCriteria = cri;
      this.updateCriteriaKeyControls();
      this.startStopEmulation('start');
    });

    this.dataShare.getComponentCriteria('HTL').subscribe(cri => {
      this.baseHTLCriteria = cri;
      this.updateCriteriaKeyControls();
    });

    this.dataShare.getComponentCriteria('FLT').subscribe(cri => {
      this.baseFLTCriteria = cri;
      this.updateCriteriaKeyControls();
    });

    this.dataShare.getComponentCriteria('TRS').subscribe(cri => {
      this.baseTRSCriteria = cri;
      this.updateCriteriaKeyControls();
    });

    this.dataShare.getComponentCriteria('CAR').subscribe(cri => {
      this.baseCARCriteria = cri;
      this.updateCriteriaKeyControls();
    });

    this.dataShare.getComponentCriteria('CRU').subscribe(cri => {
      this.baseCRUCriteria = cri;
      this.updateCriteriaKeyControls();
    });

    this.dataShare.getComponentCriteria('GEN').subscribe(cri => {
      this.baseGENCriteria = cri;
      this.updateCriteriaKeyControls();
    });
    this.dataShare.getCurrentMsg().subscribe(msg => {
      if (msg === 'HTL_close' || msg === 'FLT_close' || msg === 'PKG_close' || msg === 'CAR_close'
        || msg === 'DP_close' || msg === 'GEN_close' || msg === 'TRS_close' || msg === 'MAN_close'
        || msg === 'CRU_close' || msg === 'MANFLT_close' || msg === 'TOU_close') {
        this.activeSearchFlow = null;
        this.showSearchBar = true;
      }
      if (msg != undefined && msg.split('~') != undefined && (msg.split('~')[0] === 'DP_Switch')) {
        this.activeSearchFlow = 'DP';
      }
    });
    // this.dataServiceHandler.getCurrencies().subscribe(
    //   (res) => {
    //     if (res) {
    //       this.currencies = res;
    //       this.setCurrencies();
    //     }
    //   },
    //   (error) => {
    //     console.error('Error in getting Currencies');
    //   });
    //
    // this.setBrands();
    // this.performHealthCheck();
    if (performance.navigation.type == 1) {

      const emulating = JSON.stringify(sessionStorage.getItem('emulating'));
      const emulatingTCProfile = JSON.stringify(sessionStorage.getItem('emulatingTCProfile'));
      const excludeKeysLoginSession = Object.keys(SurfLoginSessionExternalizableDataEnum);
      excludeKeysLoginSession.push(SurfTabDataExternalizableEnum.TAB_ID);
      // for experimental feature
      excludeKeysLoginSession.push('exp');
      const excludeKeys = excludeKeysLoginSession.join(',');
      this.surfUtilService.clearSessionStorage(excludeKeys);
      // When emulating, the state is needed when refreshing. Therefore, the session Storage items will be reinstated here.
      if (emulating === 'true' && emulatingTCProfile !== 'null') {
        sessionStorage.setItem('emulating', emulating);
        sessionStorage.setItem('emulatingTCProfile', emulatingTCProfile);
      }
    }
    this.surfLoggerService.addWebStorageLog();
    let log = new SurfCustomLog();
    log.message = 'Dashboard Initialized';
    this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.INFO );

    // initialize lia chat bot widget
    // this.initializeChatBot();
    this.route.queryParams.subscribe((params) => {
      if (params.bkgId) {
        this.bkgId = params.bkgId;
        this.activeToaster = true;
        this.cleanUrl();
      }
    });
    this.handler = new SurfC1RedirectEventModel(this.surfRedirectUrlCaptureServiceService, this.surfExternalProductCriteriaEventService);
    this.handler.setNeedUrlClean(true);

    // check if ngrx enabled by module config
    this.allowNgrxCaching = this.configLoader.getModuleConfig('B2B_DASHBOARD_CACHE_STORE_ENABLE',
      TC.MODULE_NAME.SURF_B2B)?.toString()?.toUpperCase() === 'TRUE';
    if (this.experimentalFeatureService.has(this.STORE_CONSTANTS.EXPERIMENTAL_FEATURE_CONFIG)) {
      this.allowNgrxCaching = this.experimentalFeatureService.get(this.STORE_CONSTANTS.EXPERIMENTAL_FEATURE_CONFIG);
    }
    if (this.allowNgrxCaching) {
      this.loadCachingConfigsForModules();
    }
    this.loadStaticData();

  }

  /**
   * removing items in session storage
   */
  resetSessionStorage() {
    // reset state of item that stores whether opaque view shortcut has been pressed or not
    if (sessionStorage.getItem(SessionStorage.IS_OPAQUE_VIEW_SHORTCUT_PRESSED) != null) {
      sessionStorage.removeItem(SessionStorage.IS_OPAQUE_VIEW_SHORTCUT_PRESSED);
    }

    // remove current cart session from session storage
    if (sessionStorage.getItem(SurfTabDataExternalizableEnum.CART_SESSION) != null) {
      sessionStorage.removeItem(SurfTabDataExternalizableEnum.CART_SESSION);
    }
  }

  cleanUrl() {
    this.router.navigate([], {
      queryParams: {
        bkgId: null,
        isFromNavigationAwayEvent: null,
        youCanRemoveMultiple: null,
      },
      queryParamsHandling: 'merge'
    });
  }

  initializeChatBot() {
    try {
      let enableChatBot = false;
      let enableSurveyBot = false;
      let enableMultiSkillBot = false;
      let chatURl = '';
      let chatWaitTime = 5000;

      // check if lia chat enabled by module config
      enableChatBot = this.configLoader.getModuleConfig('CHATBOT_ENABLE', TC.MODULE_NAME.SURF_B2B).toString().toUpperCase() === 'TRUE';
      enableSurveyBot = this.configLoader.getModuleConfig('SURVEYBOT_ENABLE', TC.MODULE_NAME.SURF_B2B).toString().toUpperCase() === 'TRUE';
      enableMultiSkillBot = this.configLoader.getModuleConfig('MULTI_SKILLED_BOT_ENABLE', TC.MODULE_NAME.SURF_B2B).toString().toUpperCase() === 'TRUE';

      chatWaitTime = this.configLoader.getModuleConfig('CHATBOT_LIA_WIDGET_WAIT_TIME', TC.MODULE_NAME.SURF_B2B) ?
        parseInt(this.configLoader.getModuleConfig('CHATBOT_LIA_WIDGET_WAIT_TIME', TC.MODULE_NAME.SURF_B2B)) : 5000;

      chatURl = this.configLoader.getModuleConfig('CHATBOT_LIA_WIDGET_URL', TC.MODULE_NAME.SURF_B2B);

      const chatBotThemeCodeMap = this.configLoader.getModuleConfig('CHATBOT_LIA_THEME_CODE_MAP', 'SURF B2B');
      if (chatBotThemeCodeMap) {
        this.chatBotThemeCodeMap = chatBotThemeCodeMap.toString();
      }

      //page wise skills map ex: DASHBOARD:SURVEY;BOOKING:TIPS;OTHER:SURVEY,TIPS
      const chatBotSkillsMap = this.configLoader.getModuleConfig('CHATBOT_LIA_SKILLS_MAP', 'SURF B2B');
      if (chatBotSkillsMap) {
        this.chatBotSkillsMap = chatBotSkillsMap.toString();
      }

      // for lia chat bot
      const chatBotTenant = this.configLoader.getModuleConfig('CHATBOT_LIA_P2P_TENANT', 'SURF B2B');

      if (enableChatBot && chatURl) {
        const promise = this.loadScript(chatURl, chatBotTenant.toString());
        promise.then(
          result => this.initLiaWidget(chatWaitTime),
          error => () => {
            const log = new SurfCustomLog();
            log.message = 'Lia ChatBot Loading failed';
            this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.SEVERE );
          }
        );
      }

      // for lia survey bot
      const surveyBotTenant = this.configLoader.getModuleConfig('SURVEYBOT_LIA_P2P_TENANT', 'SURF B2B');

      if (enableSurveyBot && chatURl) {
        const promise = this.loadScriptWithTenant(chatURl, surveyBotTenant.toString());
        promise.then(
          result => this.initLiaSurveyBotWidget(chatWaitTime),
          error => () => {
            const log = new SurfCustomLog();
            log.message = 'Lia SurveyBot Loading failed';
            this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.SEVERE );
          }
        );
      }

      // for lia multi skilled bot
      const multiSkillBotTenant =  this.configLoader.getModuleConfig('CHATBOT_LIA_P2P_TENANT', 'SURF B2B');

      if (enableMultiSkillBot && chatURl) {
        const promise = this.loadScriptWithTenant(chatURl, multiSkillBotTenant.toString());
        promise.then(
          result => this.initLiaMultiSkillBotWidget(chatWaitTime),
          error => () => {
            const log = new SurfCustomLog();
            log.message = 'Lia MultiSkillBot Loading failed';
            this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.SEVERE );
          }
        );
      }

    } catch (e) {
      const log = new SurfCustomLog();
      log.message = 'ChatBot initialization failed...' + e;
      this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.SEVERE );
    }
  }
  onclickActionItem(event){
    if (event && event === 'cancel') {
      this.activeToaster = false;
    }
    if (event && event === 'confirm') {
      const basePath = '/';
      window.location.href = basePath + 'b2b-booking/?bkgId=' + this.bkgId;
    }
  }
  loadScript(url: string, tenant: string) {
      return new Promise((resolve, reject) => {

        var meta = document.createElement('meta');
        meta.name = "p2p-tenant";
        meta.content = tenant;
        document.getElementsByTagName('head')[0].appendChild(meta);

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onload = () => resolve(true);
        script.onerror = (error: any) => reject(error);
        document.getElementsByTagName('head')[0].appendChild(script);
      });
  }

  loadScriptWithTenant(url: string, tenant: string) {
    return new Promise((resolve, reject) => {

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url + '/' + tenant;
      script.onload = () => resolve(true);
      script.onerror = (error: any) => reject(error);
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  initLiaWidget(chatWaitTime) {
    try {
      // calls initialize method when chat script is loaded.
      // initializeWidget();

      // wait time added since the widget initializing requires time and we have to wait before calling initializeP2PWidget()
      // let promise = new Promise(resolve => {
      //   setTimeout(() => resolve('done!'), chatWaitTime);
      // });

      // promise.then( () => {
        // get the name and email from logged in user to default on the chat window
        let firstName = '';
        let lastName = '';
        let email = '';
        let themeCode = '';
        if (this.user && this.user.userSummary ) {
          firstName = this.user.userSummary.firstName ? this.user.userSummary.firstName : '';
          lastName = this.user.userSummary.lastName ? this.user.userSummary.lastName : '';
          email = this.user.userSummary.email ? this.user.userSummary.email : '';
        }
        if (this.chatBotThemeCodeMap) {
          const themeCodes = this.chatBotThemeCodeMap.split(';').filter(
            code =>
              code.split(':')[0] === this.cookieService.get('X-rst-brand')
          );
          if (themeCodes.length > 0) {
            themeCode = themeCodes[0].split(':')[1];
          } else {
            // if there's no brand set it to first
            themeCode = this.chatBotThemeCodeMap.split(';')[0].split(':')[1];
          }
        }

        // calls initialize method with user info
        // initializeP2PWidget({name: firstName + ' ' + lastName, email, themeCode}); //old  signature
        initializeP2PWidget({
          userDetails: {
            name: firstName + ' ' + lastName,
            email:email
          },
          themeCode: themeCode
        });

      // });
    } catch (e) {
      let log = new SurfCustomLog();
      log.message = 'Lia ChatBot Widget initialization failed...' + e;
      this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.SEVERE );
    }
  }

  initLiaSurveyBotWidget(chatWaitTime) {
    try {

     // wait time added since the widget initializing requires time and we have to wait before calling initializeP2PWidget()
      const promise = new Promise(resolve => {
         setTimeout(() => resolve('done!'), chatWaitTime);
      });

      promise.then( () => {

      let enableAnonymity = true;
      // check if anonymity for surveybot enabled by module config
      enableAnonymity = this.configLoader.getModuleConfig('SURVEYBOT_ANONYMITY_ENABLED', TC.MODULE_NAME.SURF_B2B).toString().toUpperCase() === 'TRUE';

      // get the name and email from logged in user
      let firstName = '';
      let lastName = '';
      let email = '';

      if ( !enableAnonymity && this.user && this.user.userSummary ) {
        firstName = this.user.userSummary.firstName ? this.user.userSummary.firstName : '';
        lastName = this.user.userSummary.lastName ? this.user.userSummary.lastName : '';
        email = this.user.userSummary.email ? this.user.userSummary.email : '';
      }

      // get other user details from logged in user
      let company = '';
      let brand = '';
      let division = '';
      let channel = '';
      let country = '';
      let clientGroup = '';
      let language = '';

      if ( this.user && this.user.userDetail ) {
        company = this.user.userDetail.defaultCom && this.user.userDetail.defaultCom.code ? this.user.userDetail.defaultCom.code : '';
        if( this.user.userDetail.defaultDivison)
        {
          division = this.user.userDetail.defaultDivison.code ? this.user.userDetail.defaultDivison.code : '';
          country = this.user.userDetail.defaultDivison.detail && this.user.userDetail.defaultDivison.detail.countryCode ? this.user.userDetail.defaultDivison.detail.countryCode : '';
          language = this.user.userDetail.defaultDivison.detail && this.user.userDetail.defaultDivison.detail.language ? this.user.userDetail.defaultDivison.detail.language : '';
        }
        brand = this.user.userDetail.defaultBrand && this.user.userDetail.defaultBrand.code ? this.user.userDetail.defaultBrand.code : '';
        channel = this.user.userDetail.defaultChannel && this.user.userDetail.defaultChannel.code ? this.user.userDetail.defaultChannel.code : '';

      }
      if ( this.user) {
        clientGroup = this.user.clientGroup ? this.user.clientGroup : '';
      }

      let currentTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');

      // calls initialize method with user info
        initializeP2PWidgetManually({
        userDetails: {
          name: firstName + ' ' + lastName,
          email:email,
          company:company,
          division:division,
          brand: brand,
          channel:channel,
          country:country,
          language: language,
          clientGroup:clientGroup,
          local_time: currentTime
        },
        mode: "SURVEY"
      });

      });
    } catch (e) {
      let log = new SurfCustomLog();
      log.message = 'Lia SurveyBot Widget initialization failed...' + e;
      this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.SEVERE );
    }
  }
  setFlightGrabPnrResult(event:any){
    this.flightGrabPnrResult = event;
  }
  setManualItemConfigPnr(event:boolean){
    this.showManualItemConfigPnr = event;
  }
  setGrabPNRCriteria(event: PnrComponentOutput){
    this.pnrComOutput = event;
  }

  initLiaMultiSkillBotWidget(chatWaitTime) {
    try {

      // wait time added since the widget initializing requires time and we have to wait before calling initializeP2PWidgetManually()
      const promise = new Promise(resolve => {
        setTimeout(() => resolve('done!'), chatWaitTime);
      });

      promise.then( () => {

        let enableAnonymity = true;
        // check if anonymity for surveybot enabled by module config
        enableAnonymity = this.configLoader.getModuleConfig('SURVEYBOT_ANONYMITY_ENABLED', TC.MODULE_NAME.SURF_B2B).toString().toUpperCase() === 'TRUE';

        // get the name and email from logged in user
        let firstName = '';
        let lastName = '';
        let email = '';
        let themeCode = '';
        let skill = [];
        if ( !enableAnonymity && this.user && this.user.userSummary ) {
          firstName = this.user.userSummary.firstName ? this.user.userSummary.firstName : '';
          lastName = this.user.userSummary.lastName ? this.user.userSummary.lastName : '';
          email = this.user.userSummary.email ? this.user.userSummary.email : '';
        }

        // resolve theme code based on brand
        // example config value = FC:flight_centre;GOGO:gogo_vacations;TA:travevl_associates;LT:liberty_travel;IH:infinity;TTJ:travel_junction
        if (this.chatBotThemeCodeMap) {
          const themeCodes = this.chatBotThemeCodeMap.split(';').filter(
            code =>
              code.split(':')[0] === this.cookieService.get('X-rst-brand')
          );
          if (themeCodes.length > 0) {
            themeCode = themeCodes[0].split(':')[1];
          } else {
            // if there's no brand set it to first
            themeCode = this.chatBotThemeCodeMap.split(';')[0].split(':')[1];
          }
        }

        // resolve skills based on page
        // exampled config value = DASHBOARD:SURVEY,TIPS;OTHER:TIPS
        const thisPage = 'DASHBOARD';  // TODO for each page
        if (this.chatBotSkillsMap) {
          const skills = this.chatBotSkillsMap.split(';').filter(
            code =>
              code.split(':')[0] === thisPage
          );
          if (skills.length > 0) {
            skill = skills[0].split(':')[1].split(',');
          } else {
            const otherSkills = this.chatBotSkillsMap.split(';').filter(
              code =>
                code.split(':')[0] === 'OTHER'
            );
            if (otherSkills.length > 0) {
              skill = otherSkills[0].split(':')[1].split(',');
            }
          }
        }

        // get other user details from logged in user
        let company = '';
        let brand = '';
        let division = '';
        let channel = '';
        let country = '';
        let clientGroup = '';
        let cliOwnershipType = '';
        let language = '';
        let userGroups = '';

        if ( this.user && this.user.userDetail ) {
          company = this.user.userDetail.defaultCom && this.user.userDetail.defaultCom.code ? this.user.userDetail.defaultCom.code : '';
          if( this.user.userDetail.defaultDivison)
          {
            division = this.user.userDetail.defaultDivison.code ? this.user.userDetail.defaultDivison.code : '';
            country = this.user.userDetail.defaultDivison.detail && this.user.userDetail.defaultDivison.detail.countryCode ? this.user.userDetail.defaultDivison.detail.countryCode : '';
            language = this.user.userDetail.defaultDivison.detail && this.user.userDetail.defaultDivison.detail.language ? this.user.userDetail.defaultDivison.detail.language : '';
          }
          brand = this.user.userDetail.defaultBrand && this.user.userDetail.defaultBrand.code ? this.user.userDetail.defaultBrand.code : '';
          channel = this.user.userDetail.defaultChannel && this.user.userDetail.defaultChannel.code ? this.user.userDetail.defaultChannel.code : '';

          const userGroupsArr = this.user.userDetail['groups'];
          if (userGroupsArr && userGroupsArr.length > 0) {
            const groupCodes = [];
            userGroupsArr.map(({code}) => {
              if (code) {
                groupCodes.push(code);
              }
            });
            userGroups = groupCodes.join(',');
          }
        }
        if ( this.user) {
          clientGroup = this.user.clientGroup ? this.user.clientGroup : '';
        }

        const client = this.dataStore.get(DataKey.tradeClient).getValue();
        if (client) {
          const ownershipType = client.attributes.find(
            attr => attr.type === 'TRADE_CLIENT_OWNERSHIP_TYPE'
          );

          cliOwnershipType = ownershipType.value ? ownershipType.value : '';
        }

        let currentTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');

        let eventHandlers: any = {};

        eventHandlers.chatStarted = () => {
          // code to run when chat started
          console.debug("Started Chat");
        };

        eventHandlers.dataRequestedByLia = (data) => {
          // triggers when Lia sends an event

          console.log("payload", data);
          const userDetail = this.dataStore.get(DataKey.userDetail).getValue();

          if (Chat && userDetail && userDetail.clientId) {
            switch (data.requestData.activity) {
              case "CLIENT_ID":
                // Client Id should be sent
                Chat.send({
                  token: data.token,
                  payload: { VALUE: userDetail.clientId}
                });
                break;
            }
          }
        };

        // calls initialize method with user info
        initializeP2PWidgetManually({
          userDetails: {
            name: firstName + ' ' + lastName,
            email:email,
            country:country,
            values : [{
              role:'',
              region: country,
              company:company,
              division:division,
              brand: brand,
              channel:channel,
              language: language,
              clientGroup:clientGroup,
              local_time: currentTime,
              authToken: this.cookieService.get(this.tokenCookieName),
              cliOwnershipType: cliOwnershipType,
              userGroups: userGroups,
              sessionInfo: `conversationId~${window.sessionStorage.getItem('conversationId')}`
            }]
          },
          eventHandlers: eventHandlers,
          themeCode: themeCode,
          skill: skill // ex skill: ["INFO","TIPS"]
        });

      });
    } catch (e) {
      let log = new SurfCustomLog();
      log.message = 'Lia SurveyBot Widget initialization failed...' + e;
      this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.SEVERE );
    }
  }

  selectSearchFlow(event) {
    if (event == 'DP' && this.activeSearchFlow !== event) {
      sessionStorage.setItem('paxSet', JSON.stringify(new PaxAllocationItem(2, [], [])));
    }
    this.activeSearchFlow = event;
    switch (this.activeSearchFlow) {
      case 'MANFLT':
        this.activeMBIProductCode = this.PRODUCT_CODES.FLIGHT;
        break;
      case 'MAN_CRU':
        this.activeMBIProductCode = this.PRODUCT_CODES.CRUISE;
        break;
    }
    this.showSearchBar = false;
  }

  ngAfterViewInit(): void {
    try {
      let logWrapper: LogWrapper;
      let surfPageLoadPerformanceLog: SurfPageLoadPerformanceLog = this.surfPerformanceLogService.getPageLoadLog();
      logWrapper = this.logStore.getWrapper(
        LOG_WRAPPERS.PAGE_LOAD_DEFAULT,
        LOG_WRAPPERS_CONTEXT.PageLoad
      );
      surfPageLoadPerformanceLog.type = logWrapper.type;
      logWrapper.log = surfPageLoadPerformanceLog;
      this.logStore.add(logWrapper);
    } catch (e) {
    }
    this.userAgent = this.deviceService.getDeviceInfo().os;
  }

  performHealthCheck() {
    this.healthCheckServiice.performHelthCheck(this.healthCheckUrl).subscribe(
      (res) => {
        this.isHealchCheckPassed = true;
      },
      (err) => {
        this.isHealchCheckPassed = false;
      });
  }

  ngAfterContentInit(): void {
    this.loggerService.initializeLoggersForModule(TC.MODULE_NAME.SURF_B2B);
    if (this.surfPerformanceLogService.getLog() && this.surfPerformanceLogService.getLog().length > 0) {
      this.surfPerformanceLogService.getLog().forEach(log => {
        let logWrapper: LogWrapper;
        logWrapper = this.logStore.getWrapper(
          FRONT_END_LOG_WRAPPERS.API_PERFORMANCE_DEFAULT,
          FRONT_END_LOG_WRAPPERS_CONTEXT.APIPerformance
        );
        logWrapper.log = log;
        this.logStore.add(logWrapper);
      });
      this.surfPerformanceLogService.clearLogs();
    }
    this.surfLoggerService.initConsoleErrorLogs();
    try {
      if (this.user && this.user.userSummary && (this.user.userSummary.firstName || this.user.userSummary.lastName)) {
        this.greetingTest = this.user.userSummary.firstName + ' ' + this.user.userSummary.lastName;
      }
    } catch (e) {
      let log = new SurfCustomLog();
      log.message = 'User summary not loaded....' + e;
      this.surfLoggerService.addSurfLog(LOG_WRAPPERS.SURF_COMMON,LOG_WRAPPERS_CONTEXT.SurfCommon, log, LogLevel.SEVERE );
    }
  }

  // setCurrencies(): void {
  //   let defaultCurrency;
  //   if (this.user && this.user.userDetail && this.user.userDetail.defaultCurrency && this.user.userDetail.defaultCurrency.code) {
  //     defaultCurrency = this.user.userDetail.defaultCurrency;
  //     this.currencies = [];
  //     let selectedCur = {
  //       selected: true,
  //       value: defaultCurrency.name,
  //       code: defaultCurrency.code
  //     };
  //
  //     this.currencies.push(selectedCur);
  //
  //   } else {
  //     defaultCurrency = {code: this.defCurrency.code, value: this.defCurrency.name, selected: true};
  //   }
  //   this.currencies.forEach((curr) => {
  //     curr.selected = (curr.code === defaultCurrency.code);
  //     curr.value = curr.code;
  //   });
  //   this.selectCurrency(defaultCurrency);
  //   this.triggerDropDownChange = !this.triggerDropDownChange;
  // }

  // setBrands(): void {
  //   let defaultBrand;
  //   if (this.user && this.user.userDetail) {
  //     defaultBrand = this.user.userDetail.defaultBrand;
  //     this.user.userDetail.brands.forEach((brand) => {
  //       this.brands.push({
  //         code: brand.code,
  //         value: brand.name,
  //         selected: brand.code === defaultBrand.code
  //       });
  //     });
  //   } else {
  //     defaultBrand = {code: this.defBrand.code, value: this.defBrand.name, selected: true};
  //     this.brands.push(defaultBrand);
  //   }
  //   this.selectBrand(defaultBrand);
  // }

  selectCurrency(event): void {
    this.baseCriteria.cur = event.code;
    this.baseHTLCriteria.cur = event.code;
    this.baseFLTCriteria.cur = event.code;
    this.baseTRSCriteria.cur = event.code;
    this.baseCARCriteria.cur = event.code;
    this.baseCRUCriteria.cur = event.code;
    this.baseGENCriteria.cur = event.code;
    this.updateCompCriteria();
    this.constructKeyParams();
  }

  selectBrand(event): void {
    this.baseCriteria.brand = event.code;
    this.baseHTLCriteria.brand = event.code;
    this.baseFLTCriteria.brand = event.code;
    this.baseTRSCriteria.brand = event.code;
    this.baseCARCriteria.brand = event.code;
    this.baseCRUCriteria.brand = event.code;
    this.baseGENCriteria.brand = event.code;
    this.updateCompCriteria();
    this.constructKeyParams();
  }

  updateCriteriaKeyControls() {

    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'BOOKING_TYPE':
            if (this.baseCriteria) {
              this.baseCriteria.bkgType = mc.name;
            }
            if (this.baseHTLCriteria) {
              this.baseHTLCriteria.bkgType = mc.name;
            }
            if (this.baseFLTCriteria) {
              this.baseFLTCriteria.bkgType = mc.name;
            }
            if (this.baseTRSCriteria) {
              this.baseTRSCriteria.bkgType = mc.name;
            }
            if (this.baseCARCriteria) {
              this.baseCARCriteria.bkgType = mc.name;
            }
            if (this.baseCRUCriteria) {
              this.baseCRUCriteria.bkgType = mc.name;
            }
            if (this.baseGENCriteria) {
              this.baseGENCriteria.bkgType = mc.name;
            }
            break;
          case 'CLIENT_GROUP':
            if (this.baseCriteria) {
              this.baseCriteria.cliGrp = mc.name;
            }
            if (this.baseHTLCriteria) {
              this.baseHTLCriteria.cliGrp = mc.name;
            }
            if (this.baseFLTCriteria) {
              this.baseFLTCriteria.cliGrp = mc.name;
            }
            if (this.baseTRSCriteria) {
              this.baseTRSCriteria.cliGrp = mc.name;
            }
            if (this.baseCARCriteria) {
              this.baseCARCriteria.cliGrp = mc.name;
            }
            if (this.baseCRUCriteria) {
              this.baseCRUCriteria.cliGrp = mc.name;
            }
            if (this.baseGENCriteria) {
              this.baseGENCriteria.cliGrp = mc.name;
            }
            break;
          case 'CLIENT_TYPE':
            if (this.baseCriteria) {
              this.baseCriteria.cliType = mc.name;
            }
            if (this.baseHTLCriteria) {
              this.baseHTLCriteria.cliType = mc.name;
            }
            if (this.baseFLTCriteria) {
              this.baseFLTCriteria.cliType = mc.name;
            }
            if (this.baseTRSCriteria) {
              this.baseTRSCriteria.cliType = mc.name;
            }
            if (this.baseCARCriteria) {
              this.baseCARCriteria.cliType = mc.name;
            }
            if (this.baseCRUCriteria) {
              this.baseCRUCriteria.cliType = mc.name;
            }
            if (this.baseGENCriteria) {
              this.baseGENCriteria.cliType = mc.name;
            }
            break;
          case 'CLIENT_ID':
            if (this.baseCriteria) {
              this.baseCriteria.cliId = mc.name;
            }
            if (this.baseHTLCriteria) {
              this.baseHTLCriteria.cliId = mc.name;
            }
            if (this.baseFLTCriteria) {
              this.baseFLTCriteria.cliId = mc.name;
            }
            if (this.baseTRSCriteria) {
              this.baseTRSCriteria.cliId = mc.name;
            }
            if (this.baseCARCriteria) {
              this.baseCARCriteria.cliId = mc.name;
            }
            if (this.baseCRUCriteria) {
              this.baseCRUCriteria.cliId = mc.name;
            }
            if (this.baseGENCriteria) {
              this.baseGENCriteria.cliId = mc.name;
            }
            break;
          case 'DEFAULT_RESULTS_COUNT':
            this.defaultResultsCount = mc.name;
            break;
        }
      });
    }

    if (this.user) {
      if (this.baseCriteria) {
        this.baseCriteria.userId = this.user.id;
      }
      if (this.baseHTLCriteria) {
        this.baseHTLCriteria.userId = this.user.id;
      }
      if (this.baseFLTCriteria) {
        this.baseFLTCriteria.userId = this.user.id;
      }
      if (this.baseTRSCriteria) {
        this.baseTRSCriteria.userId = this.user.id;
      }
      if (this.baseCARCriteria) {
        this.baseCARCriteria.userId = this.user.id;
      }
      if (this.baseCRUCriteria) {
        this.baseCRUCriteria.userId = this.user.id;
      }
      if (this.baseGENCriteria) {
        this.baseGENCriteria.userId = this.user.id;
      }

      if (this.user.userSummary) {
        if (this.baseCriteria) {
          this.baseCriteria.userName = this.user.userSummary.username;
        }
        if (this.baseHTLCriteria) {
          this.baseHTLCriteria.userName = this.user.userSummary.username;
        }
        if (this.baseFLTCriteria) {
          this.baseFLTCriteria.userName = this.user.userSummary.username;
        }
        if (this.baseTRSCriteria) {
          this.baseTRSCriteria.userName = this.user.userSummary.username;
        }
        if (this.baseCARCriteria) {
          this.baseCARCriteria.userName = this.user.userSummary.username;
        }
        if (this.baseCRUCriteria) {
          this.baseCRUCriteria.userName = this.user.userSummary.username;
        }
        if (this.baseGENCriteria) {
          this.baseGENCriteria.userName = this.user.userSummary.username;
        }
      }
    }

    // setting key Controls when not Emulating
    if (!this.emulationInProgress) {
      const tradeClient = this.dataStore.get(DataKey.tradeClient).getValue();
      this.surfCriteriaUtil.updateCriteriaKeyControlsFromTradeClient(
        tradeClient,
        [
          this.baseCriteria,
          this.baseCARCriteria,
          this.baseFLTCriteria,
          this.baseHTLCriteria,
          this.baseCRUCriteria,
          this.baseGENCriteria,
          this.baseTRSCriteria
        ]
      );
    }

    // setting key Controls for Emulation
    if (this.emulationInProgress) {
      this.updateCriteriaKeyControlsForEmulation(
        this.emulatingTradeClient,
        [
          this.baseCriteria,
          this.baseCARCriteria,
          this.baseFLTCriteria,
          this.baseHTLCriteria,
          this.baseCRUCriteria,
          this.baseGENCriteria,
          this.baseTRSCriteria
        ],
        this.emulatingTradeClientId,
        this.emulatingTradeClient.summary.address.country
      );
    }

    // construct key param  URL section
    this.constructKeyParams();
  }

  constructKeyParams() {
    const keyParams = 'cmp=' + this.baseCriteria.cmp + '&div=' + this.baseCriteria.div + '&brand=' + this.baseCriteria.brand
      + '&channel=' + this.baseCriteria.channel + '&cur=' + this.baseCriteria.cur + '&bkgType=' + this.baseCriteria.bkgType
      + '&cliGrp=' + this.baseCriteria.cliGrp + '&cliType=' + this.baseCriteria.cliType
      + '&srcCountry=' + this.baseCriteria.srcCountry + '&cliId=' + this.baseCriteria.cliId
      + '&userId=' + this.baseCriteria.userId + '&username=' + this.baseCriteria.userName
      + '&resultsCount=' + this.defaultResultsCount;

    this.dataShare.changeKeyParamString(keyParams);
  }

  getKeyControls(): KeyControlParameter {
    const KeyControls = new KeyControlParameter();
    if (this.dataStore.get(DataKey.emulating).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
      const emulatingTC = this.dataStore.get(DataKey.emulatingTCProfile).getValue()
      KeyControls.cmp = emulatingTC.controls.cmp;
      KeyControls.div = emulatingTC.controls.div;
      KeyControls.brand = emulatingTC.controls.brand;
      KeyControls.channel = emulatingTC.controls.channel;
      KeyControls.cur = emulatingTC.controls.cur;
      KeyControls.srcCountry = emulatingTC.summary.address.country;
      KeyControls.cliGrp = emulatingTC.controls.cliGrp;
      KeyControls.cliId = emulatingTC.summary.id;
    }
    else if (this.dataStore.get(DataKey.tradeClient).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
      const tradeClient = this.dataStore.get(DataKey.tradeClient).getValue();
      KeyControls.cmp = tradeClient.controls.cmp;
      KeyControls.div = tradeClient.controls.div;
      KeyControls.brand = tradeClient.controls.brand;
      KeyControls.channel = tradeClient.controls.channel;
      KeyControls.cur = tradeClient.controls.cur;
      KeyControls.cliGrp = tradeClient.controls.cliGrp;
      KeyControls.cliId = tradeClient.summary.id;
      KeyControls.srcCountry = tradeClient.summary.address.country;
    } else if (!this.dataStore.get(DataKey.keyControls).getValue()) {
      KeyControls.cmp = this.user.userDetail.defaultCom.code;
      KeyControls.div = this.user.userDetail.defaultDivison.code;
      KeyControls.brand = this.user.userDetail.defaultBrand.code;
      KeyControls.channel = this.user.userDetail.defaultChannel.code;
      KeyControls.cur = this.user.userDetail.defaultCurrency.code;
      KeyControls.cliGrp = this.user.clientGroup;
      KeyControls.cliId = this.user.clientId;
    }

    // from booking or cart
    if (this.dataStore.get(DataKey.keyControls).getValue()) {
      const keyControlsObj = this.dataStore.get(DataKey.keyControls).getValue();
      KeyControls.brand = keyControlsObj.brand;
      KeyControls.cmp = keyControlsObj.cmp;
      KeyControls.div = keyControlsObj.div;
      KeyControls.channel = keyControlsObj.channel;
      KeyControls.cur = keyControlsObj.cur;
      KeyControls.cliGrp = keyControlsObj.cliGrp;
      KeyControls.cliId = keyControlsObj.cliId;
      keyControlsObj.srcCountry = keyControlsObj.srcCountry;
    }

    KeyControls.userId = this.user.id ? this.user.id : null;
    KeyControls.username = this.user.userSummary.username ? this.user.userSummary.username : null;

    return KeyControls;
  }
  updateCompCriteria() {
    this.dataShare.updateBaseCriteria(this.baseCriteria);
    this.dataShare.updateComponentCriteria(this.baseHTLCriteria, 'HTL');
    this.dataShare.updateComponentCriteria(this.baseFLTCriteria, 'FLT');
    this.dataShare.updateComponentCriteria(this.baseTRSCriteria, 'TRS');
    this.dataShare.updateComponentCriteria(this.baseCARCriteria, 'CAR');
    this.dataShare.updateComponentCriteria(this.baseCRUCriteria, 'CRU');
    this.dataShare.updateComponentCriteria(this.baseGENCriteria, 'GEN');
  }


  /**
   * handling surf-client-search-modal response
   * @param: event
   */
  handleSurfClientSearchModal(event) {
    if (event === 'confirm') {
      // this.isSurfClientSearchModalActive = false;
    } else if (event === 'cancel') {
      this.openAndCloseSurfClientSearchModal('close');
    }
  }

  /**
   * handle Open And Close Surf-Client-Search-Modal
   * @param: $event
   */
  openAndCloseSurfClientSearchModal($event: string) {
    if ($event === 'open') {
      this.isSurfClientSearchModalActive = true;
    } else if ($event === 'close') {
      this.isSurfClientSearchModalActive = false;
    }
  }

  /**
   * handle Open And Close Emulation Warning Message Box
   * @param: $event
   */
  openAndCloseEmulationWarning($event: string) {
    if ($event === 'open') {
      this.activeEmulationWarning = true;
    } else if ($event === 'close') {
      this.activeEmulationWarning = false;
    }
  }

  handleEmulationWarning($event) {
    if ($event === 'confirm') {
      this.startStopEmulation('start');
    } else if ($event === 'cancel') {
      this.startStopEmulation('stop');
    }
    this.openAndCloseEmulationWarning('close');
  }

  /**
   * selecting The Emulating User
   * @param: $event
   */
  selectTheEmulatingClient($event) {
    this.openAndCloseSurfClientSearchModal('close');
    this.openAndCloseEmulationWarning('open');

    // assign the new trade client
    this.emulatingTradeClientId = $event.id;
    this.emulatingTradeClient = $event;
  }

  /**
   * loading the trade client using Client Id
   * @param: cliId
   * @param: emulating
   */
  getTradeClient(cliId: number, emulating?: boolean) {
    if (cliId) {
      const clientGetCriteria = new ClientGETCriteria();
      clientGetCriteria.cliId = cliId;

      // resetting unwanted default parameters
      clientGetCriteria.userId = null;
      clientGetCriteria.quote = null;
      clientGetCriteria.tax = null;
      clientGetCriteria.isUpdated = null;
      clientGetCriteria.search = null;
      clientGetCriteria.offset = null;

      this.dataStore.set(DataKey.client, null);
      // @ts-ignore
      this.clientSearchSubscription = this.clientSearchServiceHandler.getClient(clientGetCriteria).subscribe(
        result => {
          if (this.commonHelper.dataValidity(result)) {
            if (emulating) {
              this.emulatingTradeClient = result;

              // currently the country is available in the response as the country code
              this.setFullCountryNameByCountryCode(this.emulatingTradeClient.summary.address.country);

              // this.mapEmuClient();
              sessionStorage.setItem(this.emulatingTCProfile, this.emulatingTradeClient.id);
              sessionStorage.setItem(this.emulating, String(true));
              this.dataStore.set(DataKey.emulatingTCProfile, this.emulatingTradeClient);
              this.dataStore.set(DataKey.emulating, true);
              window.location.href = window.location.origin;
            }
          } else if ((result instanceof TcHttpError) || (result instanceof TcApiError) || (result && result.length === 0)) {
            if (emulating) {
              this.emulatingTradeClient = null;
            } else {
              this.originalUserTradeClient = null;
            }
          }
          this.clientSearchSubscription.unsubscribe();
        }
      );
    }

  }

  // private mapEmuClient() {
  //   // setting key Controls for Emulation
  //   this.updateCriteriaKeyControlsForEmulation(
  //     this.emulatingTradeClient,
  //     [
  //       this.baseCriteria,
  //       this.baseCARCriteria,
  //       this.baseFLTCriteria,
  //       this.baseHTLCriteria,
  //       this.baseGENCriteria,
  //       this.baseTRSCriteria
  //     ],
  //     this.emulatingTradeClientId,
  //     this.emulatingTradeClient.summary.address.country
  //   );
  //   this.emulationInProgress = true;
  // }

  /**
   * start or stop emulation
   * @param: $event
   */
  startStopEmulation($event: string) {
    this.emulationInProgress = sessionStorage.getItem(this.emulating) && sessionStorage.getItem(this.emulating) === 'true';
    if ($event === 'stop') {
      this.emulationInProgress = false;
      this.emulatingTradeClient = null;
      this.emulatingTradeClientId = null;
      sessionStorage.removeItem(this.emulating);
      sessionStorage.removeItem(this.emulatingTCProfile);
      this.dataStore.set(DataKey.emulating, false);
      this.dataStore.set(DataKey.emulatingTCProfile, null);
      window.location.href = window.location.origin;
    } else if (
      $event === 'start' && !sessionStorage.getItem(this.emulating) &&
      this.dataStore.get(DataKey.tradeClient) &&
      this.dataStore.get(DataKey.tradeClient).getValue()
    ) {
      this.getTradeClient(this.emulatingTradeClientId, true);
    } else {
      this.emulatingTradeClient = this.dataStore.get(DataKey.emulatingTCProfile).getValue();

      // currently the country is available in the response as the country code
      // this.setFullCountryNameByCountryCode(this.emulatingTradeClient.summary.address.country);

      if (
        this.emulatingTradeClient &&
        !sessionStorage.getItem(this.emulating) &&
        this.dataStore.get(DataKey.tradeClient) &&
        this.dataStore.get(DataKey.tradeClient).getValue()
      ) {
        // this.mapEmuClient();
      }
    }
  }

  /**
   * update Key Controls For Emulation
   * @param: emulatingTradeClient
   * @param: baseCriteria1
   */
  updateCriteriaKeyControlsForEmulation(emulatingTradeClient: any, baseCriteriaArray: BaseCriteria[], cliId: number, country: any) {
    baseCriteriaArray.forEach((baseCriteria) => {
      if (baseCriteria) {
        baseCriteria.cmp = emulatingTradeClient.controls.cmp;
        baseCriteria.div = emulatingTradeClient.controls.div;
        baseCriteria.brand = emulatingTradeClient.controls.brand;
        baseCriteria.channel = emulatingTradeClient.controls.channel;
        baseCriteria.cliGrp = emulatingTradeClient.controls.cliGrp;
        baseCriteria.cur = emulatingTradeClient.controls.cur;
        baseCriteria.cliId = cliId;
        baseCriteria.srcCountry = emulatingTradeClient.summary.address.country;

        // baseCriteria.srcCountry = country;
        // baseCriteria.locale = emulatingTradeClient.controls.locale;
      }
    });
    // this.constructKeyParams();
  }

  private setFullCountryNameByCountryCode(country: any) {
    this.subscriptions.push(this.countryPipe.transform(country).subscribe(res => {
      if (res && country) {
        this.emulatingTradeClient.summary.address.country = res;
      }
    }));
  }

  goToDetailsView() : void {
    this.enableDashboardDetailView = true;
  }

  backToLandingView() : void {
    this.enableDashboardDetailView = false;
    this.setBookingSearchInputFocus();
  }


// ------------NGRX Caching -------------------------------------
  private loadStaticData() {
    (this.cachingItemMap.get(StoreConstants.CACHE_PAX_SUB_TYPE_AGES) !== null && this.cachingItemMap.get(StoreConstants.CACHE_PAX_SUB_TYPE_AGES) > 0) ? this.getPaxSubTypeAges(this.cachingItemMap.get(StoreConstants.CACHE_PAX_SUB_TYPE_AGES)) : null;
    // (this.cachingItemMap.get(StoreConstants.CACHE_H2H_SYSTEMS) !== null && this.cachingItemMap.get(StoreConstants.CACHE_H2H_SYSTEMS) > 0) ? this.getH2hSystems(this.cachingItemMap.get(StoreConstants.CACHE_H2H_SYSTEMS)) : null;

  }
  private getPaxSubTypeAges(timeout: number) {
    this.masterDataStoreService.getPaxSubTypeAges(timeout).subscribe(
      res => {
        if (res && res[0]) {
          this.paxSubTypeAges = res[0].paxTypes;
        }
      },
      error => {
        console.error('Error in getting paxSubTypeAges:', error);
      });
  }

  private getH2hSystems(timeout: number) {
    this.masterDataStoreService.getH2HSystems(timeout).subscribe(
      res => {
        if (res) {
          this.h2hSystems = res;
        }
      },
      error => {
        console.error('Error in getting h2hSystems:', error);
      });
  }

  private loadCachingConfigsForModules() {
    let bookingCacheModules = this.configLoader.getModuleConfig('B2B_DASHBOARD_CACHE_ENABLE_STATIC_MODULES', TC.MODULE_NAME.SURF_B2B);
    if (this.experimentalFeatureService.has('B2B_DASHBOARD_CACHE_ENABLE_STATIC_MODULES')
      && this.experimentalFeatureService.has('B2B_DASHBOARD_CACHE_ENABLE_STATIC_MODULES')) {
      bookingCacheModules = this.experimentalFeatureService.get('B2B_DASHBOARD_CACHE_ENABLE_STATIC_MODULES');
    }
    if (bookingCacheModules) {
      const resultStaticDataArray = bookingCacheModules.toString().split(StoreConstants.MODULE_CONFIGS_SEPARATOR);
      if (resultStaticDataArray.length > 0) {
        resultStaticDataArray.forEach(data => {
          if (data.indexOf(StoreConstants.MODULE_CONFIG_TIMEOUT_SEPARATOR) !== -1) {
            const staticDataArray = data.split(StoreConstants.MODULE_CONFIG_TIMEOUT_SEPARATOR);
            if (staticDataArray.length > 0) {
              this.cachingItemMap.set(staticDataArray[0], Number(staticDataArray[1]));
            }
          }
        });
      }
    }
  }

  checkModuleCaching(subModule: string): boolean {
    if (this.cachingItemMap.get(subModule) !== null && this.cachingItemMap.get(subModule) > 0) {
      switch (subModule) {
        case StoreConstants.CACHE_PAX_SUB_TYPE_AGES:
          return this.paxSubTypeAges && this.paxSubTypeAges.length > 0;
        case StoreConstants.CACHE_H2H_SYSTEMS:
          return this.h2hSystems && this.h2hSystems.length > 0;
      }
    }
    return true;
  }

  onToggleRetailStoreViewStatus (status : boolean) {
    this.setBookingSearchInputFocus();
  }

  setBookingSearchInputFocus () {
    this.isBookingSearchInputFocussed = true;
  }
}
