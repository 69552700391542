import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, Renderer2,
  SimpleChanges
} from '@angular/core';
import {HotelCriteriaComponent} from '../hotel-criteria/hotel-criteria.component';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {
  ConfigService,
  DataShareService, SurfAuthorizationService,
  SurfCalendarStartDateService,
  SurfCriteriaUtil, SurfGeoSpatialSearchGoogleService,
  SurfTravellerEarliestDepartureDateService
} from '@surf/surf-components-core';
import {ConfigLoader, DataStore} from '@tc-core/util/framework';
import {TravellerInterest} from "@surf/surf-traveller-interests";
@Component({
  selector: 'surf-hotel-criteria-dp',
  templateUrl: './hotel-criteria-dp.component.html'
})
export class HotelCriteriaDpComponent extends HotelCriteriaComponent implements OnInit,OnChanges {

  @Input() selectComp = false;              /*needed for dp criteria*/
  @Output() itemRemoved: EventEmitter<any> = new EventEmitter<any>();

  isSelected = false;
  datePickerLabel = 'CHECK-IN & CHECK-OUT DATES*';

  enableTravellerInterestsModal = false;
  holidayTypes = [];
  addChildToBodyIdMap = new Map<string, boolean>();
  @Input() showBtnWrapper = true;
  @Input() showHeader = false;

  constructor(protected dataServiceHandler: DataServiceHandler,
              protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected surfGeoSpatialSearchGoogleService: SurfGeoSpatialSearchGoogleService,
              private renderer: Renderer2,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              protected surfAuthorizationService: SurfAuthorizationService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService) {
    super(dataServiceHandler, commonService, dataStore, configLoader, configService, surfCriteriaUtil, surfGeoSpatialSearchGoogleService,
      travellerEarliestDepartureDateService, surfAuthorizationService, surfCalendarStartDateService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    /*if this component is added to the dp criteria panel initially, then it should be selected = true*/
    if (changes.selectComp) {
      this.isSelected = changes.selectComp.currentValue;
    }
  }

  public selectComponent() {
    this.isSelected = true;
  }

  public deselectComponent() {
    this.isSelected = false;
  }

  getCalendarStartDate() {
    if (this.dpEnableDateChanged) {
      this.surfCalendarStartDateService.updateMinDate(this.dpCalEnableDate, 'HTL');
      return this.dpCalEnableDate;
    }
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'HTL');
    return this.calendarMinDate;
  }

  removeItem() {
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(null, 'HTL');
    }
    this.itemRemoved.emit(this.index);
  }

  moveToDP(position: string) {
    if (!this.isRefine) {          /*TODO: Implemented for refine*/
      if (!this.isDPSearch) {                     /*Move to DP search panel if only; was in the component flow previously*/
        this.msg = 'DP_Switch~HTL~' + position;
        // this.commonService.changeMessage(msg);
        // sessionStorage.setItem('partialCriteria', JSON.stringify(this.hotelCriteria));
      }
    }
  }

  passengerSplitChange(event) {
    if (event && event.length === this.paxSplitArray.length) {
      this.isPaxSplitChanged.emit(false);
    } else {
      this.isPaxSplitChanged.emit(true);
    }
  }

  /**
   * enable passenger split dropdown
   */
  enablePaxSplitDropdown() {
    this.enablePaxSplit = true;
  }

  addParentToLocationGroup(locationGroup, city) {
    let parent = [];
    if(city){
      parent.push({code: city.code, name: city.name, type: city.type});  /*add city*/
    }
    if(city.parent && city.parent[0]){
      parent.push({code: city.parent[0].code, name: city.parent[0].name, type: city.parent[0].type});  /*add country*/
    }
    locationGroup.parent = parent;
    return locationGroup;
  }

  getCountry(city): string {
    let parentName = null;
    if (city.parent && city.parent.length > 0) {
      city.parent.forEach(p => {
        if (p.type == 20) {
          parentName = p.name;
        }
      });
    }
    return parentName;
  }

  rearrangeModals(id) {
    if (document.getElementById(id)) {
      document.body.appendChild(document.getElementById(id));
      this.addChildToBodyIdMap.set(id, true);
    }
  }

  /**
   * open surf-traveller-interests modal on add/update action
   */
  onAddOrUpdateTravellerInterests() {
    this.renderer.addClass(document.body, 'u-unscrollable');
    this.enableTravellerInterestsModal = true;
    this.rearrangeModals('traveller-interests-modal');
  }

  /**
   * get selected Traveller Interests emitted from the surf-traveller-interests
   * @param event - list of selected Traveller Interests
   */
  getSelectedTravellerInterests(event: TravellerInterest[]) {
    if (event) {
      this.selectedTravellerInterests = event;
    }
  }

  /**
   * receive modal close emit and close modal
   * @param event - boolean false
   */
  closeTravellerInterestModal(event) {
    this.renderer.removeClass(document.body, 'u-unscrollable');
    this.enableTravellerInterestsModal = event;
  }

  /**
   * handle and display geo location auto complete error list
   * @param errorList - error list
   */
  handleGeoLocationErrors(errorList: Array<string>) {
    this.geoLocationAutoCompleteErrors = errorList;
    if (this.geoLocationAutoCompleteErrors && this.geoLocationAutoCompleteErrors.length > 0 && this.GEO_HTL_AUTO_SWITCH_ENABLED) {
      this.switchGeoLocationSearch();
    }
  }

  /**
   * switch geo location search to normal search
   */
  switchGeoLocationSearch() {
    this.isGeoSearchSwitched = true;
    this.activeToaster = true;
    this.departureUrl = (this.STATIC_DATA_URL_BASE + '?dataTypes=' + this.getStaticDataTypes() +
      '&userInput=[UI]&aggrLevel=NA&locale=en&matchType=CODE~NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE);
    setTimeout(() => {
      this.switchNormalSearch();
    }, this.GEO_HTL_AUTO_SWITCH_RETRY_TIME * 1000);
  }

  /**
   * switch normal search to geo location search
   */
  switchNormalSearch() {
    this.isGeoSearchSwitched = false;
    this.activeToaster = false;
    this.geoLocationAutoCompleteErrors = [];
    this.departureUrl = (this.STATIC_DATA_URL_BASE + '?dataTypes=' + this.getStaticDataTypes() +
      '&userInput=[UI]&aggrLevel=NA&locale=en&matchType=CODE~NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE);
  }

  onclickActionItem(event) {
    if (event && event === 'cancel') {
      this.activeToaster = false;
    }
  }
}
