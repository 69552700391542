import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  CarCriteria,
  ConfigService,
  DataShareService,
  DestinationType,
  Detail,
  GenericCriteria,
  HotelCriteria,
  KeyCodes,
  LocationSources,
  OntologyResponse,
  PlaceDetails,
  SurfAuthorizationService,
  SurfCalendarStartDateService,
  SurfCriteriaUtil,
  SurfErrorMessages,
  SurfGeoSpatialSearchGoogleService,
  SurfScrollDirective,
  SurfTextCasePipe,
  SurfTravellerEarliestDepartureDateService
} from '@surf/surf-components-core';
import {PaxAllocationItem} from '@surf/surf-pax-selection';
import {DataServiceHandler} from '@tc-core/service/service-handlers/data-service-handler.service';
import {TC} from '@tc-core/util';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';
import {Subscription} from 'rxjs';
import moment from 'moment';
import {TravellerInterest} from '@surf/surf-traveller-interests';
import {PermissionKeys} from '../security/permission-constants';
import surfOperations from '../security/surf-operations.json';

@Component({
  selector: 'surf-hotel-criteria',
  templateUrl: 'hotel-criteria.component.html',
})
export class HotelCriteriaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() isDPSearch = false;
  @Input() index: number;
  @Input() isRefine = false;
  @Input() isDpRefine = false;
  @Input() selectedPax: any;
  @Input() originalRefineCriteria: any;
  @Input() windowScrollEnable = true;
  @Input() setRoomWise = false;
  @Input() preItemDate: any;
  @Input() postItemDate: any;
  @Input() preItemLocation: any;
  @Input() dpHotelDestinations: any;
  // new impl
  @Input() dpEnableDate: any;
  @Input() dpInitialDateStr: any;
  @Input() dpInitialDestStr: any;
  dpCalEnableDate: Date;
  @Input() isServiceAddComponent = false;
  @Input() cartPaxSelection: any;
  @Input() unfreeze = true;
  @Input() isAmendmentFlow = false;
  @Input() isCartFromBooking = false;
  @Input() isItemCloseable = true;
  @Input() oldCriteriaItem;
  @Input() bookingId: string;
  @Input() productKey: string;
  @Input() amendmentReasonCode = null;
  @Input() amendmentCauseCode = null;
  @Input() paxSplitArray = [];
  @Input() enablePaxSplit = false;
  @Input() triggerExternalSearch = '';
  @Input() isForEpToDpConversion = false;
  @Input() selectedTravellerInterestsKeys: string[];
  @Input() originalRefineTravellerInterestsKeys: string[];
  @Input() paxTypeAges = [];
  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() getCurrentHotelCriteria = new EventEmitter<HotelCriteria>();
  @Output() onKeyDownLast = new EventEmitter<boolean>();
  @Output() notifyDPDependencyChange = new EventEmitter<any>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter();
  @Output() isUnfreeze = new EventEmitter<boolean>();
  @Output() isPaxSplitChanged = new EventEmitter<boolean>();

  FIELD_SEPERATOR = '~';
  AGE_SEPERATOR = '__';
  LESS_THAN_ONE = '< 1';
  /*input values for pax selection component. Those values should be set internally once service is finalized*/
  MAX_CABIN_PAX_COUNT = 5;
  MAX_CHILD = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_TEEN = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_INFANTS = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_ROOMS = 9;
  MIN_ADULT = 1;
  MAX_ADULT = 10;
  MAX_INFANT_AGE = 23;
  MAX_CHILD_AGE = 12;
  MAX_TEEN_AGE = 17;
  TEEN_PAX_ACTIVE = true;
  MAX_PAX_COUNT = 40;
  DEFAULT_ADULT_AGE = 30;
  DEFAULT_OFFSET = 10;
  INITIAL_DURATION = 3;
  MIN_SEARCH_QUERY_LENGTH = 3;
  CALENDAR_RANGE = 2;
  DISABLE_DP_SWITCH = false;
  STATIC_DATA_URL_BASE = '/data/data-service/v2/static-data';
  STATIC_DATA_FETCH_SIZE = 20;
  HTL_SEARCH_RESULT_COUNT: string;
  HTL_SEARCH_EXPAND_RESULT: string;
  HTL_SEARCH_ENABLE_SOURCE_SUMMARY_CACHE: string;
  HTL_STATIC_DATA_TYPES: string[] = ['CITY', 'HOTEL'];
  REC_ENABLE_FOR_HOTEL_SEARCH = false;

  dpEnableDateChanged = false;
  url = '';
  keyParams: string;
  resetCalendar = false;
  moduleConfigs: any;
  surfTextCasePipe: SurfTextCasePipe;
  destSearchInitiated = false;
  guestComponentFocus = false;
  datePickerFocus = false;
  destinations = [];
  filteredList = [];
  initialDates = [];
  selectedDest: OntologyResponse = new OntologyResponse();
  hotelCriteria: HotelCriteria;
  productCriteriaList = [];
  defaultPaxSet: Array<PaxAllocationItem> = [];
  currentPaxOption = 0;
  enterPressed = false;
  roomWiseSelected = false;
  datePickerLabel = 'CHECK-IN & CHECK-OUT DATES*';
  departureUrl = '';
  serviceCriteria;
  subscriptions = new Subscription();
  calendarMinDate: Date = new Date();
  calendarMinDateForInitialEpeToDpConversionCriteria: Date = moment().toDate();
  dpPreItemDate: Date = null;
  dpPreItemToDate: Date = null;
  dpPreItemToDateString: string;
  preItemDayGap = 0;
  focusToDestination = true;
  focusInit = false;
  paxDisplay: string;
  msg = '';
  freeze: boolean; // enable/disable all input fields during amendment search
  destinationWarning = false;
  checkInWarning;
  checkOutWarning;
  previousDateSet = []; // use to identify either changed date start date or not
 isTeenActive = false;
 paxSplitDisplayText = 'All Guests';
 cacheCity: string;
 cacheCountry: string;
 ENABLE_PAX_SPLIT = false;
  GEO_HTL_AUTO_SWITCH_ENABLED = false;
  GEO_HTL_AUTO_SWITCH_RETRY_TIME: number = 60; // time in seconds
  hideErrorMsg = true;
  validateDisabledDateSelection = false;
  SURF_ERROR_MESSAGES = SurfErrorMessages;
  selectedTravellerInterests: TravellerInterest[] = []; // User selected travellers Interests
  permissionKeys;
  enableRecommendations = false;
  enableGeoSpatialSearchPermission = false;
  GEO_ENABLE_FOR_HOTEL_SEARCH = false;
  isSelectGeoLocation = false;
  geoPlaceDetails: PlaceDetails;
  geoPlaceDetailsError = '';
  externalSources: Array<string> = [];
  geoLocationAutoCompleteErrors: Array<string> = [];
  isGeoSearchSwitched = false;
  activeToaster = false;
  tbxResultMap = new Map<string, Detail>();
  GEO_ENABLE_LOCATION_OVERRIDE_SETUP = false;
  overriddenDataSubscription = new Subscription();
  GEO_GATEWAY_FOR_HTL_LOCATIONS = 'GOOGLE';

  constructor(protected dataServiceHandler: DataServiceHandler,
              protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected surfGeoSpatialSearchGoogleService: SurfGeoSpatialSearchGoogleService,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              protected surfAuthorizationService: SurfAuthorizationService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService) {
    this.hotelCriteria = new HotelCriteria();
    this.subscriptions.add(
      this.commonService.getProductCriteriaList().subscribe(list => {
        this.productCriteriaList = list;
      }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unfreeze && this.unfreeze !== undefined) {
      this.freeze = !this.unfreeze;
    }
    if (changes.selectedPax) {
      this.selectedPax = changes.selectedPax.currentValue;
      if (this.isRefine) {
        this.defaultPaxSet = this.selectedPax;
      }
      if (this.isServiceAddComponent) {
        const adults = this.selectedPax.reduce( (acc, pax ) => acc + pax.adult , 0 ) ;
        const child = this.selectedPax.reduce( (acc, pax) => acc + pax.child.length , 0) ;
        const teen = this.selectedPax.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
        const infant = this.selectedPax.reduce( (acc, pax) => acc + pax.infant.length , 0 ) ;
        this.paxDisplay = this.generateDisplayString(adults, child, infant, teen) ;
      }

      // if (this.isServiceAddComponent) {
      //   this.defaultPaxSet = this.selectedPax;
      //   console.log(this.selectedPax);
      //   const pax = this.selectedPax[0];
      //   this.serviceCriteria = this.hotelCriteria;
      //   let adultText  = '~30~DOB';
      //   let childText  = '~6~DOB';
      //   let infantText =  '~~';
      //   adultText = pax.adult + adultText;
      //   this.serviceCriteria.adult = adultText;
      //   if (pax.child.lenth > 0) {
      //     childText = pax.child.length + childText ;
      //     this.serviceCriteria.child = childText;
      //   }
      //   if (pax.infant.length > 0) {
      //     infantText = pax.infant.length + infantText ;
      //     this.serviceCriteria.infant = infantText;
      //   }
      //   this.validateInputs();
      // }
    }
    if (changes.cartPaxSelection && this.cartPaxSelection) {
      const adults = this.cartPaxSelection.reduce( (acc, pax ) => acc + pax.adult , 0 );
      const child = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.child.length , 0);
      const teen = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.teen.length , 0);
      const infant = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.infant.length , 0 );
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getPassengerSelection(this.cartPaxSelection);
    }
    if (changes.setRoomWise) {
      this.roomWiseSelected = changes.setRoomWise.currentValue;
    }

    if (changes.dpEnableDate) {
      this.dpEnableDate = changes.dpEnableDate.currentValue;
      if (this.dpEnableDate) {
        this.dpCalEnableDate = new Date(this.dpEnableDate);
        this.dpEnableDateChanged = true;
      } else {
        this.dpCalEnableDate = new Date();
        this.dpEnableDateChanged = false;
      }
      this.validateInputsForDP();
    }

    if (changes.dpInitialDateStr) {
      this.dpInitialDateStr = changes.dpInitialDateStr.currentValue;
      if (this.dpInitialDateStr && this.dpInitialDateStr.split('~')[0] && this.dpInitialDateStr.split('~')[0] !== 'undefined'
        && this.dpInitialDateStr.split('~')[1]) {
        this.setInitialDates(this.dpInitialDateStr.split('~')[0], parseInt(this.dpInitialDateStr.split('~')[1]));
        this.validateInputsForDP();
      }
    }

    if (changes.paxSplitArray) {
      const selectedPaxArray = this.paxSplitArray.filter(pax => {
        return pax.selected;
      });
      if (selectedPaxArray && selectedPaxArray.length === this.paxSplitArray.length) {
        this.paxSplitDisplayText = 'All Guests';
      } else {
        this.paxSplitDisplayText = selectedPaxArray.length.toString() + (selectedPaxArray.length === 1 ? ' Guest' : ' Guests');
      }
    }

    if (changes.dpInitialDestStr) {
      this.dpInitialDestStr = changes.dpInitialDestStr.currentValue;

      // DISPLAYTEXT~LOCTYPE~LOCCODE~LOCNAME~PARENTNAME
      if (this.dpInitialDestStr && this.dpInitialDestStr.split('~')[0] && this.dpInitialDestStr.split('~')[1] &&
        this.dpInitialDestStr.split('~')[2] && this.dpInitialDestStr.split('~')[3] &&
        (this.dpInitialDestStr.split('~')[4] ||
          (this.isDPSearch && this.isDpRefine && this.dpInitialDestStr.split('~')[1] === DestinationType.GEO_LOCATION))) {
        if (this.isAmendmentFlow && this.dpInitialDestStr.split('~')[1] === DestinationType.GEO_LOCATION) {
          this.isSelectGeoLocation = true;
        }
        this.updateLocationCriteria(this.dpInitialDestStr.split('~')[0], this.dpInitialDestStr.split('~')[1],
          this.dpInitialDestStr.split('~')[2],
          this.dpInitialDestStr.split('~')[3], this.dpInitialDestStr.split('~')[4], changes.dpInitialDestStr.firstChange);
      }
      if (this.isAmendmentFlow && !this.isItemCloseable) {
        this.checkDestinationChange(this.hotelCriteria);
      }
    }

    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === 'HTL') {
      this.searchHotels(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }


  }

  ngOnInit() {
    this.setAuthenticationParameters();
    this.surfGeoSpatialSearchGoogleService.loadConfigs();
    this.loadConfigs();
    this.getGeoDataFromTBX();
    if (!this.paxTypeAges || this.paxTypeAges.length === 0) {
      this.loadPaxTypeAges();
    } else {
      this.mapPaxSubTypeAges();
    }
    this.surfTextCasePipe = new SurfTextCasePipe(this.configService);
    this.subscriptions.add(
      this.commonService.getComponentCriteria('HTL').subscribe(cri => {
        if (!(this.isAmendmentFlow && this.isDPSearch)) {
          this.hotelCriteria = cri as HotelCriteria;
        }
      }));

    this.subscriptions.add(
      this.commonService.getKeyParamString().subscribe(keyParams => {
        // update resultsCount
        if (this.HTL_SEARCH_RESULT_COUNT && this.HTL_SEARCH_RESULT_COUNT.length > 0) {
          const tempList = keyParams.split('resultsCount=');
          if (tempList[1]) {
            const sliceIndex = tempList[1].indexOf('&') >= 0 ? tempList[1].indexOf('&') : tempList[1].length;
            tempList[1] = this.HTL_SEARCH_RESULT_COUNT + tempList[1].slice(sliceIndex);
          } else {
            tempList[1] = this.HTL_SEARCH_RESULT_COUNT;
          }
          keyParams = tempList.join('resultsCount=');
        }
        this.keyParams = keyParams;
      }));

    this.subscriptions.add(this.commonService.getCurrentMsg().subscribe(msg => {
      if (msg === 'triggerReset') {
        this.resetInputs();
      }
    }));

    // this.selectedDest.displayText = "";
    if (this.hotelCriteria.destinationName && this.hotelCriteria.destinationName !== 'undefined'
      && this.isDPSearch && this.dpInitialDestStr) {
      this.selectedDest.displayText = this.hotelCriteria.destinationName + ((this.hotelCriteria.destinationParentCode &&
        this.hotelCriteria.destinationParentCode !== 'null') ? ',' + this.hotelCriteria.destinationParentCode : '');
    } else {
      this.selectedDest.displayText = '';
    }

    if (this.isDPSearch && this.preItemLocation) {
      // this.adjustLocations();
    }

    if (this.isRefine) {
      this.setInitialDates(this.hotelCriteria.startDate, this.hotelCriteria.nights);
      this.cacheCity = this.hotelCriteria.city;
      this.cacheCountry = this.hotelCriteria.country;
      if (this.hotelCriteria.destinationType && this.hotelCriteria.destinationType === DestinationType.GEO_LOCATION) {
        this.isSelectGeoLocation = true;
      }
    }
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'HTL');
    if (this.isDPSearch && this.productCriteriaList) {
      const dpHotelCri = this.productCriteriaList[this.surfCriteriaUtil.getCriteriaItemArrayIndex(this.productCriteriaList, 'HTL', this.index)];
      if (dpHotelCri) {
        this.setInitialDates(dpHotelCri.startDate, dpHotelCri.nights);
        this.validateInputsForDP();
      }
    }

    /*Load cities and countries during the component flow only*/
    // if (!this.isDPSearch) {
      // this.dataServiceHandler.getCitiesWithDetails('all').subscribe(
      //   res => {
      //     if (res && res.data) {
      //       let cities = res.data;
      //       this.surfCriteriaUtil.updateValue(cities);
      //       cities.forEach(city => {
      //         this.destinations.push(city);
      //         if (this.isRefine && (this.hotelCriteria.destinationType == DestinationType.CITY) && (this.hotelCriteria.destinationCode == city.code)) {
      //           this.hotelCriteria.destinationName = city.name;
      //           this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
      //           if (this.isDPSearch) {
      //             // this.updateDPCriteria('dest-name');
      //           }
      //         }
      //       });
      //     }
      //   },
      // error => {
      //   console.log('Error in getting cities');
      // });
      //
      // this.dataServiceHandler.getCountries().subscribe(
      //   res => {
      //     if (res) {
      //       let countries = res;
      //       this.surfCriteriaUtil.updateValue(countries);
      //       countries.forEach(country => {
      //         this.destinations.push(country);
      //         if (this.isRefine && (this.hotelCriteria.destinationType == DestinationType.COUNTRY) && (this.hotelCriteria.destinationCode == country.code)) {
      //           this.hotelCriteria.destinationName = country.name;
      //           this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
      //           if (this.isDPSearch) {
      //             // this.updateDPCriteria('dest-name');
      //           }
      //         }
      //       });
      //     }
      //   },
      // error => {
      //   console.log('Error in getting Countries');
      // });
    // }else {
    //   this.destinations = this.dpHotelDestinations;
    // }
  }

  getGeoDataFromTBX() {
    const setupMap = new Map<string, Detail>();

    this.overriddenDataSubscription = this.dataServiceHandler.getOverriddenGeoLocationData(this.GEO_GATEWAY_FOR_HTL_LOCATIONS).subscribe(
      data => {

        if (data && data.length > 0){

          console.log(data);

          data.forEach(element => {
            setupMap.set(element.code?.toString()?.trim(), element.detail);
          });
          this.tbxResultMap = setupMap;
          this.surfGeoSpatialSearchGoogleService.setResultMap(this.tbxResultMap);
          this.overriddenDataSubscription?.unsubscribe();
        }

      },
      error => console.error('Error in getting data from TBX setup', error)
    );

  }

  /**
   * Setting up the authentication parameters from the permission framework
   */
  setAuthenticationParameters() {
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(surfOperations);
    const enableRecommendationsPermission = this.surfAuthorizationService
      .getPermissionResponse(PermissionKeys.ENABLE_RECOMMENDATIONS);
    this.enableRecommendations = (enableRecommendationsPermission && enableRecommendationsPermission.allowed);
    const enableGeoSearchPermission = this.surfAuthorizationService
      .getPermissionResponse(PermissionKeys.ENABLE_GEO_SPATIAL_SEARCH);
    this.enableGeoSpatialSearchPermission = (enableGeoSearchPermission && enableGeoSearchPermission.allowed);
  }

  ngAfterViewInit() {
    if (!this.isRefine) {
      this.focusInit = true;
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }
  }

  loadPaxTypeAges() {
    this.subscriptions.add(
      this.dataServiceHandler.getPaxSubTypeAges().subscribe(
        res => {
          if (res == null || !(Array.isArray(res)) || res.length === 0) {
            console.log('Could not fetch Pax Type Ages');
            return;
          }

          this.paxTypeAges = res[0].paxTypes;
          this.mapPaxSubTypeAges();
        },
        error => {
          console.log('Error in getting pax sub type ages');
        }
      ));
  }
  private mapPaxSubTypeAges() {
    this.MAX_CHILD_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'C').age.upper / 12);
    this.MAX_TEEN_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'T').age.upper / 12);
    this.MAX_INFANT_AGE = this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
  }
  loadConfigs() {
    this.STATIC_DATA_URL_BASE = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA] ? this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA] : this.STATIC_DATA_URL_BASE;

    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();

    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'MIN_SEARCH_QUERY_LENGTH':
            this.MIN_SEARCH_QUERY_LENGTH = mc.name;
            break;
          case 'MAX_ROOMS':
            this.MAX_ROOMS = mc.name;
            break;
          case 'MIN_ADULT':
            this.MIN_ADULT = mc.name;
            break;
          case 'MAX_ADULT':
            this.MAX_ADULT = mc.name;
            break;
          case 'MAX_PAX_COUNT':
            this.MAX_PAX_COUNT = mc.name;
            break;
          // case 'MAX_CHILD_AGE':
          //   this.MAX_CHILD_AGE = +mc.name;
          //   break;
          // case 'MAX_INFANT_AGE':
          //   this.MAX_INFANT_AGE = +mc.name;
          //   break;
          case 'MAX_CABIN_PAX_COUNT':
            this.MAX_CABIN_PAX_COUNT = mc.name;
            break;
          case 'DEFAULT_ADULT_AGE':
            this.DEFAULT_ADULT_AGE = mc.name;
            break;
          case 'INITIAL_DURATION':
            this.INITIAL_DURATION = mc.name;
            break;
          case 'DEFAULT_CALENDAR_OFFSET':
            this.DEFAULT_OFFSET = mc.name;
            break;
          case 'CALENDAR_RANGE':
            this.CALENDAR_RANGE = mc.name;
            break;
          case 'DISABLE_DP_SWITCH':
            this.DISABLE_DP_SWITCH = (mc.name === 'true');
            break;
          case 'STATIC_DATA_FETCH_SIZE':
            this.STATIC_DATA_FETCH_SIZE = mc.name;
            break;
          case 'HTL_SEARCH_RESULT_COUNT':
            this.HTL_SEARCH_RESULT_COUNT = mc.name;
            break;
          case 'HTL_SEARCH_EXPAND_RESULT':
            this.HTL_SEARCH_EXPAND_RESULT = mc.name;
            break;
          case 'HTL_SEARCH_ENABLE_SOURCE_SUMMARY_CACHE':
            this.HTL_SEARCH_ENABLE_SOURCE_SUMMARY_CACHE = mc.name;
            break;
          case 'TEEN_PAX_ACTIVE':
            this.TEEN_PAX_ACTIVE = ((mc.name as string) === 'true');
            break;
          case 'HTL_STATIC_DATA_TYPES':
            if (mc.name) {
              this.HTL_STATIC_DATA_TYPES = mc.name.split(',');
            }
            break;
        }
      });
    }
    const ENABLE_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('ENABLE_DP_PASSENGER_SPLIT', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_PAX_SPLIT;
    if (ENABLE_PAX_SPLIT_CONFIG) {
      ENABLE_PAX_SPLIT = ENABLE_PAX_SPLIT_CONFIG.toString().toUpperCase() === 'TRUE';
    }
    const ENABLE_HTL_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('DP_SPLIT_PAX_ENABLED_PRODUCTS', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_HTL_PAX_SPLIT;
    if (ENABLE_HTL_PAX_SPLIT_CONFIG) {
      ENABLE_HTL_PAX_SPLIT = ENABLE_HTL_PAX_SPLIT_CONFIG.toString().split(',').includes('HTL');
    }
    if (ENABLE_PAX_SPLIT && ENABLE_HTL_PAX_SPLIT) {
      this.ENABLE_PAX_SPLIT = true;
    }
    const GEO_ENABLE_FOR_HOTEL_SEARCH_CONFIG = this.configLoader.getModuleConfig('GEO_ENABLE_FOR_HOTEL_SEARCH', TC.MODULE_NAME.SURF_B2B);
    if (GEO_ENABLE_FOR_HOTEL_SEARCH_CONFIG && this.enableGeoSpatialSearchPermission) {
      this.GEO_ENABLE_FOR_HOTEL_SEARCH = GEO_ENABLE_FOR_HOTEL_SEARCH_CONFIG.toString().toUpperCase() === 'TRUE';
    }
    const GEO_GATEWAY_FOR_HTL_LOCATIONS_CONFIG =
      this.configLoader.getModuleConfig('GEO_GATEWAY_FOR_HTL_LOCATIONS', TC.MODULE_NAME.SURF_B2B);
    if (GEO_GATEWAY_FOR_HTL_LOCATIONS_CONFIG) {
      this.externalSources = GEO_GATEWAY_FOR_HTL_LOCATIONS_CONFIG.toString().toUpperCase().split(',');
    }
    const GEO_HTL_AUTO_SWITCH_ENABLED_CONFIG = this.configLoader.getModuleConfig('GEO_HTL_AUTO_SWITCH_ENABLED', TC.MODULE_NAME.SURF_B2B);
    if (GEO_HTL_AUTO_SWITCH_ENABLED_CONFIG) {
      this.GEO_HTL_AUTO_SWITCH_ENABLED = GEO_HTL_AUTO_SWITCH_ENABLED_CONFIG.toUpperCase() === 'TRUE';
    }
    const GEO_HTL_AUTO_SWITCH_RETRY_TIME_CONFIG = this.configLoader.getModuleConfig('GEO_HTL_AUTO_SWITCH_RETRY_TIME', TC.MODULE_NAME.SURF_B2B);
    if (GEO_HTL_AUTO_SWITCH_RETRY_TIME_CONFIG && !isNaN(parseInt(GEO_HTL_AUTO_SWITCH_RETRY_TIME_CONFIG.toString(), 10))) {
      this.GEO_HTL_AUTO_SWITCH_RETRY_TIME = parseInt(GEO_HTL_AUTO_SWITCH_RETRY_TIME_CONFIG.toString(), 10);
    }
    this.departureUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=' + this.getStaticDataTypes() + '&userInput=[UI]&aggrLevel=NA&locale=en&matchType=CODE~NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;

    // Recommendation Config
    const REC_ENABLE_FOR_HOTEL_SEARCH  = this.configLoader.getModuleConfig('REC_ENABLE_FOR_HOTEL_SEARCH', 'SURF B2B');
    if (REC_ENABLE_FOR_HOTEL_SEARCH) {
      this.REC_ENABLE_FOR_HOTEL_SEARCH = REC_ENABLE_FOR_HOTEL_SEARCH.toString().toUpperCase() === 'TRUE';
    }

    //TBX setup override config
    const GEO_ENABLE_LOCATION_OVERRIDE_SETUP = this.configLoader.getModuleConfig('GEO_ENABLE_LOCATION_OVERRIDE_SETUP', TC.MODULE_NAME.SURF_B2B);
    if (GEO_ENABLE_LOCATION_OVERRIDE_SETUP) {
      this.GEO_ENABLE_LOCATION_OVERRIDE_SETUP = GEO_ENABLE_LOCATION_OVERRIDE_SETUP.toUpperCase() === 'TRUE';
    }


    const GEO_GATEWAY_FOR_HTL_LOCATIONS =
      this.configLoader.getModuleConfig('GEO_GATEWAY_FOR_HTL_LOCATIONS', TC.MODULE_NAME.SURF_B2B);
    if (GEO_GATEWAY_FOR_HTL_LOCATIONS) {
      this.GEO_GATEWAY_FOR_HTL_LOCATIONS = GEO_GATEWAY_FOR_HTL_LOCATIONS.toString();
    }
  }

  getStaticDataTypes() {
    if (this.GEO_ENABLE_FOR_HOTEL_SEARCH && !this.isGeoSearchSwitched) {
      return 'HOTEL';
    } else if (this.HTL_STATIC_DATA_TYPES && this.HTL_STATIC_DATA_TYPES.length > 0) {
      // return 'COUNTRY~CITY~HOTEL';
      return this.HTL_STATIC_DATA_TYPES.join('~');
    }
  }

  getHotelDestinationPlaceHolder() {
    let hotelDestinationPlaceHolder = 'Search ';
    if (this.GEO_ENABLE_FOR_HOTEL_SEARCH && !this.isGeoSearchSwitched) {
      hotelDestinationPlaceHolder = hotelDestinationPlaceHolder + 'location or hotel';
    } else if (this.HTL_STATIC_DATA_TYPES && this.HTL_STATIC_DATA_TYPES.length > 0) {
      hotelDestinationPlaceHolder = hotelDestinationPlaceHolder + (this.HTL_STATIC_DATA_TYPES.join(', ')).toLocaleLowerCase();
    }
    return hotelDestinationPlaceHolder.replace(/,(?=[^,]*$)/, ' or');
  }

  /**
   * set calendar initial dates based on the config values
   */
  setInitialDates(sd: any, nights: number) {
    if (sd == undefined || nights == undefined) {
      return;
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.hotelCriteria.startDate = sd;
      this.hotelCriteria.nights = nights;
    }
    this.initialDates = [];
    const startDate = new Date(sd);
    this.initialDates.push(startDate);
    for (let i = 1; i <= nights; i++) {
      this.initialDates.push(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i));
    }
    this.previousDateSet = JSON.parse(JSON.stringify(this.initialDates));

    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkDateChange();
    }
  }

  updateLocationCriteria(displayText, type, code, name, parent, firstChange) {
    this.hotelCriteria.destinationType = type;
    this.hotelCriteria.destinationCode = code;
    this.hotelCriteria.destinationName = name;
    this.hotelCriteria.destinationParentCode = parent;

    if (this.isAmendmentFlow && firstChange && this.oldCriteriaItem && this.oldCriteriaItem.criItem) {
      this.hotelCriteria.hotelCode = code;
      this.cacheCity = this.oldCriteriaItem.criItem.destinationCode;
      this.cacheCountry = this.oldCriteriaItem.criItem.country;
    }

    if (this.isAmendmentFlow && type === DestinationType.GEO_LOCATION) {
      this.hotelCriteria.geoLocationType = LocationSources.GOOGLE;
      this.hotelCriteria.city = null;
      this.hotelCriteria.hotelCode = null;
      this.cacheCity = null;
      this.cacheCountry = null;
    }

    if (displayText !== 'undefined, undefined') {
      this.selectedDest.displayText = '';
      setTimeout(() => {
        this.selectedDest.displayText = !parent || parent === 'undefined' || parent === 'null' ? name : name + ', ' + parent;
        this.validateInputsForDP();
      }, 100);
    }
    this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
  }

  returnDestination(data) {
    // console.log("return data : ",data);
    if (data && data.length > 0) {
      this.filteredList = data;
    } else {
      this.filteredList = [];
      this.hotelCriteria.destinationCode = null;
      this.hotelCriteria.hotelCode = null;
      this.hotelCriteria.destinationName = null;
      this.hotelCriteria.destinationParentCode = null;
    }
  }

  returnedSearchQuery(event) {
    this.destSearchInitiated = event && event.length >= 3;
    if (this.selectedDest.displayText !== undefined && (this.selectedDest.displayText == '' || this.selectedDest.displayText == ' ') && this.isDPSearch) {
      const value = this.selectedDest.displayText
        + '~' + (this.hotelCriteria.destinationType ? this.hotelCriteria.destinationType : '')
        + '~' + ''
        + '~' + (this.hotelCriteria.destinationName ? (this.hotelCriteria.destinationType && this.hotelCriteria.destinationType === DestinationType.CITY) ?  this.hotelCriteria.destinationName.trim() : this.surfTextCasePipe.transform(this.hotelCriteria.destinationName, 'title').trim() : '')
        + '~' + (this.hotelCriteria.destinationParentCode ? this.hotelCriteria.destinationParentCode : '')
        + '~' + '';

      const changeObj = {
        index : this.index,
        productCode : 'HTL',
        changeType : 'DEST',
        onlyForButtonDisable : true,
        value
      };
      this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
      this.commonService.updateChangeItem(changeObj);
    }
  }

  selectDestination(data) {
    this.filteredList = [];
    this.calendarMinDate = new Date();
    this.cacheCity = null;
    this.cacheCountry = null;

    if (!this.hotelCriteria.startDate) {
      setTimeout(() => {
        this.datePickerFocus = true;
        this.guestComponentFocus = false;
        this.focusToDestination = false;
      }, 200);
    }

    if (data.attributes && data.attributes.length > 0) {
      for (let i = 0; i < data.attributes.length ; i++) {
        if (data.attributes[i].name === 'current_time') {
          this.calendarMinDate = new Date(data.attributes[i].value);
        }
      }
    } else {
      this.calendarMinDate = new Date();
    }
    this.isSelectGeoLocation = false;
    this.surfCalendarStartDateService.updateMinDate(this .calendarMinDate, 'HTL');
    // set hotel city and country when select hotel
    let hotelCityCode = null;
    let hotelCountryCode = null;

    if (data.type == 'CITY' || data.type == 40) {
      this.selectedDest.displayText = data.name + (this.surfCriteriaUtil.getCountry(data) ? ', ' + this.surfCriteriaUtil.getCountry(data) : '');
      this.hotelCriteria.destinationType = DestinationType.CITY;
      this.hotelCriteria.destinationParentCode = this.surfCriteriaUtil.getCountry(data);
      hotelCityCode = this.surfCriteriaUtil.getCity(data) ? this.surfCriteriaUtil.getCity(data).code : null;
      hotelCountryCode = this.surfCriteriaUtil.getCountryCode(data);
    } else if (data.type == 80) {
      this.hotelCriteria.destinationParentCode = this.surfCriteriaUtil.getCity(data).name + (this.surfCriteriaUtil.getCountry(data) ? ', ' + this.surfCriteriaUtil.getCountry(data) : '');
      this.selectedDest.displayText = data.name + ', ' + this.hotelCriteria.destinationParentCode;
      this.hotelCriteria.destinationType = DestinationType.HOTEL;
      hotelCityCode = this.surfCriteriaUtil.getCity(data) ? this.surfCriteriaUtil.getCity(data).code : null;
      hotelCountryCode = this.surfCriteriaUtil.getCountryCode(data);
      if (hotelCityCode) {
        this.cacheCity = hotelCityCode;
      } else if (hotelCountryCode) {
        this.cacheCountry = hotelCountryCode;
      }
    } else if (data.type === 200) {
      this.isSelectGeoLocation = true;
      this.hotelCriteria.destinationParentCode = this.surfCriteriaUtil.getCity(data).name + (this.surfCriteriaUtil.getCountry(data) ? ', ' + this.surfCriteriaUtil.getCountry(data) : '');
      this.selectedDest.displayText = data.name + (data.secondaryName ? (', ' + data.secondaryName) : '');
      this.hotelCriteria.geoLocationType = data.source;
      this.hotelCriteria.destinationType = DestinationType.GEO_LOCATION;
      // hotelCityCode = this.surfCriteriaUtil.getCity(data) ? this.surfCriteriaUtil.getCity(data).code : null;
      // hotelCountryCode = this.surfCriteriaUtil.getCountryCode(data);
      // if (hotelCityCode) {
      //   this.cacheCity = hotelCityCode;
      // } else if (hotelCountryCode) {
      //   this.cacheCountry = hotelCountryCode;
      // }
    } else {
      this.selectedDest.displayText = data.name;
      this.hotelCriteria.destinationType = DestinationType.COUNTRY;
    }
    this.selectedDest.displayText = data.type === 'CITY' || data.type === 40 || this.isSelectGeoLocation ?
      this.selectedDest.displayText.trim() : this.surfTextCasePipe.transform(this.selectedDest.displayText, 'title').trim();
    this.destSearchInitiated = false;
    if (data.type != 80) {
      this.hotelCriteria.hotelCode = null;
      this.hotelCriteria.destinationCode = data.code;
    } else {
      this.hotelCriteria.destinationCode = null;
      this.hotelCriteria.hotelCode = data.code;
      this.cacheCity = (this.isAmendmentFlow) && this.hotelCriteria && this.hotelCriteria.destinationCode ?
        this.hotelCriteria.destinationCode :
        this.cacheCity;
    }
    this.hotelCriteria.destinationName = data.name;

    if (this.hotelCriteria.productCode == undefined) {
      this.hotelCriteria.productCode = 'HTL';
    }

    if (!(this.isAmendmentFlow && this.isDPSearch)) {
      this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
    }

    if (this.isDPSearch) {
      /*Notify the change
      * change value : for DEST ->  DISPLAYTEXT~LOCTYPE~LOCCODE~LOCNAME~PARENTNAME~HOTEL_CODE~~*/

      const value = this.selectedDest.displayText
        + '~' + this.hotelCriteria.destinationType
        + '~' + (data.type == 80 ? this.surfCriteriaUtil.getCity(data).code : this.hotelCriteria.destinationCode)
        + '~' + (this.hotelCriteria.destinationName ? (this.hotelCriteria.destinationType && this.hotelCriteria.destinationType === DestinationType.CITY) ?  this.hotelCriteria.destinationName.trim() : this.surfTextCasePipe.transform(this.hotelCriteria.destinationName, 'title').trim() : '')
        + '~' + this.hotelCriteria.destinationParentCode
        + '~' + (data.type == 80 ? this.hotelCriteria.hotelCode : '')
        + '~' + (hotelCityCode ? hotelCityCode : '')
        + '~' + (hotelCountryCode ? hotelCountryCode : '');

      const changeObj = {
        index : this.index,
        productCode : 'HTL',
        changeType : 'DEST',
        amendmentChangeType : 'DESTINATION',
        value
      };

      this.commonService.updateChangeItem(changeObj);
      this.validateInputsForDP();
    }
    this.getCurrentHotelCriteria.emit(this.hotelCriteria);
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkDestinationChange(data);
    }
  }

  checkDependency() {
    // before updating straight away, check whether there are CAR/GEN items in the itinerary below this hotel which lies in the date period of the hotel
    const postItemsArray = [];
    if (this.productCriteriaList && this.productCriteriaList.length > 0) {
      let thisHotel;
      let thisHotelFromDate;
      let thisHotelToDate;

      this.productCriteriaList.forEach(itm => {
        if (itm.itemIndex == this.index && itm instanceof HotelCriteria) {
          thisHotel = itm as HotelCriteria;
        }
      });
      if (thisHotel) {
        thisHotelFromDate = new Date(thisHotel.startDate);
        thisHotelToDate = new Date(thisHotel.startDate);
        thisHotelToDate.setDate(thisHotelToDate.getDate() + thisHotel.nights);
      }

      this.productCriteriaList.forEach(item => {
        if (item.itemIndex > this.index && (item.productCode == 'CAR' || item.productCode == 'GEN')) {   // get items below this hotel and inside the date period of the hotel
          if (item instanceof CarCriteria) {
            const car = item as CarCriteria;
            const carDate = new Date(car.pickupDateTime);
            if (thisHotelFromDate <= carDate && carDate <= thisHotelToDate) {
              postItemsArray.push(item);
            }
          }
          if (item instanceof GenericCriteria) {
            const gen = item as GenericCriteria;
            const genDate = new Date(gen.startDate);
            if (thisHotelFromDate <= genDate && genDate <= thisHotelToDate) {
              postItemsArray.push(item);
            }
          }
        }
      });
    }

    if (postItemsArray.length > 0) {
      const changeParam = this.selectedDest.displayText + '|' + this.hotelCriteria.destinationType + '|' +
        this.hotelCriteria.destinationCode + '|' + this.hotelCriteria.destinationName + '|' + this.hotelCriteria.destinationParentCode;

      this.notifyDPDependencyChange.emit({items: postItemsArray, changeParam});
    }
  }

  validateInputs(): boolean {
    // if (this.isServiceAddComponent) {
    //   // const pax = this.selectedPax[0];
    //   // let adultText  = '~30~DOB';
    //   // let childText  = '~6~DOB';
    //   // let infantText =  '~~';
    //   // adultText = pax.adult + adultText;
    //   // this.hotelCriteria.adult = adultText;
    //   // if (pax.child.length > 0) {
    //   //   childText = pax.child.length + childText ;
    //   //   this.hotelCriteria.child = childText;
    //   // }
    //   // if (pax.infant.length > 0) {
    //   //   infantText = pax.infant.length + infantText ;
    //   //   this.hotelCriteria.infant = infantText;
    //   // }
    //   this.getPassengerSelection(this.cartPaxSelection);
    // }
    let isAllInputsPresent = false;
    const isLastMinuteBookingValidationPass = this.surfCalendarStartDateService.lastMinuteBookingErrorSet.size <= 0;
    if ((this.hotelCriteria.destinationCode || this.hotelCriteria.hotelCode) && this.selectedDest.displayText &&
      this.hotelCriteria.nights && this.hotelCriteria.nights > 0 && this.hotelCriteria.startDate &&
      this.hotelCriteria.adult) {
      isAllInputsPresent = true;
    }
    if (this.isAmendmentFlow) {
      return isLastMinuteBookingValidationPass && isAllInputsPresent;
    } else {
      return isAllInputsPresent;
    }
  }

  closeCriteriaBox() {
    this.commonService.changeMessage('HTL_close');
    this.commonService.updateComponentCriteria(null, 'HTL');
    if (this.isRefine) {
      this.cancelRefineSearch();
    }
  }

  validateInputsForDP() {
    if (this.selectedDest.displayText) {
      this.isValid.emit(true);
    } else {
      this.isValid.emit(false);
    }
  }

  /**
   * Construct the hotel search URL and redirect to the listing page
   */
  searchHotels(isExternal?) {
    if (this.isSelectGeoLocation) {
      this.getGeoDetailResult();
    } else {
      if (this.validateInputs()) {
        let criteriaUrl ;
        const basePath = '/'; // this.isRefine ? window.location.origin + '/' : window.location.href;
        if (this.isServiceAddComponent || this.isAmendmentFlow) {
          this.travellerEarliestDepartureDateService.addNewDepartureDate(this.hotelCriteria.startDate);
          this.travellerEarliestDepartureDateService.checkAndUpdateEarliestDepartureDate();
          // let f = this.travellerEarliestDepartureDateService.isEarliestDepartureDateIsChanged();
          if (this.isRefine) {
            criteriaUrl = basePath + 'b2b-hotels/?' + this.keyParams + this.getHotelSearchUrlParams();
          } else {
            if (this.isAmendmentFlow) {
              criteriaUrl = basePath + 'b2b-hotels/?' + this.keyParams + this.getHotelSearchUrlParams();
            } else {
              criteriaUrl = basePath + 'b2b-hotels/?' + this.getHotelSearchUrlParams();
            }
            // criteriaUrl = basePath + 'b2b-hotels/?' + this.getHotelSearchUrlParams();
          }
          this.getUrlCriteria.emit({url: criteriaUrl, isExternal});
        } else {
          window.location.href = basePath + 'b2b-hotels/?' + this.keyParams + this.getHotelSearchUrlParams();
        }
      }
    }
  }

  /**
   * get geo spatial detail result
   */
  getGeoDetailResult() {
    this.geoPlaceDetailsError = '';
    if (this.hotelCriteria.geoLocationType === LocationSources.GOOGLE) {
      const detailUrl = this.surfGeoSpatialSearchGoogleService.getGoogleDetailResult(this.hotelCriteria.destinationCode);
      detailUrl.subscribe(
        arg => this.processDetailResult(arg, false),
        error => console.log(error)
      );
    }
  }

  processDetailResult(detailResult, isExternal?) {
    if (typeof detailResult === 'string') {
      this.geoPlaceDetailsError = detailResult;
    } else if (detailResult && detailResult.data) {
      this.geoPlaceDetails = detailResult.data;
      if (this.validateInputs()) {
        let criteriaUrl ;
        const basePath = '/'; // this.isRefine ? window.location.origin + '/' : window.location.href;
        if (this.isServiceAddComponent || this.isAmendmentFlow) {
          this.travellerEarliestDepartureDateService.addNewDepartureDate(this.hotelCriteria.startDate);
          this.travellerEarliestDepartureDateService.checkAndUpdateEarliestDepartureDate();
          // let f = this.travellerEarliestDepartureDateService.isEarliestDepartureDateIsChanged();
          if (this.isRefine) {
            criteriaUrl = basePath + 'b2b-hotels/?' + this.keyParams + this.getHotelSearchUrlParams();
          } else {
            if (this.isAmendmentFlow) {
              criteriaUrl = basePath + 'b2b-hotels/?' + this.keyParams + this.getHotelSearchUrlParams();
            } else {
              criteriaUrl = basePath + 'b2b-hotels/?' + this.getHotelSearchUrlParams();
            }
            // criteriaUrl = basePath + 'b2b-hotels/?' + this.getHotelSearchUrlParams();
          }
          this.getUrlCriteria.emit({url: criteriaUrl, isExternal});
        } else {
          window.location.href = basePath + 'b2b-hotels/?' + this.keyParams + this.getHotelSearchUrlParams();
        }
      }
    }
  }

  getHotelSearchUrlParams() {
    const detail = this.getOverriddenDatafromTBX(this.hotelCriteria.destinationCode);
    /*Consider check-in date when calculating DOB*/
    if (this.hotelCriteria.adult) {
      // this.hotelCriteria.adult = this.surfCriteriaUtil.replaceDOB(this.hotelCriteria.adult, this.hotelCriteria.startDate);
      this.hotelCriteria.adult = this.hotelCriteria.adult.replace(/DOB/g, ''); // removing DOB from adult
    }
    let childParams = '';
    if (this.hotelCriteria.teen && this.hotelCriteria.teen.length > 0) {
      this.hotelCriteria.teen = this.hotelCriteria.teen.replace(/DOB/g, ''); // removing DOB from teen
      childParams = '&child=' + this.hotelCriteria.teen;
    }
    if (this.hotelCriteria.child && this.hotelCriteria.child.length > 0) {
      if (childParams.toString().includes('&child')) {
        this.hotelCriteria.child = this.hotelCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = childParams + this.AGE_SEPERATOR + this.hotelCriteria.child;
      } else {
        this.hotelCriteria.child = this.hotelCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = '&child=' + this.hotelCriteria.child;
      }
    }
    if (this.hotelCriteria.infant && this.hotelCriteria.infant.length > 0) {
      // this.hotelCriteria.infant = this.surfCriteriaUtil.replaceDOB(this.hotelCriteria.infant, this.hotelCriteria.startDate, '', TravellerType.INFANT);
      // this.hotelCriteria.infant = this.hotelCriteria.infant.replace(/DOB/g, ''); // removing DOB from infant
      childParams += '&infant=' + this.hotelCriteria.infant;
    }

    // get hotel related URL params
    let queryStr;
    if (this.isAmendmentFlow && this.hotelCriteria.destinationType.toString() === 'HOTEL') {
      if (this.hotelCriteria.hotelCode) {
        queryStr = '&adult=' + this.hotelCriteria.adult + childParams + '&startDate=' + this.hotelCriteria.startDate
          + '&nights=' + this.hotelCriteria.nights + '&destinationType=' + this.hotelCriteria.destinationType
          + (this.hotelCriteria.destinationCode ? '&destinationCode=' + this.hotelCriteria.destinationCode : '')
          + (this.hotelCriteria.hotelCode ? '&hotelCode=' + this.hotelCriteria.hotelCode : '')
          + '&isRoomWise=' + this.roomWiseSelected;
      } else {
        queryStr = '&adult=' + this.hotelCriteria.adult + childParams + '&startDate=' + this.hotelCriteria.startDate
          + '&nights=' + this.hotelCriteria.nights + '&destinationType=' + this.hotelCriteria.destinationType
          + (this.hotelCriteria.destinationCode ? '&hotelCode=' + this.hotelCriteria.destinationCode : '')
          + '&isRoomWise=' + this.roomWiseSelected;
      }
    } else {
      queryStr = '&adult=' + this.hotelCriteria.adult + childParams + '&startDate=' + this.hotelCriteria.startDate
        + '&nights=' + this.hotelCriteria.nights + '&destinationType=' + this.hotelCriteria.destinationType
        + (this.hotelCriteria.destinationCode ? '&destinationCode=' + this.hotelCriteria.destinationCode : '')
        + (this.hotelCriteria.hotelCode ? '&hotelCode=' + this.hotelCriteria.hotelCode : '')
        + '&isRoomWise=' + this.roomWiseSelected;
    }
    if (this.cacheCity) {
      queryStr += '&city=' + this.cacheCity;
    } else if (this.cacheCountry) {
      queryStr += '&country=' + this.cacheCountry;
    }
    // if (this.hotelCriteria.destinationParentCode) {
    //   queryStr += '&dp=' + this.hotelCriteria.destinationParentCode;
    // }

    queryStr += '&expand=' + this.HTL_SEARCH_EXPAND_RESULT;
    queryStr += '&enableSourceSummaryCache=' + this.HTL_SEARCH_ENABLE_SOURCE_SUMMARY_CACHE;
    if (this.isAmendmentFlow) {
      queryStr += '&bkgId=' + this.bookingId;
      queryStr += '&productKey=' + this.productKey;
      queryStr += '&reason=' + this.amendmentReasonCode;
      queryStr += '&cause=' + this.amendmentCauseCode;
      queryStr += '&isAmendment=true';
    }

    // Concatenate Travellers interest into Hotel search query
    if (this.REC_ENABLE_FOR_HOTEL_SEARCH && this.enableRecommendations) {
      const travellerInterests = this.getTravellerInterestsAsString();
      if (travellerInterests && travellerInterests.length > 0) {
        queryStr += '&travellerInterests=' + travellerInterests;
      }
    }

    if (this.isSelectGeoLocation && this.geoPlaceDetails) {

      if (this.geoPlaceDetails.geometry && this.geoPlaceDetails.geometry.location && this.geoPlaceDetails.geometry.viewport) {
        if(this.GEO_ENABLE_LOCATION_OVERRIDE_SETUP) {
          if (this.tbxResultMap.has(this.hotelCriteria.destinationCode)
            && detail.coordinates && !(detail.radius)
          ) {
            console.log("case1");
            queryStr += '&geometryType=CIRCLE_BY_BOUNDING_BOX';
            queryStr += ('&geometryCoordinates=' + detail.coordinates.longitude + '~' +
              detail.coordinates.latitude + ',' +
              this.geoPlaceDetails.geometry.viewport.northeast.lng + '~' + this.geoPlaceDetails.geometry.viewport.northeast.lat + ',' +
              this.geoPlaceDetails.geometry.viewport.southwest.lng + '~' + this.geoPlaceDetails.geometry.viewport.southwest.lat);
          } else if (this.tbxResultMap.has(this.hotelCriteria.destinationCode)
            && detail.radius && !(detail.coordinates)
          ) {
            console.log("case2");
            queryStr += '&geometryType=CIRCLE';
            queryStr += ('&geometryCoordinates=' + this.geoPlaceDetails.geometry.location.lng + '~' +
              this.geoPlaceDetails.geometry.location.lat + '&geometryRadius=' + detail.radius);
          } else if (this.tbxResultMap.has(this.hotelCriteria.destinationCode)
            && detail.radius && detail.coordinates
          ) {
            console.log("case3");
            queryStr += '&geometryType=CIRCLE';
            queryStr += ('&geometryCoordinates=' + detail.coordinates.longitude + '~' +
              detail.coordinates.latitude + '&geometryRadius=' + detail.radius);
          } else {
            queryStr += '&geometryType=CIRCLE_BY_BOUNDING_BOX';
            queryStr += ('&geometryCoordinates=' + this.geoPlaceDetails.geometry.location.lng + '~' +
              this.geoPlaceDetails.geometry.location.lat + ',' +
              this.geoPlaceDetails.geometry.viewport.northeast.lng + '~' + this.geoPlaceDetails.geometry.viewport.northeast.lat + ',' +
              this.geoPlaceDetails.geometry.viewport.southwest.lng + '~' + this.geoPlaceDetails.geometry.viewport.southwest.lat);
          }
        }
       else{
          console.log("case4");
          queryStr += '&geometryType=CIRCLE_BY_BOUNDING_BOX';
          queryStr += ('&geometryCoordinates=' + this.geoPlaceDetails.geometry.location.lng + '~' +
          this.geoPlaceDetails.geometry.location.lat + ',' +
          this.geoPlaceDetails.geometry.viewport.northeast.lng + '~' + this.geoPlaceDetails.geometry.viewport.northeast.lat + ',' +
          this.geoPlaceDetails.geometry.viewport.southwest.lng + '~' + this.geoPlaceDetails.geometry.viewport.southwest.lat);
          }
      }
      if (this.hotelCriteria.geoLocationType) {
        queryStr += '&geoLocationType=' + this.hotelCriteria.geoLocationType;
      }
      if (this.geoPlaceDetails.name) {
        queryStr += '&geoLocationName=' + this.geoPlaceDetails.name;
        if(this.hotelCriteria.destinationCode && this.GEO_ENABLE_LOCATION_OVERRIDE_SETUP) {
          if (this.tbxResultMap.has(this.hotelCriteria.destinationCode)
            && detail.coordinates && !(detail.radius)) {
            queryStr += ('&attributes=GEO_PLACE_ID~' + this.hotelCriteria.destinationCode + ',GEO_PLACE_NAME~' + this.geoPlaceDetails.name +
              ',GEO_OVERRIDDEN_TYPE~LOCATION');
          }
          else if (this.tbxResultMap.has(this.hotelCriteria.destinationCode)
            && detail.radius && !(detail.coordinates)) {
            queryStr += ('&attributes=GEO_PLACE_ID~' + this.hotelCriteria.destinationCode + ',GEO_PLACE_NAME~' + this.geoPlaceDetails.name +
              ',GEO_OVERRIDDEN_TYPE~RADIUS');
          }
          else if (this.tbxResultMap.has(this.hotelCriteria.destinationCode)
            && detail.radius && detail.coordinates) {
            queryStr += ('&attributes=GEO_PLACE_ID~' + this.hotelCriteria.destinationCode + ',GEO_PLACE_NAME~' + this.geoPlaceDetails.name +
              ',GEO_OVERRIDDEN_TYPE~LOCATION_RADIUS');
          }
        }
        else if(this.hotelCriteria.destinationCode){
          queryStr += ('&attributes=GEO_PLACE_ID~' + this.hotelCriteria.destinationCode + ',GEO_PLACE_NAME~' + this.geoPlaceDetails.name );
        }
      }
      // if (this.hotelCriteria.geoLocationType) {
      //   queryStr += '&geoLocationType=' + this.hotelCriteria.geoLocationType;
      // }
    }
    if (this.isCartFromBooking) {
      queryStr += '&isCartFromBooking=true';
    }

    return queryStr;
  }

  getOverriddenDatafromTBX(placeId: string){

    const detail = this.tbxResultMap.get(placeId);
    return detail;

  }

  /**
   * get selected duration and update base criteria
   * @param $event
   */
  getSelectedDuration($event) {
    // console.log('[ get selected dates ]', $event);
    const duration = $event;
    if (duration > 0 ) {
      this.hotelCriteria.nights = duration - 1 ;
    } else {
      this.hotelCriteria.nights = 0;
    }
    if (this.hotelCriteria.productCode == undefined) {
      this.hotelCriteria.productCode = 'HTL';
    }
    this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');

    if (this.isDPSearch) {
      if (this.hotelCriteria.startDate != undefined) {
        let value = this.hotelCriteria.startDate + '~' + this.hotelCriteria.nights + '~';
        const toDate = new Date(this.hotelCriteria.startDate);
        toDate.setDate(toDate.getDate() + this.hotelCriteria.nights);
        value = value + this.surfCriteriaUtil.convertDateObjToStr(toDate, true);

        const changeObj = {
          index: this.index,
          productCode: 'HTL',
          changeType: 'DATE',
          value
        };
        this.commonService.updateChangeItem(changeObj);

        this.validateInputsForDP();
      }
    }
    if (this.hotelCriteria && this.hotelCriteria.startDate) {
      this.travellerEarliestDepartureDateService
        .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.hotelCriteria.startDate);
      if (this.isAmendmentFlow) {
        this.doCheckLastMinuteBookingDate(true);
      }
    }
    this.getCurrentHotelCriteria.emit(this.hotelCriteria);
  }

  /**
   * get all dates emitted by the datepicker component
   * update base criteria start date with the first value of the array
   * @param $event
   */
  getSelectedDateRange($event) {
    // console.log(this.convertDateObjToStr($event[0]));
    if ($event && $event[0] ) {
      this.hotelCriteria.startDate  = this.surfCriteriaUtil.convertDateObjToStr($event[0], true);

      // Set the date gap with the previous item
      const newDate = new Date(this.hotelCriteria.startDate);
      if (this.dpPreItemToDate) {
        const diffTime = Math.abs(newDate.getTime() - this.dpPreItemToDate.getTime());
        this.preItemDayGap = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }

      if (this.hotelCriteria.productCode == undefined) {
        this.hotelCriteria.productCode = 'HTL';
      }
      if (!(this.isAmendmentFlow && this.isDPSearch)) {
        this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
      }
      if (this.isDPSearch) {
        /*NEW IMPL - notify the change
        * change value : for DATE ->  STARTDATE~ENDDATE*/
        if (this.hotelCriteria.startDate != undefined) {
          let value = this.hotelCriteria.startDate + '~' + this.hotelCriteria.nights + '~';
          const toDate = new Date(this.hotelCriteria.startDate);
          toDate.setDate(toDate.getDate() + this.hotelCriteria.nights);
          value = value + this.surfCriteriaUtil.convertDateObjToStr(toDate, true);
          let amendmentTypeString = '';
          if (this.isAmendmentFlow && this.previousDateSet.length > 0) {
              const previousStartDate = new Date(this.previousDateSet[0]);
              const previousEndDate = new Date(this.previousDateSet[(this.previousDateSet.length - 1)]);
              if (!(previousStartDate.getFullYear() === $event[0].getFullYear() && previousStartDate.getMonth() === $event[0].getMonth()
                && previousStartDate.getDate() === $event[0].getDate())) {
                amendmentTypeString = amendmentTypeString + 'START_DATE';
              }
              if (!(previousEndDate.getFullYear() === $event[($event.length - 1)].getFullYear()
              && previousEndDate.getMonth() === $event[($event.length - 1)].getMonth()
              && previousEndDate.getDate() === $event[($event.length - 1)].getDate())) {
              amendmentTypeString = amendmentTypeString + '~END_DATE';
            }
          }

          const changeObj = {
            index : this.index,
            productCode : 'HTL',
            changeType : 'DATE',
            amendmentChangeType : amendmentTypeString,
            value
          };
          this.commonService.updateChangeItem(changeObj);
        }
        this.validateInputsForDP();
      }
      this.previousDateSet = JSON.parse(JSON.stringify($event));
      this.getCurrentHotelCriteria.emit(this.hotelCriteria);
    } else {
      this.hotelCriteria.startDate = null;
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkDateChange();
    }
    if (this.hotelCriteria && this.hotelCriteria.startDate) {
      if (this.isAmendmentFlow) {
        this.doCheckLastMinuteBookingDate(true);
      }
      this.travellerEarliestDepartureDateService
        .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.hotelCriteria.startDate);
    }
  }

  isRoomWiseSelected(event) {
    this.roomWiseSelected = event;
    if (!this.roomWiseSelected) {
      this.defaultPaxSet = [new PaxAllocationItem(2, [], [], [], [])];
    } /*else {
      this.defaultPaxSet = this.selectedPax;
    }*/
  }

  scrollTo(selector) {
    if (!this.isDPSearch) {
      if (this.windowScrollEnable) {
        SurfScrollDirective.scrollTo(
          selector,
          null,
          false,
          false,
          0,
          60
        );
      }
    }
  }

  getPassengerSelection($event: Array<PaxAllocationItem>) {
    if ($event != null && this.validateAdultsAges($event) && this.validateChildAges($event) && this.validateTeenAges($event)) {
      this.hotelCriteria.adult = this.processAdultPaxAllocation($event);
      const children = this.processChildAllocation($event);
      const teens = this.processTeenAllocation($event);
      const infant = this.processInfantAllocation($event);

      if (children.CHD && children.CHD.length > 0) {
        this.hotelCriteria.child = children.CHD;
      } else {
        this.hotelCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.hotelCriteria.teen = teens;
      } else {
        this.hotelCriteria.teen = null;
      }
      this.hotelCriteria.infant = infant && infant.length > 0 ? infant : null;

      this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
    } else {
      this.hotelCriteria.adult = null;
      this.hotelCriteria.child = null;
      this.hotelCriteria.teen = null;
      this.hotelCriteria.infant = null;
      this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
    }

  }
  /**
   * check for adult
   * @param selectedPax
   */
  validateAdultsAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => (acc && pax.adult > 0), true);
  }

  /**
   * check for invalid child ages
   * @param selectedPax
   */
  validateChildAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => acc && ( pax.child.indexOf(-1) > -1 ? false : true ), true);
  }
  /**
   * check for invalid teen ages
   * @param selectedPax
   */
  validateTeenAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => acc && ( pax.teen.indexOf(-1) > -1 ? false : true ), true);
  }
  processAdultPaxAllocation(paxArray: Array<PaxAllocationItem>) {

    const defaultAdultAge =  this.DEFAULT_ADULT_AGE;
    const fieldSeperator = '~';
    const roomSeperator = '__';

    let paxStr = '';

    for (let i = 0 ; i < paxArray.length ; i++) {
      const paxItem = paxArray[i];
      if (i != 0) {
        if (this.roomWiseSelected) {
          paxStr = paxStr + roomSeperator;
        }
      }
      // paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + this.convertDateObjToStr(this.getDOB(defaultAdultAge)) + fieldSeperator +  (i + 1);
      paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + 'DOB';
      if (this.roomWiseSelected) {
        paxStr = paxStr + fieldSeperator + (i + 1);
      }
    }
    return paxStr;
  }

  processChildAllocation(paxArray: Array<PaxAllocationItem>) {

    const children = {
      INF: '',
      CHD: '',
    };

    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.child.length > 0) {

        const childAgeMap = {};
        const childOrder = [];
        paxRoom.child.forEach(childAge => {
          if (childOrder.findIndex(age => age === childAge) < 0) {
            childOrder.push(childAge);
          }
        });
        paxRoom.child.forEach(childAge => {
          childAgeMap[childAge] ? childAgeMap[childAge]++ : childAgeMap[childAge] = 1;
        });

        for (const age of childOrder) {
          const key = age + '';
          const count = childAgeMap[key] + '';
          /* TODO update this logic based on guest age setup */
          if (key.trim() == this.LESS_THAN_ONE || key.trim() == '1') {

            const infantAge = (key.trim() == this.LESS_THAN_ONE) ? 0 : 1;

            /*Infants */
            // children.INF += count + fieldSeperator + 0 + fieldSeperator + this.convertDateObjToStr(this.getDOB(0)) + fieldSeperator + roomNo + ageSeperator;
            children.INF += count + this.FIELD_SEPERATOR + infantAge + this.FIELD_SEPERATOR + 'DOB';
            if (this.roomWiseSelected) {
              children.INF += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
            } else {
              children.INF += this.AGE_SEPERATOR;
            }
          } else {
           /*child*/
            // children.CHD += count + fieldSeperator + key + fieldSeperator + this.convertDateObjToStr(this.getDOB(key)) + fieldSeperator + roomNo + ageSeperator;
            children.CHD += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
            if (this.roomWiseSelected) {
              children.CHD += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
            } else {
              children.CHD += this.AGE_SEPERATOR;
            }
          }
        }
      }
      roomNo++;
    });

    if (children.INF.length > 2) {
      if (this.roomWiseSelected) {
        children.INF = children.INF.slice(0, children.INF.length - 2);
      } else {
        children.INF = children.INF.slice(0, children.INF.length - 2);
      }
    }
    if (children.CHD.length > 2) {
      if (this.roomWiseSelected) {
         children.CHD = children.CHD.slice(0, children.CHD.length - 2);
      } else {
        children.CHD = children.CHD.slice(0, children.CHD.length - 2);
      }
    }

    return children;
  }

  processTeenAllocation(paxArray: Array<PaxAllocationItem>) {

    let teens = '';
    // teens
    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.teen.length > 0) {
        const teenAgeMap = {};
        const teenOrder = [];
        paxRoom.teen.forEach(teenAge => {
          if (teenOrder.findIndex(age => age === teenAge) < 0) {
            teenOrder.push(teenAge);
          }
        });
        paxRoom.teen.forEach(teenAge => {
          teenAgeMap[teenAge] ? teenAgeMap[teenAge]++ : teenAgeMap[teenAge] = 1;
        });
        for (const age of teenOrder) {
          const key = age + '';
          const count = teenAgeMap[key] + '';
          /* TODO update this logic based on guest age setup */
          /*teens*/
          teens += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
          if (this.roomWiseSelected) {
            teens += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          } else {
            teens += this.AGE_SEPERATOR;
          }
        }
      }
      roomNo++;
    });

    if (teens.length > 2) {
      if (this.roomWiseSelected) {
        teens = teens.slice(0, teens.length - 2);
      } else {
        teens = teens.slice(0, teens.length - 2);
      }
    }
    return teens;
  }
  processInfantAllocation(paxArray: Array<PaxAllocationItem>) {

    let roomNo = 1;
    let inf = '';
    if (this.roomWiseSelected) {
      paxArray.forEach(paxRoom => {
        inf += (paxRoom.infant.length) + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR ;
        roomNo ++;
      });
    } else {
      inf = (paxArray[0].infant.length) + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR ;
    }
    return inf;
  }


  /**
   * Upon clicking the reset button, all the input fields and corresponding
   * values in the criteria object will be reset
   */
  resetInputs(isRefine?: boolean) {
    if (!isRefine) {
      this.selectedDest.displayText = '';
      this.hotelCriteria.destinationType = null;
      this.hotelCriteria.destinationCode = null;
      this.hotelCriteria.hotelCode = null;
      this.hotelCriteria.destinationName = null;
      this.hotelCriteria.destinationParentCode = null;
      this.hotelCriteria.adult = '';
      this.hotelCriteria.child = '';
      this.hotelCriteria.teen = '';
      // this.setSelectedDatesDefault();
      this.hotelCriteria.startDate = null;
      this.hotelCriteria.nights = 0;
      this.initialDates = [];
      this.cacheCity = null;
      this.cacheCountry = null;
      this.selectedTravellerInterestsKeys = [];
      this.resetDisplayItems();
      this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
    } else {
      this.cancelRefineSearch();
    }
    if (this.isServiceAddComponent) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }
  }

  resetDisplayItems() {
    this.resetCalendar = true;
    this.setRoomWise = true;
    setTimeout(() => {
      this.resetCalendar = false;
      this.setRoomWise = false;
      this.defaultPaxSet = [];
    }, 0);
  }

  // /**
  //  * set selected dates to initial dates
  //  */
  // setSelectedDatesDefault() {
  //   if (this.initialDates && this.initialDates.length > 0 ) {
  //     this.hotelCriteria.startDate = this.surfCriteriaUtil.convertDateObjToStr(this.initialDates[0]);
  //     this.hotelCriteria.nights = this.INITIAL_DURATION - 1;
  //   } else {
  //     this.hotelCriteria.startDate = null;
  //     this.hotelCriteria.nights = 0;
  //   }
  // }

  onClickInsideTypeAhead(event) {
    this.geoPlaceDetailsError = '';
    const hotelCri = this.isDPSearch ? this.productCriteriaList[this.surfCriteriaUtil.getCriteriaItemArrayIndex(this.productCriteriaList, 'HTL', this.index)] : this.hotelCriteria;

    if (hotelCri) {
      if (hotelCri.destinationType == DestinationType.CITY && hotelCri.destinationCode) {
        this.selectedDest.displayText = hotelCri.destinationName.trim();
      } else if (hotelCri.destinationType == DestinationType.HOTEL) {
        this.selectedDest.displayText = this.surfTextCasePipe.transform(hotelCri.destinationName, 'title').trim();
      }
    }
  }

  onClickOutsideTypeAhead(event) {

    const hotelCri = this.isDPSearch ? this.productCriteriaList[this.surfCriteriaUtil.getCriteriaItemArrayIndex(this.productCriteriaList, 'HTL', this.index)] : this.hotelCriteria;

    if (hotelCri) {
      if (hotelCri.destinationType == DestinationType.CITY && hotelCri.destinationCode) {
        if (this.isRefine && !hotelCri.destinationParentCode) {
          const city = this.destinations.filter(des => des.code == hotelCri.destinationCode);
          if (city && city.length > 0) {
            hotelCri.destinationParentCode = this.surfCriteriaUtil.getCountry(city[0]);
          }
        }
        this.selectedDest.displayText = hotelCri.destinationName.trim() + (hotelCri.destinationParentCode ? ', ' + hotelCri.destinationParentCode : '');
      } else if (hotelCri.destinationType == DestinationType.HOTEL) {
        this.selectedDest.displayText = this.surfTextCasePipe.transform(hotelCri.destinationName, 'title').trim() + (hotelCri.destinationParentCode ? ', ' + hotelCri.destinationParentCode : '');
      }
      if (this.isRefine && hotelCri.destinationType == DestinationType.COUNTRY) {
        this.selectedDest.displayText = this.surfTextCasePipe.transform(hotelCri.destinationName, 'title').trim();
      }
      if (this.isRefine && hotelCri.destinationType === DestinationType.GEO_LOCATION) {
        this.selectedDest.displayText = this.surfTextCasePipe.transform(hotelCri.destinationName, 'title').trim();
      }
    }
    if (this.isDPSearch) {
      this.validateInputsForDP();
    }

    /*if dpSearch=true, fill dates if they are available*/
    // if (this.isDPSearch && hotelCri && hotelCri.startDate && hotelCri.nights) {
    //   this.setInitialDates(hotelCri.startDate, hotelCri.nights);
    // }
  }

  /**
   * handle focusout
   */
  onFocusOut($event, component) {
    if (component === 1) {
      this.guestComponentFocus = false;
    } else if (component === 2) {
      this.datePickerFocus = false;
    }
  }

  /**
   * handle key down
   */
  onKeyDown($event, component) {
    if ($event.keyCode === KeyCodes.ENTER ) {
      console.log(this.selectedDest);
      if (component === 0) {
        setTimeout(() => {
          this.datePickerFocus = true;
          this.guestComponentFocus = false;
          this.focusToDestination = false;
        }, 200);
      } else if (component === 1) {
        if (this.currentPaxOption === 3) {
          if (this.enterPressed && (this.hotelCriteria.nights === undefined || this.hotelCriteria.nights === 0)) {
            setTimeout(() => {
              this.guestComponentFocus = false;
              this.datePickerFocus = false;
              this.focusToDestination = false;
            }, 200);
          }
          this.enterPressed = true;
        }
        if (this.currentPaxOption !== 3 && (this.hotelCriteria.nights === undefined || this.hotelCriteria.nights === 0)) {
          setTimeout(() => {
            this.guestComponentFocus = false;
            this.datePickerFocus = false;
            this.focusToDestination = false;
          }, 200);
          this.enterPressed = false;
        }
      } else if (component === 2) {
        setTimeout(() => {
          this.guestComponentFocus = false;
          this.datePickerFocus = false;
          this.focusToDestination = false;
          this.onKeyDownLast.emit(true);
        }, 200);
      }
    } else {
      this.guestComponentFocus = false;
      this.datePickerFocus = false;
      this.focusToDestination = false;
    }

    if ($event.keyCode === KeyCodes.UPARROW || $event.keyCode === KeyCodes.DOWNARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = false;
      }
    }

    if ($event.keyCode === KeyCodes.RIGHTARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = true;
      }
    }
  }

  cancelRefineSearch() {
    this.cancelRefine.emit(this.isRefine);
    this.resetToOriginalRefineCriteria();
  }

  resetToOriginalRefineCriteria() {
    if (this.originalRefineCriteria) {
      this.defaultPaxSet = this.selectedPax;
      this.setInitialDates(this.originalRefineCriteria.startDate, this.originalRefineCriteria.nights);
      this.cacheCity = this.originalRefineCriteria.city;
      this.cacheCountry = this.originalRefineCriteria.country;
      if (this.originalRefineCriteria.destinationType == DestinationType.CITY && this.originalRefineCriteria.destinationCode) {
        const city = this.destinations.filter(des => des.code == this.originalRefineCriteria.destinationCode);
        const parent = this.surfCriteriaUtil.getCountry(city[0]);
        this.hotelCriteria.destinationParentCode = parent;
        this.commonService.updateComponentCriteria(this.hotelCriteria, 'HTL');
        this.selectedDest.displayText = this.hotelCriteria.destinationName.trim() + ', ' + parent;
      }
      if (this.originalRefineCriteria.destinationType == DestinationType.COUNTRY) {
        this.selectedDest.displayText = this.surfTextCasePipe.transform(this.hotelCriteria.destinationName, 'title').trim();
      }
      if (this.originalRefineTravellerInterestsKeys) {
        this.selectedTravellerInterestsKeys = this.originalRefineTravellerInterestsKeys;
      }
    }
  }

  getCurrentOption($event) {
    this.currentPaxOption = $event;
  }

  generateDisplayString(adult, child, infant, teen) {

    // should handle
    let str = '';

    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    console.log(str);
    return str;
  }

  /**
   * handle freeze-unfreeze checkbox
   */
  checkValue(event) {
    this.unfreeze = event.target.checked;
    this.freeze = !this.unfreeze;
    this.isUnfreeze.emit(event.target.checked);
  }

  /**
   * set warning true and false with the destination input change, by comparing with the initial value.
   */
  checkDestinationChange(data) {
    let destinationMatch = false;
    if (data.name && this.oldCriteriaItem && this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.destinationName) {
      if (data.name.toUpperCase() === this.oldCriteriaItem.criItem.destinationName.toUpperCase()) {
        if (data.type === 'CITY' || data.type === 40) {
          if (this.surfCriteriaUtil.getCountry(data) === this.oldCriteriaItem.criItem.destinationParentCode) {
            destinationMatch = true;
          }
        } else if (data.type === 80) {
          if (this.surfCriteriaUtil.getCity(data).name + (this.surfCriteriaUtil.getCountry(data) ? ', ' +
            this.surfCriteriaUtil.getCountry(data) : '') === this.oldCriteriaItem.criItem.destinationParentCode) {
            destinationMatch = true;
          }
        } else {
          destinationMatch = true;
        }
      }
      this.destinationWarning = !destinationMatch;
    } else if (data.destinationName && this.oldCriteriaItem && this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.destinationName) {
      if (data.destinationName === this.oldCriteriaItem.criItem.destinationName) {
        this.destinationWarning = false;
      } else {
        this.destinationWarning = true;
      }
    }
  }

  /**
   * set warning true and false with the CheckIn/CheckOut input change, by comparing with the initial value.
   */
  checkDateChange() {
    if (this.hotelCriteria && this.hotelCriteria.startDate && this.oldCriteriaItem && this.oldCriteriaItem.criItem &&
      this.oldCriteriaItem.criItem.startDate) {

      const oldCheckoutDate = new Date(this.oldCriteriaItem.criItem.startDate);
      oldCheckoutDate.setDate(oldCheckoutDate.getDate() + this.oldCriteriaItem.criItem.nights);

      const newCheckoutDate = new Date(this.hotelCriteria.startDate);
      newCheckoutDate.setDate(newCheckoutDate.getDate() + this.hotelCriteria.nights);
      if (this.isAmendmentFlow) {
        this.doCheckLastMinuteBookingDate(false);
      }
      if (this.hotelCriteria.startDate.split('T')[0] === this.oldCriteriaItem.criItem.startDate.split('T')[0]) {
        this.checkInWarning = false;
        this.checkOutWarning = this.hotelCriteria.nights !== this.oldCriteriaItem.criItem.nights;
      } else {
        this.checkInWarning = true;
        this.checkOutWarning = newCheckoutDate.toDateString() !== oldCheckoutDate.toDateString();
      }
    }
  }

  doCheckLastMinuteBookingDate(isAmendmentOldItemDateSelection: boolean) {
    const checkingDate = new Date(this.hotelCriteria.startDate);
    if (this.surfCalendarStartDateService.validateWithCheckingIsEarlierDate(new Date(), checkingDate, 'HTL')) {
      this.hideErrorMsg = true;
      this.validateDisabledDateSelection = false;
      this.surfCalendarStartDateService.lastMinuteBookingErrorSet.delete(this.index);
      if (isAmendmentOldItemDateSelection) {
        this.surfCalendarStartDateService.hasValidUnfreezeItem = true;
      }
    } else {
      if (isAmendmentOldItemDateSelection) {
        this.surfCalendarStartDateService.hasValidUnfreezeItem = false;
      }
      this.surfCalendarStartDateService.lastMinuteBookingErrorSet.add(this.index);
      this.hideErrorMsg = false;
      this.validateDisabledDateSelection = true;
    }
  }

  /**
   * get comma seperated traveller interests key from selected traveller interests
   */
  getTravellerInterestsAsString(): string {
    let travellerInterestString = '';
    this.selectedTravellerInterests.forEach(travellerInterest => {
      travellerInterestString += travellerInterest.key + ',';
    });

    if (travellerInterestString.length > 1) {
      return travellerInterestString.substring(0, travellerInterestString.length - 1);
    }

    return travellerInterestString;
  }
}
