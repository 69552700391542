import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  SessionStorage,
  SURF_PROVIDERS,
  SurfComponentsCoreModule,
  SurfHealthCheckService, SurfLoggerService,
  SurfServiceModule, SurfUtf8StringCheckerUtil,
  SurfUtilService, SurfPaxStringService,
  SurfAuthorizationService
} from '@surf/surf-components-core';
import { SurfFooterModule } from '@surf/surf-footer';
import { SurfToasterNotificationModule } from '@surf/surf-toaster-notification';
import { SurfHeaderModule } from '@surf/surf-header';
import { SurfLinkingItinerariesModule } from '@surf/surf-linking-itineraries';
import {SurfButtonSwitchModule} from '@surf/surf-button-switch';
import { SurfMessageBoxModule } from '@surf/surf-message-box';
import { SurfPaginationModule } from '@surf/surf-pagination';
import { RootService } from '@tc-core/service';
import { APIConfigLoaderService } from '@tc-core/service/api';
import { DataLoader } from '@tc-core/service/api/data-loader.service';
import { DataLoaderV2 } from '@tc-core/service/api/data-loader-v2.service';
import { BookingHelperService } from '@tc-core/service/helpers/booking';
import { CardDataHandlerService } from '@tc-core/service/helpers/card-data';
import { CartHelperService } from '@tc-core/service/helpers/cart';
import { EtagHelperService } from '@tc-core/service/helpers/common/etag-helper-service';
import { CookieService } from '@tc-core/service/helpers/cookie';
import { CriteriaHelperService } from '@tc-core/service/helpers/criteria/criteria-helper.service';
import { LocalStorageService } from '@tc-core/service/helpers/local-storage';
import { LoggerService } from '@tc-core/service/helpers/logging';
import { TravellerService } from '@tc-core/service/helpers/traveller';
import { UdaHelperService } from '@tc-core/service/helpers/uda';
import {ObjectPathUtil} from '@tc-core/util/helpers/object-path-util.service';
import {LocalStorageHandler} from '@tc-core/util/framework/local-storage-handler';
import { SurfRedirectEventStatusBarModule } from '@surf/surf-redirect-event-status-bar';
import {
  AccomAvailabilityServiceHandler,
  AccountsServiceHandlerService,
  BookingServiceHandlerService,
  CarSearchServiceHandler,
  CartServiceHandler,
  ClientSearchServiceHandler,
  DocumentsServiceHandlerService,
  DPSearchServiceHandler,
  FilterInputServiceHandlerService,
  FlightSearchServiceHandler,
  GatewayServiceHandlerService,
  GenericSearchServiceHandler,
  HotelSearchServiceHandler,
  InsuranceSearchServiceHandler,
  MiscellaneousServiceHandler,
  OperationsServiceHandler,
  PackageBuilderServiceHandlerService,
  PackageSearchServiceHandler,
  PaymentServiceHandlerService,
  PersonalizationHandler,
  QueueManagerServiceHandlerService,
  ReservationServiceHandler,
  ServiceHandler,
  SupplierServiceHandler,
  TransferSearchServiceHandler,
  UserServiceHandlerService
} from '@tc-core/service/service-handlers';
import { AdminServiceHandler } from '@tc-core/service/service-handlers/admin-service-handler.service';
import { DataServiceHandler } from '@tc-core/service/service-handlers/data-service-handler.service';
import { LoyaltyServiceHandlerService } from '@tc-core/service/service-handlers/loyalty-service-handler.service';
import { AlphaNumericSorting } from '@tc-core/service/sorters/sorter.service';
import { Common } from '@tc-core/util/common';
import {
  ActionHandlerService,
  DataKey,
  DataStore,
  LogStore,
  RouteManager,
  UserJourneyManager
} from '@tc-core/util/framework';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { ErrorProcessor } from '@tc-core/util/framework/error-processor.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { QueryParamsService } from '@tc-core/util/framework/query-params.service';
import { CommonHelper } from '@tc-core/util/helpers';
import { AuthorizationService } from '@tc-core/util/security';
import { DateFormatter } from '@tc-core/util/system';
import { BaseService } from '@tc-core/util/system/base.service';
import { FileUploadService } from '@tc-core/util/system/file-upload.service';
import { HeaderService } from '@tc-core/util/system/header.service';
import { JsonUtilService } from '@tc-core/util/system/json-util.service';
import { KebabService } from '@tc-core/util/ui';
import { WindowRef } from '@tc-core/util/ui/windowref.service';
import { CookieService as NGXCoockieServive } from 'ngx-cookie-service';
import { SurfAddManualItemModule } from '@surf/surf-add-manual-item';
import {SurfCancellationPolicyOverrideModule} from '@surf/surf-cancellation-policy-override';
import { SurfBookingSummaryModule } from '../../projects/surf-booking-summary/src/lib/surf-booking-summary.module';
import { SurfCarCriteriaModule } from '../../projects/surf-car-criteria/src/lib/surf-car-criteria.module';
import { SurfDPAddBarModule } from '../../projects/surf-dp-add-bar/src/lib/surf-dp-add-bar.module';
import { SurfDPCriteriaModule } from '../../projects/surf-dp-criteria/src/lib/surf-dp-criteria.module';
import { SurfFlightCriteriaModule } from '../../projects/surf-flight-criteria/src/lib/surf-flight-criteria.module';
import { SurfGenericCriteriaModule } from '../../projects/surf-generic-criteria/src/lib/surf-generic-criteria.module';
import { SurfGrabTourModule } from '../../projects/surf-grab-tour/src/lib/surf-grab-tour.module';
import { SurfHotelCriteriaModule } from '../../projects/surf-hotel-criteria/src/lib/surf-hotel-criteria.module';
import { SurfCruiseCriteriaModule } from '../../projects/surf-cruise-criteria/src/lib/surf-cruise-criteria.module';
import { SurfPackageCriteriaModule } from '../../projects/surf-package-criteria/src/lib/surf-package-criteria.module';
import { SurfSearchPanelModule } from '../../projects/surf-search-panel/src/lib/surf-search-panel.module';
import { SurfTourCriteriaModule } from '../../projects/surf-tour-criteria/src/lib/surf-tour-criteria.module';
import { SurfTransferCriteriaModule } from '../../projects/surf-transfer-criteria/src/lib/surf-transfer-criteria.module';
import { SurfClientSearchModalModule } from '../../projects/surf-client-search-modal/src/lib/surf-client-search-modal.module';
import { environment } from '../environments/environment.app';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppService } from './app.service';
import { B2bDashboardInterceptor } from './b2b-dashboard-interceptor';
import { B2bDashboardComponent } from './modules/b2b-dashboard/b2b-dashboard.component';
import {TC} from '@tc-core/util';
import {SurfAddManualBookingItemModule} from '@surf/surf-add-manual-booking-item';
import {SurfModalModule} from '@surf/surf-modal';
import {SurfAddInsuranceModule} from '@surf/surf-add-insurance';
import {SurfSplitPaxModule} from '../../projects/surf-split-pax/src/lib/surf-split-pax.module';
import {SurfCancellationPoliciesModule} from '@surf/surf-cancellation-policies';
import {TourSearchServiceHandler} from '@tc-core/service/service-handlers/tour-search-service-handler.service';
import {
  AirlineEffects,
  AirportEffects, CabinClassEffects,
  CruiseDestinationEffects,
  CruiseLineEffects, DocumentTypeEffects,
  ElementGroupEffects, GdsEffects, H2HSystemEffects, PaxSubTypeAgeEffects,
  SurfStateManageModule, WSHolidayTypeEffects
} from '@surf/surf-state-manage';
import {EffectsModule} from '@ngrx/effects';

export function initialize(app: AppService) {
  return async () => {
    sessionStorage.removeItem(SessionStorage.CONVERSATION_ID);
    sessionStorage.removeItem(SessionStorage.RELEASE_VERSION);
    await app.preLoadConfig();
    await app.captureUserCookies();
    await app.checkValid();
    await app.loadConfigurations();
    await app.loadModuleConfigurations();
    await app.userLogin();
    await app.loadUserDetailFromAPI();
    app.loadFeatureConfigs();
    await app.loadClient();
    await app.loadEmuClient();
    await app.loadPaxTypeAges();
    await app.loadLoggerConfigs();
    await app.loadPermissions();
    await app.loadExternalScripts();
  };
}

export function handleLogin() {

}

export function baseServiceSwitcher(
  httpClient: HttpClient,
  authorizationService: AuthorizationService,
  userJourneyManager: UserJourneyManager,
  logStore: LogStore,
  dataStore: DataStore,
  commonHelper: CommonHelper
) {
  if (environment.mock) {

  } else {
    return new BaseService(httpClient, authorizationService, userJourneyManager, logStore, dataStore, commonHelper);
  }
}

export function errorProcessorFactory(
  configLoader: ConfigLoader,
  dataStore: DataStore,
  jsonUtilService: JsonUtilService,
  baseService: BaseService
) {
  if (environment.mock) {

  } else {
    return new ErrorProcessor(configLoader, dataStore, jsonUtilService, baseService);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    B2bDashboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    SurfServiceModule,
    HttpClientModule,
    SurfSearchPanelModule,
    SurfLinkingItinerariesModule,
    SurfHotelCriteriaModule,
    SurfCruiseCriteriaModule,
    SurfTourCriteriaModule,
    SurfFlightCriteriaModule,
    SurfPackageCriteriaModule,
    SurfCarCriteriaModule,
    SurfTransferCriteriaModule,
    SurfBookingSummaryModule,
    SurfHeaderModule,
    SurfFooterModule,
    SurfFooterModule,
    SurfDPAddBarModule,
    SurfPaginationModule,
    SurfDPCriteriaModule,
    SurfGenericCriteriaModule,
    SurfComponentsCoreModule,
    SurfMessageBoxModule,
    SurfAddManualItemModule,
    SurfClientSearchModalModule,
    SurfModalModule,
    SurfAddManualItemModule,
    SurfAddManualBookingItemModule,
    SurfToasterNotificationModule,
    SurfAddInsuranceModule,
    SurfButtonSwitchModule,
    SurfSplitPaxModule,
    SurfCancellationPoliciesModule,
    SurfCancellationPolicyOverrideModule,
    SurfRedirectEventStatusBarModule,
    SurfGrabTourModule,
    SurfStateManageModule,
    EffectsModule.forRoot([AirportEffects, AirlineEffects, PaxSubTypeAgeEffects, CabinClassEffects, CruiseDestinationEffects,
      CruiseLineEffects, ElementGroupEffects, GdsEffects, WSHolidayTypeEffects, H2HSystemEffects, DocumentTypeEffects ])

  ],
  providers: [
    NGXCoockieServive,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: B2bDashboardInterceptor,
      multi: true
    },
    SURF_PROVIDERS,
    DataServiceHandler,
    DataLoader,
    DataLoaderV2,
    ConfigLoader,
    AppService,
    LogStore,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [AppService],
      multi: true
    },
    {
      provide: BaseService,
      deps: [HttpClient, AuthorizationService, UserJourneyManager, LogStore, DataStore, CommonHelper],
      useFactory: baseServiceSwitcher
    },
    {
      provide: ErrorProcessor,
      deps: [ConfigLoader, DataStore, JsonUtilService, BaseService],
      useFactory: errorProcessorFactory
    },
    DataStore,
    HeaderService,
    AlphaNumericSorting,
    QueryParamsService,
    AdminServiceHandler,
    ServiceHandler,
    CartServiceHandler,
    ClientSearchServiceHandler,
    MiscellaneousServiceHandler,
    CarSearchServiceHandler,
    FlightSearchServiceHandler,
    GenericSearchServiceHandler,
    HotelSearchServiceHandler,
    InsuranceSearchServiceHandler,
    PackageSearchServiceHandler,
    TransferSearchServiceHandler,
    PersonalizationHandler,
    BookingServiceHandlerService,
    OperationsServiceHandler,
    SupplierServiceHandler,
    AccomAvailabilityServiceHandler,
    PackageBuilderServiceHandlerService,
    PaymentServiceHandlerService,
    QueueManagerServiceHandlerService,
    AccountsServiceHandlerService,
    UserServiceHandlerService,
    RootService,
    RouteManager,
    GatewayServiceHandlerService,
    DocumentsServiceHandlerService,
    ReservationServiceHandler,
    DPSearchServiceHandler,
    CommonHelper,
    LocalStorageHandler,
    ObjectPathUtil,
    AuthorizationService,
    UserJourneyManager,
    EventManager,
    WindowRef,
    SurfUtilService,
    SurfHealthCheckService,
    FileUploadService,
    FilterInputServiceHandlerService,
    CriteriaHelperService,
    ActionHandlerService,
    BookingHelperService,
    CartHelperService,
    TravellerService,
    DateFormatter,
    CookieService,
    LocalStorageService,
    CardDataHandlerService,
    UdaHelperService,
    Common,
    KebabService,
    APIConfigLoaderService,
    JsonUtilService,
    LoyaltyServiceHandlerService,
    LoggerService,
    EtagHelperService,
    SurfLoggerService,
    SurfPaxStringService,
    SurfUtf8StringCheckerUtil,
    SurfAuthorizationService,
    TourSearchServiceHandler
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
