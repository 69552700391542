import {NgModule} from '@angular/core';
import {HotelCriteriaComponent} from './hotel-criteria/hotel-criteria.component';
import {CommonModule} from '@angular/common';
import {SurfTypeAheadModule} from '@surf/surf-type-ahead';
import {SurfInputModule} from '@surf/surf-input';
import {SurfDatePickerModule} from '@surf/surf-date-picker';
import {SurfCalendarModule} from '@surf/surf-calendar';
import {SurfPaxSelectionModule} from '@surf/surf-pax-selection';
import {SurfTextCasePipeModule, SurfComponentsCoreModule, SurfCriteriaUtil} from '@surf/surf-components-core';
import { HotelCriteriaDpComponent } from './hotel-criteria-dp/hotel-criteria-dp.component';
import {SurfDPAddBarModule} from '@surf/surf-dp-add-bar';
import {SurfMultiSelectorModule} from '@surf/surf-multi-selector';
import {SurfMultiSelectorDropDownModule} from "@surf/surf-multi-selector-drop-down";
import {SurfTravellerInterestsModule} from "@surf/surf-traveller-interests";
import {SurfToasterNotificationModule} from "@surf/surf-toaster-notification";

@NgModule({
  declarations: [HotelCriteriaComponent, HotelCriteriaDpComponent],
    imports: [
        CommonModule,
        SurfTypeAheadModule,
        SurfInputModule,
        SurfDatePickerModule,
        SurfCalendarModule,
        SurfTextCasePipeModule,
        SurfPaxSelectionModule,
        SurfComponentsCoreModule,
        SurfDPAddBarModule,
        SurfMultiSelectorModule,
        SurfMultiSelectorDropDownModule,
        SurfTravellerInterestsModule,
        SurfToasterNotificationModule
    ],
  exports: [HotelCriteriaComponent, HotelCriteriaDpComponent],
  providers:[SurfCriteriaUtil]
})
export class SurfHotelCriteriaModule { }
