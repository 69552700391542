import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  CALENDAR_VIEW,
  ConfigService,
  CruiseCriteria,
  DataShareService,
  DestinationType,
  KeyCodes,
  OntologyResponse,
  SurfCriteriaUtil, SurfErrorMessages,
  SurfScrollDirective,
  SurfTextCasePipe
} from '@surf/surf-components-core';
import {MasterDataStoreService, StoreConstants} from '@surf/surf-state-manage';
import {Subscription} from 'rxjs';
import {PaxAllocationItem} from '@surf/surf-pax-selection';
import {TC} from '@tc-core/util';
import {ConfigLoader} from '@tc-core/util/framework';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {MultiFieldInputData, MultiFieldInputPropertyFactory} from '@surf/surf-multi-field-input';
import {SurfCruiseCriteriaService} from '../surf-cruise-criteria.service';

@Component({
  selector: 'surf-cruise-criteria',
  templateUrl: './cruise-criteria.component.html'
})
export class CruiseCriteriaComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isDPSearch = false;
  @Input() index: number;
  @Input() isServiceAddComponent = false;
  @Input() isRefine = false;
  @Input() setRoomWise = false;
  @Input() windowScrollEnable = true;
  @Input() selectedPax: any;
  @Input() cartPaxSelection: any;
  @Input() isAmendmentFlow = false;
  @Input() isItemCloseable = true;
  @Input() triggerExternalSearch = '';
  @Input() cachingItemMap = new Map<string, number>();
  @Input() allowNgrxCaching = false;

  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter();
  @Output() getCurrentCruiseCriteria = new EventEmitter<CruiseCriteria>();

  /**
   * variables
   */
  message: boolean;
  paxDisplay: string;
  subscription = new Subscription();
  cruiseCriteria: CruiseCriteria = new CruiseCriteria();
  guestComponentFocus = false;
  currentPaxOption = 0;
  enterPressed = false;
  defaultPaxSet: Array<PaxAllocationItem> = [];
  TEEN_PAX_ACTIVE = true;
  DISABLE_DP_SWITCH = false;
  keyParams: string;
  durationOffset = '0';
  destinationWarning = false;   // todo: set destination warning
  depPortWarning = false;   // todo: set port warning
  cruiseLineWarning = false;   // todo: set cruiseLine warning
  shipWarning = false;   // todo: set ship warning
  destinationList = [];
  initialDestinationList = [];
  filteredPortList = [];
  cruiseLineList = [];
  initialCruiseLineList = [];
  shipNameList = [];
  initialShipNameList = [];
  filteredShipList;
  initialDates = [];
  resetCalendar = false;
  defaultActiveCalendar = CALENDAR_VIEW.SINGLE_YEAR_VIEW;
  isDateNotSelected = false;
  durationError = false;
  minDurationError = false;
  maxDurationError = false;
  durationOffsetError = false;
  durationProperty;
  durationValues;
  durationOffsetProperty;
  durationOffsetValues;
  minDurationProperty;
  minDurationValues;
  maxDurationProperty;
  maxDurationValues;
  selectedPort: OntologyResponse = new OntologyResponse();
  depPortUrl = '';
  MIN_SEARCH_QUERY_LENGTH = 3;
  destSearchInitiated = false;
  depPortPartiallyTyped = false;
  cacheDepCity: string;
  cacheDepCountry: string;
  textCasePipe: SurfTextCasePipe;
  @Input() validRange: any [];

  /**
   * Constants
   */
  initialShipName = 'Any ship';
  readonly NONE_SELECTED = '-1';
  FIELD_SEPERATOR = '~';
  AGE_SEPERATOR = '__';
  LESS_THAN_ONE = '< 1';
  DEFAULT_ADULT_AGE = 30;
  MAX_INFANT_AGE = 23;
  MAX_CHILD_AGE = 12;
  MAX_TEEN_AGE = 17;
  CRU_MAX_CABIN_PAX_COUNT = 20;
  CRU_MAX_CHILD = this.CRU_MAX_CABIN_PAX_COUNT - 1;
  CRU_MAX_TEEN = this.CRU_MAX_CABIN_PAX_COUNT - 1;
  CRU_MAX_INFANTS = this.CRU_MAX_CABIN_PAX_COUNT - 1;
  CRU_MIN_ADULT = 1;
  CRU_MAX_ADULT = 20;
  CRU_MAX_PAX_COUNT = 40;
  calendarRange = 3;
  calendarView = CALENDAR_VIEW.SINGLE_DATE_MONTH_YEAR_VIEW;
  monthCalendarLabel = 'EMBARKATION DATE/MONTH/YEAR';
  monthCalendarPlaceholder = 'DD/MM/YYYY';
  calendarMinDate = new Date();
  DURATION_CATEGORIES = {code: 'N', value: 'Nights', selected: true};
  STATIC_DATA_URL_BASE = '/data/data-service/v2/static-data';
  STATIC_DATA_FETCH_SIZE = 20;
  CRU_SEARCH_RESULT_COUNT = 200;
  HTL_STATIC_DATA_TYPES: string[] = ['CITY', 'HOTEL'];
  surfTextCasePipe: SurfTextCasePipe;
  SURF_ERROR_MESSAGES = SurfErrorMessages;

  constructor(protected commonService: DataShareService,
              protected dataServiceHandler: DataServiceHandler,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected configLoader: ConfigLoader,
              protected surfCruiseCriteriaService: SurfCruiseCriteriaService,
              protected masterDataStoreService: MasterDataStoreService,
  ) {
    this.textCasePipe = new SurfTextCasePipe(configService);
  }

  /**
   * returns the month value as a 2 digit number when a complete date is given
   */
  private static extractMonthFromDate(date) {
    let dateStr = '';
    if (date && date !== '') {
      if (date.getMonth() < 9) {
        dateStr += '0' + (date.getMonth() + 1);
      } else {
        dateStr += '' + (date.getMonth() + 1);
      }
    }
    return dateStr;
  }

  /**
   * retrieve year as a string from the date picker selected month output
   */
  private static getYearStr(date) {
    let dateStr = '';
    if (date != null && date !== '') {
      try {
        dateStr = date.getFullYear();
      } catch (e) {
        console.error(e);
      }
      return dateStr;
    }
  }

  ngOnInit() {
    this.loadConfigs();
    this.subscription.add(
      this.commonService.getComponentCriteria('CRU').subscribe(cri => {
        this.cruiseCriteria = cri as CruiseCriteria;
      }));
    this.surfCruiseCriteriaService.sharedMessage.subscribe(message => this.message = message);
    this.subscription.add(this.commonService.getKeyParamString().subscribe(keyParams => {
      if (keyParams) {
        this.keyParams = this.surfCriteriaUtil.overrideResultCount(keyParams, this.CRU_SEARCH_RESULT_COUNT);
      }
    }));
    this.setMultiFieldInputProperties();
    this.loadData();
    if (this.isRefine) {
      this.setRefineDate();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedPax) {
      this.selectedPax = changes.selectedPax.currentValue;
      if (this.isRefine) {
        this.defaultPaxSet = this.selectedPax;
      }
      if (this.isServiceAddComponent) {
        const adults = this.selectedPax.reduce((acc, pax) => acc + pax.adult, 0);
        const child = this.selectedPax.reduce((acc, pax) => acc + pax.child.length, 0);
        const teen = this.selectedPax.reduce((acc, pax) => acc + pax.teen.length, 0);
        const infant = this.selectedPax.reduce((acc, pax) => acc + pax.infant.length, 0);
        this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      }
    }
    if (changes.cartPaxSelection && this.cartPaxSelection) {
      const adults = this.cartPaxSelection.reduce((acc, pax) => acc + pax.adult, 0);
      const child = this.cartPaxSelection.reduce((acc, pax) => acc + pax.child.length, 0);
      const teen = this.cartPaxSelection.reduce((acc, pax) => acc + pax.teen.length, 0);
      const infant = this.cartPaxSelection.reduce((acc, pax) => acc + pax.infant.length, 0);
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getPassengerSelection(this.cartPaxSelection);
    }
    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === 'CRU') {
      this.searchCruise(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadConfigs() {
    this.STATIC_DATA_URL_BASE = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA] ?
      this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA] : this.STATIC_DATA_URL_BASE;

    this.STATIC_DATA_FETCH_SIZE = +this.configLoader.getModuleConfig('STATIC_DATA_FETCH_SIZE', TC.MODULE_NAME.SURF_B2B);

    this.CRU_SEARCH_RESULT_COUNT = +this.configLoader.getModuleConfig('CRU_SEARCH_RESULT_COUNT', TC.MODULE_NAME.SURF_B2B);

    this.TEEN_PAX_ACTIVE = this.configLoader.getModuleConfig('TEEN_PAX_ACTIVE', TC.MODULE_NAME.SURF_B2B)
      .toString().toUpperCase() === 'TRUE';

    this.DISABLE_DP_SWITCH = this.configLoader.getModuleConfig('DISABLE_DP_SWITCH', TC.MODULE_NAME.SURF_B2B)
      .toString().toUpperCase() === 'TRUE';
    this.DISABLE_DP_SWITCH = true; // TODO remove this once DP is implemented

    this.MIN_SEARCH_QUERY_LENGTH = +this.configLoader.getModuleConfig('MIN_SEARCH_QUERY_LENGTH', TC.MODULE_NAME.SURF_B2B);

    this.depPortUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=CRUISE_PORT&userInput=[UI]&aggrLevel=CITY&locale=en&matchType=ALL&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;

    this.durationOffset = this.configLoader.getModuleConfig('CRU_ONLY_WS_DURATION_OFFSET', TC.MODULE_NAME.SURF_B2B);
  }

  /**
   * get static data types string needed for departure port url
   */
  getStaticDataTypes() {
    if (this.HTL_STATIC_DATA_TYPES && this.HTL_STATIC_DATA_TYPES.length > 0) {
      // return 'COUNTRY~CITY~HOTEL';
      return this.HTL_STATIC_DATA_TYPES.join('~');
    }
  }

  loadData() {
    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_CRUISE_DESTINATIONS) !== null && this.cachingItemMap.get(StoreConstants.CACHE_CRUISE_DESTINATIONS) > 0)) {
      this.getCruiseDestinationsFromCache(this.cachingItemMap.get(StoreConstants.CACHE_CRUISE_DESTINATIONS));
    } else {
      this.subscription.add(
        this.dataServiceHandler.getCruiseDestinations().subscribe(
          res => {
            if (res) {
              this.generateDestinations(res);
            }
          },
          error => {
            console.log('Error in getting cruise destinations');
          }
        ));
    }
    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_CRUISE_LINES) !== null && this.cachingItemMap.get(StoreConstants.CACHE_CRUISE_LINES) > 0)) {
      this.getCruiseLinesFromCache(this.cachingItemMap.get(StoreConstants.CACHE_CRUISE_LINES));
    } else {
      this.subscription.add(
        this.dataServiceHandler.getCruiseLines().subscribe(
          res => {
            if (res) {
              this.generateCruiseLines(res);
            }
          },
          error => {
            console.log('Error in getting activity types');
          }
        ));
    }


    // during refine search, departure port will be initially selected if cruiseCriteria.depCruisePort value is present
    if (this.cruiseCriteria.depCruisePort) {
      this.selectedPort.displayText = this.cruiseCriteria.depCruisePort;
    }
  }

  private generateDestinations(res: any[]) {
    res.forEach(item => {
      this.initialDestinationList.push({code: item.code, value: item.name, selected: false});
    });
    this.initialDestinationList.sort(function(a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    // during refine search, destination will be initially selected if cruiseCriteria.desRegion value is present
    this.destinationList = this.addSelectAnyOption(this.initialDestinationList, this.cruiseCriteria.desRegion, 'Any Destination');

  }

  private generateCruiseLines(res: any[]) {
    res.forEach(item => {
      this.initialCruiseLineList.push({code: item.code, value: item.name, selected: false});
      if (item.ships && item.ships.length > 0) {
        item.ships.forEach(ship => {
          this.initialShipNameList.push({code: ship.code, name: ship.name, cruLineCode: item.code, selected: false});
        });
      }
    });
    this.initialShipNameList.sort((a, b) => this.compareStringOrder(b.name, a.name));
    this.initialCruiseLineList.sort((a, b) => this.compareStringOrder(b.value, a.value));
    // during refine search, cruiseLine will be initially selected if cruiseCriteria.cruiseLine value is present
    this.cruiseLineList = this.addSelectAnyOption(this.initialCruiseLineList, this.cruiseCriteria.cruiseLine, 'Any cruise line');
    this.shipNameList = this.addSelectAnyOption(this.initialShipNameList, null, 'Any ship');
    this.filteredShipList = this.setDefaultShipName(JSON.parse(JSON.stringify(this.shipNameList)),
      this.cruiseCriteria.ship, this.cruiseCriteria.cruiseLine, 'Any ship');

  }

  private getCruiseDestinationsFromCache(timeout: number) {
    this.masterDataStoreService.getCruiseDestinations(timeout).subscribe(
      res => {
        if (res) {
          this.generateDestinations(res);
        }
      },
      error => {
        console.log('Error in getting cruise destinations');
      });
  }

  private getCruiseLinesFromCache(timeout: number) {
    this.masterDataStoreService.getCruiseLines(timeout).subscribe(
      res => {
        if (res) {
          this.generateCruiseLines(res);
        }
      },
      error => {
        console.log('Error in getting cruise lines');
      });
  }

  /**
   * compare strings a and b for alphabetical order
   * @param a first string
   * @param b second string
   */
  compareStringOrder(a: string, b: string): number {
    if (!b || !a) {
      return 0;
    }
    if (b.toUpperCase() > a.toUpperCase()) {
      return 1;
    } else if (b.toUpperCase() < a.toUpperCase()) {
      return -1;
    } else {
      return 0;
    }
  }

  /**
   * Construct the cruise search URL and redirect to the results page
   */
  searchCruise(isExternal?) {
    const basePath = '/';
    if (this.isServiceAddComponent) {
      const criteriaUrl = basePath + 'b2b-cruises/?' + this.keyParams + this.getCruiseSearchUrlParams();
      this.getUrlCriteria.emit({url: criteriaUrl, isExternal});
    } else {
      window.location.href = basePath + 'b2b-cruises/?' + this.keyParams + this.getCruiseSearchUrlParams();
    }
  }

  /**
   * set 'select any' option to dropdowns
   */
  addSelectAnyOption(list, selectedElementCode, defaultValue: string) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    if (list && list.length > 0) {
      if (!(list[0].code === -1 || list[0].name === defaultValue)) {
        const defaultItem = JSON.parse(JSON.stringify(list[0]));
        defaultItem.value = defaultValue;
        defaultItem.name = defaultValue;
        if (!selectedElementCode) {
          selectedElementCode = this.NONE_SELECTED;
          defaultItem.selected = true;
        }
        defaultItem.code = this.NONE_SELECTED;
        defaultItem.cruLineCode = this.NONE_SELECTED;
        list.unshift(defaultItem);
      }
      list.forEach(dropdownItem => {
        dropdownItem.value = surfTextCasePipe.transform(dropdownItem.value, 'title');
        dropdownItem.selected = selectedElementCode === dropdownItem.code;
      });
    }
    return list;
  }

  /**
   * set default selection for ship dropdown
   */
  setDefaultShipName(list, selectedShipCode, selectedCruiseLine, defaultValue: string) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    if (selectedCruiseLine) {
      list = list.filter(ship => {
        return (ship.cruLineCode === selectedCruiseLine || ship.cruLineCode === this.NONE_SELECTED);
      });
    }
    if (list && list.length > 0) {
      if (!(list[0].code === -1 || list[0].name === defaultValue)) {
        const defaultItem = JSON.parse(JSON.stringify(list[0]));
        defaultItem.value = defaultValue;
        defaultItem.name = defaultValue;
        if (!selectedShipCode) {
          selectedShipCode = this.NONE_SELECTED;
          defaultItem.selected = true;
        }
        defaultItem.code = this.NONE_SELECTED;
        defaultItem.cruLineCode = this.NONE_SELECTED;
        list.unshift(defaultItem);
      }
      list.forEach(dropdownItem => {
        dropdownItem.value = surfTextCasePipe.transform(dropdownItem.value, 'sentence');
        dropdownItem.selected = selectedShipCode === dropdownItem.code;
      });
    }
    return list;
  }

  /**
   * set properties for duration selection multi-field-input
   */
  setMultiFieldInputProperties() {
    this.durationProperty = [];
    this.durationValues = [];
    this.durationOffsetProperty = [];
    this.durationOffsetValues = [];
    this.minDurationProperty = [];
    this.minDurationValues = [];
    this.maxDurationProperty = [];
    this.maxDurationValues = [];

    this.durationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true, 'Any', false, false, 'number', '', 'durationValue', ''));
    this.durationOffsetProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true, 'Any', false, false, 'number', '', 'durationOffsetValue', ''));
    this.minDurationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true, 'Any', false, false, 'number', '', 'minDurationValues', ''));
    this.maxDurationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true, 'Any', false, false, 'number', '', 'maxDurationValues', ''));
    if (this.isRefine) {
      this.durationValues.push(new MultiFieldInputData(
        '',
        this.cruiseCriteria.nights ? this.cruiseCriteria.nights.toString() : ''
      ));
      this.durationOffsetValues.push(new MultiFieldInputData(
        '',
        this.cruiseCriteria.durationOffset ? this.cruiseCriteria.durationOffset.toString() : ''
      ));
      this.minDurationValues.push(new MultiFieldInputData(
        '',
        this.cruiseCriteria.minDuration ? this.cruiseCriteria.minDuration.toString() : ''
      ));
      this.maxDurationValues.push(new MultiFieldInputData(
        '',
        this.cruiseCriteria.maxDuration ? this.cruiseCriteria.maxDuration.toString() : ''
      ));
    } else {
      this.durationValues.push(new MultiFieldInputData('', ''));
      // this.durationOffsetValues.push(new MultiFieldInputData('', ''));
      this.minDurationValues.push(new MultiFieldInputData('', ''));
      this.maxDurationValues.push(new MultiFieldInputData('', ''));
    }

    this.durationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true,
      '',
      false,
      true,
      'text',
      '',
      'durationType',
      ''
    ));
    this.durationOffsetProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true,
      '',
      false,
      true,
      'text',
      '',
      'durationOffsetType',
      ''
    ));
    this.maxDurationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true,
      '',
      false,
      true,
      'text',
      '',
      'maxDurationType',
      ''
    ));
    this.minDurationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true,
      '',
      false,
      true,
      'text',
      '',
      'minDurationType',
      ''
    ));

    this.durationValues.push(new MultiFieldInputData(this.DURATION_CATEGORIES.code, this.DURATION_CATEGORIES.value));
    this.durationOffsetValues.push(new MultiFieldInputData(
      this.DURATION_CATEGORIES.code,
      this.DURATION_CATEGORIES.value
    ));
    this.maxDurationValues.push(new MultiFieldInputData(this.DURATION_CATEGORIES.code, this.DURATION_CATEGORIES.value));
    this.minDurationValues.push(new MultiFieldInputData(this.DURATION_CATEGORIES.code, this.DURATION_CATEGORIES.value));
  }

  /**
   * get selected months from calendar month
   */
  getMonthSelection($event) {
    if ($event !== null && $event[0] !== '') {
      this.isDateNotSelected = false;
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_MONTH_VIEW;
      this.cruiseCriteria.startMonth = this.getMonthStr($event[0]);
      this.cruiseCriteria.startYear = CruiseCriteriaComponent.getYearStr($event[0]);
      this.cruiseCriteria.startDate = null;
      this.commonService.updateBaseCriteria(this.cruiseCriteria);
    }
  }

  /**
   * get selected dates from calendar
   */
  getDateSelection($event) {
    if ($event) {
      this.isDateNotSelected = false;
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_DATE_VIEW;
      this.cruiseCriteria.startDate = this.convertDateObjToStr($event[0]);
      this.cruiseCriteria.startMonth = null;
      this.cruiseCriteria.startYear = null;
      this.commonService.updateBaseCriteria(this.cruiseCriteria);
    } else {
      this.isDateNotSelected = true;
    }
  }

  /**
   * set cruise destination
   */
  selectDestination(event) {
    if (event.code && event.code !== this.NONE_SELECTED) {
      this.cruiseCriteria.desRegion = event.code;
      this.cruiseCriteria.destinationName = event.value;
    } else {
      this.cruiseCriteria.desRegion = null;
      this.cruiseCriteria.destinationName = null;
    }
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(this.cruiseCriteria, 'CRU');
    }
  }

  /**
   * set cruise line
   */
  selectCruiseLine(event) {
    this.cruiseCriteria.cruiseLine = event.code;
    if (event.code !== this.NONE_SELECTED) {
      this.filteredShipList = this.shipNameList.filter(ship => {
        return (ship.cruLineCode === event.code || ship.cruLineCode === this.NONE_SELECTED);
      });
    } else {
      this.filteredShipList = JSON.parse(JSON.stringify(this.shipNameList));
    }
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(this.cruiseCriteria, 'CRU');
    }
  }

  /**
   * set ship
   */
  selectShip(event) {
    this.cruiseCriteria.ship = event.code;
    if (event.code !== this.NONE_SELECTED) {
      this.cruiseLineList.forEach(line => {
        if (line.code === event.cruLineCode) {
          line.selected = true;
          this.cruiseCriteria.cruiseLine = event.cruLineCode;
        } else {
          line.selected = false;
        }
      });
    }
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(this.cruiseCriteria, 'CRU');
    }
  }

  /**
   * set duration of the cruise ship (number of nights in the journey)
   */
  setDuration($event) {
    if ($event != null) {
      if (this.validateDuration($event[0].value)) {
        this.durationError = false;
        if ($event[0].value && $event[0].value !== '') {
          this.cruiseCriteria.nights = parseInt($event[0].value, 10);
        } else {
          this.cruiseCriteria.nights = 0;
        }
        this.commonService.updateBaseCriteria(this.cruiseCriteria);
      } else {
        this.cruiseCriteria.nights = 0;
        this.commonService.updateBaseCriteria(this.cruiseCriteria);
      }
    }
  }

  /**
   * set maxDuration of the cruise ship
   */
  setMaxDuration($event) {
    // if (this.message === false) {
    if ($event != null) {
      if (this.validateDuration($event[0].value)) {
        this.maxDurationError = false;
        if ($event[0].value && $event[0].value !== '') {
          this.cruiseCriteria.maxDuration = $event[0].value;
        } else {
          this.cruiseCriteria.maxDuration = '0';
        }
        this.commonService.updateBaseCriteria(this.cruiseCriteria);
      } else {
        this.cruiseCriteria.maxDuration = ' 0 ';
        this.commonService.updateBaseCriteria(this.cruiseCriteria);
      }
    }
    // }

  }

  setValid($event) {

  }

  /**
   * set minDuration for cruiseShip
   */
  setMinDuration($event) {
    // if (this.message === false) {
    if ($event === '') {
      $event[0].value = '';
    }
    if ($event != null) {
      if (this.validateDuration($event[0].value)) {
        this.minDurationError = false;
        if ($event[0].value && $event[0].value !== '') {
          this.cruiseCriteria.minDuration = $event[0].value;
        } else {
          this.cruiseCriteria.minDuration = '0';
        }
        this.commonService.updateBaseCriteria(this.cruiseCriteria);
      } else {
        this.cruiseCriteria.minDuration = '0';
        this.commonService.updateBaseCriteria(this.cruiseCriteria);
      }
    }
    // }

  }

  /**
   * create URL parameters
   */
  getCruiseSearchUrlParams() {
    // get adult guest related params
    if (this.cruiseCriteria.adult) {
      this.cruiseCriteria.adult = this.cruiseCriteria.adult.replace(/DOB/g, ''); // removing DOB from adult
    }
    // get child guest related params
    let childParams = '';
    if (this.cruiseCriteria.teen && this.cruiseCriteria.teen.length > 0) {
      this.cruiseCriteria.teen = this.cruiseCriteria.teen.replace(/DOB/g, ''); // remove DOB from child
      childParams = '&child=' + this.cruiseCriteria.teen;
    }
    if (this.cruiseCriteria.child && this.cruiseCriteria.child.length > 0) {
      this.cruiseCriteria.child = this.cruiseCriteria.child.replace(/DOB/g, ''); // remove DOB from teen
      if (childParams.toString().includes('&child')) {
        childParams += '__' + this.cruiseCriteria.child;
      } else {
        childParams += '&child=' + this.cruiseCriteria.child;
      }
    }
    // get infant guest related params
    if (this.cruiseCriteria.infant && this.cruiseCriteria.infant.length > 0) {
      this.cruiseCriteria.infant = this.cruiseCriteria.infant.replace(/DOB/g, ''); // remove DOB from infant
      this.cruiseCriteria.infant = this.cruiseCriteria.infant.replace(/NaN/g, ''); // remove DOB from infant
      this.cruiseCriteria.infant = this.cruiseCriteria.infant.replace('-1', ''); // remove -1 from infant
      childParams += '&infant=' + this.cruiseCriteria.infant;
    }

    // get duration related params
    let durationParam = '';
    // if (this.cruiseCriteria.durationOffset !== undefined) {
    //   durationParam += '&durationOffset=' + this.cruiseCriteria.durationOffset;
    // } else if (this.durationOffset !== undefined) {
    //   durationParam += '&durationOffset=' + this.durationOffset;
    // }

    if (this.cruiseCriteria.nights) {
      durationParam += '&nights=' + this.cruiseCriteria.nights;
      durationParam += '&duration=' + this.cruiseCriteria.nights;
    }


    //for min and max duration
    if (this.message === false) {
      if (this.cruiseCriteria.minDuration) {
        durationParam += '&minDuration=' + this.cruiseCriteria.minDuration;
      }
      if (this.cruiseCriteria.maxDuration) {
        durationParam += '&maxDuration=' + this.cruiseCriteria.maxDuration;
      }
    }


    // get destination, journey related params
    let queryStr = '&adult=' + this.cruiseCriteria.adult + childParams
      + durationParam + (this.cruiseCriteria.desRegion ? '&desRegion=' + this.cruiseCriteria.desRegion : '')
      + (this.cruiseCriteria.cruiseLine ? '&cruiseLine=' + this.cruiseCriteria.cruiseLine : '')
      + (this.cruiseCriteria.ship ? '&ship=' + this.cruiseCriteria.ship : '')
      + (this.cruiseCriteria.destinationName ? '&destinationName=' + this.cruiseCriteria.destinationName : '');

    if (this.cruiseCriteria.startDate) {
      queryStr = queryStr + '&startDate=' + this.cruiseCriteria.startDate;
    } else if (this.cruiseCriteria.startMonth && this.cruiseCriteria.startYear) {
      queryStr = queryStr + '&startMonth=' + this.cruiseCriteria.startMonth;
      queryStr = queryStr + '&startYear=' + this.cruiseCriteria.startYear;
    } else if (this.cruiseCriteria.startYear) {
      queryStr = queryStr + '&startYear=' + this.cruiseCriteria.startYear;
    }

    if (!this.depPortPartiallyTyped) {
      if (this.cruiseCriteria.depCruisePort) {
        queryStr = queryStr + '&depCruisePort=' + this.cruiseCriteria.depCruisePort;
      }
      if (this.cruiseCriteria.depCruisePortCode) {
        queryStr = queryStr + '&depCruisePortCode=' + this.cruiseCriteria.depCruisePortCode;
      }
    }


    queryStr += '&expand=all';

    return queryStr;
  }

  getPassengerSelection($event: Array<PaxAllocationItem>) {
    if ($event != null && this.validateChildAges($event) && this.validateTeenAges($event)) {
      this.cruiseCriteria.adult = this.processAdultPaxAllocation($event);

      const children = this.processChildAllocation($event);
      const teens = this.processTeenAllocation($event);
      const infant = this.processInfantAllocation($event);
      if (children.CHD && children.CHD.length > 0) {
        this.cruiseCriteria.child = children.CHD;
      } else {
        this.cruiseCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.cruiseCriteria.teen = teens;
      } else {
        this.cruiseCriteria.teen = null;
      }
      this.cruiseCriteria.infant = infant && infant.length > 0 ? infant : null;

      this.commonService.updateComponentCriteria(this.cruiseCriteria, 'CRU');
    } else {
      this.cruiseCriteria.adult = null;
      this.cruiseCriteria.child = null;
      this.cruiseCriteria.teen = null;
      this.cruiseCriteria.infant = null;
      this.commonService.updateComponentCriteria(this.cruiseCriteria, 'CRU');
    }

  }

  processTeenAllocation(paxArray: Array<PaxAllocationItem>) {
    let teens = '';
    const roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.teen.length > 0) {
        const teenAgeMap = {};
        paxRoom.teen.forEach(teenAge => {
          teenAgeMap[teenAge] ? teenAgeMap[teenAge]++ : teenAgeMap[teenAge] = 1;
        });
        for (const key in teenAgeMap) {
          if (teenAgeMap.hasOwnProperty(key)) {
            const count = teenAgeMap[key] + '';
            teens += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + roomNo +
              this.AGE_SEPERATOR;
          }
        }
      }
    });

    if (teens.length > 2) {
      teens = teens.slice(0, teens.length - 2);
    }
    return teens;
  }

  processChildAllocation(paxArray: Array<PaxAllocationItem>) {
    const children = {INF: '', CHD: ''};
    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.child.length > 0) {

        const childAgeMap = {};
        paxRoom.child.forEach(childAge => {
          childAgeMap[childAge] ? childAgeMap[childAge]++ : childAgeMap[childAge] = 1;
        });

        for (const key in childAgeMap) {
          if (childAgeMap.hasOwnProperty(key)) {
            const count = childAgeMap[key] + '';
            if (key.trim() === this.LESS_THAN_ONE) {
              /*Infants*/
              children.INF += count + this.FIELD_SEPERATOR + 0 + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + roomNo +
                this.AGE_SEPERATOR;
            } else {
              /*child*/
              children.CHD += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + roomNo +
                this.AGE_SEPERATOR;
            }
          }
        }
      }
      roomNo++;
    });

    if (children.INF.length > 2) {
      children.INF = children.INF.slice(0, children.INF.length - 2);
    }
    if (children.CHD.length > 2) {
      children.CHD = children.CHD.slice(0, children.CHD.length - 2);
    }

    return children;
  }

  processInfantAllocation(paxArray: Array<PaxAllocationItem>) {
    let roomNo = 1;
    let inf = '';
    if (paxArray.length > 0) {
      paxArray.forEach(paxRoom => {
        inf += (paxRoom.infant.length) + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR + roomNo +
          this.AGE_SEPERATOR;
        roomNo++;
      });
    }
    return inf;
  }

  processAdultPaxAllocation(paxArray: Array<PaxAllocationItem>) {

    const defaultAdultAge = this.DEFAULT_ADULT_AGE;
    const fieldSeperator = '~';
    const roomSeperator = '__';
    let paxStr = '';
    for (let i = 0; i < paxArray.length; i++) {
      const paxItem = paxArray[i];
      if (i !== 0) {
        paxStr = paxStr + roomSeperator;
      }
      // remove adult dob and room count for fix FC-43944
      // paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + 'DOB' + fieldSeperator + (i + 1);
      paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator;
    }
    return paxStr;
  }

  resetDestination() {
    this.destinationList.forEach(ele => {
      ele.selected = ele.code === this.NONE_SELECTED;
    });
  }

  resetCruiseLine() {
    this.cruiseLineList.forEach(ele => {
      ele.selected = ele.code === this.NONE_SELECTED;
    });
  }

  resetShipName() {
    this.shipNameList.forEach(ele => {
      ele.selected = ele.code === this.NONE_SELECTED;
    });
    this.filteredShipList = JSON.parse(JSON.stringify(this.shipNameList));
  }

  resetDate() {
    this.resetCalendar = true;   // todo: try this without timeout using this.resetCalendar = !this.resetCalendar
    setTimeout(() => {
      this.resetCalendar = false;
    }, 0);
    this.cruiseCriteria.startDate = null;
    this.cruiseCriteria.startMonth = null;
    this.cruiseCriteria.startYear = null;
  }

  /**
   * check for invalid child ages
   * @param selectedPax: Array<PaxAllocationItem>
   */
  validateChildAges(selectedPax: Array<PaxAllocationItem>) {
    return selectedPax.reduce((acc, pax) => acc && (pax.child.indexOf(-1) <= -1), true);
  }

  /**
   * check for invalid teen ages
   * @param selectedPax: Array<PaxAllocationItem>
   */
  validateTeenAges(selectedPax: Array<PaxAllocationItem>) {
    return selectedPax.reduce((acc, pax) => acc && (pax.teen.indexOf(-1) <= -1), true);
  }

  /**
   * validate duration input value
   * @duration
   */
  validateDuration(duration: string) {
    if (duration !== null) {
      const value = duration.toString().replace(/[^0-9]*/g, '');
      if (value === duration) {
        if (parseInt(duration, 10) > 0) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  /**
   * move to dp flow when add another item from the component flow.
   */
  moveToDP(position: string, event: boolean) {
    // Todo
  }

  /**
   * retrieve month as a string from the date picker selected month output
   */
  getMonthStr(date) {
    if (date != null && date !== '') {
      try {
        return CruiseCriteriaComponent.extractMonthFromDate(date);
      } catch (e) {
        console.error(e);
      }
      return '';
    }
  }

  /**
   * Convert javascript Date object to yyyy-MM-dd
   */
  convertDateObjToStr(date): string {
    let dateStr = '';
    if (date != null && date !== '') {
      try {
        dateStr = date.getFullYear();
        dateStr = dateStr + '-';
        dateStr = dateStr + CruiseCriteriaComponent.extractMonthFromDate(date);
        dateStr = dateStr + '-';
        if (date.getDate() <= 9) {
          dateStr += '0' + date.getDate();
        } else {
          dateStr += '' + date.getDate();
        }
      } catch (e) {
        console.error(e);
      }
      return dateStr;
    }
  }

  closeCriteriaBox() {
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(this.cruiseCriteria, 'CRU');
      this.commonService.changeMessage('CRU_close');
    }
    if (this.isRefine) {
      this.cancelRefineSearch();
    }
  }

  /**
   * validate all component inputs
   */
  validateInputs() {
    let isAllInputsPresent = false;
    if (this.cruiseCriteria.adult) {
      isAllInputsPresent = true;
    }
    return isAllInputsPresent;
  }

  /**
   * Upon clicking the reset/cancel button, all the input fields and corresponding
   * values in the criteria object will be reset
   */
  resetInputs(isRefine?: boolean) {
    if (!isRefine) {
      this.selectedPort = new OntologyResponse();
      this.resetDisplayItems();
      this.setMultiFieldInputProperties();
    } else {
      this.cancelRefineSearch();
    }
    if (this.isServiceAddComponent) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }
  }

  cancelRefineSearch() {
    this.cancelRefine.emit(this.isRefine);
  }

  scrollTo(selector) {
    if (!this.isDPSearch) {
      if (this.windowScrollEnable) {
        SurfScrollDirective.scrollTo(selector, null, false, false, 0, 60);
      }
    }
  }

  /**
   * handle key down
   * @param $event, component
   */
  onKeyDown($event, component) {
    if ($event.keyCode === KeyCodes.ENTER) {
      if (component === 0) {
        setTimeout(() => {
          this.guestComponentFocus = false;
        }, 200);
      } else if (component === 1) {
        if (this.currentPaxOption === 3) {
          if (this.enterPressed && (this.cruiseCriteria.nights === undefined || this.cruiseCriteria.nights === 0)) {
            setTimeout(() => {
              this.guestComponentFocus = false;
            }, 200);
          }
          this.enterPressed = true;
        }
        if (this.currentPaxOption !== 3 && (this.cruiseCriteria.nights === undefined || this.cruiseCriteria.nights === 0)) {
          setTimeout(() => {
            this.guestComponentFocus = false;
          }, 200);
          this.enterPressed = false;
        }
      }
    }

    if ($event.keyCode === KeyCodes.UPARROW || $event.keyCode === KeyCodes.DOWNARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = false;
      }
    }

    if ($event.keyCode === KeyCodes.RIGHTARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = true;
      }
    }
  }

  getCurrentOption($event) {
    this.currentPaxOption = $event;
  }

  resetDisplayItems() {
    this.defaultPaxSet = [];
    this.resetDate();
    this.resetDestination();
    this.resetCruiseLine();
    this.resetShipName();
  }

  /**
   * generate the display string to show the selected pax numbers in surf-pax-selection component
   */
  generateDisplayString(adult, child, infant, teen) {
    let str = '';
    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    return str;
  }

  /**
   * set refine search attributes
   */
  private setRefineDate() {

    if (this.cruiseCriteria.startDate) {
      this.initialDates = [this.convertStrToDateObj(this.cruiseCriteria.startDate)];
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_DATE_VIEW;
    } else if (this.cruiseCriteria.startMonth && this.cruiseCriteria.startYear) {
      const month = this.cruiseCriteria.startMonth;
      const year = this.cruiseCriteria.startYear;
      this.initialDates = [this.createDummyDateUsingMonthAndYear(year, month)];
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_MONTH_VIEW;
    } else if (this.cruiseCriteria.startYear) {
      const year = this.cruiseCriteria.startYear;
      this.initialDates = [this.createDummyDateUsingYear(year)];
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_YEAR_VIEW;
    }
  }

  convertStrToDateObj(str) {
    if (str) {
      const dates = str.split('-');
      const formattedDate = new Date(dates[0], dates[1] - 1, dates[2]);
      return formattedDate;
    }
    return '';
  }

  /**
   * create dummy date to set moth date picker
   */
  createDummyDateUsingMonthAndYear(year, month) {
    if (year && month && year > 0 && month > 0) {
      return new Date(year, month - 1, 1);
    }
    return new Date();
  }

  /**
   * create dummy date to set year date picker
   */
  createDummyDateUsingYear(year) {
    if (year && year > 0) {
      return new Date(year, 0, 1);
    }
    return new Date();
  }

  returnedSearchQuery(event) {
    this.destSearchInitiated = event && event.length >= 3;
    this.depPortPartiallyTyped = !!event;
    if (this.selectedPort.displayText !== undefined && (this.selectedPort.displayText === '' || this.selectedPort.displayText === ' ')
      && this.isDPSearch) {
      const value = this.selectedPort.displayText
        + '~' + (this.cruiseCriteria.destinationType ? this.cruiseCriteria.destinationType : '')
        + '~' + ''
        + '~' + (this.cruiseCriteria.destinationName ? (this.cruiseCriteria.destinationType && this.cruiseCriteria.destinationType ===
          DestinationType.CITY) ? this.cruiseCriteria.destinationName.trim() :
          this.surfTextCasePipe.transform(this.cruiseCriteria.destinationName, 'title').trim() : '')
        + '~' + (this.cruiseCriteria.destinationParentCode ? this.cruiseCriteria.destinationParentCode : '')
        + '~' + '';

      const changeObj = {
        index: this.index,
        productCode: 'CRU',
        changeType: 'DEST',
        onlyForButtonDisable: true,
        value
      };
      this.commonService.updateComponentCriteria(this.cruiseCriteria, 'CRU');
      this.commonService.updateChangeItem(changeObj);
    }
  }

  returnedPort(data) {
    if (data && data.length > 0) {
      this.filteredPortList = data;
    } else {
      this.filteredPortList = [];
      this.cruiseCriteria.destinationCode = null;
      this.cruiseCriteria.depCruisePort = null;
      this.cruiseCriteria.destinationName = null;
      this.cruiseCriteria.destinationParentCode = null;
    }
  }

  /**
   * set cruise departure port
   */
  selectDeparturePort(data) {
    this.surfTextCasePipe = new SurfTextCasePipe(this.configService);
    this.filteredPortList = [];
    this.calendarMinDate = new Date();
    this.cacheDepCity = null;
    this.cacheDepCountry = null;
    this.depPortPartiallyTyped = false;

    for (const attribute of data.attributes) {
      if (attribute.name === 'current_time') {
        this.calendarMinDate = new Date(attribute.value);
      }
    }

    this.cruiseCriteria.destinationParentCode = this.surfCriteriaUtil.getCity(data).name +
      (this.surfCriteriaUtil.getCountry(data) ? ', ' + this.surfCriteriaUtil.getCountry(data) : '');
    // set cruise city and country when select departure port
    const depCityCode = this.surfCriteriaUtil.getCity(data) ? this.surfCriteriaUtil.getCity(data).code : null;
    const depCountryCode = this.surfCriteriaUtil.getCountryCode(data);
    if (depCityCode) {
      this.cacheDepCity = depCityCode;
    } else if (depCountryCode) {
      this.cacheDepCountry = depCountryCode;
    }
    this.selectedPort.displayText = this.sanitizePortDisplayValue(data.name);
    this.destSearchInitiated = false;
    this.cruiseCriteria.destinationCode = null;
    this.cruiseCriteria.depCruisePort = this.selectedPort.displayText;
    this.cruiseCriteria.depCruisePortCode = data.code;

    if (this.cruiseCriteria.productCode === undefined) {
      this.cruiseCriteria.productCode = 'CRU';
    }

    if (!(this.isAmendmentFlow && this.isDPSearch)) {
      this.commonService.updateComponentCriteria(this.cruiseCriteria, 'CRU');
    }

    if (this.isDPSearch) {
      /*Notify the change
      * change value : for DEST ->  DISPLAYTEXT~LOCTYPE~LOCCODE~LOCNAME~PARENTNAME~DEP_CRUISE_PORT~~*/

      const value = this.selectedPort.displayText
        + '~' + this.cruiseCriteria.destinationType
        + '~' + (data.type === 80 ? this.surfCriteriaUtil.getCity(data).code : this.cruiseCriteria.destinationCode)
        + '~' + (this.cruiseCriteria.destinationName ? (this.cruiseCriteria.destinationType && this.cruiseCriteria.destinationType ===
          DestinationType.CITY) ? this.cruiseCriteria.destinationName.trim() :
          this.surfTextCasePipe.transform(this.cruiseCriteria.destinationName, 'title').trim() : '')
        + '~' + this.cruiseCriteria.destinationParentCode
        + '~' + (data.type === 80 ? this.cruiseCriteria.depCruisePort : '')
        + '~' + (depCityCode ? depCityCode : '')
        + '~' + (depCountryCode ? depCountryCode : '');

      const changeObj = {
        index: this.index,
        productCode: 'CRU',
        changeType: 'DEST',
        amendmentChangeType: 'DESTINATION',
        value
      };

      this.commonService.updateChangeItem(changeObj);
      // todo: cruiseDP, this.validateInputsForDP();
    }
    this.getCurrentCruiseCriteria.emit(this.cruiseCriteria);
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      // todo: cruise amendment, this.checkDestinationChange(data);
    }
  }

  private sanitizePortDisplayValue(unsanitizedPortName: string): string {
    let str = this.textCasePipe.transform(unsanitizedPortName, 'title').trim();
    str = str.replace(/( +)-(.+)/, '');
    return str;
  }

  /**
   * add parent location details to child location object.
   */
  addParentToLocationGroup(locationGroup, city) {
    const parent = [];
    if (city) {
      parent.push({code: city.code, name: city.name, type: city.type});  /*add city*/
    }
    if (city.parent && city.parent[0]) {
      parent.push({code: city.parent[0].code, name: city.parent[0].name, type: city.parent[0].type});  /*add country*/
    }
    locationGroup.parent = parent;
    return locationGroup;
  }

  /**
   * get country from city / depPort.
   */
  getCountry(city): string {
    let parentName = null;
    if (city.parent && city.parent.length > 0) {
      city.parent.forEach(p => {
        if (p.type === 20) {
          parentName = p.name;
        }
      });
    }
    return parentName;
  }

  /**
   * get selected years from calendar year
   */
  getYearSelection($event) {
    if ($event !== null && $event[0] !== '') {
      this.isDateNotSelected = false;
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_YEAR_VIEW;
      this.cruiseCriteria.startYear = CruiseCriteriaComponent.getYearStr($event[0]);
      this.cruiseCriteria.startMonth = null;
      this.cruiseCriteria.startDate = null;
      this.commonService.updateBaseCriteria(this.cruiseCriteria);
    }
  }

  /**
   * set duration offset of the cruise ship
   */
  // setDurationOffset($event) {
  //   if ($event != null) {
  //     if (this.validateDuration($event[0].value)) {
  //       this.durationOffsetError = false;
  //       if ($event[0].value && $event[0].value !== '') {
  //         this.cruiseCriteria.durationOffset = parseInt($event[0].value, 10);
  //       } else {
  //         this.cruiseCriteria.durationOffset = 0;
  //       }
  //       this.commonService.updateBaseCriteria(this.cruiseCriteria);
  //     } else {
  //       this.cruiseCriteria.durationOffset = 0;
  //       this.commonService.updateBaseCriteria(this.cruiseCriteria);
  //     }
  //   }
  // }
}
