import { NgModule } from '@angular/core';
import {DPAddBarComponent} from './dp-add-bar/dp-add-bar.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [DPAddBarComponent],
  imports: [
    CommonModule
  ],
  exports: [DPAddBarComponent]
})
export class SurfDPAddBarModule { }
