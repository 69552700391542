import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {GenericCriteriaComponent} from '../generic-criteria/generic-criteria.component';
import {
  ConfigService,
  DataShareService,
  SurfCalendarStartDateService,
  SurfCriteriaUtil,
  SurfTravellerEarliestDepartureDateService,
  SurfUtilService
} from '@surf/surf-components-core';
import {ConfigLoader, DataStore} from '@tc-core/util/framework';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {MasterDataStoreService} from '@surf/surf-state-manage';
// imports are above
@Component({
  selector: 'surf-generic-criteria-dp',
  templateUrl: './generic-criteria-dp.component.html',
  styleUrls: ['./generic-criteria-dp.component.css']
})
export class GenericCriteriaDpComponent extends GenericCriteriaComponent implements OnInit, OnChanges {

  @Input() selectComp = false;   /*needed for dp criteria*/
  @Output() itemRemoved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('location') locationInput: ElementRef<any>;

  isSelected = false;
  isFocusToLocation = false;
  msg = '';

  constructor(protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected dataServiceHandler: DataServiceHandler,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService,
              protected surfUtilService: SurfUtilService,
              protected masterDataStoreService: MasterDataStoreService) {
    super(commonService, dataStore, configLoader, dataServiceHandler, configService, surfCriteriaUtil,
      travellerEarliestDepartureDateService, surfCalendarStartDateService, surfUtilService, masterDataStoreService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    /*if this component is added to the dp criteria panel initially, then it should be selected = true*/
    if (changes.selectComp) {
      this.isSelected = changes.selectComp.currentValue;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.showTypeAheadContent = true;
  }

  public selectComponent() {
    this.isSelected = true;
  }

  public deselectComponent() {
    this.isSelected = false;
    this.showTypeAheadContent = false;
  }

  removeItem() {
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(null, 'GEN');
    }
    this.itemRemoved.emit(this.index);
  }

  moveToDP(position: string) {
    if (!this.isDPSearch) {                     /*Move to DP search panel if only; was in the component flow previously*/
      this.msg = 'DP_Switch~GEN~' + position;
      // this.commonService.changeMessage(msg);
    }
  }

  passengerSplitChange(event) {
    if (event && event.length === this.paxSplitArray.length) {
      this.isPaxSplitChanged.emit(false);
    } else {
      this.isPaxSplitChanged.emit(true);
    }
  }

  /**
   * enable passenger split dropdown
   */
  enablePaxSplitDropdown() {
    this.enablePaxSplit = true;
  }

  addParentToLocationGroup(locationGroup, city) {
    let parent = [];
    if(city){
      parent.push({code: city.code, name: city.name, type: city.type});  /*add city*/
    }
    if(city.parent && city.parent[0]){
      parent.push({code: city.parent[0].code, name: city.parent[0].name, type: city.parent[0].type});  /*add country*/
    }
    locationGroup.parent = parent;
    return locationGroup;
  }

  getCountry(city): string {
    let parentName = null;
    if (city.parent && city.parent.length > 0) {
      city.parent.forEach(p => {
        if (p.type == 20) {
          parentName = p.name;
        }
      });
    }
    return parentName;
  }
}
