import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';
import {
  Configurations,
  DataShareService,
  DestinationType,
  KeyCodes,
  OntologyResponse, SurfCalendarStartDateService,
  SurfCityPipe,
  SurfCriteriaUtil, SurfErrorMessages,
  SurfScrollDirective, SurfTravellerEarliestDepartureDateService,
  TransferCriteria
} from '@surf/surf-components-core';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {MultiFieldInputData, MultiFieldInputProperty, MultiFieldInputPropertyFactory} from '@surf/surf-multi-field-input';
import {PaxAllocationItem} from '@surf/surf-pax-selection';
import moment from 'moment';
import {TC} from '@tc-core/util';
import {Subscription} from 'rxjs';
import LOCATION_TYPE = TC.LOCATION_TYPE;

@Component({
  selector: 'surf-transfer-criteria',
  templateUrl: './transfer-criteria.component.html'
})
export class TransferCriteriaComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isDPSearch = false;
  @Input() index: number;
  @Input() isRefine = false;
  @Input() windowScrollEnable = true;
  @Input() selectedPax: any;
  @Input() dpTransferDestinations: any;
  @Input() originalRefineCriteria: any;
  @Input() dpTransferRefineCriteria: any;
  // new impl
  @Input() isServiceAddComponent = false;
  @Input() cartPaxSelection: any;
  @Input() dpEnableDate: any;
  @Input() dpInitialDateStr: any;
  @Input() dpSetInitialReturnDate = false;
  @Input() dpInitialDestStr: any;
  @Input() unfreeze = true;
  @Input() isAmendmentFlow = false;
  @Input() isItemCloseable = true;
  @Input() amendmentReasonCode = null;
  @Input() amendmentCauseCode = null;
  @Input() productKey: string;
  @Input() bookingId = null;
  @Input() oldCriteriaItem;
  @Input() paxSplitArray = [];
  @Input() enablePaxSplit = false;
  @Input() triggerExternalSearch = '';
  dpCalEnableDate: Date;
  @Input() isForEpToDpConversion = false;
  @Input() enableRecommendLocations = true;
  @Input() recommendingLocations;
  @Input() paxTypeAges = [];

  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyDownLast = new EventEmitter<boolean>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isUnfreeze = new EventEmitter<boolean>();
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter();
  @Output() isPaxSplitChanged = new EventEmitter<boolean>();
  TRS_MAX_CABIN_PAX_COUNT = 20;
  TRS_MAX_CHILD = this.TRS_MAX_CABIN_PAX_COUNT - 1;
  TRS_MAX_TEEN = this.TRS_MAX_CABIN_PAX_COUNT - 1;
  TRS_MAX_INFANTS = this.TRS_MAX_CABIN_PAX_COUNT - 1;
  MAX_ROOMS = 9;
  TRS_MIN_ADULT = 1;
  TRS_MAX_ADULT = 20;
  TRS_MAX_PAX_COUNT = 40;
  DEFAULT_ADULT_AGE = 30;
  DEFAULT_DRIVER_AGE = 25;
  DEFAULT_OFFSET = 10;
  DEFAULT_CONTRACT_TYPE = 'TRS_HIRE';
  INITIAL_DURATION = 3;
  MAX_INFANT_AGE = 23;
  MAX_CHILD_AGE = 12;
  MAX_TEEN_AGE = 17;
  MIN_SEARCH_QUERY_LENGTH = 3;
  DISABLE_UI = false;
  DISABLE_DP_SWITCH = false;
  STATIC_DATA_URL_BASE = '/data/data-service/v2/static-data';
  STATIC_DATA_FETCH_SIZE = 20;
  TRS_SEARCH_RESULT_COUNT: string;
  TRS_DEFAULT_LOCATION_CODE = 'Any';
  TRS_DEFAULT_LOCATION_NAME = 'Any';
  ENABLED_TRANSFER_OPEN_SEARCH_FLOW = false;
  TRANSFER_ENABLE_LOCATION_TYPE_CODE = 'Default';
  TRANSFER_ENABLE_LOCATION_TYPE_VALUE = 'Any';
  TRS_LOCATION_TYPES : string;
  TRS_ALLOW_LOCATION_ANY_SEARCH = false;
  enableTransferRecommendation = false;
  TEEN_PAX_ACTIVE = true;
  pickupUrl = ''; // static data service url for getting pick-up locations
  dropoffUrl = ''; // static data service url for getting drop-off locations
  pickupFilterListError = false;
  dropoffFilterListError = false;
  isQueryError = false;
  dataTypesRegex = /(dataTypes=)(.*?)(&)/;

  FIELD_SEPERATOR = '~';
  AGE_SEPERATOR = '__';
  LESS_THAN_ONE = '< 1';

  CALENDAR_RANGE = 2;
  defaultPaxSet: Array<PaxAllocationItem> = [];
  datePickerFocus = false;
  enterPressed = false;

  transferCriteria: TransferCriteria = new TransferCriteria();
  productCriteriaList = [];
  moduleConfigs: any;
  keyParams: string;
  destinations = [];
  airportsLoaded = false;
  citiesLoaded = false;

  isInvalidTime = false;


  pickUpLocationProperty: MultiFieldInputProperty;
  pickupLocationPropId = 'PICKUP';
  pickupTypeProperty: MultiFieldInputProperty;
  pickupTypePropId = 'pickupType';
  dropOffLocationProperty: MultiFieldInputProperty;
  dropoffLocationPropId = 'DROP';
  dropoffTypeProperty: MultiFieldInputProperty;
  dropoffTypePropId = 'dropoffType';
  pickUpProperties: MultiFieldInputProperty[] = [];
  dropoffProperties: MultiFieldInputProperty[] = [];
  selectedPickUpPoint = new OntologyResponse();
  selectedDropOffPoint = new OntologyResponse();
  pickUpValues = []; // pickup multi field input possible values
  dropoffValues = []; // dropoff multi field input possible values
  filteredDestinations = [];
  returnedProperty: MultiFieldInputProperty;
  pickUpInitialDate = [];
  returnInitialDate = [];

  selectedPickupCriteria: any;
  selectedDropCriteria: any;
  previousSelectedPickupCriteria: any;
  previousSelectedDropCriteria: any;

  selectedPickupDate: Date;
  selectedReturnDate: Date;
  guestComponentFocus = false;
  currentPaxOption = 0;

  resetPickupCalendar = false;
  resetReturnCalendar = false;

  triggerChange: boolean;
  showPickupTypeAheadContent = true;
  showDropoffTypeAheadContent = true;
  dpPreItemDate: Date = null;
  dpPreItemToDate: Date = null;
  preItemDayGap = 0;
  dpReturnDisplayDate: Date;
  typeAheadPickupHasError = false; // this was initially introduced for past transfer criteria validations
  typeAheadDropoffHasError = false; // this was initially introduced for past transfer criteria validations
  pickupInputErrorMsg = '';
  dropoffInputErrorMsg = '';
  hasPickupLocationMismatchErr = false; //used to validate if pickup location and type mismatch is available. Note that there are scenarios where
  hasDropoffLocationMismatchErr = false; //used to validate if pickup location and type mismatch is available

  isLastItemInMultiInput = false // check is focusing input is last input in multi input
  pressEscPickup: false;
  pressEscDropoff: false;
  paxDisplay: string;
  freeze: boolean; // enable/disable all input fields during amendment search
  returnDateWarning = false;
  pickupDateWarning = false;
  subscriptions = new Subscription();
  calendarMinDate: Date = new Date();
  dpEnableDateChanged = false;
  paxSplitDisplayText = 'All Guests';
  ENABLE_TRANSFER_NFR = false;
  ENABLE_PAX_SPLIT = false;
  pickupIndependent: boolean = false;
  dropoffIndependent: boolean = false;
  showPickUpRecommendationList = false;
  showDropOffRecommendationList = false;
  calendarMinDateForInitialEpeToDpConversionCriteria: Date = moment().toDate();
  hideErrorMsgPickUp = true;
  hideErrorMsgReturn = true;
  validateDisabledDateSelectionPickUp = false;
  validateDisabledDateSelectionReturn = false;
  SURF_ERROR_MESSAGES = SurfErrorMessages;
  setTimeoutValue = 200;
  trsIndependentDisplay = false;

  constructor(protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected dataServiceHandler: DataServiceHandler,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected surfCityPipe: SurfCityPipe,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService) {
    this.subscriptions.add(
      this.commonService.getProductCriteriaList().subscribe(list => {
        this.productCriteriaList = list;
      }));
  }

  ngOnInit() {
    this.loadConfigs();
    if (!this.paxTypeAges || this.paxTypeAges.length === 0) {
      this.loadPaxTypeAges();
    } else {
      this.mapPaxSubTypeAges();
    }
    this.DISABLE_UI = !this.surfCriteriaUtil.getProductEnableStatus('TRS', 'products');

    this.subscriptions.add(
      this.commonService.getComponentCriteria('TRS').subscribe(cri => {
        this.transferCriteria = cri as TransferCriteria;
      }));
    if (this.dpTransferRefineCriteria) {
      this.transferCriteria = this.dpTransferRefineCriteria; // do not replace this with a copy of dpTransferRefineCriteria
      if (this.isAmendmentFlow && this.cartPaxSelection) {
        this.getPassengerSelection(this.cartPaxSelection);
      }
      this.validateInputsForDP();
    }

    this.subscriptions.add(
      this.commonService.getKeyParamString().subscribe(keyParams => {
        // update resultsCount
        if (this.TRS_SEARCH_RESULT_COUNT && this.TRS_SEARCH_RESULT_COUNT.length > 0) {
          const tempList = keyParams.split('resultsCount=');
          if (tempList[1]) {
            const sliceIndex = tempList[1].indexOf('&') >= 0 ? tempList[1].indexOf('&') : tempList[1].length;
            tempList[1] = this.TRS_SEARCH_RESULT_COUNT + tempList[1].slice(sliceIndex);
            keyParams = tempList.join('resultsCount=');
          } else {
            tempList[1] = this.TRS_SEARCH_RESULT_COUNT;
            keyParams = tempList.join('&resultsCount=');
          }
        }
        this.keyParams = keyParams;
      }));

    this.subscriptions.add(
      this.commonService.getCurrentMsg().subscribe(msg => {
        if (msg === 'triggerReset') {
          this.resetInputs();
        }
      }));

    if ((this.isRefine && !this.isDPSearch) || (!this.isDPSearch && this.isAmendmentFlow)) {
      this.setInitialDates(this.transferCriteria.onewayPickupTime,this.transferCriteria.returnPickupTime);
      this.selectedPickUpPoint.displayText = this.transferCriteria.pickupLocation ? this.transferCriteria.pickupLocation : this.transferCriteria.pickupCityName;
      this.selectedDropOffPoint.displayText = this.transferCriteria.returnLocation ? this.transferCriteria.returnLocation : this.transferCriteria.dropOffCityName;
    }
    if ((this.isRefine && this.isDPSearch)) {
      if (this.transferCriteria.onewayPickupTime) { this.setInitialDates(this.transferCriteria.onewayPickupTime); }
      this.transferCriteria.pickupLocation = this.transferCriteria.pickupLocationName ? this.transferCriteria.pickupLocationName : this.transferCriteria.pickupLocation? this.transferCriteria.pickupLocation : ''; //pickup location is set to a code although it should be a name. Therefore, setting it again here
      this.transferCriteria.returnLocation = this.transferCriteria.dropOffLocationName ? this.transferCriteria.dropOffLocationName : this.transferCriteria.returnLocation? this.transferCriteria.returnLocation : ''; //dropoff location is set to a code although it should be a name. Therefore, setting it again here
      this.selectedPickUpPoint.displayText = this.transferCriteria.selectedPickupType == 40 ? this.transferCriteria.pickupCityName : this.transferCriteria.pickupLocationName;
      this.selectedDropOffPoint.displayText = this.transferCriteria.selectedDropoffType == 40 ? this.transferCriteria.dropOffCityName : this.transferCriteria.dropOffLocationName;
      if (this.selectedPickUpPoint.displayText === 'null') {
        this.selectedPickUpPoint.displayText = '';
      }
      if (this.selectedDropOffPoint.displayText === 'null') {
        this.selectedDropOffPoint.displayText = '';
      }
      this.handleLocationTypeMismatchError(this.transferCriteria.pickupLocationType, this.pickupTypePropId);
      this.handleLocationTypeMismatchError(this.transferCriteria.dropoffLocationType, this.dropoffTypePropId);
      this.validateInputsForDP();
      if (this.transferCriteria.pickupLocation.includes('undefined')) {
        this.transferCriteria.pickupLocation = '';
        this.selectedPickUpPoint.displayText = '';
      }
      if (this.transferCriteria.returnLocation.includes('undefined')) {
        this.transferCriteria.returnLocation = '';
        this.selectedDropOffPoint.displayText = '';
      }
    }
    else if (this.isDPSearch) {
      // used when another component is added in component flow which will redirect to dp flow
      if (this.transferCriteria.selectedPickupType) {
        this.selectedPickUpPoint.displayText = this.transferCriteria.selectedPickupType == 40 ? this.transferCriteria.pickupCityName : this.transferCriteria.pickupLocationName;
      } else if (this.selectedPickUpPoint.displayText === 'null') {
        this.selectedPickUpPoint.displayText = '';
      }
      if (this.transferCriteria.selectedDropoffType) {
        this.selectedDropOffPoint.displayText = this.transferCriteria.selectedDropoffType == 40 ? this.transferCriteria.dropOffCityName : this.transferCriteria.dropOffLocationName;
      } else if (this.selectedDropOffPoint.displayText === 'null') {
        this.selectedDropOffPoint.displayText = '';
      }

      if (this.transferCriteria.onewayPickupTime) {
        this.setInitialDates(this.transferCriteria.onewayPickupTime);
      }
      this.handleLocationTypeMismatchError(this.transferCriteria.pickupLocationType, this.pickupTypePropId);
      this.handleLocationTypeMismatchError(this.transferCriteria.dropoffLocationType, this.dropoffTypePropId);
      this.validateInputsForDP();
    }
    this.pickUpLocationProperty = MultiFieldInputPropertyFactory.getTypeAheadProperties('Search location', true, false, this.pickupLocationPropId, 'PICK-UP LOCATION & TYPE*', 'strict', this.selectedPickUpPoint, this.MIN_SEARCH_QUERY_LENGTH, true, this.pickupUrl);
    this.pickupTypeProperty = MultiFieldInputPropertyFactory.getDropdownProperties(false, '', true, false, 'button', this.pickupTypePropId, '');
    this.dropOffLocationProperty = MultiFieldInputPropertyFactory.getTypeAheadProperties('Search location', true, false, this.dropoffLocationPropId, 'DROP-OFF LOCATION & TYPE*', 'strict', this.selectedDropOffPoint, this.MIN_SEARCH_QUERY_LENGTH, true, this.dropoffUrl);
    this.dropoffTypeProperty = MultiFieldInputPropertyFactory.getDropdownProperties(false, '', true, false, 'button', this.dropoffTypePropId, '');
    this.pickUpProperties.push(this.pickUpLocationProperty);
    this.pickUpProperties.push(this.pickupTypeProperty);
    this.dropoffProperties.push(this.dropOffLocationProperty);
    this.dropoffProperties.push(this.dropoffTypeProperty);
    this.pickUpValues.push([]);
    this.pickUpValues.push(this.getTransferLocationTypes());
    this.dropoffValues.push([]);
    this.dropoffValues.push(this.getTransferLocationTypes());
    this.pickupIndependent = typeof this.transferCriteria.pickupDependent === 'undefined' ? false : !this.transferCriteria.pickupDependent;
    this.dropoffIndependent = typeof this.transferCriteria.dropoffDependent === 'undefined' ? false : !this.transferCriteria.dropoffDependent;
    this.transferCriteria.pickupDependent = !this.pickupIndependent;
    this.transferCriteria.dropoffDependent = !this.dropoffIndependent;
    if (this.isRefine || ((this.isDPSearch || this.isAmendmentFlow) && this.transferCriteria)) {
      //set location type drop down initially selected values in refine search
      if (this.transferCriteria.pickupLocationType && this.transferCriteria.pickupLocationType.length > 0) {
        this.pickUpValues[1].find(x => x.code == this.transferCriteria.pickupLocationType).selected = true;
        this.generateLocationUrl(this.transferCriteria.pickupLocationType, this.pickupTypePropId);
      } else {
        this.pickUpValues[1][0].selected = true;
      }
      if (this.transferCriteria.dropoffLocationType && this.transferCriteria.dropoffLocationType.length > 0) {
        this.dropoffValues[1].find(x => x.code == this.transferCriteria.dropoffLocationType).selected = true;
        this.generateLocationUrl(this.transferCriteria.dropoffLocationType, this.dropoffTypePropId);
      } else {
        this.dropoffValues[1][0].selected = true;
      }
    } else {
      this.pickUpValues[1][0].selected = true;
      this.dropoffValues[1][0].selected = true;
    }
    this.showPickupTypeAheadContent = true;
    this.showDropoffTypeAheadContent = true;

    // followings are the change detection of criteria in refine pop up
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkLocationChange('BOTH');
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkTypeChange(this.transferCriteria.pickupLocationType, 'pickupType');
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkTypeChange(this.transferCriteria.dropoffLocationType, 'dropoffType');
    }
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'TRS');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unfreeze && this.unfreeze !== undefined) {
      this.freeze = !this.unfreeze;
    }
    if (changes.selectedPax) {
      this.selectedPax = changes.selectedPax.currentValue;
      if (this.isRefine) {
        this.defaultPaxSet = this.selectedPax;
      }
      if (this.isServiceAddComponent) {
        const adults = this.selectedPax.reduce( (acc, pax ) => acc + pax.adult , 0 ) ;
        const child = this.selectedPax.reduce( (acc, pax) => acc + pax.child.length , 0) ;
        const teen = this.selectedPax.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
        const infant = this.selectedPax.reduce( (acc, pax) => acc + pax.infant.length , 0 ) ;
        this.paxDisplay = this.generateDisplayString(adults, child, infant, teen) ;
      }
    }
    console.log('changes.cartPaxSelection');
    console.log(changes.cartPaxSelection);
    if (changes.cartPaxSelection && this.cartPaxSelection) {
      const adults = this.cartPaxSelection.reduce( (acc, pax ) => acc + pax.adult , 0 );
      const child = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.child.length , 0);
      const teen = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
      const infant = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.infant.length , 0 )
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getPassengerSelection(this.cartPaxSelection);
    }
    if (changes.dpEnableDate) {
      this.dpEnableDate = changes.dpEnableDate.currentValue;
      if (this.dpEnableDate && this.dpEnableDate !== undefined) {
        this.dpCalEnableDate = new Date(this.dpEnableDate);
        this.dpEnableDateChanged = true;
      } else {
        this.dpCalEnableDate = new Date();
        this.dpEnableDateChanged = false;
      }
      this.validateInputsForDP();
    }
    if (changes.isDPSearch && !changes.isDPSearch.isFirstChange()) {
     this.setPickupDropOffTypes();
    }

    if (changes.dpInitialDateStr) {
      this.dpInitialDateStr = changes.dpInitialDateStr.currentValue;
      if (this.dpSetInitialReturnDate) {
        if (this.dpInitialDateStr && this.dpInitialDateStr.split('~')[0] && this.dpInitialDateStr.split('~')[1]) {
          this.dpReturnDisplayDate = new Date(this.dpInitialDateStr.split('~')[0]);
          this.setInitialDates(this.dpInitialDateStr.split('~')[0], this.dpInitialDateStr.split('~')[1]);
          this.validateInputsForDP();
        }
      } else {
        if (this.dpInitialDateStr && this.dpInitialDateStr.split('~')[0]) {
          this.setInitialDates(this.dpInitialDateStr.split('~')[0], null);
          this.validateInputsForDP();
        }
      }
    }

    if (changes.dpInitialDestStr) {
      this.dpInitialDestStr = changes.dpInitialDestStr.currentValue;
      if (this.dpInitialDestStr && !this.dpInitialDestStr.includes('undefined')) {
        this.selectedPickUpPoint.displayText = this.dpInitialDestStr;
        if (!(this.selectedDropOffPoint && this.selectedDropOffPoint.displayText)) {
          if (this.dpTransferRefineCriteria && this.dpTransferRefineCriteria.destinationType &&
            this.dpTransferRefineCriteria.destinationType === 'HOTEL' && this.dpTransferRefineCriteria.dropOffLocationName) {
            this.selectedDropOffPoint.displayText = this.dpTransferRefineCriteria.dropOffLocationName;
          } else {
            if (this.dpTransferRefineCriteria?.pickupLocationType === 'HOTEL'
            && this.dpTransferRefineCriteria?.dropoffLocationType === 'AIRPORT' && !this.dpTransferRefineCriteria.dropOffLocationName) {
              this.selectedDropOffPoint.displayText = undefined;
            } else {
              this.selectedDropOffPoint.displayText = this.dpInitialDestStr;

            }
          }
        }
        this.validateInputsForDP();
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkLocationChange('BOTH');
        }
      }
    }

    if (changes['paxSplitArray']) {
      const selectedPaxArray = this.paxSplitArray.filter(pax => {
        return pax.selected;
      });
      if (selectedPaxArray && selectedPaxArray.length === this.paxSplitArray.length) {
        this.paxSplitDisplayText = 'All Guests';
      } else {
        this.paxSplitDisplayText = selectedPaxArray.length.toString() + (selectedPaxArray.length === 1 ? ' Guest' : ' Guests');
      }
    }

    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === 'TRS') {
      this.searchTransfers(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }
  }

  loadPaxTypeAges() {
    this.subscriptions.add(
      this.dataServiceHandler.getPaxSubTypeAges().subscribe(
        res => {
          if (res == null || !(Array.isArray(res)) || res.length === 0) {
            console.log('Could not fetch Pax Type Ages');
            return;
          }

          this.paxTypeAges = res[0].paxTypes;
          this.mapPaxSubTypeAges();
          },
        error => {
          console.log('Error in getting pax sub type ages');
        }));
  }

  private mapPaxSubTypeAges() {
    this.MAX_CHILD_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'C').age.upper / 12);
    this.MAX_TEEN_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'T').age.upper / 12);
    this.MAX_INFANT_AGE = this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
  }

  /**
   * returns the transfer location types array which is used in location type drop downs
   */
  getTransferLocationTypes() : MultiFieldInputData[] {
    if (this.TRS_LOCATION_TYPES && this.TRS_LOCATION_TYPES.length > 0) {
      let multiFieldArr: MultiFieldInputData[] = [];
      let location_types = this.TRS_LOCATION_TYPES.split('~'); // code1:name1~code2:name2~code3:name3...
      location_types.forEach(location => {
        let codeNamePair = location.split(':'); // code:name
        if (codeNamePair[0] && codeNamePair[1]) {
          multiFieldArr.push(new MultiFieldInputData(codeNamePair[0], codeNamePair[1]))
        }
      });
      return this.processAnyTransferLocationTypes(multiFieldArr, this.isDPSearch);
    }
    else {
      const locationTypes = [
        new MultiFieldInputData(this.TRS_DEFAULT_LOCATION_CODE, this.TRS_DEFAULT_LOCATION_NAME, true),
        new MultiFieldInputData(LOCATION_TYPE.AIRPORT, 'Airport'),
        new MultiFieldInputData(LOCATION_TYPE.CRUISE_PORT, 'Cruise port'),
        new MultiFieldInputData(LOCATION_TYPE.HOTEL, 'Hotel'),
        new MultiFieldInputData(LOCATION_TYPE.LOCATION, 'Location'),
        new MultiFieldInputData(this.TRANSFER_ENABLE_LOCATION_TYPE_CODE, this.TRANSFER_ENABLE_LOCATION_TYPE_VALUE)
      ];
      return this.processAnyTransferLocationTypes(locationTypes, this.isDPSearch);

    }
  }

  processAnyTransferLocationTypes(locationTypes, isDpFlow) {
    const processedList = [];
    locationTypes.filter(locationType => {
      // if (!(isDpFlow && locationType.code === this.TRANSFER_ENABLE_LOCATION_TYPE_CODE)) {
      //   processedList.push(locationType);
      // }
      processedList.push(locationType);
    });
    if (isDpFlow && this.dpTransferRefineCriteria) {
      if (this.dpTransferRefineCriteria.dropoffLocationType && this.dpTransferRefineCriteria.dropoffLocationType ===
        this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
        this.dpTransferRefineCriteria.dropoffLocationType = 'Any';
      }
      if (this.dpTransferRefineCriteria.pickupLocationType && this.dpTransferRefineCriteria.pickupLocationType ===
        this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
        this.dpTransferRefineCriteria.pickupLocationType = 'Any';
      }
    }
    return processedList;
  }

  setPickupDropOffTypes(reset = false) {
    if (reset) {
      this.pickUpValues = [];
      this.pickUpValues.push([]);
      this.pickUpValues.push(this.getTransferLocationTypes());
      this.dropoffValues = [];
      this.dropoffValues.push([]);
      this.dropoffValues.push(this.getTransferLocationTypes());
      this.ENABLED_TRANSFER_OPEN_SEARCH_FLOW = this.TRS_LOCATION_TYPES.includes(this.TRANSFER_ENABLE_LOCATION_TYPE_CODE);
    } else {
      this.pickUpValues = this.processAnyTransferLocationTypes(this.pickUpValues, this.isDPSearch);
      this.dropoffValues = this.processAnyTransferLocationTypes(this.dropoffValues, this.isDPSearch);
    }
  }


  getCalendarStartDate() {
    if (this.dpEnableDateChanged) {
      this.surfCalendarStartDateService.updateMinDate(this.dpCalEnableDate, 'TRS');
      return this.dpCalEnableDate;
    }
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'TRS');
    return this.calendarMinDate;
  }

  generateDisplayString(adult, child, infant, teen) {

    // should handle
    let str = '';

    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    return str;
  }

  /**
   * set calendar initial dates based on the config values
   */
  setInitialDates(pickUpDate: any, returnDate?: any) {
    this.pickUpInitialDate = [];
    this.returnInitialDate = [];

    const jsPickUp = new Date(pickUpDate);
    this.pickUpInitialDate.push(jsPickUp);
    this.selectedPickupDate = jsPickUp;
    if ((this.isDPSearch || this.isAmendmentFlow) && this.dpSetInitialReturnDate) {
      this.dpReturnDisplayDate = this.selectedPickupDate;
    }

    if (returnDate && returnDate !== 'Invalid date') {
      const jsReturn = new Date(returnDate);
      this.returnInitialDate.push(jsReturn);
      this.selectedReturnDate = jsReturn;
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkPickupDateChange(true);
    }
    if (this.isAmendmentFlow) {
      if (this.selectedPickupDate) {
        this.doCheckLastMinuteBookingDate(this.selectedPickupDate, true, false);
      }
      if (this.selectedReturnDate) {
        this.doCheckLastMinuteBookingDate(this.selectedReturnDate, false, false);
      }
    }
    console.log(this.transferCriteria);
  }

  closeCriteriaBox() {
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(this.transferCriteria, 'TRS');
      this.commonService.changeMessage('TRS_close');
    }
    if (this.isRefine) {
      this.cancelRefineSearch();
    }
  }

  cancelRefineSearch() {
    this.cancelRefine.emit(this.isRefine);
  }

  /**
   * Upon clicking the reset button, all the input fields and corresponding
   * values in the criteria object will be reset
   */
  resetInputs(isRefine?: boolean) {
    if (!isRefine) {
      this.selectedPickUpPoint.displayText = '';
      this.selectedDropOffPoint.displayText = '';
      this.selectedPickupDate = null;
      this.selectedReturnDate = null;
      this.transferCriteria.pickupCity = null;
      this.transferCriteria.pickupCountry = null;
      this.transferCriteria.dropOffCity = null;
      this.transferCriteria.dropOffCountry = null;
      this.transferCriteria.onewayPickupTime = null;
      this.transferCriteria.returnPickupTime = null;
      this.transferCriteria.onewayDropoffTime = null;
      this.transferCriteria.returnDropoffTime = null;
      this.transferCriteria.isReturn = false;
      this.transferCriteria.pickupLocation = null;
      this.transferCriteria.pickupLocationCode = null;
      this.transferCriteria.pickupLocationType = null;
      (this.pickUpValues[1] as MultiFieldInputData[]).forEach((val, index) => {
        val.selected = index == 0;
      });
      (this.dropoffValues[1] as MultiFieldInputData[]).forEach((val, index) => {
        val.selected = index == 0;
      });
      this.transferCriteria.returnLocation = null;
      this.transferCriteria.dropoffLocationCode = null;
      this.transferCriteria.dropoffLocationType = null;
      this.transferCriteria.adult = '';
      this.transferCriteria.child = '';
      this.transferCriteria.teen = '';
      this.selectedDropCriteria = null;
      this.selectedPickupCriteria = null;
      this.pickUpInitialDate = [];
      this.returnInitialDate = [];
      this.pickupFilterListError = false;
      this.dropoffFilterListError = false;
      this.hasPickupLocationMismatchErr = false;
      this.hasDropoffLocationMismatchErr = false;
      this.commonService.updateComponentCriteria(this.transferCriteria, 'TRS');
      this.resetDisplayItems();
      this.validateInputs();
    } else {
      this.cancelRefineSearch();
    }
    if (this.isServiceAddComponent) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }
  }

  resetDisplayItems() {
    this.defaultPaxSet = [];
    this.resetPickupCalendar = true;
    this.resetReturnCalendar = true;
    setTimeout(() => {
      this.resetPickupCalendar = false;
      this.resetReturnCalendar = false;
    }, 0);
  }

  /**
   * Construct the Car search URL and redirect to the listing page
   */
  searchTransfers(isExternal?) {
    console.log('transfer search ************')
    if (this.validateInputs()) {
      this.travellerEarliestDepartureDateService.addNewDepartureDate(this.transferCriteria.onewayPickupTime);
      this.travellerEarliestDepartureDateService.checkAndUpdateEarliestDepartureDate();
      const basePath = '/';
      if (this.isServiceAddComponent || this.isAmendmentFlow) {
        const criteriaUrl = basePath + 'b2b-transfers/?' + this.keyParams + this.getTransferSearchUrlParams();
        this.getUrlCriteria.emit({url: criteriaUrl, isExternal});
      } else {
        // this.isRefine ? window.location.origin + '/' : window.location.href;
        window.location.href = basePath + 'b2b-transfers/?' + this.keyParams + this.getTransferSearchUrlParams();
      }
      // window.location.href = 'http://localhost:4315/' + 'b2b-transfers/?' + this.keyParams + this.getTransferSearchUrlParams();

    }
  }

  getTransferSearchUrlParams() {
    /*Consider check-in date when calculating DOB*/
    if (this.transferCriteria.adult) {
      // this.transferCriteria.adult = this.surfCriteriaUtil.replaceDOB(this.transferCriteria.adult, this.transferCriteria.onewayPickupTime);
      this.transferCriteria.adult = this.transferCriteria.adult.replace(/DOB/g, ''); // removing DOB from adult
    }

    let childParams = '';
    if (this.transferCriteria.teen && this.transferCriteria.teen.length > 0) {
      // this.transferCriteria.child = this.surfCriteriaUtil.replaceDOB(this.transferCriteria.child, this.transferCriteria.onewayPickupTime);
      this.transferCriteria.teen = this.transferCriteria.teen.replace(/DOB/g, ''); // removing DOB from child
      childParams = '&child=' + this.transferCriteria.teen;
    }
    if (this.transferCriteria.child && this.transferCriteria.child.length > 0) {
      if (childParams.toString().includes('&child')) {
        this.transferCriteria.child = this.transferCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = childParams + this.AGE_SEPERATOR + this.transferCriteria.child;
      } else {
        this.transferCriteria.child = this.transferCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = '&child=' + this.transferCriteria.child;
      }
    }

    if (this.transferCriteria.infant && this.transferCriteria.infant.length > 0) {
      // this.transferCriteria.infant = this.surfCriteriaUtil.replaceDOB(this.transferCriteria.infant, this.transferCriteria.onewayPickupTime, '', TravellerType.INFANT);
      childParams += '&infant=' + this.transferCriteria.infant;
    }

    let locationParams = '';
    if (this.transferCriteria.pickupLocation !== null && this.transferCriteria.pickupLocationCode !== null &&
      this.transferCriteria.pickupLocationType !== this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
      locationParams += '&pickupLocation=' + this.transferCriteria.pickupLocation +
        '&pickupLocationCode=' + this.transferCriteria.pickupLocationCode;
    }
    if (this.transferCriteria.pickupLocationType && this.transferCriteria.pickupLocationType !== this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
      locationParams += '&pickupLocationType=' + this.transferCriteria.pickupLocationType;
    } else if (this.transferCriteria.pickupLocationType && this.transferCriteria.pickupLocationType === this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
      locationParams += '&isRefinedPickupLocationType=' + this.TRANSFER_ENABLE_LOCATION_TYPE_CODE;

    }
    if (this.transferCriteria.returnLocation !== null && this.transferCriteria.dropoffLocationCode !== null &&
      this.transferCriteria.dropoffLocationType !== this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
      locationParams += '&returnLocation=' + this.transferCriteria.returnLocation +
        '&dropoffLocationCode=' + this.transferCriteria.dropoffLocationCode;
    }

    if (this.transferCriteria.dropoffLocationType && this.transferCriteria.dropoffLocationType !== this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
      locationParams += '&dropoffLocationType=' + this.transferCriteria.dropoffLocationType;
    } else if (this.transferCriteria.dropoffLocationType && this.transferCriteria.dropoffLocationType === this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
      locationParams += '&isRefinedDropOffLocationType=' + this.TRANSFER_ENABLE_LOCATION_TYPE_CODE;
    }

    // get transfer related URL params
    let queryStr = '&adult=' + this.transferCriteria.adult + childParams + '&onewayPickupTime=' + this.transferCriteria.onewayPickupTime +
      '&onewayDropoffTime=' + this.transferCriteria.onewayDropoffTime + '&pickupCountry=' + this.transferCriteria.pickupCountry +
      '&pickupCityName=' + this.transferCriteria.pickupCityName + '&pickupCity=' + this.transferCriteria.pickupCity +
      '&dropOffCountry=' + this.transferCriteria.dropOffCountry + '&dropOffCityName=' + this.transferCriteria.dropOffCityName +
      '&dropOffCity=' + this.transferCriteria.dropOffCity + locationParams +
      '&startDate=' + moment(new Date(this.transferCriteria.onewayPickupTime)).format('YYYY-MM-DD') + '&products=TRS';

    if (this.transferCriteria.dropoffLocationType && this.transferCriteria.dropoffLocationType !== this.TRANSFER_ENABLE_LOCATION_TYPE_CODE) {
      queryStr += '&selectedPickupType=' + this.transferCriteria.selectedPickupType + '&selectedDropoffType=' +
        this.transferCriteria.selectedDropoffType;
    }
    if (this.transferCriteria.isReturn) {
      queryStr += '&returnPickupTime=' + this.transferCriteria.returnPickupTime + '&isReturn=true' +
        '&returnDropoffTime=' + this.transferCriteria.returnDropoffTime;
    } else {
      queryStr += '&isReturn=false';
    }
    if (this.ENABLE_TRANSFER_NFR) {
      queryStr += '&expand=summary,source,rate,mode,choices';
    } else {
      queryStr += '&expand=all';
    }

    if (this.isAmendmentFlow) {
      queryStr += '&bkgId=' + this.bookingId;
      queryStr += '&productKey=' + this.productKey;
      queryStr += '&isAmendment=true';
      queryStr += '&reason=' + this.amendmentReasonCode;
      queryStr += '&cause=' + this.amendmentCauseCode;
    }

    return queryStr;
  }

  validateInputs(): boolean {
    let isAllInputsPresent = false;
    let hasValidationErr = false;
    this.isInvalidTime = false;
    if (this.transferCriteria.adult && this.transferCriteria.onewayPickupTime) {
      if (this.isDPSearch || this.isAmendmentFlow) {
        isAllInputsPresent = !!(this.transferCriteria.pickupCity && this.selectedPickUpPoint.displayText &&
          this.transferCriteria.dropOffCity && this.selectedDropOffPoint.displayText);
      } else {
        if (this.transferCriteria.pickupCity && this.selectedPickUpPoint.displayText &&
          this.transferCriteria.dropOffCity && this.selectedDropOffPoint.displayText) {
        isAllInputsPresent = true;
      }
      }
      if (this.transferCriteria.isReturn) {
        if (!(this.transferCriteria.returnPickupTime)) {
          isAllInputsPresent = false;
        }
      }
    }

    if (this.selectedPickupDate && this.selectedReturnDate && (this.selectedPickupDate.getTime() > this.selectedReturnDate.getTime())) {
      this.isInvalidTime = true;
    }

    if (this.hasPickupLocationMismatchErr || this.hasDropoffLocationMismatchErr) {
      hasValidationErr = true;
    }
    if (!this.TRS_ALLOW_LOCATION_ANY_SEARCH && (!this.transferCriteria.pickupLocationType || !this.transferCriteria.dropoffLocationType)) {
      hasValidationErr = true;
    }

    const isLastMinuteBookingValidationPass = this.surfCalendarStartDateService.lastMinuteBookingErrorSet.size <= 0;
    if (this.isAmendmentFlow) {
      return isLastMinuteBookingValidationPass && isAllInputsPresent && !this.isInvalidTime && !hasValidationErr;
    } else {
      return isAllInputsPresent && !this.isInvalidTime && !hasValidationErr;
    }
  }

  validateInputsForDP() {
    let timeValid = true;
    let locationTypeValid = true;
    if (!this.selectedPickupDate) {
      timeValid = false;
    } else if (this.selectedPickupDate && this.selectedReturnDate &&
              (this.selectedPickupDate.getTime() >= this.selectedReturnDate.getTime())) {
      timeValid = false;
    }
    if (this.hasPickupLocationMismatchErr || this.hasDropoffLocationMismatchErr) {
      locationTypeValid = false;
    }
    if (!this.TRS_ALLOW_LOCATION_ANY_SEARCH && (!this.transferCriteria.pickupLocationType || !this.transferCriteria.dropoffLocationType)) {
      locationTypeValid = false;
    }
    // pickup & drop-off points are not validated in DP
    this.isValid.emit(timeValid && locationTypeValid);
  }

  scrollTo(selector) {
    if (!this.isDPSearch) {
      if (this.windowScrollEnable) {
        SurfScrollDirective.scrollTo(
          selector,
          null,
          false,
          false,
          0,
          60
        );
      }
    }
  }

  loadConfigs() {
    this.STATIC_DATA_URL_BASE = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA] ? this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA] : this.STATIC_DATA_URL_BASE;
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();

    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'MIN_SEARCH_QUERY_LENGTH':
            this.MIN_SEARCH_QUERY_LENGTH = mc.name;
            break;
          case 'MAX_ROOMS':
            this.MAX_ROOMS = mc.name;
            break;
          case 'MIN_ADULT':
            this.TRS_MIN_ADULT = mc.name;
            break;
          case 'MAX_ADULT':
            this.TRS_MAX_ADULT = mc.name;
            break;
          case 'MAX_PAX_COUNT':
            this.TRS_MAX_PAX_COUNT = mc.name;
            break;
          case 'MAX_CABIN_PAX_COUNT':
            this.TRS_MAX_CABIN_PAX_COUNT = mc.name;
            break;
          case 'DEFAULT_ADULT_AGE':
            this.DEFAULT_ADULT_AGE = mc.name;
            break;
          case 'INITIAL_DURATION':
            this.INITIAL_DURATION = mc.name;
            break;
          case 'DEFAULT_CALENDAR_OFFSET':
            this.DEFAULT_OFFSET = mc.name;
            break;
          case 'CALENDAR_RANGE':
            this.CALENDAR_RANGE = mc.name;
            break;
          case 'DEFAULT_DRIVER_AGE':
            this.DEFAULT_DRIVER_AGE = mc.name;
            break;
          case 'DISABLE_DP_SWITCH':
            this.DISABLE_DP_SWITCH = (mc.name === 'true');
            break;
          case 'DEFAULT_CONTRACT_TYPE':
            this.DEFAULT_CONTRACT_TYPE = mc.name;
            break;
          case 'MAX_CHILD_AGE':
            this.MAX_CHILD_AGE = +mc.name;
            break;
          case 'MAX_INFANT_AGE':
            this.MAX_INFANT_AGE = +mc.name;
            break;
          case 'STATIC_DATA_FETCH_SIZE':
            this.STATIC_DATA_FETCH_SIZE = mc.name;
            break;
          case 'TRS_SEARCH_RESULT_COUNT':
            this.TRS_SEARCH_RESULT_COUNT = mc.name;
            break;
          case 'TRS_LOCATION_TYPES':
            this.TRS_LOCATION_TYPES = mc.name;
            let def_location = this.TRS_LOCATION_TYPES.split('~')[0];
            this.TRS_DEFAULT_LOCATION_CODE = def_location.split(':')[0];
            this.TRS_DEFAULT_LOCATION_NAME = def_location.split(':')[1];
            this.setPickupDropOffTypes(true);
            break;
          case 'TRS_ALLOW_LOCATION_ANY_SEARCH':
            this.TRS_ALLOW_LOCATION_ANY_SEARCH = (typeof mc.name == 'string') ? mc.name === 'true' : mc.name;
            break;
          case 'TEEN_PAX_ACTIVE':
            this.TEEN_PAX_ACTIVE = ((mc.name as string) === 'true');
            break;
          case 'ENABLE_TRANSFER_RECOMMENDATION':
            this.enableTransferRecommendation = ((mc.name) ? mc.name.toUpperCase() === 'YES' : false);
            break;
        }
      });
    }
    this.ENABLE_TRANSFER_NFR = this.configLoader.getModuleConfig('ENABLE_TRANSFER_NFR',TC.MODULE_NAME.SURF_B2B).toString().toUpperCase() === 'TRUE';
    const ENABLE_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('ENABLE_DP_PASSENGER_SPLIT', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_PAX_SPLIT;
    if (ENABLE_PAX_SPLIT_CONFIG) {
      ENABLE_PAX_SPLIT = ENABLE_PAX_SPLIT_CONFIG.toString().toUpperCase() === 'TRUE';
    }
    const ENABLE_TRS_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('DP_SPLIT_PAX_ENABLED_PRODUCTS', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_TRS_PAX_SPLIT;
    if (ENABLE_TRS_PAX_SPLIT_CONFIG) {
      ENABLE_TRS_PAX_SPLIT = ENABLE_TRS_PAX_SPLIT_CONFIG.toString().split(',').includes('TRS');
    }
    if (ENABLE_PAX_SPLIT && ENABLE_TRS_PAX_SPLIT) {
      this.ENABLE_PAX_SPLIT = true;
    }
    this.pickupUrl = this.STATIC_DATA_URL_BASE +
      '?dataTypes=AIRPORT~CITY~HOTEL~LOCATION&userInput=[UI]&aggrLevel=NA&locale=en&matchType=CODE~NAME&expand=all&resultCount=' +
      this.STATIC_DATA_FETCH_SIZE;
    this.dropoffUrl = this.pickupUrl;
  }

  /**
   * Handles the typeahead search results for both pick-up and drop-off locations
   * @param event
   */
  getDestinations(event) {

    this.showPickUpRecommendationList = !(event && event.filteredList && event.filteredList.length > 0)
      && (event && event.property && event.property.id === this.pickupLocationPropId);
    this.showDropOffRecommendationList = !(event && event.filteredList && event.filteredList.length > 0)
      && (event && event.property && event.property.id === this.dropoffLocationPropId);
    if (event.property.id === this.pickupLocationPropId) {
      this.typeAheadPickupHasError = false;
      this.transferCriteria.selectedPickupType = null;
    } else if (event.property.id === this.dropoffLocationPropId) {
      this.typeAheadDropoffHasError = false;
      this.transferCriteria.selectedDropoffType = null;
    }
    if (event && event.filteredList && event.filteredList.length > 0) {
      if (event.property.id === this.pickupLocationPropId) {
        this.showPickupTypeAheadContent = true;
        this.pickupFilterListError = false;
      }
      else if (event.property.id === this.dropoffLocationPropId) {
        this.showDropoffTypeAheadContent = true;
        this.dropoffFilterListError = false;
      }
      this.returnedProperty = event.property;
      this.filteredDestinations = event.filteredList;

    } else {
      if (event.property && event.property.selectedItem && event.property.selectedItem.displayText.length < 3) {
        if (event.property.id === this.pickupLocationPropId) {
          this.pickupFilterListError = false;
          this.transferCriteria.pickupCountry = null;
          this.transferCriteria.pickupCity = null;
          this.transferCriteria.pickupCityName = null;
          this.transferCriteria.pickupLocation = null;
          this.transferCriteria.pickupLocationCode = null;
          this.typeAheadPickupHasError = false;
          this.transferCriteria.selectedPickupType = null;
        } else if (event.property.id === this.dropoffLocationPropId) {
          this.dropoffFilterListError = false;
          this.transferCriteria.dropOffCountry = null;
          this.transferCriteria.dropOffCity = null;
          this.transferCriteria.dropOffCityName = null;
          this.transferCriteria.dropoffLocationCode = null;
          this.typeAheadDropoffHasError = false;
          this.transferCriteria.selectedDropoffType = null;
        }
      } else {
        if (event.property.id === this.pickupLocationPropId) {
          this.transferCriteria.pickupCountry = null;
          this.transferCriteria.pickupCity = null;
          this.transferCriteria.pickupCityName = null;
          this.transferCriteria.pickupLocation = null;
          this.transferCriteria.pickupLocationCode = null;
          this.typeAheadPickupHasError = true;
          this.transferCriteria.selectedPickupType = null;
        } else if (event.property.id === this.dropoffLocationPropId) {
          this.transferCriteria.dropOffCountry = null;
          this.transferCriteria.dropOffCity = null;
          this.transferCriteria.dropOffCityName = null;
          this.transferCriteria.dropoffLocationCode = null;
          this.typeAheadDropoffHasError = true;
          this.transferCriteria.selectedDropoffType = null;
        }
      }
      this.showPickupTypeAheadContent = false;
      this.showDropoffTypeAheadContent = false;
    }

    if (this.typeAheadPickupHasError) {
      this.pickupFilterListError = true;
      this.pickupInputErrorMsg = 'No results found';
    }
    if (this.typeAheadDropoffHasError) {
      this.dropoffFilterListError = true;
      this.dropoffInputErrorMsg = 'No results found';
    }
    if (this.transferCriteria.productCode === undefined) {
      this.transferCriteria.productCode = 'TRS';
    }
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(this.transferCriteria, 'TRS');
    }
  }

  handleSuggestionSelect(event, id) {
    if (event && event.tempDetails) {
      if (this.pickupLocationPropId === id && this.pickUpProperties.filter(property => property.type === 'type-ahed').length > 0) {
        this.returnedProperty = this.pickUpProperties.filter(property => property.type === 'type-ahed')[0];
      } else if (this.dropoffLocationPropId === id && this.dropoffProperties.filter(property => property.type === 'type-ahed').length > 0) {
        this.returnedProperty = this.dropoffProperties.filter(property => property.type === 'type-ahed')[0];
      }
      this.handleSelection(event.tempDetails);
      this.showPickUpRecommendationList = false;
      this.showDropOffRecommendationList = false;
    }
  }

  handleSelection(item) {
    if (item && this.returnedProperty) {

      if (!this.datePickerFocus && this.returnedProperty.id !== this.pickupLocationPropId && !this.selectedPickupDate) {
        setTimeout(() => {
          this.guestComponentFocus = false;
          this.datePickerFocus = true;
          this.isLastItemInMultiInput = false;
        }, this.setTimeoutValue);
      }

      this.showPickupTypeAheadContent = false;
      this.showDropoffTypeAheadContent = false;

      if (this.returnedProperty.id === this.pickupLocationPropId) {
        this.selectedPickUpPoint.displayText = item.name;
        this.selectedPickUpPoint.query = item.name;
        this.transferCriteria.selectedPickupType = item.type;
        this.hasPickupLocationMismatchErr = false;
        this.selectedPickupCriteria = item;
        this.previousSelectedPickupCriteria = item;
        this.pressEscPickup = item.isPressEsc;
        this.calendarMinDate = new Date();
        for (let i = 0; i < item.attributes?.length ; i++) {
          if (item.attributes[i].name === 'current_time') {
            this.calendarMinDate = new Date(item.attributes[i].value);
          }
        }
        this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'TRS');
        // if CITY selected as pickup location
        if (item.type === 'CITY' || item.type === 40) {
          if (this.ENABLED_TRANSFER_OPEN_SEARCH_FLOW) {
            this.autoSelectLocationType(1, this.TRANSFER_ENABLE_LOCATION_TYPE_CODE);
          }
          this.transferCriteria.pickupCity = this.selectedPickupCriteria.code;
          this.transferCriteria.pickupCityName = this.selectedPickupCriteria.name;
          this.transferCriteria.pickupCountry = this.selectedPickupCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).code;
          this.transferCriteria.pickupLocation = null;
          this.transferCriteria.pickupLocationCode = null; // pickup location code has to be null for city search. Please do not set a value
          this.transferCriteria.pickupLocationName = this.selectedPickupCriteria.name;
        }

        // if AIRPORT selected as pickup location
        if (item.type === 'AIRPORT' || item.type === 60) {
          this.forcefullyChangeTypes(item);
          this.autoSelectLocationType(1, LOCATION_TYPE.AIRPORT);
          this.transferCriteria.pickupCity = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).code;
          this.transferCriteria.pickupCityName = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).name;
          this.transferCriteria.pickupCountry = this.selectedPickupCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).code;
          this.transferCriteria.pickupLocation = this.selectedPickupCriteria.name;
          this.transferCriteria.pickupLocationCode = this.selectedPickupCriteria.code;
          this.transferCriteria.pickupLocationName = this.selectedPickupCriteria.name;
        }

        // if HOTEL selected as pickup location
        if (item.type === 'HOTEL' || item.type === 80) {
          this.forcefullyChangeTypes(item);
          this.autoSelectLocationType(1, LOCATION_TYPE.HOTEL);
          this.transferCriteria.pickupCity = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).code;
          this.transferCriteria.pickupCityName = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).name;
          this.transferCriteria.pickupCountry = this.selectedPickupCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).code;
          this.transferCriteria.pickupLocation = this.selectedPickupCriteria.name;
          this.transferCriteria.pickupLocationCode = this.selectedPickupCriteria.code;
          this.transferCriteria.pickupLocationName = this.selectedPickupCriteria.name;
        }

        // if LOCATION selected as pickup location
        if (item.type === 'LOCATION' || item.type === 100) {
          this.forcefullyChangeTypes(item);
          this.autoSelectLocationType(1, LOCATION_TYPE.LOCATION);
          this.transferCriteria.pickupCity = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).code;
          this.transferCriteria.pickupCityName = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).name;
          this.transferCriteria.pickupCountry = this.selectedPickupCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).code;
          this.transferCriteria.pickupLocation = this.selectedPickupCriteria.name;
          this.transferCriteria.pickupLocationCode = this.selectedPickupCriteria.id;
          this.transferCriteria.pickupLocationName = this.selectedPickupCriteria.name;
        }

        this.emitDPDestValue(item,'PICK');
        this.triggerChange = !this.triggerChange;
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkLocationChange(this.returnedProperty.id);
        }
      }

      else if (this.returnedProperty.id === this.dropoffLocationPropId) {
        this.selectedDropOffPoint.displayText = item.name;
        this.selectedDropOffPoint.query = item.name;
        this.transferCriteria.selectedDropoffType = item.type;
        this.hasDropoffLocationMismatchErr = false;
        this.selectedDropCriteria = item;
        this.previousSelectedDropCriteria = item;
        this.pressEscDropoff = item.isPressEsc;

        // if CITY selected as drop-off location
        if (item.type === 'CITY' || item.type === 40) {
          this.autoSelectLocationType(0, this.TRANSFER_ENABLE_LOCATION_TYPE_CODE);
          this.transferCriteria.dropOffCity = this.selectedDropCriteria.code;
          this.transferCriteria.dropOffCityName = this.selectedDropCriteria.name;
          this.transferCriteria.dropOffCountry = this.selectedDropCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).code;
          this.transferCriteria.dropOffCountryName = this.selectedDropCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).name;
            this.transferCriteria.returnLocation = null;
            // this.transferCriteria.dropoffLocationType = null;
            this.transferCriteria.dropoffLocationCode = null; // dropoff location code has to be null for city search. Please do not set a value
            this.transferCriteria.dropOffLocationName = this.selectedDropCriteria.name;
            this.transferCriteria.destinationType = DestinationType.CITY;
        }

        // if AIRPORT selected as drop-off location
        if (item.type === 'AIRPORT' || item.type === 60) {
          this.forcefullyChangeTypes(item);
          this.autoSelectLocationType(0, 'AIRPORT');
          this.transferCriteria.dropOffCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
          this.transferCriteria.dropOffCityName = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).name;
          this.transferCriteria.dropOffCountry = this.selectedDropCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).code;
          this.transferCriteria.dropOffCountryName = this.selectedDropCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).name;
          this.transferCriteria.returnLocation = this.selectedDropCriteria.name;
          this.transferCriteria.dropoffLocationCode = this.selectedDropCriteria.code;
          // this.transferCriteria.dropoffLocationType = 'AIRPORT';
          this.transferCriteria.dropOffLocationName = this.selectedDropCriteria.name;
          this.transferCriteria.destinationType = DestinationType.CITY;
        }

        // if HOTEL selected as drop-off location
        if (item.type === 'HOTEL' || item.type === 80) {
          this.forcefullyChangeTypes(item);
          this.autoSelectLocationType(0, LOCATION_TYPE.HOTEL);
          this.transferCriteria.dropOffCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
          this.transferCriteria.dropOffCityName = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).name;
          this.transferCriteria.dropOffCountry = this.selectedDropCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).code;
          this.transferCriteria.dropOffCountryName = this.selectedDropCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).name;
          this.transferCriteria.returnLocation = this.selectedDropCriteria.name;
          this.transferCriteria.dropoffLocationCode = this.selectedDropCriteria.code;
          // this.transferCriteria.dropoffLocationType = 'HOTEL';
          this.transferCriteria.dropOffLocationName = this.selectedDropCriteria.name;
          this.transferCriteria.destinationType = DestinationType.HOTEL;
        }

        // if LOCATION selected as drop-off location
        if (item.type === 'LOCATION' || item.type === 100) {
          this.forcefullyChangeTypes(item);
          this.autoSelectLocationType(0, LOCATION_TYPE.LOCATION);
          this.transferCriteria.dropOffCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
          this.transferCriteria.dropOffCityName = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).name;
          this.transferCriteria.dropOffCountry = this.selectedDropCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).code;
          this.transferCriteria.dropOffCountryName = this.selectedDropCriteria.parent.find(p => {
            return p.type === 'COUNTRY' || p.type === 20;
          }).name;
          this.transferCriteria.returnLocation = this.selectedDropCriteria.name;
          this.transferCriteria.dropoffLocationCode = this.selectedDropCriteria.id;
          // this.transferCriteria.dropoffLocationType = 'LOCATION';
          this.transferCriteria.dropOffLocationName = this.selectedDropCriteria.name;
          this.transferCriteria.destinationType = DestinationType.CITY;
        }

        this.triggerChange = !this.triggerChange;

        this.emitDPDestValue(item,'DROP');
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkLocationChange(this.returnedProperty.id);
        }
      }

      if (this.transferCriteria.productCode === undefined) {
        this.transferCriteria.productCode = 'TRS';
      }
      if (!this.isRefine && !this.isDPSearch) {
        this.commonService.updateComponentCriteria(this.transferCriteria, 'TRS');
      }
      if (this.isDPSearch) {
        this.validateInputsForDP();
      }
    }
  }

  /**
   * Forcefully change locationTypes
   */
  forcefullyChangeTypes(item) {
    if (item.type === 80 || item.type === LOCATION_TYPE.HOTEL) {
      this.forcefullyChangeLocationTypesForType(LOCATION_TYPE.AIRPORT);
      this.forcefullyChangeLocationTypesForType(LOCATION_TYPE.LOCATION);

    } else if (item.type === 60 || item.type === LOCATION_TYPE.AIRPORT) {
      this.forcefullyChangeLocationTypesForType(LOCATION_TYPE.HOTEL);
      this.forcefullyChangeLocationTypesForType(LOCATION_TYPE.LOCATION);

    } else if (item.type === LOCATION_TYPE.LOCATION || item.type === 100) {
      this.forcefullyChangeLocationTypesForType(LOCATION_TYPE.HOTEL);
      this.forcefullyChangeLocationTypesForType(LOCATION_TYPE.AIRPORT);
    }
  }
  /**
   * Forcefully change locationTypes given a type is different
   */
  forcefullyChangeLocationTypesForType(type) {
    if (this.returnedProperty.id === this.pickupLocationPropId) {
      if (this.transferCriteria.pickupLocationType === type) {
        this.autoSelectLocationType(true, this.TRANSFER_ENABLE_LOCATION_TYPE_CODE, true);
      }
    }else{
      if (this.transferCriteria.dropoffLocationType === type) {
        this.autoSelectLocationType(false, this.TRANSFER_ENABLE_LOCATION_TYPE_CODE, true);
      }
    }
  }

  /**
   * Handles the pickup and drop-off location type dropdown selections
   * @param event - is of type MultiFieldInputData[]
   * @param inputId - specifies pick-up or drop-off dropdown id
   */
  public handleLocationTypeSelection(event , inputId: string) {
    if (event && event[1]) {
      this.generateLocationUrl(event[1].code, inputId);
      if (inputId == this.pickupTypePropId) {
        if (event[1].code == this.TRS_DEFAULT_LOCATION_CODE) {
          this.transferCriteria.pickupLocationType = null;
        }
        else {
          this.transferCriteria.pickupLocationType = event[1].code;
        }
        this.handleLocationTypeMismatchError(this.transferCriteria.pickupLocationType, this.pickupTypePropId);
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkTypeChange(event[1].code, inputId);
        }
      }
      else if (inputId == this.dropoffTypePropId) {
        if (event[1].code == this.TRS_DEFAULT_LOCATION_CODE) {
          this.transferCriteria.dropoffLocationType = null;
        }
        else {
          this.transferCriteria.dropoffLocationType = event[1].code;
        }
        this.handleLocationTypeMismatchError(this.transferCriteria.dropoffLocationType, this.dropoffTypePropId);
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkTypeChange(event[1].code, inputId);
        }
      }
      // if (inputId == this.pickupTypePropId) {
      //   this.emitDPDestValue('PICK');
      // }
      // else if (inputId == this.dropoffTypePropId) {
      //   this.emitDPDestValue('DROP');
      // }

      if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
        this.commonService.updateComponentCriteria(this.transferCriteria, 'TRS');
      }
      if (this.isDPSearch) {
        this.validateInputsForDP();
      }
    }
  }

  /**
   * Generates static data service url for a location typeahead based on the location type
   * @param locationType
   * @param inputId
   */
  private generateLocationUrl (locationType : string, inputId : string) {
    let dataTypeStr = '';
    switch (locationType) {
      case this.TRS_DEFAULT_LOCATION_CODE:
        dataTypeStr = 'AIRPORT~CITY~HOTEL~LOCATION';
        break;
      case LOCATION_TYPE.HOTEL:
        dataTypeStr = 'CITY~HOTEL';
        break;
      case LOCATION_TYPE.AIRPORT:
        dataTypeStr = 'CITY~AIRPORT';
        break;
      case LOCATION_TYPE.LOCATION:
        dataTypeStr = 'CITY~LOCATION';
        break;
      case LOCATION_TYPE.CRUISE_PORT:
        dataTypeStr = 'CITY';
        break;
      default:
        dataTypeStr = 'AIRPORT~CITY~HOTEL~LOCATION';
        break;
    }
    if (inputId == this.pickupTypePropId) {
      this.pickupUrl = this.pickupUrl.replace(this.dataTypesRegex, '$1' + dataTypeStr + '$3');
      this.pickUpLocationProperty.staticDataUrl = this.pickupUrl;
    }
    else if (inputId == this.dropoffTypePropId) {
      this.dropoffUrl = this.dropoffUrl.replace(this.dataTypesRegex, '$1' + dataTypeStr + '$3');
      this.dropOffLocationProperty.staticDataUrl = this.dropoffUrl;
    }
  }

  /**
   * automatically select the Location type when not selected for Component flow
   */
  autoSelectLocationType(isPickup, type, isForced = false) {
    if (this.ENABLED_TRANSFER_OPEN_SEARCH_FLOW || isForced) {
      const returnEvent = [];
      returnEvent.push([]);
      if (isPickup && this.pickUpValues[1] && this.pickUpValues[1][0] ) {
        //this.pickUpValues[1][0].selected = false;
        this.pickUpValues[1].forEach((m)=>{m.selected=false})
        this.pickUpValues[1].filter(pickupValue => pickupValue.code  === type)[0].selected = true;
        let selectedType=this.pickUpValues[1].find(pickupValue => pickupValue.code  === type)
        returnEvent.push(selectedType);
        this.handleLocationTypeSelection(returnEvent, this.pickupTypePropId);
      }
      if (!isPickup && this.dropoffValues[1] && this.dropoffValues[1][0] ) {
        //this.dropoffValues[1][0].selected = false;
        this.dropoffValues[1].forEach((m)=>{m.selected=false})
        this.dropoffValues[1].filter(dropOffValue => dropOffValue.code  === type)[0].selected = true;
        returnEvent.push({code: type});
        this.handleLocationTypeSelection(returnEvent, this.dropoffTypePropId);
      }
    }
  }

  /**
   * handle error validation if currently selected location and location type is a mismatch
   * @param criteriaLocationType - the particular transfer criteria location type
   * @param inputId - specifies pick-up or drop-off dropdown id
   */
  private handleLocationTypeMismatchError(criteriaLocationType: string, inputId: string) {
    switch (criteriaLocationType) {
      case LOCATION_TYPE.AIRPORT:
        if ( inputId == this.pickupTypePropId) {
          if (this.transferCriteria.selectedPickupType == 80 || this.transferCriteria.selectedPickupType == 100 ) {
            this.pickupFilterListError = true;
            this.hasPickupLocationMismatchErr = true;
            this.pickupInputErrorMsg = 'Selected location and type mismatch';
          }
          else {
            this.pickupFilterListError = false;
            this.hasPickupLocationMismatchErr = false;
          }
        }
        else if (inputId == this.dropoffTypePropId) {
          if (this.transferCriteria.selectedDropoffType == 80 || this.transferCriteria.selectedDropoffType == 100) {
            this.dropoffFilterListError = true;
            this.hasDropoffLocationMismatchErr = true;
            this.dropoffInputErrorMsg = 'Selected location and type mismatch';
          }
          else {
            this.dropoffFilterListError = false;
            this.hasDropoffLocationMismatchErr = false;
          }
        }
        break;
      case LOCATION_TYPE.HOTEL:
        if ( inputId == this.pickupTypePropId) {
          if (this.transferCriteria.selectedPickupType == 60 || this.transferCriteria.selectedPickupType == 100 ) {
            this.pickupFilterListError = true;
            this.hasPickupLocationMismatchErr = true;
            this.pickupInputErrorMsg = 'Selected location and type mismatch';
          }
          else {
            this.pickupFilterListError = false;
            this.hasPickupLocationMismatchErr = false;
          }
        }
        else if (inputId == this.dropoffTypePropId) {
          if (this.transferCriteria.selectedDropoffType == 60 || this.transferCriteria.selectedDropoffType == 100) {
            this.dropoffFilterListError = true;
            this.hasDropoffLocationMismatchErr = true;
            this.dropoffInputErrorMsg = 'Selected location and type mismatch';
          }
          else {
            this.dropoffFilterListError = false;
            this.hasDropoffLocationMismatchErr = false;
          }
        }
        break;
      case LOCATION_TYPE.LOCATION:
        if ( inputId == this.pickupTypePropId) {
          if (this.transferCriteria.selectedPickupType == 60 || this.transferCriteria.selectedPickupType == 80 ) {
            this.pickupFilterListError = true;
            this.hasPickupLocationMismatchErr = true;
            this.pickupInputErrorMsg = 'Selected location and type mismatch';
          }
          else {
            this.pickupFilterListError = false;
            this.hasPickupLocationMismatchErr = false;
          }
        }
        else if (inputId == this.dropoffTypePropId) {
          if (this.transferCriteria.selectedDropoffType == 60 || this.transferCriteria.selectedDropoffType == 80) {
            this.dropoffFilterListError = true;
            this.hasDropoffLocationMismatchErr = true;
            this.dropoffInputErrorMsg = 'Selected location and type mismatch';
          }
          else {
            this.dropoffFilterListError = false;
            this.hasDropoffLocationMismatchErr = false;
          }
        }
        break;
      case LOCATION_TYPE.CRUISE_PORT:
        if ( inputId == this.pickupTypePropId) {
          if (this.transferCriteria.selectedPickupType == 60 || this.transferCriteria.selectedPickupType == 80 || this.transferCriteria.selectedPickupType == 100) {
            this.pickupFilterListError = true;
            this.hasPickupLocationMismatchErr = true;
            this.pickupInputErrorMsg = 'Selected location and type mismatch';
          }
          else {
            this.pickupFilterListError = false;
            this.hasPickupLocationMismatchErr = false;
          }
        }
        else if (inputId == this.dropoffTypePropId) {
          if (this.transferCriteria.selectedDropoffType == 60 || this.transferCriteria.selectedDropoffType == 80 || this.transferCriteria.selectedDropoffType == 100) {
            this.dropoffFilterListError = true;
            this.hasDropoffLocationMismatchErr = true;
            this.dropoffInputErrorMsg = 'Selected location and type mismatch';
          }
          else {
            this.dropoffFilterListError = false;
            this.hasDropoffLocationMismatchErr = false;
          }
        }
        break;
      case this.TRANSFER_ENABLE_LOCATION_TYPE_CODE:
        if (inputId === this.pickupTypePropId) {
          this.pickupFilterListError = false;
          this.hasPickupLocationMismatchErr = false;
        } else if (inputId === this.dropoffTypePropId) {
          this.dropoffFilterListError = false;
          this.hasDropoffLocationMismatchErr = false;
        }
        break;
      default:
        if (this.transferCriteria.pickupLocationCode && inputId == this.pickupTypePropId) {
          this.pickupFilterListError = false;
        }
        else if (this.transferCriteria.dropoffLocationCode && inputId == this.dropoffTypePropId) {
          this.dropoffFilterListError = false;
        }
        break;
    }
  }

  /**
   * Creates the change item on pick-up or drop-off value change and updates the DataShareService
   * Note: the change item is used to modify the dp criteriaItemList
   * @param item
   * @param type - TBX location type code
   * Not used any since dpTransferCriteria is passed from parent and therefore parent transfer item is updated automatically
   * TODO: Remove this once verified
   */
  emitDPDestValue(item, type) {
    if (this.isDPSearch && this.isAmendmentFlow) {
      /*NEW IMPL - notify the change
       * change value : for DEST ->  PICKUPCITY~PICKUPLOCATION~DROPOFFCITY~RETURNLOCATION~~*/
      let dpDropOffObj = item.name + ', ' + this.surfCriteriaUtil.getCountry(item)
        + '~' + item.type
        + '~' + (item.type == 80 ? this.surfCriteriaUtil.getCity(item).code : (item.type == 100 ? this.surfCriteriaUtil.getCity(item).code : item.code))
        + '~' + item.name
        + '~' + this.surfCriteriaUtil.getCountry(item)
        + '~' + (item.type == 80 ? item.code : '');

      let value = this.transferCriteria.pickupCity + '~' + this.transferCriteria.pickupLocationCode + '~'
        + this.transferCriteria.dropOffCity + '~' + this.transferCriteria.dropoffLocationCode + '#' + dpDropOffObj + '#' + type;

      let amendTypeString = '';
      if (this.isAmendmentFlow) {
        if (type === 'PICK') {
          amendTypeString = 'PICKUP_LOCATION';
        } else {
          amendTypeString = 'DROP_OFF_LOCATION';
        }
      }

      let changeObj = {
        index: this.index,
        productCode: 'TRS',
        changeType: 'DEST',
        amendmentChangeType : amendTypeString,
        value: value
      };

      this.commonService.updateChangeItem(changeObj);
      this.validateInputsForDP();
    }
  }

  getSelectedDateTime(event, isPickup: boolean) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    this.resetReturnCalendar = false;
    if (event && event.length > 0) {
      if (isPickup) {
        this.selectedPickupDate = event[0];
        if (this.isDPSearch || this.isAmendmentFlow) {
          this.transferCriteria.onewayPickupTime = moment(this.selectedPickupDate).format(format);
          this.dpReturnDisplayDate = this.selectedPickupDate;
          // Set the date gap with the previous item
          const newDate = new Date(this.selectedPickupDate);
          if (this.dpPreItemToDate) {
            const diffTime = Math.abs(newDate.getTime() - this.dpPreItemToDate.getTime());
            this.preItemDayGap = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          }
        }
        if (this.selectedReturnDate && this.selectedReturnDate.getTime() <= this.selectedPickupDate.getTime()) {
          this.selectedReturnDate = null;
          this.transferCriteria.returnPickupTime = null;
          this.transferCriteria.returnDropoffTime = null;
          this.transferCriteria.isReturn = false;
          this.resetReturnCalendar = true;
          this.returnInitialDate = [];
        }
        if (!this.isDPSearch) { // in dp flow date will be updated by the dp criteria
          this.transferCriteria.onewayPickupTime = moment(this.selectedPickupDate).format(format);
          // set dropOff time  for add to cart purposes
          const dropOff = this.transferCriteria.onewayPickupTime.split('T');
          dropOff[1] = '00:00:00';
          this.transferCriteria.onewayDropoffTime = dropOff.join('T');
        }
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkPickupDateChange(true);
        }
      } else {
        this.selectedReturnDate = event[0];
        if (this.selectedPickupDate && this.selectedPickupDate.getTime() >= this.selectedReturnDate.getTime()) {
          // this.selectedPickupDate = null;
          // this.resetReturnCalendar = true;
        }
        this.transferCriteria.returnPickupTime = moment(this.selectedReturnDate).format(format);
        // set dropOff time  for add to cart purposes
        const dropOff = this.transferCriteria.returnPickupTime.split('T');
        dropOff[1] = '00:00:00';
        this.transferCriteria.returnDropoffTime = dropOff.join('T');
        this.transferCriteria.isReturn = true;
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkPickupDateChange(false);
        }
      }
    } else {
      // executes if invalid date and time is typed
      if (isPickup) {
        this.selectedPickupDate = null;
        this.transferCriteria.onewayPickupTime = null;
        this.transferCriteria.onewayDropoffTime = null;
      } else {
        this.selectedReturnDate = null;
        this.transferCriteria.isReturn = false;
        this.transferCriteria.returnPickupTime = null;
        this.transferCriteria.returnDropoffTime = null;
      }
    }
    if (this.transferCriteria.productCode === undefined) {
      this.transferCriteria.productCode = 'TRS';
    }
    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.transferCriteria, 'TRS');
    }

    if (this.isDPSearch) {
      let amendTypeString = '';
      if (this.isAmendmentFlow) {
        if (isPickup) {
          amendTypeString = 'START_DATE';
        } else {
          amendTypeString = 'END_DATE';
        }
      }
      // TODO: no need of change item due to reason mentioned in emitDPDestValue function. Remove after verifying
    //   /*NEW IMPL - notify the change
    //   *change value : for DATE ->  ONEWAYPICKUPTIME~returnPickupTime*/
      const value = moment(this.selectedPickupDate).format(format);
      const changeObj = {
        index : this.index,
        productCode : 'TRS',
        changeType : 'DATE',
        amendmentChangeType : amendTypeString,
        value
      };
      this.commonService.updateChangeItem(changeObj);
      this.validateInputsForDP();
    }
    if (this.transferCriteria && this.transferCriteria.onewayPickupTime) {
      this.travellerEarliestDepartureDateService
        .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.transferCriteria.onewayPickupTime);
      if (this.isAmendmentFlow) {
        if (this.selectedPickupDate) {
          this.doCheckLastMinuteBookingDate(this.selectedPickupDate, true, true);
        }
        if (this.selectedReturnDate) {
          this.doCheckLastMinuteBookingDate(this.selectedReturnDate, false, true);
        }
      }
    }
  }

  getPassengerSelection($event: Array<PaxAllocationItem>) {
    if ($event != null && this.validateChildAges($event) && this.validateTeenAges($event)) {
      this.transferCriteria.adult = this.processAdultPaxAllocation($event);

      const children = this.processChildAllocation($event);
      let teens = this.processTeenAllocation($event);
      const infant = this.processInfantAllocation($event);
      if (children.CHD && children.CHD.length > 0) {
        this.transferCriteria.child = children.CHD;
      } else {
        this.transferCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.transferCriteria.teen = teens;
      } else {
        this.transferCriteria.teen = null;
      }
      this.transferCriteria.infant = infant && infant.length > 0 ? infant : null;

      this.commonService.updateComponentCriteria(this.transferCriteria, 'TRS');
    } else {
      this.transferCriteria.adult = null;
      this.transferCriteria.child = null;
      this.transferCriteria.teen = null;
      this.transferCriteria.infant = null;
      this.commonService.updateComponentCriteria(this.transferCriteria, 'TRS');
    }

  }
  processTeenAllocation(paxArray: Array<PaxAllocationItem>) {

    let teens = '';
    // teens
    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.teen.length > 0) {
        let teenAgeMap = {};
        const teenOrder = [];
        paxRoom.teen.forEach(teenAge => {
          if (teenOrder.findIndex(age => age === teenAge) < 0) {
            teenOrder.push(teenAge);
          }
        });
        paxRoom.teen.forEach(teenAge => {
          teenAgeMap[teenAge] ? teenAgeMap[teenAge]++ : teenAgeMap[teenAge] = 1;
        });
        for (let age of teenOrder) {
          const key = age + '';
          let count = teenAgeMap[key] + '';
          /* TODO update this logic based on guest age setup */
          /*teens*/
          teens += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
          teens += this.AGE_SEPERATOR;

        }
      }
      roomNo++;
    });

    if (teens.length > 2) {
      teens = teens.slice(0, teens.length - 2);
    }
    return teens;
  }
  processChildAllocation(paxArray: Array<PaxAllocationItem>) {

    const children = {
      INF: '',
      CHD: '',
    };

    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.child.length > 0) {

        const childAgeMap = {};
        const childOrder = [];
        paxRoom.child.forEach(childAge => {
          if (childOrder.findIndex(age => age === childAge) < 0) {
            childOrder.push(childAge);
          }
        });
        paxRoom.child.forEach(childAge => {
          childAgeMap[childAge] ? childAgeMap[childAge]++ : childAgeMap[childAge] = 1;
        });

        for (const age of childOrder) {
          const key = age + '';
          const count = childAgeMap[key] + '';
          if (key.trim() === this.LESS_THAN_ONE) {
            /*Infants*/
            // children.INF += count + fieldSeperator + 0 + fieldSeperator + this.convertDateObjToStr(this.getDOB(0)) + fieldSeperator + roomNo + ageSeperator;
            children.INF += count + this.FIELD_SEPERATOR + 0 + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          } else {
            /*child*/
            // children.CHD += count + fieldSeperator + key + fieldSeperator + this.convertDateObjToStr(this.getDOB(key)) + fieldSeperator + roomNo + ageSeperator;
            children.CHD += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          }
        }
      }
      roomNo++;
    });

    if (children.INF.length > 2) {
      children.INF = children.INF.slice(0, children.INF.length - 2);
    }
    if (children.CHD.length > 2) {
      children.CHD = children.CHD.slice(0, children.CHD.length - 2);
    }

    return children;
  }

  processInfantAllocation(paxArray: Array<PaxAllocationItem>) {
    let roomNo = 1;
    let inf = '';
    if (paxArray.length > 1) {
      paxArray.forEach(paxRoom => {
        inf += (paxRoom.infant.length) + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR + '__' + roomNo + '__' ;
        roomNo ++;
      });
    } else {
      inf = (paxArray[0].infant.length) + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR ;
    }
    return inf;
  }

  processAdultPaxAllocation(paxArray: Array<PaxAllocationItem>) {

    const defaultAdultAge =  this.DEFAULT_ADULT_AGE;
    const fieldSeperator = '~';
    const roomSeperator = '__';

    let paxStr = '';

    for (let i = 0 ; i < paxArray.length ; i++) {
      const paxItem = paxArray[i];
      if (i !== 0) {
        paxStr = paxStr + roomSeperator;
      }
      // paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + this.convertDateObjToStr(this.getDOB(defaultAdultAge)) + fieldSeperator +  (i + 1);
      paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + 'DOB' + fieldSeperator + (i + 1);
    }

    return paxStr;
  }

  /**
   * check for invalid child ages
   * @param selectedPax
   */
  validateChildAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => acc && ( pax.child.indexOf(-1) > -1 ? false : true ), true);
  }
  validateTeenAges(selectedPax : Array<PaxAllocationItem> ){
    return selectedPax.reduce((acc, pax) => acc && ( pax.teen.indexOf(-1) > -1 ? false : true ), true);
  }
  getCurrentOption($event) {
    this.currentPaxOption = $event;
  }
  /**
   * handle focusout
   */
  onFocusOut($event, component) {
    if (component === 1) {
      this.guestComponentFocus = false;
    } else if (component === 2) {
      this.datePickerFocus = false;
    }
  }

  /**
   * check focusing input is the last input of multi field input
   * param isLastItem
   */
  ShowLastElement(isLastItem) {
    this.isLastItemInMultiInput = isLastItem;
  }

  /**
   * handle key down
   */
  onKeyDown($event, component) {
    if ($event.keyCode === KeyCodes.ENTER ) {
      this.guestComponentFocus = false;
      this.datePickerFocus = false;
      if (component === 0) {
        setTimeout(() => {
          // this.datePickerFocus = true;
          this.guestComponentFocus = false;
          if (this.isLastItemInMultiInput === true) {
            this.datePickerFocus = true;
            this.isLastItemInMultiInput = false;
          }
        }, this.setTimeoutValue);
      } else if (component === 1) {
        if (this.currentPaxOption === 3) {
          if (this.enterPressed && (this.transferCriteria.nights === undefined || this.transferCriteria.nights === 0)) {
            setTimeout(() => {
              this.guestComponentFocus = false;
              this.datePickerFocus = false;
            }, this.setTimeoutValue);
          }
          this.enterPressed = true;
        }
        if (this.currentPaxOption !== 3 && (this.transferCriteria.nights === undefined || this.transferCriteria.nights === 0)) {
          setTimeout(() => {
            this.guestComponentFocus = false;
            this.datePickerFocus = false;
          }, this.setTimeoutValue);
          this.enterPressed = false;
        }
      } else if (component === 2) {
        setTimeout(() => {
          this.guestComponentFocus = false;
          this.datePickerFocus = false;
          this.onKeyDownLast.emit(true);
        }, this.setTimeoutValue);
      }
    }

    if ($event.keyCode === KeyCodes.UPARROW || $event.keyCode === KeyCodes.DOWNARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = false;
      }
    }

    if ($event.keyCode === KeyCodes.RIGHTARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = true;
      }
    }
  }

  getSearchQuery(event) {
    if (event) {
      if (event.length < this.MIN_SEARCH_QUERY_LENGTH) {
        this.isQueryError = false;
      } else {
        this.isQueryError = true;
      }
    }
  }

  updateDPCriteria(event) {
    /*in the DP flow, update the relavent product criteria inside the product criteria list*/
    const idx = this.surfCriteriaUtil.getCriteriaItemArrayIndex(this.productCriteriaList, 'TRS', this.index);
    if (this.productCriteriaList && this.productCriteriaList[idx]) {
      this.mapToDPCriteriaItem(this.productCriteriaList[idx], this.transferCriteria, event);
    } else {
      const trsCriItem = this.surfCriteriaUtil.getNewCriItem('TRS', this.index);
      this.mapToDPCriteriaItem(trsCriItem, this.transferCriteria, event);
      this.productCriteriaList.push(trsCriItem);
    }
    this.validateInputsForDP();
    this.commonService.updateProductCriteriaList(this.productCriteriaList);
  }

  mapToDPCriteriaItem(dpCriItem, criItem, event) {
    if (event === 'dest-name') {
      if (criItem.destinationName) {
        dpCriItem.destinationName = JSON.parse(JSON.stringify(criItem.destinationName));
      }
    }
    if (event === 'pickup-dropoff') {
      if (criItem.pickupCity) {
        dpCriItem.pickupCity = JSON.parse(JSON.stringify(criItem.pickupCity));
      }
      if (criItem.pickupLocationCode) {
        dpCriItem.pickupLocationCode = JSON.parse(JSON.stringify(criItem.pickupLocationCode));
      }
      if (criItem.dropOffCity) {
        dpCriItem.dropOffCity = JSON.parse(JSON.stringify(criItem.dropOffCity));
      }
      if (criItem.dropoffLocationCode) {
        dpCriItem.dropoffLocationCode = JSON.parse(JSON.stringify(criItem.dropoffLocationCode));
      }
    }

    if (event === 'date-time') {
      if (criItem.returnPickupTime) {
        dpCriItem.returnPickupTime = JSON.parse(JSON.stringify(criItem.returnPickupTime));
      }
      if (criItem.onewayPickupTime) {
        dpCriItem.onewayPickupTime = JSON.parse(JSON.stringify(criItem.onewayPickupTime));
      }
    }
  }

  /**
   * handle freeze-unfreeze checkbox
   */
  checkValue(event) {
    this.unfreeze = event.target.checked;
    this.freeze = !this.unfreeze;
    this.isUnfreeze.emit(event.target.checked);
  }

  /**
   * set warning true and false with the PickUp/DropOff location input change, by comparing with the initial value.
   */
  checkLocationChange(type) {
    if (this.oldCriteriaItem && this.oldCriteriaItem.criItem &&  this.transferCriteria && this.transferCriteria.pickupLocationName &&
      this.transferCriteria.dropOffLocationName) {
      if (type === 'PICKUP' && this.transferCriteria.pickupCity) {
        this.pickUpProperties[0].warning = !(this.transferCriteria.pickupLocationName === this.oldCriteriaItem.criItem.pickupLocationName &&
          this.transferCriteria.pickupCity === this.oldCriteriaItem.criItem.pickupCity);
      } else if (type === 'DROP' && this.transferCriteria.dropOffCity) {
        this.dropoffProperties[0].warning = !(this.transferCriteria.dropOffLocationName === this.oldCriteriaItem.criItem.dropOffLocationName
          && this.transferCriteria.dropOffCity === this.oldCriteriaItem.criItem.dropOffCity);
      } else if (type === 'BOTH' && this.transferCriteria.pickupCity && this.transferCriteria.dropOffCity) {
        this.pickUpProperties[0].warning = !(this.transferCriteria.pickupLocationName === this.oldCriteriaItem.criItem.pickupLocationName &&
          this.transferCriteria.pickupCity === this.oldCriteriaItem.criItem.pickupCity);
        this.dropoffProperties[0].warning = !(this.transferCriteria.dropOffLocationName === this.oldCriteriaItem.criItem.dropOffLocationName
          && this.transferCriteria.dropOffCity === this.oldCriteriaItem.criItem.dropOffCity);
      }
    }
  }

  /**
   * set warning true and false with the PickUp/DropOff type input change, by comparing with the initial value.
   */
  checkTypeChange(item, type) {
    if (this.oldCriteriaItem && this.oldCriteriaItem.criItem) {
      if (type === 'pickupType') {
        this.pickUpProperties[1].warning = item !== this.oldCriteriaItem.criItem.pickupLocationType;
      } else if (type === 'dropoffType') {
        this.dropoffProperties[1].warning = item !== this.oldCriteriaItem.criItem.dropoffLocationType;
      }
    }
  }

  /**
   * set warning true and false with the date input change, by comparing with the initial value.
   */
  checkPickupDateChange(isPickup) {
    if (this.transferCriteria && this.transferCriteria.onewayPickupTime && this.oldCriteriaItem &&
      this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.onewayPickupTime) {
      if (isPickup) {
        this.pickupDateWarning = !(
          this.transferCriteria.onewayPickupTime.split('T')[0] === this.oldCriteriaItem.criItem.onewayPickupTime.split('T')[0]);
      } else {
        this.returnDateWarning = !(
          this.transferCriteria.onewayDropoffTime.split('T')[0] === this.oldCriteriaItem.criItem.onewayDropoffTime.split('T')[0]);
      }
    }
  }

  setPickDropDependent(event, type){
    if( type == 'P' ){
      this.pickupIndependent = !this.pickupIndependent;
      this.transferCriteria.pickupDependent = !this.pickupIndependent;
    }

    if( type == 'D' ){
      this.dropoffIndependent = !this.dropoffIndependent;
      this.transferCriteria.dropoffDependent = !this.dropoffIndependent;
    }

  }

  doCheckLastMinuteBookingDate(date: Date, isPickUp: boolean, isAmendmentOldItemDateSelection: boolean) {
    if (date) {
      let checkingDate = date;
      if (isPickUp) {
        if (this.surfCalendarStartDateService.validateWithCheckingIsEarlierDate(new Date(), checkingDate, 'TRS')) {
          this.hideErrorMsgPickUp = true;
          this.validateDisabledDateSelectionPickUp = false;
          this.surfCalendarStartDateService.lastMinuteBookingErrorSet.delete(this.index + '-PICKUP');
          if (isAmendmentOldItemDateSelection) {
            this.surfCalendarStartDateService.hasValidUnfreezeItem = true;
          }
        } else {
          this.hideErrorMsgPickUp = false;
          this.validateDisabledDateSelectionPickUp = true;
          this.surfCalendarStartDateService.lastMinuteBookingErrorSet.add(this.index + '-PICKUP');
        }
      } else {
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = false;
        }
        if (this.surfCalendarStartDateService.validateWithCheckingIsEarlierDate(new Date(), checkingDate, 'TRS')) {
          this.hideErrorMsgReturn = true;
          this.validateDisabledDateSelectionReturn = false;
          this.surfCalendarStartDateService.lastMinuteBookingErrorSet.delete(this.index + '-RETURN');
        } else {
          this.hideErrorMsgReturn = false;
          this.validateDisabledDateSelectionReturn = true;
          this.surfCalendarStartDateService.lastMinuteBookingErrorSet.add(this.index + '-RETURN');
        }
      }
    }
  }
}
