import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SurfCriteriaUtil, SurfTextCasePipeModule } from '@surf/surf-components-core';
import { SurfDatePickerModule } from '@surf/surf-date-picker';
import { SurfDPAddBarModule } from '@surf/surf-dp-add-bar';
import { SurfDropDownModule } from '@surf/surf-drop-down';
import { SurfGrabTourModule } from '@surf/surf-grab-tour';
import { SurfMultiFieldInputModule } from '@surf/surf-multi-field-input';
import { SurfMultiSelectorDropDownModule } from '@surf/surf-multi-selector-drop-down';
import { SurfPaxSelectionModule } from '@surf/surf-pax-selection';
import { SurfTypeAheadModule } from '@surf/surf-type-ahead';
import { TourCriteriaComponent } from './tour-criteria/tour-criteria.component';
import { TourCriteriaDpComponent } from './tour-criteria-dp/tour-criteria-dp.component';

@NgModule({
  declarations: [TourCriteriaComponent, TourCriteriaDpComponent],
    imports: [
        SurfPaxSelectionModule,
        SurfDPAddBarModule,
        CommonModule,
        SurfTypeAheadModule,
        SurfTextCasePipeModule,
        SurfDropDownModule,
        SurfDatePickerModule,
        SurfMultiSelectorDropDownModule,
        SurfMultiFieldInputModule,
        SurfGrabTourModule
    ],
  exports: [TourCriteriaComponent, TourCriteriaDpComponent],
  providers: [SurfCriteriaUtil]
})
export class SurfTourCriteriaModule {}
