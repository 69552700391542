import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {
  BaseCriteria,
  Configurations,
  DataShareService,
  SurfAuthorizationService, SurfC1RedirectEventProductTypeListEnum,
  SurfCriteriaUtil,
  SurfExternalProductCriteriaEventService, SurfSpecialGenModalConfig, SurfSpecialGenConfigService
} from '@surf/surf-components-core';
import {User} from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import {DataKey, DataStore} from '@tc-core/util/framework/data-store.service';
import {DataServiceHandler} from '@tc-core/service/service-handlers/data-service-handler.service';
import {HotelCriteria} from '@tc-core/model/it/codegen/tbx/api/criteria/hotel/hotel-criteria';
import {TC} from "@tc-core/util";
import Product = TC.Product;
import {ConfigLoader} from "@tc-core/util/framework";
import { CommonHelper } from '@tc-core/util/helpers';
import { PermissionKeys } from '../security/permission-constants';
import searchPanelOperations from '../security/search-panel-operations.json';
import { TbxKeyControlParameter } from '@tc-core/model/it/codegen/tbx/api/tbx-key-control-parameter';
import { SurfScriptOperationsUtil } from '@surf/surf-components-core';

@Component({
  selector: 'surf-search-panel',
  templateUrl: 'search-panel.component.html',
})
export class SearchPanelComponent implements OnInit {

  @Input() moveToDP: boolean = false;
  @Input() activeSearchFlow: string = null;
  @Input() isAddItemSearch = false;
  @Input() enableInBooking = false;
  @Input() showSpecialGen = false;
  @Output() searchFlow: EventEmitter<any> = new EventEmitter<any>();
  @HostBinding('class') classes = 'dashboard__search-block-wrapper';

  // activeSearchFlow: string = null;
  DISABLE_PRODUCT_FLOW = '';
  HIDE_PRODUCT_FLOW = '';
  SHOW_MAN_CRU_FOR_CRU_BUTTON = true;
  disableItemArray = [];
  hiddenItemArray = [];
  specialProductConfigList: SurfSpecialGenModalConfig[] = [];
  genericFlowEnabledProducts = [];
  user: User;
  permissionKeys;

  baseCriteria: BaseCriteria;
  baseHTLCriteria: BaseCriteria;
  baseFLTCriteria: BaseCriteria;
  baseTRSCriteria: BaseCriteria;
  baseCARCriteria: BaseCriteria;
  baseCRUCriteria: BaseCriteria;
  baseGENCriteria: BaseCriteria;

  moduleConfigs: any;
  productStatus = {
    HTL: {productCode: 'HTL', status: false},
    FLT: {productCode: 'FLT', status: false},
    CAR: {productCode: 'CAR', status: false},
    CRU: {productCode: 'CRU', status: false},
    GEN: {productCode: 'GEN', status: false},
    TRS: {productCode: 'TRS', status: false},
    TMD: {productCode: 'TMD', status: false},
    PKG: {productCode: 'PKG', status: false}
  };

  showManualItemDropDown:boolean = false;
  showManualItemConfig = false;
  showManualItemConfigStr:string;
  isActiveManualDropdownIcon = true;
  epLabelName:string = 'Packages';
  EP_ADD_PRODUCT_LABEL_NAME = '';
  brand = '';
  division = '';
  clientGroup = '';

  constructor(private commonService: DataShareService,
              private dataStore: DataStore,
              private configLoader: ConfigLoader,
              private commonHelper: CommonHelper,
              public surfCriteriaUtil : SurfCriteriaUtil,
              private surfScriptOperationsUtil: SurfScriptOperationsUtil,
               private surfSpecialGenConfigService: SurfSpecialGenConfigService,
              private surfAuthorizationService: SurfAuthorizationService,
              private surfExternalProductCriteriaEventService: SurfExternalProductCriteriaEventService) {
  }

  ngOnInit() {
    // surf permissions
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(searchPanelOperations);
    this.isActiveManualItemDropdown();
    // hide /show manual items
    this.showManualItemConfigStr = this.configLoader.getModuleConfig(Configurations.ENABLE_MANUAL_BOOKING_ITEMS, TC.MODULE_NAME.SURF_B2B);
    this.showManualItemConfig = (this.showManualItemConfigStr == "true");
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();
    this.loadConfigs();
    this.getProductStatus();
    this.watchExternalProductCriteriaEvents();
    this.commonService.getBaseCriteria().subscribe(cri => {
      this.baseCriteria = cri;
    });

    this.commonService.getComponentCriteria('HTL').subscribe(cri => {
      this.baseHTLCriteria = cri;
    });

    this.commonService.getComponentCriteria('FLT').subscribe(cri => {
      this.baseFLTCriteria = cri;
    });

    this.commonService.getComponentCriteria('TRS').subscribe(cri => {
      this.baseTRSCriteria = cri;
    });

    this.commonService.getComponentCriteria('CAR').subscribe(cri => {
      this.baseCARCriteria = cri;
    });

    this.commonService.getComponentCriteria('CRU').subscribe(cri => {
      this.baseCRUCriteria = cri;
    });

    this.commonService.getComponentCriteria('GEN').subscribe(cri => {
      this.baseGENCriteria = cri;
    });

    this.commonService.getCurrentMsg().subscribe(msg => {
      if (msg === 'HTL_close' || msg === 'PKG_close' || msg === 'CAR_close'  || msg === 'CRU_close' || msg === 'FLT_close'
        || msg === 'DP_close' || msg === 'GEN_close' || msg === 'TRS_close' || msg === 'MAN_close' || msg === 'TOU_close') {
        this.activeSearchFlow = null;
        this.clearBaseCriteria();
      }
      if (msg != undefined && msg.split('~') != undefined && (msg.split('~')[0] === 'DP_Switch')) {
        this.activeSearchFlow = 'DP';
      }
    });
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    this.loadBrandDivisionCliGrps();

    // const currentURL = window.location.href;
    // if(currentURL.includes('b2b-booking') || currentURL.includes('b2b-cart')){
    //   this.disablePackageBtn=true
    // }

  }

  watchExternalProductCriteriaEvents() {
    this.surfExternalProductCriteriaEventService.watchExternalProductCriteria$.subscribe((data) => {
      if (data) {
        switch (data) {
          case SurfC1RedirectEventProductTypeListEnum.Activity:
            this.selectSearchFlow('GEN');
            break;
          case SurfC1RedirectEventProductTypeListEnum.Car:
            this.selectSearchFlow('CAR');
            break;
          case SurfC1RedirectEventProductTypeListEnum.Cruise:
            this.selectSearchFlow('CRU');
            break;
          case SurfC1RedirectEventProductTypeListEnum.DynamicPackage:
            this.selectSearchFlow('DP');
            break;
          case SurfC1RedirectEventProductTypeListEnum.ElitePackage:
            this.selectSearchFlow('PKG');
            break;
          case SurfC1RedirectEventProductTypeListEnum.Flight:
            this.selectSearchFlow('FLT');
            break;
          case SurfC1RedirectEventProductTypeListEnum.Hotel:
            this.selectSearchFlow('HTL');
            break;
          case SurfC1RedirectEventProductTypeListEnum.Insurance:
            this.selectSearchFlow('INS');
            break;
          case SurfC1RedirectEventProductTypeListEnum.ManualPriceItem:
            this.selectSearchFlow('MAN');
            break;
          case SurfC1RedirectEventProductTypeListEnum.MBICruise:
            this.selectSearchFlow('MAN_CRU');
            break;
          case SurfC1RedirectEventProductTypeListEnum.MBIFlight:
            this.selectSearchFlow('MANFLT');
            break;
          case SurfC1RedirectEventProductTypeListEnum.Tour:
            this.selectSearchFlow('TOU');
            break;
          case SurfC1RedirectEventProductTypeListEnum.Transfer:
            this.selectSearchFlow('TRS');
            break;
        }
      }
    });
  }

  getProductStatus(){
    this.productStatus.HTL.status = !this.surfCriteriaUtil.getProductEnableStatus(this.productStatus.HTL.productCode, 'products');
    this.productStatus.FLT.status = !this.surfCriteriaUtil.getProductEnableStatus(this.productStatus.FLT.productCode, 'products');
    this.productStatus.CAR.status = !this.surfCriteriaUtil.getProductEnableStatus(this.productStatus.CAR.productCode, 'products');
    this.productStatus.CRU.status = !this.surfCriteriaUtil.getProductEnableStatus(this.productStatus.CRU.productCode, 'products');
    this.productStatus.GEN.status = !this.surfCriteriaUtil.getProductEnableStatus(this.productStatus.GEN.productCode, 'products');
    this.productStatus.TRS.status = !this.surfCriteriaUtil.getProductEnableStatus(this.productStatus.TRS.productCode, 'products');
    this.productStatus.PKG.status = !this.surfCriteriaUtil.getProductEnableStatus(this.productStatus.PKG.productCode, 'products');
    this.productStatus.TMD.status = !this.surfCriteriaUtil.getProductEnableStatus(this.productStatus.TMD.productCode, 'products');
  }

  isActiveManualItemDropdown() {
    const mpiAddProduct = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.MPI_ADD_PRODUCT);
    const mbiFltAddProduct = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.MBI_FLT_ADD_PRODUCT);
    const mbiCruiseAddProduct = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.MBI_CRU_ADD_PRODUCT);

    if ((!mpiAddProduct.allowed) && (!mbiFltAddProduct.allowed) && (!mbiCruiseAddProduct.allowed)) {
      this.isActiveManualDropdownIcon = false;
    } else {
      this.isActiveManualDropdownIcon = true;
    }
  }

  selectSearchFlow(type: string): void {
    switch (type) {
      case 'PKG':
        this.activeSearchFlow = 'PKG';
        this.clearBaseCriteria();
        break;
      case 'HTL':
        this.activeSearchFlow = 'HTL';
        this.clearBaseCriteria();
        break;
      case 'FLT':
        this.activeSearchFlow = 'FLT';
        console.log('flt')
        this.clearBaseCriteria();
        break;
      case 'CAR':
        this.activeSearchFlow = 'CAR';
        this.clearBaseCriteria();
        break;
      case 'DP':
        this.activeSearchFlow = 'DP';
        this.clearBaseCriteria();
        break;
      case 'TRS':
        this.activeSearchFlow = 'TRS';
        this.clearBaseCriteria();
        break;
      case 'CRU':
        if (this.SHOW_MAN_CRU_FOR_CRU_BUTTON) {
          this.activeSearchFlow = 'MAN_CRU';
          this.clearBaseCriteria();
        } else {
          this.activeSearchFlow = 'CRU';
          this.clearBaseCriteria();
        }
        break;
      case 'CRU':
        this.activeSearchFlow = 'CRU';
        this.clearBaseCriteria();
        break;
      case 'GEN':
        this.activeSearchFlow = 'GEN';
        this.clearBaseCriteria();
        break;
      case 'INS':
        this.activeSearchFlow = 'INS';
        this.clearBaseCriteria();
        break;
      case 'MAN':
        this.activeSearchFlow = 'MAN';
        this.clearBaseCriteria();
        break;
      case 'MANFLT':
        this.activeSearchFlow = 'MANFLT';
        this.clearBaseCriteria();
        break;
      case 'INS':
        this.activeSearchFlow = 'INS';
        this.clearBaseCriteria();
        break;
      case 'MAN_CRU':
        this.activeSearchFlow = 'MAN_CRU';
        this.clearBaseCriteria();
        break;
      case 'TOU':
        this.activeSearchFlow = 'TOU';
        this.clearBaseCriteria();
        break;
      case this.surfSpecialGenConfigService.getConfig(type).pt:
        this.activeSearchFlow = type;
        this.clearBaseCriteria();
        break;
      default:
        this.activeSearchFlow = '';
        this.clearBaseCriteria();
        break;
    }
    this.showManualItemDropDown = false;
    if (this.genericFlowEnabledProducts.includes(type)) { // force enable the GEN add item flow
      this.searchFlow.emit('GEN');
      return;
    }
    this.searchFlow.emit(this.activeSearchFlow);
  }

  /***
   * show/hide manual item dropdown
   */
  showManualItemDD(){
    this.showManualItemDropDown = !this.showManualItemDropDown;
  }

  checkSpecialGenericProductsEnabledForUser(product) {
    let divisionCode, brandCode;
    if (this.dataStore.get(DataKey.emulating) && this.dataStore.get(DataKey.emulating).getValue() &&
        this.dataStore.get(DataKey.emulatingTCProfile).getValue()) {
      const emulatingTradeClient = this.dataStore.get(DataKey.emulatingTCProfile).getValue();
      divisionCode = emulatingTradeClient.controls.div.toUpperCase();
      brandCode = emulatingTradeClient.controls.brand.toUpperCase();
    } else {
      const user = this.dataStore.get(DataKey.userDetail).getValue();
      divisionCode = user.userDetail.defaultDivison.code?.toUpperCase();
      brandCode = user.userDetail.defaultBrand.code?.toUpperCase();
    }
    const config = this.configLoader.getModuleConfig('ENABLE_SPECIAL_PRODUCTS_DIV_BRANDS', TC.MODULE_NAME.SURF_B2B);
    let productConfig = '';
    if (config) {
      config.trim().split('-').forEach((configItem) => {
        const items = configItem.split('>');
        if (items[0] === product.pt) {
          productConfig = items[1].trim();
        }
      });
    }
    return productConfig ? this.surfScriptOperationsUtil.validateDivBrand(productConfig, divisionCode, brandCode) : false;
  }

  loadConfigs(){
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();

    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'DISABLED_PRODUCT_FLOWS':
            this.DISABLE_PRODUCT_FLOW = mc.name;
            this.disableItemArray = this.DISABLE_PRODUCT_FLOW.toString().split(',');
            break;
          case 'EP_ADD_PRODUCT_LABEL_NAME':
            this.EP_ADD_PRODUCT_LABEL_NAME = mc.name;
            break;
        }
      });
    }
    const SPECIAL_PRODUCT_MODAL_CONFIGS = this.configLoader.getModuleConfig('SPECIAL_PRODUCT_MODAL_CONFIGS', TC.MODULE_NAME.SURF_B2B);
    this.specialProductConfigList = SPECIAL_PRODUCT_MODAL_CONFIGS ? JSON.parse(SPECIAL_PRODUCT_MODAL_CONFIGS) : [];
    const config = this.configLoader.getModuleConfig('GEN_ENABLED_SPECIAL_PRODUCTS', TC.MODULE_NAME.SURF_B2B);
    this.genericFlowEnabledProducts = config ? config.trim().split(',').map(element => {
      return element.trim();
    }) : [];
    this.SHOW_MAN_CRU_FOR_CRU_BUTTON = this.commonHelper.convertToBoolean(this.configLoader.getModuleConfig(
      'SHOW_MAN_CRU_FOR_CRU_BUTTON',
      TC.MODULE_NAME.SURF_B2B
    ));
    this.HIDE_PRODUCT_FLOW = this.configLoader.getModuleConfig(
      'HIDE_PRODUCT_FLOWS',
      TC.MODULE_NAME.SURF_B2B
    );
    if (this.HIDE_PRODUCT_FLOW) {
      this.hiddenItemArray = this.HIDE_PRODUCT_FLOW.toString().split(',');
    }
  }

  loadBrandDivisionCliGrps() {
    /* test data for config*/
    //this.EP_ADD_PRODUCT_LABEL_NAME = 'INFEU~FC~C_Flight Centre Holidays' ;
    //this.EP_ADD_PRODUCT_LABEL_NAME = 'INFUS~ANY~D_Flight Centre Vacations' ;
    //this.EP_ADD_PRODUCT_LABEL_NAME = 'INFUS~FC~ANY_Flight Centre Vacations1' ;
    //this.EP_ADD_PRODUCT_LABEL_NAME = 'INFUS~ANY~ANY_Flight Centre Vacations2' ;
    //this.EP_ADD_PRODUCT_LABEL_NAME = 'ANY' ;
    //this.EP_ADD_PRODUCT_LABEL_NAME = 'INFEU~FC~C_Flight Centre Holidays,INFUS~ANY~D_Flight Centre Vacations,INFUS~FC~B_FC Vacations' ;
    //this.EP_ADD_PRODUCT_LABEL_NAME = 'INFEU~ANY~C_Flight Centre Holidays,INFEU~FC~C_Flight Centre Vacations,INFUS~FC~B_FC Vacations' ;
    //this.EP_ADD_PRODUCT_LABEL_NAME = 'INFEU~~_FC Vacations' ;
    //this.EP_ADD_PRODUCT_LABEL_NAME = '' ;

    const loggedUserClientData = sessionStorage.getItem('blLoggedUserClient') ?
      JSON.parse(sessionStorage.getItem('blLoggedUserClient')) : undefined;

    if (loggedUserClientData) {
      this.division = loggedUserClientData.controls.div;
      this.brand = loggedUserClientData.controls.brand;
      this.clientGroup = loggedUserClientData.controls.cliGrp;
    }

    try{
    const brandDivCliGrps = this.EP_ADD_PRODUCT_LABEL_NAME.split(',');
    let isLabelNameSelected = false;
    brandDivCliGrps.forEach(companyDivisionElement => {
      const brandDivisionIdCliGrpListList = this.splitData(companyDivisionElement, '~');

      const divisionList = brandDivisionIdCliGrpListList && brandDivisionIdCliGrpListList.length > 0 ? this.splitData(brandDivisionIdCliGrpListList[0], ',') : [];
      const brandList = brandDivisionIdCliGrpListList && brandDivisionIdCliGrpListList.length > 1 ? this.splitData(brandDivisionIdCliGrpListList[1], ',') : [];

      const cliGrpLabels = brandDivisionIdCliGrpListList && brandDivisionIdCliGrpListList.length > 2 ? brandDivisionIdCliGrpListList[2].split(',') : [];
      cliGrpLabels.forEach(cliGrpLabelsElement => {
        const cliGrpLabelsListList = this.splitData(cliGrpLabelsElement, '_');
        const cliGrpList = cliGrpLabelsListList && cliGrpLabelsListList.length > 0 ? this.splitData(cliGrpLabelsListList[0], ',') : [];
        const labelsList = cliGrpLabelsListList && cliGrpLabelsListList.length > 1 ? this.splitData(cliGrpLabelsListList[1], ',') : [];

        if(!(isLabelNameSelected)){
          if (((brandList.indexOf(this.brand) !== -1) || (brandList.includes('ANY')) || (brandList.includes('any')) )
            && (divisionList && ((divisionList.indexOf(this.division) !== -1) || (divisionList.includes('ANY')) || (divisionList.includes('any')) ) )
            && (cliGrpList && ((cliGrpList.indexOf(this.clientGroup) !== -1) || (cliGrpList.includes('ANY')) || (cliGrpList.includes('any')) ) )
            ){
            this.epLabelName = labelsList[0];
            isLabelNameSelected =  true;
            }
          else {
            this.epLabelName = 'Packages';
            }
        }
      });
    });
    }
    catch (e) {
      console.log(e);
      this.epLabelName = 'Packages';
    }
  }

  splitData(stringToSplit, splitPrm) {
    let splitDataArray = [];
    try {
      if (stringToSplit.indexOf(splitPrm) >= 0) {
        splitDataArray = stringToSplit.split(splitPrm);
      } else {
        splitDataArray.push(stringToSplit);
      }
    } catch (error) {
      splitDataArray = [];
    }
    return splitDataArray;
  }

  clearBaseCriteria() {
    this.commonService.updateProductCriteriaList([]);
    this.commonService.updateComponentCriteria(new BaseCriteria(), 'HTL');
    this.commonService.updateComponentCriteria(new BaseCriteria(), 'FLT');
    this.commonService.updateComponentCriteria(new BaseCriteria(), 'TRS');
    this.commonService.updateComponentCriteria(new BaseCriteria(), 'CAR');
    this.commonService.updateComponentCriteria(new BaseCriteria(), 'CRU');
    this.commonService.updateComponentCriteria(new BaseCriteria(), 'GEN');
    this.commonService.updateComponentCriteria(new BaseCriteria(), 'TOU');
  }

  onClickOutside(event) {
    this.showManualItemDropDown = false;
  }
}
