<div [ngClass]="{'c-advance-search__content': !isDPSearch}">
  <ng-container *ngIf="!isServiceAddComponent; else elseblock">
    <div class="c-advance-search__content-top" *ngIf="!isDPSearch">
      <button class="a-btn a-btn--link c-pkg-search__back-btn" (click)="closeCriteriaBox()">
        <svg class="a-icon u-mr">
          <use xlink:href="#previous"></use>
        </svg>
      </button>
      <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Search cruises for</h3>
      <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine your cruise search</h3>
      <div class="c-advance-search__guests">
        <div class="m-form-item auto-pax-count" (click)="scrollTo('#scroll')">
          <surf-pax-selection
            [(isFocused)]="guestComponentFocus"
            [paxSet]="defaultPaxSet"
            [product]="'FLT'"
            [inputWrapperView]="false"
            [maxAdult]="CRU_MAX_ADULT"
            [minAdult]="CRU_MIN_ADULT"
            [maxPaxCount]="CRU_MAX_PAX_COUNT"
            [maxCabinPaxCount]="CRU_MAX_CABIN_PAX_COUNT"
            [maxChildren]="CRU_MAX_CHILD"
            [maxTeen]="CRU_MAX_TEEN"
            [maxInfants]="CRU_MAX_INFANTS"
            [maxInfantAge]="MAX_INFANT_AGE"
            [maxChildAge]="MAX_CHILD_AGE"
            [maxTeenAge]="MAX_TEEN_AGE"
            [parentScrollId]="windowScrollEnable ? 'scroll': null"
            [isTeenActive]="TEEN_PAX_ACTIVE"
            (paxSelection)="getPassengerSelection($event)"
            [tabIndex]="2"
            (currentOption)="getCurrentOption($event)"
            (keydown)="onKeyDown($event, 1)"
            [b2bView]="true"
            [roomViewSelected]="false"
            [infoDisabled]="true">
          </surf-pax-selection>
        </div>
      </div>
      <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
    </div> <!--Content top : hidden in DP-->
  </ng-container>
  <ng-template #elseblock>
    <div class="c-advance-search__content-top">
      <button class="a-btn a-btn--link o-modal__inner-close" (click)="resetInputs()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
      <h3 class="t2 c-advance-search__title">Define your search for </h3>
      <div class="c-advance-search__guests">
        <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
      </div>
    </div>
  </ng-template>

  <div class="c-advance-search__form" *ngIf="!isDPSearch">

    <div class="c-dp-search">
      <ng-container *ngIf="!isServiceAddComponent">
        <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}" [totalItemCount]="1"
                         [isDPSearch]="false" [isIntro]="false" [isRefine]="isRefine"
                         [disableDPSwitch]="DISABLE_DP_SWITCH"
                         [isDisabled]="DISABLE_DP_SWITCH"
                         (showBarPanelToggle)="moveToDP('pre', $event)">

        </surf-dp-add-bar>
      </ng-container>
      <div class="c-dp-search__block c-dp-search__block--cruise" [ngClass]="{'is-selected': !isDPSearch}">
        <div class="c-dp-search__action">
          <div class="c-dp-search__block-icon">
            <svg class="a-icon">
              <use xlink:href="#products--cruise"></use>
            </svg>
          </div>
        </div>

        <div class="c-dp-search__block-label">
          <svg class="a-icon u-rmr">
            <use xlink:href="#products--cruise"></use>
          </svg>
          <span class="lbl">Cruise</span>
        </div>

        <div class="c-dp-search__form c-dp-search__form--cruise">

          <div class="c-dp-search__form-item">
            <div class="m-form-item">
              <div class="m-form-item__input auto-cru-destination">
                <surf-ul-drop-down
                  [label]="'Destination'"
                  [items]="destinationList"
                  [native]="true"
                  [select]=true
                  [warningStyle]="'is-visited'"
                  [warning]="destinationWarning"
                  (singleObject)="selectDestination($event)">
                </surf-ul-drop-down>
              </div>
            </div>
          </div>

          <div class="c-dp-search__form-item">
            <div class="c-dp-search__form-item c-dp-search__form-item--desc auto-dep-date">
              <surf-date-picker
                [range]="calendarRange"
                [calendarType]="calendarView"
                [inputLabel]="monthCalendarLabel"
                [enablePreviousDates]="true"
                [currentDateOfTimeZone]="calendarMinDate"
                [inputPlaceholder]="monthCalendarPlaceholder"
                [initial_dates]="initialDates"
                [reset]="resetCalendar"
                [defaultActiveCalendar]="defaultActiveCalendar"
                (selected_months)="getMonthSelection($event)"
                (selected_dates)="getDateSelection($event)"
                (selectedYears)="getYearSelection($event)">
              </surf-date-picker>
            </div>
          </div>

          <div class="c-dp-search__form-item c-dp-search__form-item--desc">
            <div class="m-form-item">
              <div class="m-form-item__input auto-cru-line">
                <surf-ul-drop-down
                  [label]="'Cruise Line'"
                  [items]="cruiseLineList"
                  [native]="true"
                  [select]=true
                  [warningStyle]="'is-visited'"
                  [warning]="cruiseLineWarning"
                  (singleObject)="selectCruiseLine($event)">
                </surf-ul-drop-down>
              </div>
            </div>
          </div>

          <div class="c-dp-search__form-item c-dp-search__form-item--desc">
            <div class="m-form-item">
              <div class="m-form-item__input auto-ship-name">
                <surf-multi-selector-drop-down
                  [title]="'Ship Name'"
                  [dataList]="filteredShipList? filteredShipList : shipNameList"
                  [searchableDropdown]="true"
                  [inputDisplay]="initialShipName"
                  (selectionChange)="selectShip($event)"
                >
                </surf-multi-selector-drop-down>
              </div>
            </div>
          </div>

          <div class="c-dp-search__form-item c-dp-search__form-item--inverted">
            <div class="m-form-item">
              <div class="m-form-item__input auto-cru-departing-port" id="auto-cru-departing-port"
                   (click)="scrollTo('#scroll')">
                <surf-type-ahead
                  [id]="'' + (index ? index : 0)"
                  [label]="'Departing From'"
                  [filterType]="'strict'"
                  [mappings]="['code', 'value']"
                  [sortOrder]="'A2Z'"
                  [selectedItem]="selectedPort"
                  [paramName]="'ontotext'"
                  [placeholder]="'Departure port'"
                  [bindFocusOut]="false"
                  [selectAllOnEnter]="false"
                  [isOnto]="true"
                  [autoFocus]="!isRefine"
                  [url]="depPortUrl"
                  [warningStyle]="'is-visited'"
                  [warning]="depPortWarning"
                  [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                  (returnedQuery)="returnedSearchQuery($event)"
                  (returnedData)="returnedPort($event)"
                  (keydown)="onKeyDown($event, 0)"
                  [tabIndex]="1">

                  <surf-type-ahead-content
                    [typeAheadData]="filteredPortList"
                    [available]="filteredPortList?.length > 0"
                    [enableKeyStrokeIgnore]="true"
                    (setItem)="selectDeparturePort($event)">

                    <ng-container *ngFor="let item of filteredPortList;let i = index">
                      <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 40">
                        <!-- ng content here-->
                        <div class="c-dp-search__list-item search-ineligible auto-city-item">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use xlink:href="#map"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                            <span class="s5 ts-muted ts-wet"><span
                              *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                          </div>
                          <span
                            *ngIf="item?.type == 'CITY' || item?.type == 40"
                            class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                        </div>

                      </surf-type-ahead-item>
                      <surf-type-ahead-item *ngIf="item?.type == 160">
                        <div
                          (click)="selectDeparturePort(item)"
                          class="c-dp-search__list-item auto-location">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use
                              xlink:href="#map"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                            <span class="s5 ts-muted ts-wet"><span
                              *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                          </div>
                          <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Cruise Port</span>
                        </div>
                      </surf-type-ahead-item>
                      <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                        <ng-template [ngIf]="item?.type == 40">
                          <!--then iterate its children (AIRPORTS) as sub components-->
                          <div (click)="selectDeparturePort( addParentToLocationGroup(child, item) )"
                               class="c-dp-search__list-item c-dp-search__list-item--sub auto-child-location"
                               [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span
                                *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{getCountry(item)}}</span>
                            </div>
                          </div>
                        </ng-template>

                      </surf-type-ahead-item>
                    </ng-container>
                  </surf-type-ahead-content>
                </surf-type-ahead>
                <span *ngIf="destSearchInitiated && filteredPortList.length == 0"
                      style="color: red;">No results found</span>
              </div>
            </div>
          </div>

          <div class="c-dp-search__form-group c-dp-search__form-group--extended">
            <div *ngIf="!range" class="c-dp-search__form-item c-dp-search__form-item--desc auto-cru-duration">
              <surf-multi-field-input
                [properties]="durationProperty"
                [componentValue]="durationValues"
                [title]="'Duration'"
                [error]="durationError"
                [msg]="'Invalid duration value'"
                (emitCombinedOutEvent)="setDuration($event)">
              </surf-multi-field-input>
              <span class="a-link-btn a-link-btn--primary c-dp-search__add-link u-mt-t"
                    (click)="addRange()">Add range</span>
            </div>
            <div *ngIf="range" class="c-dp-search__form-item c-dp-search__form-item--desc auto-cru-duration-offset">
              <surf-multi-field-input
                [properties]="minDurationProperty"
                [componentValue]="minDurationValues"
                [title]="'Min Duration'"
                [error]="minDurationError"
                [msg]="'Invalid min duration value'"
                (emitCombinedOutEvent)="setMinDuration($event)"
              >
              </surf-multi-field-input>
            </div>
            <div *ngIf="range" class="c-dp-search__form-item c-dp-search__form-item--desc auto-cru-duration-offset">
              <surf-multi-field-input
                [properties]="maxDurationProperty"
                [componentValue]="maxDurationValues"
                [title]="'Max Duration'"
                [error]="maxDurationError"
                [msg]="'Invalid max duration value'"
                (emitCombinedOutEvent)="setMaxDuration($event)">
              </surf-multi-field-input>
              <span class="a-link-btn a-link-btn--primary c-dp-search__add-link u-mt-t" (click)="removeRange()">Remove range</span>
            </div>
          </div>


        </div>

        <!--commented out to remove cross in cruise search as no other items can be added in dp flow as of now-->
        <!--        <div class="c-dp-search__close" *ngIf="!isServiceAddComponent">
                  <svg class="a-icon">
                    <use xlink:href="#close"></use>
                  </svg>
                </div>-->

      </div>
      <ng-container *ngIf="!isServiceAddComponent">
        <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}" [totalItemCount]="1"
                         [isDPSearch]="false" [isIntro]="false" [isRefine]="isRefine"
                         [disableDPSwitch]="DISABLE_DP_SWITCH"
                         [isDisabled]="DISABLE_DP_SWITCH"
                         (showBarPanelToggle)="moveToDP('post', $event)"></surf-dp-add-bar>
      </ng-container>
    </div>

  </div> <!--Cruise criteria content : visible in Component flow-->

  <div class="c-advance-search__btn-wrapper" *ngIf="!isDPSearch">
    <div *ngIf="!isRefine" class="c-advance-search__search-action">
      <ng-container *ngIf="isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-cancel-btn" [tabIndex]="4">Cancel</button>
      </ng-container>
      <ng-container *ngIf="!isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-btn-reset" [tabIndex]="4">Reset Search</button>
      </ng-container>
      <button (click)="searchCruise(false)" class="a-btn a-btn--primary auto-btn-search" [tabIndex]="5"
              [ngClass]="{'is-disabled':!validateInputs()}">Search
      </button>
    </div>
    <div *ngIf="isRefine" class="c-advance-search__search-action">
      <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default auto-btn-cancel">Back to results</button>
      <button (click)="searchCruise(false)" class="a-btn a-btn--primary auto-btn-refine"
              [ngClass]="{'is-disabled':!validateInputs()}">
        Refine Search
      </button>
    </div>
  </div> <!--Button wrapper : hidden in DP-->

</div>
