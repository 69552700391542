import { Component } from '@angular/core';
import {
  CAR_CONTRACT_TYPES,
  DestinationType, Detail,
  FlightCriteria,
  OntologyResponse,
  ProductType, SurfGeoSpatialSearchGoogleService
} from '@surf/surf-components-core';
import { SurfDropDownOutput } from '@surf/surf-drop-down';
import { FlightGroupDetail } from '@surf/surf-flight-criteria';
import { FlightGrabPnrGETCriteria, PnrComponentOutput } from '@surf/surf-grab-pnr';
import { MultiFieldInputPropertyFactory } from '@surf/surf-multi-field-input';
import { PaxAllocationItem } from '@surf/surf-pax-selection';
import { NameValuePair } from '@tc-core/model/it/codegen/tbx/api/cart/name-val-pair';
import { CarCriteria as DPCarCriteria } from '@tc-core/model/it/codegen/tbx/api/criteria/car/car-criteria';
import { CarFilter } from '@tc-core/model/it/codegen/tbx/api/criteria/car/car-filter';
import { CarParameter } from '@tc-core/model/it/codegen/tbx/api/criteria/car/car-parameter';
import { FlightCriteria as DPFlightCriteria } from '@tc-core/model/it/codegen/tbx/api/criteria/flight/flight-criteria';
import { FlightParameter } from '@tc-core/model/it/codegen/tbx/api/criteria/flight/flight-parameter';
import { GrabPNR } from '@tc-core/model/it/codegen/tbx/api/criteria/flight/grab-pnr';
import { GenericCriteria as DPGenericCriteria } from '@tc-core/model/it/codegen/tbx/api/criteria/generic/generic-criteria';
import { TourCriteria as DPTourCriteria } from '@tc-core/model/it/codegen/tbx/api/criteria/tour/tour-criteria';
import { GenericFilter } from '@tc-core/model/it/codegen/tbx/api/criteria/generic/generic-filter';
import { GenericParameter } from '@tc-core/model/it/codegen/tbx/api/criteria/generic/generic-parameter';
import { HotelCriteria as DPHotelCriteria } from '@tc-core/model/it/codegen/tbx/api/criteria/hotel/hotel-criteria';
import { HotelFilter } from '@tc-core/model/it/codegen/tbx/api/criteria/hotel/hotel-filter';
import { HotelParameter } from '@tc-core/model/it/codegen/tbx/api/criteria/hotel/hotel-parameter';
import { LiteTraveller } from '@tc-core/model/it/codegen/tbx/api/criteria/lite-traveller';
import { PackageCriteria } from '@tc-core/model/it/codegen/tbx/api/criteria/pkg/package-criteria';
import { PackageParameter } from '@tc-core/model/it/codegen/tbx/api/criteria/pkg/package-parameter';
import { PackagePreference } from '@tc-core/model/it/codegen/tbx/api/criteria/pkg/package-preference';
import { ProductParameter } from '@tc-core/model/it/codegen/tbx/api/criteria/product-parameter';
import { TransferCriteria as DPTransferCriteria } from '@tc-core/model/it/codegen/tbx/api/criteria/transfer/transfer-criteria';
import { TransferParameter } from '@tc-core/model/it/codegen/tbx/api/criteria/transfer/transfer-parameter';
import { OccupancyChoice } from '@tc-core/model/it/codegen/tbx/api/product/criteria/occupancy-choice';
import { PaxAge } from '@tc-core/model/it/codegen/tbx/api/product/criteria/pax-age';
import { SearchSegment } from '@tc-core/model/it/codegen/tbx/api/product/flight/search-segment';
import { TbxKeyControlParameter } from '@tc-core/model/it/codegen/tbx/api/tbx-key-control-parameter';
import { TBXRequestWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-request-wrapper';
import QuoteBookingType = ProductParameter.QuoteBookingType;
import {TC} from "@tc-core/util";
import Product = TC.Product;
import {TourFilter} from '@tc-core/model/it/codegen/tbx/api/criteria/tour/tour-filter';
import { TourParameter } from '@tc-core/model/it/codegen/tbx/api/criteria/tour/tour-parameter';
import { Supplier } from '@tc-core/model/it/codegen/tbx/api/product/supplier';
import {TourExternalSource} from '@tc-core/model/it/codegen/tbx/api/criteria/tour/tour-external-source';
import moment from "moment";


export class DpCriteriaManageFlights {

  DEST_TYPE_ID_AIRPORT = 60;
  DEST_TYPE_ID_CITY = 40;
  DEST_TYPE_ID_COUNTRY = 20;

  TRAVELLER_TYPE_ADULT = 'A';
  TRAVELLER_TYPE_CHILD = 'C';
  TRAVELLER_TYPE_TEEN = 'T';
  TRAVELLER_TYPE_INFANT = 'I';
  PACKAGE_PARAMETER_SIZE = 1;
  public static DP_ENABLE_SOURCE_SUMMARY_CACHE = true;
  public static DP_ENABLE_SOURCE_CACHE = true;
  MIN_SEARCH_QUERY_LENGTH: number = 3;
  PACKAGE_PARAMETER_WITH_AVAILABILITY = true;
  DEFAULT_SELECTED_GROUP = 1;
  INITIAL_NUMBER_OF_GROUPS = 2;
  GROUP_CLASS_NAMES = [
    't-primary',
    't-secondary',
    't-tertiary',
    't-quaternary',
    't-quinary',
  ];

  geoLocationDetailList = [];
  geoLocationDetailError = '';

  public static readonly ATTR_AMENDMENT = 'amd';
  public static readonly ELITE_PACKAGE_ITEM_META_INFO = 'elitePackageItemMetaInfo';

  requestPayloadSessionStorageAccessKey = 'requestPayload';

  staticDataUrl_FLT = "/data/data-service/v2/static-data?dataTypes=AIRPORT&userInput=[UI]&aggrLevel=CITY&locale=en&matchParent=true&resultCount=20&expand=all";

  /**
   * in DP criteria it initially adds 2 flights
   * @param groupDetails - flight group details
   * @param criteriaItemList - dp criteria item list (initial flight items)
   */
  addInitialFlightItems(groupDetails: Array<FlightGroupDetail>, criteriaItemList: Array<CriteriaItem>, startingIndex?: number): void {
    let index: number;
    for ( index = startingIndex ? startingIndex : 0; index < this.INITIAL_NUMBER_OF_GROUPS; index++ ) {
      const groupDetail = new FlightGroupDetail();
      groupDetail.itineraryItemNo = index;
      groupDetail.dropdownOptions = this.getDropdownOptions(this.INITIAL_NUMBER_OF_GROUPS, this.DEFAULT_SELECTED_GROUP);
      groupDetail.groupNumber = this.DEFAULT_SELECTED_GROUP;
      groupDetail.groupClassName = this.getClassNameByGroupNumber(groupDetail.groupNumber);
      groupDetails.push(groupDetail);

      criteriaItemList[index].criItem.legs[0].groupNumber = this.DEFAULT_SELECTED_GROUP;
      console.log(criteriaItemList);
    }
  }

  /**
   * update groupDetails upon adding a new product criteria to the dp
   * @param groupDetails - flight group details
   * @param criteriaItemList - dp criteria item list (all items in dp)
   * @param newItemIndex - newly added item index in criteriaItemList
   */
  updateFlightItemsOnAdd(groupDetails: Array<FlightGroupDetail>, criteriaItemList: Array<CriteriaItem>, newItemIndex: number, isAmendmentFlow?: boolean): void {
    if (!groupDetails) { groupDetails = new Array<FlightGroupDetail>(); }
    const flightItems = criteriaItemList.filter((item) => (item.productCode === ProductType.FLT));
    let groups = 0;
    let maxPnrGroup = 0;
    let pnrItems;
    if (flightItems.length > 0) {
      pnrItems = flightItems.filter(flt => {
        return flt.isGrabbedLeg;
      });
      let notPnrItems = flightItems.filter(flt => {
        return (!flt.isGrabbedLeg);
      });
      if (pnrItems && pnrItems.length > 0) {
        pnrItems.forEach(flight => {
          flight.criItem.legs.forEach(leg => {
            if (leg.groupNumber > groups) {
              groups = leg.groupNumber;
              maxPnrGroup = leg.groupNumber;
            }
          });
        });
      }
      if (notPnrItems && notPnrItems.length > 0) {
        groups += notPnrItems.length;
      }
      // set new flight group no in amendment flow
      if (isAmendmentFlow) {
        let newFlightItems = flightItems.filter(flt => {
          let flight = flt as any;
          return flight.isNewCriteriaItem;
        });
        if (newFlightItems && newFlightItems.length > 0) {
          if (pnrItems && pnrItems.length > 0) {
            maxPnrGroup++;
          } else {
            let oldFlightItems = flightItems.filter(flt => {
              let flight = flt as any;
              return !(flight.isNewCriteriaItem);
            });
            if (oldFlightItems && oldFlightItems.length > 0) {
              oldFlightItems.forEach(item => {
                item.criItem.legs.forEach(leg => {
                  if (leg.groupNumber) {
                    maxPnrGroup = leg.groupNumber;
                  }
                });
              });
              maxPnrGroup++;
            } else {
              maxPnrGroup++;
            }
          }
        } else {
          maxPnrGroup = this.DEFAULT_SELECTED_GROUP;
        }
      }
    }
    if (criteriaItemList[newItemIndex].productCode === ProductType.FLT) {
      const toBeAddedIndex = flightItems.findIndex( (item) => (item.index === newItemIndex ));
      const groupDetail = new FlightGroupDetail();
      groupDetail.itineraryItemNo = flightItems[toBeAddedIndex].index;
      groupDetail.dropdownOptions = this.getDropdownOptions(groups, ((pnrItems && pnrItems.length > 0)? (maxPnrGroup + 1) : this.DEFAULT_SELECTED_GROUP), maxPnrGroup);
      groupDetail.groupNumber = (pnrItems && pnrItems.length > 0)? (maxPnrGroup + 1) : (isAmendmentFlow ? maxPnrGroup : this.DEFAULT_SELECTED_GROUP);
      groupDetail.groupClassName = this.getClassNameByGroupNumber(groupDetail.groupNumber);
      groupDetails.splice(toBeAddedIndex, 0, groupDetail);

      criteriaItemList[newItemIndex].criItem.legs[0].groupNumber = ((pnrItems && pnrItems.length > 0)? (maxPnrGroup + 1) : (isAmendmentFlow ? maxPnrGroup : this.DEFAULT_SELECTED_GROUP));
      if (groupDetails[toBeAddedIndex]) {
        groupDetails[toBeAddedIndex].groupNumber = ((pnrItems && pnrItems.length > 0)? (maxPnrGroup + 1) : (isAmendmentFlow ? maxPnrGroup : this.DEFAULT_SELECTED_GROUP));
      }
    }
    // correct item index upon adding a new item
    let index: number;
    console.log(flightItems);
    for (index = 0; index < groupDetails.length; index++) {
      const item = groupDetails[index];
      if (item) {
        if (maxPnrGroup !== 0 && item.groupNumber > maxPnrGroup) {
          item.dropdownOptions = this.getDropdownOptions(groups, item.groupNumber, maxPnrGroup);
        } else {
          item.dropdownOptions = this.getDropdownOptions(groups, item.groupNumber);
        }
        item.itineraryItemNo = flightItems[index].index;
      }
    }
    this.updateDisplayGroupDetails(groupDetails);
  }

  /**
   * update groupDetails upon deleting a product criteria from dp
   * @param groupDetails - flight group details
   * @param criteriaItemList - dp criteria item list (updated)
   * @param removedItem - removed item criteria
   */
  updateFlightItemsOnRemove(groupDetails: Array<FlightGroupDetail>, criteriaItemList: Array<CriteriaItem>, removedItem: CriteriaItem) {
    const flightItems = criteriaItemList.filter((item) => (item.productCode === ProductType.FLT));
    if (removedItem.productCode === ProductType.FLT) {
      const toBeRemovedFlightIndex = groupDetails.findIndex( (item) => (item.itineraryItemNo === removedItem.index));
      groupDetails.splice(toBeRemovedFlightIndex, 1);
      const maxSelectedGroupNo = Math.max(...groupDetails.map((item) => (item.groupNumber)));
      if (flightItems.length < maxSelectedGroupNo) {
        const groups = Array.from(Array(groupDetails.length).keys()).map(i => ++i);
        let nonAssignedGroup: number;
        groups.forEach((groupNo) => {
          if (!groupDetails.find((detail) => (detail.groupNumber === groupNo))) {
            nonAssignedGroup = groupNo;
          }
        });
        groupDetails.forEach((item) => {
          if (item.groupNumber === maxSelectedGroupNo) {
            item.groupNumber = nonAssignedGroup;
            item.groupClassName = this.getClassNameByGroupNumber(item.groupNumber);
          }
        });
      }
    }
    // correct item index upon deleting a item
    let index: number;
    for (index = 0; index < groupDetails.length; index++) {
      const item = groupDetails[index];
      item.dropdownOptions = this.getDropdownOptions(flightItems.length, item.groupNumber);
      item.itineraryItemNo = flightItems[index].index;
    }
    this.updateDisplayGroupDetails(groupDetails);
  }

  /**
   * assign groupNumbers upon confirming the Combine Flights window
   * @param groupDetails - flight group details
   * @param criteriaItemList - dp criteria item list (all items in dp)
   */
  confirmSelectedGroupDetails(groupDetails: Array<FlightGroupDetail>, criteriaItemList: Array<CriteriaItem>): void {
    let index: number;
    for (index = 0; index < groupDetails.length; index++) {
      const selectedOption = groupDetails[index].dropdownOptions.find((option) => (option.selected));
      groupDetails[index].groupNumber = parseInt(selectedOption.code, 10);
      groupDetails[index].groupClassName = this.getClassNameByGroupNumber(groupDetails[index].groupNumber);

      criteriaItemList[groupDetails[index].itineraryItemNo].criItem.legs[0].groupNumber = groupDetails[index].groupNumber;
    }
    this.updateDisplayGroupDetails(groupDetails);
  }

  /**
   * reset dropDown selections upon cancelling the Combine Flights window
   * @param groupDetails - flight group details
   */
  resetGroupDetails(groupDetails: Array<FlightGroupDetail>): void {
    let index: number;
    for (index = 0; index < groupDetails.length; index++) {
      groupDetails[index].dropdownOptions = this.getDropdownOptions(groupDetails.length, groupDetails[index].groupNumber);
    }
  }

  /**
   * returns new dropdown options
   * @param numberOfGroups - number of dropdown options
   * @param selectedGroupIndex - selected option
   */
  getDropdownOptions(numberOfGroups: number, selectedGroupIndex: number, pnrGroupCount?: number): Array<SurfDropDownOutput> {
    const dropDownOutputs = new Array<SurfDropDownOutput>();
    let index: number;
    for ( index = 0; index < numberOfGroups; index++ ) {
      if (!(pnrGroupCount && pnrGroupCount >= (index + 1))) {
        const dropDownOutput = new SurfDropDownOutput();
        dropDownOutput.code = (index + 1).toString();
        dropDownOutput.value = 'Group ' + (index + 1);
        dropDownOutput.selected = (index + 1) === selectedGroupIndex;
        dropDownOutputs.push(dropDownOutput);
      }
    }
    return dropDownOutputs;
  }

  /**
   * group numbers are displayed if only more than 1 group is selected for grouping
   * @param groupDetails - flight group details
   */
  updateDisplayGroupDetails(groupDetails: Array<FlightGroupDetail>): void {
    const displayGroupDetails = !groupDetails.every((item) => (item.groupNumber === groupDetails[0].groupNumber));
    groupDetails.forEach((value, index) => {
      groupDetails[index].displayGroupDetails = displayGroupDetails;
    });
  }

  /**
   * assign combineWith property when clicking on Search Holiday
   * @param groupDetails - flight group details
   */
  updateCombinedWithIndexes(groupDetails: Array<FlightGroupDetail>): void {
/*    // 0th index represents the 1st group and contains each lead flight index of each group
    const groupLeads = new Array(groupDetails.length);

    let index: number;
    for (index = 0; index < groupDetails.length; index++) {
      const groupIndex = groupDetails[index].groupNumber - 1;
      if (groupLeads[groupIndex] >= 0) {
        // if there is already a lead flight in a group, combine with it
        groupDetails[index].combineWith = groupLeads[groupIndex];
      } else {
        // first flight item to be in a particular group becomes the group lead flight
        groupLeads[groupIndex] = index;
        // and lead flight won't be combined
        groupDetails[index].combineWith = -1;
      }
    }*/

    /*New assignment of flight combinewith parameter : for a flight group, always the combineWith parameter should be it's lead flight itinerary item index number*/
    let groups = this.getGroupsWithLeadFlightItineraryNumber(groupDetails, 'groupNumber');

    groupDetails.forEach(group => {
      if (Object.keys(groups).includes(group.groupNumber + '')) {
        group.combineWith = groups[group.groupNumber + ''][0];
      }
    });
  }

  /*This method returns the list of unique groups with each group's smallest flight item itinerary number (which is the lead flight of that group)*/
  getGroupsWithLeadFlightItineraryNumber(data, key) {
    // `data` is an array of objects, `key` is the key (or property accessor) to group by
    // reduce runs this anonymous function on each element of `data` (the `item` parameter,
    // returning the `storage` parameter at the end
    return data.reduce(function(storage, item) {
      // get the first instance of the key by which we're grouping
      var group = item[key];
      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];
      // add this item to its group within `storage`
      if (storage[group].length == 0) {
        storage[group].push(item.itineraryItemNo);
      } else if (item.itineraryItemNo < storage[group]) {
        storage[group] = [];
        storage[group].push(item.itineraryItemNo);
      }
      // return the updated storage to the reduce function, which will then loop through the next
      return storage;
    }, {}); // {} is the initial value of the storage
  };


  /**
   * returns CSS class name for a group for a particular a group number
   * @see GROUP_CLASS_NAMES - static class names
   * @param groupNumber - selected group number
   */
  getClassNameByGroupNumber(groupNumber: number): string {
    const numOfClassed = this.GROUP_CLASS_NAMES.length;
    return this.GROUP_CLASS_NAMES[groupNumber % numOfClassed];
  }

  /**
   * if criteriaItemList is non empty (refine search) creates the flight group details
   * @param criteriaItemList - dp criteria item list (all items in dp)
   */
  createGroupDetailsFromCriteriaList(criteriaItemList: Array<CriteriaItem>, refineGrab?:boolean): Array<FlightGroupDetail> {
    const groupDetails = new Array<FlightGroupDetail>();
    const flightItems = criteriaItemList ? criteriaItemList.filter((item) => (item.productCode === ProductType.FLT)) : null;
    if (flightItems && flightItems.length > 0) {
      flightItems.forEach((item) => {
        if (item.productCode === ProductType.FLT) {
          const groupDetail = new FlightGroupDetail();
          const selectedGroup = item.criItem.legs[0].groupNumber ? item.criItem.legs[0].groupNumber : this.DEFAULT_SELECTED_GROUP;
          groupDetail.itineraryItemNo = item.index;
          groupDetail.dropdownOptions = this.getDropdownOptions(flightItems.length, selectedGroup);
          groupDetail.groupNumber = selectedGroup;
          groupDetail.groupClassName = this.getClassNameByGroupNumber(selectedGroup);
          if (refineGrab) {
            groupDetail.isDisabled = true;
            groupDetail.displayGroupDetails = true;
          }
          groupDetails.push(groupDetail);
        }
      });
    }
    if (!refineGrab) {
      this.updateDisplayGroupDetails(groupDetails);
    }
    return groupDetails;
  }

  /**
   * Create and return an empty flight leg criteria item object
   * @return flight leg object - dp criteria item list (all items in dp)
   */
  createEmptyFlightItemObject(): any {
    let fromOntTemp = new OntologyResponse();
    let toOntTemp = new OntologyResponse();
    let airlineOntTemp = new OntologyResponse();
    const viaPointOntologyTemp = new OntologyResponse();
    let multiFieldInpPropTemp = [];
    fromOntTemp.displayText = '';
    toOntTemp.displayText = '';
    airlineOntTemp.displayText = '';
    viaPointOntologyTemp.displayText = '';
    let cabinClassListTemp = [];

    multiFieldInpPropTemp.push(MultiFieldInputPropertyFactory.getTypeAheadProperties('Search airport', true, false,
      'DEPARTURE_FROM', 'Departure*', 'strict', fromOntTemp, this.MIN_SEARCH_QUERY_LENGTH, true, this.staticDataUrl_FLT));
    multiFieldInpPropTemp.push(MultiFieldInputPropertyFactory.getTypeAheadProperties('Search airport', true, false,
      'DEPARTURE_TO', 'Arrival*', 'strict', toOntTemp, this.MIN_SEARCH_QUERY_LENGTH, true, this.staticDataUrl_FLT));

    return {
      showTypeAheadContent: false,
      typeAheadHasError: false,
      typeAheadDepartureHasError: false,
      typeAheadArrivalHasError: false,
      typeAheadErrorMsg: '',
      fromOntology: fromOntTemp,
      toOntology: toOntTemp,
      airlineOntology: airlineOntTemp,
      viaPointOntology: airlineOntTemp,
      multiFieldInputProperty: multiFieldInpPropTemp,
      isSelected: false,
      dateReset: false,
      isAirlineSearchInitiated: false,
      cabinClassList: cabinClassListTemp
    };
  }

  /**
   * Map the grab pnr response to a list of dp criteria objects and return
   * @param event - grab pnr response
   * @param criteriaItemList - empty dp criteria item list
   * @param flightsGrouping - empty list of flight groupings
   * @param grabPax - temporary pax item set used to determine the guest detail string at the refine page (usage in html)
   * @param cachePaxSet - temporary pax item set
   * @param passengerAllocation - guest detail pax item object
   * @return flight leg object - dp criteria item list (all items in dp)
   */
  mapFromGrabCriteria(event: PnrComponentOutput, criteriaItemList: any, flightsGrouping: any, grabPax:any, cachePaxSet:any, passengerAllocation:any) : any {

    if (event.multiValidatingCarriers) {
      //adjust flight group number
      let groups = new Set();
      let groupMap = [];

      event.flightComponentCriteria.legs.forEach(pnrLeg => {
        groups.add(pnrLeg.combineWith);
      });

      let groupNumber = 1;
      groups.forEach(group => {
        groupMap.push({no: groupNumber, comb: group});
        groupNumber++;
      });

      event.flightComponentCriteria.legs.forEach(pnrLeg => {
        groupMap.forEach(group => {
          if (pnrLeg.combineWith == group.comb) {
            pnrLeg.combineWith = group.no;
          }
        });
      });
    }

    if (criteriaItemList == undefined) {
      criteriaItemList = [];
    }

    let index = 0;
    event.flightComponentCriteria.legs.forEach(pnrLeg => {

      let fltCriItem = new FlightCriteria();
      fltCriItem.legs = [];
      fltCriItem.legs.push(this.createEmptyFlightItemObject());

      fltCriItem.itemIndex = index;
      fltCriItem.grabSectorNumber = index + 1;

      fltCriItem.legs[0].departureDate = pnrLeg.departureDate;
      fltCriItem.legs[0].departureAirportCode = pnrLeg.departureAirportCode;
      fltCriItem.legs[0].departureAirportCityName = pnrLeg.departureAirportCity;
      fltCriItem.legs[0].departureAirportCountry = pnrLeg.departureAirportCountry;
      fltCriItem.legs[0].departureAirportCity = pnrLeg.departureAirportCityCode;
      fltCriItem.legs[0].departureAirportCountryName = pnrLeg.departureAirportCountryName;

      fltCriItem.legs[0].arrivalAirportCode = pnrLeg.arrivalAirportCode;
      fltCriItem.legs[0].arrivalAirportCityName = pnrLeg.arrivalAirportCity;
      fltCriItem.legs[0].arrivalAirportCountry = pnrLeg.arrivalAirportCountry;
      fltCriItem.legs[0].arrivalAirportCity = pnrLeg.arrivalAirportCityCode;
      fltCriItem.legs[0].arrivalAirportCountryName = pnrLeg.arrivalAirportCountryName;

      fltCriItem.legs[0].fromOntology.displayText = pnrLeg.departureAirportCity + ' (' + pnrLeg.departureAirportCode + ')';
      fltCriItem.legs[0].toOntology.displayText = pnrLeg.arrivalAirportCity + ' (' + pnrLeg.arrivalAirportCode + ')';
      fltCriItem.legs[0].groupNumber = pnrLeg.combineWith;

      fltCriItem.legs[0].depObj = JSON.parse(JSON.stringify(this.getLocObj(
        pnrLeg.departureAirportCode,
        pnrLeg.departureAirportName,
        pnrLeg.departureAirportCityCode,
        pnrLeg.departureAirportCity,
        pnrLeg.departureAirportCountry,
        pnrLeg.departureAirportCountryName
        )
      ));

      fltCriItem.legs[0].arrObj = JSON.parse(JSON.stringify(this.getLocObj(
        pnrLeg.arrivalAirportCode,
        pnrLeg.arrivalAirportName,
        pnrLeg.arrivalAirportCityCode,
        pnrLeg.arrivalAirportCity,
        pnrLeg.arrivalAirportCountry,
        pnrLeg.arrivalAirportCountryName
        )
      ));

      let destStr =
        fltCriItem.legs[0].arrivalAirportCode + '~'
        + fltCriItem.legs[0].arrivalAirportCity + '~'
        + fltCriItem.legs[0].arrivalAirportCityName + '~'
        + fltCriItem.legs[0].arrivalAirportCountryName
        + '|'
        + fltCriItem.legs[0].departureAirportCode + '~'
        + fltCriItem.legs[0].departureAirportCity + '~'
        + fltCriItem.legs[0].departureAirportCityName + '~'
        + fltCriItem.legs[0].departureAirportCountryName;

      criteriaItemList.push({
        index: index,
        productCode: 'FLT',
        criItem: fltCriItem,
        gapWithChangedItem: 0,
        initialDateStr: fltCriItem.legs[0].departureDate,
        initialDestStr: destStr,
        isGrabbedLeg : true
      });

      const groupDetail = new FlightGroupDetail();
      groupDetail.itineraryItemNo = index;
      groupDetail.groupNumber = pnrLeg.combineWith;
      groupDetail.dropdownOptions = this.getDropdownOptions(
        event.flightComponentCriteria.legs.length,
        groupDetail.groupNumber
      );
      groupDetail.displayGroupDetails = true;
      groupDetail.groupClassName = this.getClassNameByGroupNumber(groupDetail.groupNumber);
      groupDetail.combineWith = pnrLeg.combineWith;
      groupDetail.isDisabled = true;

      if (flightsGrouping == undefined) {
        flightsGrouping = [];
      }

      flightsGrouping.push(groupDetail);

      index++;
    });

    //set grab passengers
    let a = 0;
    let c = [];
    let t = [];
    let cDOB = [];
    let tDOB = [];
    let i = [];
    let iDOB = [];
    let infSeatAssociation = [];

    if (event.grabPax && event.grabPax.length > 0) {
      grabPax = event.grabPax;
      event.grabPax.forEach(grabPax => {
        if (grabPax.profile.type == 'A') {
          a++;
        } else if (grabPax.profile.type == 'C') {
          c.push(grabPax.profile.age);
          cDOB.push(grabPax.profile.dob);
        } else if (grabPax.profile.type == 'T') {
          t.push(grabPax.profile.age);
          tDOB.push(grabPax.profile.dob);
        } else if (grabPax.profile.type == 'I') {
          i.push(grabPax.profile.age);
          iDOB.push(grabPax.profile.dob);
          infSeatAssociation.push(grabPax.infantSeatRequired);
        }
      });
    }

    cachePaxSet = [];
    cachePaxSet = [new PaxAllocationItem(a, c, i, infSeatAssociation, t)];
    cachePaxSet[0].childDOB = cDOB;
    cachePaxSet[0].teenDOB = tDOB;
    cachePaxSet[0].infantDOB = iDOB;

    passengerAllocation = [new PaxAllocationItem(a, c, i, infSeatAssociation, t)];
    passengerAllocation[0].childDOB = cDOB;
    passengerAllocation[0].teenDOB = tDOB;
    passengerAllocation[0].infantDOB = iDOB;

    return {
      criteriaItemList : criteriaItemList,
      flightsGrouping : flightsGrouping,
      grabPax : grabPax,
      cachePaxSet : cachePaxSet,
      passengerAllocation : passengerAllocation
    };
  }

  /*return the location object for a givan destination / departure parameters*/
  getLocObj(aptCode, aptName, ctyCode, ctyName, cntCode, cntName): any {
    return {
      code: aptCode,
      name: aptName,
      type: this.DEST_TYPE_ID_AIRPORT,
      parent: [
        {
          code: ctyCode,
          name: ctyName,
          type: this.DEST_TYPE_ID_CITY
        },
        {
          code: cntCode,
          name: cntName,
          type: this.DEST_TYPE_ID_COUNTRY
        }
      ]
    };
  }

  /*check and return true if the grabbed criteria is valid*/
  isValidGrabCriteria(obj): boolean {
    return obj && obj.flightComponentCriteria &&
      obj.flightComponentCriteria.legs &&
      obj.flightComponentCriteria.legs.length > 0;
  }
  /**
   * Set attributes in dp criteria object when existing criteria list item object contains attributes
   * @param attributes - attributes passed in the existing criteria list item object
   */
  setAttributes(attributes, criteria): void {
    const elitePackageItemMetaInfo =  attributes.filter(attr => attr.name === DpCriteriaManageFlights.ELITE_PACKAGE_ITEM_META_INFO);
    if (elitePackageItemMetaInfo && elitePackageItemMetaInfo.length > 0) {
      criteria.attributes.push(new NameValuePair(elitePackageItemMetaInfo[0].name,elitePackageItemMetaInfo[0].value));
    }
  }

  /**
   * async method to get geo location detail results by google
   * @param criteriaItemList - criteria list that added by user
   * @param surfGeoSpatialSearchGoogleService - google service
   */
  async processGeoLocationDetailResult(criteriaItemList,
                                       surfGeoSpatialSearchGoogleService: SurfGeoSpatialSearchGoogleService): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      for (const surfCriItem of criteriaItemList) {
        if (surfCriItem.productCode === Product.ACCOMMODATION &&
          surfCriItem.criItem.destinationType === DestinationType.GEO_LOCATION && surfCriItem.criItem.destinationCode) {
          const arg = await this.getDetailResult(surfCriItem, surfGeoSpatialSearchGoogleService);
          if (typeof arg === 'string') {
            this.geoLocationDetailError = arg;
          } else if (arg?.data) {
            this.geoLocationDetailList.push({
              destinationCode: surfCriItem.criItem.destinationCode,
              detailResult: arg.data
            });
          }
        }
      }
      resolve({
        geoDetailList: this.geoLocationDetailList,
        geoDetailError: this.geoLocationDetailError
      });
    });
  }

  /**
   * async method to get geo location detail result by google
   * @param surfCriItem - hotel criteria that added by user
   * @param surfGeoSpatialSearchGoogleService - google service
   */
  async getDetailResult(surfCriItem, surfGeoSpatialSearchGoogleService): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const detailUrl = surfGeoSpatialSearchGoogleService.getGoogleDetailResult(surfCriItem.criItem.destinationCode);
      detailUrl.subscribe(
        arg => {
          resolve(arg);
        },
        error => console.log(error)
      );
    });
  }

  /*build and return the dp criteria object list for the dp search [POST] request using the existing criteria item object list
  * @param criteriaItemList - existing criteria item object list
  * @param dpProductCriteria - empty dp product criteria list
  * @param passengerAllocation - pax allocation list
  * @param cachePaxSet - pax allocation list from the guest detail component
  * @param flightsGrouping - flight groupings list for the combined parameters
  * @param flightGrabPnrCriteria - flight grab pnr initial criteria (if grab flow)
  * @param selectedPnrNumber - grabbed pnr string (if grab flow)
  * @param gds - gds code related to the pnr (if grab flow)
  * @param defaultAdultAge - default adult age retrieved from configs
  * @param maxChildAge - max child age retrieved from configs
  * @param isGrabFlow - if called during the grab flow
  * @param roomWiseSelected - if multiple rooms are selected (when there are hotels)
  * @return dpProductCriteria[]
  * */
  buildDPProductCriteria(criteriaItemList: any, dpProductCriteria: any, passengerAllocation: any, cachePaxSet: any, flightsGrouping: any,
                         flightGrabPnrCriteria: FlightGrabPnrGETCriteria, selectedPnrNumber: string, gds: string,
                         defaultAdultAge: number, maxChildAge: number, maxTeenAge: number, isGrabFlow: boolean, roomWiseSelected: boolean,
                         isAmendmentFlow: boolean = false, unfreezeItemMap?: any, isGetOnlyRequestPayload?: boolean,
                         adultAgeList?: Array<number>): any {


    criteriaItemList.forEach((surfCriItem, index) => {
      switch (surfCriItem.productCode) {
        case 'HTL':
          let dpHotelCriteria = new DPHotelCriteria();
          //map common info
          dpHotelCriteria.productCode = 'HTL';
          dpHotelCriteria.itineraryItemNo = surfCriItem.index;
          dpHotelCriteria.itineraryItemOrder = surfCriItem.index;

          //map hotel params
          dpHotelCriteria.param = new HotelParameter();
          dpHotelCriteria.param.departureDate = moment(surfCriItem.criItem.startDate).format('YYYY-MM-DD') ;
          dpHotelCriteria.param.nights = surfCriItem.criItem.nights;
          dpHotelCriteria.param.quoteBookingType = QuoteBookingType.BOOKING;
          // set country for hotel param
          if (surfCriItem.criItem.country) {
            dpHotelCriteria.param.country = surfCriItem.criItem.country;
          }
          // set city for hotel param
          if (surfCriItem.criItem.city) {
            dpHotelCriteria.param.city = surfCriItem.criItem.city;
          }

          //map hotel filter
          dpHotelCriteria.filter = new HotelFilter();

          if (surfCriItem.criItem.destinationType === 'HOTEL' || surfCriItem.criItem.destinationType == 80) {
            // set country for hotel filter
            if (surfCriItem.criItem.country) {
              dpHotelCriteria.filter.country = surfCriItem.criItem.country;
            }
            // set city for hotel filter
            if (surfCriItem.criItem.city) {
              dpHotelCriteria.filter.city = surfCriItem.criItem.city;
            }
            dpHotelCriteria.filter.hotelCode = surfCriItem.criItem.hotelCode;
          } else if (surfCriItem.criItem.destinationType === 100) {
            dpHotelCriteria.filter.destinationCode = surfCriItem.criItem.destinationCode;
            dpHotelCriteria.filter.destinationType = HotelFilter.DestinationType.CITY;
          }else{
            dpHotelCriteria.filter.destinationCode = surfCriItem.criItem.destinationCode;
            dpHotelCriteria.filter.destinationType = surfCriItem.criItem.destinationType;
          }

          //map hotel travellers
          dpHotelCriteria.travellers = this.populateTravellersArray(surfCriItem.criItem.startDate, passengerAllocation, cachePaxSet,
            defaultAdultAge, maxChildAge, maxTeenAge, isGrabFlow);

          if (isAmendmentFlow && dpHotelCriteria.travellers && dpHotelCriteria.travellers.length > 0) {
            this.rearrangePaxNosInAmendment(surfCriItem.paxSelection, dpHotelCriteria.travellers);
          }

          //map hotel attributes
          dpHotelCriteria.attributes = new Array<NameValuePair>();
          dpHotelCriteria.attributes.push(new NameValuePair('expandAlternatives', 'true'));
          dpHotelCriteria.attributes.push(new NameValuePair('nonRefundable', 'false'));
          if (isAmendmentFlow) {
            dpHotelCriteria.attributes.push(new NameValuePair('criteriaUpdateOption', unfreezeItemMap.get(surfCriItem.index)? 'REAL' : 'DUMMY'));
            dpHotelCriteria.attributes.push(new NameValuePair(DpCriteriaManageFlights.ATTR_AMENDMENT, 'true'));
          }
          if (surfCriItem.attributes && surfCriItem.attributes.length > 0){
            this.setAttributes(surfCriItem.attributes, dpHotelCriteria);
          }

          // Following Data is added to Dp criteria to populate refine criteria
          surfCriItem.initialDestStr  = this.createLocationStringForHotel(surfCriItem.criItem);
          surfCriItem.initialDateStr  = surfCriItem.criItem.startDate + '~' + surfCriItem.criItem.nights;

          //map hotel roomwise pax if the roomWise toggle is enabled
          if(roomWiseSelected){
            dpHotelCriteria.occupancyChoices = this.populateOccupancyChoices(passengerAllocation, defaultAdultAge);
          }

          dpProductCriteria.push(dpHotelCriteria);
          break;
        case 'FLT':
          let dpFlightCriteria = new DPFlightCriteria();

          //map common info
          dpFlightCriteria.productCode = 'FLT';
          dpFlightCriteria.itineraryItemNo = surfCriItem.index;
          dpFlightCriteria.itineraryItemOrder = surfCriItem.index;

          //map flight params
          dpFlightCriteria.param = new FlightParameter();
          dpFlightCriteria.param.quoteBookingType = QuoteBookingType.BOOKING;

          if(!surfCriItem.isGrabbedLeg){
            /*Normal search flow*/
            dpFlightCriteria.param.departureDate = moment(surfCriItem.criItem.legs[0].departureDate).format('YYYY-MM-DD') ;
            dpFlightCriteria.param.searchSegments = new Array<SearchSegment>();
            let fltSearchSegment = new SearchSegment();
            fltSearchSegment.departure = surfCriItem.criItem.legs[0].departureAirportCode;
            fltSearchSegment.destination = surfCriItem.criItem.legs[0].arrivalAirportCode;
            fltSearchSegment.departureDate =moment(surfCriItem.criItem.legs[0].departureDate).format('YYYY-MM-DD') ;
            fltSearchSegment.cabinClass = surfCriItem.criItem.legs[0].cabinClass;
            fltSearchSegment.airlines = surfCriItem.criItem.legs[0].airlines ?
              surfCriItem.criItem.legs[0].airlines
                                         .map(airLine => airLine.airlineCode ? airLine.airlineCode : '') : [];
            fltSearchSegment.connectionPoints = surfCriItem.criItem.legs[0].viaPoints ?
              surfCriItem.criItem.legs[0].viaPoints
                                         .map(viaPoint => viaPoint.viaPointCode ? viaPoint.viaPointCode : '') : [];
            if (isAmendmentFlow) {
              fltSearchSegment.combineWith = surfCriItem.criItem.legs[0].groupNumber ? surfCriItem.criItem.legs[0].groupNumber : 0;
            } else {
              const groupDetails = flightsGrouping.find(item => item.itineraryItemNo === surfCriItem.index);
              fltSearchSegment.combineWith = groupDetails ? groupDetails.combineWith : 0;
            }

            dpFlightCriteria.param.searchSegments.push(fltSearchSegment);
          }

          if(surfCriItem.isGrabbedLeg){
            /*Grab PNR flow*/
            dpFlightCriteria.grabPNR = new GrabPNR();
            dpFlightCriteria.grabPNR.pnr = selectedPnrNumber;
            dpFlightCriteria.grabPNR.gdsRef = gds;
            // dpFlightCriteria.grabPNR.pseudoCity = "LONFB310D";
            if(flightGrabPnrCriteria){
              dpFlightCriteria.grabPNR.useGDSPriceRecord = flightGrabPnrCriteria.useGDSPriceRecord;
              dpFlightCriteria.grabPNR.validateWithBookedPNRs = flightGrabPnrCriteria.validateWithBookedPNRs;
            }else{
              dpFlightCriteria.grabPNR.useGDSPriceRecord = false;
              dpFlightCriteria.grabPNR.validateWithBookedPNRs = false;
            }
            dpFlightCriteria.grabPNR.productKey = surfCriItem.criItem.grabSectorNumber;
            dpFlightCriteria.journeyType = "Grab-PNR";
          }

          //map flight travellers
          dpFlightCriteria.travellers = this.populateTravellersArray(surfCriItem.criItem.legs[0].departureDate, passengerAllocation, cachePaxSet,
            defaultAdultAge, maxChildAge, maxTeenAge, isGrabFlow, adultAgeList);

          if (isAmendmentFlow && dpFlightCriteria.travellers && dpFlightCriteria.travellers.length > 0) {
            this.rearrangePaxNosInAmendment(surfCriItem.paxSelection, dpFlightCriteria.travellers);
          }

          //map flight attributes
          dpFlightCriteria.attributes = new Array<NameValuePair>();
          dpFlightCriteria.attributes.push(new NameValuePair('expandAlternatives', 'true'));
          // dpFlightCriteria.attributes.push(new NameValuePair('combine_with', 0));
          dpFlightCriteria.attributes.push(new NameValuePair('nonRefundable', 'false'));
          if (isAmendmentFlow) {
            dpFlightCriteria.attributes.push(new NameValuePair('criteriaUpdateOption', unfreezeItemMap.get(surfCriItem.index)? 'REAL' : 'DUMMY'));
            dpFlightCriteria.attributes.push(new NameValuePair(DpCriteriaManageFlights.ATTR_AMENDMENT, 'true'));
          }
          if (surfCriItem.attributes && surfCriItem.attributes.length > 0){
            this.setAttributes(surfCriItem.attributes, dpFlightCriteria);
          }

          dpProductCriteria.push(dpFlightCriteria);

          // Following data is added to criteria object in order to populate DP refine criteria
          surfCriItem.initialDestStr = this.createAirportStringForRefineCriteria(surfCriItem.criItem);
          surfCriItem.initialDateStr = surfCriItem.criItem.legs[0].departureDate;
          break;
        case 'TRS':
          let dpTransferCriteria = new DPTransferCriteria();

          //map common info
          dpTransferCriteria.productCode = 'TRS';
          dpTransferCriteria.itineraryItemNo = surfCriItem.index;
          dpTransferCriteria.itineraryItemOrder = surfCriItem.index;
          if (isGetOnlyRequestPayload && !surfCriItem.valid) {
            dpProductCriteria.push(dpTransferCriteria);
            break;
          }
          //map transfer params
          dpTransferCriteria.param = new TransferParameter();
          dpTransferCriteria.param.departureDate = surfCriItem.criItem.onewayPickupTime.split('T')[0];
          dpTransferCriteria.param.quoteBookingType = QuoteBookingType.BOOKING;
          dpTransferCriteria.param.pickupDependent = true
          dpTransferCriteria.param.dropoffDependent = true

          //if hotel have after trs
          // if(index+1 < criteriaItemList.length){
          //   if(criteriaItemList[index+1].productCode=="HTL"){
          //     dpTransferCriteria.param.dropoffDependent = true
          //   }
          // }
          //
          // //if hotel have before trs
          // if(index > 0){
          //   if(criteriaItemList[index-1].productCode=="HTL"){
          //     dpTransferCriteria.param.pickupDependent = true
          //   }
          // }

          // Assigning pickup and drop-off when TRS only
          this.assignTransferPickUpDropOffLocations(dpTransferCriteria, surfCriItem.criItem);

          //map transfer travellers
          dpTransferCriteria.travellers = this.populateTravellersArray(surfCriItem.criItem.onewayPickupTime.split('T')[0], passengerAllocation, cachePaxSet,
            defaultAdultAge, maxChildAge, maxTeenAge, isGrabFlow);

          if (isAmendmentFlow && dpTransferCriteria.travellers && dpTransferCriteria.travellers.length > 0) {
            this.rearrangePaxNosInAmendment(surfCriItem.paxSelection, dpTransferCriteria.travellers);
          }

          //map transfer attributes
          dpTransferCriteria.attributes = new Array<NameValuePair>();
          dpTransferCriteria.attributes.push(new NameValuePair('expandAlternatives', 'true'));
          dpTransferCriteria.attributes.push(new NameValuePair('nonRefundable', 'false'));
          if (isAmendmentFlow) {
            dpTransferCriteria.attributes.push(new NameValuePair('criteriaUpdateOption', unfreezeItemMap.get(surfCriItem.index)? 'REAL' : 'DUMMY'));
            dpTransferCriteria.attributes.push(new NameValuePair(DpCriteriaManageFlights.ATTR_AMENDMENT, 'true'));
          }
          if (surfCriItem.attributes && surfCriItem.attributes.length > 0){
            this.setAttributes(surfCriItem.attributes, dpTransferCriteria);
          }

          dpProductCriteria.push(dpTransferCriteria);
          // Following data is added to the transfer criteria to be used in refine stage

          break;
        case 'CAR':
          let dpCarCriteria = new DPCarCriteria();

          //map common info
          dpCarCriteria.productCode = 'CAR';
          dpCarCriteria.itineraryItemNo = surfCriItem.index;
          dpCarCriteria.itineraryItemOrder = surfCriItem.index;

          //map car params
          dpCarCriteria.param = new CarParameter();
          dpCarCriteria.param.departureDate = surfCriItem.criItem.pickupDateTime.split('T')[0];
          dpCarCriteria.param.pickupDateTime = surfCriItem.criItem.pickupDateTime;
          dpCarCriteria.param.returnDateTime = surfCriItem.criItem.returnDateTime;
          // TODO: If possible Remove time form CAR search criteria UI
          // dpCarCriteria.param.pickupDateTime = '';
          // dpCarCriteria.param.returnDateTime = '';
          dpCarCriteria.param.pickupCity = surfCriItem.criItem.pickupCity;
          dpCarCriteria.param.returnCity = surfCriItem.criItem.returnCity;
          dpCarCriteria.param.pickupLocation = surfCriItem.criItem.pickupLocation;
          dpCarCriteria.param.returnLocation = surfCriItem.criItem.returnLocation;
          dpCarCriteria.param.driverAge = surfCriItem.criItem.driverAge;
          dpCarCriteria.param.quoteBookingType = QuoteBookingType.BOOKING;

          //map car travellers
          dpCarCriteria.travellers = this.populateTravellersArray(surfCriItem.criItem.pickupDateTime.split('T')[0], passengerAllocation, cachePaxSet,
            defaultAdultAge, maxChildAge, maxTeenAge, isGrabFlow);

          if (isAmendmentFlow && dpCarCriteria.travellers && dpCarCriteria.travellers.length > 0) {
            this.rearrangePaxNosInAmendment(surfCriItem.paxSelection, dpCarCriteria.travellers);
          }

          //map car attributes
          dpCarCriteria.attributes = new Array<NameValuePair>();
          dpCarCriteria.attributes.push(new NameValuePair('expandAlternatives', 'true'));
          dpCarCriteria.attributes.push(new NameValuePair('nonRefundable', 'false'));
          if (isAmendmentFlow) {
            dpCarCriteria.attributes.push(new NameValuePair('criteriaUpdateOption', unfreezeItemMap.get(surfCriItem.index)? 'REAL' : 'DUMMY'));
            dpCarCriteria.attributes.push(new NameValuePair(DpCriteriaManageFlights.ATTR_AMENDMENT, 'true'));
          }
          if (surfCriItem.attributes && surfCriItem.attributes.length > 0){
            this.setAttributes(surfCriItem.attributes, dpCarCriteria);
          }

          // map car contract_type
          const carFilter = new CarFilter();
          carFilter.contractType = surfCriItem.criItem.contractType ? surfCriItem.criItem.contractType : CAR_CONTRACT_TYPES.CAR_HIRE;
          dpCarCriteria.filter = carFilter;

          surfCriItem.initialDateStr = this.createCarDateString(surfCriItem.criItem);
          dpProductCriteria.push(dpCarCriteria);
          break;
        case 'GEN':
          let dpGenericCriteria = new DPGenericCriteria();

          //map common info
          dpGenericCriteria.productCode = 'GEN';
          dpGenericCriteria.itineraryItemNo = surfCriItem.index;
          dpGenericCriteria.itineraryItemOrder = surfCriItem.index;

          //map generic params
          dpGenericCriteria.param = new GenericParameter();
          dpGenericCriteria.param.quoteBookingType = QuoteBookingType.BOOKING;
          dpGenericCriteria.param.departureDate =moment(surfCriItem.criItem.startDate).format('YYYY-MM-DD') ;

          if (surfCriItem.criItem.duration) {
            const durationNumber = surfCriItem.criItem.duration.match(/\d+/g);
            const durationType =  surfCriItem.criItem.duration.match(/[a-zA-Z]+/g);
            dpGenericCriteria.param.duration = durationNumber[0];
            if (durationType[0] && durationType[0].substring(0, 1) === 'D') {
              dpGenericCriteria.param.durationType = 'DAYS';
            } else if ( durationType[0] && durationType[0].substring(0, 1) === 'H' ) {
              dpGenericCriteria.param.durationType = 'HOURS';
            } else if ( durationType[0] && durationType[0].substring(0, 1) === 'N' ) {
              dpGenericCriteria.param.durationType = 'NIGHTS';
            }
          }

          // Map location criteria
          if (surfCriItem.criItem &&
            (surfCriItem.criItem.destinationType === 'CITY' || surfCriItem.criItem.destinationType === 'HOTEL') &&
            surfCriItem.criItem.destinationCode) {
            dpGenericCriteria.param.city =  surfCriItem.criItem.destinationCode;
            if (surfCriItem.criItem.country) {
              dpGenericCriteria.param.country = surfCriItem.criItem.country;
            }
          } else if (surfCriItem.criItem && surfCriItem.criItem.destinationType === 'COUNTRY' && surfCriItem.criItem.destinationCode) {
            dpGenericCriteria.param.country =  surfCriItem.criItem.destinationCode;
          }


          //map generic filters
          dpGenericCriteria.filter = new GenericFilter();
          dpGenericCriteria.filter.elementGroupCode = surfCriItem.criItem.elementGroup;

          //map generic travellers
          dpGenericCriteria.travellers = this.populateTravellersArray(surfCriItem.criItem.startDate, passengerAllocation, cachePaxSet,
            defaultAdultAge, maxChildAge, maxTeenAge, isGrabFlow);

          if (isAmendmentFlow && dpGenericCriteria.travellers && dpGenericCriteria.travellers.length > 0) {
            this.rearrangePaxNosInAmendment(surfCriItem.paxSelection, dpGenericCriteria.travellers);
          }

          //map generic attributes
          dpGenericCriteria.attributes = new Array<NameValuePair>();
          dpGenericCriteria.attributes.push(new NameValuePair('expandAlternatives', 'true'));
          dpGenericCriteria.attributes.push(new NameValuePair('nonRefundable', 'false'));
          if (isAmendmentFlow) {
            dpGenericCriteria.attributes.push(new NameValuePair('criteriaUpdateOption', unfreezeItemMap.get(surfCriItem.index)? 'REAL' : 'DUMMY'));
            dpGenericCriteria.attributes.push(new NameValuePair(DpCriteriaManageFlights.ATTR_AMENDMENT, 'true'));
          }
          if (surfCriItem.attributes && surfCriItem.attributes.length > 0){
            this.setAttributes(surfCriItem.attributes, dpGenericCriteria);
          }

          dpProductCriteria.push(dpGenericCriteria);
          break;
        case 'TOU':// tour creteria mapping
          let dpTourCriteria = new DPTourCriteria();

          //map common info
          dpTourCriteria.productCode = 'TOU';
          dpTourCriteria.itineraryItemNo = surfCriItem.index;
          dpTourCriteria.itineraryItemOrder = surfCriItem.index;

          //map Tour params
          dpTourCriteria.param = new TourParameter();
          dpTourCriteria.param.quoteBookingType = QuoteBookingType.BOOKING;
          if( surfCriItem.criItem.durationRange ){
            dpTourCriteria.param.durationRange = surfCriItem.criItem.durationRange;
          }
          if( surfCriItem.criItem.startDate ) {
            let departureDate = surfCriItem.criItem.startDate;
            let timeStamp = 'T00:00:00';
            if (index > 0 ) {
              const __ret = this.getTourTimeStamp(dpProductCriteria, index, criteriaItemList, timeStamp);
              timeStamp = __ret.timeStamp;
            }
            if (!departureDate.includes('T')) {
              departureDate += timeStamp;
            }
            dpTourCriteria.param.departureDate =  departureDate;
          }else {
            let day = '01';
            const month = surfCriItem.criItem.startMonth;
            const year = surfCriItem.criItem.startYear;
            const currentDate = new Date();
            if (+month === (currentDate.getMonth() + 1)) {
              day = currentDate.getDay().toString().padStart(2, '0');
            }
            let timeStamp = 'T00:00:00';
            let departureDate = year + '-' + month + '-' + day + timeStamp;
            if (index > 0) {
              const __ret = this.getTourTimeStamp(dpProductCriteria, index, criteriaItemList, timeStamp);
              const prevDepartureDate = __ret.prevDepartureDate;
              const preDepMonth = new Date(prevDepartureDate).getMonth() + 1;
              if (+month <= preDepMonth) {
                timeStamp = __ret.timeStamp;
                departureDate = prevDepartureDate + timeStamp;
              }
            }
            dpTourCriteria.param.departureDate = departureDate;
            dpTourCriteria.param.startMonth =  surfCriItem.criItem.startMonth;
            dpTourCriteria.param.startYear =  surfCriItem.criItem.startYear;
          }
          // Map location criteria in param
          if (surfCriItem.criItem &&
            (surfCriItem.criItem.destinationType === 'CITY' || surfCriItem.criItem.destinationType === 'HOTEL') &&
            surfCriItem.criItem.destinationCode) {
            dpTourCriteria.param.city =  surfCriItem.criItem.destinationCode;
            if (surfCriItem.criItem.country) {
              dpTourCriteria.param.country = surfCriItem.criItem.country;
            }
          } else if (surfCriItem.criItem && surfCriItem.criItem.destinationType === 'COUNTRY' && surfCriItem.criItem.destinationCode) {
            dpTourCriteria.param.country =  surfCriItem.criItem.destinationCode;
          }

          //map Tour filters
          dpTourCriteria.filter = new TourFilter();
          if (surfCriItem?.isGrab) {
            let externalSource = new TourExternalSource();
            externalSource.id = Number( surfCriItem?.sourceInfo?.code );
            externalSource.ref =  surfCriItem?.sourceInfo?.name;
            externalSource.leadPaxSurname = surfCriItem?.surName;
            externalSource.supplierCode = surfCriItem.criItem.supplier;
            externalSource.bookingRef = surfCriItem?.grabbedTourId;
            dpTourCriteria.filter.externalSource = externalSource;
          } else {
            dpTourCriteria.filter.itineraryName = surfCriItem.criItem.itineraryName;
            if( surfCriItem.criItem.tourCategory ){
              dpTourCriteria.filter.tourCategory = surfCriItem.criItem.tourCategory.replace(/[~]/g,',');
            }
            if( surfCriItem.criItem.tourDestCities ){
              dpTourCriteria.filter.tourDestinationCities = surfCriItem.criItem.tourDestCities.split(',');
            }
            if( surfCriItem.criItem.supplier ){
              let supplier = new Supplier();
              supplier.code = surfCriItem.criItem.supplier;
              dpTourCriteria.filter.supplier = supplier;
            }
          }

          //map Tour travellers
          dpTourCriteria.travellers = this.populateTravellersArray(surfCriItem.criItem.startDate, passengerAllocation, cachePaxSet,
            defaultAdultAge, maxChildAge, maxTeenAge, isGrabFlow);

          if (isAmendmentFlow && dpTourCriteria.travellers && dpTourCriteria.travellers.length > 0) {
            this.rearrangePaxNosInAmendment(surfCriItem.paxSelection, dpTourCriteria.travellers);
          }

          //map Tour attributes
          dpTourCriteria.attributes = new Array<NameValuePair>();
          if (surfCriItem?.isGrab) {
            dpTourCriteria.attributes.push(surfCriItem?.externalTourId);

          }else{
            dpTourCriteria.attributes.push(new NameValuePair('expandAlternatives', 'true'));
            dpTourCriteria.attributes.push(new NameValuePair('nonRefundable', 'false'));
          }
          if (isAmendmentFlow) {
            dpTourCriteria.attributes.push(new NameValuePair('criteriaUpdateOption', unfreezeItemMap.get(surfCriItem.index)? 'REAL' : 'DUMMY'));
            dpTourCriteria.attributes.push(new NameValuePair(DpCriteriaManageFlights.ATTR_AMENDMENT, 'true'));
          }
          if (surfCriItem.attributes && surfCriItem.attributes.length > 0){
            this.setAttributes(surfCriItem.attributes, dpTourCriteria);
          }

          dpProductCriteria.push(dpTourCriteria);
          break;
        case 'OWA':
          const ownArrangementParam = new OwnArrangementParam();
          ownArrangementParam.name = surfCriItem.criItem.name;
          ownArrangementParam.description = surfCriItem.criItem.description;
          ownArrangementParam.quoteBookingType = surfCriItem.criItem.quoteBookingType;
          ownArrangementParam.departureDate = surfCriItem.criItem.departureDate.includes('T') ? surfCriItem.criItem.departureDate.split('T')[0] : surfCriItem.criItem.departureDate;
          ownArrangementParam.duration = surfCriItem.criItem.duration;
          const ownArrangementCriteria = new OwnArragementCriteria();
          ownArrangementCriteria.itineraryItemNo = surfCriItem.index;
          ownArrangementCriteria.itineraryItemOrder = surfCriItem.index;
          ownArrangementCriteria.productCode = 'OWA';
          ownArrangementCriteria.param = ownArrangementParam;

          //map own arrangement attributes
          ownArrangementCriteria.attributes = new Array<NameValuePair>();
          ownArrangementCriteria.attributes.push(new NameValuePair('expandAlternatives', 'true'));
          ownArrangementCriteria.attributes.push(new NameValuePair('nonRefundable', 'false'));
          if (isAmendmentFlow) {
            ownArrangementCriteria.attributes.push(new NameValuePair('criteriaUpdateOption', unfreezeItemMap.get(surfCriItem.index)? 'REAL' : 'DUMMY'));
            ownArrangementCriteria.attributes.push(new NameValuePair(DpCriteriaManageFlights.ATTR_AMENDMENT, 'true'));
          }

          dpProductCriteria.push(ownArrangementCriteria);

          break;
      }
    });

    return {
      criteriaItemList: criteriaItemList,
      dpProductCriteria: dpProductCriteria,
      passengerAllocation: passengerAllocation,
      cachePaxSet: cachePaxSet
    };
  }

   convertDateObjToStr(date, addTimeComponent = false): string {
    let dateStr = '';
    if (date != null) {
      try {
        dateStr = date.getFullYear();

        dateStr = dateStr + '-';
        if (date.getMonth() < 9) {
          dateStr += '0' + (date.getMonth() + 1);
        } else {
          dateStr += '' + (date.getMonth() + 1);
        }

        dateStr = dateStr + '-';

        if (date.getDate() <= 9) {
          dateStr += '0' + date.getDate();
        } else {
          dateStr += '' + date.getDate();
        }
        if (addTimeComponent) {
          dateStr += 'T00:00:00'
        }
      } catch (e) {
        console.error(e);
      }
      return dateStr;
    }
  }

  addDuration(date, NoOfdays) {
     var depDate = new Date(date);
     var currentDay = depDate.getDate();
     depDate.setDate(currentDay + NoOfdays);
     return this.convertDateObjToStr(depDate, true);
  }

  private getTourTimeStamp(dpProductCriteria: any, index, criteriaItemList: any, timeStamp: string) {
    const [prevDepartureDate, prevTimeStamp] =
      dpProductCriteria[index - 1]?.productCode === 'HTL'
        ? (this.addDuration(dpProductCriteria[index - 1]?.param?.departureDate,parseInt(dpProductCriteria[index - 1]?.param?.nights))).split('T')
        : dpProductCriteria[index - 1]?.param?.departureDate?.split('T');
    const prevSurfCriItem = criteriaItemList[index - 1];
    if (!prevTimeStamp) {
      if (prevSurfCriItem.productCode === 'TRS' && prevSurfCriItem.criItem.onewayPickupTime.includes('T')) {
        timeStamp = 'T' + prevSurfCriItem.criItem.onewayPickupTime.split('T')[1];
      } else if (prevSurfCriItem.productCode === 'CAR' && prevSurfCriItem.criItem.pickupDateTime.includes('T')) {
        timeStamp = 'T' + prevSurfCriItem.criItem.pickupDateTime.split('T')[1];
      } else if (prevSurfCriItem.productCode === 'OWA' && prevSurfCriItem.criItem.departureDate.includes('T')) {
        timeStamp = 'T' + prevSurfCriItem.criItem.departureDate.split('T')[1];
      }
    } else {
      timeStamp = 'T' + prevTimeStamp;
    }
    return {prevDepartureDate, timeStamp};
  }

  createPackageCriteriaPayload(
    dpProductCriteria: any,
    criteriaItemList: any,
    flightGrabPnrCriteria: any,
    baseCriteria: any,
    user: any,
    isAmendmendFlow?: boolean,
    isGetOnlyRequestPayload?: boolean,
    isEpToDpConversion?: boolean,
    geoLocationDetailList?: any,
    geoCoordinatesSetUpData?: any
  ) {
    const packageCriteriaPayload = new TBXRequestWrapper<PackageCriteria>();
    packageCriteriaPayload.keyControls = this.constructPOSTKeyControls(baseCriteria, user);
    packageCriteriaPayload.payload = new PackageCriteria();
    packageCriteriaPayload.payload.param = this.populatePOSTParams();
    packageCriteriaPayload.payload.attributes = this.populatePOSTAttributes(isAmendmendFlow);
    if (dpProductCriteria.length > 0) {
      // if amendment flow, itinerary number should be same as the productKey's number
      if (isAmendmendFlow && criteriaItemList.length > 0) {
        criteriaItemList.forEach((item, index) => {
          if (item && item.productKey) {
           try {
             const tempArray = item.productKey.split('~');
             if (tempArray.length > 1) {
               dpProductCriteria[index].itineraryItemNo = tempArray[1];
             }
           } catch (e) {}
          } else if (item && item.productCode) {
            // when there's a new item added.
            dpProductCriteria[index].itineraryItemNo = '-1';
          }
        });
      }
      // Stop using following sorting method as soon as possible.
      // This method is used because of the date issues in the criteria
      // This ordering has already affects the combineWith attribute fo flight (which is fixed using the workaround)
      if (!isGetOnlyRequestPayload) {
        if ( !isEpToDpConversion ) {
          this.orderProductCriteriaByDates(dpProductCriteria, isAmendmendFlow);
        }
        this.orderCriteriaItemListByDates(criteriaItemList, isAmendmendFlow);
      }
      // this.orderProductCriteriaByItineraryItemNo();
      packageCriteriaPayload.payload.productCriteria = dpProductCriteria;
      const travellerAvailableProduct = dpProductCriteria.find(criteriaItem => criteriaItem.travellers);
      if (travellerAvailableProduct) {
        packageCriteriaPayload.payload.travellers = JSON.parse(JSON.stringify(travellerAvailableProduct.travellers));
      }
      let criteria = dpProductCriteria.find(
        criteria => !!criteria.occupancyChoices && criteria.occupancyChoices.length > 0);
      if (criteria) {
        packageCriteriaPayload.payload.occupancyChoices = criteria.occupancyChoices;
      }
      this.rearrangeItemWisePax(dpProductCriteria, criteriaItemList);
      packageCriteriaPayload.payload.productCriteria = dpProductCriteria;
    }
    // enabling result caching
    const dpPreference = new PackagePreference();
    dpPreference.enableSourceSummaryCache = DpCriteriaManageFlights.DP_ENABLE_SOURCE_SUMMARY_CACHE;
    dpPreference.enableSourceCache = DpCriteriaManageFlights.DP_ENABLE_SOURCE_CACHE;
    packageCriteriaPayload.payload.preference = dpPreference;
    sessionStorage.setItem(this.requestPayloadSessionStorageAccessKey, JSON.stringify(packageCriteriaPayload));
    sessionStorage.setItem('criteria', JSON.stringify(criteriaItemList));
    if (isGetOnlyRequestPayload) {
      sessionStorage.setItem('epRequestPayload', JSON.stringify(packageCriteriaPayload));
    }
    if (geoLocationDetailList) {
      sessionStorage.setItem('geoLocationDetailResultsMap', JSON.stringify(geoLocationDetailList));
      if (geoCoordinatesSetUpData) {
        sessionStorage.setItem('geoCoordinatesSetUpMap', JSON.stringify({tbxSetUpDataList : geoCoordinatesSetUpData}));
      }
    }
  }

  rearrangePaxNosInAmendment(selectedPaxArr, travellers) {
    travellers.forEach(traveller => {
      if (selectedPaxArr && selectedPaxArr.length > 0) {
        const matchingPax = selectedPaxArr.find(pax => {
          return pax.code === traveller.reference
        });
        if (matchingPax) {
          traveller.no = matchingPax.paxNo;
        }
      }
    });
  }

  /*populates the traveller list in each criteria object
   * @param date - departure date of each criteria object
   * @param passengerAllocation - pax allocation list
   * @param cachePaxSet - pax allocation list from the guest detail component
  * @param defaultAdultAge - default adult age retrieved from configs
  * @param maxChildAge - max child age retrieved from configs
  * @param maxTeenAge - max teen age retrieved from configs
  * @param isGrabFlow - if called during the grab flow
  * @return LiteTraveller[]
  * */
  populateTravellersArray(date: string, passengerAllocation: any, cachePaxSet: any,
                          defaultAdultAge : number, maxChildAge : number, maxTeenAge : number, isGrabFlow:boolean, adultAgeList?: Array<number>): LiteTraveller[] {
    const travellerArray: LiteTraveller[] = [];
    let travellerNo = 0;
    if (passengerAllocation.length > 0) {
      passengerAllocation.forEach(paxItem => {
        for (let i = 0; i < paxItem.adult; i++) {
          travellerNo += 1;
          const traveller = new LiteTraveller();
          traveller.type = this.TRAVELLER_TYPE_ADULT;
          if(adultAgeList && adultAgeList.length>i){
            traveller.age = adultAgeList[i];
          }else{
            traveller.age = defaultAdultAge;
          }
          // traveller.age = defaultAdultAge;
          // traveller.dob = this.getDOB(this.TRAVELLER_TYPE_ADULT, defaultAdultAge, maxChildAge, date);
          traveller.dobUnknown = true;
          traveller.no = travellerNo;
          traveller.infantSeatRequired = false;
          traveller.reference = this.TRAVELLER_TYPE_ADULT + travellerNo;
          traveller.count = 1;
          travellerArray.push(traveller);
        }
        if (paxItem.teen.length > 0) {
          for (let i = 0; i < paxItem.teen.length; i++) {
            travellerNo += 1;
            const traveller = new LiteTraveller();
            traveller.type = this.TRAVELLER_TYPE_TEEN;
            traveller.age = paxItem.teen[i];
            if (isGrabFlow && cachePaxSet && cachePaxSet[0] &&
              cachePaxSet[0].teenDOB && cachePaxSet[0].teenDOB[i]) {
              traveller.dob = cachePaxSet[0].teenDOB[i];
              traveller.dobUnknown = false;
            } else {
              // traveller.dob = this.getDOB(this.TRAVELLER_TYPE_TEEN, defaultAdultAge, maxTeenAge, date, traveller.age);
              traveller.dobUnknown = true;
            }
            traveller.no = travellerNo;
            traveller.infantSeatRequired = false;
            traveller.reference = this.TRAVELLER_TYPE_TEEN + travellerNo;
            traveller.count = 1;
            travellerArray.push(traveller);
          }
        }
        if (paxItem.child.length > 0) {
          for (let i = 0; i < paxItem.child.length; i++) {
            travellerNo += 1;
            const traveller = new LiteTraveller();
            traveller.type = this.TRAVELLER_TYPE_CHILD;
            traveller.age = paxItem.child[i];
            if (isGrabFlow && cachePaxSet && cachePaxSet[0] &&
              cachePaxSet[0].childDOB && cachePaxSet[0].childDOB[i]) {
              traveller.dob = cachePaxSet[0].childDOB[i];
              traveller.dobUnknown = false;
            } else {
              // traveller.dob = this.getDOB(this.TRAVELLER_TYPE_CHILD, defaultAdultAge, maxChildAge, date, traveller.age);
              traveller.dobUnknown = true;
            }
            traveller.no = travellerNo;
            traveller.infantSeatRequired = false;
            traveller.reference = this.TRAVELLER_TYPE_CHILD + travellerNo;
            traveller.count = 1;
            travellerArray.push(traveller);
          }
        }
        if (paxItem.infant.length > 0) {
          for (let i = 0; i < paxItem.infant.length; i++) {
            travellerNo += 1;
            const traveller = new LiteTraveller();
            traveller.type = this.TRAVELLER_TYPE_INFANT;

            if (isGrabFlow && cachePaxSet && cachePaxSet[0] &&
              cachePaxSet[0].infantDOB && cachePaxSet[0].infantDOB[i]) {

              traveller.dob = cachePaxSet[0].infantDOB[i];
              traveller.dobUnknown = false;
              let depDate = new Date(date);
              let dob = new Date(cachePaxSet[0].infantDOB[i]);
              let monthDiff = this.monthDiff(dob, depDate);
              if(!isNaN(monthDiff)){
                traveller.age = monthDiff;
              }else{
                traveller.age = paxItem.infant[i];
              }
            } else {
              // traveller.dob = this.getDOB(this.TRAVELLER_TYPE_INFANT, date, this.passengerAllocation[0].infant[i]);;
              // traveller.age = paxItem.infant[i];
              // No infant age and DOB is required as per new development
              if (cachePaxSet[0].infantDOB && cachePaxSet[0].infantDOB[i]) {
                traveller.dob = cachePaxSet[0].infantDOB[i];
                traveller.dobUnknown = false;
              } else {
                // traveller.dob = '';
                traveller.dobUnknown = true;
              }
              if (cachePaxSet[0].infant[i] && cachePaxSet[0].infant[i] > 0) {
                traveller.age = cachePaxSet[0].infant[i];
              } else {
                traveller.age = 1;
              }
            }
            traveller.no = travellerNo;
            traveller.infantSeatRequired = paxItem.infantSeatAllocation[i];
            traveller.reference = this.TRAVELLER_TYPE_INFANT + travellerNo;
            traveller.count = 1;
            travellerArray.push(traveller);
          }
        }
      });
    }
    return travellerArray;
  }

  /*Get DOB for each traveller type
   Restricted to YYYY-MM-DD date format*/
  getDOB(travellerType: string, defaultAdultAge : number, maxChildAge : number, date?: string, age?: number): string {
    let dateBreakDown = null;
    let year  = -1;
    let month = -1;
    let day = -1;
    if (date) {
      dateBreakDown = date.split('-');
      year = +dateBreakDown[0];
      month = +dateBreakDown[1];
      let indexOfT = (dateBreakDown[2] as string).indexOf('T');
      if (indexOfT < 0) {
        day = +dateBreakDown[2];
      }
      else {
        day = +(dateBreakDown[2] as string).slice(0, indexOfT);
      }
    }
    if (age) {
      if (travellerType === this.TRAVELLER_TYPE_INFANT) {
        if (age > 12) {
          year -= 1;
          age %= 12;
          if (month < age ) {
            year -= 1;
            month = 12 - age;
          }
        } else {
          if (month < age ) {
            age -= month;
            year -= 1;
            month = 12 - age;
          } else {
            month -= age;
          }
        }
      } else {
        year -= age;
      }
    } else {
      switch (travellerType) {
        case(this.TRAVELLER_TYPE_ADULT) :
          year -=  defaultAdultAge;
          break;
        case(this.TRAVELLER_TYPE_CHILD) :
          year -=  maxChildAge;
          break;
        case(this.TRAVELLER_TYPE_TEEN) :
          year -=  maxChildAge;
          break;
        case(this.TRAVELLER_TYPE_INFANT) :
          // MAX_INFANT_AGE is 23 months
          year -= 2;
          month += 1;
          if (month > 12) {
            year += 1;
            month = 1;
          }
          break;
      }
    }
    let monthStr = '';
    if (month < 10) {
      monthStr = '0' + month;
    } else {
      monthStr = '' + month;
    }
    let dayStr = '';
    if (day < 10) {
      dayStr = '0' + day;
    } else {
      dayStr = '' + day;
    }
    //mapping js zero months into cgdate month
    if (monthStr === '00') {
      monthStr = '12';
      year--;
    }

    if (monthStr == '02' && dayStr == '29') {
      if (!this.isLeapYear(year)) {
        dayStr = '28';
      }
    }

    return '' + year + '-' + monthStr + '-' + dayStr;
  }

  /*check and return true if the given year is a leap year
  * @param year - given year
  * @return true if leap year, false otherwise*/
  isLeapYear(year): boolean {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  /*return the difference in months between two javascript dates
  * @param d1 - date 1
  * @param d2 - date 2
  * @return - month diff
  * */
  monthDiff(d1, d2) {
    let usrYear, usrMonth = d1.getMonth() + 1;
    let curYear, curMonth = d2.getMonth() + 1;
    if ((usrYear = d1.getFullYear()) < (curYear = d2.getFullYear())) {
      curMonth += (curYear - usrYear) * 12;
    }
    let diffMonths = curMonth - usrMonth;
    if (d1.getDate() > d2.getDate()) {
      diffMonths--;
    }
    return diffMonths;
  }

 /* In hotel criteria refine initial destination is accepted as
  DISPLAYTEXT~LOCTYPE~LOCCODE~LOCNAME~PARENTNAME*/
  createLocationStringForHotel(criteriaItem: any): string {
    let destination: string;
    if (criteriaItem.destinationParentCode === null || criteriaItem.destinationParentCode === 'null' ||
      criteriaItem.destinationParentCode === '') {
      destination = '' + criteriaItem.destinationName + '~' +
        criteriaItem.destinationType + '~' + criteriaItem.destinationCode + '~' +
        criteriaItem.destinationName + '~' + criteriaItem.destinationParentCode;
    } else {
      destination = '' + criteriaItem.destinationName + ', ' + criteriaItem.destinationParentCode + '~' +
        criteriaItem.destinationType + '~' + criteriaItem.destinationCode + '~' +
        criteriaItem.destinationName + '~' + criteriaItem.destinationParentCode;
    }
    return destination;
  }

  /*For car criteria refine pickup and dropOff dates are expected as follows
  PICKUPDATE~DROPOOFDATE*/
  createCarDateString(criteriaItem: any): string {
    return '' + criteriaItem.pickupDateTime + '~' + criteriaItem.returnDateTime;
  }

  /*In flight criteria refine initial destinations are accepted as a string
  * @param criteriaItem - flight criteria item*/
  createAirportStringForRefineCriteria(criteriaItem: any): string {
    let departureAirportString = '';
    let arrivalAirportString = '';
    if (criteriaItem.legs && criteriaItem.legs.length > 0) {
      departureAirportString += criteriaItem.legs[0].departureAirportCode ? criteriaItem.legs[0].departureAirportCode + '~' : '';
      departureAirportString += criteriaItem.legs[0].departureAirportCity ? criteriaItem.legs[0].departureAirportCity + '~' : '';
      departureAirportString += criteriaItem.legs[0].departureAirportCityName ? criteriaItem.legs[0].departureAirportCityName + '~' : '';
      departureAirportString += criteriaItem.legs[0].departureAirportCountryName ? criteriaItem.legs[0].departureAirportCountryName : '';

      arrivalAirportString += criteriaItem.legs[0].arrivalAirportCode ? criteriaItem.legs[0].arrivalAirportCode + '~' : '';
      arrivalAirportString += criteriaItem.legs[0].arrivalAirportCity ? criteriaItem.legs[0].arrivalAirportCity + '~' : '';
      arrivalAirportString += criteriaItem.legs[0].arrivalAirportCityName ? criteriaItem.legs[0].arrivalAirportCityName + '~' : '';
      arrivalAirportString += criteriaItem.legs[0].arrivalAirportCountryName ? criteriaItem.legs[0].arrivalAirportCountryName : '';
      return arrivalAirportString + '|' + departureAirportString;
    }
    return '';
  }

  /*populate occupancy choice objects if there are hotels with multiple rooms and guest allocations*/
  populateOccupancyChoices(passengerAllocation: any, defaultAdultAge : number): any {
    let occupancyChoices =  new Array<OccupancyChoice>();
    if (passengerAllocation.length > 0) {
      passengerAllocation.forEach(paxAllocation => {
        let room = new OccupancyChoice();
        room.adultCount = paxAllocation.adult;
        if (paxAllocation.child) {
          room.childCount = paxAllocation.child.length;
        } else {
          room.childCount = 0;
        }
        if (paxAllocation.teen) {
          room.teenCount = paxAllocation.teen.length;
        } else {
          room.teenCount = 0;
        }
        if (paxAllocation.infant) {
          room.infantCount = paxAllocation.infant.length;
        } else {
          room.infantCount = 0;
        }

        let paxAges = new PaxAge();
        paxAges.adultAges = [];
        for (let i = 0; i < paxAllocation.adult; i++) {
          paxAges.adultAges.push(defaultAdultAge);
        }
        paxAges.teenAges = paxAllocation.teen;
        paxAges.childAges = paxAllocation.child;
        if (paxAllocation.teen && paxAllocation.teen.length > 0) {
          /**
           * The API occupancyChoices model does not have a separate representation for teenAges. Therefore, for the occupancyChoices, the
           * teen ages and count should be appended to the child details (since teen is a subtype of child)
           */
          room.childCount += paxAllocation.teen.length;
          if (!paxAges.childAges) {
            paxAges.childAges = [];
          } else {
            paxAges.childAges = JSON.parse(JSON.stringify(paxAges.childAges));
          }
          paxAllocation.teen.forEach(age => paxAges.childAges.push(age));
        }
        paxAges.infantAges = paxAllocation.infant;

        room.paxAges = paxAges;
        occupancyChoices.push(room);
      });
    }
    return occupancyChoices;

  }

  /*revalidate transfer pickup and dropoff locations for transfer criteria items*/
  assignTransferPickUpDropOffLocations(transferCriteria: DPTransferCriteria, surfCriItem: any) {
    transferCriteria.param.pickupCity = surfCriItem.pickupCity;
    transferCriteria.param.dropOffCity = surfCriItem.dropOffCity;
    transferCriteria.param.pickupLocationCode = typeof surfCriItem.pickupLocationCode === "undefined" || surfCriItem.pickupLocationCode === 'undefined' || surfCriItem.pickupLocationCode === null ? "" : surfCriItem.pickupLocationCode;
    transferCriteria.param.dropoffLocationCode = typeof surfCriItem.dropoffLocationCode === "undefined" || surfCriItem.dropoffLocationCode === 'undefined' || surfCriItem.dropoffLocationCode === null ? "" : surfCriItem.dropoffLocationCode;
    transferCriteria.param.pickupLocationType = surfCriItem.pickupLocationType;
    transferCriteria.param.dropoffLocationType = surfCriItem.dropoffLocationType;
  }

  /* Populate Params section */
  constructPOSTKeyControls(searchKeyControls: any, user:any): TbxKeyControlParameter {
    const keyControls = new TbxKeyControlParameter();
    keyControls.cmp = searchKeyControls.cmp;
    keyControls.div = searchKeyControls.div;
    keyControls.brand = searchKeyControls.brand;
    keyControls.channel = searchKeyControls.channel;
    keyControls.cliId = searchKeyControls.cliId;
    keyControls.cliGrp = searchKeyControls.cliGrp;
    keyControls.cliType = searchKeyControls.cliType;
    keyControls.bkgType = searchKeyControls.bkgType;
    keyControls.membershipType = searchKeyControls.membershipType;
    keyControls.srcCountry = searchKeyControls.srcCountry;
    keyControls.srcMarket = searchKeyControls.srcMarket;
    keyControls.cur = searchKeyControls.cur;
    if (user) {
      keyControls.userId = user.id;
      keyControls.username = user.userSummary.username;
    }
    return keyControls;
  }

  populatePOSTParams() {
    const payloadParams = new PackageParameter();
    payloadParams.cacheType = PackageParameter.CacheType.CACHE;
    payloadParams.searchType = PackageParameter.SearchType.TAILOR_MADE;
    payloadParams.size = this.PACKAGE_PARAMETER_SIZE;
    payloadParams.withAvailability = this.PACKAGE_PARAMETER_WITH_AVAILABILITY;
    return payloadParams;
  }

  populatePOSTAttributes(isAmendmentFlow?: boolean): Array<NameValuePair> {
    const attributes = new Array<NameValuePair>();
    attributes.push(new NameValuePair('expandAlternatives', 'true'));
    attributes.push(new NameValuePair('nonRefundable', 'false'));
    if (isAmendmentFlow) {
      attributes.push(new NameValuePair(DpCriteriaManageFlights.ATTR_AMENDMENT, 'true'));
    }
    return attributes;
  }

  orderProductCriteriaByDates(dpProductCriteria: any, isAmendmendFlow?) {
    if (!isAmendmendFlow) {
      dpProductCriteria.sort( (a, b) =>  this.compareProductDatesAndItineraryItemNo(a, b) );
    }
    // Below map is used to update the FLT 'Combine With' group numbers after sorting of criteria items
    // This map with hold <oldIndex, newIndex> key value pairs<= Important
    const itineraryIndexMemoryMap: Map<number, number> = new Map();
    dpProductCriteria.forEach( (criteria, index) => {
      itineraryIndexMemoryMap.set(criteria.itineraryItemNo, index);
      if (!isAmendmendFlow) {
        criteria.itineraryItemNo = index;
      }
      criteria.itineraryItemOrder = index;
    });
    // Re-Assigning new itinerary index numbers to flight combine with attribute
    if (!isAmendmendFlow) {
      this.rearrangeFlightCombineWithIndexes(itineraryIndexMemoryMap, dpProductCriteria);
    }
  }

  orderCriteriaItemListByDates(criteriaItemList: any, isAmendmendFlow?) {
    if (!isAmendmendFlow) {
      criteriaItemList.sort( (a, b) =>  this.compareStartDates(a, b) );
    }
  }

  compareProductDatesAndItineraryItemNo(a, b): number {
    if (a.param && b.param && a.param.departureDate && b.param.departureDate) {
      const aProductDate = new Date(a.param.departureDate);
      const bProductDate =  new Date(b.param.departureDate);
      if (aProductDate < bProductDate) {
        return -1;
      } else if (aProductDate > bProductDate) {
        return 1;
      } else {
        return a.itineraryItemNo < b.itineraryItemNo ? -1 : a.itineraryItemNo > b.itineraryItemNo ? 1 : 0;
      }
    }
  }

  compareStartDates(a, b): number {
    if (a.criItem && b.criItem && a.criItem.startDate && b.criItem.startDate) {
      const aStartDate = a.productCode === ProductType.TRS &&
      !a.criItem.startDate.includes('T') ?
        new Date(a.criItem.startDate  + 'T00:00:00') : new Date(a.criItem.startDate);
      const bStartDate = b.productCode === ProductType.TRS &&
      !b.criItem.startDate.includes('T') ?
        new Date(b.criItem.startDate  + 'T00:00:00') : new Date(b.criItem.startDate);
      if (aStartDate < bStartDate) {
        return -1;
      } else if (aStartDate > bStartDate) {
        return 1;
      }
    }
  }

  rearrangeFlightCombineWithIndexes(itineraryIndexMemory: Map<number, number>, dpProductCriteria: any) {
    dpProductCriteria.forEach( (criteria) => {
      if (criteria.productCode === ProductType.FLT && criteria.param &&
        criteria.param.searchSegments && criteria.param.searchSegments.length > 0 &&
        criteria.param.searchSegments[0].combineWith > -1) {
        criteria.param.searchSegments[0].combineWith = itineraryIndexMemory.get(criteria.param.searchSegments[0].combineWith);
      }
    });
  }

  rearrangeItemWisePax(dpProducts, criteriaItems) {
    dpProducts.forEach((product, index) => {
      if (criteriaItems[index].isPaxSelectionChanged) {
        const newTravellerArray = [];
        product.travellers.forEach(traveller => {
          let criTraveller = criteriaItems[index].paxSelection.find(pax => {
            return pax.code === traveller.reference;
          });
          if (criTraveller && criTraveller.selected) {
            newTravellerArray.push(traveller);
          }
        });
        product.travellers = newTravellerArray;
        criteriaItems[index].enablePaxSplit = true;
      } else {
        criteriaItems[index].enablePaxSplit = false;
      }
    });
  }
}

export class CriteriaItem {
  index: number;
  productCode: string;
  criItem: any;
  isGrabbedLeg: boolean;
}

export class OwnArragementCriteria {
  productCode: string;
  itineraryItemNo: number;
  itineraryItemOrder: number;
  param: OwnArrangementParam;
  attributes: Array<NameValuePair>;
}

export class OwnArrangementParam {
  name: string;
  description: string;
  quoteBookingType: string;
  departureDate: string;
  duration: number;
}
