import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {
  ConfigService,
  DataShareService,
  DateTimeFormat,
  FetchCriteriaDataExchangeModel,
  SurfAuthorizationService,
  SurfCriteriaUtil, SurfTextCasePipe,
  SurfTourUtilService,
  SurfTourRefreshUtilService,
  SurfUtilService,
  TourCriteria,
  TourFetchCriteria,
  TourFetchStep,
  TourItem, TourRefreshChangeType, TourPaxWiseRefreshChangeType, TourRefreshItem, SurfActionAlertService
} from '@surf/surf-components-core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {CartServiceHandler, DataServiceHandler} from '@tc-core/service/service-handlers';
import { TourSearchServiceHandler } from '@tc-core/service/service-handlers/tour-search-service-handler.service';
import { TC } from '@tc-core/util';
import { ConfigLoader, DataKey, DataStore } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { Subscription } from 'rxjs';
import grabTourOperations from '../security/grab-tour-operations.json';
import { PermissionKeys } from '../security/permission-constants';
import TYPE = TcErrorType.TYPE;
import {Traveller} from '@tc-core/model/it/codegen/tbx/api/commons/traveller';
import {SurfTourGrabProcessUtil} from '../model/surf-tour-grab-process.util';
import {PNR_MODALS, GRAB_PNR_TYPE, PNR_CRITERIA_ACTION} from '@surf/surf-grab-pnr';
import {TBXRequestWrapper} from "@tc-core/model/it/codegen/tbx/api/tbx-request-wrapper";
import {TbxKeyControlParameter} from "@tc-core/model/it/codegen/tbx/api/tbx-key-control-parameter";
import {Product} from "@tc-core/model/it/codegen/tbx/api/product/product";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'surf-grab-tour',
  templateUrl: './grab-tour.component.html',
  styleUrls: ['./grab-tour.component.css']
})
export class GrabTourComponent implements OnInit, OnChanges {
  @Input() grabType: GRAB_PNR_TYPE;
  @Input() tourProduct: any;
  @Input() isDisableGrabTourButton = false;
  @Input() isInBookingPage = false;
  @Input() isDP = false;
  @Input() grabbedTourId = '';
  @Input() tourFetchSummarySearchCriteria: TourCriteria = new TourCriteria();
  @Input() travellers: Array<Traveller>;
  @Input() keyControls;
  @Input() bkgId: any;
  @Input() cachingItemMap = new Map<string, number>();
  @Input() allowNgrxCaching = false;
  @Output() tourGrabResultOutput: EventEmitter<any> = new EventEmitter();
  havePermissionForGrabTour = true;
  showGrabTourModal = false;
  h2hItems = [];
  supplierItems = [];
  OPERATOR = 'OPERATOR';
  tourFetchCriteria = new TourFetchCriteria();
  tourCriteria = new TourCriteria();
  enableH2hDropdown = false;
  BOOKING_TYPE = '';
  CLIENT_TYPE = '';
  searchLoading = false;
  showDPGrabWarning = false;
  isDPCriteriaExist = false;
  fetchError = '';

  permissionKeys;
  tourGrabResult;

  passengerCount: number;

  supplierDataSubscription: Subscription = new Subscription();
  h2hDataSubscription: Subscription = new Subscription();
  tourSummarySearchSubscription: Subscription = new Subscription();
  PNR_EXISTS_IN_DIFFERENT_BOOKING_OR_QUOTE = 'This PNR exists in Quote/Booking ';
  private subscription: Subscription;
  externalError = false;
  disableInputs = false;
  invalidErrorMsg: string;
  invalidErrorMsgDefault = 'Invalid PNR number';
  showLoader = false;
  loaderTitle;
  externalBookingCodeExistInBookings = false;
  surfModalDescription = null;
  grabbedTourItem: TourItem;
  tempCriteria;
  showTravellerChangesModal: boolean;
  grabPnrBookingModule: SurfTourGrabProcessUtil;
  TOU_SEARCH_RESULT_COUNT = 200;
  bookingGrabErrorList = [];
  blockedResultsAvailable = false;
  allResultsBlocked = false;
  paxTypesNames:
    Array<{code: string, name: string}> = [
    {code: 'A', name: 'Adult'},
    {code: 'T', name: 'Teen'},
    {code: 'C', name: 'Child'},
    {code: 'I', name: 'Infant'},
  ];
  DP_GRAB_TOUR_WARNING_MODAL_ID = 'dp-grab-tour-warning-modal';
  UPDATE_TRAVELLER_WARNING_MODAL_ID = 'grab-tour-traveller-update-warning';
  DATE_FORMAT = DateTimeFormat.ADVANCED_DATE_SHORT_FORMAT;
  DATE_TIME_FORMAT = DateTimeFormat.ADVANCED_DATE_WITH_TIME_SHORT_MONTH_TIME_FORMAT;
  childTravellerUpperAgeLimit: number = 11;
  user: any;
  surfTextCasePipe : SurfTextCasePipe;
  durationString = '';
  isMismatchDetailWarning = false;

  showRefreshChangesModal: boolean;
  updateAvailableMessage = false;
  tourRefreshResult: any;
  tourRefreshItem: TourRefreshItem;
  updateBookingObserver: Subscription = new Subscription();
  showApplyChangesModal: boolean;
  refreshError = false;
  refreshErrorMsg: string;

  GRAB_PNR_TYPE = GRAB_PNR_TYPE;
  TourRefreshChangeType = TourRefreshChangeType;
  TourPaxWiseRefreshChangeType = TourPaxWiseRefreshChangeType;
  APPLY_CHANGES_SUCCESS_MODAL_ID = 'refresh-tour--apply-success-modal';
  APPLY_CHANGES_ERROR_MODAL_ID = 'refresh-tour--apply-error-modal';

  constructor(
    private surfAuthorizationService: SurfAuthorizationService,
    protected dataServiceHandler: DataServiceHandler,
    private commonHelper: CommonHelper,
    private renderer: Renderer2,
    private dataStore: DataStore,
    private tourSearchServiceHandler: TourSearchServiceHandler,
    private configLoader: ConfigLoader,
    private surfCriteriaUtil: SurfCriteriaUtil,
    private surfUtilService: SurfUtilService,
    private surfTourUtilService: SurfTourUtilService,
    private surfTourRefreshUtilService: SurfTourRefreshUtilService,
    private commonService: DataShareService,
    private configService: ConfigService,
    private cartServiceHandler: CartServiceHandler,
    private surfActionAlertService: SurfActionAlertService,
  ) {
    this.surfTextCasePipe = new SurfTextCasePipe(configService);
  }

  ngOnInit() {
    this.loadConfigs();
    this.setAuthenticationParameters();
    this.loadAndSetupSupplierData();
    this.loadAndSetupHtoHData();
    this.initBookingType();
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    if (this.user) {
      this.tourCriteria.userId = this.user.id;
      this.tourCriteria.userName = this.user.userSummary.username;
    }
    if (this.dataStore.get(DataKey.emulating).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
      const emulatingTC = this.dataStore.get(DataKey.emulatingTCProfile).getValue();
      this.tourCriteria.cmp = emulatingTC.controls.cmp;
      this.tourCriteria.div = emulatingTC.controls.div;
      this.tourCriteria.brand = emulatingTC.controls.brand;
      this.tourCriteria.channel = emulatingTC.controls.channel;
      this.tourCriteria.cur = emulatingTC.controls.cur;
      this.tourCriteria.cliGrp = emulatingTC.controls.cliGrp;
      this.tourCriteria.cliId = emulatingTC.summary.id;
      this.tourCriteria.srcCountry = emulatingTC.summary.address.country;
    } else if (this.dataStore.get(DataKey.tradeClient).getValue() && !this.dataStore.get(DataKey.keyControls).getValue()) {
      const tradeClient = this.dataStore.get(DataKey.tradeClient).getValue();
      this.tourCriteria.cmp = tradeClient.controls.cmp;
      this.tourCriteria.div = tradeClient.controls.div;
      this.tourCriteria.brand = tradeClient.controls.brand;
      this.tourCriteria.channel = tradeClient.controls.channel;
      this.tourCriteria.cur = tradeClient.controls.cur;
      this.tourCriteria.cliGrp = tradeClient.controls.cliGrp;
      this.tourCriteria.cliId = tradeClient.summary.id;
      this.tourCriteria.srcCountry = tradeClient.summary.address.country;
    } else if (!this.dataStore.get(DataKey.keyControls).getValue()) {
      this.tourCriteria.cmp = this.user.userDetail.defaultCom.code;
      this.tourCriteria.div = this.user.userDetail.defaultDivison.code;
      this.tourCriteria.brand = this.user.userDetail.defaultBrand.code;
      this.tourCriteria.channel = this.user.userDetail.defaultChannel.code;
      this.tourCriteria.cur = this.user.userDetail.defaultCurrency.code;
      this.tourCriteria.cliGrp = this.user.clientGroup;
      this.tourCriteria.cliId = this.user.clientId;
    }

    if (this.dataStore.get(DataKey.keyControls).getValue()) {
      const keyControls = this.dataStore.get(DataKey.keyControls).getValue();

      // tourCriteria
      this.tourCriteria.cmp = keyControls.cmp;
      this.tourCriteria.div = keyControls.div;
      this.tourCriteria.brand = keyControls.brand;
      this.tourCriteria.channel = keyControls.channel;
      this.tourCriteria.cur = keyControls.cur;
      this.tourCriteria.cliGrp = keyControls.cliGrp;
      this.tourCriteria.cliId = keyControls.cliId;
      this.tourCriteria.srcCountry = keyControls.srcCountry;
    }
    this.commonService.getCurrentMsg().subscribe(msg => {
      if (msg === 'dpCriteriaExist') {
        this.isDPCriteriaExist = true;
      }
      if (msg === 'dpCriteriaDoesNotExist') {
        this.isDPCriteriaExist = false;
      }
    });
  }

  loadConfigs() {
    if (this.configLoader.getModuleConfig('TOUR_GRAB_ENABLE_H2H', TC.MODULE_NAME.SURF_B2B)) {
      this.enableH2hDropdown = this.configLoader.getModuleConfig('TOUR_GRAB_ENABLE_H2H', TC.MODULE_NAME.SURF_B2B)
        .toUpperCase() === 'TRUE';
    }
    if (this.configLoader.getModuleConfig('BOOKING_TYPE', TC.MODULE_NAME.SURF_B2B)) {
      this.BOOKING_TYPE = this.configLoader.getModuleConfig('BOOKING_TYPE', TC.MODULE_NAME.SURF_B2B);
    }
    if (this.configLoader.getModuleConfig('CLIENT_TYPE', TC.MODULE_NAME.SURF_B2B)) {
      this.CLIENT_TYPE = this.configLoader.getModuleConfig('CLIENT_TYPE', TC.MODULE_NAME.SURF_B2B);
    }
    if (this.configLoader.getModuleConfig('TOU_SEARCH_RESULT_COUNT', TC.MODULE_NAME.SURF_B2B)) {
      this.TOU_SEARCH_RESULT_COUNT = parseInt(this.configLoader.getModuleConfig('TOU_SEARCH_RESULT_COUNT', TC.MODULE_NAME.SURF_B2B), 10);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.tourFetchSummarySearchCriteria && this.tourFetchSummarySearchCriteria && this.tourFetchSummarySearchCriteria.sourceInfo) {
      const fetchDetails = this.surfTourUtilService.parseTourSourceInfo(this.tourFetchSummarySearchCriteria.sourceInfo);
      if (!this.tourFetchCriteria) {
        this.tourFetchCriteria = new TourFetchCriteria();
      }
      if (fetchDetails) {
        this.handleBookingNo(fetchDetails.bookingNo);
        this.refineSupplier(fetchDetails.bookingNo);
        this.handleLastName(fetchDetails.lastNameOnBooking);
        this.tourFetchCriteria.h2hCode = fetchDetails.h2hCode;
        this.tourFetchCriteria.h2hName = fetchDetails.h2hName;
        this.tourFetchCriteria.supplierCode = fetchDetails.supplierCode;
        this.tourFetchCriteria.supplierName = fetchDetails.supplierName;
      }
    }
  }

  /**
   * Setting up the authentication parameters from the permission framework
   */
  setAuthenticationParameters() {
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(grabTourOperations);

    const tourGrabPermission = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.TOUR_GRAB);

    if ((tourGrabPermission && tourGrabPermission.allowed) || (tourGrabPermission && tourGrabPermission.allowed)) {
      this.havePermissionForGrabTour = true;
    } else {
      this.havePermissionForGrabTour = false;
    }

  }


  changeModalVisibility(modalType: PNR_MODALS, display: boolean) {
    switch (modalType) {
      case PNR_MODALS.GRAB_PNR:
        break;
      case PNR_MODALS.TRAVELLER_COMPARISON:
        this.showTravellerChangesModal = display;
        if (display) {
          this.rearrangeModals('tour-grab-pnr--traveller-comparison-modal');
        }
        break;
      case PNR_MODALS.PNR_ALREADY_EXISTS_IN_A_BOOKING:
        break;
      case PNR_MODALS.REFRESH_PNR_CHANGES:
        break;
    }
    if (display) {
      this.renderer.addClass(document.body, 'is-itinerary-modal-active');
    } else {
      this.renderer.removeClass(document.body, 'is-itinerary-modal-active');
    }
  }

  initBookingType() {
    if (this.isInBookingPage && !this.isDP) {
      this.grabPnrBookingModule = new SurfTourGrabProcessUtil();
      if( this.travellers ){
        this.grabPnrBookingModule.bkgTravellers = this.clone(this.travellers);
      }
    }
  }

  initiateDPGrab(event) {
    if (event === 'confirm') {
      this.showDPGrabWarning = false;
      this.openGrabTourModal();
    } else if (event === 'cancel') {
      this.showDPGrabWarning = false;
    }
  }
  onClickGrab() {
    if (this.isDP) {
      if (this.isDPCriteriaExist) {
        this.showDPGrabWarning = true;
        this.rearrangeModals(this.DP_GRAB_TOUR_WARNING_MODAL_ID);
      } else {
        this.openGrabTourModal();
      }
    } else {
      this.openGrabTourModal();
    }

  }
  /**
   * Open grab tour criteria modal
   */
  openGrabTourModal() {
    this.refineSupplier(this.tourFetchCriteria.supplierCode);
    this.changeFetchTourStage(TourFetchStep.OPEN_GRAB_CRITERIA_MODAL, null);
    if (this.isInBookingPage && !this.isDP) {
      this.commonService.updateBaseCriteria(this.tourCriteria);
      this.commonService.updateComponentCriteria(this.tourCriteria, TC.Product.TOURS);
    }
  }

  rearrangeModals(id) {
    if (document.getElementById(id)) {
      document.body.appendChild(document.getElementById(id));
    }
  }

  /**
   * close grab tour criteria modal
   */
  closeGrabTourModal() {
    this.changeFetchTourStage(TourFetchStep.CLOSE_GRAB_CRITERIA_MODAL, null);
  }

  /**
   * handle booking number change
   */
  handleBookingNo(value) {
    this.tourFetchCriteria.bookingNo = value;
    this.fetchError = '';
  }

  /**
   * handle booking last name change
   */
  handleLastName(value) {
    this.tourFetchCriteria.lastNameOnBooking = value;
    this.fetchError = '';
  }

  /**
   * handle change h2h
   */
  selectHtoH(event) {
    this.fetchError = '';
  }

  /**
   * handle change supplier
   */
  selectSupplier(event) {
    this.tourFetchCriteria.supplierName = event.value;
    this.tourFetchCriteria.supplierCode = event.code;
    this.fetchError = '';
  }

  refineSupplier(event) {
    this.supplierItems.forEach(line => {
        line.selected =  event !== '-1' && event === line.code;
      }
    );
  }

  /**
   * load needed Supplier data
   */
  loadAndSetupSupplierData() {
    this.supplierDataSubscription = this.dataServiceHandler.getSuppliers(TC.Product.TOURS, this.OPERATOR).subscribe(
      data => {
        if (data && data.length > 0) {
          this.supplierItems = [];
          this.supplierDataSubscription.unsubscribe();
          const tempSuppliers = [];
          data.forEach(item => {
            tempSuppliers.push({code: item.code, name: item.name, value: item.name, selected: false});
          });
          tempSuppliers.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
          this.supplierItems.push({code: '-1', name: 'Select supplier', value: 'Select supplier', selected: true});
          this.supplierItems.push(...tempSuppliers);
        }
      },
      error => this.supplierDataSubscription.unsubscribe()
    );
  }

  /**
   * load needed h2h data
   */
  loadAndSetupHtoHData() {
    this.h2hDataSubscription = this.dataServiceHandler.getH2HSystems(false, true).subscribe(
      data => {
        if (data && data.length > 0) {
          const filteredData = data.filter(value => value.detail?.productType === 'TOU' && value?.detail?.active);
          this.h2hItems = [];
          this.h2hDataSubscription.unsubscribe();
          filteredData.forEach((item, index) => {
            if (index === 0) {
              this.h2hItems.push({code: item.code, value: item.name, selected: true});
              this.tourFetchCriteria.h2hCode = item.code;
              this.tourFetchCriteria.h2hName = item.name;
            } else {
              this.h2hItems.push({code: item.code, value: item.name, selected: false});
            }
          });
        }
      },
      error => this.h2hDataSubscription.unsubscribe()
    );
  }

  /**
   * reset tour fetch criteria to initial step
   */
  resetTourFetchCriteria() {
    const selectedH2hCode = this.tourFetchCriteria.h2hCode;
    const selectedH2hName = this.tourFetchCriteria.h2hName;
    this.tourFetchCriteria = new TourFetchCriteria();
    this.tourFetchCriteria.h2hCode = selectedH2hCode;
    this.tourFetchCriteria.h2hName = selectedH2hName;
  }

  /**
   * click grab tour button event behaviour
   */
  clickGrabTour() {
    if (this.tourFetchSummarySearchCriteria) {
      this.tourFetchSummarySearchCriteria.sources = this.tourFetchCriteria.h2hName;
      this.tourFetchSummarySearchCriteria.expand = 'all';
      const tempSearchCriteria = JSON.parse(JSON.stringify(this.tourFetchSummarySearchCriteria));
      tempSearchCriteria.externalSourceId = this.tourFetchCriteria.h2hCode;
      tempSearchCriteria.externalSourceRef = this.tourFetchCriteria.h2hName;
      tempSearchCriteria.externalBookingRef = this.tourFetchCriteria.bookingNo;
      tempSearchCriteria.supplierRef = this.tourFetchCriteria.supplierCode;
      tempSearchCriteria.leadPaxSurname = this.tourFetchCriteria.lastNameOnBooking;
      tempSearchCriteria.validateAlreadyFetched = true;
      this.changeFetchTourStage(TourFetchStep.INITIAL_GRAB_CALL, tempSearchCriteria);
    }
  }
  onClickCancelTraveller() {
    this.initBookingType();
    // this.onClickRemove();
    this.changeModalVisibility(PNR_MODALS.TRAVELLER_COMPARISON, false);
  }
  onClickRemove(): void {
    // this.updateAvailableMessage = false;
    // this.removePnrSelection();
    // this.emitComponentCriteria();
  }

  public isExternalIdExists(searchCriteria) {
    if (this.grabType === GRAB_PNR_TYPE.REFRESH) {
      this.changeFetchTourStage(TourFetchStep.LOADING, 'Refreshing Tour');
    } else {
      this.changeFetchTourStage(TourFetchStep.LOADING, 'Loading Results');
    }
    this.dataStore.set(DataKey.tourExternalSourceInfo, null);
    this.tourSummarySearchSubscription = this.tourSearchServiceHandler.getExternalSourceInfo(searchCriteria).subscribe(result => {
      if (result !== null) {
        if (this.grabType === GRAB_PNR_TYPE.REFRESH) {
          this.validateRefreshDetails(result);
        } else {
          this.validateResults(result, searchCriteria, TourFetchStep.INITIAL_GRAB_CALL, false);
        }
      }
    });
  }

  /**
   * Send tour summary search and get results
   * @param searchCriteria - search criteria
   */
  public performTourSummarySearch(searchCriteria) {
    this.changeFetchTourStage(TourFetchStep.LOADING, 'Loading Results');
    this.setInitialGrabTourSummaryCriteria(searchCriteria);
    searchCriteria.sources = this.tourFetchCriteria.h2hName;
    searchCriteria.sourceInfo = this.tourFetchCriteria.h2hCode + `~` + this.tourFetchCriteria.h2hName + '~' +
      this.tourFetchCriteria.bookingNo + '~~~' + this.tourFetchCriteria.supplierCode + '~' + this.tourFetchCriteria.lastNameOnBooking;
    searchCriteria.showBlockedResults = true;
    this.dataStore.set(DataKey.searchResults, null);
    this.tourSummarySearchSubscription = this.tourSearchServiceHandler.getTourResults(searchCriteria).subscribe(result => {
      if (result !== null) {
        this.validateResults(result, searchCriteria, TourFetchStep.SECONDARY_GRAB_CALL, true);
      }
    });
  }
  clone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  setPassengerDetails(result) {
    const passengers = result.travellerInfo.travellers as Array<any>;
    this.passengerCount = (passengers && passengers.length > 0) ? passengers.length : 0;
    this.grabPnrBookingModule.pnrTravellers = this.clone(passengers);
    this.grabPnrBookingModule.bkgTravellers = this.clone(this.travellers);
  }

  onClickAcceptTravellerChanges() {
    // check for changes and give warning if there are changes
    this.checkMismatch();
    this.proceedToTourPage();
  }
  proceedToTourPage() {
    if (!this.isMismatchDetailWarning) {
      const basePath = '/';
      if (this.grabPnrBookingModule && this.grabPnrBookingModule.resultsPageHelperParam) {
        this.tourFetchSummarySearchCriteria.travellerMapping = this.grabPnrBookingModule.resultsPageHelperParam;
      }
      this.tourFetchSummarySearchCriteria.bookingId = this.bkgId;
      window.location.href = basePath + 'b2b-tours/?' +
        this.surfUtilService.constructKeyParams(this.keyControls, this.TOU_SEARCH_RESULT_COUNT) +
        this.surfTourUtilService.getTourSearchUrlParams(this.tourFetchSummarySearchCriteria, true, this.surfUtilService);
    }
  }

  onClickContinueTraveller(event) {
    // if ( this.grabType === GRAB_PNR_TYPE.QUOTE ) {
    //   this.tourFetchCriteria.quote = true;
    // }
    this.isMismatchDetailWarning = false;
    if (event === 'confirm') {
      this.proceedToTourPage();
      // this.grabPnrBookingModule.continueToTourResultPage(this.tourCriteria, this.passengerCount, this.bkgId);
    } else if (event === 'cancel') {
      this.onClickCancelTraveller();
    }
  }

  checkMismatch() {
  let misMatch = false;
    for ( let i = 0; i < this.grabPnrBookingModule.matchingTravellers.length ; i++) {
      // for matching travellers check other attributes have a difference
      if ( this.grabPnrBookingModule.matchingTravellers[i].diffs.hasMatch === true) {
        if ( Object.keys(this.grabPnrBookingModule.matchingTravellers[i].diffs).filter(d => this.grabPnrBookingModule.matchingTravellers[i].diffs[d] === false ).length >= 1 ) {
          this.changeModalVisibility(PNR_MODALS.TRAVELLER_COMPARISON, false);
          // give a warning if there are changes
          misMatch = true;
          break;
        }
      }
    }
    if (misMatch) {
      this.rearrangeModals(this.UPDATE_TRAVELLER_WARNING_MODAL_ID);
      this.isMismatchDetailWarning = true;
    }
  }
  /**
   * child infant teen travellers
   * @param traveller - pnr traveller
   */
  getPaxTypeDisplayText(traveller: Traveller): string {
    if (traveller && traveller.profile && traveller.profile.type ) {
      const type = this.paxTypesNames.find((item) => (item.code === traveller.profile.type));
      if (type && type.code === this.paxTypesNames[2].code && traveller.profile.age > this.childTravellerUpperAgeLimit) {
        return this.paxTypesNames[1].name;
      }
      return type.name;
    }
    return '';
  }
  getTelephoneNumber(traveller: Traveller) {
    const phone = this.grabPnrBookingModule.getTravellerContact(traveller, 'Telephone' );
    if (phone != null) {
      // FC-35870: Need to remove '+' value here due to a inconsistency in sabre and amadeus grab flows
      return (phone && phone.value && phone.value.length > 0) ? phone.value.replace('+', '') : 'N/A';
    }
    return 'N/A';
  }

  /**
   * check both travellers emails are there
   */
  checkEmail(bkgTraveller: Traveller, pnrTraveller: Traveller ) {

    let email = this.grabPnrBookingModule.getTravellerContact(bkgTraveller, 'E-mail' );
    let pnrEmail = this.grabPnrBookingModule.getTravellerContact(pnrTraveller, 'E-mail' );

    // if both emails are empty return false
    if ((!pnrEmail || !pnrEmail.value || pnrEmail.value.length === 0) && (!email || !email.value || email.value.length === 0)) {
      return false;
    } else {
      return true;
    }
  }

  // setDurationString() {
  //   if (this.tourGrabResult && this.tourGrabResult.summary && this.tourGrabResult.summary.travelDuration &&
  //     this.tourGrabResult.summary.travelDuration.durationType && this.tourGrabResult.summary.travelDuration.duration) {
  //     this.durationString = this.tourGrabResult.summary.travelDuration.duration
  //       + ' ' + this.surfTextCasePipe.transform(this.tourGrabResult.summary.travelDuration.durationType, 'sentence');
  //   }
  // }
  /**
   * Return the email of the traveller
   */
  getEmail(traveller: Traveller) {
    const email = this.grabPnrBookingModule.getTravellerContact(traveller, 'E-mail' );
    if ( email != null) {
      return (email && email.value && email.value.length > 0) ? email.value : 'N/A';
    }
    return 'N/A';
  }
  public validateResults(result, searchCriteria, step: TourFetchStep, isComparison) {
    if (this.commonHelper.dataValidity(result)) {
      this.tourSummarySearchSubscription.unsubscribe();
      if (result && result.data && result.data.length > 0 && step === TourFetchStep.SECONDARY_GRAB_CALL) {
        this.tourGrabResult = result.data;
        // this.setDurationString();
        const criteriaDataList: FetchCriteriaDataExchangeModel[] = [];
        for (const singleResult of result.data) {
          this.onResultsHandler(singleResult, searchCriteria, criteriaDataList);
        }
        this.initializeBookingPageErrors(criteriaDataList);
        if (this.allResultsBlocked) {
          this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
          this.changeFetchTourStage(TourFetchStep.OPEN_GRAB_CRITERIA_MODAL, null);
          return;
        }
        // this.tourCriteria.startMonth = this.surfCriteriaUtil.getMonthStr($event[0]);
        // this.tourCriteria.startYear = this.surfCriteriaUtil.getYearStr($event[0]);
        if (isComparison && result.data && result.data.length > 0 && this.isInBookingPage && !this.isDP) {
          this.setPassengerDetails(result.data[0]);
          this.grabPnrBookingModule.matchTravellers();
          // this.handleStageChange(PNR_STAGES.TRAVELLER_COMPARISON);
          this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
          this.changeModalVisibility(PNR_MODALS.TRAVELLER_COMPARISON, true);
        } else {
          this.changeFetchTourStage(TourFetchStep.CLOSE_GRAB_CRITERIA_MODAL, null);
          this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
          this.changeFetchTourStage(TourFetchStep.FILL_CRITERIA, criteriaDataList);
        }
      } else if (result && result.data && result.data.length === 0 && step === TourFetchStep.SECONDARY_GRAB_CALL) {
        this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
        this.changeFetchTourStage(TourFetchStep.CLOSE_GRAB_CRITERIA_MODAL, null);
        this.changeFetchTourStage(TourFetchStep.OPEN_GRAB_CRITERIA_MODAL, null);
        this.tourFetchSummarySearchCriteria.sourceInfo = null;
        this.fetchError = 'Please check the details are entered correctly and try again.';
      } else if (result && result.data && result.data.length === 0 && step === TourFetchStep.INITIAL_GRAB_CALL) {
        this.changeFetchTourStage(TourFetchStep.CLOSE_GRAB_CRITERIA_MODAL, null);
        this.changeFetchTourStage(TourFetchStep.SECONDARY_GRAB_CALL, this.tourFetchSummarySearchCriteria);
      }
    } else if (result instanceof TcHttpError) {
      this.tourSummarySearchSubscription.unsubscribe();
      // this.fetchError = result.httpResponse.message;
      this.fetchError = 'No results found.';
      this.changeFetchTourStage(TourFetchStep.OPEN_GRAB_CRITERIA_MODAL, null);
      this.tourFetchSummarySearchCriteria.sourceInfo = null;
      this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
    } else if (result instanceof TcApiError) {
      this.tourSummarySearchSubscription.unsubscribe();
      if (step === TourFetchStep.INITIAL_GRAB_CALL && result && result.type &&
        result.type === TYPE.WARN && result.tcResultError && result.tcResultError.message && result.tcResultError.message.length > 0) {
        this.changeFetchTourStage(TourFetchStep.LOADING, this.tourFetchSummarySearchCriteria);
        this.surfModalDescription = result.tcResultError.message + '. \nDo you wish to continue?';
        this.changeFetchTourStage(TourFetchStep.CLOSE_GRAB_CRITERIA_MODAL, null);
        this.changeFetchTourStage(TourFetchStep.BOOKING_EXIST_MODAL_OPEN, this.tourFetchSummarySearchCriteria);
      } else {
        // this.fetchError = result.tcResultError.message;
        this.fetchError = 'No results found.';
        this.changeFetchTourStage(TourFetchStep.OPEN_GRAB_CRITERIA_MODAL, null);
        this.tourFetchSummarySearchCriteria.sourceInfo = null;
      }
      this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
    }
  }

  initializeBookingPageErrors(criteriaDataList) {
    if (this.isInBookingPage && !this.isDP && criteriaDataList && criteriaDataList.length > 0) {
      this.allResultsBlocked = false;
      let blockCount = 0;
      criteriaDataList.forEach(result => {
        if (result.isBlockedResult) {
          this.blockedResultsAvailable = true;
          blockCount++;
          if (!this.bookingGrabErrorList.includes('Booking you are trying to grab is invalid due to ' + result.blockedReason)) {
            this.bookingGrabErrorList.push('Booking you are trying to grab is invalid due to ' + result.blockedReason);
          }
        }
      });
      if (criteriaDataList.length === blockCount) {
        this.allResultsBlocked = true;
      }
    }
  }
  /**
   * set initial tour fetch summary call criteria
   */
  public setInitialGrabTourSummaryCriteria(searchCriteria) {
    if (searchCriteria) {
      searchCriteria.durationRange = null;
      searchCriteria.itineraryName = null;
      searchCriteria.startDate = null;

      this.surfCriteriaUtil.updateKeyControlsForAnyCriteria(this.keyControls, searchCriteria);
      searchCriteria.bkgType = this.BOOKING_TYPE;
      searchCriteria.cliType = this.CLIENT_TYPE;
      searchCriteria.durationType = 'D';
      searchCriteria.privateTour = false;
      const loginUserToday = new Date();
      loginUserToday.setDate(loginUserToday.getDate() + 7);
      searchCriteria.startDate = this.surfUtilService.getDateString(loginUserToday);

    }
  }

  public onResultsHandler(result, searchCriteria, arr) {
    const dataModel = new FetchCriteriaDataExchangeModel();
    this.surfTourUtilService.mapToFetchCriteriaExchangeModel(result, searchCriteria, dataModel);
    if (this.tourFetchCriteria && this.tourFetchCriteria.bookingNo) {
      dataModel.grabbedTourId = this.tourFetchCriteria.bookingNo;
    }
    arr.push(dataModel);

  }


  /**
   * set searchSegments for flight-refine-search component to populate criteria
   */
  setRefineHelperParams() {
    // this.flightCriteria.searchSegments = segmentArray.join(FLIGHT_SEARCH_SEGMENT_SPLITTERS.LEG_SPLITTER) as string;
  }


  changeFetchTourStage(stage: TourFetchStep, data: any) {
    switch (stage) {
      case TourFetchStep.LOADING:
        this.showLoader = true;
        this.loaderTitle = data;
        this.fetchError = '';
        // this.surfModalDescription = errorMessage + '. \nDo you wish to continue?';
        break;
      case TourFetchStep.STOP_LOADING:
        this.showLoader = false;
        this.loaderTitle = null;
        break;
      case TourFetchStep.BOOKING_EXIST_MODAL_OPEN:
        this.rearrangeModals('auto-tour-fetching-exists-modal');
        this.tempCriteria = data;
        this.externalBookingCodeExistInBookings = true;
        break;
      case TourFetchStep.BOOKING_EXIST_MODAL_CLOSE:
        this.externalBookingCodeExistInBookings = false;
        break;;
      case TourFetchStep.FILL_CRITERIA:
        this.tourGrabResultOutput.emit(data);
        break;
      case TourFetchStep.INITIAL_GRAB_CALL:
        this.isExternalIdExists(data);
        break;
      case TourFetchStep.SECONDARY_GRAB_CALL:
        this.performTourSummarySearch(data);
        break;
      case TourFetchStep.OPEN_GRAB_CRITERIA_MODAL:
        this.renderer.addClass(document.body, 'u-unscrollable');
        this.showGrabTourModal = true;
        this.rearrangeModals('grab-tour-modal');
        break;
      case TourFetchStep.CLOSE_GRAB_CRITERIA_MODAL:
        this.renderer.removeClass(document.body, 'u-unscrollable');
        this.showGrabTourModal = false;
        break;
      case TourFetchStep.PASSENGER_COMPARISON:
        this.renderer.removeClass(document.body, 'u-unscrollable');
        this.showGrabTourModal = false;
        break;
      case TourFetchStep.INITIAL_REFRESH_CALL:
        this.isExternalIdExists(data);
        break;
      case TourFetchStep.SHOW_REFRESH_ERROR:
        this.refreshError = true;
        this.refreshErrorMsg = data;
        break;
      case TourFetchStep.HIDE_REFRESH_ERROR:
        this.refreshError = false;
        break;
      case TourFetchStep.OPEN_REFRESH_COMPARISON_MODAL:
        this.renderer.addClass(document.body, 'u-unscrollable');
        this.showRefreshChangesModal = true;
        this.rearrangeModals('refresh-tour--comparison-modal');
        break;
      case TourFetchStep.CLOSE_REFRESH_COMPARISON_MODAL:
        this.renderer.removeClass(document.body, 'u-unscrollable');
        this.showRefreshChangesModal = false;
        break;

    }
    this.surfActionAlertService.triggerGlobalAlertEventHandler({
      event: 'HIDE_DOM',
      hideDOM: this.showGrabTourModal
    });

  }



  handleExternalBookingCodeExistInBooking($event) {
    if ($event === 'confirm') {
      this.changeFetchTourStage(TourFetchStep.LOADING, 'Loading Results');
      this.changeFetchTourStage(TourFetchStep.SECONDARY_GRAB_CALL, this.tempCriteria);
      this.changeFetchTourStage(TourFetchStep.BOOKING_EXIST_MODAL_CLOSE, null);
    } else if ($event === 'cancel') {
      this.changeFetchTourStage(TourFetchStep.BOOKING_EXIST_MODAL_CLOSE, null);
    }
  }

  /**
   * Handle tour refresh button click
   */
  onClickRefresh() {
    if (this.tourCriteria) {
      const tempSearchCriteria = JSON.parse(JSON.stringify(this.tourCriteria));
      tempSearchCriteria.bkgDate = this.tourProduct.bookedDate;
      tempSearchCriteria.productKey = this.tourProduct.productKey;
      tempSearchCriteria.actions = [];
      tempSearchCriteria.actions.push(PNR_CRITERIA_ACTION.REFRESH);
      tempSearchCriteria.bkgId = this.bkgId;
      this.changeFetchTourStage(TourFetchStep.INITIAL_REFRESH_CALL, tempSearchCriteria);
    }
  }

  /**
   * validate and process refresh tour response
   * @param result - refresh tour response
   */
  validateRefreshDetails(result) {
    if (this.commonHelper.dataValidity(result)) {
      this.tourSummarySearchSubscription.unsubscribe();
      if (result && result.data && result.data.length > 0) {
        this.tourRefreshResult = result.data[0];
        this.tourRefreshItem = this.surfTourRefreshUtilService.processRefreshChanges(this.tourRefreshResult);
        console.log(this.tourRefreshItem);
        this.changeFetchTourStage(TourFetchStep.OPEN_REFRESH_COMPARISON_MODAL, null);
        this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
      } else if (result && result.data && result.data.length === 0) {
      }
    } else if (result instanceof TcHttpError) {
      this.tourSummarySearchSubscription.unsubscribe();
      this.changeFetchTourStage(TourFetchStep.SHOW_REFRESH_ERROR, 'Error occurred in refreshing tour.');
      this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
    } else if (result instanceof TcApiError) {
      this.tourSummarySearchSubscription.unsubscribe();
      this.tourSummarySearchSubscription.unsubscribe();
      this.changeFetchTourStage(TourFetchStep.SHOW_REFRESH_ERROR, 'Error occurred in refreshing tour.');
      this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
    }
  }

  /**
   * close refresh tour comparison modal
   */
  closeRefreshTourModal() {
    this.changeFetchTourStage(TourFetchStep.CLOSE_REFRESH_COMPARISON_MODAL, null);
  }

  /**
   * update booking
   */
  updateBooking() {
    this.changeFetchTourStage(TourFetchStep.LOADING, 'Applying tour changes to the booking');
    this.changeFetchTourStage(TourFetchStep.CLOSE_REFRESH_COMPARISON_MODAL, null);

    const requestWrapper = new TBXRequestWrapper();
    const dummyProduct = new Product();
    dummyProduct.productCode = 'TOU';

    requestWrapper.keyControls = this.keyControls;
    requestWrapper.payload = dummyProduct;

    const params = new HttpParams()
      .set('bkgSource', 'TC')
      .set('itemSource', 'TC')
      .set('bkgId', String(this.bkgId))
      .set('actions', 'REFRESH_FROM_EXTERNAL_SOURCE')
      .set('confirm', String(true))
      .set('expand', 'all');

    this.dataStore.set(DataKey.cart, null);
    this.updateBookingObserver.add(
      this.cartServiceHandler.updateCart(String(-1), this.tourProduct.productKey, requestWrapper, params).subscribe(
      result => {
        if (result instanceof TcApiError || result instanceof TcHttpError) {
          this.updateBookingObserver.unsubscribe();
          this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
          this.changeFetchTourStage(TourFetchStep.SHOW_REFRESH_ERROR, 'Error occurred while updating booking.');
          this.showApplyChangesModal = false;
        } else if (result) {
          this.updateBookingObserver.unsubscribe();
          this.changeFetchTourStage(TourFetchStep.STOP_LOADING, null);
          this.showApplyChangesModal = true;
        }
      }));
  }

  /**
   * reload page
   */
  reloadPage() {
    this.showApplyChangesModal = false;
    location.reload();
  }

  /**
   * Handle ok button click in error message
   */
  onErrorMsgButtonClick() {
    this.changeFetchTourStage(TourFetchStep.HIDE_REFRESH_ERROR, null);
  }

}
