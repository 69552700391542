import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { SurfCheckboxModule } from '@surf/surf-checkbox';
import {GrabPnrComponent} from './grab-pnr/grab-pnr.component';
import {SurfInputModule} from '@surf/surf-input';
import {SurfComponentsCoreModule} from '@surf/surf-components-core';
import {SurfPageLoaderModule} from '@surf/surf-page-loader';
import {SurfMessageBoxModule} from '@surf/surf-message-box';
import {SurfModalModule} from "@surf/surf-modal";
import {SurfMultiFieldInputModule} from '@surf/surf-multi-field-input';
import {SurfDropDownModule} from '@surf/surf-drop-down';
import { GrabPnrDetailsComponent } from './grab-pnr-details/grab-pnr-details.component';
import { GrabFlightPriceDetailsComponent } from './grab-flight-price-details/grab-flight-price-details.component';


@NgModule({
  declarations: [GrabPnrComponent, GrabPnrDetailsComponent, GrabFlightPriceDetailsComponent],
    imports: [
        CommonModule,
        SurfInputModule,
        SurfComponentsCoreModule,
        SurfPageLoaderModule,
        SurfMessageBoxModule,
        SurfCheckboxModule,
        SurfModalModule,
        SurfMultiFieldInputModule,
        SurfDropDownModule
    ],
  exports: [GrabPnrComponent]
})
export class SurfGrabPnrModule { }
