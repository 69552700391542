import { NgModule } from '@angular/core';
import { DpTemplateComponent } from './dp-template/dp-template.component';
import {SurfComponentsCoreModule} from '@surf/surf-components-core';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [DpTemplateComponent],
  imports: [
    CommonModule,
    SurfComponentsCoreModule
  ],
  exports: [DpTemplateComponent]
})
export class SurfDpTemplateModule { }
