// ################################## //
// Dont TsLint correct the whole file at Once //
// Go through each TsLint error and correct them //
// Some comparisons use loose comparison with ==
// ###########################################
import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {
  ApiPaxTypes,
  BaseCriteria,
  CarCriteria,
  ConfigService,
  DataShareService,
  DestinationType, Detail,
  DPCriteria,
  FlightCriteria,
  GenericCriteria,
  HotelCriteria,
  ProductType, SurfAuthorizationService, SurfCalendarStartDateService,
  SurfCityPipe, SurfCriteriaService,
  SurfCriteriaUtil, SurfGeoSpatialSearchGoogleService,
  SurfScrollDirective,
  SurfStaticDataService, SurfTravellerEarliestDepartureDateService, TourCriteria,
  TransferCriteria
} from '@surf/surf-components-core';
import {FlightGrabPnrGETCriteria, GRAB_PNR_TYPE, PNR_STAGES, PnrComponentOutput} from '@surf/surf-grab-pnr';
import {PaxAllocationItem} from '@surf/surf-pax-selection';
import {Subscription} from 'rxjs';
import {CriteriaItem} from './criteria-item.model';
import moment from 'moment';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {ProductParameter} from '@tc-core/model/it/codegen/tbx/api/criteria/product-parameter';
import {TransferParameter} from '@tc-core/model/it/codegen/tbx/api/criteria/transfer/transfer-parameter';
import {User} from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import {DpCriteriaManageFlights} from './dp-criteria-manage-flights';
import {DpCriteriaManageTour} from './dp-criteria-manage-tour';
import {AirlineChangeItem, FlightGroupDetail, ViaPointChangeItem} from '@surf/surf-flight-criteria';
import {TC} from '@tc-core/util/constants';
import Product = TC.Product;
import {CommonHelper} from '@tc-core/util/helpers';
import {KeyControlParameter} from '@tc-core/model/it/codegen/tbx/api/v2/key-control-parameter';
import { PermissionKeys } from '../security/permission-constants';
import dpCriteriaOperations from '../security/dp-criteria-operations.json';
import {MasterDataStoreService, StoreConstants} from '@surf/surf-state-manage';

@Component({
  selector: 'surf-dp-criteria',
  templateUrl: 'dp-criteria.component.html'
})
export class DPCriteriaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() isRefine = false;
  @Input() refineGrab = false;
  @Input() keyControls: KeyControlParameter;
  @Input() windowScrollEnable = true;
  @Input() criteriaItemList: any[] = [];
  @Input() isServiceAddComponent = false;
  @Input() cartPaxSelection: any;
  @Input() cartId: string;
  @Input() selectedPaxString: string;
  @Input() productKey: string;
  @Input() bookingId = null;
  @Input() cart = null;
  @Input() isAmendmentFlow = false;
  @Input() isItemsCloseable = true;
  @Input() continueText: string;
  @Input() amendmentReasonCode = null;
  @Input() amendmentCauseCode = null;
  @Input() amendedProducts: string;
  @Input() refinePaxString: string = null;
  @Input() isEpToDpConversion = false;
  @Input() isResetButtonVisible = true;
  @Input() amendProductKey: string; // use to find unfreeze items
  @Input() triggerExternalSearch = '';
  @Input() isGetOnlyRequestPayload = false; // this is use to get request payload with out displaying gp criteria
  @Input() airportCodesList = [];
  @Input() paxTypeAges = [];
  @Input() cachingItemMap = new Map<string, number>();
  @Input() allowNgrxCaching = false;
  @Output() emitRefineSearchClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter();
  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitUnfreezeItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitIsFinishGetOnlyRequestPayload: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line:variable-name
  _continueText = 'Create Itinerary';
  /*DP Guests properties*/
  readonly PROD_CONST = TC.Product;
  guestComponentFocus = false;
  defaultPaxSet: Array<PaxAllocationItem> = [];
  cachePaxSet: Array<any> = [];
  roomWiseSelected = false;
  setRoomWise = false;
  MAX_CABIN_PAX_COUNT = 5;
  MAX_CHILD = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_TEEN = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_INFANTS = this.MAX_CABIN_PAX_COUNT - 1;
  MAX_ROOMS = 9;
  MIN_ADULT = 1;
  MAX_ADULT = 10;
  MAX_PAX_COUNT = 40;
  DEFAULT_ADULT_AGE = 30;
  CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES;
  MAX_INFANT_AGE = 23;
  MAX_CHILD_AGE = 12;
  MAX_TEEN_AGE = 17;
  TEEN_PAX_ACTIVE;
  DISABLE_DP_GRAB_ADD_ANOTHER_FLOW = false;
  GEO_ENABLE_FOR_HOTEL_SEARCH = false;
  DP_TEMPLATES_ENABLED = true;
  DP_TEMPLATE_ELIGIBLE_DIV_BRANDS = '';
  FIELD_SEPERATOR = '~';
  AGE_SEPERATOR = '__';
  LESS_THAN_ONE = '< 1';
  MIN_SEARCH_QUERY_LENGTH = 3;
  MIN_SEARCH_QUERY_LENGTH_AIRLINE = 2;
  GDS_AMD = 'AMD';
  selectedGDS;
  ENABLE_SURF_MC_GRAB;
  ENABLE_SURF_TOUR_GRAB_MULTIPLE_ITEM_DP_FLOW = false;
  GEO_ENABLE_LOCATION_OVERRIDE_SETUP = false;
  /*General properties*/

  DEST_TYPE_ID_AIRPORT = 60;
  DEST_TYPE_ID_CITY = 40;
  DEST_TYPE_ID_COUNTRY = 20;

  initialItem: any;
  allowRoomWisePax = false;
  tourFetchEnableInDp = false;
  permissionKeys;

  // criteriaItemList : any[];
  baseCriteria: BaseCriteria;
  dpCriteria: DPCriteria = new DPCriteria();
  productCriteriaList = [];
  dpProductCriteria = [];
  hotelDestinations = [];
  carDestinations = [];
  elementGroups = [];
  moduleConfigs: any;
  activeMessageBox = false;
  hotelDependencyItems: any;
  changingItems = '';
  addToTop = false;
  TRAVELLER_TYPE_ADULT = 'A';
  TRAVELLER_TYPE_CHILD = 'C';
  TRAVELLER_TYPE_TEEN = 'T';
  TRAVELLER_TYPE_INFANT = 'I';
  PACKAGE_PARAMETER_SIZE = 1;
  PACKAGE_PARAMETER_WITH_AVAILABILITY = true;
  user: User = null;
  passengerAllocation: Array<PaxAllocationItem> = null;
  requestPayloadSessionStorageAccessKey = 'requestPayload';
  cityPipe: SurfCityPipe;
  paxDisplay: string;
  hasHotelItem = false;

  // manage flights / air components
  manageFlightsModalDisplay = false;
  manageFlightIncompleteFields = false;
  DpCriteriaManageFlights: DpCriteriaManageFlights;
  dpCriteriaManageTour: DpCriteriaManageTour;
  flightsGrouping: Array<FlightGroupDetail>;

  enableGrabPnr: boolean;
  pnrType: GRAB_PNR_TYPE;
  selectedPnrNumber: string;
  disableAllInputs = false; // when grab PNR disable all user inputs
  isPnr = false; // whether the current criteria is PNR or not
  pnrReset = false;
  showDPGrabResetWarning = false;
  showDPTourGrabResetWarning = false;
  showAmendmentDependencyModal = false;

  MANAGE_FLIGHTS_MODAL_ID = 'manage-flights-modal-dp';
  DP_GRAB_RESET_WARNING_MODAL_ID = 'grab-reset-modal-dp';
  DP_GRAB_RESET_WARNING_MODAL_ID_NEW = 'grab-reset-modal-dp-new';
  AMENDMENT_MODAL_ID = 'amendment-modal-destination-change';
  AMENDMENT_DEPENDENCY_MODAL_ID = 'amendment-dependency-modal-dp';
  subscriptions = new Subscription();
  flightGrabPnrCriteria: FlightGrabPnrGETCriteria;
  tourCriteria: TourCriteria = new TourCriteria();
  stageChangeTrigger: PNR_STAGES = PNR_STAGES.DEFAULT;
  grabbedTourId;
  grabPax = [];
  isGrabFlow = false;
  grabbedCriteriaItemList = [];
  unfreezeItemMap = new Map();
  initialItemMap = new Map();
  hasFlightItem = false;
  hasTourItem = false;
  isFlightsNotAmendedMessageShown = false;
  isToursNotAmendedMessageShown = false;
  recentlyChangedItem: any = null;
  impactedItemList: AmendmentImpactedItem [];
  mapChangeActions = {
    ADD : 'ADD',
    REMOVE : 'REMOVE'
  };
  isPassengerSplit = false;
  existingPassengerCount = 0;
  activeCriteriaButton = false;


  adultAgeList: Array<number> = [];
  selectedItemMap = new Map<number, string>();
  isActiveDpTemplate = false;
  brand = '';
  division = '';
  geoLocationDetailError = '';
  geoLocationDetailList = [];
  overriddenDataSubscription = new Subscription();
  tbxResultData = [];
  GEO_GATEWAY_FOR_HTL_LOCATIONS = 'GOOGLE';
  constructor(protected commonService: DataShareService,
              protected dataServiceHandler: DataServiceHandler,
              private configLoader: ConfigLoader,
              private commonHelper: CommonHelper,
              protected dataStore: DataStore,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected configService: ConfigService,
              private surfAuthorizationService: SurfAuthorizationService,
              public surfCriteriaService: SurfCriteriaService,
              private surfStaticDataService: SurfStaticDataService,
              protected surfCityPipe: SurfCityPipe,
              protected renderer: Renderer2,
              private dataService: DataServiceHandler,
              protected surfGeoSpatialSearchGoogleService: SurfGeoSpatialSearchGoogleService,
              public surfTravellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              public surfCalendarStartDateService: SurfCalendarStartDateService,
              protected masterDataStoreService: MasterDataStoreService) {
    this.cityPipe = new SurfCityPipe(surfStaticDataService);
  }

  ngOnInit(): void {
    this.setAuthenticationParameters();
    this.tourCriteria.adult = '2~30'; // set dummy adult value
    if (this.isRefine || this.isAmendmentFlow) {
      // cached pax criteria needs to be loaded only for refine flow and amend flow initially
      this.getCachedPaxCriteria();
    } else {
      this.cachePaxSet = [new PaxAllocationItem(2, [], [], [], [])];
    }
    this.loadConfigs();
    this.loadBrandDivisions();
    if (!this.paxTypeAges || this.paxTypeAges.length === 0) {
      this.loadPaxTypeAges();
    } else {
      this.mapPaxSubTypeAges();
    }
    this.loadCities();
    this.loadTbxGeoData();
    this.dpCriteria = new DPCriteria();
    this.DpCriteriaManageFlights = new DpCriteriaManageFlights();
    this.dpCriteriaManageTour = new DpCriteriaManageTour();
    this.flightsGrouping = this.DpCriteriaManageFlights.createGroupDetailsFromCriteriaList(this.criteriaItemList, this.refineGrab);
    if (this.criteriaItemList && this.criteriaItemList.length > 0) {
      this.hasHotelItem = this.criteriaItemList.findIndex(
        (criteria) => criteria.productCode && criteria.productCode === ProductType.HTL) > -1;
      const grabTourItemFound = this.criteriaItemList.find( (criteria) =>
        criteria.productCode && criteria.productCode === ProductType.TOU && criteria?.isGrab
      );
      if (grabTourItemFound) {
        this.grabbedTourId = grabTourItemFound.grabbedTourId; // populate grab id here
      }
    }
    // this.criteriaItemList = [];

    this.subscriptions.add(this.commonService.getBaseCriteria().subscribe(cri => {
      this.baseCriteria = cri;
    }));

    this.subscriptions.add(this.commonService.getCurrentMsg().subscribe(msg => {
      if (msg != undefined && msg.split('~') != undefined && (msg.split('~')[0] === 'DP_Switch')) {
        if (msg.split('~').length > 3) {
          if (msg.split('~')[1] && msg.split('~')[1] === 'FLT') {
            if (this.commonService.getComponentCriteria('FLT')) {
              const criteria = this.commonService.getComponentCriteria('FLT').source as any;
              if (criteria._value.productCode === undefined) {
                this.addCriteriaItem(msg.split('~')[1], 1);
              } else {
                this.addCriteriaItem(msg.split('~')[1]);
              }
            }
          } else {
            this.addCriteriaItem(msg.split('~')[1]);
          }
          this.addCriteriaItem(msg.split('~')[4]);
        } else {
          this.addCriteriaItem(msg.split('~')[1]);
        }
        const itemArray = [];
        this.criteriaItemList = JSON.parse(JSON.stringify(this.criteriaItemList));
        if (msg.split('~')[4] && msg.split('~')[4] === 'FLT') {
          if (this.commonService.getComponentCriteria('FLT')) {
            const criteria = this.commonService.getComponentCriteria('FLT').source as any;
            if (criteria._value.productCode === undefined) {
              this.DpCriteriaManageFlights.updateFlightItemsOnRemove(
                this.flightsGrouping,
                this.criteriaItemList,
                this.criteriaItemList[this.criteriaItemList.length - 1]
              );
              this.criteriaItemList.splice(this.criteriaItemList.length - 1, 1);
            }
          }
        }
        if (msg.split('~').length < 7) {
          this.criteriaItemList.forEach((item, index) => {
            if (item.valueOf().productCode !== 'OWA') {
              if (this.commonService.getComponentCriteria(item.valueOf().productCode)) {
                const criteria = this.commonService.getComponentCriteria(item.valueOf().productCode).source as any;
                criteria._value.itemIndex = item.index;
                if (index === (this.criteriaItemList.length - 1) && criteria._value.productCode === undefined) {
                  if (item.valueOf().productCode === 'FLT') {
                    this.DpCriteriaManageFlights.updateFlightItemsOnRemove(
                      this.flightsGrouping,
                      this.criteriaItemList,
                      this.criteriaItemList[index]
                    );
                  }
                  this.criteriaItemList.splice(index, 1);
                  const event = {
                    preItemIndex: (index - 1),
                    postItemIndex: index,
                    newItemType: item.valueOf().productCode
                  };
                  this.addAnotherCriteriaItem(event);
                } else if (criteria) {
                  itemArray.push(criteria._value);
                }
              }
            } else {
              if (index === (this.criteriaItemList.length - 1)) {
                this.criteriaItemList.splice(index, 1);
                const event = {preItemIndex: (index - 1), postItemIndex: index, newItemType: item.valueOf().productCode};
                this.addAnotherCriteriaItem(event);
              }
            }
          });
        } else {
          this.criteriaItemList.forEach((item, index) => {
            if (item.valueOf().productCode !== 'OWA') {
              if (this.commonService.getComponentCriteria(item.valueOf().productCode)) {
                const criteria = this.commonService.getComponentCriteria(item.valueOf().productCode).source as any;
                criteria._value.itemIndex = index;
                if (index === (this.criteriaItemList.length - 1) && criteria._value.productCode === undefined) {
                  this.criteriaItemList.splice(index, 1);
                  const position = parseInt(msg.split('~')[6]);
                  const event = {
                    preItemIndex: position,
                    postItemIndex: (position + 1),
                    newItemType: item.valueOf().productCode
                  };
                  this.addAnotherCriteriaItem(event);
                } else if (criteria) {
                  itemArray.push(criteria._value);
                }
              }
            } else if (index === (this.criteriaItemList.length - 1)) {
              this.criteriaItemList.splice(index, 1);
              const position = parseInt(msg.split('~')[6]);
              const event = {
                preItemIndex: position,
                postItemIndex: (position + 1),
                newItemType: item.valueOf().productCode
              };
              this.addAnotherCriteriaItem(event);
            }
          });
        }
        this.commonService.updateProductCriteriaList(itemArray);
      }
    }));

    this.subscriptions.add(this.commonService.getShareObj().subscribe(shareObj => {
      if (shareObj != null && shareObj instanceof PnrComponentOutput) {
        const grabCriteria = shareObj as PnrComponentOutput;
        this.setGrabPNRCriteria(grabCriteria, true);
      }
    }));

    // this.commonService.getProductCriteriaList().subscribe(list => {
    //   // this.productCriteriaList = list;
    //   // this.syncCriteriaItems();
    //   // this.updateRelativeItemProperties();
    // });
    if (this.isRefine) {
      this.commonService.updateChangeItem(null); // when initially loading dp criteria in refine page, there has to be no change items
    }
    this.subscriptions.add(this.commonService.getChangeItem().subscribe(changeItem => {
      if (!changeItem) {
        return;
      }
      this.recentlyChangedItem = changeItem as any;
      if (this.isAmendmentFlow && this.recentlyChangedItem.amendmentChangeType) {
        this.handleAmendmentDependencyIdentification(changeItem);
      } else {
        this.updateCriteriaItemList(changeItem);
      }
    }));

    // this.loadCities();
    // this.loadCountries();
    if ( (this.airportCodesList == undefined || (this.airportCodesList && this.airportCodesList.length == 0)) && !this.isRefine ) {
      this.loadAirports();
    }
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    // set pax set values if it is refine search
    if (this.isRefine) {
      this.getPassengerSelection(this.cachePaxSet);
    } else if (this.cartPaxSelection) {
      this.getPassengerSelection(this.cartPaxSelection);
    }

    if (this.isServiceAddComponent && this.passengerAllocation) {
      const adults = this.passengerAllocation.reduce( (acc, pax ) => acc + pax.adult , 0 ) ;
      const child = this.passengerAllocation.reduce( (acc, pax) => acc + pax.child.length , 0) ;
      const teen = this.passengerAllocation.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
      const infant = this.passengerAllocation.reduce( (acc, pax) => acc + pax.infant.length , 0 ) ;
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen) ;
    }

    if (this.isRefine) {
      this.setSelectedPnrInRefine();
      this.pnrType = GRAB_PNR_TYPE.REFINE;
      this.disableAllInputs = this.refineGrab;
      if (this.refineGrab) {
        this.isGrabFlow = true;
      }
    } else {
      this.pnrType = GRAB_PNR_TYPE.CRITERIA;
    }

    if (this.isAmendmentFlow) {
      this.impactedItemList = [];
      this.showAmendmentDependencyModal = false;
      this.activeMessageBox = false;
      this._continueText = 'Search';
      this.hasTour();
    }


    if (this.isGetOnlyRequestPayload) {
      this.createDPProductCriteria(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cartPaxSelection && this.cartPaxSelection) {
      const adults = this.cartPaxSelection.reduce( (acc, pax ) => acc + pax.adult , 0 );
      const child = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.child.length , 0);
      const teen = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.teen.length , 0);
      const infant = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.infant.length , 0 );
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getPassengerSelection(this.cartPaxSelection);
    }

    if (changes.isAmendmentFlow) {
      console.log('this.isAmendmentFlow');
      console.log(this.isAmendmentFlow);
    }

    if (changes.amendProductKey && this.amendProductKey) {
      this.impactedItemList = [];
      this.showAmendmentDependencyModal = false;
      this.activeMessageBox = false;
      this.setInitialUnfreezeMap();
    }

    if (changes.criteriaItemList && this.criteriaItemList && this.isAmendmentFlow) {
      this.loadBrandDivisions();
      this.setOldCriteria();
    }

    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === 'DP') {
      this.createDPProductCriteria(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }
  }

  loadTbxGeoData() {
    this.tbxResultData = [];
    const setupMap = new Map<string, Detail>();

    this.overriddenDataSubscription = this.dataService.getOverriddenGeoLocationData(this.GEO_GATEWAY_FOR_HTL_LOCATIONS).subscribe(
      data => {
        if (data && data.length > 0) {
         data.forEach(element => {
           this.tbxResultData.push({code: element.code, detail: element.detail});
           setupMap.set(element.code, element.detail);
         });
         this.surfGeoSpatialSearchGoogleService.setResultMap(setupMap);
         this.overriddenDataSubscription.unsubscribe();
        }
      },
      error => console.error('Error in getting data from TBX setup', error)
    );
  }

  loadBrandDivisions() {
    this.user = this.dataStore.get(DataKey.userDetail).getValue();
    if (this.user && this.user.userDetail && (this.user.userDetail.defaultBrand || this.user.userDetail.defaultDivison)) {
      this.brand = this.user.userDetail.defaultBrand.code;
      this.division = this.user.userDetail.defaultDivison.code;
    }

    const brandAndDivisions = this.DP_TEMPLATE_ELIGIBLE_DIV_BRANDS.split(';');
    brandAndDivisions.forEach(companyDivisionElement => {
      const brandDivisionIdListList = this.splitData(companyDivisionElement, '~');
      const brandList = brandDivisionIdListList && brandDivisionIdListList.length > 0 ? this.splitData(brandDivisionIdListList[0], ',') : [];
      const divisionList = brandDivisionIdListList && brandDivisionIdListList.length > 1 ? this.splitData(brandDivisionIdListList[1], ',') : [];

      if ((brandList && (brandList.indexOf(this.brand) !== -1)) && divisionList && (divisionList.indexOf(this.division) !== -1)) {
        this.isActiveDpTemplate = true;
      }
    });
  }
  /**
   * Split given string and return result array
   * @param stringToSplit
   * @param splitPrm
   */
  splitData(stringToSplit, splitPrm) {
    let splitDataArray = [];
    try {
      if (stringToSplit.indexOf(splitPrm) >= 0) {
        splitDataArray = stringToSplit.split(splitPrm);
      } else {
        splitDataArray.push(stringToSplit);
      }
    } catch (error) {
      splitDataArray = [];
    }
    return splitDataArray;
  }

  /**
   * this is depend on the configuration value
   */
  checkTourGrabHasMultipleItems(): boolean {
    let isCriteriaValid = false;

    if (this.ENABLE_SURF_TOUR_GRAB_MULTIPLE_ITEM_DP_FLOW) {
      isCriteriaValid = true;
    } else {
      const itemList = this.criteriaItemList.filter( criteriaItem => criteriaItem.productCode === ProductType.TOU && criteriaItem?.isGrab );
      if ( itemList.length <= 1) {
        isCriteriaValid = true;
      }
    }
    return isCriteriaValid;

  }
  loadPaxTypeAges() {
    this.dataServiceHandler.getPaxSubTypeAges().subscribe(
      res => {
        if (res == null || !(Array.isArray(res)) || res.length === 0) {
          console.log('Could not fetch Pax Type Ages');
          return;
        }

        this.paxTypeAges = res[0].paxTypes;
        this.mapPaxSubTypeAges();

      },
      error => {
        console.log('Error in getting pax sub type ages');
      });
  }
  private mapPaxSubTypeAges() {
    this.MAX_CHILD_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'C').age.upper / 12);
    this.MAX_TEEN_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'T').age.upper / 12);
    this.MAX_INFANT_AGE = this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
  }

  ngAfterViewInit(): void {
    this.rearrangeModals();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.removeModals();
  }

  loadConfigs() {
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();
    // this.DISABLE_DP_GRAB_ADD_ANOTHER_FLOW = !(this.commonHelper.convertToBoolean(
    //     this.configLoader.getModuleConfig('ENABLE_GRAB_PLUS_OTHER-ITEM_FLOW')));
    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'MAX_ROOMS':
            this.MAX_ROOMS = mc.name;
            break;
          case 'MIN_ADULT':
            this.MIN_ADULT = mc.name;
            break;
          case 'MAX_ADULT':
            this.MAX_ADULT = mc.name;
            break;
          case 'MAX_PAX_COUNT':
            this.MAX_PAX_COUNT = mc.name;
            break;
          case 'MAX_CHILD_AGE':
            this.MAX_CHILD_AGE = +mc.name;
            break;
          case 'MAX_INFANT_AGE':
            this.MAX_INFANT_AGE = +mc.name;
            break;
          case 'MAX_CABIN_PAX_COUNT':
            this.MAX_CABIN_PAX_COUNT = mc.name;
            break;
          case 'DEFAULT_ADULT_AGE':
            this.DEFAULT_ADULT_AGE = mc.name;
            break;
          case 'CAR_AUTO_POPULATING_PICKUP_DROPOFF_DEFAULT_TIMES':
            this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES = JSON.parse(mc.name);
            break;
          case 'GRAB_PNR_ENABLE':
            this.enableGrabPnr = ((mc.name as string) === 'true');
            break;
          case 'DP_ENABLE_SOURCE_SUMMARY_CACHE':
            DpCriteriaManageFlights.DP_ENABLE_SOURCE_SUMMARY_CACHE = (typeof mc.name == 'string') ? mc.name == 'true' : mc.name;
            break;
          case 'DP_ENABLE_SOURCE_CACHE':
            DpCriteriaManageFlights.DP_ENABLE_SOURCE_CACHE = (typeof mc.name == 'string') ? mc.name == 'true' : mc.name;
            break;
          case 'ENABLE_SURF_MC_GRAB':
            this.ENABLE_SURF_MC_GRAB = (typeof mc.name == 'string') ?
              (mc.name && mc.name.toLowerCase() == 'true') :
              mc.name;
            break;
          case 'ENABLE_SURF_TOUR_GRAB_MULTIPLE_ITEM_DP_FLOW':
            this.ENABLE_SURF_TOUR_GRAB_MULTIPLE_ITEM_DP_FLOW = (typeof mc.name == 'string') ?
              (mc.name && mc.name.toLowerCase() == 'true') :
              false;
            break;
          case 'GEO_ENABLE_LOCATION_OVERRIDE_SETUP':
            this.GEO_ENABLE_LOCATION_OVERRIDE_SETUP = (typeof mc.name == 'string') ?
              (mc.name && mc.name.toLowerCase() == 'true') :
              false;
            break;
          case 'TEEN_PAX_ACTIVE':
            this.TEEN_PAX_ACTIVE = ((mc.name as string) === 'true');
            break;
          case 'ENABLE_GRAB_PLUS_OTHER-ITEM_FLOW':
            this.DISABLE_DP_GRAB_ADD_ANOTHER_FLOW = !((mc.name as string) === 'true');
            break;
          case 'DP_TEMPLATES_ENABLED':
            this.DP_TEMPLATES_ENABLED = ((mc.name as string) === 'TRUE');
            break;
          case 'DP_TEMPLATE_ELIGIBLE_DIV_BRANDS':
            this.DP_TEMPLATE_ELIGIBLE_DIV_BRANDS = mc.name;
            break;
          case 'GEO_ENABLE_FOR_HOTEL_SEARCH':
            this.GEO_ENABLE_FOR_HOTEL_SEARCH = ((mc.name as string).toUpperCase() === 'TRUE');
            break;
        }
      });
    }
  }

  loadCities() {
    this.dataServiceHandler.getCitiesWithDetails('all').subscribe(
      res => {
        if (res && res.data) {
          const cities = res.data;
          this.surfCriteriaUtil.updateValue(cities);
          cities.forEach(city => {
            this.hotelDestinations.push(city);
            this.carDestinations.push(city);
          });
        }
      },
      error => {
        console.log('Error in getting cities');
      });
  }

  loadCountries() {
    this.dataServiceHandler.getCountries().subscribe(
      res => {
        if (res) {
          const countries = res;
          this.surfCriteriaUtil.updateValue(countries);
          countries.forEach(country => {
            this.hotelDestinations.push(country);
          });
        }
      },
      error => {
        console.log('Error in getting Countries');
      });
  }

  loadAirports() {
    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_AIRPORTS) !== null
      && this.cachingItemMap.get(StoreConstants.CACHE_AIRPORTS) > 0)) {
      this.getAirportsFromCache(this.cachingItemMap.get(StoreConstants.CACHE_AIRPORTS));
    } else {
      this.dataServiceHandler.getAirports().subscribe(
        res => {
          this.mapAirportsList(res);
        },
        error => {
          console.log('Error in getting airports');
        });
    }
  }

  private getAirportsFromCache(timeout: number) {
      this.masterDataStoreService.getAirports(timeout).subscribe(
        res => {
          if (res) {
            this.mapAirportsList(res);
          }
        },
        error => {
          console.log('Error in getting airports');
        });
  }

  private mapAirportsList(res: any) {
    this.airportCodesList = [];
    const airportList = res;
    if (airportList && airportList.length > 0) {
      airportList.forEach(airport => {
        if (airport.code) {
          this.airportCodesList.push(airport.code);
        }
      });
    }
  }

  /**
   * Receives a change item and updates the corresponding item in the criteriaItemList. Also updates post index items based on the change item value
   * Note: The change item is used to extract the new value out of a search criteria component
   * @param changeItem
   */
  updateCriteriaItemList(changeItem) {
    if (this.criteriaItemList && this.criteriaItemList.length > 0 &&
      changeItem != undefined && changeItem.index != undefined && changeItem.productCode != undefined && changeItem.value != undefined && changeItem.changeType != undefined) {

      // if user added an item to the top of the list and set dates for the first time
      if (changeItem.index == 0 && this.criteriaItemList.length > 1 && this.addToTop) {
        let ciObj;
        this.criteriaItemList.forEach(itm => {
          if (itm.index == changeItem.index) {
            ciObj = itm;
          }
        });

        // initial time
        // then check the change item date is ahead or before of the next item's date
        const nextItemDate = this.getItemStartDate(this.criteriaItemList[1]);
        let currentItemDate;

        if (changeItem.productCode == 'HTL') {
          currentItemDate = changeItem.value.split('~')[2];
        } else if (changeItem.productCode == 'TRS' || changeItem.productCode == 'CAR') {
          currentItemDate = changeItem.value.split('~')[0];
        } else {
          currentItemDate = changeItem.value;
        }

        if (currentItemDate && nextItemDate) {
          const jsCurrentItemDate = new Date(currentItemDate);
          const jsNextItemDate = new Date(nextItemDate);

          if (jsCurrentItemDate <= jsNextItemDate ) {
            // do not change the post items, only change the current item
            this.updateExistingItem(ciObj.criItem, ciObj.productCode, changeItem.value, changeItem.changeType);
          } else {
            // shift post items to match with current item date
            this.updateExistingItem(ciObj.criItem, ciObj.productCode, changeItem.value, changeItem.changeType);

            // shift
            /*Step 3 - adjust post items to the changed item according to new values*/
            this.updatePostItems(changeItem.productCode, changeItem.index, changeItem.value, changeItem.changeType);

            // TODO - if hotels are there in the post items list...need to adjust later items

            this.criteriaItemList.forEach(ci => {
              // if((ci.productCode == 'HTL' || (ci.productCode == 'FLT' && !ci.isGrabbedLeg) || ci.productCode == 'TRS') && !(this.isAmendmentFlow && !this.unfreezeItemMap.get(ci.index))){
              if ((ci.productCode == 'HTL' || (ci.productCode == 'FLT' && !ci.isGrabbedLeg) || ci.productCode == 'TRS') && !this.isAmendmentFlow) {
                const preItem = this.criteriaItemList[ci.index - 1];
                let preItemToDate;
                let preItemDisableDate;

                if (preItem && preItem.productCode == 'HTL' && preItem.criItem && preItem.criItem.startDate && preItem.criItem.nights) {
                  preItemToDate = new Date(preItem.criItem.startDate);
                  preItemDisableDate = new Date(preItem.criItem.startDate);
                  preItemToDate.setDate(preItemToDate.getDate() + parseInt(preItem.criItem.nights));
                }
                if (preItem && preItem.productCode == 'FLT' && preItem.criItem && preItem.criItem.legs && preItem.criItem.legs[0] && preItem.criItem.legs[0].departureDate ) {
                  preItemToDate = new Date(preItem.criItem.legs[0].departureDate);
                  preItemDisableDate = new Date(preItem.criItem.legs[0].departureDate);
                }
                if (preItem && preItem.productCode == 'TRS' && preItem.criItem && preItem.criItem.onewayPickupTime) {
                  preItemToDate = new Date(preItem.criItem.onewayPickupTime);
                  preItemDisableDate = new Date(preItem.criItem.onewayPickupTime);
                }
                if (preItem && preItem.productCode === ProductType.GEN && preItem.disableStartDate) {
                  preItemDisableDate = new Date(preItem.disableStartDate);
                }

                if (preItemToDate && !isNaN(preItemToDate.getTime())) {

                  if (ci.productCode == 'HTL' && ci.criItem) {
                    ci.criItem.startDate = this.surfCriteriaUtil.convertDateObjToStr(preItemToDate, true);
                    if (ci.criItem.nights == undefined) {
                      ci.criItem.nights = 1;
                    }
                    ci.disableStartDate = preItemDisableDate;
                    ci.initialDateStr = ci.criItem.startDate + '~' + ci.criItem.nights;
                  }
                  if (ci.productCode == 'FLT' && ci.criItem && ci.criItem.legs && ci.criItem.legs[0]) {
                    ci.criItem.legs[0].departureDate  = this.surfCriteriaUtil.convertDateObjToStr(preItemToDate, true);
                    ci.disableStartDate = preItemDisableDate;
                    ci.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(preItemToDate, true);
                  }
                  if (ci.productCode == 'TRS' && ci.criItem) {
                    ci.criItem.onewayPickupTime  = this.surfCriteriaUtil.convertDateObjToStr(preItemToDate);
                    ci.disableStartDate = preItemDisableDate;
                    ci.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(preItemToDate);
                  }
                  if (ci.productCode === ProductType.GEN) {
                    ci.disableStartDate = preItemDisableDate;
                    ci.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(preItemToDate);
                  }
                }
              }
            });

          }
        } else {
          /*Step 1 - update gaps*/
          this.updateGapWithChangeItem(changeItem.index, changeItem.value, changeItem.productCode);

          /*Step 2 - update the changed item info*/
          this.criteriaItemList.forEach(itm => {
            if (itm.index == changeItem.index && itm.productCode == changeItem.productCode) {
              // item found
              if (itm.criItem == null || itm.criItem == undefined) {
                itm.criItem = this.createNewItem(itm.productCode);
                itm.criItem.itemIndex = itm.index;
              }
              this.updateExistingItem(itm.criItem, itm.productCode, changeItem.value, changeItem.changeType);
            }
          });

          /*Step 3 - adjust post items to the changed item according to new values*/
          this.updatePostItems(changeItem.productCode, changeItem.index, changeItem.value, changeItem.changeType);
        }
        this.addToTop = false;
      } else {
        /*Step 1 - update gaps*/
        this.updateGapWithChangeItem(changeItem.index, changeItem.value, changeItem.productCode);

        /*Step 2 - update the changed item info*/
        this.criteriaItemList.forEach(itm => {
          if (itm.index == changeItem.index && itm.productCode == changeItem.productCode) {
            // item found
            if (itm.criItem == null || itm.criItem == undefined) {
              itm.criItem = this.createNewItem(itm.productCode);
              itm.criItem.itemIndex = itm.index;
            }
            if (itm.productCode === 'OWA') {
              itm.initialDateStr = changeItem.value.toString().split('~')[0];
            }
            this.updateExistingItem(itm.criItem, itm.productCode, changeItem.value, changeItem.changeType);
            this.setValid(true, itm);
          }
        });

        /*Step 3 - adjust post items to the changed item according to new values*/
        this.updatePostItems(changeItem.productCode, changeItem.index, changeItem.value, changeItem.changeType);
      }
    }
  }

  createNewItem(productCode): any {
    switch (productCode) {
      case 'TOU':
        this.setHasTourItem(true, true);
        return new TourCriteria();
      case 'HTL':
        return new HotelCriteria();
      case 'FLT':
        const fc = new FlightCriteria();
        fc.legs = [];
        fc.legs.push(this.DpCriteriaManageFlights.createEmptyFlightItemObject());
        this.setHasFlightItem(true, true);
        return fc;
      case 'TRS':
        return new TransferCriteria();
      case 'CAR':
        const cc = new CarCriteria();
        cc.driverAge = -1;
        return cc;
      case 'GEN':
        return new GenericCriteria();
      case 'OWA':
        return {
          name : '',
          description: '',
          quoteBookingType: 'BOOKING',
          departureDate: '',
          duration: 0
        };
    }
  }

  updateExistingItem(item, productCode, value, type) {
    if (value) {
      if (type == 'DEST') {
        switch (productCode) {
          case 'HTL':
            item.destinationType = value.split('~')[1];
            item.destinationCode = value.split('~')[2];
            item.destinationName = value.split('~')[3];
            item.destinationParentCode = (value.split('~')[4] && value.split('~')[4] !== 'null') ? value.split('~')[4] : '';
            if (value.split('~')[5] && value.split('~')[5] != '') {
              item.hotelCode = value.split('~')[5];
            } else if (this.isAmendmentFlow) {
              // this was required due to fix done for FC-35895. If this is not added, then if newly added item reads from
              // previous item, it will set the hotel code as the destination code
              item.hotelCode = null;
            }
            // set city of hotel
            if (value.split('~')[6] && value.split('~')[6] != '') {
              item.city = value.split('~')[6];
            }
            // set country of hotel
            if (value.split('~')[7] && value.split('~')[7] != '') {
              item.country = value.split('~')[7];
            }
            break;
          case 'FLT':
            item.legs[0].departureAirportCode = value.split('|')[0].split('~')[0];
            item.legs[0].departureAirportCity = value.split('|')[0].split('~')[1];
            item.legs[0].departureAirportCityName = value.split('|')[0].split('~')[2];
            item.legs[0].departureAirportCountryName = value.split('|')[0].split('~')[3];
            item.legs[0].arrivalAirportCode = value.split('|')[1].split('~')[0];
            item.legs[0].arrivalAirportCity = value.split('|')[1].split('~')[1];
            item.legs[0].arrivalAirportCityName = value.split('|')[1].split('~')[2];
            item.legs[0].arrivalAirportCountryName = value.split('|')[1].split('~')[3];
            break;
          case 'TRS':
            // item.pickupCity = value.split('#')[0].split('~')[0]; TODO: commented this since trs dp criteria already is updated.
            // // item.pickupLocationName = item.pickupLocation;
            // item.pickupLocation = value.split('#')[0].split('~')[1];
            // item.dropOffCity = value.split('#')[0].split('~')[2];
            // item.returnLocation = value.split('#')[0].split('~')[3];
            // item.destinationType = value.split('#')[1].split('~')[1];
            // item.dropOffLocationName = value.split('#')[1].split('~')[3];
            // item.dropOffCountryName = value.split('#')[1].split('~')[4];
            break;
          case 'CAR':
            if ( value.split('~')[0] !== item.pickupCity) {
              item.pickupCity = value.split('~')[0];
              item.pickupLocationName = value.split('~')[4];
            }
            item.pickupLocation = value.split('~')[1];
            if (item.returnCity !== value.split('~')[2]) {
              item.returnCity = value.split('~')[2];
              item.returnLocationName = value.split('~')[4];
            }
            item.returnLocation = value.split('~')[3];
            break;
          case 'GEN':
            // item.destinationType = value.split('~')[1]; TODO: commented this since trs dp criteria already is updated.
            // item.destinationCode = value.split('~')[2];
            // item.destinationName = value.split('~')[3];
            // item.destinationParentCode = value.split('~')[4];
            break;
        }

      } else if (type == 'DATE') {
        switch (productCode) {
          case 'HTL':
            item.startDate = value.split('~')[0];
            item.nights = value.split('~')[1];
            break;
          case 'FLT':
            item.legs[0].departureDate = value;
            break;
          case 'TRS':
            item.onewayPickupTime = value.split('~')[0];
            item.onewayDropoffTime = this.getStartTimeOfTheDay(item.onewayPickupTime);
            break;
          case 'CAR':
            item.pickupDateTime = value.split('~')[0];
            item.returnDateTime = value.split('~')[1];
            break;
          case 'GEN':
            item.startDate = value.split('~')[0];
            break;
        }
      } else if (type === 'CABINCLASS') {
        // Cabin Class Change of flight-criteria
        item.legs[0].cabinClass = value.split('~')[0];
      } else if (type === 'AIRLINE') {
        // Airline Change of flight-criteria
        const changeItem = value as AirlineChangeItem;
        item.legs[0].isAirlineSearchInitiated = changeItem.isAirlineSearchInitiated;
        if (item.legs[0].airlineOntology) {
          item.legs[0].airlineOntology.displayText = changeItem.ontologyDisplayText;
        }
        item.legs[0].airlineOntologies = changeItem.airlineOntologies;
        item.legs[0].airlines = changeItem.airlines;
      } else if (type === 'VIAPOINT') {
        // Via Point Change of flight-criteria
        const changeItem = value as ViaPointChangeItem;
        item.legs[0].isViaPointSearchInitiated = changeItem.isViaPointSearchInitiated;
        item.legs[0].viaPointOntology.displayText = changeItem.ontologyDisplayText;
        item.legs[0].viaPointOntologies = changeItem.viaPointOntologies;
        item.legs[0].viaPoints = changeItem.viaPoints;
      } else if (type == 'DRIVERAGE') {
        item.driverAge = value;
      } else if (type == 'ELEMENTGROUP') {
        item.elementGroup = value.split('~')[0];
      } else if (type == 'DURATION') {
        item.duration = value;
      }
    }
  }

  updatePostItems(changeItemType, changeItemIndex, changeItemValue, changeType) {
    let changeItem;
    let dependentItems = [];
    let nextFltIndex = -1;
    this.criteriaItemList.forEach(itm => {
      if (itm.index == changeItemIndex) {
        changeItem = itm;
      }
      if (this.isGrabFlow && nextFltIndex === -1 && itm.index > changeItemIndex && itm.productCode === Product.FLIGHT && changeType === 'DEST') {
        nextFltIndex = itm.index;
      }
    });
    if (nextFltIndex === -1) {
      nextFltIndex = this.criteriaItemList[this.criteriaItemList.length - 1].index + 1;
    }

    this.criteriaItemList.forEach(itm => {
      if (itm.index > changeItemIndex && !itm.isGrabbedLeg && itm.index < nextFltIndex &&
        !(this.isAmendmentFlow && !this.unfreezeItemMap.get(itm.index)) &&
        !(this.isAmendmentFlow && changeItemIndex === 0 && changeItemType === Product.FLIGHT) &&
        !(itm.productCode === Product.ACCOMMODATION && this.GEO_ENABLE_FOR_HOTEL_SEARCH && changeType === 'DEST')) {

        if (itm.criItem == null || itm.criItem == undefined) {
          itm.criItem = this.createNewItem(itm.productCode);
          itm.criItem.itemIndex = itm.index;
        }

        const format = 'YYYY-MM-DDTHH:mm:ss';

        if (changeItemType === 'HTL') {
          const destinationType = changeItem?.criItem?.destinationType;
          if (changeType === 'DEST' && !(destinationType && destinationType === DestinationType.GEO_LOCATION)) {
            switch (itm.productCode) {
              case 'HTL':
                /*HTL -> HTL : should city change?? -- No, this only happens when adding a new post item according to RES manager*/
                // below changes done due to FCTGJIR-2809 sandpit issue
                const hasUndefinedValues = Object.keys(itm.criItem).some(k => (!itm.criItem[k] || itm.criItem[k] === 'undefined'));
                if ((changeItemValue && (changeItemValue.split('~').length >= 5))) {
                  if (hasUndefinedValues) {
                    itm.criItem.destinationType = DestinationType.HOTEL;
                    itm.criItem.destinationCode = changeItemValue.split('~')[2];
                    itm.criItem.destinationName = changeItemValue.split('~')[3];
                    itm.criItem.destinationParentCode = changeItemValue.split('~')[4];
                    if (changeItemValue.split('~')[5] && changeItemValue.split('~')[5] != '') {
                      itm.criItem.hotelCode = changeItemValue.split('~')[5];
                    }
                    itm.initialDestStr = changeItemValue;
                  }
                }
                break;
              case 'FLT':
                /*HTL -> FLT : should dep airport change??  -- NO, cannot get the Airport from the City coz there are many airports connected to a city*/
                break;
              case 'TRS':
                /*HTL -> TRS : should city change??   -- YES according to RES manager*/
                itm.criItem.pickupCity = changeItemValue.split('~')[2];
                itm.criItem.pickupLocation = changeItemValue.split('~')[2];
                itm.criItem.pickupLocationCode = changeItemValue.split('~')[5];
                itm.initialDestStr = changeItemValue.split('~')[3];
                itm.criItem.pickupLocationName = changeItemValue.split('~')[3];
                if (itm.criItem.dropOffCity === undefined || itm.criItem.dropOffCity === 'undefined') {
                  itm.criItem.dropOffCity = changeItemValue.split('~')[2];
                  itm.criItem.returnLocation = changeItemValue.split('~')[2];
                  if (changeItemType === Product.ACCOMMODATION && changeItemValue.split('~')[1] && changeItemValue.split('~')[1] === 'HOTEL') {
                    itm.criItem.destinationType = changeItemValue.split('~')[1];
                    itm.criItem.dropOffLocationName = changeItemValue.split('~')[4];
                    itm.criItem.dropoffLocationCode = null;
                  } else {
                    itm.criItem.dropOffLocationName = changeItemValue.split('~')[3];
                    itm.criItem.dropoffLocationCode = changeItemValue.split('~')[5];
                  }
                }
                break;
              case 'CAR':
                /*HTL -> CAR : should city change??         : Only if it's inside HTL's date period*/
                // check if the hotel and this particular CAR has its dates defined,
                // And check the CAR's pickup date is inside the hote's dates if yes, then change this CAR's destination to hotel's destination
                if (changeItem.criItem.startDate && changeItem.criItem.nights && itm.criItem.pickupDateTime) {
                  const hToDate = new Date(changeItem.criItem.startDate);
                  hToDate.setDate(hToDate.getDate() + parseInt(changeItem.criItem.nights));
                  if (this.isInsideDateRange(new Date(itm.criItem.pickupDateTime), new Date(changeItem.criItem.startDate), hToDate)) {

                    if (!(itm.criItem.pickupCity == undefined || itm.criItem.pickupCity == 'undefined')) {
                      dependentItems.push(itm);
                    } else {
                      // commenting below lines since for cars, only car locations can be selected in FC context. Uncomment this if cities can be selected for cars
                      // itm.criItem.pickupCity = changeItemValue.split('~')[2];
                      // itm.criItem.pickupLocation = changeItemValue.split('~')[2];
                      // itm.criItem.returnCity = changeItemValue.split('~')[2];
                      // itm.criItem.returnLocation = changeItemValue.split('~')[2];
                      // itm.initialDestStr = changeItemValue.split('~')[3];
                    }
                  }
                } else if (itm.criItem.pickupDateTime == undefined) {
                  // auto populate destination if its the first time
                  if (itm.criItem.pickupCity == undefined || itm.criItem.pickupCity == 'undefined') {
                    // commenting below lines since for cars, only car locations can be selected in FC context. Uncomment this if cities can be selected for cars
                    // itm.criItem.pickupCity = changeItemValue.split('~')[2];
                    // itm.criItem.pickupLocation = changeItemValue.split('~')[2];
                    // itm.criItem.returnCity = changeItemValue.split('~')[2];
                    // itm.criItem.returnLocation = changeItemValue.split('~')[2];
                    // itm.initialDestStr = changeItemValue.split('~')[3];
                  }
                }
                break;
              case 'GEN':
                /*HTL -> GEN : should city change??         : Only if it's inside HTL's date period  -- do the same with CAR*/
                if (changeItem.criItem.startDate && changeItem.criItem.nights && itm.criItem.startDate) {
                  const hToDate = new Date(changeItem.criItem.startDate);
                  hToDate.setDate(hToDate.getDate() + parseInt(changeItem.criItem.nights));
                  if (this.isInsideDateRange(new Date(itm.criItem.startDate), new Date(changeItem.criItem.startDate), hToDate)) {

                    if (!(itm.criItem.destinationCode == undefined || itm.criItem.destinationCode == 'undefined')) {
                      dependentItems.push(itm);
                    } else {
                      itm.criItem.destinationType = changeItemValue.split('~')[1];
                      itm.criItem.destinationCode = changeItemValue.split('~')[2];
                      itm.criItem.destinationName = changeItemValue.split('~')[3];
                      itm.criItem.destinationParentCode = changeItemValue.split('~')[4];
                      if (itm.criItem.destinationType === 'HOTEL') {
                        itm.initialDestStr = changeItemValue.split('~')[4];
                      } else {
                        itm.initialDestStr = changeItemValue.split('~')[0];
                      }
                    }
                  }
                } else if (itm.criItem.startDate == undefined) {
                  if (itm.criItem.destinationCode == undefined || itm.criItem.destinationCode == 'undefined') {
                    itm.criItem.destinationType = changeItemValue.split('~')[1];
                    itm.criItem.destinationCode = changeItemValue.split('~')[2];
                    itm.criItem.destinationName = changeItemValue.split('~')[3];
                    itm.criItem.destinationParentCode = changeItemValue.split('~')[4];
                    if (itm.criItem.destinationType === 'HOTEL') {
                      itm.initialDestStr = changeItemValue.split('~')[4];
                    } else {
                      itm.initialDestStr = changeItemValue.split('~')[0];
                    }
                  }
                }
                if ( changeItem.criItem.country && (itm.criItem.country == undefined || itm.criItem.country == 'undefined') ) {
                  itm.criItem.country = changeItem.criItem.country;
                }
                break;
            }
          } else if (changeType == 'DATE') {
            const newStartDate = new Date(changeItemValue.split('~')[0]);  // get the changed hotel fromDate
            switch (itm.productCode) {
              case 'HTL':
                /*HTL -> HTL : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                this.updateHotelDates(itm, newStartDate, changeItemValue, 'HTL');
                break;
              case 'FLT':
                /*HTL -> FTL : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                this.updateFlightDates(itm, newStartDate, changeItemValue, 'HTL');
                break;
              case 'TRS':
                /*HTL -> TRS : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                this.updateTransferDates(itm, newStartDate, changeItemValue, 'HTL');
                break;
              case 'CAR':
                /*HTL -> CAR : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.pickupDateTime == undefined || itm.criItem.pickupDateTime == 'undefined') {
                  this.updateCarDates(itm, newStartDate, changeItemValue, 'HTL');
                }
                break;
              case 'GEN':
                /*HTL -> GEN : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.startDate == undefined || itm.criItem.startDate == 'undefined') {
                  this.updateGenericDates(itm, newStartDate, changeItemValue, 'HTL');
                }
                break;
              case 'OWA':
                /*HTL -> GEN : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.startDate == undefined || itm.criItem.startDate == 'undefined') {
                  this.updateOwnArrangementDates(itm, newStartDate, changeItemValue, 'HTL');
                }
                break;
            }
          }
        } else if (changeItemType == 'FLT') {
          if (changeType == 'DEST') {
            switch (itm.productCode) {
              case 'HTL':
                /*FLT -> HTL : should city change??  yes hotel's city should be updated as the nearest City to the pre flight arrival airport*/
                if (changeItemValue.split('|')[1] && changeItemValue.split('|')[1].split('~')[1] && changeItemValue.split('|')[1].split('~')[2] &&
                  changeItemValue.split('|')[1].split('~')[3] && !this.isAmendmentFlow) {
                  itm.criItem.destinationType = DestinationType.CITY;
                  itm.criItem.destinationCode = changeItemValue.split('|')[1].split('~')[1];
                  itm.criItem.destinationName = changeItemValue.split('|')[1].split('~')[2];
                  itm.criItem.destinationParentCode = changeItemValue.split('|')[1].split('~')[3];
                  const displayText = changeItemValue.split('|')[1].split('~')[2] + ', ' + changeItemValue.split('|')[1].split('~')[3];
                  itm.initialDestStr = displayText + '~CITY~' + changeItemValue.split('|')[1].split('~')[1] + '~' + changeItemValue.split('|')[1].split('~')[2]
                  + '~' + changeItemValue.split('|')[1].split('~')[3];
                }
                break;
              case 'FLT':
                /*FLT -> FLT : should dep airport change??   : if dep airport changes, arr airport should be changed and if arr airport changes, dep airport should be changed*/
                const airportDepartureData = changeItemValue.split('|')[0];
                const airportArrivalData = changeItemValue.split('|')[1];

                if (!(airportArrivalData == undefined || airportArrivalData.split('~')[0] == 'undefined' || airportArrivalData.split('~')[1] == 'undefined' ||
                  airportArrivalData.split('~')[2] == 'undefined' || airportArrivalData.split('~')[3] == 'undefined')) {
                  itm.criItem.legs[0].fromOntology.displayText = (airportArrivalData.split('~')[2] !== '' && airportArrivalData.split('~')[0] !== '') ? (airportArrivalData.split('~')[2] + ' (' + airportArrivalData.split('~')[0] + ')') : '';
                  itm.criItem.legs[0].fromOntology.query = airportArrivalData.split('~')[1];
                  itm.criItem.legs[0].departureAirportCode = airportArrivalData.split('~')[0];
                  itm.criItem.legs[0].departureAirportCity = airportArrivalData.split('~')[1];
                  itm.criItem.legs[0].departureAirportCityName = airportArrivalData.split('~')[2];
                  itm.criItem.legs[0].departureAirportCountryName = airportArrivalData.split('~')[3];
                  itm.criItem.legs[0].depObj = changeItem.criItem.legs[0].arrObj;
                }

                if (!(airportDepartureData == undefined || airportDepartureData.split('~')[0] == 'undefined' || airportDepartureData.split('~')[1] == 'undefined' ||
                  airportDepartureData.split('~')[2] == 'undefined' || airportDepartureData.split('~')[3] == 'undefined')) {
                  itm.criItem.legs[0].toOntology.displayText = airportDepartureData.split('~')[2] + ' (' + airportDepartureData.split('~')[0] + ')';
                  itm.criItem.legs[0].toOntology.query = airportDepartureData.split('~')[1];
                  itm.criItem.legs[0].arrivalAirportCode = airportDepartureData.split('~')[0];
                  itm.criItem.legs[0].arrivalAirportCity = airportDepartureData.split('~')[1];
                  itm.criItem.legs[0].arrivalAirportCityName = airportDepartureData.split('~')[2];
                  itm.criItem.legs[0].arrivalAirportCountryName = airportDepartureData.split('~')[3];
                  itm.criItem.legs[0].arrObj = changeItem.criItem.legs[0].depObj;
                }
                itm.initialDestStr = null;
                itm.initialDestStr = changeItemValue;
                break;
              case 'TRS':
                /*FLT -> TRS : should city change?? YES according to RES manager*/
                if (changeItemValue.split('|')[1] && changeItemValue.split('|')[1].split('~')[1] && changeItemValue.split('|')[1].split('~')[2]) {
                  itm.criItem.pickupCity = changeItemValue.split('|')[1].split('~')[1];
                  itm.criItem.pickupLocation = changeItemValue.split('|')[1].split('~')[1];
                  itm.criItem.pickupLocationCode = ''; // changeItemValue.split('|')[1].split('~')[1];
                  itm.criItem.dropOffCity = changeItemValue.split('|')[1].split('~')[1];
                  itm.criItem.returnLocation = changeItemValue.split('|')[1].split('~')[1];
                  itm.initialDestStr = changeItemValue.split('|')[1].split('~')[2];
                }
                break;
              case 'CAR':
                /*FLT -> CAR : should city change??  - set for the initial time only*/
                if (itm.criItem.pickupCity == undefined || itm.criItem.pickupCity == 'undefined') {
                  if (changeItemValue.split('|')[1] && changeItemValue.split('|')[1].split('~')[1] && changeItemValue.split('|')[1].split('~')[2]) {
                    itm.criItem.pickupCity = changeItemValue.split('|')[1].split('~')[1];
                    itm.criItem.pickupLocation = changeItemValue.split('|')[1].split('~')[1];
                    itm.criItem.returnCity = changeItemValue.split('|')[1].split('~')[1];
                    itm.criItem.returnLocation = changeItemValue.split('|')[1].split('~')[1];
                    itm.initialDestStr = changeItemValue.split('|')[1].split('~')[2];
                  }
                }
                break;
              case 'GEN':
                /*FLT -> GEN : should city change?? - set for the initial time only*/
                if (itm.criItem.destinationCode == undefined || itm.criItem.destinationCode == 'undefined') {
                  if (changeItemValue.split('|')[1] && changeItemValue.split('|')[1].split('~')[1] && changeItemValue.split('|')[1].split('~')[2] &&
                    changeItemValue.split('|')[1].split('~')[3]) {
                    itm.criItem.destinationType = DestinationType.CITY;
                    itm.criItem.destinationCode = changeItemValue.split('|')[1].split('~')[1];
                    itm.criItem.destinationName = changeItemValue.split('|')[1].split('~')[2];
                    itm.criItem.destinationParentCode = changeItemValue.split('|')[1].split('~')[3];
                    itm.initialDestStr = changeItemValue.split('|')[1].split('~')[2] + ', ' + changeItemValue.split('|')[1].split('~')[3];
                  }
                }
                break;
            }
          } else if (changeType == 'DATE') {
            const newStartDate = new Date(changeItemValue);  // get the changed flight departureDate
            switch (itm.productCode) {
              case 'HTL':
                /*FLT -> HTL : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                this.updateHotelDates(itm, newStartDate, changeItemValue, 'FLT');
                break;
              case 'FLT':
                /*FLT -> FTL : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                this.updateFlightDates(itm, newStartDate, changeItemValue, 'FLT');
                break;
              case 'TRS':
                /*FLT -> TRS : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                this.updateTransferDates(itm, newStartDate, changeItemValue, 'FLT');
                break;
              case 'CAR':
                /*FLT -> CAR : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.pickupDateTime == undefined || itm.criItem.pickupDateTime == 'undefined') {
                  this.updateCarDates(itm, newStartDate, changeItemValue, 'FLT');
                }
                break;
              case 'GEN':
                /*FLT -> GEN : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.startDate == undefined || itm.criItem.startDate == 'undefined') {
                  this.updateGenericDates(itm, newStartDate, changeItemValue, 'FLT');
                }
                break;
              case 'OWA':
                /*HTL -> GEN : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.startDate == undefined || itm.criItem.startDate == 'undefined') {
                  this.updateOwnArrangementDates(itm, newStartDate, changeItemValue, 'HTL');
                }
                break;
            }
          }
        } else if (changeItemType == 'TRS') {
          if (changeType == 'DATE') {
            const newPickUpDate = new Date((changeItemValue as string).split('~')[0]);  // get the changed transfer dates

            switch (itm.productCode) {
              case 'HTL':
                /*TRS -> HTL : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                let effectedHotelInAmend;
                if(this.isAmendmentFlow) {
                  effectedHotelInAmend = this.criteriaItemList.find( itemTemp => itemTemp.index > changeItemIndex
                  && itemTemp.productCode === Product.ACCOMMODATION
                  && this.criteriaItemList[changeItemIndex].criItem.dropoffLocationType === 'HOTEL' )
                }

                if ( (this.isAmendmentFlow ? (effectedHotelInAmend && effectedHotelInAmend.index === itm.index): true)  && newPickUpDate && !isNaN(newPickUpDate.getTime())) {
                  this.updateHotelDates(itm, newPickUpDate, changeItemValue, 'TRS');
                }
                break;
              case 'FLT':
                /*TRS -> FTL : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                if (newPickUpDate && !isNaN(newPickUpDate.getTime())) {
                  this.updateFlightDates(itm, newPickUpDate, changeItemValue, 'TRS');
                }
                break;
              case 'TRS':
                /*TRS -> TRS : should dates change??        : yes dates should be shitfed forward or backward maintaining the gap*/
                if (newPickUpDate && !isNaN(newPickUpDate.getTime())) {
                  this.updateTransferDates(itm, newPickUpDate, changeItemValue, 'TRS');
                }
                break;
              case 'CAR':
                /*TRS -> CAR : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.pickupDateTime == undefined || itm.criItem.pickupDateTime == 'undefined') {
                  if (newPickUpDate && !isNaN(newPickUpDate.getTime())) {
                    this.updateCarDates(itm, newPickUpDate, changeItemValue, 'TRS');
                  }
                }
                break;
              case 'GEN':
                /*TRS -> GEN : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.startDate == undefined || itm.criItem.startDate == 'undefined') {
                  if (newPickUpDate && !isNaN(newPickUpDate.getTime())) {
                    this.updateGenericDates(itm, newPickUpDate, changeItemValue, 'TRS');
                  }
                }
                break;
              case 'OWA':
                /*HTL -> GEN : should dates change?? -Dont think so because its independent of other items - but only change if its initial time*/
                if (itm.criItem.startDate == undefined || itm.criItem.startDate == 'undefined') {
                  this.updateOwnArrangementDates(itm, newPickUpDate, changeItemValue, 'HTL');
                }
                break;
            }
          }
        } else if (changeItemType == 'CAR') {
          /*independent - do not affect other items- only auto populate dates and dest for the initial time*/
          if (changeType == 'DEST' && itm.productCode == 'CAR') {
            if (itm.criItem.pickupCity == undefined || itm.criItem.pickupCity == 'undefined') {
              itm.criItem.pickupCity = changeItemValue.split('~')[0];
              itm.criItem.pickupLocation = changeItemValue.split('~')[1];
              itm.criItem.returnCity = changeItemValue.split('~')[2];
              itm.criItem.returnLocation = changeItemValue.split('~')[3];
              itm.initialDestStr = changeItemValue.split('~')[4];
            }
          } else if (changeType == 'DATE' && itm.productCode == 'CAR') {
            if (itm.criItem.pickupDateTime == undefined || itm.criItem.pickupDateTime == 'undefined') {
              const newCarPickUpDate = new Date(changeItemValue.split('~')[0]);
              const newCarDropOffDate = new Date(changeItemValue.split('~')[0]);
              this.updateCarDates(itm, newCarPickUpDate, changeItemValue, 'CAR', newCarDropOffDate);
            }
          }
        } else if (changeItemType == 'GEN') {
          /*independent - do not affect other items - only auto populate dates and dest for the initial time*/
          if (changeType == 'DEST' && itm.productCode == 'GEN') {
            if (itm.criItem.destinationCode == undefined || itm.criItem.destinationCode == 'undefined') {
              itm.criItem.destinationType = changeItemValue.split('~')[1];
              itm.criItem.destinationCode = changeItemValue.split('~')[2];
              itm.criItem.destinationName = changeItemValue.split('~')[3];
              itm.criItem.destinationParentCode = changeItemValue.split('~')[4];
              itm.initialDestStr = changeItemValue.split('~')[0];
            }
          } else if (changeType == 'DATE' && itm.productCode == 'GEN') {
            const newStartDate = new Date(changeItemValue);  // get the changed hotel fromDate
            if (itm.criItem.startDate == undefined || itm.criItem.startDate == 'undefined') {
              this.updateGenericDates(itm, newStartDate, changeItemValue, 'GEN');
            } else {
              itm.criItem.startDate = this.surfCriteriaUtil.convertDateObjToStr(newStartDate, true);
              itm.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(newStartDate, true);
            }
          }
        }
      }
    });

    if (changeItemType == 'TRS') {
      if (changeType == 'DEST') {
        /*When TRS destination changes, only the next hotel which is adjacent to the Transfer will be affected*/
        // check if the next item is hotel
        if (!this.isAmendmentFlow) {
          if (this.criteriaItemList[changeItemIndex + 1] &&
            this.criteriaItemList[changeItemIndex + 1].productCode == 'HTL' &&
            this.criteriaItemList[changeItemIndex + 1].criItem) {

            if (changeItemValue &&
              changeItemValue.split('#')[2] === 'DROP' &&
              changeItemValue.split('#')[1] &&
              changeItemValue.split('#')[1].split('~')[1] &&
              changeItemValue.split('#')[1].split('~')[2] &&
              changeItemValue.split('#')[1].split('~')[3] &&
              changeItemValue.split('#')[1].split('~')[4]) {

              let destType = changeItemValue.split('#')[1].split('~')[1];
              // getting destType in location id format so have to convert it to hotel criteria attribute format
              if (destType == '40') {
                destType = 'CITY';
              } else if (destType == '20') {
                destType = 'COUNTRY';
              }
              this.criteriaItemList[changeItemIndex + 1].criItem.destinationType = destType;
              this.criteriaItemList[changeItemIndex + 1].criItem.destinationCode = changeItemValue.split('#')[1].split('~')[2];

              if (this.criteriaItemList[changeItemIndex + 1].criItem.destinationType == 80) {
                this.criteriaItemList[changeItemIndex + 1].criItem.hotelCode = changeItemValue.split('#')[1].split('~')[5];
              }

              this.criteriaItemList[changeItemIndex + 1].criItem.destinationCode = changeItemValue.split('#')[1].split('~')[2];  // assign transfer dropOff city
              this.criteriaItemList[changeItemIndex + 1].criItem.destinationName = changeItemValue.split('#')[1].split('~')[3];  // assign transfer dropOff cityname
              this.criteriaItemList[changeItemIndex + 1].criItem.destinationParentCode = changeItemValue.split('#')[1].split('~')[4];  // assign transfer dropOff city parent
              this.criteriaItemList[changeItemIndex + 1].initialDestStr = changeItemValue.split('#')[1];
            }
          }
        }
      }
    }

    /*
    * Pop the warning message if;
    * 1. Hotel dest changes and there are car, generic items and
    * 2. New dest is not the same dest of generic item or car
    *
    * */
    const destinationType = changeItem?.criItem?.destinationType;
    if (this.isAmendmentFlow && changeType === 'DEST' && changeItemType === Product.ACCOMMODATION &&
      !(destinationType && destinationType === DestinationType.GEO_LOCATION)) {
      dependentItems = [];
      if (!this.recentlyChangedItem.onlyForButtonDisable) {
        this.criteriaItemList.forEach(itm => {
          if (itm.productCode === Product.CAR) {
            if (changeItem.criItem.startDate && changeItem.criItem.nights && itm.criItem.pickupDateTime) {
              const hToDate = new Date(changeItem.criItem.startDate);
              hToDate.setDate(hToDate.getDate() + parseInt(changeItem.criItem.nights));
              if (this.isInsideDateRange(new Date(itm.criItem.pickupDateTime), new Date(changeItem.criItem.startDate), hToDate)) {
                if (!(itm.criItem.pickupCity == undefined || itm.criItem.pickupCity == 'undefined')) {
                  dependentItems.push(itm);
                }
              }
            }
          }
          if (itm.productCode === Product.GENERIC) {
            if (changeItem.criItem.startDate && changeItem.criItem.nights && itm.criItem.startDate) {
              const hToDate = new Date(changeItem.criItem.startDate);
              hToDate.setDate(hToDate.getDate() + parseInt(changeItem.criItem.nights));
              if (this.isInsideDateRange(new Date(itm.criItem.startDate), new Date(changeItem.criItem.startDate), hToDate)) {
                if (!(itm.criItem.destinationCode == undefined || itm.criItem.destinationCode == 'undefined')) {
                  dependentItems.push(itm);
                }
              }
            }
          }
        });
      }
    }
    if (dependentItems.length > 0 && changeItemValue) {

      // Extracting destinationType and destinationCode from changeItemValue
      const newHoteldestinationType = changeItemValue.split('~')[1];
      const newHoteldestinationCode = changeItemValue.split('~')[2];

      // Checking CARs and GENs separately for their location equality
      dependentItems.forEach((item) => {
        if (
          item.productCode === 'CAR' &&
          !(item.criItem.pickupCity === newHoteldestinationCode)
        ) { this.onDependencyChange({items: dependentItems, changeParam: changeItemValue, changeItemIndex}); }

        if (
          item.productCode === 'GEN' &&
          !(
            item.criItem.destinationType === newHoteldestinationType &&
            item.criItem.destinationCode === newHoteldestinationCode
          )
        ) {
          this.onDependencyChange({items: dependentItems, changeParam: changeItemValue, changeItemIndex});
        }
      });
    }
    this.updateDisableDatesOwnArrangement();
  }

  /*Mini Functions*/
  updateHotelDates(itm, newStartDate, changeItemValue, pc) {
    let nights = 0;
    if (changeItemValue.split('~')[1] && pc != 'TRS') {
      nights = parseInt(changeItemValue.split('~')[1]);
    }
    newStartDate.setDate(newStartDate.getDate() + nights + itm.gapWithChangedItem); // add the new hotel nights and gap
    itm.criItem.startDate = this.surfCriteriaUtil.convertDateObjToStr(newStartDate, true);
    if (itm.criItem.nights == undefined) {  // set initial nights as 1 if undefined
      itm.criItem.nights = 1;
      // all the items below this hotel should shift 1 day forward
      this.updateGapsBelowHotel(itm.index);
    }
    itm.disableStartDate = changeItemValue.split('~')[0];
    itm.initialDateStr = itm.criItem.startDate + '~' + itm.criItem.nights;
  }
  /**
   * Setting up the authentication parameters from the permission framework
   */
  setAuthenticationParameters() {
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(dpCriteriaOperations);
    const tourFetchEnablePermissionInDp = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.DP_TOUR_FETCH_ENABLE);

    if (tourFetchEnablePermissionInDp && tourFetchEnablePermissionInDp.allowed) {
      this.tourFetchEnableInDp = true;
    } else {
      this.tourFetchEnableInDp = false;
    }
  }

  updateFlightDates(itm, newStartDate, changeItemValue, pc) {
    let nights = 0;
    if (changeItemValue.split('~')[1] && pc != 'TRS') {
      nights = parseInt(changeItemValue.split('~')[1]);
    }
    newStartDate.setDate(newStartDate.getDate() + nights + itm.gapWithChangedItem); // add the new hotel nights(if any) and gap
    itm.criItem.legs[0].departureDate = this.surfCriteriaUtil.convertDateObjToStr(newStartDate, true);
    itm.disableStartDate = changeItemValue.split('~')[0];
    itm.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(newStartDate, true);
  }

  updateTransferDates(itm, newStartDate, changeItemValue, pc) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    let nights = 0;
    if (changeItemValue.split('~')[1] && pc != 'TRS') {
      nights = parseInt(changeItemValue.split('~')[1]);
      nights = isNaN(nights) ? 0 : nights;
    }
    newStartDate.setDate(newStartDate.getDate() + nights + itm.gapWithChangedItem); // add the new hotel nights and gap

    const trsPickUpDate = new Date(newStartDate.getTime());
    const trsDropOffDate = new Date(newStartDate.getTime());

    if (itm.criItem.onewayPickupTime === undefined) {
      trsPickUpDate.setHours(0);
      trsPickUpDate.setMinutes(0);
      trsDropOffDate.setHours(0);
      trsDropOffDate.setMinutes(0);
    } else {
      const d1  = new Date(itm.criItem.onewayPickupTime);
      const d2  = new Date(itm.criItem.onewayDropoffTime);
      trsPickUpDate.setHours(d1.getHours());
      trsPickUpDate.setMinutes(d1.getMinutes());
      if (d2) {
        trsDropOffDate.setHours(d2.getHours());
        trsDropOffDate.setMinutes(d2.getMinutes());
      }
    }
    itm.criItem.onewayPickupTime = moment(trsPickUpDate).format(format);
    itm.criItem.onewayDropoffTime = moment(trsDropOffDate).format(format);
    itm.disableStartDate = changeItemValue.split('~')[0];
    itm.initialDateStr = itm.criItem.onewayPickupTime;
  }

  updateCarDates(itm, newStartDate, changeItemValue, pc, dropOffDate?) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    let nights = 0;
    if (changeItemValue.split('~')[1] && pc != 'TRS') {
      nights = parseInt(changeItemValue.split('~')[1]);
    }
    if (isNaN(nights)) {
      nights = 0;
    }
    newStartDate.setDate(newStartDate.getDate() + nights + itm.gapWithChangedItem); // add the new hotel nights and gap

    const carPickUpDate = new Date(newStartDate.getTime());
    const carDropOffDate = new Date(newStartDate.getTime());
    if (pc != 'CAR') {
      carPickUpDate.setHours(12);
      carPickUpDate.setMinutes(0);
    }
    carDropOffDate.setHours(18);
    carDropOffDate.setMinutes(0);

    itm.criItem.pickupDateTime = moment(carPickUpDate).format(format);
    itm.criItem.returnDateTime = moment(carDropOffDate).format(format);
    itm.initialDateStr = itm.criItem.pickupDateTime + '~' + itm.criItem.returnDateTime;
  }

  updateGenericDates(itm, newStartDate, changeItemValue, pc) {
    let nights = 0;
    if (changeItemValue.split('~')[1] && pc != 'TRS') {
      nights = parseInt(changeItemValue.split('~')[1]);
    }
    if (isNaN(nights)) {
      nights = 0;
    }
    newStartDate.setDate(newStartDate.getDate() + nights + itm.gapWithChangedItem); // add the new hotel nights and gap

    itm.criItem.startDate = this.surfCriteriaUtil.convertDateObjToStr(newStartDate, true);
    itm.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(newStartDate, true);
  }

  updateOwnArrangementDates(itm, newStartDate, changeItemValue, pc) {
    let nights = 0;
    if (changeItemValue.split('~')[1] && pc != 'TRS') {
      nights = parseInt(changeItemValue.split('~')[1]);
    }
    newStartDate.setDate(newStartDate.getDate() + nights + itm.gapWithChangedItem); // add the new hotel nights and gap
    itm.criItem.departureDate = this.surfCriteriaUtil.convertDateObjToStr(newStartDate, true);
    if (!itm.criItem.duration) {  // set initial nights as 1 if undefined
      itm.criItem.duration = 1;
      // all the items below this hotel should shift 1 day forward
      this.updateGapsBelowHotel(itm.index);
    }
    itm.initialDateStr = itm.criItem.departureDate;
  }

  updateGapsBelowHotel(hotelIndex) {
    this.criteriaItemList.forEach(itm => {
      if (itm.index > hotelIndex) {
        itm.gapWithChangedItem++;
      }
    });
  }

  getItemStartDate(item) {
    if (item.criItem) {
      switch (item.productCode) {
        case 'HTL':
          return item.criItem.startDate;
        case 'FLT':
          return item.criItem.legs[0].departureDate;
        case 'TRS':
          return item.criItem.onewayPickupTime;
        case 'CAR':
          return item.criItem.pickupDateTime;
        case 'GEN':
          return item.criItem.startDate;
      }
    }
  }

  updateGapWithChangeItem(changeItemIndex, changeItemValue, changeItemProductCode) {
    // update gap with changed item for it's post items

    // get the changed item before it updates
    let toBeChangedItem;
    let toBeChangedItemDate;
    this.criteriaItemList.forEach(itm => {
      if (itm.index == changeItemIndex) {
        toBeChangedItem = itm;
      }
    });

    if (toBeChangedItem && toBeChangedItem.criItem == undefined) {
      // initial time, so there is no previous value, then get the new value
      switch (toBeChangedItem.productCode) {
        case 'HTL':
          toBeChangedItemDate = new Date(changeItemValue.split('~')[2]);
          break;
        default:
          toBeChangedItemDate = new Date(changeItemValue);
          break;
      }
    } else {
      switch (toBeChangedItem.productCode) {
        case 'HTL':
          toBeChangedItemDate = new Date(toBeChangedItem.criItem.startDate);
          toBeChangedItemDate.setDate(toBeChangedItemDate.getDate() + parseInt(toBeChangedItem.criItem.nights));
          break;
        case 'FLT':
          if (toBeChangedItem.criItem.legs && toBeChangedItem.criItem.legs[0]) {
            toBeChangedItemDate = new Date(toBeChangedItem.criItem.legs[0].departureDate);
          }
          break;
        case 'TRS':
          toBeChangedItemDate = new Date(toBeChangedItem.criItem.onewayPickupTime);
          break;
        case 'CAR':
          toBeChangedItemDate = new Date(toBeChangedItem.criItem.pickupDateTime);
          break;
        case 'GEN':
          toBeChangedItemDate = new Date(toBeChangedItem.criItem.startDate);
          break;
      }
    }

    if (toBeChangedItemDate != undefined && !isNaN(toBeChangedItemDate.getTime())) {  // check for valid date
      // adjust the gaps of post items based on the date of the to-be-changed item
      let itrValue = 0;
      this.criteriaItemList.forEach(itm => {
        if (itm.index > changeItemIndex) {
          switch (itm.productCode) {
            case 'HTL':
              if (itm.criItem.startDate) {
                itm.gapWithChangedItem = this.getJSDateDifference(new Date(itm.criItem.startDate), new Date(toBeChangedItemDate));
                if (this.isAmendmentFlow
                && ( changeItemProductCode === Product.TRANSFERS || changeItemProductCode === Product.ACCOMMODATION )
                && itm.oldCriteriaItem?.criItem
                && this.criteriaItemList[changeItemIndex].oldCriteriaItem?.criItem) {
                  if(changeItemProductCode === Product.TRANSFERS) {
                    itm.gapWithChangedItem = this.getJSDateDifference(
                    new Date(itm.oldCriteriaItem.criItem.startDate),
                    new Date(this.criteriaItemList[changeItemIndex].oldCriteriaItem.criItem.onewayPickupTime));
                  } else if (changeItemProductCode === Product.ACCOMMODATION) {
                    itm.gapWithChangedItem = this.getJSDateDifference(
                    new Date((( new Date(this.criteriaItemList[changeItemIndex].oldCriteriaItem.criItem.startDate)).getTime()
                    + Number(this.criteriaItemList[changeItemIndex].oldCriteriaItem.criItem.nights)*24*3600*1000 )),
                    new Date(itm.oldCriteriaItem.criItem.startDate));
                  }
                }
                if (isNaN(itm.gapWithChangedItem)) {
                  itm.gapWithChangedItem = 0;
                }
              } else {
                itm.gapWithChangedItem = itrValue;
              }
              break;
            case 'FLT':
              if (itm.criItem.legs[0].departureDate) {
                itm.gapWithChangedItem = this.getJSDateDifference(new Date(itm.criItem.legs[0].departureDate), new Date(toBeChangedItemDate));
                if (isNaN(itm.gapWithChangedItem)) {
                  itm.gapWithChangedItem = 0;
                }
              } else {
                itm.gapWithChangedItem = 0;
              }
              break;
            case 'TRS':
              if (itm.criItem.onewayPickupTime) {
                itm.gapWithChangedItem = this.getJSDateDifference(new Date(itm.criItem.onewayPickupTime), new Date(toBeChangedItemDate));
                if (this.isAmendmentFlow
                && changeItemProductCode === Product.ACCOMMODATION
                && itm.oldCriteriaItem?.criItem
                && this.criteriaItemList[changeItemIndex].oldCriteriaItem?.criItem) {
                  itm.gapWithChangedItem = this.getJSDateDifference(
                  new Date(itm.oldCriteriaItem.criItem.onewayPickupTime),
                  new Date((( new Date(this.criteriaItemList[changeItemIndex].oldCriteriaItem.criItem.startDate)).getTime()
                    + Number(this.criteriaItemList[changeItemIndex].oldCriteriaItem.criItem.nights)*24*3600*1000 )),);
                }
                if (isNaN(itm.gapWithChangedItem)) {
                  itm.gapWithChangedItem = 0;
                }
              } else {
                itm.gapWithChangedItem = 0;
              }
              break;
            case 'CAR':
              if (itm.criItem.pickupDateTime) {
                itm.gapWithChangedItem = this.getJSDateDifference(new Date(itm.criItem.pickupDateTime), new Date(toBeChangedItemDate));
                if (isNaN(itm.gapWithChangedItem)) {
                  itm.gapWithChangedItem = 0;
                }
              } else {
                itm.gapWithChangedItem = 0;
              }
              break;
            case 'GEN':
              if (itm.criItem.startDate) {
                itm.gapWithChangedItem = this.getJSDateDifference(new Date(itm.criItem.startDate), new Date(toBeChangedItemDate));
                if (isNaN(itm.gapWithChangedItem)) {
                  itm.gapWithChangedItem = 0;
                }
              } else {
                itm.gapWithChangedItem = 0;
              }
              break;
            case 'OWA':
              if (itm.criItem.departureDate) {
                itm.gapWithChangedItem = this.getJSDateDifference(new Date(itm.criItem.departureDate), new Date(toBeChangedItemDate));
                if (isNaN(itm.gapWithChangedItem)) {
                  itm.gapWithChangedItem = 0;
                }
              } else {
                itm.gapWithChangedItem = itrValue;
              }
              break;
          }
          itrValue++;
        }
      });
    }

  }

  isInsideDateRange(date: Date, rangeStart: Date, rangeEnd: Date): boolean {
    let isInside = false;
    if (rangeStart <= date && date <= rangeEnd) {
      isInside = true;
    }
    return isInside;
  }

  /**
   * add paxSet key to session storage.
   * store last DP search pax values to refine search.
   * Other flows didn't use session storage. Others extract values from GET request and Dp search use POST request to navigate tailor mode.
   * @param paxEvent : last pax event
   */
  cachingPaxCriteria(paxEvent: Array<PaxAllocationItem>) {
    sessionStorage.setItem('paxSet', JSON.stringify(paxEvent));
  }

  /**
   * add paxSet key to session storage.
   * store last DP search pax values to refine search.
   * Other flows didn't use session storage. Others extract values from GET request and Dp search use POST request to navigate tailor mode.
   */
  getCachedPaxCriteria() {
    try {
      const temp = JSON.parse(sessionStorage.getItem('paxSet'));
      this.cachePaxSet = JSON.parse(JSON.stringify(temp));
      this.cachePaxSet.forEach((item: any, index) => {
        this.cachePaxSet[index].adult = item._adult;
        this.cachePaxSet[index].child = item._child;
        this.cachePaxSet[index].teen = item._teen;
        this.cachePaxSet[index].infant = item._infant;
        this.cachePaxSet[index].infantSeatAllocation = item._infantSeatAllocation;
      });
    } catch (e) {
      this.cachePaxSet = [new PaxAllocationItem(2, [], [], [], [])];
    }

  }
  /*DP Guests related functions*/
  getPassengerSelection(event: Array<PaxAllocationItem>) {
    if (this.cachePaxSet && this.cachePaxSet.length > 1 && !this.roomWiseSelected) {
      this.roomWiseSelected = true;
      this.setRoomWise = true;
    }
    this.hasFlight();
    if (event != null && this.validateChildAges(event) && this.validateTeenAges(event)) {
      this.dpCriteria.adult = this.processAdultPaxAllocation(event);
      if (!this.isAmendmentFlow && this.cachePaxSet && this.cachePaxSet.length > 0) {
        this.cachePaxSet[0]._adult = parseInt(this.dpCriteria.adult.split('~')[0]);
        if (event[0]) {
          if (event[0].child) {
            this.cachePaxSet[0]._child = JSON.parse(JSON.stringify(event[0].child));
          }
          if (event[0].infant) {
            this.cachePaxSet[0]._infant = JSON.parse(JSON.stringify(event[0].infant));
          }
          if (event[0].teen) {
            this.cachePaxSet[0]._teen = JSON.parse(JSON.stringify(event[0].teen));
          }
        }
      }

      const children = this.processChildAllocation(event);
      const teens = this.processTeenAllocation(event);
      const infant = this.processInfantAllocation(event);

      if (children.CHD && children.CHD.length > 0) {
        this.dpCriteria.child = children.CHD;
      } else {
        this.dpCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.dpCriteria.teen = teens;
      } else {
        this.dpCriteria.teen = null;
      }

      this.dpCriteria.infant = infant && infant.length > 0 ? infant : null;
      this.passengerAllocation = event;
      if (!this.hasFlightItem) {
        this.passengerAllocation[0].infantSeatAllocation.fill(false);
      }
      // caching pax set for refine search
      // this.cachingPaxCriteria(event);
      if (
        !this.isAmendmentFlow &&
        this.cachePaxSet &&
        this.cachePaxSet.length > 0
      ) {
        const paxCount = this.cachePaxSet[0]._adult + this.cachePaxSet[0]._teen.length + this.cachePaxSet[0]._child.length
          + this.cachePaxSet[0]._infant.length;
        if (this.criteriaItemList && this.criteriaItemList.length > 0) {
          if (this.existingPassengerCount < paxCount) {
            this.handleIncreasePaxCount();
          }
          if (this.existingPassengerCount > paxCount) {
            this.handleDecreasePaxCount();
          }
        }
        this.existingPassengerCount = paxCount;
      }

    } else {
      this.dpCriteria.adult = null;
      this.dpCriteria.child = null;
      this.dpCriteria.teen = null;
      this.dpCriteria.infant = null;
    }
  }
  validateChildAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => acc && ( pax.child.indexOf(-1) > -1 ? false : true ), true);
  }
  validateTeenAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => acc && ( pax.teen.indexOf(-1) > -1 ? false : true ), true);
  }

  processAdultPaxAllocation(paxArray: Array<PaxAllocationItem>) {

    const defaultAdultAge =  this.DEFAULT_ADULT_AGE;
    const fieldSeperator = '~';
    const roomSeperator = '__';

    let paxStr = '';

    for (let i = 0 ; i < paxArray.length ; i++) {
      const paxItem = paxArray[i];
      if (i != 0) {
        if (this.roomWiseSelected) {
          paxStr = paxStr + roomSeperator;
        }
      }
      // paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + this.convertDateObjToStr(this.getDOB(defaultAdultAge)) + fieldSeperator +  (i + 1);
      paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + 'DOB';
      if (this.roomWiseSelected) {
        paxStr = paxStr + fieldSeperator + (i + 1);
      }
    }
    return paxStr;
  }
  processTeenAllocation(paxArray: Array<PaxAllocationItem>) {

    let teen = '';

    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.teen.length > 0) {

        const teenAgeMap = {};
        paxRoom.teen.forEach(teenAge => {
          teenAgeMap[teenAge] ? teenAgeMap[teenAge]++ : teenAgeMap[teenAge] = 1;
        });

        for (const key in teenAgeMap) {
          const count = teenAgeMap[key] + '';
          /* TODO update this logic based on guest age setup */
          teen += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
          if (this.roomWiseSelected) {
            teen += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          } else {
            teen += this.AGE_SEPERATOR;
          }
        }
      }
      roomNo++;
    });


    if (teen.length > 2) {
      if (this.roomWiseSelected) {
        teen = teen.slice(0, teen.length - 2);
      } else {
        teen = teen.slice(0, teen.length - 2);
      }
    }

    return teen;
  }
  processChildAllocation(paxArray: Array<PaxAllocationItem>) {

    const children = {
      INF: '',
      CHD: '',
    };

    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.child.length > 0) {

        const childAgeMap = {};
        paxRoom.child.forEach(childAge => {
          childAgeMap[childAge] ? childAgeMap[childAge]++ : childAgeMap[childAge] = 1;
        });

        for (const key in childAgeMap) {
          const count = childAgeMap[key] + '';
          /* TODO update this logic based on guest age setup */
          if (key.trim() == this.LESS_THAN_ONE || key.trim() == '1') {

            const infantAge = (key.trim() == this.LESS_THAN_ONE) ? 0 : 1;

            /*Infants */
            // children.INF += count + fieldSeperator + 0 + fieldSeperator + this.convertDateObjToStr(this.getDOB(0)) + fieldSeperator + roomNo + ageSeperator;
            children.INF += count + this.FIELD_SEPERATOR + infantAge + this.FIELD_SEPERATOR + 'DOB';
            if (this.roomWiseSelected) {
              children.INF += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
            } else {
              children.INF += this.AGE_SEPERATOR;
            }
          } else {
            /*child*/
            // children.CHD += count + fieldSeperator + key + fieldSeperator + this.convertDateObjToStr(this.getDOB(key)) + fieldSeperator + roomNo + ageSeperator;
            children.CHD += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
            if (this.roomWiseSelected) {
              children.CHD += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
            } else {
              children.CHD += this.AGE_SEPERATOR;
            }
          }
        }
      }
      roomNo++;
    });

    if (children.INF.length > 2) {
      if (this.roomWiseSelected) {
        children.INF = children.INF.slice(0, children.INF.length - 2);
      } else {
        children.INF = children.INF.slice(0, children.INF.length - 2);
      }
    }
    if (children.CHD.length > 2) {
      if (this.roomWiseSelected) {
        children.CHD = children.CHD.slice(0, children.CHD.length - 2);
      } else {
        children.CHD = children.CHD.slice(0, children.CHD.length - 2);
      }
    }

    return children;
  }

  processInfantAllocation(paxArray: Array<PaxAllocationItem>) {

    let roomNo = 1;
    let inf = '';
    paxArray.forEach(paxRoom => {
      if (paxRoom.infant.length > 0) {

        const infantAgeMap = {};
        paxRoom.infant.forEach(childAge => {
          infantAgeMap[childAge] ? infantAgeMap[childAge]++ : infantAgeMap[childAge] = 1;
        });

        for (const key in infantAgeMap) {
          const count = infantAgeMap[key] + '';
          inf += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';
          if (this.roomWiseSelected) {
            inf += this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          } else {
            inf += this.AGE_SEPERATOR;
          }
        }
      }
      roomNo++;
    });

    if (inf.length > 2) {
      inf = inf.slice(0, inf.length - 2);
    }
    return inf;
  }

  isRoomWiseSelected(event) {
    this.roomWiseSelected = event;
    if (!this.roomWiseSelected) {
      this.defaultPaxSet = [new PaxAllocationItem(2, [], [], [], [])];
    }
    this.passengerAllocation = [new PaxAllocationItem(2, [], [], [], [])];
  }
  /*DP Guests related functions*/

  setValid(event, item) {
    if (event) {
      this.surfTravellerEarliestDepartureDateService.setCachedDpCriteriaItemList(this.criteriaItemList);
      switch (item.productCode) {
        case 'HTL':
          if (item && item.criItem && item.criItem.destinationCode &&
            item.criItem.startDate && item.criItem.nights && item.criItem.nights > 0) {
            item.valid = true;
          } else {
            item.valid = false;
          }
          break;
        case 'TOU':
          if (item && item.criItem && item.criItem.tourDestCities &&
            ( item.criItem.startDate || item.criItem.startMonth && item.criItem.startYear ) ) {
            item.valid = true;
          } else {
            item.valid = false;
          }
          if (item && item?.isGrab) {
            item.valid = event;
          }
          break;
        case 'FLT':
          if (item && item.criItem && item.criItem.legs && item.criItem.legs[0] &&
            item.criItem.legs[0].departureDate && item.criItem.legs[0].departureAirportCode &&
            item.criItem.legs[0].arrivalAirportCode) {
            item.valid = true;
          } else {
            item.valid = false;
          }
          break;
        case 'TRS':
          // Note: pickup/drop-off country and city should not be set if the pickup/drop-off type is CRUISE_PORT
          if (item && item.criItem && (item.criItem.pickupLocationType === 'CRUISE_PORT' && ( !item.criItem.pickupCity || !this.isEpToDpConversion ) ||
              item.criItem.pickupLocationType !== 'CRUISE_PORT' && item.criItem.pickupCity) &&
            (item.criItem.dropoffLocationType === 'CRUISE_PORT' && (!item.criItem.dropOffCity || !this.isEpToDpConversion) ||
              item.criItem.dropoffLocationType !== 'CRUISE_PORT' && item.criItem.dropOffCity) &&
            item.criItem.onewayPickupTime) {
            item.valid = true;
          } else {
            item.valid = false;
          }
          break;
        case 'CAR':
          if (item && item.criItem && item.criItem.pickupCity && item.criItem.returnCity &&
            item.criItem.pickupDateTime && item.criItem.returnDateTime) {
            item.valid = true;
          } else {
            item.valid = false;
          }
          break;
        case 'GEN':
          if (item && item.criItem && item.criItem.destinationCode && item.criItem.startDate) {
            item.valid = true;
          } else {
            item.valid = false;
          }
          break;
        case 'OWA':
          if (item && item.criItem && item.criItem.name && item.criItem.departureDate) {
            item.valid = true;
          } else {
            item.valid = false;
          }
          break;
      }

    } else {
      item.valid = false;
    }
  }

  validateDPCriteria(): boolean {
    let isValidPax = false;
    let isValidItems = true;
    let isLastMinuteBookingValidationPass = true;
    let hasNewItem = false;
    // this is temporary fix for issue FC-39526 Multiple TSTs - DP Flow - Create Itinerary button greyed-out after redirect from Component Flow Grab
    // this.isGrabFlow should be removed after actual fix
    if (this.dpCriteria.adult || this.isGrabFlow) {
      isValidPax = true;
    }

    if (this.criteriaItemList && this.criteriaItemList.length > 0) {
      let ownArrangementCount = 0;
      this.criteriaItemList.forEach((pc, index) => {
        if (this.isAmendmentFlow) {
          if (!pc.valid && !(pc.productCode === Product.CAR && !this.unfreezeItemMap.get(index))) {
            isValidItems = false;
          }
        } else {
          if (!pc.valid) {
            isValidItems = false;
          }
        }
        if (pc.isNewCriteriaItem) {
          hasNewItem = true;
        }
        if (pc.productCode === 'OWA') {
          ownArrangementCount += 1;
        }
      });
      if (this.criteriaItemList.length === ownArrangementCount) {
        isValidItems = false;
      }
    } else {
      isValidItems = false;
    }

    isLastMinuteBookingValidationPass = this.surfCalendarStartDateService.lastMinuteBookingErrorSet.size <= 0;
    if (this.isAmendmentFlow) {
      return isValidPax && isValidItems && (hasNewItem ? true : (isLastMinuteBookingValidationPass || this.surfCalendarStartDateService.hasValidUnfreezeItem));
    } else {
      return isValidPax && isValidItems;
    }
  }

  checkAllItemFreeze(): boolean {
    if (this.isAmendmentFlow) {
      let value = true;
      for (let i = 0; i < this.criteriaItemList.length; i++) {
        if (this.unfreezeItemMap.get(i)) {
          value = false;
          break;
        }
      }
      return value;
    } else {
      return false;
    }
  }

  /*General functions*/
  closeCriteriaBox() {
    if (this.isRefine) {
      this.emitRefineSearchClose.emit(true);
    } else {
      this.commonService.changeMessage('DP_close');
      this.commonService.updateProductCriteriaList([]);
      if (!this.isAmendmentFlow && !this.isServiceAddComponent) {
        this.commonService.updateBaseCriteria(new BaseCriteria());
      }
      this.commonService.updateShareObj(null);
    }
    if (this.isServiceAddComponent || this.isAmendmentFlow || this.isEpToDpConversion) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }
  }

  cancelEpSearchCriteriaModal() {
    this.cancelRefine.emit(false);
  }
  scrollTo(selector) {
    if (this.windowScrollEnable) {
      SurfScrollDirective.scrollTo(
        selector,
        null,
        false,
        false,
        0,
        60
      );
    }
  }

  /*Check if the criteria item list is empty, so that intro block will be displayed*/
  isCriteriaListEmpty() {
    return this.criteriaItemList == undefined || this.criteriaItemList.length == 0;
  }

  getJSDateDifference(date1, date2): number {
    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    const diffTime = Math.abs(d2.getTime() - d1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  /*Add initial criteria item to the list,so the item-index will be zero always*/
  addCriteriaItem(productCode: string, initFlightCount?: number) {
    if (this.criteriaItemList == undefined) {
      this.criteriaItemList = [];
    }

    // let newItem;
    // if (this.initialItem) {
    //   newItem = this.initialItem;
    // } else {
    //   newItem = this.createNewItem(productCode);
    // }

    if (productCode == 'FLT') {
      /*If moved from Flight criteria component, add two criteria items for both legs since there were two legs at the FLT component item*/
      const criteria = this.commonService.getComponentCriteria(productCode).source as any;
      console.log(criteria);
      this.setHasFlightItem(true);
      if (criteria._value && criteria._value.productCode && criteria._value.productCode === productCode) {
        if (criteria._value.legs.length > 0) {
          criteria._value.legs.forEach((leg, index) => {
            const criItem = JSON.parse(JSON.stringify(criteria._value));
            criItem.legs.length = index + 1;
            if (index !== 0) {
              criItem.legs.splice(0, index);
            }
            let itemAirportStr: string;
            if (criItem && criItem.legs && criItem.legs[0]) {
              itemAirportStr = criItem.legs[0].arrivalAirportCode + '~'
                + criItem.legs[0].arrivalAirportCity + '~'
                + criItem.legs[0].arrivalAirportCityName + '~'
                + criItem.legs[0].arrivalAirportCountryName
                + '|'
                + criItem.legs[0].departureAirportCode + '~'
                + criItem.legs[0].departureAirportCity + '~'
                + criItem.legs[0].departureAirportCityName + '~'
                + criItem.legs[0].departureAirportCountryName;
            }

            this.criteriaItemList.push({index: this.criteriaItemList.length,
              initialDestStr: itemAirportStr, productCode, criItem, gapWithChangedItem: 0, isGrabbedLeg : false});
            this.DpCriteriaManageFlights.updateFlightItemsOnAdd(this.flightsGrouping,
              this.criteriaItemList, (this.criteriaItemList.length - 1));
          });
        }
      } else {
        const outLegItem = this.createNewItem(productCode);
        outLegItem.itemIndex = 0;
        const inLegItem = this.createNewItem(productCode);
        inLegItem.itemIndex = 1;
        if (initFlightCount === 1) {
          this.criteriaItemList.push({index: this.criteriaItemList.length, productCode, criItem: outLegItem, gapWithChangedItem: 0, isGrabbedLeg : false});
          this.DpCriteriaManageFlights.updateFlightItemsOnAdd(this.flightsGrouping, this.criteriaItemList, (this.criteriaItemList.length - 1));
        } else {
          this.criteriaItemList.push({index: this.criteriaItemList.length, productCode, criItem: outLegItem, gapWithChangedItem: 0, isGrabbedLeg : false});
          this.criteriaItemList.push({index: this.criteriaItemList.length, productCode, criItem: inLegItem, gapWithChangedItem: 0, isGrabbedLeg : false});
          this.DpCriteriaManageFlights.addInitialFlightItems(this.flightsGrouping, this.criteriaItemList, (this.criteriaItemList.length - 2));
        }
      }
    } else {
      if (productCode == this.PROD_CONST.ACCOMMODATION && !this.hasHotelItem) {
        this.hasHotelItem = true;
      }
      let criteria: any;
      if (productCode !== 'OWA' && this.commonService.getComponentCriteria(productCode)) {
        criteria = this.commonService.getComponentCriteria(productCode).source as any;
      }
      if (criteria && criteria._value && criteria._value.productCode && criteria._value.productCode === productCode) {
        this.criteriaItemList.push({index: this.criteriaItemList.length, productCode, criItem: criteria._value, gapWithChangedItem: 0});
      } else {
        this.criteriaItemList.push({index: this.criteriaItemList.length, productCode, criItem: this.createNewItem(productCode), gapWithChangedItem: 0});
      }
    }

    if (this.initialItem) {
      if (productCode == 'FLT') {
        /*If moved from Flight criteria component, add two criteria items for both legs since there were two legs at the FLT component item*/

        if (this.initialItem.legs && this.initialItem.legs.length == 2) {
          const outItem = new FlightCriteria();
          outItem.productCode = 'FLT';
          outItem.itemIndex = 0;
          outItem.legs = [];
          outItem.legs.push(JSON.parse(JSON.stringify(this.initialItem.legs[0])));

          const inItem = new FlightCriteria();
          inItem.productCode = 'FLT';
          inItem.itemIndex = 1;
          inItem.legs = [];
          inItem.legs.push(JSON.parse(JSON.stringify(this.initialItem.legs[1])));

          this.productCriteriaList.push(outItem);
          this.productCriteriaList.push(inItem);
          this.commonService.updateProductCriteriaList(this.productCriteriaList);
        }
      } else {
        if (productCode == this.PROD_CONST.ACCOMMODATION && !this.hasHotelItem) {
          this.hasHotelItem = true;
        }
        this.productCriteriaList.push(this.initialItem);
        this.commonService.updateProductCriteriaList(this.productCriteriaList);
      }
    }
    if (this.criteriaItemList.length > 0) {
      this.setInitialPaxSplit(0);
    }
    this.emitDPCriteriaStatus();
  }

  /*Remove criteria item from the list and dp search criteria panel*/
  removeCriteriaItem(event) {
    let arrayIndex = -1;
    this.criteriaItemList.filter((item, index) => {
      if (item.index == event) {
        arrayIndex = index;
      }
      if (item.index > event) {  // add a flag for post items that the pre item is removed
        item.isPreItemRemoved = true;
      }
      if (item.index < event) {  // add a flag for pre items that the pre item is removed
        item.isPreItemRemoved = false;
      }
    });
    const removedItem = this.criteriaItemList[arrayIndex];
    this.criteriaItemList.splice(arrayIndex, 1);

    this.reorderIndexes(this.criteriaItemList);

    /*Remove corresponding criteria item in the common service as well*/
    let pcIdx = -1;
    this.productCriteriaList.forEach((pc, pcIndex) => {
      if (pc.itemIndex == event) {
        pcIdx = pcIndex;
      }
    });
    this.productCriteriaList.splice(pcIdx, 1);
    if (this.hasHotelItem  && removedItem.productCode == this.PROD_CONST.ACCOMMODATION && this.criteriaItemList.findIndex(criteriaItem => criteriaItem.productCode == 'HTL') < 0) {
      this.hasHotelItem = false;
    }
    // this.commonService.updateProductCriteriaList(this.productCriteriaList);
    this.adjustIndexes(this.criteriaItemList);
    this.DpCriteriaManageFlights.updateFlightItemsOnRemove(this.flightsGrouping, this.criteriaItemList, removedItem);

    this.emitDPCriteriaStatus();
    this.validateIsPnrItemsOnly();
    this.hasFlight();
    if (this.isAmendmentFlow && event !== undefined) {
      this.updateUnfreezeMap(event, this.mapChangeActions.REMOVE);
    }
  }

  addAnotherCriteriaItem(event) {
    if (event) {

      if (event.newItemType == this.PROD_CONST.ACCOMMODATION && !this.hasHotelItem) {
        this.hasHotelItem = true;
      }
      if (event.preItemIndex == -1) {
        this.addToTop = true;
      } else {
        this.addToTop = false;
      }

      const preItems = [];
      const postItems = [];

      this.criteriaItemList.forEach((item, index) => {
        item.isPreItemRemoved = false;
        if (index <= event.preItemIndex) {
          preItems.push(item);
        }
        if (index >= event.postItemIndex) {
          postItems.push(item);
        }
      });

      const newItem = {
        index: -1,
        productCode: event.newItemType,
        criItem: this.createNewItem(event.newItemType),
        gapWithChangedItem: 0,
        initialDateStr: null,
        disableStartDate: null,
        initialDestStr: null,
        isGrabbedLeg : false,
        isNewCriteriaItem : true
      };

      // If the new item is an own arrangement then no validation or date propagation is applied.


      /*Before  adding the new item, there are 2 steps need to be done
      * Step 1 - Populate properties of the new item according to it's pre item (destination and dates)
      * Step 2 - Adjust post items to the new item accordingly (dates / dest??) */

      const preItem = this.criteriaItemList[event.preItemIndex];
      let beforePreItem = null;
      if (event.preItemIndex > 0) {
        for (let i = (event.preItemIndex - 1); i >= 0; i--) {
          if (this.criteriaItemList[i].productCode !== 'OWA') {
            beforePreItem = this.criteriaItemList[i];
            break;
          }
        }
      }
      let preItemdisableDate;
      let preItemStartDate;
      let preItemDestinationStr; // DISPLAYTEXT~LOCTYPE~LOCCODE~LOCNAME~PARENTNAME
      let preItemAirportStr; // DEPAP~DEPCITYCODE~DEPCITYNAME~DEPCOUNTRYNAME|ARRAP~ARRCITYCODE~ARRCITYNAME~ARRCOUNTRYNAME

      if (preItem && preItem.criItem) {
        switch (preItem.productCode) {
          case 'HTL':
            preItemStartDate = new Date(preItem.criItem.startDate);
            preItemStartDate.setDate(preItemStartDate.getDate() + parseInt(preItem.criItem.nights));
            preItemdisableDate = preItem.criItem.startDate;
            // let destinationOrHotelCode = preItem.criItem.destinationCode;
            // if (this.isAmendmentFlow && preItem.criItem.hotelCode) {
            //   destinationOrHotelCode = preItem.criItem.hotelCode;
            // }
            preItemDestinationStr = preItem.criItem.destinationName + ', ' + preItem.criItem.destinationParentCode + '~' + preItem.criItem.destinationType
              + '~' + preItem.criItem.destinationCode + '~' + preItem.criItem.destinationName + '~' + preItem.criItem.destinationParentCode
              + '~' + (preItem.criItem.pickupCity ? preItem.criItem.pickupCity : '') +  '~' + (preItem.criItem.dropOffCity ?
                preItem.criItem.dropOffCity : '') +  '~' + (preItem.criItem.hotelCode ? preItem.criItem.hotelCode : '')
              + '~' + (preItem.criItem.country ? preItem.criItem.country : '')
              + '~' + (preItem.criItem.destinationType === 'CITY' ? '' : preItem.criItem.destinationParentCode);
            if (this.GEO_ENABLE_FOR_HOTEL_SEARCH && preItem.criItem && preItem.criItem.destinationType &&
              preItem.criItem.destinationType === DestinationType.GEO_LOCATION) {
              preItemDestinationStr = undefined;
            }
            break;
          case 'FLT':
            if (preItem.criItem.legs[0].departureDate != null) {
              preItemStartDate = new Date(preItem.criItem.legs[0].departureDate);
            }

            if (this.isAmendmentFlow && preItem && preItem.criItem && preItem.criItem.legs && preItem.criItem.legs[0] && preItem.criItem.legs[0].arrivalDate) {
              preItemStartDate = new Date(preItem.criItem.legs[0].arrivalDate);
            }

            preItemdisableDate = preItem.criItem.legs[0].departureDate;
            // if the flight has arrival airport, the the nearest parent city will be filled as the preItemDestination
            if (preItem.criItem.legs[0]) {
              preItemDestinationStr = preItem.criItem.legs[0].arrivalAirportCityName + ', ' + preItem.criItem.legs[0].arrivalAirportCountryName
                + '~' + DestinationType.CITY
                + '~' + (preItem.criItem.legs[0].arrivalAirportCityCode ? preItem.criItem.legs[0].arrivalAirportCityCode : preItem.criItem.legs[0].arrivalAirportCity)
                + '~' + preItem.criItem.legs[0].arrivalAirportCityName
                + '~' + ((preItem.criItem.legs[0].arrivalAirportCountryName) ? preItem.criItem.legs[0].arrivalAirportCountryName : ((preItem.criItem.legs[0].arrObj && preItem.criItem.legs[0].arrObj.parent && preItem.criItem.legs[0].arrObj.parent.length > 0) ?
                  preItem.criItem.legs[0].arrObj.parent[(preItem.criItem.legs[0].arrObj.parent.length - 1)].name : ''))
                + '~' + ((preItem.criItem.legs[0].depObj && this.surfCriteriaUtil.getCity(preItem.criItem.legs[0].depObj)) ? this.surfCriteriaUtil.getCity(preItem.criItem.legs[0].depObj).code : '')
                + '~' + ((preItem.criItem.legs[0].arrObj && this.surfCriteriaUtil.getCity(preItem.criItem.legs[0].arrObj)) ? this.surfCriteriaUtil.getCity(preItem.criItem.legs[0].arrObj).code : '')
                + '~' + ((preItem.criItem.legs[0].arrObj && this.surfCriteriaUtil.getCity(preItem.criItem.legs[0].arrObj)) ? this.surfCriteriaUtil.getCity(preItem.criItem.legs[0].arrObj).name : '')
                + '~' + ((preItem.criItem.legs[0].arrObj && this.surfCriteriaUtil.getCountryCode(preItem.criItem.legs[0].arrObj)) ? this.surfCriteriaUtil.getCountryCode(preItem.criItem.legs[0].arrObj) : '' )
                + '~' + ((preItem.criItem.legs[0].arrObj && this.surfCriteriaUtil.getCountry(preItem.criItem.legs[0].arrObj)) ? this.surfCriteriaUtil.getCountry(preItem.criItem.legs[0].arrObj) : '');

              // location Pattern = DEPAP~DEPCITYCODE~DEPCITYNAME~DEPCOUNTRYNAME|ARRAP~ARRCITYCODE~ARRCITYNAME~ARRCOUNTRYNAME
              preItemAirportStr = preItem.criItem.legs[0].departureAirportCode + '~'
                + preItem.criItem.legs[0].departureAirportCity + '~'
                + preItem.criItem.legs[0].departureAirportCityName + '~'
                + preItem.criItem.legs[0].departureAirportCountryName
                + '|'
                + preItem.criItem.legs[0].arrivalAirportCode + '~'
                + preItem.criItem.legs[0].arrivalAirportCity + '~'
                + preItem.criItem.legs[0].arrivalAirportCityName + '~'
                + preItem.criItem.legs[0].arrivalAirportCountryName;
            }
            break;
          case 'TRS':
            preItemStartDate = new Date(preItem.criItem.onewayPickupTime);
            preItemdisableDate = preItem.criItem.onewayPickupTime;
            let destName = (preItem.criItem.selectedDropoffType === 40 || preItem.criItem.selectedDropoffType === 80)
              ? (preItem.criItem.dropOffLocationName ? preItem.criItem.dropOffLocationName : preItem.criItem.dropOffCityName)
              : (preItem.criItem.dropOffCityName ? preItem.criItem.dropOffCityName : preItem.criItem.dropOffLocationName);
            if (newItem.productCode === Product.TRANSFERS) {
              destName = preItem.criItem.dropOffLocationName ? preItem.criItem.dropOffLocationName : preItem.criItem.dropOffCityName;
            }
            const destCountryName = preItem.criItem.dropOffCountryName ? preItem.criItem.dropOffCountryName : preItem.criItem.dropOffCountry;
            // let displayTextTrs = preItem.criItem.dropOffLocationName + ', ' + preItem.criItem.dropOffCountryName;
            const displayTextTrs = destName + ', ' + destCountryName;
            let destType;
            if (preItem.criItem.destinationType == 40 || preItem.criItem.destinationType === DestinationType.CITY) {
              destType = DestinationType.CITY;
            } else if (preItem.criItem.destinationType == 80 || preItem.criItem.destinationType === DestinationType.HOTEL) {
              destType = DestinationType.HOTEL;
            }
            if (preItem && preItem.criItem) {
              if (newItem.productCode === Product.TRANSFERS) {
                if (preItem.criItem.selectedDropoffType) {
                  newItem.criItem.selectedPickupType = preItem.criItem.selectedDropoffType;
                }
                newItem.criItem.selectedDropoffType = 40;
              } else {
                if (preItem.criItem.selectedDropoffType) {
                  newItem.criItem.selectedDropoffType = preItem.criItem.selectedDropoffType;
                }
                if (preItem.criItem.selectedPickupType) {
                  newItem.criItem.selectedPickupType = preItem.criItem.selectedPickupType;
                }
              }
            }
            preItemDestinationStr = displayTextTrs + '~' + destType + '~' +
               ((preItem.criItem.destinationType === 40 || preItem.criItem.destinationType === DestinationType.CITY) ?
                 preItem.criItem.pickupCity : (preItem.criItem.destinationType == 80 || preItem.criItem.destinationType === DestinationType.HOTEL) ?
                                                      preItem.criItem.dropoffLocationCode : preItem.criItem.pickupCity)
              + '~' + destName + '~' + destCountryName + '~' + preItem.criItem.pickupCity + '~' + preItem.criItem.dropOffCity + '~' + preItem.criItem.dropOffCityName
               + '~' + preItem.criItem.dropOffCountry + '~' + preItem.criItem.dropOffCountryName;

            break;
          case 'CAR':
            preItemStartDate = new Date(preItem.criItem.pickupDateTime);
            if (preItem.disableStartDate) { preItemdisableDate = preItem.disableStartDate; }
            const ccCar = this.getCityCountryFromCityCode(preItem.criItem.pickupCity);
            let displayTextCar = '';
            let cityNameCar = '';
            let countryNameCar = '';
            let airportNameCar = '';
            if (ccCar != undefined) {
              cityNameCar = ccCar.split('~')[0];
              countryNameCar = ccCar.split('~')[1];
              displayTextCar = ccCar.split('~')[0] + ', ' + ccCar.split('~')[1];
            }
            if (preItem.criItem.pickupLocationName) {
              airportNameCar = preItem.criItem.pickupLocationName;
            } else if (preItem.criItem.pickUpName) {
              airportNameCar = preItem.criItem.pickupLocationName;
            } else if (preItem.criItem.returnLocationName) {
              airportNameCar = preItem.criItem.returnLocationName;
            } else if (preItem.criItem.dropName) {
              airportNameCar = preItem.criItem.dropName;
            }
            preItemDestinationStr = displayTextCar + '~' + DestinationType.CITY + '~' + preItem.criItem.pickupCity
              + '~' + cityNameCar + '~' + countryNameCar + '~' + airportNameCar + '~' + (preItem.criItem.returnCity ? preItem.criItem.returnCity : '')
              + (preItem.criItem.returnCity ? preItem.criItem.returnCity : '') + '~' + '~' + (preItem.criItem.srcCountry ? preItem.criItem.srcCountry : '');
            break;
          case 'GEN':
            if (preItem.criItem.startDate != null) {
              preItemStartDate = new Date(preItem.criItem.startDate);
            }
            if (preItem.disableStartDate) { preItemdisableDate = preItem.disableStartDate; }
            preItemDestinationStr = preItem.criItem.destinationName + ', ' + preItem.criItem.destinationParentCode + '~' + preItem.criItem.destinationType
              + '~' + preItem.criItem.destinationCode + '~' + preItem.criItem.destinationName + '~' + preItem.criItem.destinationParentCode
              + '~' + preItem.criItem.destinationCode + '~' + preItem.criItem.city + '~' + preItem.criItem.destinationName
              + '~' + preItem.criItem.country + '~' + preItem.criItem.destinationParentCode;
            break;
          case 'OWA':
            console.log(preItem.criItem);
            if (preItem.criItem.departureDate != null) {
              preItemStartDate = new Date(preItem.criItem.departureDate);
              preItemStartDate.setDate(preItemStartDate.getDate() + parseInt(preItem.criItem.duration, 10));
            }
            if (beforePreItem !== null) {
              switch (beforePreItem.productCode) {
                case 'HTL':
                  preItemDestinationStr = beforePreItem.criItem.destinationName + ', ' + beforePreItem.criItem.destinationParentCode + '~' + beforePreItem.criItem.destinationType
                    + '~' + beforePreItem.criItem.destinationCode + '~' + beforePreItem.criItem.destinationName + '~' + beforePreItem.criItem.destinationParentCode;
                  break;
                case 'FLT':
                  // if the flight has arrival airport, the the nearest parent city will be filled as the preItemDestination
                  if (beforePreItem.criItem.legs[0]) {
                    preItemDestinationStr = beforePreItem.criItem.legs[0].arrivalAirportCityName + ', ' + beforePreItem.criItem.legs[0].arrivalAirportCountryName
                      + '~' + DestinationType.CITY + '~' + beforePreItem.criItem.legs[0].arrivalAirportCity + '~' + beforePreItem.criItem.legs[0].arrivalAirportCityName
                      + '~' + beforePreItem.criItem.legs[0].arrivalAirportCountryName;

                    // location Pattern = DEPAP~DEPCITYCODE~DEPCITYNAME~DEPCOUNTRYNAME|ARRAP~ARRCITYCODE~ARRCITYNAME~ARRCOUNTRYNAME
                    preItemAirportStr = beforePreItem.criItem.legs[0].departureAirportCode + '~'
                      + beforePreItem.criItem.legs[0].departureAirportCity + '~'
                      + beforePreItem.criItem.legs[0].departureAirportCityName + '~'
                      + beforePreItem.criItem.legs[0].departureAirportCountryName
                      + '|'
                      + beforePreItem.criItem.legs[0].arrivalAirportCode + '~'
                      + beforePreItem.criItem.legs[0].arrivalAirportCity + '~'
                      + beforePreItem.criItem.legs[0].arrivalAirportCityName + '~'
                      + beforePreItem.criItem.legs[0].arrivalAirportCountryName;
                  }
                  break;
                case 'TRS':
                  const destName = beforePreItem.criItem.dropOffLocationName ? beforePreItem.criItem.dropOffLocationName : beforePreItem.criItem.dropOffCityName;
                  const destCountryName = beforePreItem.criItem.dropOffCountryName ? beforePreItem.criItem.dropOffCountryName : beforePreItem.criItem.dropOffCountry;
                  const displayTextTrs = beforePreItem.criItem.dropOffLocationName + ', ' + beforePreItem.criItem.dropOffCountryName;
                  let destType;
                  if (beforePreItem.criItem.destinationType == 40) {
                    destType = DestinationType.CITY;
                  } else if (beforePreItem.criItem.destinationType == 80) {
                    destType = DestinationType.HOTEL;
                  }
                  preItemDestinationStr = displayTextTrs + '~' + destType + '~' +
                    (beforePreItem.criItem.destinationType == 40 ?
                      beforePreItem.criItem.pickupCity : beforePreItem.criItem.destinationType == 80 ?
                        beforePreItem.criItem.dropoffLocationCode : beforePreItem.criItem.pickupCity)
                    + '~' + destName + '~' + destCountryName;

                  break;
                case 'CAR':
                  const ccCar = this.getCityCountryFromCityCode(beforePreItem.criItem.pickupCity);
                  let displayTextCar = '';
                  let cityNameCar = '';
                  let countryNameCar = '';
                  let airportNameCar = '';
                  if (ccCar != undefined) {
                    cityNameCar = ccCar.split('~')[0];
                    countryNameCar = ccCar.split('~')[1];
                    displayTextCar = ccCar.split('~')[0] + ', ' + ccCar.split('~')[1];
                  }
                  if (beforePreItem.criItem.pickupLocationName) {
                    airportNameCar = beforePreItem.criItem.pickupLocationName;
                  } else if (beforePreItem.criItem.returnLocationName) {
                    airportNameCar = beforePreItem.criItem.returnLocationName;
                  }
                  preItemDestinationStr = displayTextCar + '~' + DestinationType.CITY + '~' + beforePreItem.criItem.pickupCity
                    + '~' + cityNameCar + '~' + countryNameCar + '~' + airportNameCar;
                  break;
                case 'GEN':
                  preItemDestinationStr = beforePreItem.criItem.destinationName + ', ' + beforePreItem.criItem.destinationParentCode + '~' + beforePreItem.criItem.destinationType
                    + '~' + beforePreItem.criItem.destinationCode + '~' + beforePreItem.criItem.destinationName + '~' + beforePreItem.criItem.destinationParentCode;
                  break;
              }
            }
            break;
        }
      }
      const format = 'YYYY-MM-DDTHH:mm:ss';
      switch (event.newItemType) {
        case 'HTL':
          if (preItemStartDate != undefined && !isNaN(preItemStartDate.getTime())) {
            newItem.criItem.startDate = this.surfCriteriaUtil.convertDateObjToStr(preItemStartDate, true);
            newItem.criItem.nights = 1;
            newItem.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(preItemStartDate, true) + '~1';
          }
          newItem.disableStartDate = preItemdisableDate;
          if (!(this.GEO_ENABLE_FOR_HOTEL_SEARCH && preItem && preItem.criItem && preItem.criItem.destinationType &&
              preItem.criItem.destinationType === DestinationType.GEO_LOCATION) && preItemDestinationStr &&
            preItemDestinationStr.split('~')[0] && preItemDestinationStr.split('~')[1] &&
            preItemDestinationStr.split('~')[2] && preItemDestinationStr.split('~')[3] &&
            preItemDestinationStr.split('~')[4]) {

            newItem.criItem.destinationType = preItemDestinationStr.split('~')[1];
            if (preItemDestinationStr.split('~')[6] && preItemDestinationStr.split('~')[6] != 'null') {
              newItem.criItem.destinationCode = preItemDestinationStr.split('~')[6];
            } else {
              newItem.criItem.destinationCode = preItemDestinationStr.split('~')[2];
            }
            newItem.criItem.destinationName = preItemDestinationStr.split('~')[3];
            newItem.criItem.destinationParentCode = preItemDestinationStr.split('~')[4];
            if (!(preItem.productCode === Product.CAR || preItem.productCode === Product.GENERIC)) {
              newItem.initialDestStr = preItemDestinationStr;
            }
            if (newItem.criItem.destinationType == DestinationType.HOTEL) {
              newItem.criItem.hotelCode = preItemDestinationStr.split('~')[2];
            }
            if ( preItem.productCode === 'HTL' && newItem.criItem.destinationType === DestinationType.HOTEL) {
              newItem.criItem.hotelCode = preItemDestinationStr.split('~')[7];
            }
            // set hotel city
            if (preItemDestinationStr.split('~')[6]) {
              newItem.criItem.city = preItemDestinationStr.split('~')[6];
            } else if (this.isAmendmentFlow && preItem.productCode === 'HTL') {
              newItem.criItem.city = preItemDestinationStr.split('~')[2];
            }
            // set hotel country
            if (preItemDestinationStr.split('~')[8]) {
              newItem.criItem.country = preItemDestinationStr.split('~')[8];
            }
            if (preItemDestinationStr.split('~')[5] && preItemDestinationStr.split('~')[5] !== 'undefined') {
              newItem.criItem.pickupCity = preItemDestinationStr.split('~')[5];
            }
            if (preItemDestinationStr.split('~')[6] && preItemDestinationStr.split('~')[6] !== 'undefined') {
              newItem.criItem.dropOffCity = preItemDestinationStr.split('~')[6];
            }
          }
          break;
        case 'FLT':
          if (preItemStartDate != undefined && !isNaN(preItemStartDate.getTime())) {
            newItem.criItem.legs[0].departureDate = this.surfCriteriaUtil.convertDateObjToStr(preItemStartDate, true);
            newItem.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(preItemStartDate, true);
          }
          newItem.disableStartDate = preItemdisableDate;
          if (preItemAirportStr) {
            newItem.criItem.legs[0].departureAirportCode = preItemAirportStr.split('|')[1].split('~')[0];
            newItem.criItem.legs[0].departureAirportCity = preItemAirportStr.split('|')[1].split('~')[1];
            newItem.criItem.legs[0].departureAirportCityName = preItemAirportStr.split('|')[1].split('~')[2];
            newItem.criItem.legs[0].departureAirportCountryName = preItemAirportStr.split('|')[1].split('~')[3];
            newItem.criItem.legs[0].arrivalAirportCode = preItemAirportStr.split('|')[0].split('~')[0];
            newItem.criItem.legs[0].arrivalAirportCity = preItemAirportStr.split('|')[0].split('~')[1];
            newItem.criItem.legs[0].arrivalAirportCityName = preItemAirportStr.split('|')[0].split('~')[2];
            newItem.criItem.legs[0].arrivalAirportCountryName = preItemAirportStr.split('|')[0].split('~')[3];
            newItem.initialDestStr = preItemAirportStr;
            if (preItem.criItem.legs[0]) {
              if (preItem.criItem.legs[0].arrObj) {
                newItem.criItem.legs[0].depObj = preItem.criItem.legs[0].arrObj;
              }
              if (preItem.criItem.legs[0].depObj) {
                newItem.criItem.legs[0].arrObj = preItem.criItem.legs[0].depObj;
              }
            }
          }
          break;
        case 'TRS':
          if (preItemStartDate != undefined && !isNaN(preItemStartDate.getTime())) {
            const trsPickUpDate = new Date(preItemStartDate.getTime());
            const trsDropOffDate = new Date(preItemStartDate.getTime());
            trsPickUpDate.setHours(0);
            trsPickUpDate.setMinutes(0);
            trsDropOffDate.setHours(0);
            trsDropOffDate.setMinutes(0);

            newItem.criItem.onewayPickupTime = moment(trsPickUpDate).format(format);
            newItem.criItem.onewayDropoffTime = moment(trsDropOffDate).format(format);
            newItem.initialDateStr = newItem.criItem.onewayPickupTime;
          }
          newItem.disableStartDate = preItemdisableDate;

          if (preItemDestinationStr && preItemDestinationStr.split('~')[2] && preItemDestinationStr.split('~')[3] &&
              preItemDestinationStr.split('~')[0] && preItemDestinationStr.split('~')[0] !== 'undefined') {

            if ( preItem.productCode === Product.TRANSFERS ) {
              newItem.criItem.pickupCity = preItemDestinationStr.split('~')[6];
              newItem.criItem.dropOffCity = preItemDestinationStr.split('~')[6];
            } else {
              newItem.criItem.pickupCity = preItemDestinationStr.split('~')[2];
              newItem.criItem.dropOffCity = preItemDestinationStr.split('~')[2];
            }
            newItem.criItem.pickupLocation = preItemDestinationStr.split('~')[2];
            newItem.criItem.returnLocation = preItemDestinationStr.split('~')[2];
            if ( preItemDestinationStr.split('~')[8] ) {
              newItem.criItem.pickupCountry = preItemDestinationStr.split('~')[8];
            }
            if ( preItemDestinationStr.split('~') [7] && preItem.productCode === Product.TRANSFERS ) {
              newItem.criItem.pickupCityName = preItemDestinationStr.split('~')[7];
            }
            if (preItemDestinationStr.split('~')[6] && !preItemDestinationStr.split('~')[6].includes('undefined')
              && !preItemDestinationStr.split('~')[6].includes('null')) {

              // drop off location code has to be null for city search. Please do not set a value
              if (preItemDestinationStr.split('~')[1] &&
                (preItemDestinationStr.split('~')[1] === 'CITY' || preItemDestinationStr.split('~')[1] === 40)) {
                newItem.criItem.dropoffLocationCode = null;
                newItem.criItem.pickupLocationCode = null;
              } else {
                if (preItemDestinationStr.split('~')[1] === 'HOTEL' && preItemDestinationStr.split('~')[7] &&
                  !preItemDestinationStr.split('~')[7].includes('undefined')
                  && !preItemDestinationStr.split('~')[7].includes('null')) {
                  newItem.criItem.dropoffLocationCode = null;
                  if (preItem.productCode === Product.TRANSFERS && preItemDestinationStr.split('~')[2] &&
                    !preItemDestinationStr.split('~')[2].includes('undefined')
                    && !preItemDestinationStr.split('~')[2].includes('null')) {
                    newItem.criItem.pickupLocationCode = preItemDestinationStr.split('~')[2];
                  } else {
                    newItem.criItem.pickupLocationCode = preItemDestinationStr.split('~')[7];
                  }
                } else {
                  newItem.criItem.dropoffLocationCode = preItemDestinationStr.split('~')[6];
                  newItem.criItem.pickupLocationCode = preItemDestinationStr.split('~')[6];
                }
              }
            }
            if (preItemDestinationStr.split('~')[3] && !preItemDestinationStr.split('~')[3].includes('undefined')) {
              newItem.criItem.pickupLocationName = preItemDestinationStr.split('~')[3];
              if (preItemDestinationStr.split('~')[1] && !preItemDestinationStr.split('~')[1].includes('undefined') &&
                preItemDestinationStr.split('~')[1] === 'HOTEL') {
                if (preItem.productCode === Product.TRANSFERS && preItemDestinationStr.split('~')[7] &&
                  !preItemDestinationStr.split('~')[7].includes('undefined') &&
                  !preItemDestinationStr.split('~')[7].includes('null')) {
                  newItem.criItem.dropOffLocationName = preItemDestinationStr.split('~')[7];
                } else if (preItemDestinationStr.split('~')[4] && !preItemDestinationStr.split('~')[4].includes('undefined')) {
                  newItem.criItem.dropOffLocationName = preItemDestinationStr.split('~')[4];
                } else {
                  newItem.criItem.dropOffLocationName = preItemDestinationStr.split('~')[3];
                }
              } else {
                newItem.criItem.dropOffLocationName = preItemDestinationStr.split('~')[3];
              }
            }
            // if (preItemDestinationStr.split('~')[5] && !preItemDestinationStr.split('~')[5].includes('undefined')) {
            //   newItem.criItem.pickupCity = preItemDestinationStr.split('~')[5]
            // }
            // if (preItemDestinationStr.split('~')[6] && !preItemDestinationStr.split('~')[6].includes('undefined')) {
            //   newItem.criItem.dropOffCity = preItemDestinationStr.split('~')[6]
            // }
            newItem.initialDestStr = preItemDestinationStr.split('~')[3];
            newItem.criItem.destinationType = preItemDestinationStr.split('~')[1];
            // set hotel city
            if (preItemDestinationStr.split('~')[6]) {
              newItem.criItem.city = preItemDestinationStr.split('~')[6];
            }
            // set hotel country
            if (preItemDestinationStr.split('~')[8]) {
              newItem.criItem.country = preItemDestinationStr.split('~')[8];
            }
          }
          break;
        case 'CAR':
          if (preItemStartDate !== undefined && !isNaN(preItemStartDate.getTime())) {
            const carPickUpDate = new Date(preItemStartDate.getTime());
            const carDropOffDate = new Date(preItemStartDate.getTime());
            carPickUpDate.setHours(10);
            carPickUpDate.setMinutes(0);
            carDropOffDate.setHours(15);
            carDropOffDate.setMinutes(0);
            if (this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES &&
              this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours &&
              this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.minutes &&
              this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours &&
              this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.minutes) {
              carPickUpDate.setHours(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours);
              carPickUpDate.setMinutes(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.minutes);
              carDropOffDate.setHours(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours);
              carDropOffDate.setMinutes(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.minutes);
            }
            newItem.criItem.pickupDateTime = moment(carPickUpDate).format(format);
            newItem.criItem.returnDateTime = moment(carDropOffDate).format(format);
            newItem.initialDateStr = newItem.criItem.pickupDateTime + '~' + newItem.criItem.returnDateTime;
          }
          newItem.disableStartDate = preItemdisableDate;
          if (preItemDestinationStr && preItemDestinationStr.split('~')[2] && preItemDestinationStr.split('~')[3]) {
            // commenting this since for cars only car locations can be selected for FC context
            // if (preItem.productCode === Product.TRANSFERS && preItemDestinationStr.split('~')[1] === 'HOTEL') {
            //   newItem.criItem.pickupCity = preItemDestinationStr.split('~')[6];
            //   newItem.criItem.returnCity = preItemDestinationStr.split('~')[6];
            // } else {
            //   newItem.criItem.pickupCity = preItemDestinationStr.split('~')[2];
            //   newItem.criItem.returnCity = preItemDestinationStr.split('~')[2];
            // }
            // newItem.criItem.pickupLocation = preItemDestinationStr.split('~')[2];
            // newItem.criItem.returnLocation = preItemDestinationStr.split('~')[2];

            if (preItem.productCode === 'CAR') {
              newItem.initialDestStr = preItemDestinationStr.split('~')[5];
              if (preItem.criItem) {
                if (preItem.criItem.pickupLocation) {
                  newItem.criItem.pickupLocation = preItem.criItem.pickupLocation;
                  newItem.criItem.returnLocation = preItem.criItem.pickupLocation;
                  newItem.criItem.pickupCity = preItem.criItem.pickupCity;
                  newItem.criItem.returnCity = preItem.criItem.pickupCity;
                } else if (preItem.criItem.returnLocation) {
                  newItem.criItem.pickupLocation = preItem.criItem.returnLocation;
                  newItem.criItem.returnLocation = preItem.criItem.returnLocation;
                  newItem.criItem.pickupCity = preItem.criItem.returnCity;
                  newItem.criItem.returnCity = preItem.criItem.returnCity;
                }
              }
              // newItem.initialDestStr = preItemDestinationStr.split('~')[3];
            } else if (preItem.productCode === Product.TRANSFERS && preItemDestinationStr.split('~')[1] === 'HOTEL') {
              // commenting this since for cars only car locations can be selected for FC context
              // newItem.initialDestStr = preItemDestinationStr.split('~')[7];
            } else {
              // commenting this since for cars only car locations can be selected for FC context
              // newItem.initialDestStr = preItemDestinationStr.split('~')[3];
              // if (preItemDestinationStr.split('~')[1] === 'HOTEL') {
              //   newItem.initialDestStr = preItemDestinationStr.split('~')[4];
              // }
            }
          }
          break;
        case 'GEN':
          if (preItemStartDate != undefined && !isNaN(preItemStartDate.getTime())) {
            newItem.criItem.startDate = this.surfCriteriaUtil.convertDateObjToStr(preItemStartDate, true);
            newItem.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(preItemStartDate, true);
          }
          newItem.disableStartDate = preItemdisableDate;
          if (preItemDestinationStr && preItemDestinationStr.split('~')[0] && preItemDestinationStr.split('~')[1] &&
            preItemDestinationStr.split('~')[2] && preItemDestinationStr.split('~')[3] && preItemDestinationStr.split('~')[4]) {

            newItem.criItem.destinationType = preItemDestinationStr.split('~')[1];
            newItem.criItem.destinationCode = preItemDestinationStr.split('~')[2];
            newItem.criItem.destinationName = preItemDestinationStr.split('~')[3];
            if (newItem.criItem.destinationType === 'HOTEL') {
              newItem.criItem.destinationName = preItemDestinationStr.split('~')[4];
            }
            // set hotel city
            if (preItemDestinationStr.split('~')[6]) {
              newItem.criItem.city = preItemDestinationStr.split('~')[6];
            }
            // set hotel country
            if (preItemDestinationStr.split('~')[8]) {
              newItem.criItem.country = preItemDestinationStr.split('~')[8];
            }
            newItem.criItem.destinationParentCode = preItemDestinationStr.split('~')[4];
            newItem.initialDestStr = preItemDestinationStr.split('~')[0];
            if (!newItem.criItem.destinationName.includes('undefined')) {
              newItem.initialDestStr = newItem.criItem.destinationName;
            }
          }
          break;
        case 'OWA':
          if (preItemStartDate != undefined && !isNaN(preItemStartDate.getTime())) {
            newItem.criItem.departureDate = this.surfCriteriaUtil.convertDateObjToStr(preItemStartDate, true);
            newItem.initialDateStr = this.surfCriteriaUtil.convertDateObjToStr(preItemStartDate, true);
            newItem.disableStartDate = this.getAllowedStartDateForOwnArrangement(preItems);
          }
          break;
      }

      /*There are special case when adding a new HOTEL or OWA (Own Arrangement)
      * If there is a pre item and post item to the new hotel, it's dates should be expanded to fill the gaps*/
      if ( (event.newItemType == 'HTL' || event.newItemType === 'OWA') && this.criteriaItemList[event.preItemIndex] && this.criteriaItemList[event.postItemIndex]) {
        const postItem = this.criteriaItemList[event.postItemIndex];
        let postItemDate;

        switch (postItem.productCode) {
          case 'HTL':
            postItemDate = new Date(postItem.criItem.startDate);
            break;
          case 'FLT':
            postItemDate = new Date(postItem.criItem.legs[0].departureDate);
            break;
          case 'TRS':
            if (postItem.criItem.onewayPickupTime) {
              postItemDate = new Date(postItem.criItem.onewayPickupTime.split('T')[0]);
            }
            break;
          case 'CAR':
            if (postItem.criItem.pickupDateTime) {
              postItemDate = new Date(postItem.criItem.pickupDateTime.split('T')[0]);
            }
            break;
          case 'GEN':
            postItemDate = new Date(postItem.criItem.startDate);
            break;
        }

        if (postItemDate != undefined && !isNaN(postItemDate.getTime())) {
          // get extended nights count
          let fd = null;
          if (event.newItemType === 'HTL') {
            fd = new Date(newItem.criItem.startDate);
          } else if (event.newItemType === 'OWA') {
            fd = new Date(newItem.criItem.departureDate);
          }
          const diffTime = Math.abs(postItemDate.getTime() - fd.getTime());
          // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

          if (diffDays == 0) {
            /*this means there is no date gap between previous 2 items, therefore do not
            change nights count of the new hotel, but shift all other post items by one night*/
            let d;
            let adjustedDate;

            postItems.forEach(pi => {
              switch (pi.productCode) {
                case 'HTL':
                  // MMM d, yyyy
                  d = new Date(pi.criItem.startDate);
                  adjustedDate = this.surfCriteriaUtil.convertDateObjToStr(this.incrementDaysForJSDate(d, 1), true);
                  pi.criItem.startDate = adjustedDate;
                  pi.initialDateStr = adjustedDate + '~' + pi.criItem.nights;
                  break;
                case 'FLT':
                  d = new Date(pi.criItem.legs[0].departureDate);
                  adjustedDate = this.surfCriteriaUtil.convertDateObjToStr(this.incrementDaysForJSDate(d, 1), true);
                  if (!pi.isGrabbedLeg) {
                    pi.criItem.legs[0].departureDate = adjustedDate;
                    pi.initialDateStr = adjustedDate;
                  }
                  break;
                case 'TRS':
                  d = new Date(pi.criItem.onewayPickupTime);
                  adjustedDate = this.surfCriteriaUtil.convertDateObjToStr(this.incrementDaysForJSDate(d, 1));
                  pi.criItem.onewayPickupTime = adjustedDate + 'T' + pi.criItem.onewayPickupTime.split('T')[1];
                  if (pi.criItem.onewayDropoffTime) {
                    const dropOffTime = pi.criItem.onewayDropoffTime.split('T')[1];
                    pi.criItem.onewayDropoffTime = adjustedDate + 'T' + dropOffTime;
                  }
                  pi.initialDateStr = pi.criItem.onewayPickupTime + '~' + pi.criItem.onewayDropoffTime;
                  break;
              }
            });

          } else {
            if (postItem.productCode == 'HTL' || postItem.productCode == 'FLT' || postItem.productCode == 'TRS') { // only do this if the post item is H, F or T
              if (event.newItemType === 'HTL') {
                newItem.criItem.nights = diffDays;
                newItem.initialDateStr = newItem.criItem.startDate + '~' + diffDays;
              } else if (event.newItemType === 'OWA') {
                newItem.criItem.duration = diffDays;
                newItem.initialDateStr = newItem.criItem.departureDate;
              }
            } else {
              if (event.newItemType === 'HTL') {
                newItem.criItem.nights = 1;
                newItem.initialDateStr = newItem.criItem.startDate + '~1';
              } else if (event.newItemType === 'OWA') {
                newItem.criItem.duration = 1;
                newItem.initialDateStr = newItem.criItem.departureDate;
              }
            }
          }
        }
      }

      let finalList = [...preItems];                                                 /*1...pushing pre items first*/
      finalList.push(newItem);                                                       /*2...pushing the new item*/
      finalList = finalList.concat(postItems);                                       /*3...append post items*/

      /*reorder indexes*/
      this.reorderIndexes(finalList);

      /*Adjust indexes of criteria items*/
      this.adjustIndexes(finalList);

      this.criteriaItemList = [];
      this.criteriaItemList = [...finalList];
      if (this.isAmendmentFlow) {
        this.setInitialPaxSplitAmendNewItem(preItems.length);
      } else {
        this.setInitialPaxSplit(preItems.length);
      }
      if (this.isAmendmentFlow && ((event.preItemIndex + 1) > -1)) {
        this.updateUnfreezeMap((event.preItemIndex + 1), this.mapChangeActions.ADD);
      }
      this.DpCriteriaManageFlights.updateFlightItemsOnAdd(this.flightsGrouping, this.criteriaItemList, event.postItemIndex, this.isAmendmentFlow);
      // this.updateRelativeItemProperties();
    }

    this.emitDPCriteriaStatus();
    this.updateDisableDatesOwnArrangement();
    this.validateIsPnrItemsOnly();
  }

  reorderIndexes(itemList: CriteriaItem[]) {
    if (itemList != undefined && itemList.length > 0) {
      itemList.forEach((item, index) => {
        item.index = index;
      });
    }
  }

  emitDPCriteriaStatus() {
    if (this.criteriaItemList != undefined && this.criteriaItemList.length > 0) {
      this.commonService.changeMessage('dpCriteriaExist');
    } else {
      this.commonService.changeMessage('dpCriteriaDoesNotExist');
    }
  }

  getCityCountryFromCityCode(cityCode) {
    let city_country;
    if (this.hotelDestinations && this.hotelDestinations.length > 0) {
      this.hotelDestinations.forEach(d => {
        if (d.code == cityCode) {
          city_country = d.name + '~' + this.surfCriteriaUtil.getCountry(d);
        }
      });
    }
    if (city_country == undefined) {
      if (this.carDestinations && this.carDestinations.length > 0) {
        this.carDestinations.forEach(d => {
          if (d.code == cityCode) {
            city_country = d.name + '~' + this.surfCriteriaUtil.getCountry(d);
          }
        });
      }
    }
    return city_country;
  }

  incrementDaysForJSDate(date, dayCount): Date {
    date.setDate(date.getDate() + dayCount);
    return date;
  }

  syncCriteriaItems() {
    if (this.criteriaItemList && this.criteriaItemList.length > 0) {
      this.criteriaItemList.forEach(item => {
        if (this.productCriteriaList && this.productCriteriaList.length > 0) {
          this.productCriteriaList.forEach(pc => {
            if (item.index == pc.itemIndex) {
              item.criItem = pc;
            }
          });
        }
      });
    }
  }

  updateRelativeItemProperties() {

    if (this.criteriaItemList && this.criteriaItemList.length > 0) {
      this.criteriaItemList.forEach(item => {

        if (item.relativeItemProperties == undefined) {
          item.relativeItemProperties = {};
        }

        const preItemDate = this.getPreItemDate(item);
        if (preItemDate != null && preItemDate.length > 0) {
          item.relativeItemProperties.preItemDate = preItemDate[0];
        }
        item.relativeItemProperties.preItemLocation = this.getPreItemLocation(item);

        const postItemDate = this.getPostItemDate(item);
        if (postItemDate != null && postItemDate.length > 0) {
          item.relativeItemProperties.postItemDate = undefined;
          item.relativeItemProperties.postItemDate = postItemDate[0];
        }

      });
    }
  }

  adjustIndexes(list: any) {
    const newProductCriList = [];
    if (list && list.length > 0) {
      list.forEach(item => {
        if (item.criItem != null) {
          item.criItem.itemIndex = item.index;
          newProductCriList.push(item.criItem);
        }
      });
    }

    this.productCriteriaList = [];
    this.productCriteriaList = [...newProductCriList];
    this.commonService.updateProductCriteriaList(this.productCriteriaList);
  }

  createInitialCriItem(originalItem) {
    if (originalItem.productCode == 'HTL') {
      const temp = originalItem as HotelCriteria;
      this.initialItem = new HotelCriteria();
      this.initialItem.productCode = 'HTL';
      this.initialItem.itemIndex = 0;

      if (temp) {
        if (temp.destinationType) {
          this.initialItem.destinationType = JSON.parse(JSON.stringify(temp.destinationType));
        }
        if (temp.destinationParentCode) {
          this.initialItem.destinationParentCode = JSON.parse(JSON.stringify(temp.destinationParentCode));
        }
        if (temp.destinationCode) {
          this.initialItem.destinationCode = JSON.parse(JSON.stringify(temp.destinationCode));
        }
        if (temp.destinationName) {
          this.initialItem.destinationName = JSON.parse(JSON.stringify(temp.destinationName));
        }
        if (temp.nights) {
          this.initialItem.nights = JSON.parse(JSON.stringify(temp.nights));
        }
        if (temp.startDate) {
          this.initialItem.startDate = JSON.parse(JSON.stringify(temp.startDate));
        }
      }
    } else if (originalItem.productCode == 'CAR') {
      const temp = originalItem as CarCriteria;
      this.initialItem = new CarCriteria();
      this.initialItem.productCode = 'CAR';
      this.initialItem.itemIndex = 0;

      if (temp) {
        if (temp.destinationName) {
          this.initialItem.destinationName = JSON.parse(JSON.stringify(temp.destinationName));
        }
        if (temp.pickupCity) {
          this.initialItem.pickupCity = JSON.parse(JSON.stringify(temp.pickupCity));
        }
        if (temp.pickupLocation) {
          this.initialItem.pickupLocation = JSON.parse(JSON.stringify(temp.pickupLocation));
        }
        if (temp.returnCity) {
          this.initialItem.returnCity = JSON.parse(JSON.stringify(temp.returnCity));
        }
        if (temp.returnLocation) {
          this.initialItem.returnLocation = JSON.parse(JSON.stringify(temp.returnLocation));
        }
        if (temp.returnDateTime) {
          this.initialItem.returnDateTime = JSON.parse(JSON.stringify(temp.returnDateTime));
        }
        if (temp.pickupDateTime) {
          this.initialItem.pickupDateTime = JSON.parse(JSON.stringify(temp.pickupDateTime));
        }
        if (temp.driverAge) {
          this.initialItem.driverAge = JSON.parse(JSON.stringify(temp.driverAge));
        }
        if (temp.noOfCars) {
          this.initialItem.noOfCars = JSON.parse(JSON.stringify(temp.noOfCars));
        }
      }
    } else if (originalItem.productCode == 'TRS') {
      const temp = originalItem as TransferCriteria;
      this.initialItem = new TransferCriteria();
      this.initialItem.productCode = 'TRS';
      this.initialItem.itemIndex = 0;

      if (temp) {
        if (temp.destinationName) {
          this.initialItem.destinationName = JSON.parse(JSON.stringify(temp.destinationName));
        }
        if (temp.pickupCity) {
          this.initialItem.pickupCity = JSON.parse(JSON.stringify(temp.pickupCity));
        }
        if (temp.pickupLocation) {
          this.initialItem.pickupLocation = JSON.parse(JSON.stringify(temp.pickupLocation));
        }
        if (temp.dropOffCity) {
          this.initialItem.dropOffCity = JSON.parse(JSON.stringify(temp.dropOffCity));
        }
        if (temp.returnLocation) {
          this.initialItem.returnLocation = JSON.parse(JSON.stringify(temp.returnLocation));
        }
        if (temp.onewayDropoffTime) {
          this.initialItem.onewayDropoffTime = JSON.parse(JSON.stringify(temp.onewayDropoffTime));
        }
        if (temp.onewayPickupTime) {
          this.initialItem.onewayPickupTime = JSON.parse(JSON.stringify(temp.onewayPickupTime));
        }
      }
    } else if (originalItem.productCode == 'FLT') {
      const temp = originalItem as FlightCriteria;
      this.initialItem = new FlightCriteria();
      this.initialItem.productCode = 'FLT';
      this.initialItem.itemIndex = 0;

      if (temp) {
        if (temp.legs) {
          this.initialItem.legs = JSON.parse(JSON.stringify(temp.legs));
        }
      }
    } else if (originalItem.productCode == 'GEN') {
      const temp = originalItem as GenericCriteria;
      this.initialItem = new GenericCriteria();
      this.initialItem.productCode = 'GEN';
      this.initialItem.itemIndex = 0;

      if (temp) {
        if (temp.destinationType) {
          this.initialItem.destinationType = JSON.parse(JSON.stringify(temp.destinationType));
        }
        if (temp.destinationParentCode) {
          this.initialItem.destinationParentCode = JSON.parse(JSON.stringify(temp.destinationParentCode));
        }
        if (temp.destinationCode) {
          this.initialItem.destinationCode = JSON.parse(JSON.stringify(temp.destinationCode));
        }
        if (temp.destinationName) {
          this.initialItem.destinationName = JSON.parse(JSON.stringify(temp.destinationName));
        }
        if (temp.country) {
          this.initialItem.country = JSON.parse(JSON.stringify(temp.country));
        }
        if (temp.city) {
          this.initialItem.city = JSON.parse(JSON.stringify(temp.city));
        }
        if (temp.startDate) {
          this.initialItem.startDate = JSON.parse(JSON.stringify(temp.startDate));
        }
        if (temp.elementGroup) {
          this.initialItem.elementGroup = JSON.parse(JSON.stringify(temp.elementGroup));
        }
      }
    }
  }

  getPreItemDate(item): any {
    let dates = [];

    const currentItemIndex = item.index;

    if (currentItemIndex < 0) {
      return null;
    } else if (currentItemIndex == 0) { /*If there are no pre items, reset dates*/
      dates.push('RST_' + this.surfCriteriaUtil.convertDateObjToStr(new Date())); // set the current date as pre item date so there will be no disabled dates
      return dates;
    } else {
      /*if there are pre items, get the pre item*/
      let preItemIndex = currentItemIndex - 1;

      if (this.criteriaItemList && this.criteriaItemList.length > 0) {

        /*If the item is FLT, HTL or TRS, get dates of the immediate previous (FLT/HTL or TRS) item*/
        if (item.productCode == 'FLT' || item.productCode == 'HTL' || item.productCode == 'TRS') {
          let immediatePreItem;

          while (preItemIndex >= 0) {  /*While the pre item is a valid item*/
            this.criteriaItemList.forEach(itm => {
              if ((itm.productCode == 'FLT' || itm.productCode == 'HTL' || itm.productCode == 'TRS') && (itm.index == preItemIndex)) {
                immediatePreItem = itm;
              }
            });
            if (immediatePreItem) {
              break;
            }
            preItemIndex--;
          }

          if (immediatePreItem) {
            const data = this.pushData(immediatePreItem);
            if (data) {
              dates = data.dates;
            }
          } else {
            /*If couldnt find a HTL/FLT or TRS as immediate pre item, send other(CAR or GEN) pre item to populate dates*/
            const preItemIndex = item.index - 1;
            this.criteriaItemList.forEach(itm => {
              if (itm.index == preItemIndex) {
                const data = this.pushData(itm);
                if (data) {
                  dates = data.dates;
                }
              }
            });
          }
        } else if (item.productCode == 'CAR' || item.productCode == 'GEN') {
          this.criteriaItemList.forEach(itm => {
            if ((item.productCode == 'CAR' || item.productCode == 'GEN') && itm.index == 0) {
              const data = this.pushData(itm);
              if (data) {
                dates = data.dates;
              }
            }
          });
        }
      }

      return dates;
    }
  }

  getPostItemDate(item): any {
    let dates = [];

    const postItemIndex = item.index + 1;
    let postItem = null;

    // get the post item
    if (this.criteriaItemList && this.criteriaItemList.length > 0) {
      this.criteriaItemList.forEach(itm => {
        if (itm.index == postItemIndex) {
          postItem = itm;
        }
      });
    }

    if (postItem == null) {   /*If there are no post items*/
      return null;
    } else {
      const data = this.pushData(postItem);
      if (data) {
        dates = data.dates;
      }
      return dates;
    }
  }

  getPreItemLocation(item) {
    let location;

    const currentItemIndex = item.index;

    if (currentItemIndex <= 0) {   /*If there are no pre items*/
      return null;
    } else {
      /*if there are pre items, get the pre item*/
      const preItemIndex = currentItemIndex - 1;

      if (this.criteriaItemList && this.criteriaItemList.length > 0) {
        this.criteriaItemList.forEach(itm => {
          if (itm.index == preItemIndex) {
            const data = this.pushData(itm);
            if (data) {
              location = data.location;
            }
          }
        });
      }
    }

    return location;
  }

  pushData(itm) {
    const data = {dates: [], location: null};

    if (itm.criItem) {
      if (itm.criItem instanceof HotelCriteria) {
        let hotelDates = itm.criItem.startDate;
        const toDate = new Date(itm.criItem.startDate);
        if (itm.criItem.nights) {
          toDate.setDate(toDate.getDate() + itm.criItem.nights);
        }
        hotelDates = hotelDates + '_' + this.surfCriteriaUtil.convertDateObjToStr(toDate, true);
        data.dates.push('HTL_' + hotelDates);

        // location Pattern = TYPE~CITYCODE~CITYNAME~COUNTRYNAME
        let loc;
        if (itm.criItem.destinationType == DestinationType.CITY) {
          loc = 'HTL_CITY~';
        } else if (itm.criItem.destinationType == DestinationType.COUNTRY) {
          loc = 'HTL_COUNTRY~';
        }
        loc = loc + itm.criItem.destinationCode + '~' + itm.criItem.destinationName + '~' + itm.criItem.destinationParentCode;
        data.location = loc;
      }
      if (itm.criItem instanceof CarCriteria) {
        data.dates.push('CAR_' + itm.criItem.pickupDateTime);
        data.location = 'CAR_' + itm.criItem.pickupLocation + '~' + itm.criItem.returnLocation;
      }
      if (itm.criItem instanceof TransferCriteria) {
        data.dates.push('TRS_' + itm.criItem.onewayPickupTime);
        data.location = 'TRS_' + itm.criItem.pickupLocation + '~' + itm.criItem.returnLocation;
      }
      if (itm.criItem instanceof FlightCriteria && itm.criItem.legs && itm.criItem.legs[0]) {
        data.dates.push('FLT_' + itm.criItem.legs[0].departureDate);
        // location Pattern = DEPAP~DEPCITYCODE~DEPCITYNAME~DEPCOUNTRYNAME|ARRAP~ARRCITYCODE~ARRCITYNAME~ARRCOUNTRYNAME
        let depCityCode;
        let depCountry;
        let arrCityCode;
        let arrCountry;

        if (itm.criItem.legs[0].depObj) {
          depCityCode = this.surfCriteriaUtil.getCity(itm.criItem.legs[0].depObj).code;
          depCountry = this.surfCriteriaUtil.getCountry(itm.criItem.legs[0].depObj);
        }
        if (itm.criItem.legs[0].arrObj) {
          arrCityCode = this.surfCriteriaUtil.getCity(itm.criItem.legs[0].arrObj).code;
          arrCountry = this.surfCriteriaUtil.getCountry(itm.criItem.legs[0].arrObj);
        }

        data.location = 'FLT_' +
          itm.criItem.legs[0].departureAirportCode + '~'
          + depCityCode + '~'
          + itm.criItem.legs[0].departureAirportCity + '~'
          + depCountry
          + '|'
          + itm.criItem.legs[0].arrivalAirportCode + '~'
          + arrCityCode + '~'
          + itm.criItem.legs[0].arrivalAirportCity + '~'
          + arrCountry;

      }
      if (itm.criItem instanceof GenericCriteria) {
        data.dates.push('GEN_' + itm.criItem.startDate);
        // location Pattern = TYPE~CITYCODE~CITYNAME~COUNTRYNAME
        let loc;
        if (itm.criItem.destinationType == DestinationType.CITY) {
          loc = 'GEN_CITY~';
        } else if (itm.criItem.destinationType == DestinationType.COUNTRY) {
          loc = 'GEN_COUNTRY~';
        }
        loc = loc + itm.criItem.destinationCode + '~' + itm.criItem.destinationName + '~' + itm.criItem.destinationParentCode;
        data.location = loc;
      }
    }

    return data;
  }

  getRecentDate(dates): any {
    let recentDate = null;
    if (dates && dates.length > 0) {
      dates.forEach(date => {
        if (recentDate == null) {
          recentDate = date;
        } else {
          if (new Date(date) > new Date(recentDate)) {
            recentDate = JSON.parse(JSON.stringify(date));
          }
        }
      });
    }
    return recentDate;
  }

  onDependencyChange(event) {
    this.hotelDependencyItems = event;
    this.changingItems = '';
    if (this.hotelDependencyItems && this.hotelDependencyItems.items && this.hotelDependencyItems.items.length > 0) {
      this.hotelDependencyItems.items.forEach(di => {
        if (di.productCode == 'GEN') {
          this.changingItems += ' Generic and';
        }
        if (di.productCode == 'CAR') {
          this.changingItems += ' Car and';
        }
      });
    }
    this.changingItems = this.changingItems.substring(0, this.changingItems.length - 4) + ' item(s) which falls inside the date range of this hotel?';
    this.changingItems.trim();
    this.activeMessageBox = true;
    this.rearrangeModals();
  }

  handleDependencyWarning(event) {
    let isHotelLocation = false;
    this.criteriaItemList.forEach(item => {
      if (item.index === this.hotelDependencyItems.changeItemIndex) {
        if (item.criItem.destinationType === 'HOTEL') {
          isHotelLocation = true;
        }
      }
    });
    if (event === 'confirm') {
      this.activeMessageBox = false;
      let msg = 'resetDest_';
      if (this.hotelDependencyItems && this.hotelDependencyItems.items && this.hotelDependencyItems.items.length > 0) {
        this.hotelDependencyItems.items.forEach(di => {
          msg = msg + di.index + '~';
          this.criteriaItemList.forEach(itm => {
            if (itm.index == di.index) {
              this.unfreezeItemMap.set(itm.index, true);
              this.emitUnfreezeItem.emit({event : true, item : itm});
              if (itm.productCode == 'CAR') {
                itm.criItem.pickupCity = this.hotelDependencyItems.changeParam.split('~')[2];
                itm.criItem.pickupLocation = this.hotelDependencyItems.changeParam.split('~')[2];
                itm.criItem.returnCity = this.hotelDependencyItems.changeParam.split('~')[2];
                itm.criItem.returnLocation = this.hotelDependencyItems.changeParam.split('~')[2];
              }
              if (itm.productCode == 'GEN') {
                itm.criItem.destinationType = this.hotelDependencyItems.changeParam.split('~')[1];
                itm.criItem.destinationCode = this.hotelDependencyItems.changeParam.split('~')[2];
                itm.criItem.destinationName = this.hotelDependencyItems.changeParam.split('~')[3];
                itm.criItem.destinationParentCode = this.hotelDependencyItems.changeParam.split('~')[4];
              }
            }
          });
        });
      }

      if (isHotelLocation) {
        msg = msg + '_' + this.hotelDependencyItems.changeParam.split('~')[4];
      } else {
        msg = msg + '_' + this.hotelDependencyItems.changeParam.split('~')[0];
      }
      // change dest
      this.commonService.changeMessage(msg);
    } else if (event === 'cancel') {
      this.activeMessageBox = false;
    }
  }

  /**
   * async method to get geo location details and continue dp search
   * @params isExternal
   */
  async getGeoLocationDetailsAndContinue(isExternal) {
    const geoLocationDetails =
      await this.DpCriteriaManageFlights.processGeoLocationDetailResult(this.criteriaItemList, this.surfGeoSpatialSearchGoogleService);
    this.geoLocationDetailError = geoLocationDetails.geoDetailError;
    this.geoLocationDetailList = geoLocationDetails.geoDetailList;
    this.createDPProductCriteria(isExternal);
  }

  createDPProductCriteria(isExternal?) {
    this.dpProductCriteria = [];
    this.DpCriteriaManageFlights.updateCombinedWithIndexes(this.flightsGrouping);
    if (this.isAmendmentFlow) {
      this.criteriaItemList.forEach((item, index) => {
        item.index = index;
      });
      this.criteriaItemList = this.surfCriteriaService.sortCriItemByDate(this.criteriaItemList);
    }
    try {
      if (this.criteriaItemList && this.criteriaItemList.length > 0) {

        if (this.flightGrabPnrCriteria) {
          this.selectedGDS = this.flightGrabPnrCriteria.gdsRef;
        }
        const dpCriteriaResPack = this.DpCriteriaManageFlights.buildDPProductCriteria(
          this.criteriaItemList,
          this.dpProductCriteria,
          this.passengerAllocation,
          this.cachePaxSet,
          this.flightsGrouping,
          this.flightGrabPnrCriteria,
          this.selectedPnrNumber,
          this.selectedGDS,
          this.DEFAULT_ADULT_AGE,
          this.MAX_CHILD_AGE,
          this.MAX_TEEN_AGE,
          this.isGrabFlow,
          this.roomWiseSelected,
          this.isAmendmentFlow,
          this.unfreezeItemMap,
          this.isGetOnlyRequestPayload,
          this.adultAgeList
        );
        this.cachingPaxCriteria(this.cachePaxSet);
        this.criteriaItemList = dpCriteriaResPack.criteriaItemList;
        this.dpProductCriteria = dpCriteriaResPack.dpProductCriteria;
        this.passengerAllocation = dpCriteriaResPack.passengerAllocation;
        this.cachePaxSet = dpCriteriaResPack.cachePaxSet;


        if (!this.geoLocationDetailError) {
          this.DpCriteriaManageFlights.createPackageCriteriaPayload(
            this.dpProductCriteria,
            this.criteriaItemList,
            this.flightGrabPnrCriteria,
            this.baseCriteria,
            this.user,
            this.isAmendmentFlow,
            this.isGetOnlyRequestPayload,
            this.isEpToDpConversion,
            this.geoLocationDetailList,
            (this.GEO_ENABLE_LOCATION_OVERRIDE_SETUP ? this.tbxResultData : undefined)
          );
          if (!this.isGetOnlyRequestPayload) {
            this.proceedToResultPage(isExternal);
          } else {
            this.emitIsFinishGetOnlyRequestPayload.emit(true);
          }
        }
      }
    } catch (ex) {
       console.log(ex);
     }
  }
  hasTour() {
    const isTour = this.criteriaItemList.map(item => item.productCode).indexOf('TOU') > -1;
    this.setHasTourItem(isTour);
  }

  hasFlight() {
    const isFlight = this.criteriaItemList.map(item => item.productCode).indexOf('FLT') > -1;
    this.setHasFlightItem(isFlight);
  }

  hasMultipleFlight() {
    return this.criteriaItemList.filter(item => item.productCode === ProductType.FLT).length > 1;
  }

  /*General functions*/

  proceedToResultPage(isExternal?) {
    const basePath = '/';
    if (this.isAmendmentFlow) {
      let redirectURL = '';
      if (this.cart && this.cart.summary && this.cart.summary.id && this.cart.products) {
        // the availability of cart indicates that this is being called at booking page
        redirectURL += ('&addService=fromCart_' + this.cart.summary.id) + this.selectedPaxString;
        redirectURL = redirectURL + '&reason=' + this.amendmentReasonCode + '&cause=' + this.amendmentCauseCode +
          '&isAmendment=true&amendedProducts=' +
          this.createAmendedProductListString(this.getAmendedProducts(this.cart.products));
      } else {
        // the non-availability of cart indicates that this is being called at tailor-made page
        redirectURL += ('&addService=fromCart_' + this.cartId) + '&selectedPax=' + this.selectedPaxString;
        redirectURL = redirectURL + '&reason=' + this.amendmentReasonCode + '&cause=' + this.amendmentCauseCode +
          '&isAmendment=true&amendedProducts=' + this.amendedProducts;
      }
      redirectURL = redirectURL + '&bkgId=' + this.bookingId + '&productKey=' + this.productKey;
      window.location.href = basePath + 'b2b-tailor-made/?searchType=dp' + redirectURL;
    } else if (this.isServiceAddComponent) {
      const searchURL = basePath + 'b2b-tailor-made/?searchType=dp';
      this.getUrlCriteria.emit({url: searchURL, isExternal});
    } else {
      let fareType = '';
      if (this.flightGrabPnrCriteria) {
        fareType = this.flightGrabPnrCriteria.useGDSPriceRecord ? '&fareType=S' : '&fareType=R';
      }
      if ( this.cart && this.cart.summary && this.cart.summary.id ) {
        window.location.href = basePath + 'b2b-tailor-made/?searchType=dp' +
          (this.isGrabFlow ? ('&isPnr=true' + fareType) : '') + ('&addService=fromCart_' + this.cart.summary.id);
      } else {
        window.location.href = basePath + 'b2b-tailor-made/?searchType=dp' +
          (this.isGrabFlow ? ('&isPnr=true' + fareType) : '');
      }
    }
  }

  resetCriteria() {
    if (this.criteriaItemList && this.criteriaItemList.length > 0) {

      const grabbedFlightItems = this.criteriaItemList.filter(item => item.productCode == 'FLT' && item.isGrabbedLeg);
      const grabbedTourItems = this.criteriaItemList.filter(item => item.productCode == 'TOU' && item.isGrab);

      if (grabbedFlightItems && grabbedFlightItems.length > 0) {
        /*if there are grabbed flight items, reset all the criteria*/
        this.showDPGrabResetWarning = true;
      } else if ( grabbedTourItems && grabbedTourItems.length > 0) {
        /*if there are grabbed tour items, reset all the criteria*/
        this.showDPTourGrabResetWarning = true;
      } else {
        this.criteriaItemList.forEach(item => {
          item.valid = false;
          item.disableStartDate = undefined;
          item.initialDateStr = undefined;
          item.initialDestStr = undefined;
          item.gapWithChangedItem = 0;
          switch (item.productCode) {
            case 'HTL':
              if (item.criItem) {
                item.criItem.destinationCode = undefined;
                item.criItem.destinationName = undefined;
                item.criItem.destinationParentCode = undefined;
                item.criItem.destinationType = undefined;
                item.criItem.nights = undefined;
                item.criItem.startDate = undefined;
              }
              break;
            case 'FLT':
              if (item.criItem && item.criItem.legs && item.criItem.legs[0]) {
                item.criItem.legs[0].airlineCode = undefined;
                item.criItem.legs[0].arrivalAirportCity = undefined;
                item.criItem.legs[0].arrivalAirportCityName = undefined;
                item.criItem.legs[0].arrivalAirportCode = undefined;
                item.criItem.legs[0].arrivalAirportCountryName = undefined;
                item.criItem.legs[0].cabinClass = undefined;
                item.criItem.legs[0].departureAirportCity = undefined;
                item.criItem.legs[0].departureAirportCityName = undefined;
                item.criItem.legs[0].departureAirportCode = undefined;
                item.criItem.legs[0].departureAirportCountryName = undefined;
                item.criItem.legs[0].departureDate = undefined;
              }

              break;
            case 'TRS':
              if (item.criItem) {
                item.criItem.dropOffCity = undefined;
                item.criItem.onewayDropoffTime = undefined;
                item.criItem.onewayPickupTime = undefined;
                item.criItem.pickupCity = undefined;
                item.criItem.pickupLocation = undefined;
                item.criItem.returnLocation = undefined;
              }
              break;
            case 'CAR':
              if (item.criItem) {
                item.criItem.pickupCity = undefined;
                item.criItem.pickupDateTime = undefined;
                item.criItem.pickupLocation = undefined;
                item.criItem.pickupLocationName = undefined;
                item.criItem.returnCity = undefined;
                item.criItem.returnDateTime = undefined;
                item.criItem.returnLocation = undefined;
                item.criItem.returnLocationName = undefined;
              }
              break;
            case 'GEN':
              if (item.criItem) {
                item.criItem.destinationCode = undefined;
                item.criItem.destinationName = undefined;
                item.criItem.destinationParentCode = undefined;
                item.criItem.destinationType = undefined;
                item.criItem.startDate = undefined;
                item.criItem.duration = undefined;
                item.criItem.elementGroup = undefined;
              }
              break;
            case 'TOU':
              if (item.criItem) {
                item.criItem.tourDestCities = undefined;
                item.criItem.city = undefined;
                item.criItem.country = undefined;
                item.criItem.destinationCode = undefined;
                item.criItem.destinationName = undefined;
                item.criItem.itineraryName = undefined;
                item.criItem.startDate = undefined;
                item.criItem.startMonth = undefined;
                item.criItem.startYear = undefined;
                item.criItem.supplier = undefined;
                item.criItem.durationRange = undefined;
                item.criItem.tourCategory = undefined;
              }
              break;
          }
        });
        this.cachePaxSet = [new PaxAllocationItem(2, [], [], [], [])];
        this.commonService.changeMessage('triggerReset');
      }
    }
    this.cachePaxSet = [new PaxAllocationItem(2, [], [], [], [])];
    // reset pax split
    if (this.criteriaItemList.length > 0) {
      this.criteriaItemList.forEach((item, index) => {
        this.setInitialPaxSplit(index);
      });
    }
  }

  getStartTimeOfTheDay(dateString: string): string {
    if (!dateString.includes(dateString)) { return dateString; }
    return dateString.slice(0, dateString.indexOf('T') + 1) + '00:00:00';
  }


  /**
   * returns a string that contains productKeys of all the passed products
   * @param cart
   */
  createAmendedProductListString(products: any) {
    let str = '';
    if (products.length > 0) {
      products.forEach((product) => {
        if (product.productCode === 'TMD') {
          str += product.productKey;
          str += '_';
          if (product.products.length > 0) {
            product.products.forEach((subProduct) => {
              str += subProduct.productKey;
              str += ',';
            });
            // removes last comma
            str = str.slice(0, -1);
          }
        } else {
          str += product.productKey;
        }
        str += '__';
      });
      // removes last 2 underscores
      str = str.slice(0, -2);
    }
    return str;
  }
  getAmendedProducts(products: any) {
    /**
     * For now no filtration is there because item wise amendment is not implemented yet
     * Own arrangements are not added temporarily
     * Todo: implement the filtration that removes non-amended items
     */
    const productsArray = products;
    if (productsArray && productsArray.length === 1 && productsArray[0].productCode === 'TMD') {
      const filteredProducts = [];
      productsArray[0].products.forEach((prod) => {
        if (prod.productCode !== 'OWA') { filteredProducts.push(prod); }
      });
      productsArray[0].products = filteredProducts;
    }
    return productsArray;
  }

  generateDisplayString(adult, child, infant, teen) {

    // should handle
    let str = '';

    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    return str;
  }

  manageFlights() {
    const valid = this.criteriaItemList
        .filter((item) =>  (item.productCode === ProductType.FLT))
        .every(item => (item.valid));
    if (valid) {
      this.manageFlightIncompleteFields = false;
      this.manageFlightsModalDisplay = true;
      this.renderer.addClass(document.body, 'u-unscrollable');
    } else {
      this.manageFlightIncompleteFields = true;
    }
  }

  ManageFlightsCancel() {
    this.DpCriteriaManageFlights.resetGroupDetails(this.flightsGrouping);
    this.manageFlightsModalDisplay = false;
    this.renderer.removeClass(document.body, 'u-unscrollable');
  }

  ManageFlightsConfirm() {
    this.DpCriteriaManageFlights.confirmSelectedGroupDetails(this.flightsGrouping, this.criteriaItemList);
    this.manageFlightsModalDisplay = false;
    this.renderer.removeClass(document.body, 'u-unscrollable');
  }

  getFlightGroupDetail(index: number): FlightGroupDetail {
    return this.flightsGrouping.find((item) => (item.itineraryItemNo === index));
  }

  /**
   * This is to fix the UI issue which happens
   * when the modals are not being added in the body level
   * this will append modals in this component to the HTML <body></body>
   */
  rearrangeModals() {
    const modalsIds = [];
    modalsIds.push(this.MANAGE_FLIGHTS_MODAL_ID);
    modalsIds.push(this.DP_GRAB_RESET_WARNING_MODAL_ID);
    modalsIds.push(this.DP_GRAB_RESET_WARNING_MODAL_ID_NEW);
    modalsIds.push(this.AMENDMENT_MODAL_ID);
    modalsIds.push(this.AMENDMENT_DEPENDENCY_MODAL_ID);

    modalsIds.forEach((modalId) => {
      if (document.getElementById((modalId))) {
        document.body.appendChild(document.getElementById((modalId)));
      }
    });
  }

  removeModals() {
    const modalsIds = [];
    modalsIds.push(this.MANAGE_FLIGHTS_MODAL_ID);
    modalsIds.push(this.DP_GRAB_RESET_WARNING_MODAL_ID);
    modalsIds.push(this.DP_GRAB_RESET_WARNING_MODAL_ID_NEW);
    modalsIds.push(this.AMENDMENT_DEPENDENCY_MODAL_ID);

    modalsIds.forEach((modalId) => {
      if (document.getElementById((modalId))) {
        document.body.removeChild(document.getElementById((modalId)));
      }
    });
  }

  tourGrabResultOutput( event ): void {
    if (event && event.length > 0) {
      this.criteriaItemList = [];
      this.grabbedTourId = event[0].grabbedTourId;
      this.isGrabFlow = true;
      this.setHasTourItem(true);


      const grabResultPack = this.dpCriteriaManageTour.mapFromGrabCriteria(
        event,
        this.criteriaItemList,
        this.grabPax,
        this.cachePaxSet,
        this.passengerAllocation
      );
      sessionStorage.setItem('paxSet', JSON.stringify(grabResultPack.cachePaxSet));
      this.criteriaItemList = grabResultPack.criteriaItemList;
      this.grabbedCriteriaItemList = grabResultPack.criteriaItemList;
      this.grabPax = grabResultPack.grabPax;
      this.cachePaxSet = grabResultPack.cachePaxSet;
      this.passengerAllocation = grabResultPack.passengerAllocation;
      grabResultPack.grabPax.forEach(itemPax => {
        if (itemPax.profile.type === 'A') {
          if (itemPax.profile.age != 0) {
            this.adultAgeList.push(itemPax.profile.age);
          } else {
            this.adultAgeList.push(Number(this.DEFAULT_ADULT_AGE));
          }
        }
      });
    } else {
      this.grabbedCriteriaItemList = [];
      this.criteriaItemList = [];
      this.isGrabFlow = false;
      this.refineGrab = false;
      this.getCachedPaxCriteria();
    }
    this.validateIsPnrItemsOnly();
  }
  setGrabPNRCriteria(event: PnrComponentOutput, multiCarriers?: boolean): void {
    if (this.DpCriteriaManageFlights.isValidGrabCriteria(event)) {
      this.criteriaItemList = [];
      this.flightsGrouping = [];
      this.flightGrabPnrCriteria = event.flightGrabPnrCriteria;
      sessionStorage.setItem('pnrCriteria', JSON.stringify(this.flightGrabPnrCriteria));
      this.selectedPnrNumber = event.pnrNumber;
      this.isGrabFlow = true;
      this.setHasFlightItem(true);

      if (multiCarriers) {
        this.pnrType = GRAB_PNR_TYPE.CRITERIA;
        this.stageChangeTrigger = PNR_STAGES.GRAB_COMPLETE;
      }

      const grabResultPack = this.DpCriteriaManageFlights.mapFromGrabCriteria(
        event,
        this.criteriaItemList,
        this.flightsGrouping,
        this.grabPax,
        this.cachePaxSet,
        this.passengerAllocation
      );
      sessionStorage.setItem('paxSet', JSON.stringify(grabResultPack.cachePaxSet));
      this.criteriaItemList = grabResultPack.criteriaItemList;
      this.grabbedCriteriaItemList = grabResultPack.criteriaItemList;
      this.flightsGrouping = grabResultPack.flightsGrouping;
      this.grabPax = grabResultPack.grabPax;
      this.cachePaxSet = grabResultPack.cachePaxSet;
      this.passengerAllocation = grabResultPack.passengerAllocation;
      grabResultPack.grabPax.forEach(itemPax => {
        if (itemPax.profile.type === 'A') {
          if (itemPax.profile.age != 0) {
            this.adultAgeList.push(itemPax.profile.age);
          } else {
            this.adultAgeList.push(Number(this.DEFAULT_ADULT_AGE));
          }
        }
      });
    } else {
      this.grabbedCriteriaItemList = [];
      this.criteriaItemList = [];
      this.isGrabFlow = false;
      this.refineGrab = false;
      this.getCachedPaxCriteria();
    }
    this.validateIsPnrItemsOnly();
  }

  resetPnrComponent() {
    // const pnrMockOutput = new PnrComponentOutput();
    // pnrMockOutput.pnrStage = PNR_STAGES.DEFAULT;
    // this.setGrabPNRCriteria(pnrMockOutput);
    this.pnrReset = true;
    setTimeout( () => {
      this.pnrReset = false;
    }, 10);
  }

  continueResetGrab(event) {
    if (event == 'confirm') {
      this.grabbedTourId = null;
      this.selectedPnrNumber = null;
      this.showDPGrabResetWarning = false;
      this.showDPTourGrabResetWarning = false;
      this.criteriaItemList = [];
      this.commonService.changeMessage('dpCriteriaDoesNotExist');
      this.resetPnrComponent();
      this.isGrabFlow = false;
      this.cachePaxSet = [new PaxAllocationItem(2, [], [], [], [])];
    } else if (event == 'cancel') {
      this.showDPGrabResetWarning = false;
      this.showDPTourGrabResetWarning = false;

    }
  }

  // This method takes pre-CriteriaItem list and traverse it from the bottom until first occurrence HTL or FLT is found
  // Returns the last date of HTL or departure date of FLT to be set as the allowed date for the own arrangement
  getAllowedStartDateForOwnArrangement(criteriaItemList: any[]): string {
    const reverseCriteriaAarray = criteriaItemList.slice().reverse();
    let disableDate = null;
    reverseCriteriaAarray.forEach( (criteria) => {
      if (criteria.productCode) {
        switch (criteria.productCode) {
          case ProductType.HTL:
            const hotelEndDate = new Date(criteria.criItem.startDate);
            hotelEndDate.setDate(hotelEndDate.getDate() + parseInt(criteria.criItem.nights, 10));
            disableDate = disableDate ? disableDate : this.surfCriteriaUtil.convertDateObjToStr(hotelEndDate, false);
            break;
          case ProductType.FLT:
            console.log(criteria.criItem.legs[0].departureDate);
            if (criteria.criItem.legs && criteria.criItem.legs.length > 0 && criteria.criItem.legs[0].departureDate) {
              disableDate = disableDate ? disableDate : criteria.criItem.legs[0].departureDate;
            }
            break;
        }
      }
    });
    return disableDate;
  }

  // this method iterates the criyteria list and updates the disable dates of own arrangement objects
  updateDisableDatesOwnArrangement() {
    this.criteriaItemList.forEach( (criteria, index) => {
      if (criteria.productCode === 'OWA') {
        criteria.disableStartDate = this.getAllowedStartDateForOwnArrangement(this.criteriaItemList.slice(0, index));
      }
    });
  }

  loadAndDeletePartialCriteria() {
    if (sessionStorage.getItem('partialCriteria')) {
      this.criteriaItemList.push(JSON.parse(sessionStorage.getItem('partialCriteria')));
      // this.commonService.updateProductCriteriaList(JSON.parse(sessionStorage.getItem('partialCriteria')));
      sessionStorage.removeItem('partialCriteria');
    }
  }

  /**
   * check if there are any newly added items after grabbing
   * msg change is use to show alert when change PNR
   */
  validateIsPnrItemsOnly() {
    if (this.criteriaItemList.length > this.grabbedCriteriaItemList.length) {
      this.commonService.changeMessage('dpCriteriaExist');
    } else {
      this.commonService.changeMessage('dpCriteriaDoesNotExist');
    }
  }

  /**
   * need to identify selected PNR in refine page
   */
  setSelectedPnrInRefine() {
    const reqPayload = (JSON.parse(sessionStorage.getItem('requestPayload'))) as any;
    if (reqPayload && reqPayload.payload && reqPayload.payload.productCriteria && reqPayload.payload.productCriteria.length > 0) {
      const pnrItem = reqPayload.payload.productCriteria.filter(item => {
        return (item.grabPNR && item.grabPNR.pnr);
      })[0];
      if (pnrItem) {
        this.selectedPnrNumber = pnrItem.grabPNR.pnr;
        this.selectedGDS = pnrItem.grabPNR.gdsRef? pnrItem.grabPNR.gdsRef : '';
      }
    }
    const pnrCriteria = (JSON.parse(sessionStorage.getItem('pnrCriteria'))) as any;
    if (pnrCriteria) {
      this.flightGrabPnrCriteria = pnrCriteria;
    }
  }

  /**
   * set initial unfreeze items map
   * use to create item wise freeze unfreeze tick and unfreeze msg modal
   */
  setInitialUnfreezeMap() {
    if (!this.isRefine) {
      let isTailorMadeProduct = false;
      let selectedCriteria;
      let productType;
      if (this.productKey) {
        productType = this.productKey.split('~')[0];
      }
      if (this.amendProductKey.split('~')[0] === Product.DP_PACKAGE) {
        isTailorMadeProduct = true;
      } else {
        selectedCriteria = this.criteriaItemList.filter(item => {
          return this.amendProductKey === item.productKey;
        })[0];
      }
      if (this.criteriaItemList.length > 0) {
        this.criteriaItemList.forEach((item, index) => {
          if (isTailorMadeProduct) {
            // if (item.productCode === Product.FLIGHT) {
            //   this.unfreezeItemMap.set(index, false);
            // } else {
            //   this.unfreezeItemMap.set(index, true);
            // }
            this.unfreezeItemMap.set(index, false);
          } else {
            if (selectedCriteria === item && productType !== Product.DP_PACKAGE) {
              this.unfreezeItemMap.set(index, true);
            } else {
              this.unfreezeItemMap.set(index, false);
            }
          }
          this.initialItemMap.set(item, index);
        });
      }
    } else {
      const payloadData = JSON.parse(sessionStorage.getItem('requestPayload')).payload.productCriteria;
      this.criteriaItemList.forEach((item, index) => {
        const attribute = payloadData[index].attributes.filter(attr => {
          return attr.name === 'criteriaUpdateOption';
        })[0];
        if (attribute && attribute.value === 'DUMMY') {
          this.unfreezeItemMap.set(index, false);
        } else {
          this.unfreezeItemMap.set(index, true);
        }
        this.initialItemMap.set(item, index);
      });
    }
  }

  /**
   * update initial unfreeze items map when add new criteria item to dp or remove new item from dp (amendment flow)
   * @param position - changing item index, action - ADD/REMOVE
   */
  updateUnfreezeMap(position, action) {
    const newUnfreezeMap = new Map();
    if (action === this.mapChangeActions.ADD) {
      if (position > -1 && position < this.criteriaItemList.length) {
        this.criteriaItemList.forEach((item, index) => {
          if (index < position) {
            newUnfreezeMap.set(index, this.unfreezeItemMap.get(index));
          } else if (index > position) {
            newUnfreezeMap.set(index, this.unfreezeItemMap.get((index - 1)));
          } else {
            newUnfreezeMap.set(index, true);
          }
        });
        this.unfreezeItemMap = newUnfreezeMap;
      }
    } else if (action === this.mapChangeActions.REMOVE) {
      this.criteriaItemList.forEach((item, index) => {
        if (index < position) {
          newUnfreezeMap.set(index, this.unfreezeItemMap.get(index));
        } else {
          newUnfreezeMap.set(index, this.unfreezeItemMap.get((index + 1)));
        }
      });
      this.unfreezeItemMap = newUnfreezeMap;
    }
  }


  /**
   * need to add old criteria to the criteria items to highlight changes only in amendment flow
   */
  setOldCriteria() {
    this.criteriaItemList.forEach(item => {
      if (!item.oldCriteriaItem) {
        item.oldCriteriaItem = JSON.parse(JSON.stringify(item));
      }
    });
  }

  /**
   * emit if a criteria inside the package changes its freeze/unfreeze status
   */
  passUnfreezeItem(event, item) {
    this.unfreezeItemMap.set(item.index, event);
    this.emitUnfreezeItem.emit({event, item});
  }

  /**
   * dependency identification in dp amendment flow - pop up handling
   * @param changeItem - user changed item
   */
  handleAmendmentDependencyIdentification(changeItem) {
    if (changeItem.productCode === Product.ACCOMMODATION || changeItem.productCode === Product.FLIGHT || changeItem.productCode === Product.TRANSFERS) {
      const typeArray = changeItem.amendmentChangeType.toString().split('~');
      this.impactedItemList = [];
      this.preItemDependencyIdentification(changeItem.index, typeArray);
    } else {
      this.updateCriteriaItemList(changeItem);
    }
  }

  /**
   * identify pre item dependencies in dp amendment flow
   * @param position - changed item index
   * @param types - array of ImpactTypes(enum)
   */
  preItemDependencyIdentification(position, types) {
    if (types.length > 0) {
      const changedItem = this.criteriaItemList[position];
      this.criteriaItemList.forEach((criteria, index) => {
        if (index !== position && !this.unfreezeItemMap.get(index)) {
          if (changedItem.productCode === Product.ACCOMMODATION) {
            if (types[(types.length - 1)] === ImpactTypes.DESTINATION) {
              switch (criteria.productCode) {
                case Product.TRANSFERS: {
                  if ((index < position && criteria.criItem.dropoffLocationType === 'HOTEL') ||
                    (index > position && criteria.criItem.pickupLocationType === 'HOTEL')) {
                    let isTransferHaveMiddle = false;
                    if (index < position) {
                      for (let i = position; i > index; i--) {
                        if (this.criteriaItemList[i].productCode === Product.TRANSFERS) {
                          isTransferHaveMiddle = true;
                          break;
                        }
                      }
                    } else {
                      for (let i = position; i < index; i++) {
                        if (this.criteriaItemList[i].productCode === Product.TRANSFERS) {
                          isTransferHaveMiddle = true;
                          break;
                        }
                      }
                    }
                    if (!isTransferHaveMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.TRANSFERS, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.onewayPickupTime.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
              }
            } else if (types[(types.length - 1)] === ImpactTypes.START_DATE) {
              switch (criteria.productCode) {
                case Product.TRANSFERS: {
                  if (index < position && criteria.criItem.dropoffLocationType === 'HOTEL') {
                    let isTransferHaveMiddle = false;
                    for (let i = position; i > index; i--) {
                      if (this.criteriaItemList[i].productCode === Product.TRANSFERS) {
                        isTransferHaveMiddle = true;
                        break;
                      }
                    }
                    if (!isTransferHaveMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.TRANSFERS, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.onewayPickupTime.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
                case Product.FLIGHT: {
                  if (index < position) {
                    let isFlightHaveInMiddle = false;
                    for (let i = position; i > index; i--) {
                      if (this.criteriaItemList[i].productCode === Product.FLIGHT) {
                        isFlightHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isFlightHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.FLIGHT, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.legs[0].departureDate.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
              }
            } else if (types[(types.length - 1)] === ImpactTypes.END_DATE) {
              switch (criteria.productCode) {
                case Product.TRANSFERS: {
                  if (index > position) {
                    const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                    amendmentImpactedItem.criItemIndex = index;
                    amendmentImpactedItem.ProductType = this.getProductName(Product.TRANSFERS, index);
                    amendmentImpactedItem.isPreItem = (index < position);
                    amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.onewayPickupTime.toString().split('T')[0]);
                    if (this.isAmendmentFlow) {
                      amendmentImpactedItem.changeToDate = this.getDependencyChangeToDateString(criteria, this.criteriaItemList[position]);
                    }
                    this.impactedItemList.push(amendmentImpactedItem);
                  }
                  break;
                }
                case Product.FLIGHT: {
                  if (index > position) {
                    const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                    amendmentImpactedItem.criItemIndex = index;
                    amendmentImpactedItem.ProductType = this.getProductName(Product.FLIGHT, index);
                    amendmentImpactedItem.isPreItem = (index < position);
                    amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.legs[0].departureDate.toString().split('T')[0]);
                    this.impactedItemList.push(amendmentImpactedItem);
                  }
                  break;
                }
                case Product.ACCOMMODATION: {
                  if (index > position) {
                    const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                    amendmentImpactedItem.criItemIndex = index;
                    amendmentImpactedItem.ProductType = this.getProductName(Product.ACCOMMODATION, index);
                    amendmentImpactedItem.isPreItem = (index < position);
                    amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.startDate.toString().split('T')[0]);
                    if (this.isAmendmentFlow) {
                      amendmentImpactedItem.changeToDate = this.getDependencyChangeToDateString(criteria, this.criteriaItemList[position]);
                    }
                    this.impactedItemList.push(amendmentImpactedItem);
                  }
                  break;
                }
              }
            }
          } else if (changedItem.productCode === Product.TRANSFERS) {
            if (types[(types.length - 1)] === ImpactTypes.PICKUP_LOCATION) {
              switch (criteria.productCode) {
                case Product.ACCOMMODATION: {
                  if (index < position && this.criteriaItemList[position].criItem.pickupLocationType === 'HOTEL') {
                    let isHotelHaveInMiddle = false;
                    for (let i = position; i > index; i--) {
                      if (this.criteriaItemList[i].productCode === Product.ACCOMMODATION) {
                        isHotelHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isHotelHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.ACCOMMODATION, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.startDate.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
                case Product.FLIGHT: {
                  if (index < position && this.criteriaItemList[position].criItem.pickupLocationType === 'AIRPORT') {
                    let isFlightHaveInMiddle = false;
                    for (let i = position; i > index; i--) {
                      if (this.criteriaItemList[i].productCode === Product.FLIGHT) {
                        isFlightHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isFlightHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.FLIGHT, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.legs[0].departureDate.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
              }
            } else if (types[(types.length - 1)] === ImpactTypes.DROP_OFF_LOCATION) {
              switch (criteria.productCode) {
                case Product.ACCOMMODATION: {
                  if (index > position && this.criteriaItemList[position].criItem.dropoffLocationType === 'HOTEL') {
                    let isHotelHaveInMiddle = false;
                    for (let i = position; i < index; i++) {
                      if (this.criteriaItemList[i].productCode === Product.ACCOMMODATION) {
                        isHotelHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isHotelHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.ACCOMMODATION, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.startDate.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
                case Product.FLIGHT: {
                  if (index > position && this.criteriaItemList[position].criItem.dropoffLocationType === 'AIRPORT') {
                    let isFlightHaveInMiddle = false;
                    for (let i = position; i < index; i++) {
                      if (this.criteriaItemList[i].productCode === Product.FLIGHT) {
                        isFlightHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isFlightHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.FLIGHT, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.legs[0].departureDate.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
              }
            } else if (types[(types.length - 1)] === ImpactTypes.START_DATE) {
              switch (criteria.productCode) {
                case Product.ACCOMMODATION: {
                  if ((index < position && this.criteriaItemList[position].criItem.pickupLocationType === 'HOTEL') ||
                    (index > position && this.criteriaItemList[position].criItem.dropoffLocationType === 'HOTEL')) {
                    let isHotelHaveInMiddle = false;
                    if (index < position) {
                      for (let i = position; i > index; i--) {
                        if (this.criteriaItemList[i].productCode === Product.ACCOMMODATION) {
                          isHotelHaveInMiddle = true;
                          break;
                        }
                      }
                    } else {
                      for (let i = position; i < index; i++) {
                        if (this.criteriaItemList[i].productCode === Product.ACCOMMODATION) {
                          isHotelHaveInMiddle = true;
                          break;
                        }
                      }
                    }
                    if (!isHotelHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.ACCOMMODATION, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.startDate.toString().split('T')[0])
                      if (this.isAmendmentFlow && index > position) {
                        amendmentImpactedItem.changeToDate = this.getDependencyChangeToDateString(criteria,this.criteriaItemList[position]);
                      }
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
                case Product.FLIGHT: {
                  if ((index < position && this.criteriaItemList[position].criItem.pickupLocationType === 'AIRPORT') ||
                    (index > position && this.criteriaItemList[position].criItem.dropoffLocationType === 'AIRPORT')) {
                    let isFlightHaveInMiddle = false;
                    if (index < position) {
                      for (let i = position; i > index; i--) {
                        if (this.criteriaItemList[i].productCode === Product.FLIGHT) {
                          isFlightHaveInMiddle = true;
                          break;
                        }
                      }
                    } else {
                      for (let i = position; i < index; i++) {
                        if (this.criteriaItemList[i].productCode === Product.FLIGHT) {
                          isFlightHaveInMiddle = true;
                          break;
                        }
                      }
                    }
                    if (!isFlightHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.FLIGHT, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.legs[0].departureDate.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
              }
            }
          } else if (changedItem.productCode === Product.FLIGHT) {
            if (types[(types.length - 1)] === ImpactTypes.PICKUP_LOCATION) {
              switch (criteria.productCode) {
                case Product.TRANSFERS: {
                  if (index < position && criteria.criItem.dropoffLocationType === 'AIRPORT') {
                    let isTransferHaveInMiddle = false;
                    for (let i = position; i > index; i--) {
                      if (this.criteriaItemList[i].productCode === Product.TRANSFERS) {
                        isTransferHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isTransferHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.TRANSFERS, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.onewayPickupTime.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
              }
            } else if (types[(types.length - 1)] === ImpactTypes.DROP_OFF_LOCATION) {
              switch (criteria.productCode) {
                case Product.TRANSFERS: {
                  if (index > position && criteria.criItem.pickupLocationType === 'AIRPORT') {
                    let isTransferHaveInMiddle = false;
                    for (let i = position; i < index; i++) {
                      if (this.criteriaItemList[i].productCode === Product.TRANSFERS) {
                        isTransferHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isTransferHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.TRANSFERS, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.onewayPickupTime.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
              }
            } else if (types[(types.length - 1)] === ImpactTypes.START_DATE) {
              switch (criteria.productCode) {
                case Product.TRANSFERS: {
                  if (index > position) {
                    const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                    amendmentImpactedItem.criItemIndex = index;
                    amendmentImpactedItem.ProductType = this.getProductName(Product.TRANSFERS, index);
                    amendmentImpactedItem.isPreItem = (index < position);
                    amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.onewayPickupTime.toString().split('T')[0]);
                    this.impactedItemList.push(amendmentImpactedItem);
                  }
                  if (index < position && criteria.criItem.dropoffLocationType === 'AIRPORT') {
                    let isTransferHaveInMiddle = false;
                    for (let i = position; i > index; i--) {
                      if (this.criteriaItemList[i].productCode === Product.TRANSFERS) {
                        isTransferHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isTransferHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.TRANSFERS, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.onewayPickupTime.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
                case Product.ACCOMMODATION: {
                  if (index > position) {
                    const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                    amendmentImpactedItem.criItemIndex = index;
                    amendmentImpactedItem.ProductType = this.getProductName(Product.ACCOMMODATION, index);
                    amendmentImpactedItem.isPreItem = (index < position);
                    amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.startDate.toString().split('T')[0]);
                    this.impactedItemList.push(amendmentImpactedItem);
                  }
                  if (index < position) {
                    let isHotelHaveInMiddle = false;
                    for (let i = position; i > index; i--) {
                      if (this.criteriaItemList[i].productCode === Product.ACCOMMODATION) {
                        isHotelHaveInMiddle = true;
                        break;
                      }
                    }
                    if (!isHotelHaveInMiddle) {
                      const amendmentImpactedItem: AmendmentImpactedItem = new AmendmentImpactedItem();
                      amendmentImpactedItem.criItemIndex = index;
                      amendmentImpactedItem.ProductType = this.getProductName(Product.ACCOMMODATION, index);
                      amendmentImpactedItem.isPreItem = (index < position);
                      amendmentImpactedItem.date = this.getDependencyDateString(criteria.criItem.startDate.toString().split('T')[0]);
                      this.impactedItemList.push(amendmentImpactedItem);
                    }
                  }
                  break;
                }
              }
            }
          }
        }
      });
      types.pop();
      this.preItemDependencyIdentification(position, types);
    } else {
      if (this.impactedItemList.length > 0) {
        this.showAmendmentDependencyModal = true;
      } else {
        this.updateCriteriaItemList(this.recentlyChangedItem);
      }
    }
  }

  /**
   * return date string (date Month)
   * @param date - date string
   * return converted date string
   */
  getDependencyChangeToDateString(impactItemCriteria: any, changeItemCriteria: any): string {
     let changeToDate = '';
     let gapWithChangedItem = 0;
     if (this.isAmendmentFlow
       && ( changeItemCriteria?.productCode === Product.TRANSFERS || changeItemCriteria?.productCode === Product.ACCOMMODATION )
       && impactItemCriteria.oldCriteriaItem?.criItem
       && changeItemCriteria.oldCriteriaItem?.criItem) {
       if(changeItemCriteria?.productCode === Product.TRANSFERS) {
         gapWithChangedItem = this.getJSDateDifference(
           new Date(impactItemCriteria.oldCriteriaItem.criItem.startDate),
           new Date(changeItemCriteria.oldCriteriaItem.criItem.onewayPickupTime));
           changeToDate = (new Date(( new Date(changeItemCriteria.criItem.onewayPickupTime)).getTime()
                    + gapWithChangedItem*24*3600*1000 )).toString();
       } else if (changeItemCriteria?.productCode === Product.ACCOMMODATION) {
          if (impactItemCriteria?.productCode === Product.ACCOMMODATION) {
                   gapWithChangedItem = this.getJSDateDifference(
           (new Date(( new Date(changeItemCriteria.oldCriteriaItem.criItem.startDate)).getTime()
                    + Number(changeItemCriteria.oldCriteriaItem.criItem.nights)*24*3600*1000 )),
           new Date(impactItemCriteria.oldCriteriaItem.criItem.startDate));
            changeToDate = (new Date((( new Date(changeItemCriteria.criItem.startDate)).getTime()
              + Number(changeItemCriteria.criItem.nights)*24*3600*1000
              + gapWithChangedItem*24*3600*1000 ))).toString();
          } else if (impactItemCriteria?.productCode === Product.TRANSFERS) {
            gapWithChangedItem = this.getJSDateDifference(
           (new Date(( new Date(changeItemCriteria.oldCriteriaItem.criItem.startDate)).getTime()
                    + Number(changeItemCriteria.oldCriteriaItem.criItem.nights)*24*3600*1000 )),
           new Date(impactItemCriteria.oldCriteriaItem.criItem.onewayPickupTime));
            changeToDate = (new Date((( new Date(changeItemCriteria.criItem.startDate)).getTime()
              + Number(changeItemCriteria.criItem.nights)*24*3600*1000
              + gapWithChangedItem*24*3600*1000 ))).toString();
          }

       }

     }
    changeToDate = this.getDependencyDateString(changeToDate,true);
    return changeToDate;
  }

  /**
   * return date string (date Month)
   * @param date - date string
   * return converted date string
   */
  getDependencyDateString(date: string, year?: boolean): string {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const dt = new Date(date);
    const yearString = year? ' ' + dt.getFullYear().toString() : ''
    return dt.getDate().toString() + ' ' + monthNames[dt.getMonth()] + yearString;
  }

  /**
   * return product name coresponding to the product code
   * @param code - product code
   * @param index - position of criteriaItem array
   * return product name
   */
  getProductName(code, index): string {
    switch (code) {
      case Product.ACCOMMODATION: {
        return 'Hotel';
      }
      case Product.FLIGHT: {
        return ('Flight (' + this.criteriaItemList[index].criItem.legs[0].departureAirportCode.toString() +
          ' - ' + this.criteriaItemList[index].criItem.legs[0].arrivalAirportCode.toString() + ')');
      }
      case Product.CAR: {
        return 'Car';
      }
      case Product.TRANSFERS: {
        return 'Transfer';
      }
      case Product.GENERIC: {
        return 'Activity';
      }
      case 'OWN': {
        return 'Own Arrangement';
      }
    }
  }

  /**
   * handle user input of amendment dependency identification modal
   * @param event - proceed/cancel
   */
  handleDependencyModal(event) {
    this.showAmendmentDependencyModal = false;
    if (event == 'confirm') {
      this.criteriaItemList.forEach((criteria, index) => {
        if (!this.unfreezeItemMap.get(index) && criteria.productCode !== Product.FLIGHT) {
          const dependencyIdentified = this.impactedItemList.filter(item => {
            return item.criItemIndex === criteria.index;
          });
          if (dependencyIdentified && dependencyIdentified.length > 0) {
            this.unfreezeItemMap.set(index, true);
            this.emitUnfreezeItem.emit({event : true, item : criteria});
          }
        }
      });
      this.updateCriteriaItemList(this.recentlyChangedItem);
    } else if (event == 'cancel') {
      this.updateCriteriaItemList(this.recentlyChangedItem);
    }
  }

  /**
   * @param isNew
   */
  setHasFlightItem(hasFlightItem: boolean, isNew?: boolean) {
    this.hasFlightItem = hasFlightItem;
    if (!isNew) {
      this.isFlightsNotAmendedMessageShown = hasFlightItem;
    }
  }

  /**
   * @param isNew
   */
  setHasTourItem(hasTourItem: boolean, isNew?: boolean) {
    this.hasTourItem = hasTourItem;
    if (!isNew) {
      this.isToursNotAmendedMessageShown = hasTourItem;
    }
  }

  /**
   * set initial passenger split array to criteria
   * @param position - index of criteria item list
   * @param ignorePaxSplit - use to ignore set enable split change
   */
  setInitialPaxSplit(position, ignorePaxSplit: boolean = false) {
    if (!ignorePaxSplit) {
      this.criteriaItemList[position].enablePaxSplit = true;
    }

    let guestCount = 1;
    const paxArray = [];
    for (let i = 1; i <= this.cachePaxSet[0]._adult; i++) {
      const passenger = new SplitPassenger();
      passenger.code = 'A' + i;
      passenger.name = 'Guest ' + guestCount + '(Adult)';
      passenger.paxType = ApiPaxTypes.A;
      passenger.selected = true;
      paxArray.push(passenger);
      guestCount++;
    }
    if (this.cachePaxSet[0]._teen && this.cachePaxSet[0]._teen.length > 0) {
      this.cachePaxSet[0]._teen.forEach((teen, index) => {
        const passenger = new SplitPassenger();
        passenger.code = 'T' + guestCount.toString();
        passenger.name = 'Guest ' + guestCount + '(Teen)';
        passenger.paxType = ApiPaxTypes.T;
        passenger.selected = true;
        paxArray.push(passenger);
        guestCount++;
      });
    }
    if (this.cachePaxSet[0]._child && this.cachePaxSet[0]._child.length > 0) {
      this.cachePaxSet[0]._child.forEach((child, index) => {
        const passenger = new SplitPassenger();
        passenger.code = 'C' + guestCount.toString();
        passenger.name = 'Guest ' + guestCount + '(Child)';
        passenger.paxType = ApiPaxTypes.C;
        passenger.selected = true;
        paxArray.push(passenger);
        guestCount++;
      });
    }
    if (this.cachePaxSet[0]._infant && this.cachePaxSet[0]._infant.length > 0) {
      this.cachePaxSet[0]._infant.forEach((infant, index) => {
        const passenger = new SplitPassenger();
        passenger.code = 'I' + guestCount.toString();
        passenger.name = 'Guest ' + guestCount + '(Infant)';
        passenger.paxType = ApiPaxTypes.I;
        passenger.selected = true;
        paxArray.push(passenger);
        guestCount++;
      });
    }
    this.criteriaItemList[position].paxSelection = paxArray;
    this.criteriaItemList[position].isPaxSelectionChanged = false;
    if (!ignorePaxSplit) {
      setTimeout(() => {
        this.criteriaItemList[position].enablePaxSplit = false;
      }, 10);
    }
  }

  /**
   * set initial passenger split array to criteria in amendment flow when add new item
   * @param position - index of criteria item list
   */
  setInitialPaxSplitAmendNewItem(position) {
    this.criteriaItemList[position].enablePaxSplit = true;

    let paxSelection = null;
    if (position > 0) {
      paxSelection = JSON.parse(JSON.stringify(this.criteriaItemList[(position - 1)].paxSelection));
    } else {
      paxSelection = JSON.parse(JSON.stringify(this.criteriaItemList[(position + 1)].paxSelection));
    }
    if (paxSelection && paxSelection.length > 0) {
      paxSelection.forEach(selection => {
        selection.selected = true;
      });
    }
    this.criteriaItemList[position].paxSelection = paxSelection;
    this.criteriaItemList[position].isPaxSelectionChanged = true;
    setTimeout(() => {
      this.criteriaItemList[position].enablePaxSplit = true;
    }, 10);
  }

  /**
   * set is criteria pax split changed
   * @param event - is changed or not boolean
   * @param index - index of criteria item list
   */
  changePaxSplit(event, index) {
    this.criteriaItemList[index].isPaxSelectionChanged = event;
    this.checkPassengerSplit();
  }

  /**
   * check whether is there any passenger split is done in any criteria
   */
  checkPassengerSplit() {
    this.isPassengerSplit = false;
    for (const criteria of this.criteriaItemList) {
      if (criteria.isPaxSelectionChanged) {
        this.isPassengerSplit = true;
        break;
      }
    }
  }

  /**
   * add new passenger to pax split list in criteria items
   */
  handleIncreasePaxCount() {
    const adultsCount = this.cachePaxSet[0]._adult;
    const childCount = this.cachePaxSet[0]._child.length;
    const teenCount = this.cachePaxSet[0]._teen.length;
    const infantCount = this.cachePaxSet[0]._infant.length;

    this.criteriaItemList.forEach((criteria, index) => {
      if (!criteria.isPaxSelectionChanged) {
        this.setInitialPaxSplit(index, true);
      } else {
        let paxArray = [];
        let guestCount = 1;
        let oldAdultCount = 0;
        let oldChildCount = 0;
        let oldTeenCount = 0;
        let oldInfantCount = 0;

        // start of adult handle section
        const adults = criteria.paxSelection.filter(pax => {
          return pax.paxType === ApiPaxTypes.A;
        });
        if (adults && adults.length > 0) {
          paxArray = paxArray.concat(adults);
          oldAdultCount = adults.length;
          guestCount += adults.length;
        }
        if (oldAdultCount < adultsCount) {
          for (let i = 1; i <= (adultsCount - oldAdultCount); i++) {
            const passenger = new SplitPassenger();
            passenger.code = 'A' + (i + oldAdultCount);
            passenger.name = 'Guest ' + guestCount + '(Adult)';
            passenger.paxType = ApiPaxTypes.A;
            passenger.selected = false;
            paxArray.push(passenger);
            guestCount++;
          }
        }
        // end of adult handle section

        // start of teen handle section
        const teens = criteria.paxSelection.filter(pax => {
          return pax.paxType === ApiPaxTypes.T;
        });
        if (teens && teens.length > 0) {
          paxArray = paxArray.concat(teens);
          oldTeenCount = teens.length;
          guestCount += teens.length;
        }
        if (oldTeenCount < teenCount) {
          for (let i = 1; i <= (teenCount - oldTeenCount); i++) {
            const passenger = new SplitPassenger();
            passenger.code = 'T' + (i + oldTeenCount);
            passenger.name = 'Guest ' + guestCount + '(Teen)';
            passenger.paxType = ApiPaxTypes.T;
            passenger.selected = false;
            paxArray.push(passenger);
            guestCount++;
          }
        }
        // end of teen handle section

        // start of child handle section
        const children = criteria.paxSelection.filter(pax => {
          return pax.paxType === ApiPaxTypes.C;
        });
        if (children && children.length > 0) {
          paxArray = paxArray.concat(children);
          oldChildCount = children.length;
          guestCount += children.length;
        }
        if (oldChildCount < childCount) {
          for (let i = 1; i <= (childCount - oldChildCount); i++) {
            const passenger = new SplitPassenger();
            passenger.code = 'C' + (i + oldChildCount);
            passenger.name = 'Guest ' + guestCount + '(Child)';
            passenger.paxType = ApiPaxTypes.C;
            passenger.selected = false;
            paxArray.push(passenger);
            guestCount++;
          }
        }
        // end of child handle section

        // start of infant handle section
        const infant = criteria.paxSelection.filter(pax => {
          return pax.paxType === ApiPaxTypes.I;
        });
        if (infant && infant.length > 0) {
          paxArray = paxArray.concat(infant);
          oldInfantCount = infant.length;
          guestCount += infant.length;
        }
        if (oldInfantCount < infantCount) {
          for (let i = 1; i <= (infantCount - oldInfantCount); i++) {
            const passenger = new SplitPassenger();
            passenger.code = 'I' + (i + oldInfantCount);
            passenger.name = 'Guest ' + guestCount + '(Infant)';
            passenger.paxType = ApiPaxTypes.I;
            passenger.selected = false;
            paxArray.push(passenger);
            guestCount++;
          }
        }
        // end of infant handle section

        // rearange passenger code
        paxArray.forEach((pax, index) => {
          if (pax.paxType === ApiPaxTypes.A) {
            pax.code = 'A' + (index + 1);
            pax.name = 'Guest ' + (index + 1) + '(Adult)';
          }
          if (pax.paxType === ApiPaxTypes.C) {
            pax.code = 'C' + (index + 1);
            pax.name = 'Guest ' + (index + 1) + '(Child)';
          }
          if (pax.paxType === ApiPaxTypes.T) {
            pax.code = 'T' + (index + 1);
            pax.name = 'Guest ' + (index + 1) + '(Teen)';
          }
          if (pax.paxType === ApiPaxTypes.I) {
            pax.code = 'I' + (index + 1);
            pax.name = 'Guest ' + (index + 1) + '(Infant)';
          }
        });
        criteria.paxSelection = JSON.parse(JSON.stringify(paxArray));
      }
    });
  }

  /**
   * remove passenger to pax split list in criteria items
   */
  handleDecreasePaxCount() {
    const adultsCount = this.cachePaxSet[0]._adult;
    const childCount = this.cachePaxSet[0]._child.length;
    const teenCount = this.cachePaxSet[0]._teen.length;
    const infantCount = this.cachePaxSet[0]._infant.length;

    this.criteriaItemList.forEach((criteria, index) => {
      if (!criteria.isPaxSelectionChanged) {
        this.setInitialPaxSplit(index, true);
      } else {
        let paxArray = [];
        const guestCount = 1;
        let oldAdultCount = 0;
        let oldChildCount = 0;
        let oldTeenCount = 0;
        let oldInfantCount = 0;

        // start of adult handle section
        const adults = criteria.paxSelection.filter(pax => {
          return pax.paxType === ApiPaxTypes.A;
        });
        if (adults && adults.length > 0) {
          oldAdultCount = adults.length;
          if (oldAdultCount > adultsCount) {
            adults.splice((adults.length - (oldAdultCount - adultsCount)), (oldAdultCount - adultsCount));
            paxArray = paxArray.concat(adults);
          } else {
            paxArray = paxArray.concat(adults);
          }
        }
        // end of adult handle section

        // start of teen handle section
        const teens = criteria.paxSelection.filter(pax => {
          return pax.paxType === ApiPaxTypes.T;
        });
        if (teens && teens.length > 0) {
          oldTeenCount = teens.length;
          if (oldTeenCount > teenCount) {
            teens.splice((teens.length - (oldTeenCount - teenCount)), (oldTeenCount - teenCount));
            paxArray = paxArray.concat(teens);
          } else {
            paxArray = paxArray.concat(teens);
          }
        }
        // end of teen handle section

        // start of child handle section
        const children = criteria.paxSelection.filter(pax => {
          return pax.paxType === ApiPaxTypes.C;
        });
        if (children && children.length > 0) {
          oldChildCount = children.length;
          if (oldChildCount > childCount) {
            children.splice((children.length - (oldChildCount - childCount)), (oldChildCount - childCount));
            paxArray = paxArray.concat(children);
          } else {
            paxArray = paxArray.concat(children);
          }
        }
        // end of child handle section

        // start of infant handle section
        const infants = criteria.paxSelection.filter(pax => {
          return pax.paxType === ApiPaxTypes.I;
        });
        if (infants && infants.length > 0) {
          oldInfantCount = infants.length;
          if (oldInfantCount > infantCount) {
            infants.splice((infants.length - (oldInfantCount - infantCount)), (oldInfantCount - infantCount));
            paxArray = paxArray.concat(infants);
          } else {
            paxArray = paxArray.concat(infants);
          }
        }
        // end of infant handle section

        // rearange passenger code
        let isAllSelected = true;
        paxArray.forEach((pax, index) => {
          if (pax.paxType === ApiPaxTypes.A) {
            pax.code = 'A' + (index + 1);
            pax.name = 'Guest ' + (index + 1) + '(Adult)';
          }
          if (pax.paxType === ApiPaxTypes.C) {
            pax.code = 'C' + (index + 1);
            pax.name = 'Guest ' + (index + 1) + '(Child)';
          }
          if (pax.paxType === ApiPaxTypes.T) {
            pax.code = 'T' + (index + 1);
            pax.name = 'Guest ' + (index + 1) + '(Teen)';
          }
          if (pax.paxType === ApiPaxTypes.I) {
            pax.code = 'I' + (index + 1);
            pax.name = 'Guest ' + (index + 1) + '(Infant)';
          }
          if (!pax.selected) {
            isAllSelected = false;
          }
        });
        criteria.paxSelection = JSON.parse(JSON.stringify(paxArray));
        criteria.isPaxSelectionChanged = !isAllSelected;
      }
    });
  }

  handleDpTemplateSelectedEvent(eventData: Map<number, string>) {
    const itemList: any[] = [];
    this.criteriaItemList = [];
    this.isActiveDpTemplate = false;
    this.selectedItemMap = new Map<number, string>();
    this.selectedItemMap = eventData;

    this.selectedItemMap.forEach((itemValue, itemKey) => {
      const newItem = {
        index: itemKey,
        productCode: itemValue,
        criItem: this.createNewItem(itemValue),
        gapWithChangedItem: 0,
        initialDateStr: null,
        disableStartDate: null,
        initialDestStr: null,
        isGrabbedLeg: false,
        isNewCriteriaItem: true
      };
      if (itemValue === this.PROD_CONST.ACCOMMODATION && !this.hasHotelItem) {
        this.hasHotelItem = true;
      }
      this.addToTop = false;
      this.criteriaItemList.push(newItem);
      this.setInitialPaxSplit(itemList.length);
      this.DpCriteriaManageFlights.updateFlightItemsOnAdd(this.flightsGrouping, this.criteriaItemList, (itemKey), this.isAmendmentFlow);
    });
  }

  isTourGrabItemAvailable(criteriaItemList: any[]): boolean {
    const filteredList = criteriaItemList.filter(item => item.productCode === 'TOU' && item?.isGrab);
    return (filteredList.length > 1);
  }
}

interface ChangeItem {
  index: number;
  productCode: string;
  changeType: string;
  value: string;
}

class OwnArragementCriteria {
  productCode: string;
  itineraryItemNo: number;
  itineraryItemOrder: number;
  param: OwnArrangementParam;
}


class OwnArrangementParam {
  name: string;
  description: string;
  quoteBookingType: string;
  departureDate: string;
  duration: number;
}

class AmendmentImpactedItem {
  criItemIndex: number;
  date: string;
  ProductType: string;
  isPreItem: boolean;
  changeToDate: string;
}

class SplitPassenger {
  name: string;
  code: string;
  paxType: string;
  selected: boolean;
}

enum ImpactTypes {
  DESTINATION = 'DESTINATION',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  PICKUP_LOCATION = 'PICKUP_LOCATION',
  DROP_OFF_LOCATION = 'DROP_OFF_LOCATION'
}
