import {GrabPnrBooking, PnrTravellerDiff} from '@surf/surf-grab-pnr';
import {TourCriteria} from '@surf/surf-components-core/lib/models/tour-criteria-model';
import {Traveller} from '@tc-core/model/it/codegen/tbx/api/commons/traveller';

export class SurfTourGrabProcessUtil extends GrabPnrBooking {

  continueToTourResultPage(tourCriteria: TourCriteria, passengerCount: number, bkgId: number) {
      let params = '';
      if (tourCriteria === null || tourCriteria === undefined) {
        return params;
      }
      for (const prop in tourCriteria) {
        if (Object.prototype.hasOwnProperty.call(tourCriteria, prop)) {
          if (tourCriteria[prop] && String(tourCriteria[prop]) !== '') {
            params += '&' + prop + '=' + tourCriteria[prop];
          }
        }
      }
      if (params && params[0] === '&') {
        params = params.slice(1);
      }
      params += '&pnrPaxCount=' + passengerCount;
      params += '&travellerMapping=' + this.resultsPageHelperParam;
      params += '&pnrBkgId=' + bkgId;


      const basePath = '/';
      window.location.href = basePath + 'b2b-tours/?' + params;
  }

  validatePpDetails(pnrTraveller: Traveller, bkgTraveller: Traveller, diffs: PnrTravellerDiff) {
    return true;
  }

  checkIfMatched(pnrTraveller: Traveller, bkgTraveller: Traveller, diffs: PnrTravellerDiff): {isMatched: boolean, diffs: PnrTravellerDiff} {

    // diffs.title = true;

    const isLastNameMatching = pnrTraveller.profile.lastName != undefined &&
      (this.removeCharactersInString(pnrTraveller.profile.lastName.toUpperCase()) === this.removeCharactersInString(bkgTraveller.profile.lastName.toUpperCase()));

    const isFirstNameMatching = pnrTraveller.profile.firstName != undefined &&
      (this.removeCharactersInString(pnrTraveller.profile.firstName.toUpperCase()) === this.removeCharactersInString(bkgTraveller.profile.firstName.toUpperCase()));

    let isMiddleNameMatching = false;

    if ((pnrTraveller.profile.middleName == undefined) || (bkgTraveller.profile.middleName == undefined)) {
      isMiddleNameMatching = true;
    } else {
      isMiddleNameMatching = pnrTraveller.profile.middleName != undefined && bkgTraveller.profile.middleName != undefined &&
        (this.removeCharactersInString(pnrTraveller.profile.middleName.toUpperCase()) === this.removeCharactersInString(bkgTraveller.profile.middleName.toUpperCase()));
    }

    let isTitleMatching = false;
    if ((pnrTraveller.profile.title == undefined) || (bkgTraveller.profile.title == undefined)) {
      isTitleMatching = true;
    } else {
      isTitleMatching = pnrTraveller.profile.title != undefined && bkgTraveller.profile.title != undefined &&
        (this.removeCharactersInString(pnrTraveller.profile.title.toUpperCase()) === this.removeCharactersInString(bkgTraveller.profile.title.toUpperCase()));
    }

    if (pnrTraveller.profile.type == 'C') {
      if (isLastNameMatching && isFirstNameMatching && isMiddleNameMatching && isTitleMatching) {
        diffs.hasMatch = true;
        diffs.firstName = isFirstNameMatching;
        diffs.lastName = isLastNameMatching;
        diffs.middleName = isMiddleNameMatching;
        diffs.title = isTitleMatching;
        return {isMatched: true, diffs};
      } else if (isLastNameMatching && isFirstNameMatching) {
        diffs.hasPrimaryMatch = true;
        return {isMatched: false, diffs};
      }
    } else if (pnrTraveller.profile.type == 'I') {
      if (isLastNameMatching && isFirstNameMatching && isMiddleNameMatching && isTitleMatching) {
        diffs.hasMatch = true;
        diffs.firstName = isFirstNameMatching;
        diffs.lastName = isLastNameMatching;
        diffs.title = isTitleMatching;
        diffs.middleName = isMiddleNameMatching;
        return {isMatched: true, diffs};
      }
    } else {
      if (isLastNameMatching && isFirstNameMatching && isMiddleNameMatching && isTitleMatching) {
        diffs.hasMatch = true;
        // diffs.dateOfBirth = false;
        diffs.firstName = isFirstNameMatching;
        diffs.lastName = isLastNameMatching;
        diffs.middleName = isMiddleNameMatching;
        diffs.title = isTitleMatching;
        return {isMatched: true, diffs};
      }
    }

    return {isMatched: false, diffs: null};
  }


  /**
   * Check whether given bkg and pnr traveller details are exactly the same
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   */
  isAllMatching(pnrTraveller: Traveller, bkgTraveller: Traveller): boolean {
    let status = true;
    if (pnrTraveller.profile.firstName == undefined) {
      status = false;
    } else if (this.removeCharactersInString(pnrTraveller.profile.firstName.toUpperCase()) !== this.removeCharactersInString(bkgTraveller.profile.firstName.toUpperCase())) {
      status = false;
    }

    if (pnrTraveller.profile.lastName == undefined) {
      status = false;
    } else if (this.removeCharactersInString(pnrTraveller.profile.lastName.toUpperCase()) !== this.removeCharactersInString(bkgTraveller.profile.lastName.toUpperCase())) {
      status = false;
    }

    if (pnrTraveller.profile.type !== bkgTraveller.profile.type) {
      status = false;
    }

    if (pnrTraveller.profile.middleName !== undefined && bkgTraveller.profile.middleName !== undefined) {
      if (this.removeCharactersInString(pnrTraveller.profile.middleName.toUpperCase()) !== this.removeCharactersInString(bkgTraveller.profile.middleName.toUpperCase())) {
        status = false;
      }
    }

    if (pnrTraveller.profile.title !== undefined && bkgTraveller.profile.title !== undefined) {
      if (this.removeCharactersInString(pnrTraveller.profile.title.toUpperCase()) !== this.removeCharactersInString(bkgTraveller.profile.title.toUpperCase())) {
        status = false;
      }
    }
    return status;
  }

  validateTitle(pnrTraveller: Traveller, bkgTraveller: Traveller) {
    // infant title is not grabbed in pnr flow

      if ((pnrTraveller.profile.title === undefined) || (bkgTraveller.profile.title === undefined)) {
        return true;
      }

      // if both titles are available check whether they are equal
      if (pnrTraveller.profile && pnrTraveller.profile.title && pnrTraveller.profile.title.length > 0
        && bkgTraveller.profile && bkgTraveller.profile.title  && bkgTraveller.profile.title.length > 0) {
        return pnrTraveller.profile.title.toUpperCase() === bkgTraveller.profile.title.toUpperCase();
      }

      // if both titles are empty return true
      if ((!pnrTraveller.profile.title || pnrTraveller.profile.title.length === 0) && (!bkgTraveller.profile.title || bkgTraveller.profile.title.length === 0)) {
        return true;
      }
      // one is present and other one is not then false
      return false;
  }


  /**
   * This method will find travelers whose primary details are exactly the same
   * these travellers will be updated in the booking
   */
  findExactMatches() {
    for (const [index1, pnrTraveller] of Array.from(this.pnrTravellers.entries())) {
      if (this.bkgTravellers.length > 0) {
        for (const [index2, bkgTraveller] of Array.from(this.bkgTravellers.entries())) {
          const isMatching = this.isAllMatching(pnrTraveller, bkgTraveller);
          if (isMatching) {
            const diff = new PnrTravellerDiff(true);
            const isSecondaryFieldsMatching = this.updateSecondaryFields(pnrTraveller, bkgTraveller, diff);
            this.updateOtherFields(pnrTraveller, bkgTraveller, diff);
            this.addMatchingTraveller(pnrTraveller, bkgTraveller, diff);
            this.addResultsPageHelperParam(pnrTraveller, bkgTraveller);
            this.removeMatched(pnrTraveller, bkgTraveller);
            if (!isSecondaryFieldsMatching && !this.showErrorMessage) {
              this.showErrorMessage = true;
            }
            break;
          }
        }
      }
    }
  }
}
