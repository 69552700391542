import { OntologyResponse } from '@surf/surf-components-core';
import { SurfDropDownOutput } from '@surf/surf-drop-down';

export class FlightGroupDetail {
  itineraryItemNo: number;
  groupNumber: number;
  groupClassName: string;
  dropdownOptions: Array<SurfDropDownOutput>;
  displayGroupDetails: boolean;
  combineWith: number;
  isDisabled = false;
}

export class AirlineChangeItem {
  isAirlineSearchInitiated: boolean;
  airlines: Array<{airlineCode: string, airlineName: string}>;
  airlineOntologies: Array<OntologyResponse>;
  ontologyDisplayText: string;

  constructor(isAirlineSearchInitiated, airlines, airlineOntologies, ontologyDisplayText) {
    this.isAirlineSearchInitiated = isAirlineSearchInitiated;
    this.airlines = JSON.parse(JSON.stringify(airlines));
    this.airlineOntologies = JSON.parse(JSON.stringify(airlineOntologies));
    this.ontologyDisplayText = ontologyDisplayText;
  }
}

export class ViaPointChangeItem {
  isViaPointSearchInitiated: boolean;
  viaPoints: Array<{airlineCode: string, airlineName: string}>;
  viaPointOntologies: Array<OntologyResponse>;
  ontologyDisplayText: string;

  constructor(isViaPointSearchInitiated, viaPoints, viaPointOntologies, ontologyDisplayText) {
    this.isViaPointSearchInitiated = isViaPointSearchInitiated;
    this.viaPoints = JSON.parse(JSON.stringify(viaPoints));
    this.viaPointOntologies = JSON.parse(JSON.stringify(viaPointOntologies));
    this.ontologyDisplayText = ontologyDisplayText;
  }
}
