import grabTourOperations from './grab-tour-operations.json';

const PermissionKeys = {
  TOUR_GRAB: 'SURF_TOUR_GRAB'
};

const exportTourOperations = () => {
  return grabTourOperations;
};

export { PermissionKeys, exportTourOperations };

