import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {ModelStep} from "../models/modal-step.model";
import {DataKey, DataStore} from "@tc-core/util/framework";
import { PermissionKeys } from '../security/permission-constants';
import grabPnrOperations from '../security/grab-pnr-operations.json';
import {SurfAuthorizationService, SurfCriteriaUtil} from '@surf/surf-components-core';
@Component({
  selector: 'surf-grab-flight-price-details',
  templateUrl: './grab-flight-price-details.component.html'
})
export class GrabFlightPriceDetailsComponent implements OnInit {

  MODAL_STEP = ModelStep;
  @Input() stepStatus: string = null;
  @Input() isDP :boolean =false;
  @Input() isRefine :boolean =false;
  @Output() stepStatusOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitGrabPriceMethod: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @HostBinding('class') classes = 'c-add-manual-item__section';
  isGrabWithPrice : boolean = true;
  enableGrabWithOutPrice : boolean = false;
  grabWithPrice : string  = 'Grab with price';
  grabWithPriceDetail : string = 'Grab itinerary with price'
  grabWithOutPrice : string = 'Grab without price';
  grabWithOutPriceDetail : string = 'Grab itinerary and add prices manually'
  // Key params helpers
  moduleConfigs: Array<{code: string, name: string}>;
  permissionKeys;
  enableRadioButtonGrabWithOutPrice = true;
  enableRadioButtonGrabWithPrice = true;
  constructor(private dataStore: DataStore,
              private surfAuthorizationService: SurfAuthorizationService) { }

  ngOnInit() {
    // surf permissions
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(grabPnrOperations);
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();
    this.loadConfigs();
    this.setAuthenticationParameters();
    if(this.isRefine){
      this.completeStep();
    }
  }
  /**
   * Setting up the authentication parameters from the permission framework
   */
  setAuthenticationParameters() {
    const mbiFltGrabPnrPermission = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.MBI_FLT_GRAB_PNR);
    const fltGrabPnrPermission = this.surfAuthorizationService.getPermissionResponse(PermissionKeys.FLT_GRAB_PNR);
    if (fltGrabPnrPermission && fltGrabPnrPermission.allowed) {
      this.isGrabWithPrice = true;
      this.enableRadioButtonGrabWithPrice = true;
    } else {
      this.isGrabWithPrice = false;
      this.enableRadioButtonGrabWithPrice = false;
    }
    if (mbiFltGrabPnrPermission && mbiFltGrabPnrPermission.allowed) {
      this.enableRadioButtonGrabWithOutPrice = true;
    } else {
      this.enableRadioButtonGrabWithOutPrice = false;
    }
  }
  // load module configurations
  loadConfigs() {
    if (this.moduleConfigs && this.moduleConfigs.length) {
      const config = this.moduleConfigs.find(conf => (conf.code === '' +
        'ENABLE_MBI_FLT_GRAB_PNR'));
      if (config && config.name) {
        if (config.name === "TRUE") {
          this.enableGrabWithOutPrice = true;
        }
      }
    }
  }
  backToStep() {
    this.stepStatusOutput.emit(ModelStep.isActive);
    this.stepStatus = ModelStep.isActive;
  }
  GrabWithPrice(){
    this.isGrabWithPrice = true;
  }
  GrabWithOutPrice(){
    if (this.enableRadioButtonGrabWithOutPrice && !this.isDP) {
      this.isGrabWithPrice = false;
    }
  }
  completeStep() {
    this.emitGrabPriceMethod.emit(this.isGrabWithPrice);
    this.stepStatusOutput.emit(ModelStep.isDone);
  }

}
