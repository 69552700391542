<div class="c-advance-search__content">
  <ng-container *ngIf="!isServiceAddComponent; else elseblock">
  <div class="c-advance-search__content-top" *ngIf="!isDPSearch">
    <h3 *ngIf="!isRefine" class="t2 c-advance-search__title u-visible-sm">Search tour</h3>
    <h3 *ngIf="isRefine" class="t2 c-advance-search__title u-visible-sm">Refine your tour</h3>
    <h3 *ngIf="!isRefine" class="t2 c-advance-search__title u-hidden-sm">Search tour for</h3>
    <h3 *ngIf="isRefine" class="t2 c-advance-search__title u-hidden-sm">Refine your tour for</h3>
    <div class="c-advance-search__guests" *ngIf="!isGrabbed">
      <div class="m-form-item auto-pax-count" (click)="scrollTo('#scroll')">
        <surf-pax-selection
          [(isFocused)]="guestComponentFocus"
          [paxSet]="defaultPaxSet"
          [product]="'FLT'"
          [inputWrapperView]="false"
          [maxAdult]="TOU_MAX_ADULT"
          [minAdult]="TOU_MIN_ADULT"
          [maxPaxCount]="TOU_MAX_PAX_COUNT"
          [maxCabinPaxCount]="TOU_MAX_CABIN_PAX_COUNT"
          [maxChildren]="TOU_MAX_CHILD"
          [maxTeen]="TOU_MAX_TEEN"
          [maxInfants]="TOU_MAX_INFANTS"
          [maxInfantAge]="MAX_INFANT_AGE"
          [maxChildAge]="MAX_CHILD_AGE"
          [maxTeenAge]="MAX_TEEN_AGE"
          [parentScrollId]="windowScrollEnable ? 'scroll': null"
          [isTeenActive]="TEEN_PAX_ACTIVE"
          (paxSelection)="getPassengerSelection($event)"
          [tabIndex]="2"
          (currentOption)="getCurrentOption($event)"
          (keydown)="onKeyDown($event, 1)"
          [b2bView]="true"
          [roomViewSelected]="false"
          [infoDisabled]="true">
        </surf-pax-selection>
      </div>

    </div>
    <div class="c-advance-search__guests" *ngIf="isGrabbed && displayingGrabbedTourDetailList && displayingGrabbedTourDetailList.length > 0">
      <div class="surf-pax-selection">
        <div class="m-form-item">
          <div class="m-form-item__input surf-pax-selection__input auto-btn-search-for-close">
            <span class="m-form-field__field-item" style="border: none; background-image: none">
              <input class="m-form-field__input" readonly="true" size="8"
                     value="{{displayingGrabbedTourDetailList[0]?.guestCount}} Guest{{displayingGrabbedTourDetailList[0]?.guestCount > 1 ? 's' : ''}}">
            </span>
          </div>
        </div>
      </div>
    </div>
    <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
      <svg class="a-icon o-modal__close-icon">
        <use xlink:href="#close"></use>
      </svg>
    </button>
  </div>
  </ng-container>
  <ng-template #elseblock>
    <div class="c-advance-search__content-top">
      <button class="a-btn a-btn--link o-modal__inner-close" (click)="resetInputs()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
      <h3 class="t2 c-advance-search__title">Define your search for</h3>
      <!--    <span class="s5">Existing passenger combination will be applied to the newly adding component</span>-->
      <div class="c-advance-search__guests">
        <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
      </div>
    </div>
  </ng-template>
  <div class="c-advance-search__form" *ngIf="!isDPSearch">


    <div class="c-dp-search">
      <ng-container>
        <surf-grab-tour
          [grabbedTourId] ="grabbedTourId"
          [isDisableGrabTourButton]="isDisableGrabTourButton"
          [keyControls]="keyControls"
          [tourFetchSummarySearchCriteria]="tourCriteria"
          [cachingItemMap]="cachingItemMap"
          [allowNgrxCaching] = allowNgrxCaching
          (tourGrabResultOutput)="tourGrabResultOutput($event)"
        ></surf-grab-tour>
      </ng-container>
      <!--   this part for the tour grab only     -->
      <ng-container *ngFor="let grabTourItem of displayingGrabbedTourDetailList" >

        <ng-container *ngIf="isGrabbed && grabTourItem?.isOpenToShow">
          <ng-container *ngIf="!isServiceAddComponent">
            <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}"
                             [totalItemCount]="1"
                             [isDPSearch]="false" [isIntro]="false"
                             [isRefine]="isRefine"
                             [disableDPSwitch]="DISABLE_DP_SWITCH"
                             [isDisabled]="isGrabbed? true : DISABLE_DP_SWITCH"
                             (showBarPanelToggle)="moveToDP('pre')">


            </surf-dp-add-bar>
          </ng-container>
          <div class="c-dp-search__block c-dp-search__block--tour" [ngClass]="{'is-selected': !isDPSearch}">
            <div class="c-dp-search__action">
              <div class="c-dp-search__block-icon">
                <svg class="a-icon">
                  <use xlink:href="#products--tour"></use>
                </svg>
              </div>
            </div>

            <div class="c-dp-search__block-label">
              <svg class="a-icon u-rmr">
                <use xlink:href="#products--tour"></use>
              </svg>
              <span class="lbl">Tour</span>
            </div>

            <div class="c-dp-search__form c-dp-search__form--tour">

              <div class="c-dp-search__form-item">
                <div class="m-form-item">
                  <div class="m-form-item__input auto-tour-stop">
                    <surf-type-ahead
                      [id]="'' + (index ? index : 0)"
                      [label]="'TOUR STOP*'"
                      [filterType]="'strict'"
                      [mappings]="['code', 'value']"
                      [sortOrder]="'A2Z'"
                      [selectedItem]="selectedStop"
                      [paramName]="'ontotext'"
                      [isMultiSelect]="true"
                      [multiSelectedItems]="stopOntologies"
                      [chipForceEnable]="true"
                      [placeholder]="tourStopPlaceholderText"
                      [isDisabled]="stopOntologies.length>=TOUR_STOP_SELECT_MAX || isGrabbed"
                      [tabIndex]="1">
                    </surf-type-ahead>
                  </div>
                </div>
              </div>
              <div class="c-dp-search__form-item">
                <div class="m-form-item">
                  <div class="c-dp-search__form-item c-dp-search__form-item--desc auto-dep-date">
                    <surf-date-picker
                      [range]="calendarRange"
                      [calendarType]="calendarView"
                      [inputLabel]="monthCalendarLabel"
                      [enablePreviousDates]="true"
                      [disabled]="isGrabbed"
                      [currentDateOfTimeZone]="calendarMinDate"
                      [inputPlaceholder]="monthCalendarPlaceholder"
                      [initial_dates]="[grabTourItem.startDate]"
                      [reset]="resetCalendar"
                      [defaultActiveCalendar]="defaultActiveCalendar"
                      (selected_months)="getMonthSelection($event)"
                      (selected_dates)="getDateSelection($event)">
                    </surf-date-picker>
                  </div>
                </div>
              </div>
              <div class="c-dp-search__form-item">
                <div class="m-form-item">
                  <div class="m-form-item__input auto-tour-supplier">
                    <surf-ul-drop-down
                      [label]="'Supplier'"
                      [items]="[{code: grabTourItem.supplierId, value: grabTourItem.supplierName,selected:true}]"
                      [disabled]="isGrabbed"
                      [native]="true"
                      [select]=true
                      (singleObject)="selectTourSupplier($event)">
                    </surf-ul-drop-down>
                  </div>
                  <div class="m-form-item__drop" hidden>
                    Dropdown content here
                  </div>
                </div>
              </div>

              <div class="c-dp-search__form-item">
                <div class="m-form-item">
                  <div class="m-form-item__input auto-tour-name">
                    <surf-type-ahead
                      [id]="'' + (index ? index+2 : 2)"
                      [label]="'Tour name'"
                      [filterType]="'strict'"
                      [mappings]="['code', 'value']"
                      [sortOrder]="'A2Z'"
                      [selectedItem]="{ displayText: grabTourItem.itineraryName, query: '', index: 1}"
                      [paramName]="'ontotext'"
                      [placeholder]="tourNamePlaceholderText"
                      [isDisabled]="isGrabbed"
                      [bindFocusOut]="false"
                      [selectAllOnEnter]="false"
                      [isOnto]="true"
                      [autoFocus]="!isRefine"
                      [url]="tourNameURL"
                      [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                      (returnedQuery)="returnedSearchQueryForTourName($event)"
                      (returnedData)="returnedTourName($event)"
                      (keydown)="onKeyDown($event, 0)"
                      [tabIndex]="1">
                    </surf-type-ahead>
                  </div>
                </div>
              </div>

              <div class="c-dp-search__form-group c-dp-search__form-group--extended">
                <div class="c-dp-search__form-item">
                  <div class="m-form-item">
                    <div class="m-form-item__input auto-tour-departure-city">
                      <surf-type-ahead
                        [id]="'' + (index ? index+1 : 1)"
                        [label]="'DEPARTURE CITY'"
                        [filterType]="'strict'"
                        [mappings]="['code', 'value']"
                        [sortOrder]="'A2Z'"
                        [selectedItem]="selectedDestination"
                        [paramName]="'ontotext'"
                        [placeholder]="departureCityPlaceholderText"
                        [isDisabled]="isGrabbed"
                        [tabIndex]="1">
                      </surf-type-ahead>
                    </div>
                  </div>
                <div class="c-dp-search__form-item">
                  <div class="c-dp-search__form-item c-dp-search__form-item--desc auto-tour-duration">
                    <surf-multi-field-input
                      [disabled]="isGrabbed"
                      [isPNR]="isGrabbed"
                      [properties]="durationProperty"
                      [componentValue]="grabTourItem.durationValue"
                      [title]="'Duration'"
                      [error]="durationError"
                      [msg]="'Invalid duration value'"
                      (emitCombinedOutEvent)="setDuration($event)">
                    </surf-multi-field-input>
                  </div>
                </div>
              </div>

              <div class="c-dp-search__form-item">
                <div class="m-form-item">
                  <div class="m-form-item__input auto-tour-category">
                    <div class="c-dp-search__form-item c-dp-search__form-item--desc">
                      <div class="m-form-item">
                        <div class="m-form-item__input auto-tour-category">
                          <surf-type-ahead
                            [id]="'' + (index ? index+3 : 3)"
                            [label]="'CATEGORY'"
                            [filterType]="'strict'"
                            [mappings]="['code', 'value']"
                            [sortOrder]="'A2Z'"
                            [selectedItem]="selectedTourCategory"
                            [paramName]="'ontotext'"
                            [placeholder]="categoryPlaceholderText"
                            [isMultiSelect]="true"
                            [multiSelectedItems]="categoryOntologies"
                            [chipForceEnable]="true"
                            [bindFocusOut]="false"
                            [selectAllOnEnter]="false"
                            [isOnto]="true"
                            [isDisabled]="categoryOntologies.length>=TOUR_CATEGORY_SELECT_MAX || isGrabbed">
                          </surf-type-ahead>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="c-dp-search__msg-block" *ngIf="grabTourItem?.isBlockedResult">
                <div class="o-block c-msg-block c-msg-block--error">
                  <div class="l-inline-block">
                    <svg class="a-icon c-msg-block__info-icon">
                      <use xlink:href="#error"></use>
                    </svg>
                    <div class="c-msg-block__info">
                      <div class="c-msg-block__info-txt cap-bold">{{grabTourItem?.blockedReason}} Please remove this item to continue with the flow.</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            </div>

            <div class="c-dp-search__close" (click)="removeIfBlockedGrabbedResult(grabTourItem)">
              <svg class="a-icon">
                <use xlink:href="#close"></use>
              </svg>
            </div>

          </div>
        </ng-container>

      </ng-container>
      <ng-container *ngIf="!isServiceAddComponent && isGrabbed &&
      displayingGrabbedTourDetailList && !isDPSearch &&
      displayingGrabbedTourDetailList.length > 0">
        <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}"
                         [totalItemCount]="1"
                         [isDPSearch]="false" [isIntro]="false"
                         [isRefine]="isRefine"
                         [disableDPSwitch]="DISABLE_DP_SWITCH"
                         [isDisabled]="isGrabbed? true : DISABLE_DP_SWITCH"
                         (showBarPanelToggle)="moveToDP('pre')">


        </surf-dp-add-bar>
      </ng-container>

      <!--   this part for the tour grab only     -->

      <ng-container *ngIf="!isGrabbed">
        <ng-container *ngIf="!isServiceAddComponent && !isDPSearch">
          <surf-dp-add-bar
                           [totalItemCount]="1"
                           [isDPSearch]="false"
                           [isIntro]="false"
                           [preItemIndex]="-1"
                           [msg]="msg"
                           [postItemIndex]="0"
                           [isDisabled]="isGrabbed? true : DISABLE_DP_SWITCH"
                           [isRefine]="isRefine"
                           [disableDPSwitch]="DISABLE_DP_SWITCH"
                           (showBarPanelToggle)="moveToDP('pre')">


          </surf-dp-add-bar>
        </ng-container>
        <div class="c-dp-search__block c-dp-search__block--tour" [ngClass]="{'is-selected': !isDPSearch, 'u-no-permission': !enableSearchFlow}">
          <div class="c-dp-search__action">
            <div class="c-dp-search__block-icon">
              <svg class="a-icon">
                <use xlink:href="#products--tour"></use>
              </svg>
            </div>
          </div>

          <div class="c-dp-search__block-label">
            <svg class="a-icon u-rmr">
              <use xlink:href="#products--tour"></use>
            </svg>
            <span class="lbl">Tour</span>
          </div>

          <div class="c-dp-search__form c-dp-search__form--tour">

            <div class="c-dp-search__form-item">
              <div class="m-form-item">
                <div class="m-form-item__input auto-tour-stop">
                  <surf-type-ahead
                    [id]="'' + (index ? index : 0)"
                    [label]="'TOUR STOP*'"
                    [filterType]="'strict'"
                    [mappings]="['code', 'value']"
                    [sortOrder]="'A2Z'"
                    [selectedItem]="selectedStop"
                    [paramName]="'ontotext'"
                    [isMultiSelect]="true"
                    [multiSelectedItems]="stopOntologies"
                    [chipForceEnable]="true"
                    [placeholder]="tourStopPlaceholderText"
                    [isDisabled]="stopOntologies.length>=TOUR_STOP_SELECT_MAX"
                    [bindFocusOut]="false"
                    [selectAllOnEnter]="false"
                    [isOnto]="true"
                    [autoFocus]="!isRefine"
                    [url]="tourStopCityURL"
                    [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                    (onChipClose)="removeSelectedStop($event)"
                    (returnedQuery)="returnedSearchQueryForStops($event)"
                    (returnedData)="returnedStop($event)"
                    (keydown)="onKeyDown($event, 0)"
                    [tabIndex]="1">

                    <surf-type-ahead-content
                      [typeAheadData]="filteredTourStop"
                      [available]="filteredTourStop?.length > 0"
                      [enableKeyStrokeIgnore]="true"
                      (setItem)="selectStops($event)">

                      <ng-container *ngFor="let item of filteredTourStop;let i = index">
                        <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 20">
                          <div class="c-dp-search__list-item search-ineligible auto-country-item">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use *ngIf="item?.type != 'CITY' && item?.type != 40" xlink:href="#country"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span
                                *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                            </div>
                            <span *ngIf="item?.type != 'CITY' && item?.type != 40"
                                  class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                          </div>

                        </surf-type-ahead-item>
                        <surf-type-ahead-item *ngIf="item?.type == 40">
                          <div class="c-dp-search__list-item auto-city-item" (click)="selectStops(item)">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{item?.name}}</span>
                              <span class="s5 ts-muted ts-wet"><span
                                *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                            </div>
                            <span *ngIf="item?.type == 'CITY' || item?.type == 40"
                                  class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                          </div>
                        </surf-type-ahead-item>
                        <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                          <ng-template [ngIf]="item?.type == 20">
                            <!--then iterate its children (CITIES) as sub components-->
                            <div (click)="selectStops( addParentToLocationGroup(child, item) )"
                                 class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-cities"
                                 [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                              <svg class="a-icon c-dp-search__list-item-icon">
                                <use xlink:href="#map"></use>
                              </svg>
                              <div class="m-info-set c-dp-search__list-item-main">
                                <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                                <span class="s5 ts-muted ts-wet"><span
                                  *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                              </div>
                            </div>
                          </ng-template>
                        </surf-type-ahead-item>
                      </ng-container>
                    </surf-type-ahead-content>
                  </surf-type-ahead>
                  <span *ngIf="stopSearchInitialized && filteredTourStop.length == 0"
                        style="color: red;">No results found</span>
                </div>
              </div>
            </div>
            <div class="c-dp-search__form-item">
              <div class="m-form-item">
                <div class="c-dp-search__form-item c-dp-search__form-item--desc auto-dep-date">
                  <surf-date-picker
                    [range]="calendarRange"
                    [calendarType]="calendarView"
                    [inputLabel]="monthCalendarLabel"
                    [enablePreviousDates]="true"
                    [currentDateOfTimeZone]="calendarMinDate"
                    [inputPlaceholder]="monthCalendarPlaceholder"
                    [initial_dates]="initialDates"
                    [reset]="resetCalendar"
                    [defaultActiveCalendar]="defaultActiveCalendar"
                    (selected_months)="getMonthSelection($event)"
                    (selected_dates)="getDateSelection($event)">
                  </surf-date-picker>
                </div>
              </div>
            </div>

            <div class="c-dp-search__form-item">
              <div class="m-form-item">
                <div class="m-form-item__input auto-tour-supplier">
                  <surf-multi-selector-drop-down
                    [title]="'Supplier'"
                    [dataList]="tourSuppliers"
                    [searchableDropdown]="true"
                    [inputDisplay]="'Any supplier'"
                    (selectionChange)="selectTourSupplier($event)"
                  >
                  </surf-multi-selector-drop-down>
                </div>
                <div class="m-form-item__drop" hidden>
                  Dropdown content here
                </div>
              </div>
            </div>

            <div class="c-dp-search__form-item">
              <div class="m-form-item">
                <div class="m-form-item__input auto-tour-name">
                  <surf-type-ahead
                    [id]="'' + (index ? index+2 : 2)"
                    [label]="'Tour name'"
                    [filterType]="'strict'"
                    [mappings]="['code', 'value']"
                    [sortOrder]="'A2Z'"
                    [selectedItem]="selectedTourName"
                    [paramName]="'ontotext'"
                    [placeholder]="tourNamePlaceholderText"
                    [bindFocusOut]="false"
                    [selectAllOnEnter]="false"
                    [isOnto]="true"
                    [autoFocus]="!isRefine"
                    [url]="tourNameURL"
                    [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                    (onClickInside)="onClickInsideDestTypeAheadTourName($event)"
                    (onClickOutside)="onClickOutsideDestTypeAheadTourName($event)"
                    (returnedQuery)="returnedSearchQueryForTourName($event)"
                    (returnedData)="returnedTourName($event)"
                    (keydown)="onKeyDown($event, 0)"
                    [tabIndex]="1">

                    <surf-type-ahead-content
                      [typeAheadData]="filteredTourName"
                      [available]="filteredTourName?.length > 0"
                      [enableKeyStrokeIgnore]="true"
                      (setItem)="selectTourName($event)">

                      <ng-container *ngFor="let item of filteredTourName;let i = index">

                        <surf-type-ahead-item *ngIf="item?.type == 210 || item?.type == 220">
                          <div
                            (click)="selectTourName(item)"
                            class="c-dp-search__list-item auto-location">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use
                                xlink:href="#products--tour"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet">
                                <span *ngIf="surfCriteriaUtil.getCity(item)?.name">{{surfCriteriaUtil.getCity(item)?.name + ', '}}</span>
                                <span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>
                                <span *ngIf="surfCriteriaUtil.getCountry(item)">{{surfCriteriaUtil.getCountry(item)}}</span>
                              </span>
                            </div>
                            <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Tour</span>
                          </div>
                        </surf-type-ahead-item>
                      </ng-container>
                    </surf-type-ahead-content>
                  </surf-type-ahead>
                  <span *ngIf="nameSearchInitialized && filteredTourName.length == 0"
                        style="color: red;">No results found</span>
                </div>
              </div>
            </div>

            <div class="c-dp-search__form-group c-dp-search__form-group--extended">
              <div class="c-dp-search__form-item">
                <div class="m-form-item">
                  <div class="m-form-item__input auto-tour-departure-city">
                    <surf-type-ahead
                      [id]="'' + (index ? index+1 : 1)"
                      [label]="'DEPARTURE CITY'"
                      [filterType]="'strict'"
                      [mappings]="['code', 'value']"
                      [sortOrder]="'A2Z'"
                      [selectedItem]="selectedDestination"
                      [paramName]="'ontotext'"
                      [placeholder]="departureCityPlaceholderText"
                      [bindFocusOut]="false"
                      [selectAllOnEnter]="false"
                      [isOnto]="true"
                      [autoFocus]="!isRefine"
                      [url]="destinationCityURL"
                      [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                      (onClickInside)="onClickInsideDestTypeAheadDepartureCity($event)"
                      (onClickOutside)="onClickOutsideDestTypeAheadDepartureCity($event)"
                      (returnedQuery)="returnedSearchQueryForDepartureCity($event)"
                      (returnedData)="returnedDepartureCity($event)"
                      (keydown)="onKeyDown($event, 0)"
                      [tabIndex]="1">

                      <surf-type-ahead-content
                        [typeAheadData]="filteredDestinationCity"
                        [available]="filteredDestinationCity?.length > 0"
                        [enableKeyStrokeIgnore]="true"
                        (setItem)="selectDestination($event)">

                        <ng-container *ngFor="let item of filteredDestinationCity;let i = index">
                          <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 20">
                            <div class="c-dp-search__list-item search-ineligible auto-country-item">
                              <svg class="a-icon c-dp-search__list-item-icon">
                                <use *ngIf="item?.type != 'CITY' && item?.type != 40" xlink:href="#country"></use>
                              </svg>
                              <div class="m-info-set c-dp-search__list-item-main">
                                <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                                <span class="s5 ts-muted ts-wet"><span
                                  *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                              </div>
                              <span *ngIf="item?.type != 'CITY' && item?.type != 40"
                                    class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                            </div>

                          </surf-type-ahead-item>
                          <surf-type-ahead-item *ngIf="item?.type == 40">
                            <div class="c-dp-search__list-item auto-city-item" (click)="selectDestination(item)">
                              <svg class="a-icon c-dp-search__list-item-icon">
                                <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                              </svg>
                              <div class="m-info-set c-dp-search__list-item-main">
                                <span class="s4 ts-strong">{{item?.name}}</span>
                                <span class="s5 ts-muted ts-wet"><span
                                  *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                              </div>
                              <span *ngIf="item?.type == 'CITY' || item?.type == 40"
                                    class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                            </div>
                          </surf-type-ahead-item>

                          <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                            <ng-template [ngIf]="item?.type == 20">
                              <!--then iterate its children (CITIES) as sub components-->
                              <div (click)="selectDestination( addParentToLocationGroup(child, item) )"
                                   class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-cities"
                                   [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                                <svg class="a-icon c-dp-search__list-item-icon">
                                  <use xlink:href="#map"></use>
                                </svg>
                                <div class="m-info-set c-dp-search__list-item-main">
                                  <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                                  <span class="s5 ts-muted ts-wet"><span
                                    *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                                </div>
                              </div>
                            </ng-template>
                          </surf-type-ahead-item>
                        </ng-container>
                      </surf-type-ahead-content>
                    </surf-type-ahead>
                    <span *ngIf="destSearchInitiated && filteredDestinationCity.length == 0"
                          style="color: red;">No results found</span>
                  </div>
                </div>
              </div>

              <div class="c-dp-search__form-item">
                <div class="c-dp-search__form-item c-dp-search__form-item--desc auto-tour-duration">
                  <surf-multi-field-input
                    [properties]="durationProperty"
                    [componentValue]="durationValues"
                    [title]="'Duration'"
                    [error]="durationError"
                    [msg]="'Invalid duration value'"
                    (emitCombinedOutEvent)="setDuration($event)">
                  </surf-multi-field-input>
                </div>
              </div>
            </div>

            <div class="c-dp-search__form-item">
              <div class="m-form-item">
                <div class="m-form-item__input auto-tour-category">
                  <div class="c-dp-search__form-item c-dp-search__form-item--desc">
                    <div class="m-form-item">
                      <div class="m-form-item__input auto-tour-category">
                        <surf-type-ahead
                          [id]="'' + (index ? index+3 : 3)"
                          [label]="'CATEGORY'"
                          [filterType]="'strict'"
                          [mappings]="['code', 'value']"
                          [sortOrder]="'A2Z'"
                          [selectedItem]="selectedTourCategory"
                          [paramName]="'ontotext'"
                          [placeholder]="categoryPlaceholderText"
                          [isMultiSelect]="true"
                          [multiSelectedItems]="categoryOntologies"
                          [chipForceEnable]="true"
                          [bindFocusOut]="false"
                          [selectAllOnEnter]="false"
                          [isOnto]="true"
                          [isDisabled]="categoryOntologies.length>=TOUR_CATEGORY_SELECT_MAX"
                          [autoFocus]="!isRefine"
                          [url]="destinationCategoryURL"
                          [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                          (onChipClose)="removeSelectedTourCategory($event)"
                          (returnedQuery)="returnedSearchQueryForCategory($event)"
                          (returnedData)="returnedTourCategory($event)"
                          (keydown)="onKeyDown($event, 0)"
                          [tabIndex]="1">

                          <surf-type-ahead-content
                            [typeAheadData]="filteredTourCategory"
                            [available]="filteredTourCategory?.length > 0"
                            [enableKeyStrokeIgnore]="true"
                            (setItem)="selectTourCategory($event)">

                            <ng-container *ngFor="let item of filteredTourCategory;let i = index">
                              <surf-type-ahead-item *ngIf="item?.type == 230 || item?.type == 240">
                                <div
                                  (click)="selectTourCategory(item)"
                                  class="c-dp-search__list-item auto-location">

                                  <div class="m-info-set c-dp-search__list-item-main">
                                    <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                                  </div>
                                  <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Category</span>
                                </div>
                              </surf-type-ahead-item>
                            </ng-container>
                          </surf-type-ahead-content>
                        </surf-type-ahead>
                        <span *ngIf="categorySearchInitialized && filteredTourCategory.length == 0"
                              style="color: red;">No results found</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
            <svg class="a-icon">
              <use xlink:href="#close"></use>
            </svg>
          </div>

        </div>
        <ng-container *ngIf="!isServiceAddComponent">
          <surf-dp-add-bar
                           [totalItemCount]="1"
                           [isDPSearch]="false"
                           [isIntro]="false"
                           [msg]="msg"
                           [preItemIndex]="-1"
                           [postItemIndex]="0"
                           [isDisabled]=" false"
                           [isRefine]="isRefine"
                           [disableDPSwitch]="false"
                           (showBarPanelToggle)="moveToDP('post')">


          </surf-dp-add-bar>
        </ng-container>
      </ng-container>

    </div>

  </div>
  <!-- this section only DP flow-->
  <div class="c-dp-search__block c-dp-search__block--tour" *ngIf="isDPSearch" >
    <div class="c-dp-search__action" *ngIf="isAmendmentFlow && !isItemCloseable">
      <label  class="a-checkbox c-dp-search__action-check">
        <input type="checkbox" disabled class="a-checkbox__check" (change)="checkValue($event)" [checked]="!freeze">
        <span class="a-checkbox__label"></span>
      </label>
      <div class="c-dp-search__block-icon">
        <svg class="a-icon">
          <use xlink:href="#products--tour"></use>
        </svg>
      </div>
    </div>
    <div class="c-dp-search__action" *ngIf="!isAmendmentFlow || isAmendmentFlow && isItemCloseable">
      <div class="c-dp-search__block-icon">
        <svg class="a-icon">
          <use xlink:href="#products--tour"></use>
        </svg>
      </div>
    </div>

    <div class="c-dp-search__block-label">
      <svg class="a-icon u-rmr">
        <use xlink:href="#products--tour"></use>
      </svg>
      <span class="lbl">Tour</span>
    </div>
    <div class="c-dp-search__form-wrap">
      <span class="a-loz a-loz--success c-dp-search__form-tag" *ngIf="isAmendmentFlow && isItemCloseable" >Newly added</span>

      <div class="c-dp-search__form c-dp-search__form--tour">

      <div class="c-dp-search__form-item">
        <div class="m-form-item">
          <div class="m-form-item__input auto-tour-stop">
            <surf-type-ahead
              [id]="'' + (index ? index : 0)"
              [label]="'TOUR STOP*'"
              [filterType]="'strict'"
              [mappings]="['code', 'value']"
              [sortOrder]="'A2Z'"
              [selectedItem]="selectedStop"
              [paramName]="'ontotext'"
              [isMultiSelect]="true"
              [multiSelectedItems]="stopOntologies"
              [placeholder]="tourStopPlaceholderText"
              [isDisabled]="stopOntologies.length>=TOUR_STOP_SELECT_MAX || freeze || isDpGrab"
              [bindFocusOut]="false"
              [selectAllOnEnter]="false"
              [isOnto]="true"
              [autoFocus]="!isRefine"
              [url]="tourStopCityURL"
              [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
              (onChipClose)="removeSelectedStop($event)"
              (returnedQuery)="returnedSearchQueryForStops($event)"
              (returnedData)="returnedStop($event)"
              (keydown)="onKeyDown($event, 0)"
              [tabIndex]="1">

              <surf-type-ahead-content
                [typeAheadData]="filteredTourStop"
                [available]="filteredTourStop?.length > 0"
                [enableKeyStrokeIgnore]="true"
                (setItem)="selectStops($event)">

                <ng-container *ngFor="let item of filteredTourStop;let i = index">
                  <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 20">
                    <div class="c-dp-search__list-item search-ineligible auto-country-item">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use *ngIf="item?.type != 'CITY' && item?.type != 40" xlink:href="#country"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main">
                        <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                        <span class="s5 ts-muted ts-wet"><span
                          *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                      </div>
                      <span *ngIf="item?.type != 'CITY' && item?.type != 40"
                            class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                    </div>

                  </surf-type-ahead-item>
                  <surf-type-ahead-item *ngIf="item?.type == 40">
                    <div class="c-dp-search__list-item auto-city-item" (click)="selectStops(item)">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main">
                        <span class="s4 ts-strong">{{item?.name}}</span>
                        <span class="s5 ts-muted ts-wet"><span
                          *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                      </div>
                      <span *ngIf="item?.type == 'CITY' || item?.type == 40"
                            class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                    </div>
                  </surf-type-ahead-item>
                  <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                    <ng-template [ngIf]="item?.type == 20">
                      <!--then iterate its children (CITIES) as sub components-->
                      <div (click)="selectStops( addParentToLocationGroup(child, item) )"
                           class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-cities"
                           [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use xlink:href="#map"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                          <span class="s5 ts-muted ts-wet"><span
                            *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                        </div>
                      </div>
                    </ng-template>
                  </surf-type-ahead-item>
                </ng-container>
              </surf-type-ahead-content>
            </surf-type-ahead>
            <span *ngIf="stopSearchInitialized && filteredTourStop.length == 0"
                  style="color: red;">No results found</span>
          </div>
        </div>
      </div>
      <div class="c-dp-search__form-item">
          <div class="m-form-item">
            <div class="c-dp-search__form-item c-dp-search__form-item--desc auto-dep-dp-date">
              <surf-date-picker
                [range]="calendarRange"
                [calendarType]="calendarView"
                [inputLabel]="monthCalendarLabel"
                [enablePreviousDates]="true"
                [currentDateOfTimeZone]="calendarMinDate"
                [inputPlaceholder]="monthCalendarPlaceholder"
                [initial_dates]="initialDates"
                [reset]="resetCalendar"
                [disabled]="freeze || isDpGrab"
                [hideErrorMsg]="hideErrorMsg"
                [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : ''"
                [isError]="validateDisabledDateSelection"
                [defaultActiveCalendar]="defaultActiveCalendar"
                (selected_months)="getMonthSelection($event)"
                (selected_dates)="getDateSelection($event)">
              </surf-date-picker>
            </div>
          </div>
      </div>

      <div class="c-dp-search__form-item">
        <div class="m-form-item">
          <div class="m-form-item__input auto-tour-supplier">
            <surf-multi-selector-drop-down
              [title]="'Supplier'"
              [dataList]="tourSuppliers"
              [isDisabled]="freeze || isDpGrab "
              [searchableDropdown]="true"
              [inputDisplay]="'Any supplier'"
              (selectionChange)="selectTourSupplier($event)"
            >
            </surf-multi-selector-drop-down>
          </div>
          <div class="m-form-item__drop" hidden>
            Dropdown content here
          </div>
        </div>
      </div>

      <div class="c-dp-search__form-item">
        <div class="m-form-item">
          <div class="m-form-item__input auto-tour-name">
            <surf-type-ahead
              [id]="'' + (index ? index+2 : 2)"
              [label]="'Tour name'"
              [filterType]="'strict'"
              [mappings]="['code', 'value']"
              [sortOrder]="'A2Z'"
              [selectedItem]="selectedTourName"
              [paramName]="'ontotext'"
              [placeholder]="tourNamePlaceholderText"
              [bindFocusOut]="false"
              [selectAllOnEnter]="false"
              [isOnto]="true"
              [autoFocus]="!isRefine"
              [isDisabled]="freeze || isDpGrab"
              [url]="tourNameURL"
              [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
              (onClickInside)="onClickInsideDestTypeAheadTourName($event)"
              (onClickOutside)="onClickOutsideDestTypeAheadTourName($event)"
              (returnedQuery)="returnedSearchQueryForTourName($event)"
              (returnedData)="returnedTourName($event)"
              (keydown)="onKeyDown($event, 0)"
              [tabIndex]="1">

              <surf-type-ahead-content
                [typeAheadData]="filteredTourName"
                [available]="filteredTourName?.length > 0"
                [enableKeyStrokeIgnore]="true"
                (setItem)="selectTourName($event)">

                <ng-container *ngFor="let item of filteredTourName;let i = index">

                  <surf-type-ahead-item *ngIf="item?.type == 210 || item?.type == 220">
                    <div
                      (click)="selectTourName(item)"
                      class="c-dp-search__list-item auto-location">
                      <svg class="a-icon c-dp-search__list-item-icon">
                        <use
                          xlink:href="#products--tour"></use>
                      </svg>
                      <div class="m-info-set c-dp-search__list-item-main">
                        <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                        <span class="s5 ts-muted ts-wet">
                          <span *ngIf="surfCriteriaUtil.getCity(item)?.name">{{surfCriteriaUtil.getCity(item)?.name + ', '}}</span>
                          <span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>
                          <span *ngIf="surfCriteriaUtil.getCountry(item)">{{surfCriteriaUtil.getCountry(item)}}</span>
                        </span>
                      </div>
                      <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Tour</span>
                    </div>
                  </surf-type-ahead-item>
                </ng-container>
              </surf-type-ahead-content>
            </surf-type-ahead>
            <span *ngIf="nameSearchInitialized && filteredTourName.length == 0"
                  style="color: red;">No results found</span>
          </div>
        </div>
      </div>

      <div class="c-dp-search__form-group c-dp-search__form-group--extended">

        <div class="c-dp-search__form-item">
          <div class="m-form-item">
            <div class="m-form-item__input auto-tour-departure-city">
              <surf-type-ahead
                [id]="'' + (index ? index+1 : 1)"
                [label]="'DEPARTURE CITY'"
                [filterType]="'strict'"
                [mappings]="['code', 'value']"
                [sortOrder]="'A2Z'"
                [selectedItem]="selectedDestination"
                [paramName]="'ontotext'"
                [placeholder]="departureCityPlaceholderText"
                [bindFocusOut]="false"
                [selectAllOnEnter]="false"
                [isOnto]="true"
                [isDisabled]="freeze || isDpGrab"
                [autoFocus]="!isRefine"
                [url]="destinationCityURL"
                [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                (onClickInside)="onClickInsideDestTypeAheadDepartureCity($event)"
                (onClickOutside)="onClickOutsideDestTypeAheadDepartureCity($event)"
                (returnedQuery)="returnedSearchQueryForDepartureCity($event)"
                (returnedData)="returnedDepartureCity($event)"
                (keydown)="onKeyDown($event, 0)"
                [tabIndex]="1">

                <surf-type-ahead-content
                  [typeAheadData]="filteredDestinationCity"
                  [available]="filteredDestinationCity?.length > 0"
                  [enableKeyStrokeIgnore]="true"
                  (setItem)="selectDestination($event)">

                  <ng-container *ngFor="let item of filteredDestinationCity;let i = index">
                    <surf-type-ahead-item [isKeyStrokeIgnore]="true" *ngIf="item?.type == 20">
                      <div class="c-dp-search__list-item search-ineligible auto-country-item">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.type != 'CITY' && item?.type != 40" xlink:href="#country"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                          <span class="s5 ts-muted ts-wet"><span
                            *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                        </div>
                        <span *ngIf="item?.type != 'CITY' && item?.type != 40"
                              class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Country</span>
                      </div>

                    </surf-type-ahead-item>
                    <surf-type-ahead-item *ngIf="item?.type == 40">
                      <div class="c-dp-search__list-item auto-city-item" (click)="selectDestination(item)">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.type == 'CITY' || item?.type == 40" xlink:href="#map"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{item?.name}}</span>
                          <span class="s5 ts-muted ts-wet"><span
                            *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                        </div>
                        <span *ngIf="item?.type == 'CITY' || item?.type == 40"
                              class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                      </div>
                    </surf-type-ahead-item>
                    <surf-type-ahead-item *ngFor="let child of item?.children;let j = index;">
                      <ng-template [ngIf]="item?.type == 20">
                        <!--then iterate its children (CITIES) as sub components-->
                        <div (click)="selectDestination( addParentToLocationGroup(child, item) )"
                             class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-cities"
                             [ngClass]="{'c-dp-search__list-item--sub-last': j === item?.children.length - 1}">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use xlink:href="#map"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                            <span class="s5 ts-muted ts-wet"><span
                              *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                          </div>
                        </div>
                      </ng-template>
                    </surf-type-ahead-item>
                  </ng-container>
                </surf-type-ahead-content>
              </surf-type-ahead>
              <span *ngIf="destSearchInitiated && filteredDestinationCity.length == 0"
                    style="color: red;">No results found</span>
            </div>
          </div>
        </div>
        <div class="c-dp-search__form-item">
          <div class="c-dp-search__form-item c-dp-search__form-item--desc auto-tour-duration">
            <surf-multi-field-input
              [properties]="durationProperty"
              [componentValue]="durationValues"
              [title]="'Duration'"
              [disabled]="freeze || isDpGrab"
              [error]="durationError"
              [msg]="'Invalid duration value'"
              (emitCombinedOutEvent)="setDuration($event)">
            </surf-multi-field-input>
          </div>
        </div>

      </div>

      <div class="c-dp-search__form-item">
        <div class="m-form-item">
          <div class="m-form-item__input auto-tour-category">
            <div class="c-dp-search__form-item c-dp-search__form-item--desc">
              <div class="m-form-item">
                <div class="m-form-item__input auto-tour-category">
                  <surf-type-ahead
                    [id]="'' + (index ? index+3 : 3)"
                    [label]="'CATEGORY'"
                    [filterType]="'strict'"
                    [mappings]="['code', 'value']"
                    [sortOrder]="'A2Z'"
                    [selectedItem]="selectedTourCategory"
                    [paramName]="'ontotext'"
                    [placeholder]="categoryPlaceholderText"
                    [isMultiSelect]="true"
                    [multiSelectedItems]="categoryOntologies"
                    [bindFocusOut]="false"
                    [selectAllOnEnter]="false"
                    [isOnto]="true"
                    [isDisabled]="categoryOntologies.length>=TOUR_CATEGORY_SELECT_MAX || freeze || isDpGrab"
                    [autoFocus]="!isRefine"
                    [url]="destinationCategoryURL"
                    [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH"
                    (onChipClose)="removeSelectedTourCategory($event)"
                    (returnedQuery)="returnedSearchQueryForCategory($event)"
                    (returnedData)="returnedTourCategory($event)"
                    (keydown)="onKeyDown($event, 0)"
                    [tabIndex]="1">

                    <surf-type-ahead-content
                      [typeAheadData]="filteredTourCategory"
                      [available]="filteredTourCategory?.length > 0"
                      [enableKeyStrokeIgnore]="true"
                      (setItem)="selectTourCategory($event)">

                      <ng-container *ngFor="let item of filteredTourCategory;let i = index">
                        <surf-type-ahead-item *ngIf="item?.type == 230 || item?.type == 240">
                          <div
                            (click)="selectTourCategory(item)"
                            class="c-dp-search__list-item auto-location">

                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{item?.name | surfTextCase: 'title'}}</span>
                            </div>
                            <span class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Category</span>
                          </div>
                        </surf-type-ahead-item>
                      </ng-container>
                    </surf-type-ahead-content>
                  </surf-type-ahead>
                  <span *ngIf="categorySearchInitialized && filteredTourCategory.length == 0"
                        style="color: red;">No results found</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="c-dp-search__msg-block" *ngIf="grabBlockDetails && grabBlockDetails.code">
          <div class="o-block c-msg-block c-msg-block--error">
            <div class="l-inline-block">
              <svg class="a-icon c-msg-block__info-icon">
                <use xlink:href="#error"></use>
              </svg>
              <div class="c-msg-block__info">
                <div class="c-msg-block__info-txt cap-bold">{{grabBlockDetails.name}} Please remove this item to continue with the flow.</div>
              </div>
            </div>
          </div>
        </div>

    </div>
    </div>
    <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
      <svg class="a-icon">
        <use xlink:href="#close"></use>
      </svg>
    </div>

  </div>

  <div class="o-block c-msg-block c-msg-block--info u-mt-l" *ngIf="!isDPSearch && !isServiceAddComponent && isGrabbed && displayingGrabbedTourDetailList && displayingGrabbedTourDetailList.length > 1">
    <div class="l-inline-block">
      <svg class="a-icon c-msg-block__info-icon">
        <use xlink:href="#info"></use>
      </svg>
      <div class="c-msg-block__info">
        <div class="c-msg-block__info-txt cap-bold">Please note there are multiple tours on the grabbed booking number. Only one tour can be grabbed at a time.</div>
      </div>
    </div>
  </div>
  <div class="c-advance-search__btn-wrapper"  *ngIf = "!isDPSearch">
    <div class="c-advance-search__search-info">
      <svg class="a-icon search-info--icon" *ngIf = "!isServiceAddComponent && !isGrabbed">
        <use xlink:href="#info"></use>
      </svg>
      <span *ngIf = "!isServiceAddComponent && !isGrabbed">Please select your <b>Tour Stop</b> & <b>Travel Month/Date </b> details to continue search</span>
    </div>
    <div *ngIf="!isRefine" class="c-advance-search__search-action">
      <ng-container *ngIf="isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-cancel-btn" [tabIndex]="4">Cancel</button>
      </ng-container>
      <ng-container *ngIf="!isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-btn-reset" [tabIndex]="4">Reset Search</button>
      </ng-container>
      <button (click)="searchTour(false)" class="a-btn a-btn--primary auto-btn-search" [tabIndex]="5"
              [ngClass]="{'is-disabled':!validateInputs()}">Search
      </button>
    </div>
    <div *ngIf="isRefine" class="c-advance-search__search-action">
      <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default auto-btn-cancel">Back to results</button>
      <button (click)="searchTour(false)" class="a-btn a-btn--primary auto-btn-refine"
              [ngClass]="{'is-disabled':!validateInputs()}">
        Refine Search
      </button>
    </div>
  </div>

</div>
