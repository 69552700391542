/*
 * Public API Surface of surf-dp-template
 */
export * from './lib/dp-template/dp-template.component';
export * from './lib/util/factories/module-config-parser-factory';
export * from './lib/util/moduleConfigParsers/abstract-module-config-parser';
export * from './lib/util/moduleConfigParsers/dp-template.config';
export * from './lib/util/moduleConfigParsers/module-config-enum';
export * from './lib/util/moduleConfigParsers/module-config-other-parameters-enum';
export * from './lib/surf-dp-template.module';
