import { NgModule } from '@angular/core';
import { CarCriteriaComponent } from './car-criteria/car-criteria.component';
import { CarCriteriaDpComponent } from './car-criteria-dp/car-criteria-dp.component';
import {CommonModule} from '@angular/common';
import {SurfTypeAheadModule} from '@surf/surf-type-ahead';
import {SurfInputModule} from '@surf/surf-input';
import {SurfDatePickerModule} from '@surf/surf-date-picker';
import {SurfCalendarModule} from '@surf/surf-calendar';
import {SurfComponentsCoreModule, SurfCriteriaUtil, SurfTextCasePipeModule} from '@surf/surf-components-core';
import {SurfPaxSelectionModule} from '@surf/surf-pax-selection';
import {SurfMultiFieldInputModule} from '@surf/surf-multi-field-input';
import {SurfDropDownModule} from '@surf/surf-drop-down';
import {SurfDPAddBarModule} from '@surf/surf-dp-add-bar';
import {SurfMultiSelectorDropDownModule} from "@surf/surf-multi-selector-drop-down";

@NgModule({
  declarations: [CarCriteriaComponent, CarCriteriaDpComponent],
  imports: [
    CommonModule,
    SurfTypeAheadModule,
    SurfInputModule,
    SurfDatePickerModule,
    SurfCalendarModule,
    SurfTextCasePipeModule,
    SurfPaxSelectionModule,
    SurfComponentsCoreModule,
    SurfMultiFieldInputModule,
    SurfDropDownModule,
    SurfDPAddBarModule,
    SurfMultiSelectorDropDownModule
  ],
  exports: [CarCriteriaComponent, CarCriteriaDpComponent],
  providers:[SurfCriteriaUtil]
})
export class SurfCarCriteriaModule { }
