import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  Configurations,
  SessionStorage,
  SurfAuthenticationService,
  SurfComponentsConstants,
  SurfCookieNames, SurfRedirectUrlCaptureServiceService, SurfUtilService
} from '@surf/surf-components-core';
import {MasterDataStoreService} from '@surf/surf-state-manage';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { AppContext } from '@tc-core/model/it/codegen/ui/app-context';
import { Scaffolding } from '@tc-core/model/it/codegen/ui/framework/scaffolding';
import { TCRequestOptions } from '@tc-core/model/it/codegen/ui/request-option';
import { TC } from '@tc-core/util/constants';
import { DataServiceHandler } from '@tc-core/service/service-handlers';
import { LogStore } from '@tc-core/util/framework';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { DataKey, DataStore } from '@tc-core/util/framework/data-store.service';
import { CookieService as NGXCoockieServive } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment.app';
import { CommonHelper } from '@tc-core/util/helpers';
import { SurfGuestDetailsUtil } from '@surf/surf-guest-details';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';

@Injectable()
export class AppService {

  appContext: AppContext;
  currentURL: string = null;
  scaffStructure: BehaviorSubject<Scaffolding[]> = new BehaviorSubject([]);
  private userNameCookie = 'X-rst-username';
  private tokenCookieName = 'X-rst-session';
  private brandCookie;
  private xsrfToken;
  private brand;
  DOMAIN_IDP_MAPPING: string = 'okta-:OKTA_CLIENT_SURF';
  API_RESPONSE_CACHING_TTL = '';

  constructor(
    private configLoader: ConfigLoader,
    private dataStore: DataStore,
    private httpNew: HttpClient,
    private injector: Injector,
    private cookieService: NGXCoockieServive,
    private logStore: LogStore,
    private surfUtilService: SurfUtilService,
    private surfRedirectUrlCaptureServiceService: SurfRedirectUrlCaptureServiceService,
    private masterDataStoreService: MasterDataStoreService,
  ) {
    this.appContext = new AppContext();
    this.brandCookie = SurfCookieNames.BRAND_COOKIE;
    this.surfUtilService.clearDashboardCacheData();
  }

  public preLoadConfig() {
    const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
    requestOptionsArgs.headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = '/gateway/data/preLogin';
    return new Promise((resolve, reject) => {
      return this.httpNew.get(url).subscribe(
        (data: any) => {
          if (data && data.status && data.status.code === 1) {
            const config: any = data.data[0];
            this.surfUtilService.addSessionData(SessionStorage.BL_PRE_LOGIN, JSON.stringify(config));
            if (config) {
              this.DOMAIN_IDP_MAPPING = config.DOMAIN_IDP_MAPPING;
              resolve(true);
            }
          }
        },
        (error) => {
        }
      );
    });
  }

  public checkValid() {
    const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
    requestOptionsArgs.headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = '/gateway/authorize/valid';
    return new Promise((resolve, reject) => {
      return this.httpNew.get(url).subscribe(
        (data: any) => {
          resolve(true);
        },
        (error) => {
          this.surfRedirectUrlCaptureServiceService.addRedirectData(window.location.href);
          this.userLogout();
        }
      );
    });
  }

  public loadConfigurations() {
    return new Promise((resolve, reject) => {
      this.configLoader.loadFromBaseConfig(environment.base_config)
          .then(
            resp => {
              // load scaffolding config
              const results: Array<Scaffolding> = this.configLoader
                                                      .configurations
                                                      .get('SCAFFOLDING_CONFIG');
              this.scaffStructure.next(results);

              resolve(true);
            },
            (error) => {
              this.userLogout();
            }
          );
    });
  }

  public getXsrfTokenVal(): string {
    return this.xsrfToken;
  }

  public setXsrfTokenVal(token: string) {
    this.xsrfToken = token;
  }

  public captureUserCookies() {
    const authenticationService = this.injector.get(SurfAuthenticationService);
    let devMode = false;
    if (!environment.production) {
      devMode = true;
    }
    authenticationService.captureUserCookies(devMode);
  }

  public userLogin() {
    const authenticationService = this.injector.get(SurfAuthenticationService);
    this.DOMAIN_IDP_MAPPING = this.configLoader.getModuleConfig(
      Configurations.DOMAIN_IDP_MAPPING,
      TC.MODULE_NAME.SURF_B2B
    );
    let devMode = false;
    if (!environment.production) {
      devMode = true;
    }
    if (authenticationService.userLogin(devMode)) {
      if (authenticationService.getXsrfToken()) {
        this.setXsrfTokenVal(authenticationService.getXsrfToken());
      }
      let username = null;
      if (this.cookieService.get('X-rst-username')) {
        username = this.cookieService.get('X-rst-username');
      }
      if (this.cookieService.get(this.brandCookie)) {
        this.setBrand(this.cookieService.get(this.brandCookie));
      }

    } else {
      this.surfRedirectUrlCaptureServiceService.addRedirectData(window.location.href);
      this.userLogout();
    }
  }

  public userLogout(): void {
    const authenticationService = this.injector.get(SurfAuthenticationService);
    authenticationService.logoutUser('/gateway/authorize/logout', null, this.DOMAIN_IDP_MAPPING);
  }

  /**
   * Load method permissions
   */
  public loadPermissions() {
    let url = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.USER_RESTRICTIONS];
    const journey = this.getParameterByName('journey', window.location.href);
    let services = [];
    if (journey) {
      services = this.configLoader.conf([TC.CONF.CONF_JOURNEYS, journey.toUpperCase(), 'services']);
    }

    if (environment.mock) {
      url = url + '.mockdata.json';
    }

    return new Promise((resolve, reject) => {
      return this.httpNew.get(url, {
        params: {
          services: (services && Array.isArray(services) && services.length > 0) ? JSON.stringify(services) : null,
          modules: TC.MODULE_NAME.RESERVATION_SERVICE,
        }
      }).subscribe(
        (data: any) => {
          console.log(data);
          if (data && data.status && data.status.code === 1) {
            // this.dataStore.set(DataKey.moduleMethodPermissions, data.data.moduleMethodPermissions);
            this.dataStore.set('methodPermissions', data.data, false);
            resolve(true);
          }
        },
        (error) => {
          this.userLogout();
        }
      );
    });
  }

  public loadExternalScripts() {
    try {
      this.surfUtilService.loadExternalScripts();
    } catch (e) {
      console.log( 'Error Loading External Scripts', e);
    }
  }

  /**
   * Load user details fro jwt token
   */
  public loadUserDetail() {
    this.currentURL = window.location.href;
    const authenticationService = this.injector.get(SurfAuthenticationService);
    const session = authenticationService.getSession(this.currentURL);
    const userDetail = authenticationService.getUserFromJWT(session);
    if (!userDetail || !userDetail.user) {
      window.location.href  = authenticationService.getLoginPageUrl(this.DOMAIN_IDP_MAPPING);
    }
    this.dataStore.set(DataKey.userDetail, userDetail.user, true);
    return true;
  }

  /**
   * Load User Details from API
   */
  public loadUserDetailFromAPI() {
    this.currentURL = window.location.href;
    const authenticationService = this.injector.get(SurfAuthenticationService);
    let url = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.AUTH_USER_DETAIL];
    let username;
    if (this.currentURL && this.currentURL.includes(this.userNameCookie)) {
      const urlParams = new URLSearchParams(window.location.search);
      let userNameParam = urlParams.get(this.userNameCookie);
      username = userNameParam;
    } else if (this.cookieService.get(this.userNameCookie) || this.cookieService.get(this.userNameCookie) != '') {
      username = this.cookieService.get(this.userNameCookie);
    }
    if (!username) {
      //window.location.href  = authenticationService.getLoginPageUrl(this.DOMAIN_IDP_MAPPING);
      //TODO make url config
      url = '/gateway/authorize/userInfo';
    } else {
      url = url + '/' + username;
    }
    const sessionUserDetails = sessionStorage.getItem(SessionStorage.BL_LOGGED_USER_DETAILS);

    return new Promise((resolve, reject) => {
      if (sessionUserDetails && this.isGetDataFromSessionStorage(SessionStorageCacheRequests.USER)) {
        this.dataStore.set(DataKey.userDetail, JSON.parse(sessionUserDetails), true);
        this.surfUtilService.addSessionData(SessionStorage.BL_LOGGED_USER_DETAILS, sessionUserDetails);
        resolve(true);
      } else {
        return this.httpNew.get(url).subscribe(
          (data: any) => {
            if (data && data.status && data.status.code === 1) {
              const user: User = this.surfUtilService.updateUserChannel(data.data[0]);
              if (user && user.id && user.userSummary && user.userSummary.username) {
                this.dataStore.set(DataKey.userDetail, user, true);
                resolve(true);
                const userDetail = this.dataStore.get(DataKey.userDetail).getValue();
                this.surfUtilService.addSessionData(SessionStorage.BL_LOGGED_USER_DETAILS, JSON.stringify(userDetail));
                this.updateSessionStorageUpdateTime(SessionStorageCacheRequests.USER);
                if (!userDetail || !userDetail.b2bUser) {
                  if (this.cookieService.get(this.userNameCookie)) {
                    this.cookieService.delete(this.userNameCookie);
                  }
                  if (this.cookieService.get(this.tokenCookieName)) {
                    this.cookieService.delete(this.tokenCookieName);
                  }
                  if (!userDetail) {
                    this.userLogout();
                  }
                }
              }
            } else {
              window.location.href = authenticationService.getLoginPageUrl(this.DOMAIN_IDP_MAPPING);
            }
          },
          (error) => {
            window.location.href = authenticationService.getLoginPageUrl(this.DOMAIN_IDP_MAPPING);
          }
        );
      }
    });
  }

  loadClient() {
    let url = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.GET_CLIENT];
    if (this.dataStore.get(DataKey.userDetail)) {
      const userDetail = this.dataStore.get(DataKey.userDetail).getValue();
      const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
      requestOptionsArgs.headers = new HttpHeaders({'Content-Type': 'application/json'});
      url = url + '/' + userDetail.clientId;
      return new Promise((resolve, reject) => {
        return this.httpNew.get(url).subscribe(
          (data: any) => {
            if (data && data.status && data.status.code === 1) {
              const client: any = this.surfUtilService.updateClientChannel(data.data[0]);
              if (client) {
                this.dataStore.set(DataKey.tradeClient, client, true);
                this.surfUtilService.addSessionData(SessionStorage.BL_LOGGED_USER_CLIENT, JSON.stringify(client));
                resolve(true);
              }
            }
          },
          (error) => {
            this.userLogout();
          }
        );
      });
    }
  }

  loadEmuClient() {
    if (sessionStorage.getItem('emulating') && sessionStorage.getItem('emulatingTCProfile')) {
      let url = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.GET_CLIENT];
      const emuClientId = sessionStorage.getItem('emulatingTCProfile');
      const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
      requestOptionsArgs.headers = new HttpHeaders({'Content-Type': 'application/json'});
      url = url + '/' + emuClientId;
      return new Promise((resolve, reject) => {
        return this.httpNew.get(url).subscribe(
          (data: any) => {
            if (data && data.status && data.status.code === 1) {
              const client: any = data.data[0];
              if (client) {
                this.dataStore.set(DataKey.emulatingTCProfile, client, true);
                this.dataStore.set(DataKey.emulating, true);
                resolve(true);
              }
            }
          },
          (error) => {
            this.userLogout();
          }
        );
      });
    }
  }

  public loadModuleConfigurations() {
    const moduleConfigs = sessionStorage.getItem('blModuleConfigs');
    const url = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.MODULE_CONFIGURATIONS];

    const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
    requestOptionsArgs.headers = new HttpHeaders({'Content-Type': 'application/json'});
    requestOptionsArgs.params = new HttpParams();
    requestOptionsArgs.params = requestOptionsArgs.params.append('module', TC.MODULE_NAME.SURF_B2B);

    return new Promise((resolve, reject) => {
      if (!(moduleConfigs && this.isGetDataFromSessionStorage(SessionStorageCacheRequests.MODULE))) {
        this.configLoader.loadModuleConfigurations(url, TC.MODULE_NAME.SURF_B2B, requestOptionsArgs)
          .then(
            data => {
              this.dataStore.set(DataKey.moduleConfiguration, data);
              this.surfUtilService.addSessionData(SessionStorage.BL_MODULE_CONFIGS, JSON.stringify(data));
              this.updateSessionStorageUpdateTime(SessionStorageCacheRequests.MODULE);
              resolve(true);
            },
            (error) => {
              this.userLogout();
            }
          );
      } else {
        const  configs = JSON.parse(sessionStorage.getItem(SessionStorage.BL_MODULE_CONFIGS));
        this.dataStore.set(DataKey.moduleConfiguration, configs);
        const configurationsMap = new Map();
        configs.forEach((config) => {
          configurationsMap.set(config.code, config.name);
        });
        this.configLoader.setModuleConfigs(TC.MODULE_NAME.SURF_B2B, configurationsMap);
        this.surfUtilService.addSessionData(SessionStorage.BL_MODULE_CONFIGS, JSON.stringify(configs));
        resolve(true);
      }
      }
    );
  }

  public loadFeatureConfigs() {
    try {
      // covert the array => object, to reduce the time complexity
      const configs = this.dataStore.get(DataKey.moduleConfiguration).getValue()
        .reduce((map, {code, name}) => {
          map[code] = name;
          return map;
        }, {});

      const userDetail = this.dataStore.get(DataKey.userDetail).getValue();
      const isBetaMode = ((configs?.SURF_B2B_BETA_MODE_ENABLED?.toString().toUpperCase() === 'TRUE') && (sessionStorage.getItem('beta_mode') === 'true'));
      const betaConfigs = JSON.parse(configs?.SURF_B2B_BETA_MODE_FEATURES?.toString());
      const enabledUsers = new Set(configs?.SURF_B2B_BETA_MODE_USERS?.trim()?.split(','));

      if (isBetaMode && betaConfigs && enabledUsers &&
        (enabledUsers?.has(userDetail.userSummary.username) || enabledUsers?.has('OPT_IN_USERS') || enabledUsers?.has('ALL')) ) {
        // overrider the beta configs
        betaConfigs.forEach(item => {
            configs[item.code] = item?.name || item?.value;
        });
        // convert back to array
        const overriddenConfig = Object.keys(configs).map(key => ({code: key, name: configs[key]}));
          // update the session store and the data store
        this.dataStore.set(DataKey.moduleConfiguration, overriddenConfig);
        this.configLoader.setModuleConfigs(TC.MODULE_NAME.SURF_B2B, new Map(Object.entries(configs)));
        this.surfUtilService.addSessionData(SessionStorage.BL_MODULE_CONFIGS, JSON.stringify(overriddenConfig));
      }
    } catch (e) {
      // log error if needed
    }
  }

  public loadLoggerConfigs() {
    if (this.configLoader.configurations.get(TC.CONF.CONF_LOG_CONFIG)) {
      this.surfUtilService.addSessionData(SessionStorage.BL_LOGGER_CONF, JSON.stringify(this.configLoader.configurations.get(TC.CONF.CONF_LOG_CONFIG)));
    }
  }

  private getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public getBrand() {
    return this.brand;
  }

  public setBrand(value) {
    this.brand = value;
  }

  public async loadPaxTypeAges() {
    const sessionPaxTypeAgeConfigs = sessionStorage.getItem(SessionStorage.BL_PAX_TYPE_AGES);
    const promise = new Promise((resolve) => {
      if (sessionPaxTypeAgeConfigs && this.isGetDataFromSessionStorage(SessionStorageCacheRequests.PAX_TYPE_AGES)) {
        const paxTypeAges: any[] = JSON.parse(sessionPaxTypeAgeConfigs);
        this.masterDataStoreService.setPaxSubTypeAges([{brand: null, paxTypes: paxTypeAges}]);
        this.dataStore.set(DataKey.paxSubTypeAges, [{brand: null, paxTypes: paxTypeAges}]);
        this.surfUtilService.addSessionData(SessionStorage.BL_PAX_TYPE_AGES, JSON.stringify(paxTypeAges));
        SurfGuestDetailsUtil.MAX_CHILD_AGE = Math.floor(paxTypeAges.find(paxTypeAge => paxTypeAge.code ===
          'C').age.upper / 12);
        SurfGuestDetailsUtil.MAX_TEEN_AGE = Math.floor(paxTypeAges.find(paxTypeAge => paxTypeAge.code ===
          'T').age.upper / 12);
        if (paxTypeAges.findIndex(paxTypeAge => paxTypeAge.code === 'T') > -1) {
          SurfGuestDetailsUtil.TEEN_AGE_AVAILABLE = true;
        }
        SurfGuestDetailsUtil.MAX_INFANT_AGE = paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
        SurfGuestDetailsUtil.MIN_ADULT_AGE = paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'A').age.lower /
          12;
        resolve();
      } else {
        this.injector.get(DataServiceHandler).getPaxSubTypeAges().subscribe(
          res => {
            if (this.injector.get(CommonHelper).dataValidity(res)) {
              const paxTypeAges: any[] = res[0].paxTypes;
              this.masterDataStoreService.setPaxSubTypeAges(res);
              this.surfUtilService.addSessionData(SessionStorage.BL_PAX_TYPE_AGES, JSON.stringify(paxTypeAges));
              this.updateSessionStorageUpdateTime(SessionStorageCacheRequests.PAX_TYPE_AGES);
              SurfGuestDetailsUtil.MAX_CHILD_AGE = Math.floor(paxTypeAges.find(paxTypeAge => paxTypeAge.code ===
                'C').age.upper / 12);
              SurfGuestDetailsUtil.MAX_TEEN_AGE = Math.floor(paxTypeAges.find(paxTypeAge => paxTypeAge.code ===
                'T').age.upper / 12);
              if (paxTypeAges.findIndex(paxTypeAge => paxTypeAge.code === 'T') > -1) {
                SurfGuestDetailsUtil.TEEN_AGE_AVAILABLE = true;
              }
              SurfGuestDetailsUtil.MAX_INFANT_AGE = paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
              SurfGuestDetailsUtil.MIN_ADULT_AGE = paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'A').age.lower /
                12;
              resolve();
            } else if (res instanceof TcApiError || res instanceof TcHttpError) {
              resolve();
            }
          },
          error => {
            resolve();
            console.log('Error in getting pax sub type ages');
          }
        );
      }
    });
    return promise;

   /* if (sessionStorage.getItem(SessionStorage.BL_PAX_TYPE_AGES)) {
      const paxTypeAges = JSON.parse(sessionStorage.getItem(SessionStorage.BL_PAX_TYPE_AGES));
      this.dataStore.set(DataKey.moduleConfiguration, paxTypeAges);
      SurfGuestDetailsUtil.MAX_CHILD_AGE = Math.floor(paxTypeAges.find(paxTypeAge => paxTypeAge.code ===
        'C').age.upper / 12);
      SurfGuestDetailsUtil.MAX_TEEN_AGE = Math.floor(paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'T').age.upper /
        12);
      if (paxTypeAges.findIndex(paxTypeAge => paxTypeAge.code === 'T') > -1) {
        SurfGuestDetailsUtil.TEEN_AGE_AVAILABLE = true;
      }
      SurfGuestDetailsUtil.MAX_INFANT_AGE = paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
      SurfGuestDetailsUtil.MIN_ADULT_AGE = paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'A').age.lower / 12;
    }*/
  }

  isGetDataFromSessionStorage(request: string): boolean {
    let isGetFromSession = false;
    const moduleConfigs = sessionStorage.getItem('blModuleConfigs');

    if (moduleConfigs && !this.API_RESPONSE_CACHING_TTL && moduleConfigs.includes('"code":"API_RESPONSE_CACHING_TTL"')) {
      const TTL_CONFIG_DATA = (JSON.parse(moduleConfigs)).find(config => {
        return config.code === 'API_RESPONSE_CACHING_TTL';
      });
      if (TTL_CONFIG_DATA) {
        this.API_RESPONSE_CACHING_TTL = TTL_CONFIG_DATA.name;
      }
    }
    if (this.API_RESPONSE_CACHING_TTL) {
      const requests = this.API_RESPONSE_CACHING_TTL.split(',');
      const selectedRequest = requests.find(req => {
        return req?.split('~')[0] === request;
      });
      const apiRequestTime = sessionStorage.getItem('API_REQUEST_DATE_TIME') ?
        JSON.parse(sessionStorage.getItem('API_REQUEST_DATE_TIME')) : '';
      if (selectedRequest && selectedRequest.split('~').length > 1 && !isNaN(parseInt(selectedRequest.split('~')[1], 10)) &&
        apiRequestTime) {
        const selectedRequestName = selectedRequest.split('~')[0];
        const selectedRequestTime = selectedRequest.split('~')[1];
        if (selectedRequestName === SessionStorageCacheRequests.MODULE && apiRequestTime.module && parseInt(selectedRequestTime, 10) > 0) {
          if ((new Date(new Date(apiRequestTime.module).getTime() + parseInt(selectedRequestTime, 10) * 60000)) > new Date()) {
            isGetFromSession = true;
          }
        }
        if (selectedRequestName === SessionStorageCacheRequests.USER && apiRequestTime.user && parseInt(selectedRequestTime, 10) > 0) {
          if ((new Date(new Date(apiRequestTime.user).getTime() + parseInt(selectedRequestTime, 10) * 60000)) > new Date()) {
            isGetFromSession = true;
          }
        }
        if (selectedRequestName === SessionStorageCacheRequests.PAX_TYPE_AGES && apiRequestTime.paxTypeAges && parseInt(selectedRequestTime, 10) > 0) {
          if ((new Date(new Date(apiRequestTime.paxTypeAges).getTime() + parseInt(selectedRequestTime, 10) * 60000)) > new Date()) {
            isGetFromSession = true;
          }
        }
      }
    }
    return isGetFromSession;
  }

  updateSessionStorageUpdateTime(request: string) {
    const apiRequestTime = sessionStorage.getItem('API_REQUEST_DATE_TIME') ? JSON.parse(sessionStorage.getItem('API_REQUEST_DATE_TIME')) :
      {module : new Date().toString(), user : new Date().toString(), paxTypeAges : new Date().toString()};
    if (request === SessionStorageCacheRequests.MODULE) {
      apiRequestTime.module = new Date().toString();
    } else if (request === SessionStorageCacheRequests.USER) {
      apiRequestTime.user = new Date().toString();
    } else if (request === SessionStorageCacheRequests.PAX_TYPE_AGES) {
      apiRequestTime.paxTypeAges = new Date().toString();
    }
    sessionStorage.setItem('API_REQUEST_DATE_TIME', JSON.stringify(apiRequestTime));
    this.surfUtilService.addSessionData('API_REQUEST_DATE_TIME', JSON.stringify(apiRequestTime));
  }
}

export const SessionStorageCacheRequests = {
  MODULE: 'MODULE',
  USER: 'USER',
  PAX_TYPE_AGES: 'PAX_TYPE_AGES'
}
