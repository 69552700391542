<div [ngClass]="{'c-advance-search__content': !isDPSearch}">
  <ng-container *ngIf="!isServiceAddComponent; else elseblock">
    <div class="c-advance-search__content-top u-mb-" *ngIf="!isDPSearch">

      <button class="a-btn a-btn--link c-pkg-search__back-btn" (click)="closeCriteriaBox()">
        <svg class="a-icon u-mr">
          <use xlink:href="#previous"></use>
        </svg>
      </button>

      <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Search flights for</h3>
      <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine your flight search</h3>
      <div class="c-advance-search__guests">
        <div class="m-form-item" (click)="scrollTo('#scroll')" *ngIf="!isPnr">
          <surf-pax-selection
            [(isFocused)]="guestComponentFocus"
            [paxSet]="defaultPaxSet"
            [b2bView]="true"
            [roomViewSelected]="setRoomWise"
            [product]="'FLT'"
            [maxAdult]="FLIGHT_MAX_ADULT"
            [inputWrapperView]="false"
            [minAdult]="FLIGHT_MIN_ADULT"
            [maxPaxCount]="FLIGHT_MAX_PAX_COUNT"
            [maxCabinPaxCount]="FLIGHT_MAX_CABIN_PAX_COUNT"
            [maxChildren]="FLIGHT_MAX_CHILD"
            [maxTeen]="FLIGHT_MAX_TEEN"
            [maxInfants]="FLIGHT_MAX_INFANTS"
            [maxInfantAge]="MAX_INFANT_AGE"
            [maxChildAge]="MAX_CHILD_AGE"
            [maxTeenAge]="MAX_TEEN_AGE"
            [maxCabins]="1"
            [countInfants]="true"
            [parentScrollId]="windowScrollEnable ? 'scroll': null"
            [isTeenActive]="TEEN_PAX_ACTIVE"
            (paxSelection)="getPassengerSelection($event)"
            [tabIndex]="2"
            [infantSeatOption]="true"
            (currentOption)="getCurrentOption($event)"
            (keydown)="onKeyDown($event, 1)">
          </surf-pax-selection>
        </div>
        <span class="surf-pax-selection" *ngIf="isPnr">
        <div class="m-form-item">
          <div class="m-form-item__input surf-pax-selection__input auto-btn-search-for-close">
            <span class="m-form-field__field-item" style="border: none; background-image: none">
              <input class="m-form-field__input" readonly="true" size="8"
                     value="{{pnrPassengerCount}} Guest{{pnrPassengerCount > 1 ? 's' : ''}}">
            </span>
          </div>
        </div>
      </span>
      </div>
        <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
          <svg class="a-icon o-modal__close-icon">
            <use xlink:href="#close"></use>
          </svg>
        </button>
    </div>
  </ng-container>
  <ng-template #elseblock>
<!--    <span class="s5">Existing passenger combination will be applied to the newly adding component</span>-->
    <div class="c-advance-search__content-top">
      <button class="a-btn a-btn--link o-modal__inner-close" (click)="resetInputs()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
      <h3 class="t2 c-advance-search__title">Define your search for</h3>
      <div class="c-advance-search__guests">
      <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
      </div>
    </div>
  </ng-template>
           <!--Content top : hidden in DP-->

  <!--This is for the departure flight-->
  <ng-template [ngIf]="flightItemList && flightItemList.length > 0">

    <div class="c-advance-search__form" *ngIf="!isDPSearch ">
<!--      this is commented because not need in kuoni-->

<!--      <div class="c-advance-search__grab-pnr auto-pnr-button" *ngIf = "!isServiceAddComponent">-->
<!--        <surf-grab-pnr-->
<!--          *ngIf="enableGrabPnr"-->
<!--          [grabType]="pnrType"-->
<!--          [selectedPnrNumber]="selectedPnrNumber"-->
<!--          [selectedGDS]="selectedGDS"-->
<!--          [reset]="pnrReset"-->
<!--          [isRefine]="isRefine"-->
<!--          (flightPnrResultOutput)="setFlightGrabPnrResult($event)"-->
<!--          (showManualItemOutput)="setManualItemConfig($event)"-->
<!--          (pnrComponentOutput)="setGrabPNRCriteria($event)"-->
<!--        ></surf-grab-pnr>-->
<!--      </div>-->
      <div class="c-dp-search">
        <ng-container  class="auto-dp-add-bar">
          <surf-dp-add-bar *ngIf="!isDPSearch"
                           [isDPSearch]="isDPSearch"
                           [isIntro]="false"
                           [id] = "'addBarFirst'"
                           [preItemIndex]="-1"
                           [postItemIndex]="0"
                           [msg]="msg"
                           [totalItemCount]="flightItemList.length"
                           [ngClass]="{'u-disabled': DISABLE_DP_SWITCH && flightItemList.length == MAX_FLIGHT_LEGS }"
                           [isDisabled]="isServiceAddComponent? false : DISABLE_DP_SWITCH"
                           [disableDPSwitch]="isPnr? true : DISABLE_DP_SWITCH"
                           (click)="scrollTo($event,'#'+refineSearchScrollId);moveToDP('pre')"
                           (addItem)="addAnotherCriteriaItem($event, 'pre')">
          </surf-dp-add-bar>
        </ng-container>
        <ng-template ngFor let-item [ngForOf]="flightItemList" let-i="index">

          <div class="c-dp-search__block c-dp-search__block--flight auto-serach-card is-selected" [id]="'leg' + i" (click)="scrollTo($event,'#' + refineSearchScrollId)"
               [ngClass]="{'is-selected': item.isSelected}" (click)="selectDepartureComponent(item)"
               (clickOutside)="deselectDepartureComponent(item)">
            <div class="c-dp-search__action">
              <div class="c-dp-search__block-icon">
                <svg class="a-icon">
                  <use xlink:href="#products--flight"></use>
                </svg>
              </div>
            </div>

            <div class="c-dp-search__form c-dp-search__form--flight">

              <div class="c-dp-search__form-item auto-multi-field-input auto-location-list" (clickOutside)="deselectDepartureMultiFieldInput(item)">
                <surf-multi-field-input
                  [typeAheadAvailable]="true"
                  [useEnterFocus]="true"
                  [inputFocus]="true"
                  [focusInit]="returnFirstItem(i)"
                  (isLastElement)="ShowLastElement($event)"
                  [properties]="item.multiFieldInputProperty"
                  [componentValue]="departureTypeAheadComponentValue"
                  [onChange]="false"
                  [showTypeAheadContent]="item.showTypeAheadContent"
                  (emitFilteredList)="getDepartureTypeAheadData($event, item)"
                  [error]="item.typeAheadHasError"
                  [msg]="item.typeAheadErrorMsg"
                  [disabled]="disableAllInputs"
                  (keyup)="onKeyPress($event, 0, i)"
                  (emitIdOnFocusIn)="handleInputFocusIn($event, item)"
                  (emitIdOnFocusOut)="handleInputFocusOut($event, item)">

                  <surf-type-ahead-content *ngIf="item.showTypeAheadContent"
                                           [typeAheadData]="filteredDepartureList"
                                           [isPressEsc]="departureReturnedProperty && departureReturnedProperty.id === 'DEPARTURE_FROM' ? item.isPressEscFrom : departureReturnedProperty && departureReturnedProperty.id === 'DEPARTURE_TO' ? item.isPressEscTo : null"
                                           [selectedItem]="departureReturnedProperty && departureReturnedProperty.id === 'DEPARTURE_FROM' ? item.depObj : departureReturnedProperty && departureReturnedProperty.id === 'DEPARTURE_TO' ? item.arrObj : null"
                                           [available]="filteredDepartureList?.length > 0"
                                           (setItem)="handleDepartureSelection($event, item)">
                    <div *ngFor="let selection of filteredDepartureList;let i = index">
                      <surf-type-ahead-item>
                        <ng-template [ngIf]="selection?.type == 40">      <!--If this is a CITY -> show main item-->
                          <div (click)="handleDepartureSelection(selection, item)" class="c-dp-search__list-item auto-airports">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">All {{selection?.name | surfTextCase: 'title'}} Airports</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(selection)">{{surfCriteriaUtil.getState(selection) + ', '}}</span>{{getCountry(selection)}}</span>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template [ngIf]="selection?.type == 60">      <!--If this is an AIRPORT -> show as a main item-->
                          <div (click)="handleDepartureSelection(selection, item)" class="c-dp-search__list-item auto-airports" >
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use xlink:href="#products--flight"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{selection?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(selection)">{{surfCriteriaUtil.getState(selection) + ', '}}</span>{{getCountry(selection)}}</span>
                            </div>
                          </div>
                        </ng-template>
                      </surf-type-ahead-item>
                      <surf-type-ahead-item *ngFor="let child of selection?.children;let j = index;">
                        <ng-template [ngIf]="selection?.type == 40">  <!--then iterate its children (AIRPORTS) as sub components-->
                          <div (click)="handleDepartureSelection( addParentToAirport(child, selection), item)" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-airports" [ngClass]="{'c-dp-search__list-item--sub-last': j === selection?.children.length - 1}">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use xlink:href="#products--flight"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(selection)">{{surfCriteriaUtil.getState(selection) + ', '}}</span>{{getCountry(selection)}}</span>
                            </div>
                          </div>
                        </ng-template>

                      </surf-type-ahead-item>
                    </div>

                  </surf-type-ahead-content>

                </surf-multi-field-input>
              </div>

              <div class="c-dp-search__form-item auto-departure-datepicker">
                <div class="m-form-field">
                  <surf-date-picker [single_calendar]="true" [inputLabel]="'Departure Date*'" [reset]="item.dateReset"
                                    (selected_dates)="getSelectedDate($event,'departure', item)"
                                    [calendarStartDate]="(isForEpToDpConversion && index === 0) ? null : getPrevLegDate(i)"
                                    [calendar_start_date]="(isForEpToDpConversion && index === 0) ? calendarMinDateForInitialEpeToDpConversionCriteria : null"
                                    [isFocused]="item.isFocus"
                                    [enablePreviousDates]="true"
                                    [currentDateOfTimeZone]="calendarMinDate"
                                    [hideErrorMsg]="hideErrorMsg"
                                    [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : ''"
                                    [isError]="validateDisabledDateSelection && (!dpGrab)"
                                    [validateDisabledDateSelection]="validateDisabledDateSelection"
                                    [disabled]="disableAllInputs"
                                    [initial_dates]="item.departureDateObj"></surf-date-picker>
                </div>
              </div>

              <div class="c-dp-search__form-item auto-cabin-class-dropdown">
                <div class="m-form-field">
                  <surf-ul-drop-down
                    [label]="'Cabin Class'"
                    [labelStyle]="'m-form-field__label'"
                    [items]="item.cabinClassList"
                    [placeholder]="'Class'"
                    [triggerType]="'button'"
                    [listStyle]="'m-form-field__field'"
                    [required]="true"
                    (click) = "handleCabinClassDropDownClick($event)"
                    [native]="true"
                    [firstDisplay]="item.cabinClass? item.cabinClass: anyCabinClassString"
                    [select]=true
                    [reset]="cabinClassReset"
                    [disabled]="disableAllInputs"
                    (singleObject)="handleCabinClassSelection($event, item)">
                  </surf-ul-drop-down>
                </div>
              </div>

              <div class="c-dp-search__form-item auto-airline-input">
                <div class="m-form-field" (click)="scrollTo('#scroll')">
                  <surf-type-ahead
                    [label]="'Airline (Optional)'"
                    [dataList]="airlineList"
                    [filterType]="'strict'"
                    [mappings]="['code', 'value']"
                    [sortOrder]="'A2Z'"
                    [selectedItem]="item.airlineOntology"
                    [paramName]="'ontotext'"
                    [isMultiSelect]="true"
                    [multiSelectedItems]="item.airlineOntologies"
                    [placeholder]="'Search'"
                    [bindFocusOut]="false"
                    [selectAllOnEnter]="false"
                    [isOnto]="false"
                    [isDisabled]="disableAllInputs"
                    (onChipClose)="removeAirlineChip($event, item)"
                    [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH_AIRLINE"
                    (returnedData)="returnAirline($event, item)"
                    (returnedQuery)="returnedAirlineSearchQuery($event, item)"
                    (onClickOutside)="onAirlineClickOutside(item)">

                    <surf-type-ahead-content *ngIf="item.showAirlineTypeAheadContent"
                                             [typeAheadData]="filteredAirlineList"
                                             [available]="filteredAirlineList?.length > 0"
                                             (setItem)="selectAirline($event, item)">

                      <surf-type-ahead-item (click)="selectAirline(selection, item)" *ngFor="let selection of filteredAirlineList">
                        <div class="c-dp-search__list-item">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use xlink:href="#products--flight"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main auto-airline-list">
                            <span class="s4 ts-strong auto-list-title">{{selection?.value | surfTextCase: 'title'}}</span>
                          </div>
                        </div>

                      </surf-type-ahead-item>
                    </surf-type-ahead-content>
                  </surf-type-ahead>
                  <span *ngIf="item.isAirlineSearchInitiated && filteredAirlineList.length == 0" style="color: red;">No results found</span>
                </div>
              </div>

              <div class="c-dp-search__form-item auto-via-point-input">
                <div class="m-form-field" (click)="scrollTo('#scroll')">
                  <surf-type-ahead
                    [label]="'Via Points (Optional)'"
                    [filterType]="'strict'"
                    [mappings]="['code', 'value']"
                    [sortOrder]="'A2Z'"
                    [paramName]="'ontotext'"
                    [isMultiSelect]="true"
                    [selectedItem]="item.viaPointOntology"
                    [multiSelectedItems]="item.viaPointOntologies"
                    [placeholder]="'Search via points'"
                    [bindFocusOut]="false"
                    [selectAllOnEnter]="false"
                    [isOnto]="true"
                    [url]="viaPointsUrl"
                    [chipForceEnable]="true"
                    [isDisabled]="item.viaPoints?.length > 1 || disableAllInputs"
                    (onChipClose)="removeViaPointChip($event, item)"
                    [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH_VIA_POINTS"
                    (returnedData)="returnViaPoint($event, item)"
                    (returnedQuery)="returnedViaPointSearchQuery($event, item)"
                    (onClickOutside)="onViaPointClickOutside(item)">

                    <surf-type-ahead-content *ngIf="item.showViaPointTypeAheadContent"
                                             [typeAheadData]="filteredViaPointList"
                                             [available]="filteredViaPointList?.length > 0"
                                             (setItem)="selectViaPoint($event, item)">

                      <surf-type-ahead-item (click)="selectViaPoint(selection, item)" *ngFor="let selection of filteredViaPointList">

                        <div class="c-dp-search__list-item">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use xlink:href="#products--flight"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{selection?.name | surfTextCase: 'title'}}</span>
                          </div>
                        </div>


                      </surf-type-ahead-item>
                    </surf-type-ahead-content>
                  </surf-type-ahead>
                  <span *ngIf="item.isViaPointSearchInitiated && filteredViaPointList.length == 0" style="color: red;">No results found</span>
                </div>
              </div>

            </div>

            <div [hidden]="!isItemCloseable" class="c-dp-search__close auto-close-btn" *ngIf="isDPSearch || flightItemList?.length > 1" (click)="onFlightLegCloseClicked(i)">
              <svg class="a-icon">
                <use xlink:href="#close"></use>
              </svg>
            </div>

          </div>

          <ng-container class="auto-dp-add-bar-bottom">
            <surf-dp-add-bar *ngIf="!isDPSearch"
                             [isDPSearch]="isDPSearch"
                             [isIntro]="false"
                             [id] = "'addBar' + item?.index"
                             [preItemIndex]="item?.index"
                             [postItemIndex]="item?.index + 1"
                             [msg]="msg"
                             [totalItemCount]="flightItemList.length"
                             [disableDPSwitch]="isPnr? true : DISABLE_DP_SWITCH"
                             [isDisabled]="isServiceAddComponent? false : DISABLE_DP_SWITCH"
                             [ngClass]="{'u-disabled': DISABLE_DP_SWITCH && flightItemList.length == MAX_FLIGHT_LEGS }"
                             (click)="scrollTo($event, '#' + refineSearchScrollId);moveToDP(item?.index == (flightItemList.length - 1)? 'post' : item?.index)"
                             (addItem)="addAnotherCriteriaItem($event, item?.index == (flightItemList.length - 1)? 'post' : item?.index)">
            </surf-dp-add-bar>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <!--Flight criteria content : visible in Component flow-->

    <ng-container *ngIf="isDPSearch" class="auto-dp-add-bar">
      <surf-dp-add-bar *ngIf="!isDPSearch"
                       [isDPSearch]="true"
                       [isIntro]="false"
                       [preItemIndex]="-1"
                       [id] = "'addBarLast'"
                       [postItemIndex]="0"
                       [msg]="msg"
                       [totalItemCount]="flightItemList.length"
                       [ngClass]="{'u-disabled': DISABLE_DP_SWITCH && flightItemList.length == 2 }"
                       [isDisabled]="isPnr? true : DISABLE_DP_SWITCH"
                       [disableDPSwitch]="DISABLE_DP_SWITCH"
                       (click)="scrollTo($event, '#' + refineSearchScrollId);moveToDP('pre')"
                       (addItem)="addAnotherCriteriaItem($event, 'pre')">
      </surf-dp-add-bar>
      <ng-template ngFor let-item [ngForOf]="flightItemList" let-i="index">

        <div class="c-dp-search__block c-dp-search__block--flight auto-serach-card" [id]="'leg' + i"
             [ngClass]="{'is-selected': !isAmendmentFlow && item.isSelected, '':index%2 == 1}" (click)="selectDepartureComponent(item)"
             (clickOutside)="deselectDepartureComponent(item)">
          <div class="c-dp-search__action">
            <label *ngIf="isAmendmentFlow && !isItemCloseable" class="a-checkbox c-dp-search__action-check">
              <input disabled type="checkbox" class="a-checkbox__check" (change)="checkValue($event)" [checked]="!freeze">
              <span class="a-checkbox__label"></span>
            </label>
            <div class="c-dp-search__block-icon">
              <svg class="a-icon">
                <use xlink:href="#products--flight"></use>
              </svg>
            </div>
          </div>

          <div class="c-dp-search__form-wrap">
            <span class="a-loz a-loz--success c-dp-search__form-tag" *ngIf="isAmendmentFlow && isItemCloseable">Newly Added</span>
            <div class="c-dp-search__form c-dp-search__form--flight">
              <div class="c-dp-search__form-item auto-multi-field-input auto-location-list" (clickOutside)="deselectDepartureMultiFieldInput(item)">
                <surf-multi-field-input
                  [typeAheadAvailable]="true"
                  [properties]="item.multiFieldInputProperty"
                  [componentValue]="departureTypeAheadComponentValue"
                  [onChange]="false"
                  [showTypeAheadContent]="item.showTypeAheadContent"
                  (emitFilteredList)="getDepartureTypeAheadData($event, item)"
                  [error]="item.typeAheadHasError"
                  [msg]="item.typeAheadErrorMsg"
                  [disabled]="disableAllInputs || dpGrab || freeze"
                  (emitIdOnFocusIn)="handleInputFocusIn($event, item)"
                  (emitIdOnFocusOut)="handleInputFocusOut($event, item)">

                  <surf-type-ahead-content *ngIf="item.showTypeAheadContent"
                                           [typeAheadData]="filteredDepartureList"
                                           [available]="filteredDepartureList?.length > 0"
                                           (setItem)="handleDepartureSelection($event, item)">
                    <div *ngFor="let selection of filteredDepartureList;let i = index">
                      <surf-type-ahead-item (click)="handleDepartureSelection(selection, item)">

                        <ng-template [ngIf]="selection?.type == 40">      <!--If this is a CITY -> show main item-->
                          <div (click)="handleDepartureSelection(selection, item)" class="c-dp-search__list-item">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use xlink:href="#map"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">All {{selection?.name | surfTextCase: 'title'}} Airports</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(selection)">{{surfCriteriaUtil.getState(selection) + ', '}}</span>{{getCountry(selection)}}</span>
                            </div>
                          </div>

                        </ng-template>

                        <ng-template [ngIf]="selection.type == 60">      <!--If this is an AIRPORT -> show as a main item-->
                          <div (click)="handleDepartureSelection(selection, item)" class="c-dp-search__list-item" >
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use xlink:href="#products--flight"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{selection?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(selection)">{{surfCriteriaUtil.getState(selection) + ', '}}</span>{{getCountry(selection)}}</span>
                            </div>
                          </div>
                        </ng-template>

                      </surf-type-ahead-item>
                      <surf-type-ahead-item  *ngFor="let child of selection?.children;let j = index;">
                        <ng-template [ngIf]="selection?.type == 40">  <!--then iterate its children (AIRPORTS) as sub components-->
                          <div (click)="handleDepartureSelection( addParentToAirport(child, selection), item)" class="c-dp-search__list-item c-dp-search__list-item--sub auto-sub-airports" [ngClass]="{'c-dp-search__list-item--sub-last': j == selection?.children.length - 1}">
                            <svg class="a-icon c-dp-search__list-item-icon">
                              <use xlink:href="#products--flight"></use>
                            </svg>
                            <div class="m-info-set c-dp-search__list-item-main">
                              <span class="s4 ts-strong">{{child?.name | surfTextCase: 'title'}}</span>
                              <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(selection)">{{surfCriteriaUtil.getState(selection) + ', '}}</span>{{getCountry(selection)}}</span>
                            </div>
                          </div>
                        </ng-template>
                      </surf-type-ahead-item>
                    </div>
                  </surf-type-ahead-content>

                </surf-multi-field-input>
              </div>

              <div class="c-dp-search__form-item auto-departure-datepicker">
                <div class="m-form-field">
                  <surf-date-picker [single_calendar]="true" [inputLabel]="'Departure Date*'" [reset]="item.dateReset"
                                    (selected_dates)="getSelectedDate($event,'departure', item)"
                                    [calendarStartDate]="(isForEpToDpConversion && index === 0) ? null : getPrevLegDate(i)"
                                    [calendar_start_date]="(isForEpToDpConversion && index === 0) ? calendarMinDateForInitialEpeToDpConversionCriteria : null"
                                    [enablePreviousDates]="true"
                                    [currentDateOfTimeZone]="calendarMinDate"
                                    [hideErrorMsg]="hideErrorMsg"
                                    [invalidErrorMsg]="validateDisabledDateSelection ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : ''"
                                    [isError]="validateDisabledDateSelection && (!dpGrab)"
                                    [validateDisabledDateSelection]="validateDisabledDateSelection"
                                    [disabled]="disableAllInputs || dpGrab || freeze"
                                    [initial_dates]="item.departureDateObj"></surf-date-picker>
                </div>
              </div>

              <div class="c-dp-search__form-item auto-cabin-class-dropdown">
                <div class="m-form-field">
                  <surf-ul-drop-down
                    [label]="'Cabin Class'"
                    [labelStyle]="'m-form-field__label'"
                    [items]="item.cabinClassList"
                    [placeholder]="'Class'"
                    [triggerType]="'button'"
                    (click) = "handleCabinClassDropDownClick($event)"
                    [listStyle]="'m-form-field__field'"
                    [required]="true"
                    [native]="true"
                    [firstDisplay]="item.cabinClass? item.cabinClass: anyCabinClassString"
                    [select]=true
                    [reset]="cabinClassReset"
                    [disabled]="disableAllInputs || dpGrab || freeze"
                    (singleObject)="handleCabinClassSelection($event, item)">
                  </surf-ul-drop-down>
                </div>
              </div>

              <div class="c-dp-search__form-item auto-airline-input">
                <div class="m-form-field" (click)="scrollTo('#scroll')">
                  <surf-type-ahead
                    [label]="'Airline (Optional)'"
                    [dataList]="airlineList"
                    [filterType]="'strict'"
                    [mappings]="['code', 'value']"
                    [sortOrder]="'A2Z'"
                    [selectedItem]="item.airlineOntology"
                    [paramName]="'ontotext'"
                    [isMultiSelect]="true"
                    [multiSelectedItems]="item.airlineOntologies"
                    [placeholder]="'Search'"
                    [bindFocusOut]="false"
                    [selectAllOnEnter]="false"
                    [isOnto]="false"
                    [isDisabled]="disableAllInputs || dpGrab || freeze"
                    (onChipClose)="removeAirlineChip($event, item)"
                    [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH_AIRLINE"
                    (returnedData)="returnAirline($event, item)"
                    (returnedQuery)="returnedAirlineSearchQuery($event, item)"
                    (onClickOutside)="onAirlineClickOutside(item)">

                    <surf-type-ahead-content *ngIf="item.showAirlineTypeAheadContent"
                                             [typeAheadData]="filteredAirlineList"
                                             [available]="filteredAirlineList?.length > 0"
                                             (setItem)="selectAirline($event, item)">

                      <surf-type-ahead-item (click)="selectAirline(selection, item)" *ngFor="let selection of filteredAirlineList">
                        <div class="c-dp-search__list-item">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use xlink:href="#products--flight"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{selection?.value | surfTextCase: 'title'}}</span>
                          </div>
                        </div>

                      </surf-type-ahead-item>
                    </surf-type-ahead-content>
                  </surf-type-ahead>
                  <span *ngIf="item.isAirlineSearchInitiated && filteredAirlineList.length == 0" style="color: red;">No results found</span>
                </div>
              </div>

              <div class="c-dp-search__form-item auto-via-point-input">
                <div class="m-form-field" (click)="scrollTo('#scroll')">
                  <surf-type-ahead
                    [label]="'Via Points (Optional)'"
                    [filterType]="'strict'"
                    [mappings]="['code', 'value']"
                    [sortOrder]="'A2Z'"
                    [paramName]="'ontotext'"
                    [isMultiSelect]="true"
                    [selectedItem]="item.viaPointOntology"
                    [multiSelectedItems]="item.viaPointOntologies"
                    [placeholder]="'Search via points'"
                    [bindFocusOut]="false"
                    [selectAllOnEnter]="false"
                    [isOnto]="true"
                    [url]="viaPointsUrl"
                    [chipForceEnable]="true"
                    [isDisabled]="item.viaPoints?.length > 1 || disableAllInputs || dpGrab || freeze"
                    (onChipClose)="removeViaPointChip($event, item)"
                    [minQueryLengthToProceed]="MIN_SEARCH_QUERY_LENGTH_VIA_POINTS"
                    (returnedData)="returnViaPoint($event, item)"
                    (returnedQuery)="returnedViaPointSearchQuery($event, item)"
                    (onClickOutside)="onViaPointClickOutside(item)">

                    <surf-type-ahead-content *ngIf="item.showViaPointTypeAheadContent"
                                             [typeAheadData]="filteredViaPointList"
                                             [available]="filteredViaPointList?.length > 0"
                                             (setItem)="selectViaPoint($event, item)">

                      <surf-type-ahead-item (click)="selectViaPoint(selection, item)" *ngFor="let selection of filteredViaPointList">
                        <div class="c-dp-search__list-item">
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use xlink:href="#products--flight"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{selection?.name | surfTextCase: 'title'}}</span>
                          </div>
                        </div>

                      </surf-type-ahead-item>
                    </surf-type-ahead-content>
                  </surf-type-ahead>
                  <span *ngIf="item.isViaPointSearchInitiated && filteredViaPointList.length == 0" style="color: red;">No results found</span>
                </div>
              </div>

              <div class="c-dp-search__block-group" *ngIf="isPnr? true : flightGroupDetail?.displayGroupDetails">
                <svg class="a-icon c-dp-search__block-group-icon">
                  <use xlink:href="#exclamation"></use>
                </svg>
                <span class="c-dp-search__block-group-text">Combined with</span>
                <span class="a-loz a-loz--muted c-dp-search__block-group-label" [ngClass]="flightGroupDetail?.groupClassName">Group {{flightGroupDetail?.groupNumber}}</span>
              </div>

              <div class="c-dp-search__form-item">
                <ng-container *ngIf="ENABLE_PAX_SPLIT && !freeze && isDPSearch && enablePaxSplit">
                  <surf-multi-selector-drop-down [title]="'Split Passenger'"
                                                 [dataList]="paxSplitArray"
                                                 [inputDisplay]="paxSplitDisplayText"
                                                 [isChipClosable]="true"
                                                 [dropUP]="true"
                                                 [inputType]="'Guest'"
                                                 [isDisabled]="freeze"
                                                 (selectionChange)="passengerSplitChange($event)"
                                                 (emitClosedItem)="passengerSplitChange($event)"
                  >
                  </surf-multi-selector-drop-down>
                </ng-container>
              </div>
              <button class="a-link-btn a-link-btn--primary c-dp-search__add-link" (click)="enablePaxSplitDropdown()" *ngIf="ENABLE_PAX_SPLIT && isDPSearch && !freeze && !enablePaxSplit">
                Do you want to assign passengers?
                <span class="a-tool-tip a-tool-tip--top-left"
                  [attr.data-tooltip]="'Will allow you to assign/split passengers into multiple components'">
                  <svg class="a-icon u-rml">
                    <use xlink:href="#info"></use>
                  </svg>
                </span>
              </button>
            </div>

          </div>

          <div [hidden]="!isItemCloseable" class="c-dp-search__close auto-close-btn" *ngIf="isDPSearch" (click)="onFlightLegCloseClicked(i)">
            <svg class="a-icon">
              <use xlink:href="#close"></use>
            </svg>
          </div>

          <surf-dp-add-bar *ngIf="!isDPSearch"
                           [isDPSearch]="true"
                           [isIntro]="false"
                           [preItemIndex]="item?.index"
                           [postItemIndex]="item?.index + 1"
                           [totalItemCount]="flightItemList.length"
                           [disableDPSwitch]="DISABLE_DP_SWITCH"
                           [msg]="msg"
                           [ngClass]="{'u-disabled': DISABLE_DP_SWITCH && flightItemList.length == 2 }"
                           (click)="scrollTo($event, '#' + refineSearchScrollId);moveToDP(item?.index == (flightItemList.length - 1)? 'post' : item?.index)"
                           (addItem)="addAnotherCriteriaItem($event, item?.index == (flightItemList.length - 1)? 'post' : item?.index)">
          </surf-dp-add-bar>
        </div>
      </ng-template>
    </ng-container>   <!--Flight criteria content : visible in DP flow-->

  </ng-template>

  <div class="c-advance-search__btn-wrapper" *ngIf="!isDPSearch">
    <div class="c-advance-search__search-info">
      <svg class="a-icon search-info--icon" *ngIf = "!isServiceAddComponent">
        <use xlink:href="#info"></use>
      </svg>
      <span *ngIf = "!isServiceAddComponent">Please select at least your <b>Departure, Arrival Airports</b> and <b>Departure dates</b> to continue search</span>
    </div>
    <div *ngIf="!isRefine" class="c-advance-search__search-action auto-btn-set">
      <ng-container *ngIf="!isServiceAddComponent">
        <button class="a-btn a-btn--default auto-reset-search-btn" (click)="resetInputs(isRefine)">Reset Search</button>
      </ng-container>
      <ng-container *ngIf="isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-cancel-btn" [tabIndex]="4">Cancel</button>
      </ng-container>
      <button (click)="searchFlight(false)" class="a-btn a-btn--primary auto-search-btn" [ngClass]="{'is-disabled': !validateInputs()}">Search</button>
    </div>
    <div *ngIf="isRefine" class="c-advance-search__search-action auto-btn-set">
      <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default auto-cancel-search-btn">Back to results</button>
      <button (click)="searchFlight(false)" class="a-btn a-btn--primary auto-refine-btn" [ngClass]="{'is-disabled': !validateInputs()}">Refine
        Search
      </button>
    </div>
  </div>         <!--Button wrapper : hidden in DP-->
</div>
