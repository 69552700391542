<surf-message-box [title]="'Tour exists in another booking'"
                  id="auto-tour-fetching-exists-modal"
                  [active]="externalBookingCodeExistInBookings"
                  [hasConfirmButon]="true"
                  [hasCancelButton]="true"
                  [confirmLabel]="'Continue'"
                  [cancelLabel]="'Cancel'"
                  (clickButton)="handleExternalBookingCodeExistInBooking($event)"
                  [svgName]="'info'"
                  [type]="'info'"
>
  <span>{{surfModalDescription}}</span>
</surf-message-box>



<div class="c-advance-search__grab-bar" *ngIf="havePermissionForGrabTour && !(!isDisableGrabTourButton && isInBookingPage) && grabType !== GRAB_PNR_TYPE.REFRESH">
  <span class="a-loz a-loz--neutral/light c-advance-search__grab-lbl" *ngIf="grabbedTourId && ( !isDisableGrabTourButton || isDP )">
      {{grabbedTourId}}
<!--      <span class="u-rml u-clickable">-->
<!--          <svg class="a-icon u-x0.75">-->
<!--             <use xlink:href="#close"></use>-->
<!--          </svg>-->
<!--      </span>-->
  </span>
  <button class="a-btn a-btn--secondary c-advance-search__grab-btn" *ngIf="(!isDisableGrabTourButton && !isInBookingPage) || isDP" (click)="onClickGrab()">
    <svg class="a-icon u-rmr">
      <use xlink:href="#plus" *ngIf="!grabbedTourId"></use>
      <use xlink:href="#edit" *ngIf="grabbedTourId"></use>
    </svg>
    {{(grabbedTourId) ? 'Change Tour' : 'Grab Tour'}}
  </button>
</div>
<button class="a-link-btn c-dropdown-menu__btn" *ngIf="havePermissionForGrabTour && !isDisableGrabTourButton && isInBookingPage && grabType !== GRAB_PNR_TYPE.REFRESH" (click)="onClickGrab()">
  <svg class="a-icon u-mr u-x1.25"><use xlink:href="#products--tour"></use></svg>
  Grab Tour
</button>
<ng-container *ngIf="grabType === GRAB_PNR_TYPE.REFRESH">
  <button class="a-link-btn a-link-btn--primary" (click)="onClickRefresh()">
    <svg class="a-icon u-rmr">
      <use xlink:href="#refresh"></use>
    </svg>
    Refresh Tour
  </button>
</ng-container>

<div class="o-modal" [ngClass]="{'is-active': showGrabTourModal}" id="grab-tour-modal">

  <div class="o-modal__content c-grab-tour_modal">

    <div class="o-modal__body">
      <div class="o-modal__inner-header">
        <button class="a-btn a-btn--link o-modal__inner-close" (click)="closeGrabTourModal()">
          <svg class="a-icon o-modal__close-icon">
            <use xlink:href="#close"></use>
          </svg>
        </button>
        <button class="a-btn a-btn--light o-modal__back-btn u-visible-sm" (click)="closeGrabTourModal()">
          <svg class="a-icon u-mr">
            <use xlink:href="#previous"></use>
          </svg>
          Back
        </button>
      </div>
      <div class="c-grab-tour">
        <div class="c-grab-tour__header">
          <h4 class="t-4 c-grab-tour__title">Grab your tour</h4>
        </div>
        <div class="c-error-block" *ngIf="fetchError ||  (isInBookingPage && allResultsBlocked)">
          <div class="c-error-block__wrapper">
            <div class="c-error-block__left-col">
              <svg class="a-icon search-info--icon">
                <use xlink:href="#exclamation"></use>
              </svg>
            </div>
            <div class="c-error-block__right-col" *ngIf="!(isInBookingPage && allResultsBlocked)">
              <h3 class="t3 c-error-block__title">No results found</h3>
              <span class="c-error-block__text">{{fetchError}}</span>
            </div>
            <div class="c-error-block__right-col" *ngIf="isInBookingPage && allResultsBlocked">
              <h3 class="t3 c-error-block__title">Tour grab failed</h3>
              <span class="c-error-block__text" *ngFor="let error of bookingGrabErrorList">{{error}}</span>
            </div>
            <ng-container>

            </ng-container>
          </div>
        </div>
        <div class="c-grab-tour__form">
          <div class="o-inline-form-group c-passenger-form__group">

            <div class="o-inline-form-group__item c-passenger-form__item">
              <div class="m-form-field">
                <surf-input [id]="'grab-booking-id'"
                            [label]="'Booking Number*'"
                            [placeholder]="'Enter booking number'"
                            [inputVal]="tourFetchCriteria.bookingNo"
                            [invalidErrorMsg]="'Please enter valid booking ref'"
                            [error]="false"
                            [required]="true"
                            (value)="handleBookingNo($event)"
                >
                </surf-input>

              </div>
            </div>
            <div class="o-inline-form-group__item c-passenger-form__item">
              <div class="m-form-field">
                <surf-input [id]="'Last-name-on-booking'"
                            [label]="'Last name on booking*'"
                            [placeholder]="'Enter last name'"
                            [inputVal]="tourFetchCriteria.lastNameOnBooking"
                            [error]="false"
                            [required]="true"
                            (value)="handleLastName($event)"
                >
                </surf-input>

              </div>
            </div>

          </div>
          <div class="o-inline-form-group c-passenger-form__group">

            <div class="o-inline-form-group__item c-passenger-form__item">
              <div class="m-form-field">
                <surf-multi-selector-drop-down
                  [title]="'Supplier*'"
                  [dataList]="supplierItems"
                  [searchableDropdown]="true"
                  [inputDisplay]="'Select supplier'"
                  (selectionChange)="selectSupplier($event)"
                >
                </surf-multi-selector-drop-down>
<!--                <surf-ul-drop-down-->
<!--                  [label]="'Supplier*'"-->
<!--                  [items]="supplierItems"-->
<!--                  [native]="true"-->
<!--                  [required]="true"-->
<!--                  [select]="true"-->
<!--                  (singleObject)="selectSupplier($event)">-->
<!--                </surf-ul-drop-down>-->

              </div>
            </div>

            <div class="o-inline-form-group__item c-passenger-form__item" *ngIf="enableH2hDropdown">
              <div class="m-form-field">
                <surf-ul-drop-down
                  [label]="'H2H*'"
                  [items]="h2hItems"
                  [native]="true"
                  [required]="true"
                  [select]="true"
                  [disabled]="true"
                  (singleObject)="selectHtoH($event)">
                </surf-ul-drop-down>

              </div>
            </div>

          </div>
        </div>
      </div>
<!--      <div class="c-payment-breakdown__row-error" *ngIf="fetchError">-->
<!--        <div class="c-alert-block c-alert-block&#45;&#45;error">-->
<!--          <div class="c-alert-block__wrapper">-->
<!--            <span class="tc-important s6-text">{{fetchError}}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="o-modal__footer">
        <button class="a-btn a-btn--trinity u-mr" (click)="closeGrabTourModal()">Cancel</button>
        <button class="a-btn a-btn--primary" (click)="clickGrabTour()"
                [ngClass]="{'is-disabled': (!(tourFetchCriteria.bookingNo && tourFetchCriteria.h2hCode && tourFetchCriteria.supplierCode && tourFetchCriteria.lastNameOnBooking)) || searchLoading}">Grab Tour</button>
      </div>
    </div>

  </div>

</div>

<surf-page-loader [show]="showLoader" [loaderType]="'cart'" [title]="loaderTitle" [onBody]="true">
  <div class="c-page-loader__text">
    <span class="c-page-loader__sub-text">Please wait for a few seconds</span>
  </div>
</surf-page-loader>


<div class="o-modal" [ngClass]="{'is-active': showTravellerChangesModal}" id="tour-grab-pnr--traveller-comparison-modal">

  <div class="o-modal__content c-compare-pnr surf-msg-box--warning">

    <div class="o-modal__body">
      <div class="o-modal__inner-header">
        <button class="a-btn a-btn--link o-modal__inner-close" (click)="onClickCancelTraveller()">
          <svg class="a-icon o-modal__close-icon">
            <use xlink:href="#close"></use>
          </svg>
        </button>
        <button class="a-btn a-btn--light o-modal__back-btn u-visible-sm" (click)="onClickCancelTraveller()">
          <svg class="a-icon u-mr">
            <use xlink:href="#previous"></use>
          </svg>
          Back
        </button>
      </div>

      <div class="c-compare-pnr__header" id="errorPopupHeaderLocator">
        <div class="surf-msg-box__header">
          <ng-container *ngIf="grabPnrBookingModule?.showErrorMessage">
            <svg class="a-icon surf-msg-box__icon">
              <use xlink:href="#exclamation"></use>
            </svg>
            <h3 class="surf-msg-box__title">Warning</h3>
          </ng-container>
          <ng-container *ngIf="!grabPnrBookingModule?.showErrorMessage">
            <svg class="a-icon surf-msg-box__icon">
              <use xlink:href="#info"></use>
            </svg>
            <h3 class="surf-msg-box__title">Please review details</h3>
          </ng-container>
        </div>
        <div class="surf-msg-box__body" *ngIf="grabPnrBookingModule?.showErrorMessage">
          <span id="surf-auto-tour-grab-guest-difference-warning-message1" class="surf-msg-box__body-content">Please review the passenger details below before importing the booking. The existing passenger details in HELIO will be retained when the booking is imported.</span>
        </div>
        <div class="surf-msg-box__body" *ngIf="grabPnrBookingModule?.showErrorMessage && blockedResultsAvailable && !allResultsBlocked">
          <span id="surf-auto-tour-grab-guest-difference-warning-message2" class="surf-msg-box__body-content">Only the valid tour items are considered for the comparison.</span>
        </div>
        <div class="surf-msg-box__body" *ngIf="grabPnrBookingModule?.showErrorMessage">
          <span id="surf-auto-tour-grab-guest-difference-warning-message3" class="surf-msg-box__body-content">The highlighted changes will not be updated in the HELIO booking.</span>
        </div>
        <div class="surf-msg-box__body" *ngIf="grabPnrBookingModule?.showErrorMessage">
          <span id="surf-auto-tour-grab-guest-difference-warning-message4" class="surf-msg-box__body-content">Please Accept to continue with the booking.</span>
        </div>
      </div>

      <h4 class="t5 c-compare-pnr__title">Tour details</h4>
      <ng-container *ngFor="let item of tourGrabResult">
      <div class="c-compare-pnr__content">
          <div class="c-compare-pnr__section">
            <div class="c-itinerary-card__head-block">
              <div class="l-inline-block c-itinerary-card__info-block c-itinerary-card__info-block--tour">
                <div class="c-itinerary-card__data-set c-itinerary-card__data-set--tour">
              <span
                id="surf-auto-tour-grab-duration"
                class="m-inline-list__item s-lg-strong auto-tour-duration">{{item | surfGrabTourDurationStringPipe}}
              </span>
                  <span class="t5 c-package-cart__item-title auto-summary-generic-name"
                        id="surf-auto-tour-grab-name">{{ item?.summary?.name | surfTextCase: 'title'}}</span>
                </div>
              </div>
            </div>


            <div class="l-inline-block c-itinerary-card__info-block c-itinerary-card__info-block--tour-info">
              <div class="c-itinerary-card__info-set c-itinerary-card__info-set--date">
                <span class="tc-muted">Departure</span>
                <span class="tc-dark" id="surf-auto-tour-grab-departure-date">{{ item?.summary?.serviceStartDate | surfDatePipe:DATE_FORMAT }}</span>
              </div>
              <div class="c-itinerary-card__info-set c-itinerary-card__info-set--date">
                <span class="tc-muted">Arrival</span>
                <span class="tc-dark" id="surf-auto-tour-grab-arrival-date">{{ item?.summary?.serviceEndDate | surfDatePipe:DATE_FORMAT }}</span>
              </div>

            </div>
          </div>
        </div>
      </ng-container>

      <h4 class="t5 c-compare-pnr__title">Passengers</h4>

      <div class="c-compare-pnr__content">

        <div class="c-compare-pnr__section">
          <div class="c-compare-pnr__row c-compare-pnr__row--header">
            <div class="c-compare-pnr__item">
              <span class="a-loz a-loz--tertiary c-compare-status__tag">HELiO booking</span>
            </div>
            <div class="c-compare-pnr__item">
              <span class="a-loz a-loz--important c-compare-status__tag">Booking supplier</span>
            </div>
          </div>

          <div class="c-compare-pnr__row" *ngFor="let item of grabPnrBookingModule?.matchingTravellers; let i = index;">

            <div class="c-compare-pnr__item auto-booking-traveller">
              <ng-container *ngIf="item?.bkgTraveller">
                <span class="lbl c-compare-pnr__item-lbl">{{ item.bkgTraveller | surfGetPaxDisplayTextPipe : childTravellerUpperAgeLimit }}</span>
                <div class="c-compare-pnr__item-name">
                  <span>{{item.bkgTraveller.profile?.title | surfTextCase: 'title'}}.</span>
                  <span>{{item.bkgTraveller.profile?.firstName | surfTextCase: 'title'}}</span>
                  <span *ngIf="item.bkgTraveller.profile?.middleName && item.bkgTraveller.profile?.middleName?.length > 0">{{item.bkgTraveller.profile?.middleName | surfTextCase: 'title'}}</span>
                  <span>{{item.bkgTraveller.profile?.lastName | surfTextCase: 'title'}}</span>
                </div>

                <div class="c-compare-pnr__item-text" *ngIf="item.diffs?.hasMatch && !item?.diffs?.phoneNumber">
                  <span class="ts-strong">Phone number&nbsp;</span>
                  {{getTelephoneNumber(item.bkgTraveller)}}
                </div>

                <div class="c-compare-pnr__item-text" *ngIf="checkEmail( item.bkgTraveller, item.pnrTraveller)">
                  <span class="ts-strong">E-mail&nbsp;</span>
                  {{getEmail(item.bkgTraveller)}}
                </div>

              </ng-container>
            </div>

            <div class="c-compare-pnr__item auto-pnr-traveller">
              <ng-container *ngIf="item?.pnrTraveller">
                <span class="lbl c-compare-pnr__item-lbl">{{ item.pnrTraveller | surfGetPaxDisplayTextPipe : childTravellerUpperAgeLimit }}</span>
                <div class="c-compare-pnr__item-name">
                  <span [ngClass]="{'c-compare-pnr__item-diff' : (item.diffs?.hasMatch && !item.diffs?.title)}">
                    {{item.pnrTraveller.profile?.title | surfTextCase: 'title'}}<span *ngIf="item.pnrTraveller.profile?.title">.</span>
                  </span>
                  <span
                    [ngClass]="{'c-compare-pnr__item-diff' : (item.diffs?.hasMatch && !item.diffs?.firstName)}">{{item.pnrTraveller.profile?.firstName | surfTextCase: 'title'}}</span>
                  <span
                    [ngClass]="{'c-compare-pnr__item-diff' : (item.diffs?.hasMatch && !item.diffs?.middleName && item.pnrTraveller.profile?.middleName?.length > 0)}">{{item.pnrTraveller.profile?.middleName | surfTextCase: 'title'}}</span>
                  <span
                    [ngClass]="{'c-compare-pnr__item-diff' : (item.diffs?.hasMatch && !item.diffs?.lastName)}">{{item.pnrTraveller.profile?.lastName | surfTextCase: 'title'}}</span>
                </div>

<!--                <ng-template [ngIf]="item.pnrTraveller?.profile?.type == 'I'">-->
<!--                  <div class="c-compare-pnr__item-text" *ngIf="item.diffs?.hasMatch && !item?.diffs?.dateOfBirth">-->
<!--                    <span class="ts-strong">Date of birth</span>-->
<!--                    &lt;!&ndash;                    <span class="c-compare-pnr__item-diff">{{item.pnrTraveller.profile?.dob ? (item.pnrTraveller.profile?.dob | surfDatePipe: DATE_FORMAT) : 'N/A'}}</span>&ndash;&gt;-->
<!--                    {{item.pnrTraveller.profile?.dob ? (item.pnrTraveller.profile?.dob | surfDatePipe: DATE_FORMAT) : 'N/A'}}-->
<!--                  </div>-->
<!--                  <div class="c-compare-pnr__item-text" *ngIf="item.diffs?.hasPrimaryMatch && !item?.diffs?.dateOfBirth">-->
<!--                    <span class="ts-strong">Date of birth</span>-->
<!--                    <span class="c-compare-pnr__item-diff">{{item.pnrTraveller.profile?.dob ? (item.pnrTraveller.profile?.dob | surfDatePipe: DATE_FORMAT) : 'N/A'}}</span>-->
<!--                  </div>-->
<!--                </ng-template>-->

<!--                <ng-template [ngIf]="item.pnrTraveller?.profile?.type == 'C'">-->
<!--                  <div class="c-compare-pnr__item-text" *ngIf="item.diffs?.hasMatch && !item?.diffs?.age">-->
<!--                    <span class="ts-strong">Age</span>-->
<!--                    <span class="c-compare-pnr__item-diff">{{item.pnrTraveller.profile?.age}}</span>-->
<!--                  </div>-->
<!--                  <div class="c-compare-pnr__item-text" *ngIf="item.diffs?.hasPrimaryMatch && !item?.diffs?.age">-->
<!--                    <span class="ts-strong">Age</span>-->
<!--                    <span class="c-compare-pnr__item-diff">{{item.pnrTraveller.profile?.age}}</span>-->
<!--                  </div>-->
<!--                </ng-template>-->

                <div class="c-compare-pnr__item-text" *ngIf="item.diffs?.hasMatch && !item?.diffs?.phoneNumber">
                  <span class="ts-strong">Phone number</span>
                  <span class="c-compare-pnr__item-diff">{{getTelephoneNumber(item.pnrTraveller)}}</span>
                </div>

                <div class="c-compare-pnr__item-text" *ngIf="checkEmail( item.bkgTraveller, item.pnrTraveller)">
                  <span *ngIf="(item.diffs?.hasMatch && !item.diffs?.email)" class="ts-strong">E-mail</span>
                  <span *ngIf="!(item.diffs?.hasMatch && !item.diffs?.email)" class="ts-strong">E-mail&nbsp;</span>
                  <span
                    [ngClass]="{'c-compare-pnr__item-diff' : (item.diffs?.hasMatch && !item.diffs?.email)}">{{getEmail(item.pnrTraveller)}}</span>
                </div>

              </ng-container>

            </div>

          </div>
        </div>

      </div>

      <div class="o-modal__footer t-mini-pack">
        <button class="a-btn a-btn--trinity u-mr" (click)="onClickCancelTraveller()">Cancel</button>
        <button class="a-btn a-btn--primary auto-grab-pnr-accept" (click)="onClickAcceptTravellerChanges()">Accept</button>
      </div>
    </div>
  </div>

</div>

<div class="o-modal" [ngClass]="{'is-active': showRefreshChangesModal}" id="refresh-tour--comparison-modal">

  <div class="o-modal__content c-compare-status_modal" [ngClass]="{'surf-msg-box--success': tourRefreshItem?.tourItemSecondaryChanges?.length === 0 && tourRefreshItem?.paxWiseChanges?.size === 0}">

    <div class="o-modal__inner-header">
      <button class="a-btn a-btn--link o-modal__inner-close" (click)="closeRefreshTourModal()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
      <button class="a-btn a-btn--light o-modal__back-btn" (click)="closeRefreshTourModal()">
        <svg class="a-icon u-mr">
          <use xlink:href="#previous"></use>
        </svg>
        Back
      </button>
    </div>

    <div class="o-modal__body">

      <div class="c-compare-status">
        <div class="c-compare-status__header">
          <div class="surf-msg-box__header">
            <ng-container *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length > 0 || tourRefreshItem?.paxWiseChanges?.size > 0">
              <svg class="a-icon surf-msg-box__icon">
                <use xlink:href="#exclamation"></use>
              </svg>
              <h3 class="surf-msg-box__title">Warning</h3>
            </ng-container>
            <ng-container *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length === 0 && tourRefreshItem?.paxWiseChanges?.size === 0">
              <svg class="a-icon surf-msg-box__icon">
                <use xlink:href="#checked"></use>
              </svg>
              <h3 class="surf-msg-box__title">No mismatches found</h3>
            </ng-container>
          </div>
          <div class="surf-msg-box__body">
            <ng-container *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length > 0 && tourRefreshItem?.paxWiseChanges?.size > 0">
              <span class="surf-msg-box__body-content">Looks like we have a tour and guest detail difference here.</span>
              <br>
            </ng-container>
            <ng-container *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length > 0 && tourRefreshItem?.paxWiseChanges?.size === 0">
              <span class="surf-msg-box__body-content">Looks like we have a tour detail difference here.</span>
              <br>
            </ng-container>
            <ng-container *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length > 0">
              <span class="surf-msg-box__body-content">Please review and click Apply to update the highlighted tour details in the Helio booking</span>
            </ng-container>
            <ng-container *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length === 0">
              <span class="surf-msg-box__body-content">Please click OK to complete the refresh</span>
            </ng-container>
            <ng-container *ngIf="tourRefreshItem?.paxWiseChanges?.size > 0">
              <span class="surf-msg-box__body-content">Passenger detail differences will not be updated in the Helio booking</span>
            </ng-container>
          </div>
        </div>

        <div class="o-tabs" *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length > 0 || tourRefreshItem?.paxWiseChanges?.size > 0">

          <div class="o-tabs__content c-compare-status__body">

            <div class="o-tabs__pane c-compare-status__tab is-active" id="tab-a">

              <div class="c-compare-status__content" *ngIf="tourRefreshItem?.tourItemSecondaryChanges && tourRefreshItem?.tourItemSecondaryChanges?.length > 0">

                <h4 class="t5 c-compare-status__title">Tour details</h4>

                <div class="c-compare-status__section">
                  <div class="c-compare-status__row c-compare-status__row--header">
                    <div class="c-compare-status__item">
                      <span class="a-loz a-loz--tertiary c-compare-status__tag">HELiO booking</span>
                    </div>
                    <div class="c-compare-status__item">
                      <span class="a-loz a-loz--important c-compare-status__tag">Booking supplier</span>
                    </div>
                  </div>

                  <ng-container *ngFor="let change of tourRefreshItem?.tourItemSecondaryChanges">
                    <div class="c-compare-status__row">
                      <div class="c-compare-status__item">
                        <span class="lbl c-compare-status__item-lbl" *ngIf="change?.bkgVal">{{change?.typeName}}</span>
                        <div class="c-compare-status__item-name" *ngIf="change?.bkgVal">
                          {{change?.bkgVal}}
                        </div>
                      </div>
                      <div class="c-compare-status__item">
                        <span class="lbl c-compare-status__item-lbl" *ngIf="change?.supplierValList?.length > 0">{{change?.typeName}}</span>
                        <div class="c-compare-status__item-name" *ngIf="change?.supplierValList?.length > 0">
                          <ng-container *ngFor="let supplierVal of change?.supplierValList">
                            <span [ngClass]="{'c-compare-status__item-diff': supplierVal?.isChanged}">{{supplierVal?.value}}</span>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>

              </div>

              <div class="c-compare-status__content" *ngIf="tourRefreshItem?.paxWiseChanges && tourRefreshItem?.paxWiseChanges?.size > 0">

                <h4 class="t5 c-compare-status__title">Passengers</h4>

                <div class="c-compare-status__section">
                  <div class="c-compare-status__row c-compare-status__row--header">
                    <div class="c-compare-status__item">
                      <span class="a-loz a-loz--tertiary c-compare-status__tag">HELiO booking</span>
                    </div>
                    <div class="c-compare-status__item">
                      <span class="a-loz a-loz--important c-compare-status__tag">Booking supplier</span>
                    </div>
                  </div>

                  <ng-container *ngFor="let change of tourRefreshItem?.paxWiseChanges | keyvalue">
                    <div class="c-compare-status__row">
                      <div class="c-compare-status__item">
                        <span class="lbl c-compare-status__item-lbl"
                              *ngIf="tourRefreshItem?.paxWiseChanges?.get(change?.key) && tourRefreshItem?.paxWiseChanges?.get(change?.key)[0]?.bkgVal">
                          {{tourRefreshItem?.paxWiseChanges?.get(change?.key)[0]?.paxType}}
                        </span>
                        <ng-container *ngFor="let paxChange of tourRefreshItem?.paxWiseChanges?.get(change?.key)">
                          <div class="c-compare-status__item-name" *ngIf="paxChange?.type === TourPaxWiseRefreshChangeType?.NAME && paxChange?.bkgVal">
                            {{paxChange?.bkgVal}}
                          </div>
                          <div class="c-compare-status__item-text" *ngIf="paxChange?.type === TourPaxWiseRefreshChangeType?.EMAIL && paxChange?.bkgVal">
                            {{paxChange?.bkgVal}}
                          </div>
                          <div class="c-compare-status__item-text" *ngIf="paxChange?.type === TourPaxWiseRefreshChangeType?.TP && paxChange?.bkgVal">
                            {{paxChange?.bkgVal}}
                          </div>
                        </ng-container>
                      </div>
                      <div class="c-compare-status__item">
                        <span class="lbl c-compare-status__item-lbl" [ngClass]="{'c-compare-status__item-diff': tourRefreshItem?.paxWiseChanges?.get(change?.key)[0]?.newPaxType !== tourRefreshItem?.paxWiseChanges?.get(change?.key)[0]?.paxType}"
                              *ngIf="tourRefreshItem?.paxWiseChanges?.get(change?.key) && (tourRefreshItem?.paxWiseChanges?.get(change?.key)[0]?.supplierValList?.length > 0 || tourRefreshItem?.paxWiseChanges?.get(change?.key)[0]?.supplierVal)">
                          {{tourRefreshItem?.paxWiseChanges?.get(change?.key)[0]?.newPaxType}}
                        </span>
                        <ng-container *ngFor="let paxChange of tourRefreshItem?.paxWiseChanges?.get(change?.key)">
                          <div class="c-compare-status__item-name" *ngIf="paxChange?.type === TourPaxWiseRefreshChangeType?.NAME && paxChange?.supplierValList">
                            <ng-container *ngFor="let supplierVal of paxChange?.supplierValList">
                              <span [ngClass]="{'c-compare-status__item-diff': supplierVal?.isChanged}" *ngIf="supplierVal?.value">{{supplierVal?.value}}</span>
                            </ng-container>
                          </div>
                          <div class="c-compare-status__item-text" *ngIf="paxChange?.type === TourPaxWiseRefreshChangeType?.EMAIL && paxChange?.supplierValList">
                            <ng-container *ngFor="let supplierVal of paxChange?.supplierValList">
                              <span [ngClass]="{'c-compare-status__item-diff': supplierVal?.isChanged}" *ngIf="supplierVal?.value">{{supplierVal?.value}}</span>
                            </ng-container>
                          </div>
                          <div class="c-compare-status__item-text" *ngIf="paxChange?.type === TourPaxWiseRefreshChangeType?.TP && paxChange?.supplierValList">
                            <ng-container *ngFor="let supplierVal of paxChange?.supplierValList">
                              <span [ngClass]="{'c-compare-status__item-diff': supplierVal?.isChanged}" *ngIf="supplierVal?.value">{{supplierVal?.value}}</span>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>

              </div>

            </div>

          </div>
<!--          <div class="o-tabs__nav c-compare-status__tab-nav">-->
<!--            <a class="o-tabs__nav-item is-active" href="#tab-a">Tour 1</a>-->
<!--          </div>-->
        </div>
      </div>

      <div class="o-modal__footer t-mini-pack">
        <button class="a-btn a-btn--trinity u-mr" *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length > 0 || tourRefreshItem?.paxWiseChanges?.size > 0" (click)="closeRefreshTourModal()">Cancel</button>
        <button class="a-btn a-btn--primary" *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length > 0" (click)="updateBooking()">Apply</button>
        <button class="a-btn a-btn--primary" *ngIf="tourRefreshItem?.tourItemSecondaryChanges?.length === 0" (click)="closeRefreshTourModal()">OK</button>
      </div>

    </div>



  </div>

</div>

<surf-message-box [title]="'Warning'" [cancelLabel]="'Cancel'" [id]="UPDATE_TRAVELLER_WARNING_MODAL_ID"
                  [active]="isMismatchDetailWarning" [hasConfirmButon]="true" [confirmLabel]="'OK'"
                  [type]="'error'" [svgName]="'exclamation'" (clickButton)="onClickContinueTraveller($event)">
  <span>Please note that the existing passenger details will not be updated in the supplier's end</span>
</surf-message-box>

<surf-message-box [title]="'Booking update successful'" [active]="showApplyChangesModal" id="{{APPLY_CHANGES_SUCCESS_MODAL_ID}}"
                  [hasConfirmButon]="true" [hasCancelButton]="false" (clickButton)="reloadPage()"
                  [confirmLabel]="'Reload'"
                  [svgName]="'checked'" [type]="'success'">
  <span>Successfully updated the booking with tour changes. Please reload the booking to continue.</span>
</surf-message-box>
<surf-message-box [title]="'Proceed to Grab Tour?'" [active]="showDPGrabWarning" id="{{DP_GRAB_TOUR_WARNING_MODAL_ID}}"
                  [hasConfirmButon]="true" [hasCancelButton]="true" (clickButton)="initiateDPGrab($event)"
                  [confirmLabel]="'Proceed'"
                  [cancelLabel]="'Cancel'"
                  [svgName]="'exclamation'" [type]="'warning'">
  <span>Seems like you have already add some criteria, these will be reset if you proceed</span>
</surf-message-box>
<surf-message-box [title]="'Refresh tour failed'" [active]="refreshError" id="{{APPLY_CHANGES_ERROR_MODAL_ID}}"
                  [hasConfirmButon]="false" [hasCancelButton]="true" (clickButton)="onErrorMsgButtonClick()"
                  [cancelLabel]="'OK'"
                  [svgName]="'error'" [type]="'error'">
  <span >{{refreshErrorMsg}}</span>
</surf-message-box>
