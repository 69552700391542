import { NgModule } from '@angular/core';
import {SurfSplitPaxComponent} from './split-pax/split-pax.component';
import {SurfMultiSelectorDropDownModule} from "@surf/surf-multi-selector-drop-down";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [SurfSplitPaxComponent],
  imports: [
    SurfMultiSelectorDropDownModule,
    CommonModule
  ],
  exports: [SurfSplitPaxComponent]
})
export class SurfSplitPaxModule { }
