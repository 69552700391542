<!--<div class="c-filter-section u-hidden-sm">-->
<!--  <div class="c-filter-section__list">-->
<!--    <div class="lbl c-filter-section__list-tag">Currency</div>-->
<!--    <div class="c-filter-section__list-type">-->

<!--      <surf-ul-drop-down-->
<!--        [items]="currencies"-->
<!--        [placeholder]="'Items'"-->
<!--        [triggerType]="'button'"-->
<!--        [listStyle]="'c-filter-section__list-type'"-->
<!--        [dropdownId]="'dd2'"-->
<!--        [required]="false"-->
<!--        [firstDisplay]=""-->
<!--        [select]=true-->
<!--        [triggerChange]="triggerDropDownChange"-->
<!--        [wrapperView]="false"-->
<!--        (singleObject)="selectCurrency($event)">-->
<!--      </surf-ul-drop-down>-->

<!--    </div>-->

<!--    <div class="c-filter-section__separator"></div>-->
<!--    <div class="lbl c-filter-section__list-tag">Brand</div>-->

<!--    <div class="c-filter-section__list-type">-->

<!--      <surf-ul-drop-down-->
<!--        [items]="brands"-->
<!--        [placeholder]="'Items'"-->
<!--        [triggerType]="'button'"-->
<!--        [listStyle]="'c-filter-section__list-type'"-->
<!--        [dropdownId]="'dd3'"-->
<!--        [required]="false"-->
<!--        [firstDisplay]=""-->
<!--        [select]=true-->
<!--        [wrapperView]="false"-->
<!--        [triggerChange]="triggerDropDownChange"-->
<!--        (singleObject)="selectBrand($event)">-->
<!--      </surf-ul-drop-down>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="dashboard__search-bar">-->
  <!--<div class="c-search-navbar">-->
    <!--<div class="c-search-navbar__item c-search-navbar__item&#45;&#45;package" (click)="selectSearchFlow('PKG')" [ngClass]="{'is-selected':activeSearchFlow == 'PKG'}">-->
      <!--<svg class="a-icon c-search-navbar__item-icon">-->
        <!--<use class="a-icon__icon" xlink:href="#colored-products&#45;&#45;package"></use>-->
        <!--<use class="a-icon__hover-icon" xlink:href="#products&#45;&#45;package"></use>-->
      <!--</svg>-->

      <!--<span class="c-search-navbar__item-text u-hidden-sm">Add Package</span>-->
    <!--</div>-->
    <!--<span class="c-search-navbar__separator">or</span>-->
    <!--<div class="c-search-navbar__item" (click)="selectSearchFlow('HTL')" [ngClass]="{'is-selected':activeSearchFlow == 'HTL'}">-->
      <!--<svg class="a-icon c-search-navbar__item-icon">-->
        <!--<use xlink:href="#products&#45;&#45;hotel"></use>-->
      <!--</svg>-->
    <!--</div>-->
    <!--<div class="c-search-navbar__item" (click)="selectSearchFlow('FLT')" [ngClass]="{'is-selected':activeSearchFlow == 'FLT'}">-->
      <!--<svg class="a-icon c-search-navbar__item-icon">-->
        <!--<use xlink:href="#products&#45;&#45;flight"></use>-->
      <!--</svg>-->
    <!--</div>-->
    <!--<div class="c-search-navbar__item" (click)="selectSearchFlow('CAR')" [ngClass]="{'is-selected':activeSearchFlow == 'CAR'}">-->
      <!--<svg class="a-icon c-search-navbar__item-icon">-->
        <!--<use xlink:href="#products&#45;&#45;car"></use>-->
      <!--</svg>-->
    <!--</div>-->
    <!--<div class="c-search-navbar__item">-->
      <!--<svg class="a-icon c-search-navbar__item-icon">-->
        <!--<use xlink:href="#products&#45;&#45;bus"></use>-->
      <!--</svg>-->
    <!--</div>-->
    <!--<div class="c-search-navbar__item">-->
      <!--<svg class="a-icon c-search-navbar__item-icon">-->
        <!--<use xlink:href="#products&#45;&#45;rail"></use>-->
      <!--</svg>-->
    <!--</div>-->
    <!--<div class="c-search-navbar__item">-->
      <!--<svg class="a-icon c-search-navbar__item-icon">-->
        <!--<use xlink:href="#products&#45;&#45;cruise"></use>-->
      <!--</svg>-->
    <!--</div>-->
    <!--<div class="c-search-navbar__item">-->
      <!--<svg class="a-icon c-search-navbar__item-icon">-->
        <!--<use xlink:href="#products&#45;&#45;tickets"></use>-->
      <!--</svg>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->



<div class="c-search-navbar">

  <div id="search-item-pkg" class="c-search-navbar__item c-search-navbar__item--package auto-package" (click)="selectSearchFlow('PKG')"
       [ngClass]="{'is-selected':activeSearchFlow == 'PKG', 'u-disabled': disableItemArray.includes('PKG') }">
    <svg class="a-icon c-search-navbar__item-icon">
      <use class="close-on-click-outside" xlink:href="#products--package"></use>
    </svg>
    <span class="c-search-navbar__item-text u-hidden-sm auto-create-holiday">{{epLabelName}}</span>
  </div>

  <span class="c-search-navbar__separator">or</span>

<!--  <div id="search-item-dp" class="c-search-navbar__item c-search-navbar__item&#45;&#45;holiday" (click)="selectSearchFlow('DP')"-->
<!--       [ngClass]="{'is-selected':activeSearchFlow == 'DP', 'u-disabled': disableItemArray.includes('TMD')}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip auto-create-dp-holiday" data-tooltip="Create Itinerary">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--        <use xlink:href="#colored-products&#45;&#45;dp" class="a-icon__icon"></use>-->
<!--        <use xlink:href="#products&#45;&#45;dp" class="a-icon__hover-icon"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->

<!--  <div id="search-item-htl"  class="c-search-navbar__item auto-hotel" (click)="selectSearchFlow('HTL')"-->
<!--       [ngClass]="{'is-selected':activeSearchFlow == 'HTL', 'u-disabled': disableItemArray.includes('HTL')}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" data-tooltip="Accommodation">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--        <use class="close-on-click-outside" xlink:href="#products&#45;&#45;hotel"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->
<!--  <div id="search-item-flt"  class="c-search-navbar__item auto-flight" (click)="selectSearchFlow('FLT')"-->
<!--       [ngClass]="{'is-selected':activeSearchFlow == 'FLT', 'u-disabled': disableItemArray.includes('FLT')}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" data-tooltip="Flights">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--        <use class="close-on-click-outside" xlink:href="#products&#45;&#45;flight"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->
<!--  <div id="search-item-car" class="c-search-navbar__item auto-car" (click)="selectSearchFlow('CAR')"-->
<!--        [ngClass]="{'is-selected':activeSearchFlow == 'CAR', 'u-disabled': disableItemArray.includes('CAR')}" >-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" data-tooltip="Cars">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--        <use class="close-on-click-outside" xlink:href="#products&#45;&#45;car"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->
<!--  <div id="search-item-bus" class="c-search-navbar__item auto-transfer" (click)="selectSearchFlow('TRS')"-->
<!--       [ngClass]="{'is-selected':activeSearchFlow == 'TRS', 'u-disabled': disableItemArray.includes('TRS')}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" data-tooltip="Transfer">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--          <use class="close-on-click-outside" xlink:href="#products&#45;&#45;bus"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->


  <div id="search-item-dp" class="c-search-navbar__item c-search-navbar__item--holiday" (click)="selectSearchFlow('DP')"
       [ngClass]="{'is-selected':activeSearchFlow == 'TMD', 'u-disabled': disableItemArray.includes('TMD')}">
    <span class="a-tool-tip a-tool-tip--top c-search-navbar__tool-tip auto-create-dp-holiday" data-tooltip="Create Itinerary">
      <svg class="a-icon c-search-navbar__item-icon">
        <use xlink:href="#colored-products--dp" class="a-icon__icon"></use>
        <use xlink:href="#products--dp" class="a-icon__hover-icon"></use>
      </svg>
    </span>
  </div>

  <div id="search-item-htl" class="c-search-navbar__item auto-hotel" (click)="selectSearchFlow('HTL')"
       [ngClass]="{'is-selected':activeSearchFlow == 'HTL', 'u-disabled': disableItemArray.includes('HTL')}">
    <span class="a-tool-tip a-tool-tip--top c-search-navbar__tool-tip" data-tooltip="Accommodation">
      <svg class="a-icon c-search-navbar__item-icon">
        <use class="close-on-click-outside" xlink:href="#products--hotel"></use>
      </svg>
    </span>
  </div>

  <div id="search-item-flt" class="c-search-navbar__item auto-flight" (click)="selectSearchFlow('FLT')"
       [ngClass]="{'is-selected':activeSearchFlow == 'FLT', 'u-disabled': disableItemArray.includes('FLT')}">
    <span class="a-tool-tip a-tool-tip--top c-search-navbar__tool-tip" data-tooltip="Flights">
      <svg class="a-icon c-search-navbar__item-icon">
        <use class="close-on-click-outside" xlink:href="#products--flight"></use>
      </svg>
    </span>
  </div>

  <div id="search-item-car" class="c-search-navbar__item auto-car" (click)="selectSearchFlow('CAR')"
       [ngClass]="{'is-selected':activeSearchFlow == 'CAR', 'u-disabled': disableItemArray.includes('CAR')}">
    <span class="a-tool-tip a-tool-tip--top c-search-navbar__tool-tip" data-tooltip="Cars">
      <svg class="a-icon c-search-navbar__item-icon">
        <use class="close-on-click-outside" xlink:href="#products--car"></use>
      </svg>
    </span>
  </div>

  <div id="search-item-bus" class="c-search-navbar__item auto-transfer" (click)="selectSearchFlow('TRS')"
       [ngClass]="{'is-selected':activeSearchFlow == 'TRS', 'u-disabled': disableItemArray.includes('TRS')}">
    <span class="a-tool-tip a-tool-tip--top c-search-navbar__tool-tip" data-tooltip="Transfer">
      <svg class="a-icon c-search-navbar__item-icon">
          <use class="close-on-click-outside" xlink:href="#products--bus"></use>
      </svg>
    </span>
  </div>
  <div id="search-item-gen" class="c-search-navbar__item auto-generic " (click)="selectSearchFlow('GEN')"
       [ngClass]="{'is-selected':activeSearchFlow == 'GEN', 'u-disabled': disableItemArray.includes('GEN')}">
    <span class="a-tool-tip a-tool-tip--top c-search-navbar__tool-tip" data-tooltip="Activities">
      <svg class="a-icon c-search-navbar__item-icon">
        <use class="close-on-click-outside" xlink:href="#products--tickets"></use>
      </svg>
    </span>
  </div>

<!--  <div id="search-item-gen" class="c-search-navbar__item auto-generic" (click)="selectSearchFlow('GEN')"-->
<!--       [ngClass]="{'is-selected':activeSearchFlow == 'GEN', 'u-disabled': disableItemArray.includes('GEN')}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" data-tooltip="Activities">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--        <use class="close-on-click-outside" xlink:href="#products&#45;&#45;tickets"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->

  <!--  <div-->
<!--    SurfAuthorization [key]="SHOW_MAN_CRU_FOR_CRU_BUTTON? permissionKeys.MBI_CRU_ADD_PRODUCT: null"-->
<!--    *ngIf="!hiddenItemArray.includes('CRU')"-->
<!--    id="search-item-cru"-->
<!--    class="c-search-navbar__item auto-cruise"-->
<!--    (click)="selectSearchFlow('CRU')"-->
<!--    [ngClass]="{'is-selected':activeSearchFlow == 'CRU'}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" data-tooltip="Cruise">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--          <use class="close-on-click-outside" xlink:href="#products&#45;&#45;cruise"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->
<!--  <div SurfAuthorization [key]="permissionKeys.TOU_ADD_PRODUCT"-->
<!--    *ngIf="!hiddenItemArray.includes('TOU')"-->
<!--    id="search-item-tou"-->
<!--    class="c-search-navbar__item auto-tour"-->
<!--    (click)="selectSearchFlow('TOU')"-->
<!--    [ngClass]="{'is-selected':activeSearchFlow == 'TOU'}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" data-tooltip="Tour">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--          <use class="close-on-click-outside" xlink:href="#products&#45;&#45;tour"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->

<!--  <ng-container *ngFor="let specialProduct of specialProductConfigList">-->
<!--      <div *ngIf="showSpecialGen && !hiddenItemArray.includes(specialProduct.pt) && checkSpecialGenericProductsEnabledForUser(specialProduct)" id="search-item-special-gen" class="c-search-navbar__item auto-generic" (click)="selectSearchFlow(specialProduct.pt)"-->
<!--       [ngClass]="{'is-selected':activeSearchFlow === specialProduct.pt}">-->
<!--        <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" [attr.data-tooltip]="specialProduct.mh">-->
<!--          <svg class="a-icon c-search-navbar__item-icon">-->
<!--            <use class="close-on-click-outside" [attr.xlink:href]="'#'+ specialProduct?.pi"></use>-->
<!--          </svg>-->
<!--        </span>-->
<!--      </div>-->
<!--  </ng-container>-->

<!--  <div id="search-item-ins" class="c-search-navbar__item auto-insurance" SurfAuthorization [key]="permissionKeys.INS_ADD_PRODUCT" [ngClass]="{'is-selected':activeSearchFlow == 'INS', 'u-disabled': disableItemArray.includes('INS')}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" (click)="selectSearchFlow('INS')" data-tooltip="Insurance">-->
<!--      <svg class="a-icon c-search-navbar__item-icon u-x0.75">-->
<!--        <use xlink:href="#protected"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--  </div>-->
<!--  <div *ngIf="isActiveManualDropdownIcon" id="search-item-man" class="c-search-navbar__item c-dropdown-menu" clickOutside (clickOutside)="onClickOutside($event)"-->
<!--       [ngClass]="{'is-selected':activeSearchFlow == 'MAN', 'u-disabled': disableItemArray.includes('MAN')}">-->
<!--    <span class="a-tool-tip a-tool-tip&#45;&#45;top c-search-navbar__tool-tip" data-tooltip="Manual Items" (click)="showManualItemDD()">-->
<!--      <svg class="a-icon c-search-navbar__item-icon">-->
<!--        <use class="close-on-click-outside" xlink:href="#products&#45;&#45;manual"></use>-->
<!--      </svg>-->
<!--    </span>-->
<!--    <div class="c-dropdown-menu__btn-wrap " [ngClass]="{'is-visible':this.showManualItemDropDown}">-->
<!--      <div SurfAuthorization [key]="permissionKeys.MPI_ADD_PRODUCT">-->
<!--        <button class="a-link-btn c-dropdown-menu__btn c-dropdown-menu__btn&#45;&#45;primary" (click)="selectSearchFlow('MAN')">-->
<!--          <svg class="a-icon u-mr u-x1.25">-->
<!--            <use xlink:href="#products&#45;&#45;add-manual"></use>-->
<!--          </svg>-->
<!--          Manual price item-->
<!--        </button>-->
<!--      </div>-->
<!--      <div SurfAuthorization [key]="permissionKeys.MBI_FLT_ADD_PRODUCT">-->
<!--        <button class="a-link-btn c-dropdown-menu__btn" (click)="selectSearchFlow('MANFLT')" *ngIf="showManualItemConfig">-->
<!--          <svg class="a-icon u-mr u-x1.25">-->
<!--            <use xlink:href="#products&#45;&#45;flight"></use>-->
<!--          </svg>-->
<!--          Manual flight-->
<!--        </button>-->
<!--      </div>-->
<!--      <div SurfAuthorization [key]="permissionKeys.MBI_CRU_ADD_PRODUCT">-->
<!--        <button class="a-link-btn c-dropdown-menu__btn" (click)="selectSearchFlow('MAN_CRU')" *ngIf="showManualItemConfig && !hiddenItemArray.includes('CRU')">-->
<!--          <svg class="a-icon u-mr u-x1.25">-->
<!--            <use xlink:href="#products&#45;&#45;cruise"></use>-->
<!--          </svg>-->
<!--          Manual cruise-->
<!--        </button>-->
<!--      </div>-->
<!--&lt;!&ndash;      <button class="a-link-btn c-dropdown-menu__btn">&ndash;&gt;-->
<!--&lt;!&ndash;        <svg class="a-icon u-mr u-x1.25">&ndash;&gt;-->
<!--&lt;!&ndash;          <use xlink:href="#products&#45;&#45;hotel"></use>&ndash;&gt;-->
<!--&lt;!&ndash;        </svg>&ndash;&gt;-->
<!--&lt;!&ndash;        Manual hotel&ndash;&gt;-->
<!--&lt;!&ndash;      </button>&ndash;&gt;-->
<!--&lt;!&ndash;      <button class="a-link-btn c-dropdown-menu__btn">&ndash;&gt;-->
<!--&lt;!&ndash;        <svg class="a-icon u-mr u-x1.25">&ndash;&gt;-->
<!--&lt;!&ndash;          <use xlink:href="#products&#45;&#45;car"></use>&ndash;&gt;-->
<!--&lt;!&ndash;        </svg>&ndash;&gt;-->
<!--&lt;!&ndash;        Manual car&ndash;&gt;-->
<!--&lt;!&ndash;      </button>&ndash;&gt;-->
<!--&lt;!&ndash;      <button class="a-link-btn c-dropdown-menu__btn">&ndash;&gt;-->
<!--&lt;!&ndash;        <svg class="a-icon u-mr u-x1.25">&ndash;&gt;-->
<!--&lt;!&ndash;          <use xlink:href="#products&#45;&#45;bus"></use>&ndash;&gt;-->
<!--&lt;!&ndash;        </svg>&ndash;&gt;-->
<!--&lt;!&ndash;        Manual transfer&ndash;&gt;-->
<!--&lt;!&ndash;      </button>&ndash;&gt;-->
<!--&lt;!&ndash;      <button class="a-link-btn c-dropdown-menu__btn">&ndash;&gt;-->
<!--&lt;!&ndash;        <svg class="a-icon u-mr u-x1.25">&ndash;&gt;-->
<!--&lt;!&ndash;          <use xlink:href="#products&#45;&#45;tickets"></use>&ndash;&gt;-->
<!--&lt;!&ndash;        </svg>&ndash;&gt;-->
<!--&lt;!&ndash;        Manual activity&ndash;&gt;-->
<!--&lt;!&ndash;      </button>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
</div>
<!--<div *ngIf="isAddItemSearch" class="c-custom-itinerary__item c-custom-itinerary__item&#45;&#45;add">-->

<!--  <div class="c-itinerary-add-nav">-->
<!--    <span class="c-itinerary-add-nav__text">Add another product</span>-->

<!--    <div class="c-search-navbar">-->
<!--      <div class="c-search-navbar__item" (click)="selectSearchFlow('HTL')">-->
<!--        <svg class="a-icon c-search-navbar__item-icon">-->
<!--          <use xlink:href="#products&#45;&#45;hotel"></use>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div class="c-search-navbar__item" (click)="selectSearchFlow('FLT')">-->
<!--        <svg class="a-icon c-search-navbar__item-icon">-->
<!--          <use xlink:href="#products&#45;&#45;flight"></use>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div class="c-search-navbar__item" (click)="selectSearchFlow('CAR')">-->
<!--        <svg class="a-icon c-search-navbar__item-icon">-->
<!--          <use xlink:href="#products&#45;&#45;car"></use>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div class="c-search-navbar__item" (click)="selectSearchFlow('GEN')">-->
<!--        <svg class="a-icon c-search-navbar__item-icon">-->
<!--          <use xlink:href="#products&#45;&#45;tickets"></use>-->
<!--        </svg>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
