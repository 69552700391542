import {Pipe, PipeTransform} from '@angular/core';
import {SurfTextCasePipe} from '@surf/surf-components-core';

@Pipe({
  name: 'surfGrabTourDurationStringPipe'
})
export class SurfGrabTourDurationStringPipe implements PipeTransform{
  surfTextCasePipe = new SurfTextCasePipe(null);
  transform(tourGrabResult): string {
    let durationString = '';
    if (tourGrabResult && tourGrabResult.summary && tourGrabResult.summary.travelDuration &&
      tourGrabResult.summary.travelDuration.durationType && tourGrabResult.summary.travelDuration.duration) {
      durationString = tourGrabResult.summary.travelDuration.duration
        + ' ' + this.surfTextCasePipe.transform(tourGrabResult.summary.travelDuration.durationType, 'sentence');
    }
    return durationString;
  }
}
