<div class="o-modal__body">
  <div class="c-client-search">
    <div class="c-client-search__header">
      <h3 class="t3 c-client-search__header-title">Search Trade Client</h3>
    </div>
    <div class="c-client-search__form">
      <div class="o-inline-form-group">

        <div class="o-inline-form-group__item c-client-search__form-item">
          <surf-input
            [emitOnKeyUp]="true"
            [id]="'f-name'"
            [inputVal]="fName"
            [label]="'Client Name'"
            (value)="setFName($event)"
          ></surf-input>
        </div>

        <div class="o-inline-form-group__item c-client-search__form-item">
          <surf-input
            [emitOnKeyUp]="true"
            [inputVal]="cliRef"
            [id]="'f-ref'"
            [label]="'Client Ref'"
            (value)="setCliRef($event)"
          ></surf-input>
        </div>

      </div>

      <div class="c-client-search__form-btns t-mini-pack">
        <button class="a-btn a-btn--trinity u-mr" (click)="handleSurfClientSearchModal('close')">Cancel</button>
        <button class="a-btn a-btn--primary" [ngClass]="fName === '' && cliRef === '' ? 'is-disabled' : ''" (click)="searchClient()">Search</button>
      </div>
    </div>

    <!--    When Results are available and done loading    -->
    <div class="c-client-search__results" *ngIf="clientResultsAvailable && !tradeClientSearchLoading">
      <div class="o-result-table">

        <!-- table row title -->
        <div class="o-result-table__header c-client-search__results-header">
                <span class="o-result-table__header-item">
                    Ref
                    <svg class="a-icon">
                        <use xlink:href="#sort--down"></use>
                    </svg>
                </span>
          <span class="o-result-table__header-item">
                    Name & Location
                    <svg class="a-icon">
                        <use xlink:href="#sort--down"></use>
                    </svg>
                </span>
        </div>

        <!-- table row body -->
        <div class="o-result-table__body">

          <div class="o-result-table__row" *ngFor="let ClientResult of clientResultArray | paginate: paginationInstance; let i=index">
            <div class="o-result-table__summary c-client-search__results-row">
              <div class="c-client-search__results-data">
                <span>{{ClientResult?.id}}</span>
              </div>

              <div class="c-client-search__results-data m-info-set">
                <span>{{ClientResult?.summary?.name}}</span>
                <span class="tc-muted s6-text">
                  {{ClientResult?.summary?.address?.street ? ClientResult?.summary?.address?.street : ''}}{{ClientResult?.summary?.address?.city ? ', ' + (ClientResult?.summary?.address?.city | surfTextCase : ADDRESS_TEXT_CASE) : ''}}{{ClientResult?.summary?.address?.country ? ', ' + ClientResult?.summary?.address?.country : ''}}
                </span>
              </div>

              <div class="c-client-search__results-data">
                <button class="a-btn a-btn--primary c-client-search__select-btn" (click)="selectClientOption(ClientResult)">Select</button>
              </div>

            </div>
          </div>

        </div>
        <surf-pagination-controls *ngIf="true"
                                  [id]="paginationInstanceName"
                                  [hasMore]="false"
                                  [maxSize]="8"
                                  (pageChange)="onPageChange($event)">
        </surf-pagination-controls>

      </div>
    </div>

    <!--    When loading    -->
    <ng-container *ngIf="tradeClientSearchLoading">
      <div class="c-link-customer-profile__match c-skeleton-loader">
        <div class="c-link-customer-profile__lbl">
          <span class="c-skeleton-loader__item c-skeleton-loader__item--inline">Jhony Harris Wilson</span>
          <div class="">
            <span class="c-skeleton-loader__item c-skeleton-loader__item--inline">+80 123456789</span>
          </div>
        </div>
      </div>

      <div class="c-link-customer-profile__match c-skeleton-loader">
        <div class="c-link-customer-profile__lbl">
          <span class="c-skeleton-loader__item c-skeleton-loader__item--inline">Joe Harris Wilson Wilson</span>
          <div class="">
            <span class="c-skeleton-loader__item c-skeleton-loader__item--inline">+80 123456789</span>
          </div>
        </div>
      </div>
    </ng-container>

    <!--    When Results are not available and done loading    -->
    <span class="s4" *ngIf="resultsNotFound && !tradeClientSearchLoading">No matching trade clients found!</span>

  </div>
</div>
