import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {ModelStep} from "../models/modal-step.model";
import {MultiFieldInputProperty} from "@surf/surf-multi-field-input";
import { TC } from '@tc-core/util';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';

@Component({
  selector: 'surf-grab-pnr-details',
  templateUrl: './grab-pnr-details.component.html'
})
export class GrabPnrDetailsComponent implements OnInit {

  MODAL_STEP = ModelStep;
  @Input() properties: MultiFieldInputProperty[] = [];
  @Input() componentValue: any = [];
  @Input() disabled: boolean ;
  @Input() defaultGDS;
  @Input() isDP : boolean = false;
  @Input() isSabre : boolean = true;
  @Input() error: string = null;
  @Input() msg: string = null;
  @Input() emitOnKeyUp: boolean ;
  @Input() wrapperView: boolean ;
  @Input() stepStatus: string = null;
  @Output() usePNRPriceRecordOut: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() setValidInputOut: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() onValueChangeOut: EventEmitter<any> = new EventEmitter<any>();
  @HostBinding('class') classes = 'c-add-manual-item__section';
  usePNRPriceRecord : boolean = true;
  moduleConfigs: Array<any>;
  TPC_SPECIFIC_LABEL_ENABLED: boolean = false;
  specifyPNRLabel: string;
  specifyPNRSubLabel: string;
  pnrLabel: string;

  constructor(
    protected configLoader: ConfigLoader,
    private dataStore: DataStore
  ) {
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();
    this.loadConfigs();
    this.renameTPCLabels();
  }

  ngOnInit() {
  }
  checkValue(event) {
    this.usePNRPriceRecordOut.emit(event);
    this.usePNRPriceRecord = event.target.checked;
  }
  setValidInput(event) {
    this.setValidInputOut.emit(event);
  }
  onValueChange(event): void {
    if (this.isDP && event && event[0]) {
      if (event[0].code === 'SAB') {
        this.usePNRPriceRecord = true;
      }
    }
    this.onValueChangeOut.emit(event);
  }

  renameTPCLabels(): void {
    this.specifyPNRLabel = this.TPC_SPECIFIC_LABEL_ENABLED ? 'Specify PNR / Trip ID number' : 'Specify PNR number ';
    this.specifyPNRSubLabel = this.TPC_SPECIFIC_LABEL_ENABLED ? 'Enter valid PNR / Trip ID to search flight criteria' : 'Enter valid PNR ID to search flight criteria';
    this.pnrLabel = this.TPC_SPECIFIC_LABEL_ENABLED ? 'PNR / TRIP ID' : 'PNR ID ';
  }

  loadConfigs(): void {
    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      if (this.configLoader.getModuleConfig('TPC_SPECIFIC_LABEL_ENABLED', TC.MODULE_NAME.SURF_B2B)) {
        this.TPC_SPECIFIC_LABEL_ENABLED = this.configLoader.getModuleConfig('TPC_SPECIFIC_LABEL_ENABLED', TC.MODULE_NAME.SURF_B2B).toUpperCase() === 'TRUE';
      }
    }
  }


}
