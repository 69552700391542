import {NgModule} from '@angular/core';
import {FlightCriteriaComponent} from './flight-criteria/flight-criteria.component';
import {CommonModule} from '@angular/common';
import {SurfCriteriaSuggesterModule} from '@surf/surf-criteria-suggester';
import {SurfMultiSelectorModule} from '@surf/surf-multi-selector';
import {SurfTypeAheadModule} from '@surf/surf-type-ahead';
import {SurfDropDownModule} from '@surf/surf-drop-down';
import {SurfChipModule} from '@surf/surf-chip';
import {SurfInputModule} from '@surf/surf-input';
import {SurfCheckboxModule} from '@surf/surf-checkbox';
import {SurfRadioButtonModule} from '@surf/surf-radiobutton';
import { FlightCriteriaDpComponent } from './flight-criteria-dp/flight-criteria-dp.component';
import {SurfComponentsCoreModule, SurfCriteriaUtil} from '@surf/surf-components-core';
import {SurfPaxSelectionModule} from '@surf/surf-pax-selection';
import {SurfDatePickerModule} from '@surf/surf-date-picker';
import {SurfMultiFieldInputModule} from '@surf/surf-multi-field-input';
import {SurfDPAddBarModule} from '@surf/surf-dp-add-bar';
import {SurfGrabPnrModule} from '@surf/surf-grab-pnr';
import {SurfMultiSelectorDropDownModule} from "@surf/surf-multi-selector-drop-down";

@NgModule({
  declarations: [FlightCriteriaComponent, FlightCriteriaDpComponent],
  imports: [
    CommonModule,
    SurfCriteriaSuggesterModule,
    SurfMultiSelectorModule,
    SurfTypeAheadModule,
    SurfDropDownModule,
    SurfChipModule,
    SurfInputModule,
    SurfRadioButtonModule,
    SurfComponentsCoreModule,
    SurfPaxSelectionModule,
    SurfCheckboxModule,
    SurfDatePickerModule,
    SurfMultiFieldInputModule,
    SurfDPAddBarModule,
    SurfGrabPnrModule,
    SurfMultiSelectorDropDownModule
  ],
  exports: [FlightCriteriaComponent, FlightCriteriaDpComponent],
  providers: [SurfCriteriaUtil]
})
export class SurfFlightCriteriaModule {
}
