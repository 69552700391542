import { NgModule } from '@angular/core';
import { CruiseCriteriaComponent } from './cruise-criteria/cruise-criteria.component';
import { CruiseCriteriaDpComponent } from './cruise-criteria-dp/cruise-criteria-dp.component';
import {CommonModule} from '@angular/common';
import {SurfPaxSelectionModule} from '@surf/surf-pax-selection';
import {SurfDPAddBarModule} from '@surf/surf-dp-add-bar';
import {SurfDropDownModule} from '@surf/surf-drop-down';
import {SurfDatePickerModule} from '@surf/surf-date-picker';
import {SurfMultiFieldInputModule} from '@surf/surf-multi-field-input';
import {SurfMultiSelectorDropDownModule} from '@surf/surf-multi-selector-drop-down';
import {SurfTypeAheadModule} from '@surf/surf-type-ahead';
import {
  SurfCriteriaUtil,
  SurfTextCasePipeModule
} from '@surf/surf-components-core';
import {SurfStateManageModule} from "@surf/surf-state-manage";

@NgModule({
  declarations: [CruiseCriteriaComponent, CruiseCriteriaDpComponent],
  imports: [
    CommonModule,
    SurfPaxSelectionModule,
    SurfDPAddBarModule,
    SurfDropDownModule,
    SurfDatePickerModule,
    SurfMultiFieldInputModule,
    SurfMultiSelectorDropDownModule,
    SurfTypeAheadModule,
    SurfTextCasePipeModule,
    SurfStateManageModule,
  ],
  exports: [CruiseCriteriaComponent, CruiseCriteriaDpComponent],
  providers: [SurfCriteriaUtil]
})
export class SurfCruiseCriteriaModule { }
