<div class="c-advance-search__content" *ngIf="!isGetOnlyRequestPayload">
  <ng-container *ngIf="!isServiceAddComponent && !(isAmendmentFlow && !isRefine); else elseblock">
    <div class="c-advance-search__content-top">
      <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Create itinerary for</h3>
      <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine itinerary for</h3>
      <div class="c-advance-search__guests">
        <div class="m-form-item auto-pax-count" [id]="'pax-selection'" *ngIf="!(isGrabFlow || refineGrab || (isAmendmentFlow && isRefine) )">
          <surf-pax-selection
            [(isFocused)]="guestComponentFocus"
            [paxSet]="cachePaxSet"
            [product]="hasHotelItem ? PROD_CONST.ACCOMMODATION : PROD_CONST.FLIGHT"
            [inputWrapperView]="false"
            [disableToggle]="allowRoomWisePax"
            [maxAdult]="MAX_ADULT"
            [minAdult]="MIN_ADULT"
            [maxPaxCount]="MAX_PAX_COUNT"
            [maxCabinPaxCount]="MAX_CABIN_PAX_COUNT"
            [maxChildren]="MAX_CHILD"
            [maxInfants]="MAX_INFANTS"
            [maxInfantAge]="MAX_INFANT_AGE"
            [maxChildAge]="MAX_CHILD_AGE"
            [maxTeenAge]="MAX_TEEN_AGE"
            [maxTeen]="MAX_TEEN"
            [maxCabins]="MAX_ROOMS"
            [roomViewSelected]="setRoomWise"
            [b2bView]="true"
            [infoDisabled]="true"
            [tabIndex]="2"
            [countInfants]="true"
            [parentScrollId]="windowScrollEnable ? 'pax-selection': null"
            [infantSeatOption]="hasFlightItem"
            [isTeenActive]="TEEN_PAX_ACTIVE"
            (paxSelection)="getPassengerSelection($event)"
            (isRoomWiseSelected)="isRoomWiseSelected($event)">
          </surf-pax-selection>
        </div>
        <div class="m-form-item auto-pax-count" *ngIf="isGrabFlow || refineGrab || (isAmendmentFlow && isRefine)">
          <span class="surf-pax-selection">
            <div class="m-form-item">
              <div class="m-form-item__input surf-pax-selection__input auto-btn-search-for-close">
                <span class="m-form-field__field-item">
                  <input *ngIf="!refineGrab && isGrabFlow" class="m-form-field__input" size="8" value="{{grabPax?.length}} Guest{{grabPax?.length > 1 ? 's' : ''}}" disabled="true">
                  <input *ngIf="refineGrab || isAmendmentFlow" class="m-form-field__input" size="8" value="{{refinePaxString}}" disabled="true">
                </span>
              </div>
            </div>
          </span>
        </div>

      </div>

      <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
    </div>
  </ng-container>
  <ng-template #elseblock>
    <div class="c-advance-search__content-top">
      <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
        <svg class="a-icon o-modal__close-icon">
          <use xlink:href="#close"></use>
        </svg>
      </button>
      <h3 class="t2 c-advance-search__title">Define your search for</h3>
      <div class="c-advance-search__guests">
        <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
      </div>
    </div>

  </ng-template>

  <div class="c-advance-search__form">

    <div class="o-block o-block--warning c-msg-block c-component-search__warning" *ngIf = "isAmendmentFlow && ( isFlightsNotAmendedMessageShown || isToursNotAmendedMessageShown )">
      <div class="c-msg-block__wrapper">
        <svg class="a-icon c-msg-block__info-icon">
          <use xlink:href="#exclamation"></use>
        </svg>
        <div class="c-msg-block__info">
          <div class="c-msg-block__info-txt">Please note that flight and tour items are not allowed to be
            amended.
          </div>

        </div>
      </div>
    </div>

    <div class="c-dp-search">

<!--      this is commented because kuoni not need this-->

<!--      <div class="c-advance-search__grab-pnr auto-pnr-button">-->
<!--        <div class="c-grab-pnr ng-star-inserted">-->
<!--          <div class="c-grab-pnr__action t-tiny-pack">-->
<!--            <button class="a-btn a-btn&#45;&#45;secondary ng-star-inserted u-mr" *ngIf="hasMultipleFlight()" (click)="manageFlights()">Combine flights</button>-->
<!--            <div *ngIf="( enableGrabPnr || tourFetchEnableInDp) && !isEpToDpConversion && !isAmendmentFlow && !isServiceAddComponent" [ngClass]="{'c-dropdown-menu': (!(grabbedTourId && grabbedTourId.length > 0) && !(selectedPnrNumber && selectedPnrNumber.length > 0)) }">-->
<!--              <ng-container *ngIf="(!(grabbedTourId && grabbedTourId.length > 0) && !(selectedPnrNumber && selectedPnrNumber.length > 0))">-->
<!--                <button class="a-btn a-btn&#45;&#45;secondary">-->
<!--                  Grab Product-->
<!--                  <svg class="a-icon u-rml">-->
<!--                    <use xlink:href="#pointer-down"></use>-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </ng-container>-->
<!--              <div [ngClass]="{'c-dropdown-menu__btn-wrap': (!(grabbedTourId && grabbedTourId.length > 0) && !(selectedPnrNumber && selectedPnrNumber.length > 0)) }">-->
<!--                <surf-grab-pnr-->
<!--                  *ngIf=" !(grabbedTourId && grabbedTourId.length > 0) && enableGrabPnr && !isEpToDpConversion && !isAmendmentFlow && !isServiceAddComponent"-->
<!--                  [grabType]="pnrType"-->
<!--                  [selectedPnrNumber]="selectedPnrNumber"-->
<!--                  [selectedGDS]="selectedGDS? selectedGDS : ''"-->
<!--                  [reset]="pnrReset"-->
<!--                  [isDP]="true"-->
<!--                  [isBkgPage]="true"-->
<!--                  [isRefine] = "isRefine"-->
<!--                  [stageChangeTrigger]="stageChangeTrigger"-->
<!--                  [cachingItemMap]="cachingItemMap"-->
<!--                  [allowNgrxCaching]="allowNgrxCaching"-->
<!--                  (pnrComponentOutput)="setGrabPNRCriteria($event)">-->
<!--                </surf-grab-pnr>-->
<!--                <surf-grab-tour-->
<!--                  *ngIf="!(selectedPnrNumber && selectedPnrNumber.length > 0) && tourFetchEnableInDp && !isEpToDpConversion && !isAmendmentFlow && !isServiceAddComponent"-->
<!--                    [grabbedTourId] ="grabbedTourId"-->
<!--                    [tourFetchSummarySearchCriteria]="tourCriteria"-->
<!--                    [isDisableGrabTourButton]="grabbedTourId? true:false"-->
<!--                    [keyControls]="keyControls"-->
<!--                    [isInBookingPage]="true"-->
<!--                    [isDP] = "true"-->
<!--                    (tourGrabResultOutput)="tourGrabResultOutput($event)"-->
<!--                  ></surf-grab-tour>-->

<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!--DP criteria intro-->
      <div class="c-dp-search__intro" *ngIf="isCriteriaListEmpty()">

        <div class="c-dp-search__intro-text">
          <h3 class="t3">Start creating your itinerary</h3>
          <div class="s4">Start from any component listed below and make your own itinerary
            from scratch.
            <span class="tc-info ts-bold">Please select components in actual travelling order</span>
          </div>
        </div>

        <div class="c-dp-search__nav">
          <surf-dp-add-bar
            [isDPSearch]="true"
            [isIntro]="true"
            [totalItemCount]="criteriaItemList.length"
            [isDisabled]="isGrabFlow? DISABLE_DP_GRAB_ADD_ANOTHER_FLOW : false"
            (addItem)="addCriteriaItem($event)">
          </surf-dp-add-bar>
        </div>
        <ng-container *ngIf="isActiveDpTemplate && DP_TEMPLATES_ENABLED && criteriaItemList.length === 0">
          <span class="c-dp-search__separator">or start with a templates</span>
          <surf-dp-template
            [isActive]="isActiveDpTemplate && DP_TEMPLATES_ENABLED && criteriaItemList.length === 0"
            (selectedItemEvent)="handleDpTemplateSelectedEvent($event)"
          ></surf-dp-template>
        </ng-container>
      </div>
      <!--DP criteria intro-->


      <!--DP criteria items-->

      <ng-template [ngIf]="criteriaItemList && criteriaItemList.length > 0">

        <surf-dp-add-bar [isDPSearch]="true"
                         [isIntro]="false"
                         [preItemIndex]="-1"
                         [postItemIndex]="0"
                         [isDisabled]="isGrabFlow? DISABLE_DP_GRAB_ADD_ANOTHER_FLOW : false"
                         [totalItemCount]="criteriaItemList.length"
                         (addItem)="addAnotherCriteriaItem($event)">
        </surf-dp-add-bar>

        <ng-template ngFor let-item [ngForOf]="criteriaItemList" let-i="index">

          <ng-template [ngIf]="item?.productCode == 'HTL'">
            <surf-hotel-criteria-dp
              [isDPSearch]="true"
              [isDpRefine]="isRefine"
              [index]="item?.index"
              [oldCriteriaItem]="item?.oldCriteriaItem"
              [isItemCloseable]="isAmendmentFlow && item?.isNewCriteriaItem ? true : isItemsCloseable"
              [selectComp]="item?.index == 0"
              [dpHotelDestinations]="hotelDestinations"
              [dpEnableDate]="item?.disableStartDate"
              [dpInitialDateStr]="item?.initialDateStr"
              [dpInitialDestStr]="item?.initialDestStr"
              [unfreeze]="unfreezeItemMap.get(i)"
              [isAmendmentFlow]="isAmendmentFlow"
              [paxSplitArray]="item.paxSelection? item.paxSelection : []"
              [enablePaxSplit]="item?.enablePaxSplit"
              [isForEpToDpConversion]="isEpToDpConversion"
              (notifyDPDependencyChange)="onDependencyChange($event)"
              (isValid)="setValid($event,item)"
              (isUnfreeze)="passUnfreezeItem($event,item)"
              (isPaxSplitChanged)="changePaxSplit($event, item?.index)"
              (itemRemoved)="removeCriteriaItem($event)">
            </surf-hotel-criteria-dp>
          </ng-template>
          <ng-template [ngIf]="item?.productCode == 'TOU'">
            <surf-tour-criteria-dp
              [isDPSearch]="true"
              [isRefine]="isRefine"
              [index]="item?.index"
              [isDpGrab] = "item?.isGrab"
              [unfreeze]="unfreezeItemMap.get(i)"
              [isItemCloseable]="isAmendmentFlow && item?.isNewCriteriaItem ? true : isItemsCloseable"
              [isAmendmentFlow]="isAmendmentFlow"
              [isEpToDpConversion]="isEpToDpConversion"
              [dpTourRefineCriteria]="item?.criItem"
              [grabBlockDetails]="item?.blockDetail"
              (isValid)="setValid($event,item)"
              (isUnfreeze)="passUnfreezeItem($event,item)"
              (itemRemoved)="removeCriteriaItem($event)">
            </surf-tour-criteria-dp>
          </ng-template>

          <ng-template [ngIf]="item?.productCode == 'CAR'">
            <surf-car-criteria-dp
              [isDPSearch]="true"
              [index]="item?.index"
              [oldCriteriaItem]="item?.oldCriteriaItem"
              [isItemCloseable]="isAmendmentFlow && item?.isNewCriteriaItem ? true : isItemsCloseable"
              [selectComp]="item?.index == 0"
              [dpCarDestinations]="carDestinations"
              [dpEnableDate]="item?.disableStartDate"
              [dpInitialDateStr]="item?.initialDateStr"
              [dpInitialDestStr]="item?.initialDestStr"
              [dpCarRefineCriteria]="item?.criItem"
              [selectedDriverAge]="item?.criItem?.driverAge"
              [isRefine]="isRefine"
              [unfreeze]="unfreezeItemMap.get(i)"
              [isAmendmentFlow]="isAmendmentFlow"
              [paxSplitArray]="item.paxSelection? item.paxSelection : []"
              [enablePaxSplit]="item?.enablePaxSplit"
              (isPaxSplitChanged)="changePaxSplit($event, item?.index)"
              (isValid)="setValid($event,item)"
              (isUnfreeze)="passUnfreezeItem($event,item)"
              (itemRemoved)="removeCriteriaItem($event)">
            </surf-car-criteria-dp>
          </ng-template>

          <ng-template [ngIf]="item?.productCode == 'TRS'">
            <surf-transfer-criteria-dp
              class="auto-dp-search-block-trs"
               [isDPSearch]="true"
               [index]="item?.index"
               [oldCriteriaItem]="item?.oldCriteriaItem"
               [isItemCloseable]="isAmendmentFlow && item?.isNewCriteriaItem ? true : isItemsCloseable"
               [selectComp]="item?.index == 0"
               [dpTransferDestinations]="carDestinations"
               [dpEnableDate]="item?.disableStartDate"
               [dpInitialDateStr]="item?.initialDateStr"
               [dpInitialDestStr]="item?.initialDestStr"
               [isRefine]="isRefine"
               [dpTransferRefineCriteria]="item?.criItem"
               [unfreeze]="unfreezeItemMap.get(i)"
               [isAmendmentFlow]="isAmendmentFlow"
              [paxSplitArray]="item.paxSelection? item.paxSelection : []"
              [enablePaxSplit]="item?.enablePaxSplit"
              [isForEpToDpConversion]="isEpToDpConversion"
              (isPaxSplitChanged)="changePaxSplit($event, item?.index)"
               (isValid)="setValid($event,item)"
               (isUnfreeze)="passUnfreezeItem($event,item)"
               (itemRemoved)="removeCriteriaItem($event)">
            </surf-transfer-criteria-dp>
          </ng-template>

          <ng-template [ngIf]="item?.productCode == 'FLT'">
            <surf-flight-criteria-dp
              [isDPSearch]="true"
              [index]="item?.index"
              [isItemCloseable]="isAmendmentFlow && item?.isNewCriteriaItem ? true : isItemsCloseable"
              [selectComp]="item?.index == 0"
              [dpEnableDate]="item?.disableStartDate"
              [originalRefineCriteria]="item?.criItem?.legs[0]"
              [dpFlightRefineCriteria]="item?.criItem"
              [dpInitialDateStr]="item?.initialDateStr"
              [dpInitialDestStr]="item?.initialDestStr"
              [isRefine]="isRefine"
              [dpGrab]="item?.isGrabbedLeg"
              [isPnr]="refineGrab"
              [flightGroupDetail]="getFlightGroupDetail(item.index)"
              [unfreeze]="unfreezeItemMap.get(i)"
              [isAmendmentFlow]="isAmendmentFlow"
              [paxSplitArray]="item.paxSelection? item.paxSelection : []"
              [enablePaxSplit]="item?.enablePaxSplit"
              [isForEpToDpConversion]="isEpToDpConversion"
              [airportsList]="airportCodesList"
              (isPaxSplitChanged)="changePaxSplit($event, item?.index)"
              (isValid)="setValid($event,item)"
              (isUnfreeze)="passUnfreezeItem($event,item)"
              (itemRemoved)="removeCriteriaItem($event)">
            </surf-flight-criteria-dp>
          </ng-template>

          <ng-template [ngIf]="item?.productCode == 'GEN'">
            <surf-generic-criteria-dp
              [isDPSearch]="true"
              [index]="item?.index"
              [oldCriteriaItem]="item?.oldCriteriaItem"
              [isItemCloseable]="isAmendmentFlow && item?.isNewCriteriaItem ? true : isItemsCloseable"
              [selectComp]="item?.index == 0"
              [dpGenDestinations]="hotelDestinations"
              [dpEnableDate]="item?.disableStartDate"
              [dpInitialDateStr]="item?.initialDateStr"
              [dpInitialDestStr]="item?.initialDestStr"
              [isRefine]="isRefine"
              [dpGenericRefineCriteria]="item?.criItem"
              [unfreeze]="unfreezeItemMap.get(i)"
              [isAmendmentFlow]="isAmendmentFlow"
              [paxSplitArray]="item.paxSelection? item.paxSelection : []"
              [enablePaxSplit]="item?.enablePaxSplit"
              (isPaxSplitChanged)="changePaxSplit($event, item?.index)"
              (isValid)="setValid($event,item)"
              (isUnfreeze)="passUnfreezeItem($event,item)"
              (itemRemoved)="removeCriteriaItem($event)">
            </surf-generic-criteria-dp>
          </ng-template>

          <ng-template [ngIf]="item?.productCode == 'OWA'">
            <surf-own-arrangements
            [index]="item?.index"
            [oldCriteriaItem]="item?.oldCriteriaItem"
            [isItemCloseable]="isAmendmentFlow && item?.isNewCriteriaItem ? true : isItemsCloseable"
            [isRefine]="isRefine"
            [payloadObject]="item.criItem"
            [initialDateStr]="item?.initialDateStr"
            [disableStartDate]="item.disableStartDate"
            [unfreeze]="unfreezeItemMap.get(i)"
            [isAmendmentFlow]="isAmendmentFlow"
            [paxSplitArray]="item.paxSelection? item.paxSelection : []"
            (isPaxSplitChanged)="changePaxSplit($event, item?.index)"
            (itemRemoved)="removeCriteriaItem($event)"
            (isValid)="setValid($event,item)"
            (isUnfreeze)="passUnfreezeItem($event,item)"
            ></surf-own-arrangements>
          </ng-template>

          <surf-dp-add-bar [isDPSearch]="true"
                           [isIntro]="false"
                           [preItemIndex]="item?.index"
                           [postItemIndex]="item?.index + 1"
                           [isDisabled]="isGrabFlow? DISABLE_DP_GRAB_ADD_ANOTHER_FLOW : false"
                           [totalItemCount]="criteriaItemList.length"
                           (addItem)="addAnotherCriteriaItem($event)">
          </surf-dp-add-bar>

        </ng-template>

      </ng-template>

      <!--DP criteria items-->

      <surf-message-box [id]="AMENDMENT_MODAL_ID"  [title]="'Warning'" [cancelLabel]="'Cancel'" [active]="activeMessageBox"
                        [hasConfirmButon]="true" [confirmLabel]="'Change'"
                        [svgName]="'exclamation'" [type]="'warning'" (clickButton)="handleDependencyWarning($event)">
        <div class="surf-msg-box__body">Would you like to change the destination of {{changingItems}}</div>
      </surf-message-box>

    </div>

  </div>
  <div *ngIf="isTourGrabItemAvailable( criteriaItemList ) && !isAmendmentFlow && !ENABLE_SURF_TOUR_GRAB_MULTIPLE_ITEM_DP_FLOW" class="o-block c-msg-block c-msg-block--info u-mt-l ng-star-inserted">
    <div class="l-inline-block">
      <svg class="a-icon c-msg-block__info-icon">
      <use xlink:href="#info"></use>
      </svg>
      <div class="c-msg-block__info">
      <div class="c-msg-block__info-txt cap-bold">Please note there are multiple tours on the grabbed booking number. Only one tour can be grabbed at a time.</div>
      </div>
    </div>
  </div>

  <div class="c-advance-search__btn-wrapper">
    <div class="c-advance-search__search-info" >
      <button *ngIf="activeCriteriaButton" class="a-btn a-btn--default is-disabled u-hidden-sm" >Save/ Load Criteria</button>
    </div>
    <div class="c-advance-search__search-action" *ngIf="isEpToDpConversion">
      <button class="a-btn a-btn--default"  (click)="cancelEpSearchCriteriaModal()">Cancel</button>
    </div>
    <div class="c-advance-search__search-action">
      <ng-container *ngIf="!isServiceAddComponent && !isAmendmentFlow">
        <button class="a-btn a-btn--default auto-reset-search" *ngIf="!isRefine && isResetButtonVisible" (click)="resetCriteria()">Reset Search</button>
        <button class="a-btn a-btn--default" *ngIf="isRefine" (click)="closeCriteriaBox()">Back to results</button>
      </ng-container>
      <ng-container *ngIf="isServiceAddComponent || isAmendmentFlow">
        <button class="a-btn a-btn--default"  (click)="closeCriteriaBox()">Back to results</button>
      </ng-container>
      <button class="a-btn a-btn--primary auto-create-itinerary" *ngIf="!isRefine" [ngClass]="{'is-disabled':!validateDPCriteria() || checkAllItemFreeze() || !checkTourGrabHasMultipleItems()}" (click)="getGeoLocationDetailsAndContinue(false)">{{_continueText}}</button>
      <button class="a-btn a-btn--primary" *ngIf="isRefine" [ngClass]="{'is-disabled':!validateDPCriteria() || checkAllItemFreeze() || !checkTourGrabHasMultipleItems()}" (click)="getGeoLocationDetailsAndContinue(false)">Refine Itinerary</button>
    </div>
  </div>

</div>

<surf-message-box [title]="'Enter Mandatory Fields'" [confirmLabel]="'Ok'"  [active]="manageFlightIncompleteFields"
                  [hasConfirmButon]="true" [hasCancelButton]="false"
                  [svgName]="'info'" [type]="'info'" (clickButton)="manageFlightIncompleteFields = false">
  <!--  <div class="surf-msg-box__hint">{{msgBoxHint}}</div>-->
  <span>{{'Please enter mandatory fields before proceeding Manage flights.'}}</span>
</surf-message-box>

<div class="o-modal" [ngClass]="{'is-active': manageFlightsModalDisplay}" id="manage-flights-modal-dp">

  <div class="o-modal__content c-combine-flights-modal">

    <div class="o-modal__body">

      <h3 class="t3 ts-dark">Combine flights</h3>
      <span class="t6 ts-normal">Select the same group name to combine flights</span>
      <!-- Modal content -->
      <div class="c-combine-flights u-mt">

        <div class="c-combine-flights__row u-rmb">
          <div class="o-inline-form-group">
            <div class="o-inline-form-group__item c-combine-flights__items"></div>
            <div class="o-inline-form-group__item c-combine-flights__items">
              <span class="a-loz a-loz--important c-combine-flights-tag">Combine with</span>
            </div>
          </div>
        </div>

        <div class="c-combine-flights__row" *ngFor="let groupDetail of flightsGrouping;let index = index;">
          <div class="o-inline-form-group">
            <div class="o-inline-form-group__item c-combine-flights__items">
              <div class="t3 c-combine-flights__line">{{(index  + 1)}}.</div>
              <div class="c-combine-flights__details">
                <span class="t5 ts-bold">
                  {{groupDetail?.itineraryItemNo != null ? criteriaItemList[groupDetail?.itineraryItemNo]?.criItem?.legs[0]?.departureAirportCode : ''}}
                  <svg class="a-icon tc-muted"><use class="ng-tns-c9-3" xlink:href="#long-arrow--forward"></use></svg>
                  {{groupDetail?.itineraryItemNo != null ? criteriaItemList[groupDetail?.itineraryItemNo]?.criItem?.legs[0]?.arrivalAirportCode : ''}}
                </span>
                <span class="t6 ts-muted">{{groupDetail?.itineraryItemNo ? criteriaItemList[groupDetail?.itineraryItemNo]?.criItem?.legs[0]?.cabinClass : '' | surfTextCase:'title'}}</span>
              </div>
            </div>

            <div class="o-inline-form-group__item c-combine-flights__items">
              <div class="m-form-field">
                <surf-ul-drop-down
                  [listStyle]="'container'"
                  [select]=true
                  [items]="groupDetail?.dropdownOptions"
                  [native]="true"
                  [disabled]="index === grabbedCriteriaItemList.length || groupDetail.isDisabled">
                  <!--(singleObject)="handleCabinClassSelection($event, item)"-->
                </surf-ul-drop-down>
              </div>
            </div>

          </div>

        </div>

      </div>
      <!-- content end -->
      <div class="o-modal__footer">
        <button class="a-btn a-btn--trinity u-mr" (click)="ManageFlightsCancel()">Cancel</button>
        <button class="a-btn a-btn--primary" (click)="ManageFlightsConfirm()" [ngClass]="{'is-disabled':(grabbedCriteriaItemList.length + 1) >= flightsGrouping.length}">Continue</button>
      </div>
    </div>
  </div>

</div>


<surf-message-box [title]="'Reset PNR criteria?'" [active]="showDPGrabResetWarning" id="{{DP_GRAB_RESET_WARNING_MODAL_ID}}"
                  [hasConfirmButon]="true" [hasCancelButton]="true" (clickButton)="continueResetGrab($event)"
                  [confirmLabel]="'Confirm'"
                  [cancelLabel]="'Cancel'"
                  [svgName]="'question'" [type]="'info'">
  <span>Your grabbed flight criteria will be removed. Would your like to continue?</span>
</surf-message-box>

<surf-message-box [title]="'Reset Grabbed criteria?'" [active]="showDPTourGrabResetWarning" id="{{DP_GRAB_RESET_WARNING_MODAL_ID_NEW}}"
                  [hasConfirmButon]="true" [hasCancelButton]="true" (clickButton)="continueResetGrab($event)"
                  [confirmLabel]="'Confirm'"
                  [cancelLabel]="'Cancel'"
                  [svgName]="'question'" [type]="'info'">
  <span>Your grabbed tour criteria will be removed. Would your like to continue?</span>
</surf-message-box>

<!-- amendment dependency identification modal -->
<surf-message-box [title]="'Warning'" [active]="showAmendmentDependencyModal"  id="{{AMENDMENT_DEPENDENCY_MODAL_ID}}"
                  [hasConfirmButon]="true" [hasCancelButton]="true" (clickButton)="handleDependencyModal($event)"
                  [confirmLabel]="'Continue'"
                  [cancelLabel]="'Cancel'"
                  [svgName]="'error'" [type]="'warning'">
  <span class="surf-msg-box__body-content">There are other products that are affected by your amendment. Would you like to allow the changes?</span>
  <ul class="surf-msg-box__list u-mt2-">
    <li class="surf-msg-box__list-item a-loz a-loz--important" *ngFor="let dependencyItem of impactedItemList">
      <svg class="a-icon u-rmr">
        <use xlink:href="#info"></use>
      </svg>
      <span>{{dependencyItem.ProductType + ', '+ dependencyItem.date}}</span>
      <ng-container *ngIf="dependencyItem?.changeToDate">
        <span>{{' (Change to: '+ dependencyItem.changeToDate +')'}}</span>
      </ng-container>
    </li>
  </ul>
</surf-message-box>
