import {NgModule} from '@angular/core';
import {BookingSummaryComponent} from './booking-summary/booking-summary.component';
import {CommonModule} from '@angular/common';
import {SurfBookingSearchModule} from "@surf/surf-booking-search";
import {SurfComponentsCoreModule} from "@surf/surf-components-core";
import {SurfToggleModule} from "@surf/surf-toggle";
import {SurfCalendarModule} from "@surf/surf-calendar";

@NgModule({
  declarations: [BookingSummaryComponent],
  imports: [
    CommonModule,
    SurfBookingSearchModule,
    SurfComponentsCoreModule,
    SurfToggleModule,
    SurfCalendarModule
  ],
  exports: [BookingSummaryComponent]
})
export class SurfBookingSummaryModule {
}
