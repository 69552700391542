import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TransferCriteriaComponent} from '../transfer-criteria/transfer-criteria.component';
import {
  DataShareService,
  DestinationType, SurfCalendarStartDateService,
  SurfCityPipe,
  SurfCriteriaUtil,
  SurfStaticDataService,
  SurfTextCasePipe, SurfTravellerEarliestDepartureDateService
} from '@surf/surf-components-core';
import {ConfigLoader, DataStore} from '@tc-core/util/framework';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {TC} from '@tc-core/util';

@Component({
  selector: 'surf-transfer-criteria-dp',
  templateUrl: './transfer-criteria-dp.component.html'
})
export class TransferCriteriaDpComponent extends TransferCriteriaComponent implements OnInit, OnChanges {

  @Input() selectComp = false;              /*needed for dp criteria*/
  @Output() itemRemoved: EventEmitter<any> = new EventEmitter<any>();
  isSelected = false;
  cityPipe: SurfCityPipe;
  msg = '';
  transferHideTimeDetails = false;
  showReturnDate = false;
  TRS_ONEWAY_RETURN_SELECTION_MODE: 'DEFAULT'| 'OPT_FOR_RETURN' | any = 'DEFAULT';

  constructor(protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected dataServiceHandler: DataServiceHandler,
              public surfCriteriaUtil: SurfCriteriaUtil,
              private surfStaticDataService: SurfStaticDataService,
              protected surfCityPipe: SurfCityPipe,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService) {
    super(commonService, dataStore, configLoader, dataServiceHandler, surfCriteriaUtil, surfCityPipe,
      travellerEarliestDepartureDateService, surfCalendarStartDateService);
    this.cityPipe = new SurfCityPipe(surfStaticDataService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    /*if this component is added to the dp criteria panel initially, then it should be selected = true*/
    if (changes.selectComp) {
      this.isSelected = changes.selectComp.currentValue;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.showPickupTypeAheadContent = true;
    this.showDropoffTypeAheadContent = true;
    this.transferHideTimeDetails = this.configLoader.getModuleConfig('TRS_HIDE_TIME_DETAILS',TC.MODULE_NAME.SURF_B2B) === 'true';
    this.TRS_ONEWAY_RETURN_SELECTION_MODE = this.configLoader.getModuleConfig('TRS_ONEWAY_RETURN_SELECTION_MODE',TC.MODULE_NAME.SURF_B2B)?.toString()?.toUpperCase()?.trim() || 'DEFAULT';
  }

  public selectComponent() {
    this.isSelected = true;
  }

  public deselectComponent() {
    this.isSelected = false;
    this.showPickupTypeAheadContent = false;
    this.showDropoffTypeAheadContent = false;
    this.showPickUpRecommendationList = false;
    this.showDropOffRecommendationList = false;
  }

  removeItem() {
    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(null, 'TRS');
    }
    this.itemRemoved.emit(this.index);
  }

  moveToDP(position: string, event: boolean) {
    if (!this.isRefine && event) {          /*TODO: Implemented for refine*/
      if (!this.isDPSearch) {                     /*Move to DP search panel if only; was in the component flow previously*/
        this.msg = 'DP_Switch~TRS~' + position;
        // this.commonService.changeMessage(msg);
      }
    }
  }

  handleInputFocusOut(event) {
    console.log(event);
    if (event == this.pickupLocationPropId ) {
      if(this.transferCriteria.pickupLocationName && !this.transferCriteria.pickupLocationName.includes('undefined')) {
        this.selectedPickUpPoint.displayText = this.transferCriteria.pickupLocationName;
      }
      else if(this.transferCriteria.pickupLocationName &&
        this.transferCriteria.pickupLocationName.includes('undefined') &&
        this.transferCriteria.pickupCity &&
        !this.transferCriteria.pickupCity.includes('undefined')) {
        this.cityPipe.transform(this.transferCriteria.pickupCity).subscribe(res => {
          this.selectedPickUpPoint.displayText = res;
        });
      }
    } else if (event == this.dropoffLocationPropId) {
      if(this.transferCriteria.dropOffLocationName && !this.transferCriteria.dropOffLocationName.includes('undefined')) {
        this.selectedDropOffPoint.displayText = this.transferCriteria.dropOffLocationName;
      }
      else if(this.transferCriteria.dropOffLocationName &&
        this.transferCriteria.dropOffLocationName.includes('undefined') &&
        this.transferCriteria.dropOffCity &&
        !this.transferCriteria.dropOffCity.includes('undefined')) {
        this.cityPipe.transform(this.transferCriteria.dropOffCity).subscribe(res => {
          this.selectedDropOffPoint.displayText = res;
        })
      }
    }
  }

  /**
   * focus in
   */
  handleInputFocusIn($event, type) {
    if (this.pickupLocationPropId === type && !this.transferCriteria.pickupCityName) {
      this.showPickUpRecommendationList = true;
      this.showDropOffRecommendationList = false;
    } else if (this.dropoffLocationPropId === type && !this.transferCriteria.dropOffCityName) {
      this.showPickUpRecommendationList = false;
      this.showDropOffRecommendationList = true;
    }
  }

  passengerSplitChange(event) {
    if (event && event.length === this.paxSplitArray.length) {
      this.isPaxSplitChanged.emit(false);
    } else {
      this.isPaxSplitChanged.emit(true);
    }
  }

  /**
   * enable passenger split dropdown
   */
  enablePaxSplitDropdown() {
    this.enablePaxSplit = true;
  }
}
