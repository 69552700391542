import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, Renderer2,
  SimpleChanges
} from '@angular/core';
import {FlightCriteriaComponent, FlightSearchLeg} from '../flight-criteria/flight-criteria.component';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {
  ConfigService,
  DataShareService,
  FLIGHT_SEARCH_SEGMENT_SPLITTERS,
  FlightComponentCriteriaAirline,
  FlightComponentCriteriaViaPoint, KeyCodes,
  OntologyResponse, SurfCalendarStartDateService,
  SurfCriteriaUtil,
  SurfScrollDirective, SurfTravellerEarliestDepartureDateService,
  TravellerType
} from '@surf/surf-components-core';
import {PaxAllocationItem} from '@surf/surf-pax-selection';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';
import {GRAB_PNR_TYPE, PNR_STAGES, PnrComponentOutput} from '@surf/surf-grab-pnr';
import {MultiFieldInputPropertyFactory} from '@surf/surf-multi-field-input';
import { FlightGroupDetail, AirlineChangeItem, ViaPointChangeItem } from './flight-criteria-dp.model';
import {TbxKeyControlParameter} from "@tc-core/model/it/codegen/tbx/api/tbx-key-control-parameter";
import moment from 'moment';
import {MasterDataStoreService} from '@surf/surf-state-manage';

@Component({
  selector: 'surf-flight-criteria-dp',
  templateUrl: './flight-criteria-dp.component.html'
})
export class FlightCriteriaDpComponent extends FlightCriteriaComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectComp = false;
  @Input() windowScrollEnable = true;
  @Input() setRoomWise = false;
  @Input() isReturnFlight = false;
  @Input() isRefine = false;
  @Input() pnrPassengerCount: number;
  @Input() selectedPnrNumber: string;
  @Input() addedFromExistingPNR: boolean;
  @Input() flightGroupDetail: FlightGroupDetail;
  @Input() dpGrab = false;
  @Input() pccNumber: any;
  @Input() isForEpToDpConversion = false;
  // @Input() keyControls: TbxKeyControlParameter;
  @Input() addToExistingPNR: any;
  @Input() triggerExternalSearch = '';
  @Input() airportsList = [];
  @Input() selectedGDS = '';
  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter<any>();
  @Output() pnrComOutputFromFltCriteria: EventEmitter<PnrComponentOutput> = new EventEmitter<PnrComponentOutput>();
  @Output() fltGrabPnrResFromFltCriteria: EventEmitter<any> = new EventEmitter<any>();
  @Output() showManualItemConfigOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  calendarMinDateForInitialEpeToDpConversionCriteria: Date = moment().toDate();
  currentPaxOption = 0;
  guestComponentFocus = false;
  SEARCH_SEGMENT_SEGMENT_SPLITTER = FLIGHT_SEARCH_SEGMENT_SPLITTERS.SEGMENT_SPLITTER;
  SEARCH_SEGMENT_LIST_SPLITTER = FLIGHT_SEARCH_SEGMENT_SPLITTERS.LIST_SPLITTER;
  MAX_ALLOWED_VIA_POINTS = 2;
  cabinClassReset = false;
  dateReset = false;
  departureDate: string;
  firstTime = false;
  disableAllInputs = false; // when grab PNR disable all user inputs
  pnrReset = false;
  pnrType: GRAB_PNR_TYPE;
  showManualItemConfig : boolean = false;
  flightGrabPnrResult : any;// model not avalable
  pnrComOutput :PnrComponentOutput;

  constructor(protected dataServiceHandler: DataServiceHandler,
              protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              private renderer: Renderer2,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService,
              protected masterDataStoreService: MasterDataStoreService) {
    super(dataServiceHandler, commonService, dataStore, configLoader, configService, surfCriteriaUtil,
      travellerEarliestDepartureDateService, surfCalendarStartDateService, masterDataStoreService);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.isRefine) {
      this.pnrType = GRAB_PNR_TYPE.REFINE;
      if (this.isDPSearch) {
        this.disableAllInputs = this.isPnr && this.dpGrab;
      } else {
        this.disableAllInputs = this.isPnr;
      }
      console.log('fromFlightCriteria: ', this.isPnr);
    } else {
      this.pnrType = GRAB_PNR_TYPE.CRITERIA;
    }

    this.commonService.getCurrentMsg().subscribe(msg => {
      if (msg == 'triggerReset') {
        this.resetInputs();
      }
    });
    this.selectDepartureComponent(this.flightItemList[0]);
    this.firstTime = true;
    this.commonService.changeMessage('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);


    if (changes.cartPaxSelection && this.cartPaxSelection?.length) {
      const adults = this.cartPaxSelection.reduce( (acc, pax ) => acc + pax.adult , 0 );
      const child = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.child.length , 0);
      const infant = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.infant.length , 0 );
      const teen = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.teen.length , 0);
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getPassengerSelection(this.cartPaxSelection);
    }
    // if (changes['dpEnableDate']) {
    //   this.dpEnableDate = changes['dpEnableDate'].currentValue;
    //   if(this.dpEnableDate != undefined){
    //     this.dpCalEnableDate = new Date(this.dpEnableDate);
    //   }
    //   this.validateInputsForDP();
    // }
    //
    // if (changes.dpInitialDestStr) {
    //   this.dpInitialDestStr = changes.dpInitialDestStr.currentValue;
    //   this.adjustLocations();
    //   this.validateInputsForDP();
    // }

    if (changes.dpGrab) {
      this.dpGrab = changes.dpGrab.currentValue;
      this.disableAllInputs = this.dpGrab;
    }

    if(changes.airportsList) {
      this.airportCodesList = this.airportsList;
    }

    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === 'FLT') {
      this.searchFlight(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }
  }

  public selectDepartureComponent(item) {
    item.isSelected = true;
  }

  public deselectDepartureComponent(item) {
    if (!this.firstTime) {
    item.isSelected = false;
    }
    if (this.isDPSearch) {
      item.isSelected = false;
    }
    this.firstTime = false;
  }

  onKeyDown($event, component) {
    // ToDO needs to be implemented

  }

  /**
   * returns the query string created from the flightCriteria object
   */
  getFlightSearchUrlParams() {
    /*Consider check-in date when calculating DOB*/
    if (this.flightCriteria.adult) {
      // this.flightCriteria.adult = this.surfCriteriaUtil.replaceDOB(this.flightCriteria.adult, this.flightCriteria.legs[this.flightCriteria.legs.length -1].departureDate);
      this.flightCriteria.adult = this.flightCriteria.adult.replace(/DOB/g, ''); // removing DOB from adult
    }

    let childParams = '';
    if (this.flightCriteria.teen && this.flightCriteria.teen.length > 0) {
      // this.flightCriteria.child = this.surfCriteriaUtil.replaceDOB(this.flightCriteria.child, this.flightCriteria.legs[this.flightCriteria.legs.length -1].departureDate);
      this.flightCriteria.teen = this.flightCriteria.teen.replace(/DOB/g, ''); // removing DOB from child
      childParams = '&child=' + this.flightCriteria.teen;
    }
    if (this.flightCriteria.child && this.flightCriteria.child.length > 0) {
      if (childParams.toString().includes('&child')) {
        this.flightCriteria.child = this.flightCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = childParams + this.AGE_SEPERATOR + this.flightCriteria.child;
      } else {
        this.flightCriteria.child = this.flightCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = '&child=' + this.flightCriteria.child;
      }
    }
    if (this.flightCriteria.infant && this.flightCriteria.infant.length > 0) {
      // this.flightCriteria.infant = this.surfCriteriaUtil.replaceDOB(this.flightCriteria.infant,  this.flightCriteria.legs[this.flightCriteria.legs.length -1].departureDate,'', TravellerType.INFANT );
      this.flightCriteria.infant = this.flightCriteria.infant.replace(/DOB/g, ''); // removing DOB from infant
      childParams += '&infant=' + this.flightCriteria.infant;
    }

    // get hotel related URL params
    let queryStr = '&adult=' + this.flightCriteria.adult + childParams;
    queryStr += '&searchSegments=' + this.generateSearchSegmentString();
    if ( this.addToExistingPNR && this.addedFromExistingPNR ) {
      queryStr += ('&pseudoCity=' + this.pccNumber);
      queryStr += ('&pnrInfo=' + this.addToExistingPNR);
      queryStr += '~~AMD~' + this.pccNumber +
      '~~~&journeyType=Add Flight To PNR';
    }
    queryStr += '&expand=all';
    return queryStr;
  }

  /**
   * @see flightPnrCriteria
   * returns the query string created from the flightPnrCriteria object
   */
  getFlightSearchUrlParamsForPnr(criteria: any): string {
    let params = '';
    let hasPaxCount = false;
    if (criteria === null || criteria === undefined) {
      return params;
    }
    for (const prop in criteria) {
      if (Object.prototype.hasOwnProperty.call(criteria, prop)) {
        if (criteria[prop] && criteria[prop] !== '') {
          params += '&' + prop + '=' + criteria[prop];
          if(prop === 'pnrPaxCount'){
            hasPaxCount =  true;
          }
        }
      }
    }
    if (params && params[0] === '&') {
      params = params.slice(1);
    }
    if(!hasPaxCount){
      params += '&pnrPaxCount=' + this.pnrPassengerCount;
    }
    return params;
  }

  /**
   * Creates the hotel criteria relevant to the pax selection
   * @param $event
   */
  getPassengerSelection($event: Array<PaxAllocationItem>) {
    if ($event != null  && this.validateChildAges($event) && this.validateTeenAges($event)) {
      this.flightCriteria.adult = this.processAdultPaxAllocation($event);

      const children = this.processChildAllocation($event);
      const teens = this.processTeenAllocation($event);
      const infant = this.processInfantAllocation($event);
      if (children.CHD && children.CHD.length > 0) {
        this.flightCriteria.child = children.CHD;
      } else {
        this.flightCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.flightCriteria.teen = teens;
      } else {
        this.flightCriteria.teen = null;
      }
      this.flightCriteria.infant = infant && infant.length > 0 ? infant : null;

      // this.commonService.updateBaseCriteria(this.hotelCriteria);
    } else {
      this.flightCriteria.adult = null;
      this.flightCriteria.child = null;
      this.flightCriteria.teen = null;
      this.flightCriteria.infant = null;
      // this.commonService.updateBaseCriteria(this.hotelCriteria);
    }
    this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
  }

  /**
   * check for invalid child ages
   * @param selectedPax
   */
  validateChildAges(selectedPax: Array<PaxAllocationItem>) {
    return selectedPax.reduce((acc, pax) => acc && (pax.child?.indexOf(-1) <= -1), true);
  }

  validateTeenAges(selectedPax : Array<PaxAllocationItem> ){
    return selectedPax.reduce((acc, pax) => acc && ( pax.teen?.indexOf(-1) > -1 ? false : true ), true);
  }

  /**
   * creates the adult string for the hotel criteria
   * @param paxArray
   */
  processAdultPaxAllocation(paxArray: Array<PaxAllocationItem>) {

    const defaultAdultAge = this.DEFAULT_ADULT_AGE;
    const fieldSeperator = '~';
    const roomSeperator = '__';

    let paxStr = '';

    for (let i = 0; i < paxArray.length; i++) {
      const paxItem = paxArray[i];
      if (i != 0) {
        paxStr = paxStr + roomSeperator;
      }
      // paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + this.convertDateObjToStr(this.getDOB(defaultAdultAge)) + fieldSeperator +  (i + 1);
      paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + 'DOB' + fieldSeperator + (i + 1);
    }

    return paxStr;
  }

  processChildAllocation(paxArray: Array<PaxAllocationItem>) {

    const children = {
      INF: '',
      CHD: '',
    };

    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.child.length > 0) {

        const childAgeMap = {};
        const childOrder = [];
        paxRoom.child.forEach(childAge => {
          if (childOrder.findIndex(age => age === childAge) < 0) {
            childOrder.push(childAge);
          }
        });
        paxRoom.child.forEach(childAge => {
          childAgeMap[childAge] ? childAgeMap[childAge]++ : childAgeMap[childAge] = 1;
        });

        for (const age of childOrder) {
          const key = age + '';
          const count = childAgeMap[key] + '';
          if (key.trim() == this.LESS_THAN_ONE) {
            /*Infants*/
            // children.INF += count + fieldSeperator + 0 + fieldSeperator + this.convertDateObjToStr(this.getDOB(0)) + fieldSeperator + roomNo + ageSeperator;
            children.INF += count + this.FIELD_SEPERATOR + 0 + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          } else {
            /*child*/
            // children.CHD += count + fieldSeperator + key + fieldSeperator + this.convertDateObjToStr(this.getDOB(key)) + fieldSeperator + roomNo + ageSeperator;
            children.CHD += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + roomNo + this.AGE_SEPERATOR;
          }
        }
      }
      roomNo++;
    });

    if (children.INF.length > 2) {
      children.INF = children.INF.slice(0, children.INF.length - 2);
    }
    if (children.CHD.length > 2) {
      children.CHD = children.CHD.slice(0, children.CHD.length - 2);
    }

    return children;
  }
  processTeenAllocation(paxArray: Array<PaxAllocationItem>) {

    let teens = '';
    // teens
    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.teen.length > 0) {

        let teenAgeMap = {};
        const teenOrder = [];
        paxRoom.teen.forEach(teenAge => {
          if (teenOrder.findIndex(age => age === teenAge) < 0) {
            teenOrder.push(teenAge);
          }
        });
        paxRoom.teen.forEach(teenAge => {
          teenAgeMap[teenAge] ? teenAgeMap[teenAge]++ : teenAgeMap[teenAge] = 1;
        });

        for (let age of teenOrder) {
          const key = age + '';
          let count = teenAgeMap[key] + '';
          /* TODO update this logic based on guest age setup */
          /*teens*/
          teens += count + this.FIELD_SEPERATOR + key + this.FIELD_SEPERATOR + 'DOB';

            teens += this.AGE_SEPERATOR;
        }
      }
      roomNo++;
    });

    if (teens.length > 2) {
        teens = teens.slice(0, teens.length - 2);
    }
    return teens;
  }
  processInfantAllocation(paxArray: Array<PaxAllocationItem>) {

    let inf = '';

    paxArray.forEach(paxRoom => {
      if (paxRoom.infant.length > 0) {

        let infantInSeat = '';
        let infantInLap = '';
        const infantAgeMap = {
          seat: {},
          lap: {}
        };
        paxRoom.infant.forEach((childAge, index) => {
          if (paxRoom.infantSeatAllocation[index]) {
            infantAgeMap.seat[childAge] ? infantAgeMap.seat[childAge]++ : infantAgeMap.seat[childAge] = 1;
          } else {
            infantAgeMap.lap[childAge] ? infantAgeMap.lap[childAge]++ : infantAgeMap.lap[childAge] = 1;
          }
        });

        for (const key in infantAgeMap.seat) {
          const count = infantAgeMap.seat[key] + '';
          infantInSeat += count + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + 'true' +
            this.AGE_SEPERATOR;
        }

        for (const key in infantAgeMap.lap) {
          const count = infantAgeMap.lap[key] + '';
          infantInLap += count + this.FIELD_SEPERATOR + this.FIELD_SEPERATOR + 'DOB' + this.FIELD_SEPERATOR + 'false' +
            this.AGE_SEPERATOR;
        }

        if (infantInSeat.length > 0 && infantInLap.length > 0) {
          inf = infantInSeat + infantInLap;
        } else if (infantInSeat.length === 0) {
          inf = infantInLap;
        } else if (infantInLap.length === 0) {
          inf = infantInSeat;
        }
      }
    });

    if (inf.length > 2) {
      inf = inf.slice(0, inf.length - 2);
    }
    return inf;
  }


  generateSearchSegmentString(): string {
    const stringArr: string[] = [];
    this.flightItemList.forEach(x => {
      const searchSegment = x.departureDate + this.SEARCH_SEGMENT_SEGMENT_SPLITTER + this.SEARCH_SEGMENT_SEGMENT_SPLITTER + x.departureAirportCode + this.SEARCH_SEGMENT_SEGMENT_SPLITTER +
        x.arrivalAirportCode + this.SEARCH_SEGMENT_SEGMENT_SPLITTER + this.SEARCH_SEGMENT_SEGMENT_SPLITTER + this.generateAirlineString(x.airlines) + this.SEARCH_SEGMENT_SEGMENT_SPLITTER + (x.cabinClass ? x.cabinClass : '') +
        this.SEARCH_SEGMENT_SEGMENT_SPLITTER + this.generateViaPointString(x.viaPoints) + this.SEARCH_SEGMENT_SEGMENT_SPLITTER;
      stringArr.push(searchSegment);
    });
    return stringArr.join(this.SEARCH_SEGMENTS_SEPERATOR);
  }

  generateAirlineString(airlines: FlightComponentCriteriaAirline[]) {
    const strBuffer: string[] = [];
    for (const airline of airlines) {
      strBuffer.push(airline.airlineCode);
    }
    return strBuffer.join(this.SEARCH_SEGMENT_LIST_SPLITTER);
  }

  generateViaPointString(viaPoints: FlightComponentCriteriaViaPoint[]) {
    const strBuffer: string[] = [];
    for (const viaPoint of viaPoints) {
      strBuffer.push(viaPoint.viaPointCode);
    }
    return strBuffer.join(this.SEARCH_SEGMENT_LIST_SPLITTER);
  }

  getCurrentOption($event) {
    this.currentPaxOption = $event;
  }

  scrollTo(event, parentSelector?: string, eventIsId?: boolean) {
    if (!this.isDPSearch) {
      if (this.windowScrollEnable) {
        if (eventIsId) {
          SurfScrollDirective.scrollTo(
            event,
            null,
            false,
            false,
            -100,
            30
          );
        } else if (event.currentTarget && event.currentTarget.id) {
          SurfScrollDirective.scrollTo(
            '#' + event.currentTarget.id,
            null,
            false,
            false,
            -100,
            30
          );
        }
      } else if (parentSelector) {
        if (eventIsId) {
          SurfScrollDirective.scrollTo(
            event,
            parentSelector,
            false,
            false,
            100,
            30
          );
        } else if (event.currentTarget && event.currentTarget.id) {
          SurfScrollDirective.scrollTo(
            '#' + event.currentTarget.id,
            parentSelector,
            false,
            false,
            100,
            30
          );
        }
      }
    }
  }

  searchFlight(isExternal?) {
    const basePath = '/'; // this.isRefine ? window.location.origin + '/' : window.location.href;
    let redirectURL;
    if (this.isPnr) {
      redirectURL = basePath + 'b2b-flights/?' + this.getFlightSearchUrlParamsForPnr(this.flightPnrCriteria);
      if (this.isServiceAddComponent) {
        this.travellerEarliestDepartureDateService.addNewDepartureDate(this.flightCriteria.legs[0].departureDate);
        this.travellerEarliestDepartureDateService.checkAndUpdateEarliestDepartureDate();
        this.getUrlCriteria.emit(redirectURL);
      } else {
        window.location.href = redirectURL;
      }
    } else if (this.validateInputs()) {

      if (this.isServiceAddComponent) {
        this.travellerEarliestDepartureDateService.addNewDepartureDate(this.flightCriteria.legs[0].departureDate);
        this.travellerEarliestDepartureDateService.checkAndUpdateEarliestDepartureDate();
        if (this.isRefine) {
          redirectURL = basePath + 'b2b-flights/?' + this.keyParams + this.getFlightSearchUrlParams();
        } else {
          // hit
          redirectURL = basePath + 'b2b-flights/?' + this.getFlightSearchUrlParams();
        }

        this.getUrlCriteria.emit({url: redirectURL, isExternal});
      } else {
        redirectURL = basePath + 'b2b-flights/?' + this.keyParams + this.getFlightSearchUrlParams();
        window.location.href = redirectURL;
      }
    }
  }

  validateInputs() {
    // if (this.isServiceAddComponent) {
    //   const pax = this.selectedPax[0];
    //   let adultText  = '~30~DOB';
    //   let childText  = '~6~DOB';
    //   let infantText =  '~~';
    //   adultText = pax.adult + adultText;
    //   this.flightCriteria.adult = adultText;
    //   if (pax.child.length > 0) {
    //     childText = pax.child.length + childText ;
    //     this.flightCriteria.child = childText;
    //   }
    //   if (pax.infant.length > 0) {
    //     infantText = pax.infant.length + infantText ;
    //     this.flightCriteria.infant = infantText;
    //   }
    // }
    const isLastMinuteBookingValidationPass = this.surfCalendarStartDateService.lastMinuteBookingErrorSet.size <= 0;
    if (!this.isPnr && !this.flightCriteria.adult) {
      return false;
    }
    let validForPnr = true;
    for (const flightItem of this.flightItemList) {
      if (this.isPnr) {
        this.setFlightErrors(flightItem);
        if (flightItem.typeAheadDepartureHasError || flightItem.typeAheadArrivalHasError || !flightItem.departureDate) {
          validForPnr = false;
        }

      }
      else if (!(flightItem.departureAirportCode && flightItem.arrivalAirportCode && flightItem.departureDate)) {
        return false;
      }
    }
    if (this.isPnr) {
      if (this.isAmendmentFlow) {
        return isLastMinuteBookingValidationPass && validForPnr;
      } else {
        return validForPnr;
      }
    }
    if (this.isAmendmentFlow) {
      return isLastMinuteBookingValidationPass;
    } else {
      return true;
    }
  }


  /**
   * Upon clicking the reset button, all the input fields and corresponding
   * values in the criteria object will be reset
   */
  resetInputs(isRefine?: boolean) {
    if (this.isPnr && !isRefine) {
      this.resetPnrComponent();
    } else if (!isRefine) {
      this.flightCriteria.legs = [];
      this.flightCriteria.adult = '';
      this.flightCriteria.child = '';
      this.flightCriteria.teen = '';
      const flightItemListTemp = [];
      let legCount;
      this.isDPSearch ? legCount = 1 : legCount = 2;
      for (let i = 0; i < legCount; i++) {
        const flightItem = this.createEmptyFlightItemObject();
        flightItem.index = i;
        flightItem.productCode = 'FLT';
        flightItemListTemp.push(flightItem);
      }
      this.flightItemList = flightItemListTemp;
      this.flightCriteria.legs = [...this.flightItemList];
      this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
      this.resetDisplayItems();
    } else {
      this.cancelRefineSearch();
    }
    if (this.isServiceAddComponent) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }

  }

  resetDisplayItems() {
    this.defaultPaxSet = [];
    this.cabinClassList.forEach(cl => {
      cl.selected = false;
    });
    // this.dateReset = true;
    this.dateReset = true;
    setTimeout(() => {
      this.dateReset = false;
    }, 100);
    this.cabinClassReset = !this.cabinClassReset;
  }

  getSelectedDate($event, flightType: string, selectedFlightItem) {
    if ($event && $event[0]) {
      if (flightType == 'departure' && !this.isDPSearch) { // in dp flow date will be updated by the dp criteria
        const selectedDate = ($event[0] as Date);
        selectedFlightItem.departureDate = this.surfCriteriaUtil.convertDateObjToStr($event[0], true);

        if (selectedFlightItem.index < this.flightItemList.length - 1) {
          for (let index = selectedFlightItem.index + 1; index < this.flightItemList.length; index++) {
            const nextItemDate = new Date(this.flightItemList[index].departureDate);
            if (nextItemDate && selectedDate.getTime() > nextItemDate.getTime() ) {
              this.flightItemList[index].departureDate = null;
              this.flightItemList[index].departureDateObj = [];
              this.flightItemList[index].dateReset = true;
              setTimeout(() => {
                this.flightItemList[index].dateReset = false;
              }, 300);
            } else if ( nextItemDate && selectedDate.getTime() <= nextItemDate.getTime()) {
              break;
            }
          }
        }
      }
    } else {
      selectedFlightItem.departureDate = null;
    }

    this.flightCriteria.legs = [...this.flightItemList];
    if (this.flightCriteria.productCode == undefined) {
      this.flightCriteria.productCode = 'FLT';
    }
    this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
    if (this.isDPSearch) {
      let amendTypeString = '';
      if (this.isAmendmentFlow) {
        amendTypeString = 'START_DATE';
      }

      /*NEW IMPL - notify the change
      * change value : for for DATE ->  DEPDATE*/
      const changeObj = {
        index : this.index,
        productCode : 'FLT',
        changeType : 'DATE',
        amendmentChangeType : amendTypeString,
        value : this.surfCriteriaUtil.convertDateObjToStr($event[0], true)
      };
      this.commonService.updateChangeItem(changeObj);
      this.validateInputsForDP();
    }
    if (this.flightCriteria && this.flightCriteria.legs && this.flightCriteria.legs.length > 0 &&
      this.flightCriteria.legs[0].departureDate) {
      this.travellerEarliestDepartureDateService
        .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.flightCriteria.legs[0].departureDate);
    }
    this.getCurrentFlightCriteria.emit(this.flightCriteria);
    this.doCheckLastMinuteBookingDate(this.isAmendmentFlow);
  }

  getDepartureTypeAheadData(event, selectedFlightItem) {
    if (event.property.id == 'DEPARTURE_FROM') {
      selectedFlightItem.typeAheadDepartureHasError = false;
    } else if (event.property.id == 'DEPARTURE_TO') {
      selectedFlightItem.typeAheadArrivalHasError = false;
    }
    if (event && event.filteredList && event.filteredList.length > 0) {
      this.departureReturnedProperty = event.property;
      this.filteredDepartureList = event.filteredList;
      console.log('testing');
      console.log(this.filteredDepartureList);
      selectedFlightItem.typeAheadHasError = false;
      selectedFlightItem.showTypeAheadContent = true;
    } else {
      if (event.property && event.property.selectedItem && event.property.selectedItem.displayText.length < 3) {
        selectedFlightItem.typeAheadHasError = false;
        if (event.property.id == 'DEPARTURE_FROM') {
          selectedFlightItem.departureAirportCode = null;
        } else if (event.property.id == 'DEPARTURE_TO') {
          selectedFlightItem.arrivalAirportCode = null;
        }
      } else {
        if (event.property.id == 'DEPARTURE_FROM') {
          selectedFlightItem.departureAirportCode = null;
          selectedFlightItem.typeAheadDepartureHasError = true;
        } else if (event.property.id == 'DEPARTURE_TO') {
          selectedFlightItem.arrivalAirportCode = null;
          selectedFlightItem.typeAheadArrivalHasError = true;
        }
      }
      selectedFlightItem.showTypeAheadContent = false;

    }
    if (selectedFlightItem.typeAheadDepartureHasError && selectedFlightItem.typeAheadArrivalHasError) {
      selectedFlightItem.typeAheadHasError = true;
      selectedFlightItem.typeAheadErrorMsg = 'Please enter valid departure and arrival airports ';
    } else if (selectedFlightItem.typeAheadDepartureHasError) {
      selectedFlightItem.typeAheadHasError = true;
      selectedFlightItem.typeAheadErrorMsg = 'Please enter valid departure airport';
    } else if (selectedFlightItem.typeAheadArrivalHasError) {
      selectedFlightItem.typeAheadHasError = true;
      selectedFlightItem.typeAheadErrorMsg = 'Please enter valid arrival airport';
    }
    this.flightCriteria.legs = [...this.flightItemList];
    if (this.flightCriteria.productCode == undefined) {
      this.flightCriteria.productCode = 'FLT';
    }
    this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
    if (this.isDPSearch) {
      // this.updateDPCriteria('typeahead-data');
    }
    this.getCurrentFlightCriteria.emit(this.flightCriteria);

  }

  deselectDepartureMultiFieldInput(selectedFlightItem) {
    selectedFlightItem.showTypeAheadContent = false;

  }

  handleInputFocusIn(event, selectedFlightItem) {
    if (event == 'DEPARTURE_FROM' && selectedFlightItem.departureAirportCode) {
      if (this.isDPSearch) {
        selectedFlightItem.fromOntology.displayText = selectedFlightItem.departureAirportCityName;
      } else {
        selectedFlightItem.fromOntology.displayText = selectedFlightItem.departureAirportCity;
      }
    } else if (event == 'DEPARTURE_TO' && selectedFlightItem.arrivalAirportCode) {
      if (this.isDPSearch) {
        selectedFlightItem.toOntology.displayText = selectedFlightItem.arrivalAirportCityName;
      } else {
        selectedFlightItem.toOntology.displayText = selectedFlightItem.arrivalAirportCity;
      }
    }
  }

  handleInputFocusOut(event, selectedFlightItem) {
    console.log(event);
    if (event == 'DEPARTURE_FROM' && selectedFlightItem.departureAirportCity && selectedFlightItem.departureAirportCode) {
      if (this.isDPSearch) {
        selectedFlightItem.fromOntology.displayText = selectedFlightItem.departureAirportCityName + ' (' + selectedFlightItem.departureAirportCode + ')';
      } else {
        selectedFlightItem.fromOntology.displayText = selectedFlightItem.departureAirportCity + ' (' + selectedFlightItem.departureAirportCode + ')';
      }
    } else if (event == 'DEPARTURE_TO' && selectedFlightItem.arrivalAirportCity && selectedFlightItem.arrivalAirportCode) {
      if (this.isDPSearch) {
        selectedFlightItem.toOntology.displayText = selectedFlightItem.arrivalAirportCityName + ' (' + selectedFlightItem.arrivalAirportCode + ')';
      } else {
        selectedFlightItem.toOntology.displayText = selectedFlightItem.arrivalAirportCity + ' (' + selectedFlightItem.arrivalAirportCode + ')';
      }
    }
    if(this.isDPSearch && selectedFlightItem.toOntology){
      this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
      let depCity;
      let depCountry;
      let arrCity;
      let arrCountry;

      if (this.flightItemList && this.flightItemList[0].depObj) {
        if (this.flightItemList[0].depObj.type == 40) {
          depCity = this.flightItemList[0].depObj;
        } else {
          depCity = this.surfCriteriaUtil.getCity(this.flightItemList[0].depObj);
        }
        depCountry = this.surfCriteriaUtil.getCountry(this.flightItemList[0].depObj);
      }
      arrCountry = '';

      let value = (this.flightItemList[0].departureAirportCode ? this.flightItemList[0].departureAirportCode : '') + '~'
        + (depCity ? (depCity.code + '~' + depCity.name + '~') : '~~') + (depCountry ? depCountry : '') + '~'
        + '|'
        + (this.flightItemList[0].arrivalAirportCode ? this.flightItemList[0].arrivalAirportCode : '') + '~'
        + (this.flightItemList[0].arrivalAirportCity ? this.flightItemList[0].arrivalAirportCity : '') + '~'
        + (this.flightItemList[0].arrivalAirportCityName ? this.flightItemList[0].arrivalAirportCityName : '') + '~'
        + (arrCountry ? arrCountry : '');

      let changeObj = {
        index : this.index,
        productCode : 'FLT',
        changeType : 'DEST',
        value : value
      };
      this.commonService.updateChangeItem(changeObj);
    }
    if (this.isDPSearch) {
      this.validateInputsForDP();
    }
  }

  onFlightLegCloseClicked(index: number) {
    if (this.dpGrab) { return; }
    if (this.isDPSearch && this.flightItemList.length == 1) {   /*Emit remove item notifier when in DP*/
      this.itemRemoved.emit(this.index);
    }

    if (this.flightItemList.length > 1) {
      this.flightItemList.splice(index, 1);
      this.flightItemList.forEach(item => {
        if (item.index > index) {
          item.index--;
        }
      });
      this.flightItemList = [...this.flightItemList];
      this.flightCriteria.legs = [...this.flightItemList];
      this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
    }
  }

  getPrevLegDate(index) {
    if (this.isDPSearch) {
      return this.dpCalEnableDate;
    } else {
      if (index > 0) {
        for (let i = (index - 1); i >= 0; i--) {
          if (this.flightItemList[i].departureDate) {
            return new Date(this.flightItemList[i].departureDate);
            break;
          } else {
            if (i === 0) {
              return null;
              break;
            }
          }
        }

      } else {
        return null;
      }
    }
  }

  returnAirline(event, selectedFlightItem) {
    if (event && event.length > 0) {
      this.filteredAirlineList = event;
      selectedFlightItem.showAirlineTypeAheadContent = true;
    } else {
      this.filteredAirlineList = [];
      selectedFlightItem.showAirlineTypeAheadContent = false;
    }
  }

  returnViaPoint(event, selectedFlightItem) {
    if (event && event.length > 0) {
      this.filteredViaPointList = event;
      selectedFlightItem.showViaPointTypeAheadContent = true;
    } else {
      this.filteredViaPointList = [];
      selectedFlightItem.showViaPointTypeAheadContent = false;
    }
  }

  selectAirline(selection, selectedFlightItem) {
    if (selectedFlightItem && !selectedFlightItem.airlines) {
      selectedFlightItem.airlines = [];
    }
    if (selectedFlightItem.airlines.findIndex(x => x.airlineCode === selection.code) < 0) {
      this.filteredAirlineList = [];
      selectedFlightItem.isAirlineSearchInitiated = false;
      selectedFlightItem.airlineOntology.displayText = '';
      const airlineTemp = new FlightComponentCriteriaAirline();
      airlineTemp.airlineCode = selection.code;
      airlineTemp.airlineName = selection.value;

      //select only one airline in kuoni
      if(selectedFlightItem.airlines.length<1){
        selectedFlightItem.airlines.push(airlineTemp);
        selectedFlightItem.airlineOntologies.push(this.createAirlineOntology(airlineTemp, selectedFlightItem.airlineOntologies.length));
      }else{
        selectedFlightItem.airlines[0]=airlineTemp
        selectedFlightItem.airlineOntologies[0]= this.createAirlineOntology(airlineTemp, selectedFlightItem.airlineOntologies.length);
      }

      this.flightCriteria.legs = [...this.flightItemList];
      if (this.flightCriteria.productCode == undefined) {
        this.flightCriteria.productCode = 'FLT';
      }
      this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
      if (this.isDPSearch) {
        // NEW IMPL
        // change value : for AIRLINE ->  @see AirlineChangeItem
        const value = new AirlineChangeItem(
          selectedFlightItem.isAirlineSearchInitiated,
          selectedFlightItem.airlines,
          selectedFlightItem.airlineOntologies,
          selectedFlightItem.airlineOntology.displayText
        );
        const changeObj = {
          index: this.index,
          productCode: 'FLT',
          changeType: 'AIRLINE',
          value
        };
        this.commonService.updateChangeItem(changeObj);
      }
      this.getCurrentFlightCriteria.emit(this.flightCriteria);
    } else {
      selectedFlightItem.isAirlineSearchInitiated = false;
      selectedFlightItem.airlineOntology.displayText = '';
    }
  }

  selectViaPoint(selection, selectedFlightItem) {
    if (!selectedFlightItem.viaPoints) {
      selectedFlightItem.viaPoints = [];
    }
    if ((selectedFlightItem.viaPoints.length < this.MAX_ALLOWED_VIA_POINTS) &&
        (selectedFlightItem.viaPoints.findIndex(x => x.viaPointCode === selection.code) < 0)) {
      this.filteredViaPointList = [];
      selectedFlightItem.isViaPointSearchInitiated = false;
      selectedFlightItem.viaPointOntology.displayText = '';
      const viaPointTemp = new FlightComponentCriteriaViaPoint();
      viaPointTemp.viaPointCode = selection.code;
      viaPointTemp.viaPointName = selection.name;
      const viaPointCity = (selection.parent as [any]).find(x => (x.type == 'CITY' || x.type == 40));
      if (viaPointCity) {
        viaPointTemp.viaPointCity = viaPointCity.name;
      } else {
        viaPointTemp.viaPointCity = '';
      }
      selectedFlightItem.viaPoints.push(viaPointTemp);
      selectedFlightItem.viaPointOntologies.push(this.createViaPointOntology(viaPointTemp, selectedFlightItem.viaPointOntologies.length));
      this.flightCriteria.legs = [...this.flightItemList];
      if (this.flightCriteria.productCode == undefined) {
        this.flightCriteria.productCode = 'FLT';
      }
      this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
      this.getCurrentFlightCriteria.emit(this.flightCriteria);
      if (this.isDPSearch) {
        // NEW IMPL
        // change value : for AIRLINE ->  @see ViaPointChangeItem
        const value = new ViaPointChangeItem(
          selectedFlightItem.isViaPointSearchInitiated,
          selectedFlightItem.viaPoints,
          selectedFlightItem.viaPointOntologies,
          selectedFlightItem.viaPointOntology.displayText
        );
        const changeObj = {
          index: this.index,
          productCode: 'FLT',
          changeType: 'VIAPOINT',
          value
        };
        this.commonService.updateChangeItem(changeObj);
      }
    } else {
      selectedFlightItem.isViaPointSearchInitiated = false;
      selectedFlightItem.viaPointOntology.displayText = '';
    }
  }

  removeAirlineChip(event, selectedFlightItem) {
    if (typeof event.code === 'number') {
      selectedFlightItem.airlineOntologies.splice(event.code, 1);
      selectedFlightItem.airlineOntologies.forEach(airline => {
        if (airline.index > event.code) {
          airline.index--;
        }
      });
      selectedFlightItem.airlines.splice(event.code, 1);

      if (this.isDPSearch) {
        // NEW IMPL
        // change value : for AIRLINE ->  @see AirlineChangeItem
        const value = new AirlineChangeItem(
          selectedFlightItem.isAirlineSearchInitiated,
          selectedFlightItem.airlines,
          selectedFlightItem.airlineOntologies,
          selectedFlightItem.airlineOntology.displayText
        );
        const changeObj = {
          index: this.index,
          productCode: 'FLT',
          changeType: 'AIRLINE',
          value
        };
        this.commonService.updateChangeItem(changeObj);
      }
    }
  }

  removeViaPointChip(event, selectedFlightItem) {
    if (typeof event.code === 'number') {
      selectedFlightItem.viaPointOntologies.splice(event.code, 1);
      selectedFlightItem.viaPointOntologies.forEach(viaPoint => {
        if (viaPoint.index > event.code) {
          viaPoint.index--;
        }
      });
      selectedFlightItem.viaPoints.splice(event.code, 1);
      if (this.isDPSearch) {
        // NEW IMPL
        // change value : for AIRLINE ->  @see ViaPointChangeItem
        const value = new ViaPointChangeItem(
          selectedFlightItem.isViaPointSearchInitiated,
          selectedFlightItem.viaPoints,
          selectedFlightItem.viaPointOntologies,
          selectedFlightItem.viaPointOntology.displayText
        );
        const changeObj = {
          index: this.index,
          productCode: 'FLT',
          changeType: 'VIAPOINT',
          value
        };
        this.commonService.updateChangeItem(changeObj);
      }
    }
  }

  onAirlineClickOutside(selectedFlightItem) {
    if (this.filteredAirlineList.length > 0) {
      selectedFlightItem.isAirlineSearchInitiated = false;
    }
    this.filteredAirlineList = [];
    selectedFlightItem.showAirlineTypeAheadContent = false;
  }

  onViaPointClickOutside(selectedFlightItem) {
    if (this.filteredViaPointList.length > 0) {
      selectedFlightItem.isViaPointSearchInitiated = false;
    }
    this.filteredViaPointList = [];
    selectedFlightItem.showViaPointTypeAheadContent = false;
  }

  returnedAirlineSearchQuery(event, selectedFlightItem) {
    selectedFlightItem.showAirlineTypeAheadContent = false;
    if (event.length == 0 && selectedFlightItem.airlineCode) {
      selectedFlightItem.airlineCode = null;
      this.flightCriteria.legs = [...this.flightItemList];
      this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
      if (this.isDPSearch) {
        // NEW IMPL
        // change value : for AIRLINE ->  AIRLINESEARCHINITIATED~DISPLAYTEXT~AIRLINECODE*/
        const value = selectedFlightItem.isAirlineSearchInitiated + '~' + selectedFlightItem.airlineOntology.displayText + '~' + selectedFlightItem.airlineCode;
        const changeObj = {
          index : this.index,
          productCode : 'FLT',
          changeType : 'AIRLINE',
          value
        };
        this.commonService.updateChangeItem(changeObj);
      }
      this.getCurrentFlightCriteria.emit(this.flightCriteria);
    }
    selectedFlightItem.isAirlineSearchInitiated = event && event.length >= this.MIN_SEARCH_QUERY_LENGTH_AIRLINE;
  }

  returnedViaPointSearchQuery(event, selectedFlightItem) {
    selectedFlightItem.showViaPointTypeAheadContent = false;
    selectedFlightItem.isViaPointSearchInitiated = event && event.length >= this.MIN_SEARCH_QUERY_LENGTH_VIA_POINTS;
  }

  moveToDP(position: string) {
    if (!this.isRefine) {                         /*TODO: Implemented for refine*/
      if (!this.isDPSearch) {                     /*Move to DP search panel if only; was in the component flow previously*/
        // if (this.flightItemList && this.flightItemList.length == this.MAX_FLIGHT_LEGS) {
        this.msg = 'DP_Switch~FLT~' + position;
          // this.commonService.changeMessage(msg);
        // }
      }
    }
  }

  updateDPCriteria(event) {
    super.updateDPCriteria(event);
  }

  handleCabinClassDropDownClick(event: MouseEvent) {
    event.stopPropagation();
  }

  setGrabPNRCriteria(event: PnrComponentOutput): void {
    this.pnrComOutput = event;
    this.pnrComOutputFromFltCriteria.emit(this.pnrComOutput)
    this.showManualItemConfigOut.emit(this.showManualItemConfig);
    if (this.showManualItemConfig) { return; }
    if (!this.enableGrabPnr) { return; }
    if ( this.airportCodesList == undefined || (this.airportCodesList && this.airportCodesList.length == 0) ) {
      this.getAirports();
    }
    if (event.pnrStage === PNR_STAGES.GRAB_COMPLETE) {
      this.isPnr = true;
      this.disableAllInputs = true;
      this.flightCriteria = event.flightComponentCriteria;
      console.log(this.dataStore.get(DataKey.keyControls));
      this.flightPnrCriteria = event.flightGETCriteria;
      this.flightItemList = null;
      this.departureSelectedDeparture.displayText = '';
      this.departureSelectedArrival.displayText = '';
      this.pnrPassengerCount = event.passengerCount;
      this.createInitialFlightItemList(true);
    } else if (event.pnrStage === PNR_STAGES.DEFAULT) {
      this.isPnr = false;
      this.disableAllInputs = false;
      this.flightCriteria = this.flightCriteriaDefault;
      this.flightPnrCriteria = null;
      this.flightItemList = null;
      this.departureSelectedDeparture.displayText = '';
      this.departureSelectedArrival.displayText = '';
      this.pnrPassengerCount = 0;
      this.createDefaultFlightItemList();
    }
  }

  resetPnrComponent() {
    const pnrMockOutput = new PnrComponentOutput();
    pnrMockOutput.pnrStage = PNR_STAGES.DEFAULT;
    this.setGrabPNRCriteria(pnrMockOutput);
    this.pnrReset = true;
    setTimeout( () => {
      this.pnrReset = false;
    }, 10);
  }

  createDefaultFlightItemList() {
    this.flightItemList = [];
    if (this.isDPSearch) {
      this.flightItemList.push(new FlightSearchLeg());
    } else {
      for (let i = 0; i < 2; i++) {
        this.flightItemList.push(new FlightSearchLeg());
      }
    }
    this.flightItemList.forEach((x, index) => {
      x.showTypeAheadContent = false;
      x.showAirlineTypeAheadContent = false;
      x.showViaPointTypeAheadContent = false;
      x.typeAheadHasError = false;
      x.typeAheadDepartureHasError = false;
      x.typeAheadArrivalHasError = false;
      x.typeAheadErrorMsg = '';
      x.fromOntology = new OntologyResponse();
      x.toOntology = new OntologyResponse();
      x.airlineOntology = new OntologyResponse();
      x.viaPointOntology = new OntologyResponse();
      x.airlineOntologies = [];
      x.viaPointOntologies = [];
      x.multiFieldInputProperty = [];
      x.fromOntology.displayText = '';
      x.toOntology.displayText = '';
      x.airlineOntology.displayText = '';
      x.viaPointOntology.displayText = '';
      x.isAirlineSearchInitiated = false;
      x.isViaPointSearchInitiated = false;
      x.departureDateObj = [];
      x.cabinClassList = [];
      if (this.cabinClassList && this.cabinClassList.length > 0) {
        x.cabinClassList.push({value: this.anyCabinClassString, selected: false});
        this.cabinClassList.forEach(item => {
          x.cabinClassList.push(Object.assign({}, item));
        });
      }
      x.isSelected = false;
      const depDesUrl = this.STATIC_DATA_URL_BASE +
                        '?dataTypes=AIRPORT&userInput=[UI]&aggrLevel=NA&locale=en&matchType=ALL&expand=all&resultCount=' +
                        this.STATIC_DATA_FETCH_SIZE;

      x.multiFieldInputProperty.push(MultiFieldInputPropertyFactory.getTypeAheadProperties('Search airport', true, false,
        'DEPARTURE_FROM', 'Departure*', 'strict', x.fromOntology, this.MIN_SEARCH_QUERY_LENGTH, true, depDesUrl));

      x.multiFieldInputProperty.push(MultiFieldInputPropertyFactory.getTypeAheadProperties('Search airport', true, false,
        'DEPARTURE_TO', 'Arrival*', 'strict', x.toOntology, this.MIN_SEARCH_QUERY_LENGTH, true, depDesUrl));
      x.index = index;
      x.productCode = 'FLT';
      x.dateReset = false;
    });
  }

  /**
   * handle key down
   * @param event, component, index
   */
  onKeyPress(event, component, index) {
    if (event.keyCode === KeyCodes.ENTER) {
      for (let i = 0; i < this.flightItemList.length; i++) {
        this.flightItemList[i].isFocus = false;
      }
      if (component === 0) {
        if (this.isLastItemInMultiInput === true) {
          this.isLastItemInMultiInput = false;
          for (let i = 0; i < this.flightItemList.length; i++) {
            if (i === index) {
              this.flightItemList[i].isFocus = true;
            } else {
              this.flightItemList[i].isFocus = false;
            }
          }
        }
        // this.focusToDepartureAirport = true;
        // this.focusToDepartureMonth = false;
        // this.focusToDuration = false;
      }
      if (component === 1) {
        // this.focusToDepartureAirport = false;
        // this.focusToDepartureMonth = true;
        // this.focusToDuration = false;
      }
      if (component === 2) {
        // this.focusToDepartureAirport = false;
        // this.focusToDepartureMonth = false;
        // this.focusToDuration = true;
      }
    } else {
      // this.focusToDepartureAirport = false;
      // this.focusToDepartureMonth = false;
    }
  }

  passengerSplitChange(event) {
    if (event && event.length === this.paxSplitArray.length) {
      this.isPaxSplitChanged.emit(false);
    } else {
      this.isPaxSplitChanged.emit(true);
    }
  }

  /**
   * enable passenger split dropdown
   */
  enablePaxSplitDropdown() {
    this.enablePaxSplit = true;
  }

  setFlightGrabPnrResult(event:any){
    this.flightGrabPnrResult = event;
    this.fltGrabPnrResFromFltCriteria.emit(this.flightGrabPnrResult)
  }
  setManualItemConfig(event:boolean){
    this.showManualItemConfig = event;
  }
}
