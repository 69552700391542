import {AbstractModuleConfigParser} from './abstract-module-config-parser';
import {ModuleConfigEnum} from './module-config-enum';
import {ModuleConfigOtherParametersEnum} from './module-config-other-parameters-enum';

export class DpTemplateConfig extends AbstractModuleConfigParser {
  private static GENERAL_CONFIG_ITEM_SEPARATOR = ';';
  private static VALUE_ITEM_SEPARATOR = ',';
  private static KEY_VALUE_SEPARATOR = '~';
  private static KEY_ITEM_SEPARATOR = ':';
  private validTemplateDataMap = new Map<any, any>();
  templateWithIdMap = new Map();
  companyDivisionWithIdMap = new Map();

  constructor(moduleConfig: Map<ModuleConfigEnum, string>, otherParametersMap: Map<ModuleConfigOtherParametersEnum, any>) {
    super(moduleConfig, otherParametersMap);
  }

  /**
   * Implementation of the abstract method to run specific module config logic
   * @param moduleConfig - config value
   * @param otherParameters - if there are any other parameters needs to run the logic, can pass using this
   */
  generateConfigData(moduleConfigs: Map<ModuleConfigEnum, string>, otherParametersMap: Map<ModuleConfigOtherParametersEnum, any>): void {
    this.isValid = true;
    this.templateWithIdMap = new Map();
    this.companyDivisionWithIdMap = new Map();
    this.validTemplateDataMap = new Map<any, any>();

    if (this.validateDpTemplateDefinitionsConfig(moduleConfigs.get(ModuleConfigEnum.DP_TEMPLATE_DEFINITIONS)) &&
      this.validateDpTemplateEligibleDivBrandsConfig(moduleConfigs.get(ModuleConfigEnum.DP_TEMPLATE_ELIGIBLE_DIV_BRANDS))) {

      this.createTemplateWithIdMap(moduleConfigs.get(ModuleConfigEnum.DP_TEMPLATE_DEFINITIONS));
      this.createCompanyDivisionWithIdMap(moduleConfigs.get(ModuleConfigEnum.DP_TEMPLATE_ELIGIBLE_DIV_BRANDS));
      this.getTemplateByBrandDivision(otherParametersMap.get(ModuleConfigOtherParametersEnum.BRAND), otherParametersMap.get(ModuleConfigOtherParametersEnum.DIVISION));
    }

    this.parsedData = this.validTemplateDataMap;
  }

  createTemplateWithIdMap(DP_TEMPLATE_DEFINITIONS: string) {
    if (DP_TEMPLATE_DEFINITIONS) {
      const templateDefinitionList = DP_TEMPLATE_DEFINITIONS.split(DpTemplateConfig.GENERAL_CONFIG_ITEM_SEPARATOR);
      templateDefinitionList.forEach(templateDefinitionElement => {

        const idList = this.splitData(templateDefinitionElement, DpTemplateConfig.KEY_ITEM_SEPARATOR);
        const titleList = this.splitData(templateDefinitionElement, DpTemplateConfig.KEY_ITEM_SEPARATOR);
        const combinationList = this.splitData(templateDefinitionElement, DpTemplateConfig.KEY_ITEM_SEPARATOR);
        const nameValue = {title: null, combination: null};
        if ((titleList && titleList.length > 1) && (combinationList && combinationList.length > 2)) {
          nameValue.title = titleList[1];
          nameValue.combination = titleList[2];
        }
        this.templateWithIdMap.set(idList && idList.length > 0 ? parseInt(idList[0]) : null, nameValue);
      });
    }
  }

// createTemplateWithIdMap();
// console.log(templateWithIdMap);
// console.log(templateWithIdMap.get(1));


  createCompanyDivisionWithIdMap(DP_TEMPLATE_ELIGIBLE_DIV_BRANDS: string) {
    if (DP_TEMPLATE_ELIGIBLE_DIV_BRANDS) {
      const companyDivisionWithIdList = DP_TEMPLATE_ELIGIBLE_DIV_BRANDS.split(DpTemplateConfig.GENERAL_CONFIG_ITEM_SEPARATOR);

      companyDivisionWithIdList.forEach(companyDivisionWithIdElement => {
        const companyDivisionIdListList = this.splitData(companyDivisionWithIdElement, DpTemplateConfig.KEY_VALUE_SEPARATOR);
        const companyList = companyDivisionIdListList && companyDivisionIdListList.length > 0 ? this.splitData(companyDivisionIdListList[0], DpTemplateConfig.VALUE_ITEM_SEPARATOR) : [];
        const divisionList = companyDivisionIdListList && companyDivisionIdListList.length > 1 ? this.splitData(companyDivisionIdListList[1], DpTemplateConfig.VALUE_ITEM_SEPARATOR) : [];
        const templates = companyDivisionIdListList && companyDivisionIdListList.length > 2 ? this.splitData(companyDivisionIdListList[2], DpTemplateConfig.VALUE_ITEM_SEPARATOR) : [];
        templates.forEach(element => {
          const setData = companyList.concat(divisionList);
          this.companyDivisionWithIdMap.set(new Set(setData), parseInt(element));
        });

      });
    }
  }

  getTemplateByBrandDivision(brand: string, division: string) {
    const validTemplateDataMap = new Map<any, any>();
    this.companyDivisionWithIdMap.forEach((value, key) => {
      if (key.has(brand) && key.has(division)) {
        console.log('************');
        console.log(this.templateWithIdMap.get(1));
        console.log(typeof value);
        console.log(this.templateWithIdMap.get(value));
        if (this.templateWithIdMap.get(value)) {
          this.validTemplateDataMap.set(brand + DpTemplateConfig.KEY_VALUE_SEPARATOR + value + DpTemplateConfig.KEY_VALUE_SEPARATOR + division, this.templateWithIdMap.get(value));
        }
      }
    });
    return validTemplateDataMap;
  }


  validateDpTemplateDefinitionsConfig(DP_TEMPLATE_DEFINITIONS: string): boolean {
    let isValidConfig = true;
    if (DP_TEMPLATE_DEFINITIONS) {
      const DP_TEMPLATE_DEFINITIONS_LIST = this.splitData(DP_TEMPLATE_DEFINITIONS, DpTemplateConfig.GENERAL_CONFIG_ITEM_SEPARATOR);
      DP_TEMPLATE_DEFINITIONS_LIST.forEach(element => {
        // console.log((element.match(/:/g) || []).length);
        if ((element.match(/:/g) || []).length !== 2) {
          isValidConfig = false;
          console.log('in valid DP_TEMPLATE_DEFINITIONS--> mssing : ');
        }
      });


    } else {
      console.log('No data in DP_TEMPLATE_DEFINITIONS');
    }
    this.isValid = isValidConfig;
    return isValidConfig;
  }

  validateDpTemplateEligibleDivBrandsConfig(DP_TEMPLATE_ELIGIBLE_DIV_BRANDS: string): boolean {
    let isValidConfig = true;
    if (DP_TEMPLATE_ELIGIBLE_DIV_BRANDS) {
      const DP_TEMPLATE_ELIGIBLE_DIV_BRANDS_LIST = this.splitData(DP_TEMPLATE_ELIGIBLE_DIV_BRANDS, DpTemplateConfig.GENERAL_CONFIG_ITEM_SEPARATOR);
      DP_TEMPLATE_ELIGIBLE_DIV_BRANDS_LIST.forEach(element => {
        // console.log((element.match(/~/g) || []).length);
        if ((element.match(/~/g) || []).length !== 2) {
          isValidConfig = false;
          console.log('in valid DP_TEMPLATE_ELIGIBLE_DIV_BRANDS--> mssing ~ ');
        }
      });


    } else {
      console.log('No data in DP_TEMPLATE_ELIGIBLE_DIV_BRANDS');
    }
    this.isValid = isValidConfig;
    return isValidConfig;
  }


}
