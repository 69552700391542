import { NgModule } from '@angular/core';
import { SurfDatePickerModule } from '@surf/surf-date-picker';
import { SurfInputModule } from '@surf/surf-input';
import { OwnArrangementsComponent } from './own-arrangements/own-arrangements.component';
import {CommonModule} from '@angular/common';
import {SurfMultiSelectorDropDownModule} from "@surf/surf-multi-selector-drop-down";

@NgModule({
  declarations: [OwnArrangementsComponent],
  imports: [
    SurfDatePickerModule,
    SurfInputModule,
    CommonModule,
    SurfMultiSelectorDropDownModule
  ],
  exports: [
    OwnArrangementsComponent
  ]
})
export class SurfOwnArrangementsModule { }
