import { NgModule } from '@angular/core';
import { SurfDropDownModule } from '@surf/surf-drop-down';
import { TransferCriteriaComponent } from './transfer-criteria/transfer-criteria.component';
import { TransferCriteriaDpComponent } from './transfer-criteria-dp/transfer-criteria-dp.component';
import {CommonModule} from '@angular/common';
import {SurfTypeAheadModule} from '@surf/surf-type-ahead';
import {SurfInputModule} from '@surf/surf-input';
import {SurfDatePickerModule} from '@surf/surf-date-picker';
import {SurfCalendarModule} from '@surf/surf-calendar';
import {SurfCheckboxModule} from '@surf/surf-checkbox';

import {
  SurfCityPipe,
  SurfComponentsCoreModule,
  SurfCriteriaUtil,
  SurfTextCasePipeModule
} from '@surf/surf-components-core';
import {SurfPaxSelectionModule} from '@surf/surf-pax-selection';
import {SurfMultiFieldInputModule} from '@surf/surf-multi-field-input';
import {SurfDPAddBarModule} from '@surf/surf-dp-add-bar';
import {SurfMultiSelectorDropDownModule} from "@surf/surf-multi-selector-drop-down";

@NgModule({
  declarations: [TransferCriteriaComponent, TransferCriteriaDpComponent],
    imports: [
        CommonModule,
        SurfDPAddBarModule,
        SurfTypeAheadModule,
        SurfInputModule,
        SurfDatePickerModule,
        SurfCalendarModule,
        SurfTextCasePipeModule,
        SurfPaxSelectionModule,
        SurfComponentsCoreModule,
        SurfMultiFieldInputModule,
        SurfMultiSelectorDropDownModule,
        SurfCheckboxModule,
        SurfDropDownModule
    ],
  exports: [TransferCriteriaComponent, TransferCriteriaDpComponent],
  providers:[SurfCriteriaUtil, SurfCityPipe]
})
export class SurfTransferCriteriaModule { }
