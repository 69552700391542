<div [ngClass]="{'c-advance-search__content': !isDPSearch}">
  <ng-container *ngIf="!isServiceAddComponent; else elseblock">
  <div class="c-advance-search__content-top" *ngIf="!isDPSearch">
    <button class="a-btn a-btn--link c-pkg-search__back-btn" (click)="closeCriteriaBox()">
      <svg class="a-icon u-mr">
        <use xlink:href="#previous"></use>
      </svg>
    </button>
    <h3 *ngIf="!isRefine" class="t2 c-advance-search__title">Search transfers for</h3>
    <h3 *ngIf="isRefine" class="t2 c-advance-search__title">Refine your transfer search</h3>
    <div class="c-advance-search__guests">
      <div class="m-form-item auto-pax-count" (click)="scrollTo('#scroll')">
        <surf-pax-selection
          [(isFocused)]="guestComponentFocus"
          [paxSet]="defaultPaxSet"
          [product]="'FLT'"
          [inputWrapperView]="false"
          [maxAdult]="TRS_MAX_ADULT"
          [minAdult]="TRS_MIN_ADULT"
          [maxPaxCount]="TRS_MAX_PAX_COUNT"
          [maxCabinPaxCount]="TRS_MAX_CABIN_PAX_COUNT"
          [maxChildren]="TRS_MAX_CHILD"
          [maxTeen]="TRS_MAX_TEEN"
          [maxInfants]="TRS_MAX_INFANTS"
          [maxInfantAge]="MAX_INFANT_AGE"
          [maxChildAge]="MAX_CHILD_AGE"
          [maxTeenAge]="MAX_TEEN_AGE"
          [maxCabins]="MAX_ROOMS"
          [parentScrollId]="windowScrollEnable ? 'scroll': null"
          [isTeenActive]="TEEN_PAX_ACTIVE"
          (paxSelection)="getPassengerSelection($event)"
          [tabIndex]="2"
          (currentOption)="getCurrentOption($event)"
          (keydown)="onKeyDown($event, 1)"
          [b2bView]="true"
          [roomViewSelected]="false"
          [infoDisabled]="true">
        </surf-pax-selection>
      </div>
    </div>
    <button class="a-btn a-btn--link o-modal__inner-close auto-close-btn" (click)="closeCriteriaBox()">
      <svg class="a-icon o-modal__close-icon">
        <use xlink:href="#close"></use>
      </svg>
    </button>
  </div> <!--Content top : hidden in DP-->
  </ng-container>
    <ng-template #elseblock>
      <div class="c-advance-search__content-top">
        <button class="a-btn a-btn--link o-modal__inner-close" (click)="resetInputs()">
          <svg class="a-icon o-modal__close-icon">
            <use xlink:href="#close"></use>
          </svg>
        </button>
        <h3 class="t2 c-advance-search__title">Define your search for </h3>
        <div class="c-advance-search__guests">
          <span class="c-advance-search__guests-text">{{paxDisplay}}</span>
        </div>
      </div>
    </ng-template>
  <div class="c-advance-search__form" *ngIf="!isDPSearch">

    <div class="c-dp-search">
      <ng-container *ngIf="!isServiceAddComponent">
        <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}" [msg]="msg" [totalItemCount]="1"
                         [isDPSearch]="false" [isIntro]="false" [isRefine]="isRefine"
                         [disableDPSwitch]="DISABLE_DP_SWITCH"
                         [isDisabled]="DISABLE_DP_SWITCH"
                         (showBarPanelToggle)="moveToDP('pre', $event)"></surf-dp-add-bar>
      </ng-container>
      <div class="c-dp-search__block c-dp-search__block--car"
           [ngClass]="{'is-selected': (isSelected || !isDPSearch) , 'valid-criteria': validateInputs() && isDPSearch}"
           (click)="selectComponent()" (clickOutside)="deselectComponent()">
        <!--  is-selected t-primary-->
        <div class="c-dp-search__action">
          <div class="c-dp-search__block-icon">
            <svg class="a-icon">
              <use xlink:href="#products--bus"></use>
            </svg>
          </div>
        </div>

        <div class="c-dp-search__block-label">
          <svg class="a-icon u-rmr">
            <use xlink:href="#products--bus"></use>
          </svg>
          <span class="lbl">Transfer</span>
        </div>


        <div class="c-dp-search__form c-dp-search__form--transfer">

          <div class="c-dp-search__form-item c-dp-search__form-item--location c-dp-search__form-item--extended" id="{{windowScrollEnable ? 'scroll': ''}}">
            <div class="m-form-item" (click)="scrollTo('#scroll')">
              <div class="m-form-item__input auto-locations">
                <surf-multi-field-input [typeAheadAvailable]="true"
                                        [useEnterFocus]="true"
                                        [inputFocus]="true"
                                        [focusInit]="true"
                                        (isLastElement)="ShowLastElement($event)"
                                        [enableToolTip]="true"
                                        [properties]="pickUpProperties"
                                        [componentValue]="pickUpValues"
                                        [onChange]="false"
                                        [msg]="pickupInputErrorMsg"
                                        [warningStyle]="'is-visited'"
                                        [error]="pickupFilterListError || hasPickupLocationMismatchErr"
                                        [showTypeAheadContent]="showPickupTypeAheadContent || enableTransferRecommendation
                                        && enableRecommendLocations && showPickUpRecommendationList"
                                        (keyup)="onKeyDown($event, 0)"
                                        (emitValues)="handleLocationTypeSelection($event, pickupTypePropId)"
                                        (emitFilteredList)="getDestinations($event)"
                                        (emitIdOnFocusIn)="handleInputFocusIn($event,pickupLocationPropId)"
                                        (emitIdOnFocusOut)="handleInputFocusOut($event)"
                >
                  <surf-type-ahead-content *ngIf="enableTransferRecommendation && enableRecommendLocations && showPickUpRecommendationList"
                                           [typeAheadData]="recommendingLocations"
                                           [typeAheadInfoText]="'Locations from this itinerary'"
                                           [isPressEsc]="returnedProperty && returnedProperty.id === pickupLocationPropId ? pressEscPickup : returnedProperty && returnedProperty.id === dropoffLocationPropId ? pressEscPickup :null"
                                           [selectedItem]="returnedProperty && returnedProperty.id === pickupLocationPropId ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === dropoffLocationPropId ? previousSelectedDropCriteria :null"
                                           [available]="recommendingLocations?.length > 0"
                                           (setItem)="handleSuggestionSelect($event, pickupLocationPropId)"
                  >
                    <surf-type-ahead-item (click)="handleSuggestionSelect(item,pickupLocationPropId)" *ngFor="let item of recommendingLocations ;let i = index " >
                      <div class="c-search-selector__list-item">
                        <div class="c-dp-search__list-item auto-destinations" *ngIf="item?.itemType == 'HOTEL' || item?.itemType == 'CITY' || item?.itemType == 'LOCATION' || item?.itemType == 'CRUISE-PORT'|| item?.itemType == 'AIRPORT'" >
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use *ngIf="item?.itemType == 'CITY' || item?.itemType == 'LOCATION'" xlink:href="#map"></use>
                            <use *ngIf="item?.itemType == 'AIRPORT'" xlink:href="#products--flight"></use>
                            <use *ngIf="item?.itemType == 'HOTEL'" xlink:href="#products--hotel"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{item?.itemType == 'CITY' || item?.itemType === 40 ? item?.mainDetail : (item?.mainDetail | surfTextCase: 'title')}}</span>
                            <span class="s5 ts-muted ts-wet"><span *ngIf="true">{{item?.cityName + ', '}}</span>{{item?.countryName}}</span>
                          </div>
                          <span *ngIf="item?.itemType == 'CITY'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                          <span *ngIf="item?.itemType == 'AIRPORT'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Airport</span>
                          <span *ngIf="item?.itemType == 'HOTEL'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Hotel</span>
                          <span *ngIf="item?.itemType == 'LOCATION'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location</span>
                        </div>
                      </div>

                    </surf-type-ahead-item>

                  </surf-type-ahead-content>

                  <surf-type-ahead-content *ngIf="showPickupTypeAheadContent"
                                           [typeAheadData]="filteredDestinations"
                                           [isPressEsc]="returnedProperty && returnedProperty.id === pickupLocationPropId ? pressEscPickup : returnedProperty && returnedProperty.id === dropoffLocationPropId ? pressEscPickup :null"
                                           [selectedItem]="returnedProperty && returnedProperty.id === pickupLocationPropId ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === dropoffLocationPropId ? previousSelectedDropCriteria :null"
                                           [available]="filteredDestinations?.length > 0"
                                           (setItem)="handleSelection($event)"
                  >
                    <surf-type-ahead-item (click)="handleSelection(item)" *ngFor="let item of filteredDestinations;let i = index">
                      <div class="c-dp-search__list-item" *ngIf="item?.type == 40 || item?.type == 60 || item?.type == 80 || item?.type == 100">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.type == 'CITY' || item?.type == 40 || item?.type == 'LOCATION' || item?.type === 100" xlink:href="#map"></use>
                          <use *ngIf="item?.type == 'AIRPORT' || item?.type === 60" xlink:href="#products--flight"></use>
                          <use *ngIf="item?.type == 'HOTEL' || item?.type === 80" xlink:href="#products--hotel"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{item?.type == 'CITY' || item?.type === 40 ? item?.name : (item?.name | surfTextCase: 'title')}}</span>
                          <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                        </div>
                        <span *ngIf="item?.type == 'CITY' || item?.type === 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                        <span *ngIf="item?.type == 'AIRPORT' || item?.type === 60" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Airport</span>
                        <span *ngIf="item?.type == 'HOTEL' || item?.type === 80" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Hotel</span>
                        <span *ngIf="item?.type == 'LOCATION' || item?.type === 100" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location</span>
                      </div>

                    </surf-type-ahead-item>

                  </surf-type-ahead-content>

                </surf-multi-field-input>
              </div>
            </div>


          </div>
          <div class="c-dp-search__form-item c-dp-search__form-item--location c-dp-search__form-item--extended" id="{{windowScrollEnable ? 'scroll': ''}}">
            <div class="m-form-item" (click)="scrollTo('#scroll')">
              <div class="m-form-item__input auto-locations">
                <surf-multi-field-input [typeAheadAvailable]="true"
                                        [useEnterFocus]="true"
                                        [inputFocus]="true"
                                        [focusInit]="false"
                                        (isLastElement)="ShowLastElement($event)"
                                        [enableToolTip]="true"
                                        [properties]="dropoffProperties"
                                        [componentValue]="dropoffValues"
                                        [onChange]="false"
                                        [msg]="dropoffInputErrorMsg"
                                        [warningStyle]="'is-visited'"
                                        [error]="dropoffFilterListError || hasDropoffLocationMismatchErr"
                                        [showTypeAheadContent]="showDropoffTypeAheadContent || enableTransferRecommendation
                                        && enableRecommendLocations && showDropOffRecommendationList"
                                        (keyup)="onKeyDown($event, 0)"
                                        (emitValues)="handleLocationTypeSelection($event, dropoffTypePropId)"
                                        (emitFilteredList)="getDestinations($event)"
                                        (emitIdOnFocusIn)="handleInputFocusIn($event, dropoffLocationPropId)"
                                        (emitIdOnFocusOut)="handleInputFocusOut($event)"
                >
                  <surf-type-ahead-content *ngIf="enableTransferRecommendation && enableRecommendLocations && showDropOffRecommendationList"
                                           [typeAheadData]="recommendingLocations"
                                           [typeAheadInfoText]="'Locations from this itinerary'"
                                           [isPressEsc]="returnedProperty && returnedProperty.id === pickupLocationPropId ? pressEscPickup : returnedProperty && returnedProperty.id === dropoffLocationPropId ? pressEscPickup :null"
                                           [selectedItem]="returnedProperty && returnedProperty.id === pickupLocationPropId ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === dropoffLocationPropId ? previousSelectedDropCriteria :null"
                                           [available]="recommendingLocations?.length > 0"
                                           (setItem)="handleSuggestionSelect($event,dropoffLocationPropId)"
                  >
                    <surf-type-ahead-item (click)="handleSuggestionSelect(item,dropoffLocationPropId)" *ngFor="let item of recommendingLocations;let i = index" >
                      <div class="c-search-selector__list-item">
                        <div class="c-dp-search__list-item auto-destinations" *ngIf="item?.itemType == 'HOTEL' || item?.itemType == 'CITY' || item?.itemType == 'LOCATION' || item?.itemType == 'CRUISE-PORT'|| item?.itemType == 'AIRPORT'" >
                          <svg class="a-icon c-dp-search__list-item-icon">
                            <use *ngIf="item?.itemType == 'CITY' || item?.itemType == 'LOCATION'" xlink:href="#map"></use>
                            <use *ngIf="item?.itemType == 'AIRPORT'" xlink:href="#products--flight"></use>
                            <use *ngIf="item?.itemType == 'HOTEL'" xlink:href="#products--hotel"></use>
                          </svg>
                          <div class="m-info-set c-dp-search__list-item-main">
                            <span class="s4 ts-strong">{{item?.itemType == 'CITY' || item?.itemType === 40 ? item?.mainDetail : (item?.mainDetail | surfTextCase: 'title')}}</span>
                            <span class="s5 ts-muted ts-wet"><span *ngIf="true">{{item?.cityName + ', '}}</span>{{item?.countryName}}</span>
                          </div>
                          <span *ngIf="item?.itemType == 'CITY'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                          <span *ngIf="item?.itemType == 'AIRPORT'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Airport</span>
                          <span *ngIf="item?.itemType == 'HOTEL'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Hotel</span>
                          <span *ngIf="item?.itemType == 'LOCATION'" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location</span>
                        </div>
                      </div>

                    </surf-type-ahead-item>

                  </surf-type-ahead-content>

                  <surf-type-ahead-content *ngIf="showDropoffTypeAheadContent"
                                           [typeAheadData]="filteredDestinations"
                                           [isPressEsc]="returnedProperty && returnedProperty.id === pickupLocationPropId ? pressEscPickup : returnedProperty && returnedProperty.id === dropoffLocationPropId ? pressEscPickup :null"
                                           [selectedItem]="returnedProperty && returnedProperty.id === pickupLocationPropId ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === dropoffLocationPropId ? previousSelectedDropCriteria :null"
                                           [available]="filteredDestinations?.length > 0"
                                           (setItem)="handleSelection($event)"
                  >
                    <surf-type-ahead-item (click)="handleSelection(item)" *ngFor="let item of filteredDestinations;let i = index">
                      <div class="c-dp-search__list-item auto-destinations" *ngIf="item?.type == 40 || item?.type == 60 || item?.type == 80 || item?.type == 100">
                        <svg class="a-icon c-dp-search__list-item-icon">
                          <use *ngIf="item?.type == 'CITY' || item?.type == 40 || item?.type == 'LOCATION' || item?.type === 100" xlink:href="#map"></use>
                          <use *ngIf="item?.type == 'AIRPORT' || item?.type === 60" xlink:href="#products--flight"></use>
                          <use *ngIf="item?.type == 'HOTEL' || item?.type === 80" xlink:href="#products--hotel"></use>
                        </svg>
                        <div class="m-info-set c-dp-search__list-item-main">
                          <span class="s4 ts-strong">{{item?.type == 'CITY' || item?.type === 40 ? item?.name : (item?.name | surfTextCase: 'title')}}</span>
                          <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                        </div>
                        <span *ngIf="item?.type == 'CITY' || item?.type === 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                        <span *ngIf="item?.type == 'AIRPORT' || item?.type === 60" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Airport</span>
                        <span *ngIf="item?.type == 'HOTEL' || item?.type === 80" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Hotel</span>
                        <span *ngIf="item?.type == 'LOCATION' || item?.type === 100" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location</span>
                      </div>

                    </surf-type-ahead-item>

                  </surf-type-ahead-content>

                </surf-multi-field-input>
              </div>
            </div>


          </div>

          <div class="c-dp-search__form-group">
            <div class="c-dp-search__form-item auto-pickup-datepicker">
              <surf-date-picker
                (click)="scrollTo('#scroll')"
                [single_calendar]="true"
                [inputLabel]="(transferHideTimeDetails ? 'Pick-up date*' : 'Pick-up date & time*')"
                [check_availability]="false"
                [calendar_start_date]="(isForEpToDpConversion && index === 0) ? calendarMinDateForInitialEpeToDpConversionCriteria : null"
                [enablePreviousDates]="true"
                [currentDateOfTimeZone]="calendarMinDate"
                [isError]="isInvalidTime || validateDisabledDateSelectionPickUp"
                [invalidErrorMsg]="validateDisabledDateSelectionPickUp ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : 'Invalid Time'"
                [hideErrorMsg]="hideErrorMsgPickUp"
                [validateDisabledDateSelection]="validateDisabledDateSelectionPickUp"
                [reset]="resetPickupCalendar"
                [isFocused]="datePickerFocus"
                (selected_dates)="getSelectedDateTime($event, true)"
                [initial_dates]="pickUpInitialDate"
                [timeEnable]="!transferHideTimeDetails"
                [timeInputs]="false"
                [selectedMeridian]="'AM'"
                [selectedMin]="'00'"
                [selectedHr]="'12'"
                [warningStyle]="'is-visited'"
                [warning1]="pickupDateWarning"
              ></surf-date-picker>
            </div>
            <div *ngIf="showReturnDate || TRS_ONEWAY_RETURN_SELECTION_MODE === 'DEFAULT' || returnInitialDate.length" class="c-dp-search__form-item c-dp-search__form-item--inverted auto-dropOff-datepicker">
              <surf-date-picker
                (click)="scrollTo('#scroll')"
                [single_calendar]="true"
                [inputLabel]="(transferHideTimeDetails ? 'Return date' : 'Return date & time')"
                [check_availability]="false"
                [reset]="resetReturnCalendar"
                [calendarStartDate]="selectedPickupDate"
                [initial_dates]="returnInitialDate"
                [isError]="isInvalidTime || validateDisabledDateSelectionReturn"
                [invalidErrorMsg]="validateDisabledDateSelectionReturn ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : 'Invalid Time'"
                [hideErrorMsg]="hideErrorMsgReturn"
                [validateDisabledDateSelection]="validateDisabledDateSelectionReturn"
                (selected_dates)="getSelectedDateTime($event, false)"
                [timeEnable]="!transferHideTimeDetails"
                [timeInputs]="false"
                [selectedMeridian]="'AM'"
                [selectedMin]="'00'"
                [selectedHr]="'12'"
                [isRequired]="false"
                [warningStyle]="'is-visited'"
                [warning1]="returnDateWarning"
                [enablePreviousDates]="true"
                [currentDateOfTimeZone]="selectedPickupDate?selectedPickupDate:calendarMinDate"
              ></surf-date-picker>
              <span
                *ngIf="isInvalidTime"
                class="m-form-field__error has-error"
                style="display: block!important;">Please enter valid return date & time</span>
            </div>
            <div class="c-dp-search__form-item">
              <button *ngIf="!showReturnDate && TRS_ONEWAY_RETURN_SELECTION_MODE === 'OPT_FOR_RETURN' && !returnInitialDate.length" class="a-link-btn a-link-btn--primary c-dp-search__add-link u-mt-xl" (click)="showReturnDate = true">
                Do you want a return transfer?
                <span class="a-tool-tip a-tool-tip--top-left"
                      [attr.data-tooltip]="'Click here to specify a return date to search for return transfers'">
              <svg class="a-icon u-rml">
              <use xlink:href="#info"></use>
            </svg>
            </span>
              </button>
            </div>
          </div>
        </div>

        <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
          <svg class="a-icon">
            <use xlink:href="#close"></use>
          </svg>
        </div>


      </div>
      <ng-container *ngIf="!isServiceAddComponent">
      <surf-dp-add-bar [ngClass]="{'u-disabled': DISABLE_DP_SWITCH}" [totalItemCount]="1"
                       [isDPSearch]="false" [msg]="msg" [isIntro]="false" [isRefine]="isRefine"
                       [disableDPSwitch]="DISABLE_DP_SWITCH"
                       [isDisabled]="DISABLE_DP_SWITCH"
                       (showBarPanelToggle)="moveToDP('post', $event)"></surf-dp-add-bar>
        </ng-container>
    </div>

  </div> <!--Transfer criteria content : visible in Component flow-->


  <div class="c-dp-search__block c-dp-search__block--car"
       [ngClass]="{'is-selected': (isSelected || !isDPSearch), '':index%2 == 1, 'valid-criteria': (validateInputs() && isDPSearch)}"
       *ngIf="isDPSearch"
       (click)="selectComponent()" (clickOutside)="deselectComponent()">
    <!--  is-selected t-primary-->
    <div class="c-dp-search__action">
      <label *ngIf="isAmendmentFlow && !isItemCloseable" class="a-checkbox c-dp-search__action-check">
        <input type="checkbox" class="a-checkbox__check" (change)="checkValue($event)" [checked]="!freeze">
        <span class="a-checkbox__label"></span>
      </label>
      <div class="c-dp-search__block-icon">
        <svg class="a-icon">
          <use xlink:href="#products--bus"></use>
        </svg>
      </div>
    </div>

    <div class="c-dp-search__block-label">
      <svg class="a-icon u-rmr">
        <use xlink:href="#products--bus"></use>
      </svg>
      <span class="lbl">Transfer</span>
    </div>
    <div class="c-dp-search__form-wrap">
      <span class="a-loz a-loz--success c-dp-search__form-tag" *ngIf="isAmendmentFlow && isItemCloseable">Newly Added</span>
    <div class="c-dp-search__form c-dp-search__form--transfer">

      <div class="c-dp-search__form-item c-dp-search__form-item--location c-dp-search__form-item--extended auto-locations" id="{{windowScrollEnable ? 'scroll': ''}}">
        <surf-multi-field-input (click)="scrollTo('#scroll')"
                                [useEnterFocus]="true"
                                [inputFocus]="true"
                                [focusInit]="true"
                                (isLastElement)="ShowLastElement($event)"
                                [enableToolTip]="true"
                                [typeAheadAvailable]="true"
                                [useSingleList]="true"
                                [properties]="pickUpProperties"
                                [componentValue]="pickUpValues"
                                [onChange]="false"
                                [msg]="pickupInputErrorMsg"
                                [error]="pickupFilterListError || hasPickupLocationMismatchErr"
                                [showTypeAheadContent]="showPickupTypeAheadContent"
                                [disabled]="freeze"
                                [warningStyle]="'is-visited'"
                                (keyup)="onKeyDown($event, 0)"
                                (emitValues)="handleLocationTypeSelection($event, pickupTypePropId)"
                                (emitFilteredList)="getDestinations($event)"
                                (emitIdOnFocusOut)="handleInputFocusOut($event)"
        >

            <surf-type-ahead-content *ngIf="showPickupTypeAheadContent"
                                     [typeAheadData]="filteredDestinations"
                                     [isPressEsc]="returnedProperty && returnedProperty.id === pickupLocationPropId ? pressEscPickup : returnedProperty && returnedProperty.id === dropoffLocationPropId ? pressEscPickup :null"
                                     [selectedItem]="returnedProperty && returnedProperty.id === pickupLocationPropId ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === dropoffLocationPropId ? previousSelectedDropCriteria :null"
                                     [available]="filteredDestinations?.length > 0"
                                     (setItem)="handleSelection($event)"
            >
              <surf-type-ahead-item (click)="handleSelection(item)" *ngFor="let item of filteredDestinations;let i = index">
                <!-- ng content here-->
                <div class="c-dp-search__list-item" *ngIf="item?.type == 40 || item?.type == 60 || item?.type == 80 || item?.type == 100">
                  <svg class="a-icon c-dp-search__list-item-icon">
                    <use *ngIf="item?.type == 'CITY' || item?.type == 40 || item?.type == 'LOCATION' || item?.type === 100" xlink:href="#map"></use>
                    <use *ngIf="item?.type == 'AIRPORT' || item?.type === 60" xlink:href="#products--flight"></use>
                    <use *ngIf="item?.type == 'HOTEL' || item?.type === 80" xlink:href="#products--hotel"></use>
                  </svg>
                  <div class="m-info-set c-dp-search__list-item-main">
                    <span class="s4 ts-strong">{{item?.type == 'CITY' || item?.type === 40 ? item?.name : (item?.name | surfTextCase: 'title')}}</span>
                    <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                  </div>
                  <span *ngIf="item?.type == 'CITY' || item?.type == 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                  <span *ngIf="item?.type == 'AIRPORT' || item?.type === 60" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Airport</span>
                  <span *ngIf="item?.type == 'HOTEL' || item?.type === 80" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Hotel</span>
                  <span *ngIf="item?.type == 'LOCATION' || item?.type === 100" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location</span>
                </div>

              </surf-type-ahead-item>

            </surf-type-ahead-content>

          </surf-multi-field-input>
        </div>

      <div class="c-dp-search__form-item c-dp-search__form-item--location c-dp-search__form-item--extended auto-locations" id="{{windowScrollEnable ? 'scroll': ''}}">
        <surf-multi-field-input [typeAheadAvailable]="true"
                                [useEnterFocus]="true"
                                [inputFocus]="true"
                                [focusInit]="false"
                                (isLastElement)="ShowLastElement($event)"
                                [enableToolTip]="true"
                                [properties]="dropoffProperties"
                                [componentValue]="dropoffValues"
                                [onChange]="false"
                                [msg]="dropoffInputErrorMsg"
                                [error]="dropoffFilterListError || hasDropoffLocationMismatchErr"
                                [showTypeAheadContent]="showDropoffTypeAheadContent"
                                [disabled]="freeze"
                                [warningStyle]="'is-visited'"
                                (keyup)="onKeyDown($event, 0)"
                                (emitValues)="handleLocationTypeSelection($event, dropoffTypePropId)"
                                (emitFilteredList)="getDestinations($event)"
                                (emitIdOnFocusOut)="handleInputFocusOut($event)"
        >

            <surf-type-ahead-content *ngIf="showDropoffTypeAheadContent"
                                     [typeAheadData]="filteredDestinations"
                                     [isPressEsc]="returnedProperty && returnedProperty.id === pickupLocationPropId ? pressEscPickup : returnedProperty && returnedProperty.id === dropoffLocationPropId ? pressEscPickup :null"
                                     [selectedItem]="returnedProperty && returnedProperty.id === pickupLocationPropId ? previousSelectedPickupCriteria : returnedProperty && returnedProperty.id === dropoffLocationPropId ? previousSelectedDropCriteria :null"
                                     [available]="filteredDestinations?.length > 0"
                                     (setItem)="handleSelection($event)"
            >
              <surf-type-ahead-item (click)="handleSelection(item)" *ngFor="let item of filteredDestinations;let i = index">
                <div class="c-dp-search__list-item" *ngIf="item?.type == 40 || item?.type == 60 || item?.type == 80 || item?.type == 100">
                  <svg class="a-icon c-dp-search__list-item-icon">
                    <use *ngIf="item?.type == 'CITY' || item?.type == 40 || item?.type == 'LOCATION' || item?.type === 100" xlink:href="#map"></use>
                    <use *ngIf="item?.type == 'AIRPORT' || item?.type === 60" xlink:href="#products--flight"></use>
                    <use *ngIf="item?.type == 'HOTEL' || item?.type === 80" xlink:href="#products--hotel"></use>
                  </svg>
                  <div class="m-info-set c-dp-search__list-item-main">
                    <span class="s4 ts-strong">{{item?.type == 'CITY' || item?.type === 40 ? item?.name : (item?.name | surfTextCase: 'title')}}</span>
                    <span class="s5 ts-muted ts-wet"><span *ngIf="surfCriteriaUtil.getState(item)">{{surfCriteriaUtil.getState(item) + ', '}}</span>{{surfCriteriaUtil.getCountry(item)}}</span>
                  </div>
                  <span *ngIf="item?.type == 'CITY' || item?.type === 40" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">City</span>
                  <span *ngIf="item?.type == 'AIRPORT' || item?.type === 60" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Airport</span>
                  <span *ngIf="item?.type == 'HOTEL' || item?.type === 80" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Hotel</span>
                  <span *ngIf="item?.type == 'LOCATION' || item?.type === 100" class="c-dp-search__list-item-sub s5 ts-muted ts-wet">Location</span>
                </div>

              </surf-type-ahead-item>

            </surf-type-ahead-content>

          </surf-multi-field-input>
        </div>

      <div class="c-dp-search__form-group">
        <div class="c-dp-search__form-item c-dp-search__form-item--inverted auto-pickup-datepicker">
          <surf-date-picker
            (click)="scrollTo('#scroll')"
            [single_calendar]="true"
            [inputLabel]="(transferHideTimeDetails ? 'Pick-up date*' : 'Pick-up date & time*')"
            [check_availability]="false"
            [isError]="isInvalidTime || validateDisabledDateSelectionPickUp"
            [invalidErrorMsg]="validateDisabledDateSelectionPickUp ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : 'Invalid Time'"
            [hideErrorMsg]="hideErrorMsgPickUp"
            [validateDisabledDateSelection]="validateDisabledDateSelectionPickUp"
            [calendarStartDate]="(isForEpToDpConversion && index === 0) ? null : getCalendarStartDate()"
            [calendar_start_date]="(isForEpToDpConversion && index === 0) ? calendarMinDateForInitialEpeToDpConversionCriteria : null"
            [enablePreviousDates]="true"
            [currentDateOfTimeZone]="calendarMinDate"
            [reset]="resetPickupCalendar"
            [isFocused]="datePickerFocus"
            (selected_dates)="getSelectedDateTime($event, true)"
            [initial_dates]="pickUpInitialDate"
            [timeEnable]="!transferHideTimeDetails"
            [timeInputs]="false"
            [selectedMeridian]="'AM'"
            [selectedMin]="'00'"
            [selectedHr]="'12'"
            [disabled]="freeze"
            [warningStyle]="'is-visited'"
            [warning1]="pickupDateWarning"
          ></surf-date-picker>
        </div>
        <div class="c-dp-search__form-item c-dp-search__form-item--inverted auto-dropOff-datepicker">
          <surf-date-picker
            (click)="scrollTo('#scroll')"
            [single_calendar]="true"
            [inputLabel]="(transferHideTimeDetails ? 'Return date' : 'Return date & time')"
            [check_availability]="false"
            [reset]="resetReturnCalendar"
            [calendarStartDate]="dpReturnDisplayDate"
            [initial_dates]="returnInitialDate"
            [isError]="isInvalidTime || validateDisabledDateSelectionReturn"
            [invalidErrorMsg]="validateDisabledDateSelectionReturn ? SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE : 'Invalid Time'"
            [hideErrorMsg]="hideErrorMsgReturn"
            [validateDisabledDateSelection]="validateDisabledDateSelectionReturn"
            [timeEnable]="!transferHideTimeDetails"
            [timeInputs]="false"
            [selectedMeridian]="'AM'"
            [selectedMin]="'00'"
            [selectedHr]="'12'"
            [enablePreviousDates]="true"
            [currentDateOfTimeZone]="selectedPickupDate?selectedPickupDate:calendarMinDate"
          ></surf-date-picker>
        </div>


      </div>

      <!-- TRS pickup dropoff dependent DP flow -->
      <div class="c-dp-search__option-box" *ngIf="trsIndependentDisplay">
        <div class="c-dp-search__option-box-label">Select which type of transfer this is
          <span class="a-tool-tip a-tool-tip--top-left u-rml" data-tooltip="This will reflect the transfer type &#xa;for the selected destination">
              <svg class="a-icon">
                  <use xlink:href="#info"></use>
              </svg>
          </span>
        </div>
        <div class="c-dp-search__options">
          <div class="c-dp-search__option-item">

            <!--surf checkbox-->
            <surf-checkbox [id]="'P' + index" [selected]="pickupIndependent" (status)="setPickDropDependent($event, 'P')" [textCase]="'paragraph'" [showNameCode]="false" [autoStatusChange]="false"  [name]="'Pick-up independent'"> </surf-checkbox>

          </div>
          <div class="c-dp-search__option-item">

            <!--surf checkbox-->
            <surf-checkbox [id]="'D' + index" [selected]="dropoffIndependent" (status)="setPickDropDependent($event, 'D')" [textCase]="'paragraph'" [showNameCode]="false" [autoStatusChange]="false"  [name]="'Drop-off independent'"> </surf-checkbox>
          </div>
        </div>
      </div>

      <div class="c-dp-search__form-item" *ngIf="ENABLE_PAX_SPLIT && !freeze && isDPSearch && enablePaxSplit">
        <ng-container>
          <surf-multi-selector-drop-down [title]="'Split Passenger'"
                                         [dataList]="paxSplitArray"
                                         [inputDisplay]="paxSplitDisplayText"
                                         [isChipClosable]="true"
                                         [dropUP]="true"
                                         [inputType]="'Guest'"
                                         [isDisabled]="freeze"
                                         (selectionChange)="passengerSplitChange($event)"
                                         (emitClosedItem)="passengerSplitChange($event)"
          >
          </surf-multi-selector-drop-down>
        </ng-container>
      </div>
      <button class="a-link-btn a-link-btn--primary c-dp-search__add-link" (click)="enablePaxSplitDropdown()" *ngIf="ENABLE_PAX_SPLIT && isDPSearch && !freeze && !enablePaxSplit">
        Do you want to assign passengers?
        <span class="a-tool-tip a-tool-tip--top-left"
              [attr.data-tooltip]="'Will allow you to assign/split passengers into multiple components'">
              <svg class="a-icon u-rml">
              <use xlink:href="#info"></use>
            </svg>
            </span>
      </button>
    </div>

    </div>
    <div [hidden]="!isItemCloseable" class="c-dp-search__close" *ngIf="isDPSearch" (click)="removeItem()">
      <svg class="a-icon">
        <use xlink:href="#close"></use>
      </svg>
    </div>


  </div> <!--Transfer criteria content : visible in DP flow-->


  <div class="c-advance-search__btn-wrapper" *ngIf="!isDPSearch">
    <div class="c-advance-search__search-info">
      <svg class="a-icon search-info--icon" *ngIf = "!isServiceAddComponent">
        <use xlink:href="#info"></use>
      </svg>
      <span *ngIf = "!isServiceAddComponent && !transferHideTimeDetails">Please select your Pick-up, Drop-off locations and Pick-up date & time to continue search</span>
      <span *ngIf = "!isServiceAddComponent && transferHideTimeDetails">Please select your Pick-up, Drop-off locations and Pick-up date to continue search</span>
    </div>
    <div *ngIf="!isRefine" class="c-advance-search__search-action">
      <ng-container *ngIf="isServiceAddComponent">
        <button (click)="resetInputs()" class="a-btn a-btn--default auto-cancel-btn" [tabIndex]="4">Cancel</button>
      </ng-container>
      <ng-container *ngIf="!isServiceAddComponent">
        <button (click)="this.showReturnDate = false; resetInputs()" class="a-btn a-btn--default auto-btn-reset" [tabIndex]="4">Reset Search</button>
      </ng-container>
      <button (click)="searchTransfers(false)" class="a-btn a-btn--primary auto-btn-search" [tabIndex]="5"
              [ngClass]="{'is-disabled':!validateInputs()}">Search
      </button>
    </div>
    <div *ngIf="isRefine" class="c-advance-search__search-action">
      <button (click)="resetInputs(isRefine)" class="a-btn a-btn--default auto-btn-cancel">Back to results</button>
      <button (click)="searchTransfers(false)" class="a-btn a-btn--primary auto-btn-refine" [ngClass]="{'is-disabled':!validateInputs()}">
        Refine
        Search
      </button>
    </div>
  </div> <!--Button wrapper : hidden in DP-->

</div>

<style>
  .is-disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
    display: block;
  }
</style>
