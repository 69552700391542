import {Traveller} from '@tc-core/model/it/codegen/tbx/api/commons/traveller';
import {TravellerContact} from '@tc-core/model/it/codegen/tbx/api/commons/traveller-contact';
import {FlightGETCriteria, MatchingTraveller, PnrTravellerDiff, TRAVELLER_MAP_SPLITTERS} from './grab-pnr.models';
import {GrabPnrUtil} from './grab-pnr-util';

export class GrabPnrBooking  {
  public bkgTravellers: Array<Traveller>;
  public pnrTravellers: Array<Traveller>;
  public matchingTravellers: Array<MatchingTraveller>;
  private unmatchedTravellers: Array<MatchingTraveller>;
  public resultsPageHelperParam = '';
  public showErrorMessage = false;
  private newTravellerNo: number; // when add new travellers use this as traveller number <traveller.profile.no>

  /**
   * This does the whole traveller matching thing :)
   */
  matchTravellers() {
    this.newTravellerNo = this.getHighestTravellerNo() + 1;
    this.matchingTravellers = [];
    this.unmatchedTravellers = [];
    let primaryMatchedBkgTravellers = [];
    if (this.pnrTravellers && this.pnrTravellers.length > 0 && this.bkgTravellers && this.bkgTravellers.length > 0) {
      this.findExactMatches();
      if (this.pnrTravellers.length > 0) {
        this.showErrorMessage = true;
        this.findPartialMatches(primaryMatchedBkgTravellers);
      }
    }
    // after matching all pnrTravellers - remaining bkgTravellers will be added to the list for display purposes
    if (this.bkgTravellers && this.bkgTravellers.length > 0) {
      this.bkgTravellers.forEach((bkgTraveller) => {
        let diffs = primaryMatchedBkgTravellers[bkgTraveller.no];
        this.addUnmatchedTraveller(bkgTraveller, false, diffs);
      });
    }
    // merge unmatched travellers at the end of the list
    this.matchingTravellers = this.matchingTravellers.concat(this.unmatchedTravellers);
    // console.log('matches', this.matchingTravellers);
    // console.log('helper', this.resultsPageHelperParam);
  }


  /**
   * This method will find travelers whose primary details are exactly the same
   * these travellers will be updated in the booking
   */
  findExactMatches() {
    for (const [index1, pnrTraveller] of Array.from(this.pnrTravellers.entries())) {
      if (this.bkgTravellers.length > 0) {
        for (const [index2, bkgTraveller] of Array.from(this.bkgTravellers.entries())) {
          const isMatching = this.isAllMatching(pnrTraveller, bkgTraveller);
          if (isMatching) {
            const diff = new PnrTravellerDiff(true);
            //for infants DOB not considered for exact matching
            //there fore check dob diff here
            if (pnrTraveller.profile.type === 'I') {
              // if both dobs are available check whether they are equal
              if (pnrTraveller.profile && pnrTraveller.profile.dob && pnrTraveller.profile.dob != null
                && bkgTraveller.profile && bkgTraveller.profile.dob  && bkgTraveller.profile.dob != null) {
                diff.dateOfBirth = new Date(pnrTraveller.profile.dob).getTime() === new Date(bkgTraveller.profile.dob).getTime();
              }
              // if both dobs are empty return true
              if ((!pnrTraveller.profile.dob || pnrTraveller.profile.dob == null) && (!bkgTraveller.profile.dob || bkgTraveller.profile.dob == null)) {
                diff.dateOfBirth = true;
              } else {
                // one is present and other one is not then false
                diff.dateOfBirth = false;
              }
            }
            const isSecondaryFieldsMatching = this.updateSecondaryFields(pnrTraveller, bkgTraveller, diff);
            this.updateOtherFields(pnrTraveller, bkgTraveller, diff);
            this.addMatchingTraveller(pnrTraveller, bkgTraveller, diff);
            this.addResultsPageHelperParam(pnrTraveller, bkgTraveller);
            this.removeMatched(pnrTraveller, bkgTraveller);
            if (!isSecondaryFieldsMatching && !this.showErrorMessage) {
              this.showErrorMessage = true;
            }
            break;
          }
        }
      }
    }
  }

  /**
   * This method will find travelers whose primary details are different but still they can be the same
   * Anyway these all travellers will be added to the booking as new travellers
   */
  findPartialMatches(primaryMatchedBkgTravellers) {
    for (const [index1, pnrTraveller] of Array.from(this.pnrTravellers.entries())) {
      let tempDiff;
      if (this.bkgTravellers.length > 0) {
        let foundMatch = false;
        for (const [index2, bkgTraveller] of Array.from(this.bkgTravellers.entries())) {
          const value = this.isPartiallyMatching(pnrTraveller, bkgTraveller);
          if (value.isMatched) {
            foundMatch = true;
            this.updateSecondaryFields(pnrTraveller, bkgTraveller, value.diffs);
            this.updateOtherFields(pnrTraveller, bkgTraveller, value.diffs);
            this.addMatchingTraveller(pnrTraveller, bkgTraveller, value.diffs);
            this.addResultsPageHelperParam(pnrTraveller, null);
            this.removeMatched(pnrTraveller, bkgTraveller);
            break;
          } else if (value.diffs && value.diffs.hasPrimaryMatch) {
            tempDiff = value.diffs;
            primaryMatchedBkgTravellers[bkgTraveller.no] = value.diffs;
          }
        }
        if (!foundMatch) {
          this.addUnmatchedTraveller(pnrTraveller, true, tempDiff);
          this.addResultsPageHelperParam(pnrTraveller, null);
        }
      } else {
        this.addUnmatchedTraveller(pnrTraveller, true, tempDiff);
        this.addResultsPageHelperParam(pnrTraveller, null);
      }
    }
  }

  /**
   * Check whether given bkg and pnr traveller details are exactly the same
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   */
  isAllMatching(pnrTraveller: Traveller, bkgTraveller: Traveller): boolean {
    //ignore title matching for all passengers
    //ignore title matching for lap infants - Reason : title can be null for lap infants when grabbing, so we ignore it
    // if(pnrTraveller.profile.type === 'I'){
    //   if(pnrTraveller.infantSeatRequired){
    //     //check title only for seat infant
    //     if (pnrTraveller.profile.title == undefined) {
    //       return false;
    //     } else if (pnrTraveller.profile.title.toUpperCase() !== bkgTraveller.profile.title.toUpperCase()) {
    //       return false;
    //     }
    //   }
    // }else{
    //   //for other travellers
    //   if (pnrTraveller.profile.title == undefined){
    //     return false;
    //   } else if (pnrTraveller.profile.title.toUpperCase() !== bkgTraveller.profile.title.toUpperCase()) {
    //     return false;
    //   }
    // }

    if (pnrTraveller.profile.firstName == undefined) {
      return false;
    } else if ( this.removeCharactersInString(pnrTraveller.profile.firstName.toUpperCase()) !== this.removeCharactersInString(bkgTraveller.profile.firstName.toUpperCase())) {
      return false;
    }

    if (pnrTraveller.profile.lastName == undefined) {
      return false;
    } else if (this.removeCharactersInString(pnrTraveller.profile.lastName.toUpperCase()) !== this.removeCharactersInString(bkgTraveller.profile.lastName.toUpperCase())) {
      return false;
    }

    if (pnrTraveller.profile.type !== bkgTraveller.profile.type) {
      return false;
    }

    if (bkgTraveller.profile.type == 'C') {
      // *For children, age is not considering to match

       //age is matching, lets see dob if only its known
      if (pnrTraveller.profile.dob != undefined) {
        /*If the booking traveller DOB is KNOWN, we do compare DOBs*/
        if(!bkgTraveller.profile.dobUnknown){
          return pnrTraveller.profile.dob === bkgTraveller.profile.dob;
        }else{
          /*If the booking traveller DOB is UNKNOWN, we do NOT compare DOBs*/
          return true;
        }
      }

    }
    // FC-24085 - DOB matching for infants is removed
    // else if (bkgTraveller.profile.type == 'I') {
    //   /*For infants, match dob only */
    //   /*If the PNR traveller DOB is AVAILABLE, we do compare DOBs*/
    //   if (pnrTraveller.profile.dob != undefined) {
    //     /*If the booking traveller DOB is KNOWN, we do compare DOBs*/
    //     if(!bkgTraveller.profile.dobUnknown){
    //       return pnrTraveller.profile.dob === bkgTraveller.profile.dob;
    //     }else{
    //       /*If the booking traveller DOB is UNKNOWN, we do NOT compare DOBs*/
    //       return true;
    //     }
    //   }
    // }
    //
    // /*If the PNR traveller DOB is NOT AVAILABLE, we do NOT compare DOBs*/
    return true;
  }

  /**
   * Check whether given bkg and pnr travellers at-least share the same info to be identified as the same traveller
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   */
  isPartiallyMatching(pnrTraveller: Traveller, bkgTraveller: Traveller): {isMatched: boolean, diffs: PnrTravellerDiff} {
    const diffs = new PnrTravellerDiff();

    if (pnrTraveller.profile.type === 'I') {
      if (!pnrTraveller.infantSeatRequired) {
        //for lap infants, ignore title
        if (pnrTraveller.profile.type === bkgTraveller.profile.type) {
          return this.checkIfMatched(pnrTraveller, bkgTraveller, diffs);
        } else {
          return {isMatched: false, diffs: null};
        }
      } else {
        //for seat infants, consider title
        // if (pnrTraveller.profile.type === bkgTraveller.profile.type && pnrTraveller.profile.title != undefined) {
        if (pnrTraveller.profile.type === bkgTraveller.profile.type) {
          // ignore title matching
          // if (pnrTraveller.profile.title.toUpperCase() === bkgTraveller.profile.title.toUpperCase()) {
            return this.checkIfMatched(pnrTraveller, bkgTraveller, diffs);
          // } else {
          //   return {isMatched: false, diffs: null};
          // }
        } else {
          return {isMatched: false, diffs: null};
        }
      }
    } else {
      // if (pnrTraveller.profile.title.toUpperCase() === bkgTraveller.profile.title.toUpperCase() &&
      //   pnrTraveller.profile.type === bkgTraveller.profile.type) {
      if ( pnrTraveller.profile.type === bkgTraveller.profile.type) {
        return this.checkIfMatched(pnrTraveller, bkgTraveller, diffs);
      } else {
        return {isMatched: false, diffs: null};
      }
    }
  }

  checkIfMatched(pnrTraveller: Traveller, bkgTraveller: Traveller, diffs: PnrTravellerDiff): {isMatched: boolean, diffs: PnrTravellerDiff} {

    // diffs.title = true;

    const isLastNameMatching = pnrTraveller.profile.lastName != undefined &&
      (this.removeCharactersInString(pnrTraveller.profile.lastName.toUpperCase()) === this.removeCharactersInString(bkgTraveller.profile.lastName.toUpperCase()));

    const isFirstNameMatching = pnrTraveller.profile.firstName != undefined &&
      (this.removeCharactersInString(pnrTraveller.profile.firstName.toUpperCase()) === this.removeCharactersInString(bkgTraveller.profile.firstName.toUpperCase()));

    const isAgeMatching = pnrTraveller.profile.age && bkgTraveller.profile.age && pnrTraveller.profile.age === bkgTraveller.profile.age;

    const isDobMatching = pnrTraveller.profile.dob && bkgTraveller.profile.dob && pnrTraveller.profile.dob === bkgTraveller.profile.dob;

    if (pnrTraveller.profile.type == 'C') {
      if (isLastNameMatching && isFirstNameMatching && isAgeMatching) {
        diffs.hasMatch = true;
        diffs.age = isAgeMatching;
        diffs.firstName = isFirstNameMatching;
        diffs.lastName = isLastNameMatching;
        return {isMatched: true, diffs};
      } else if (isLastNameMatching && isFirstNameMatching) {
        diffs.hasPrimaryMatch = true;
        diffs.age = isAgeMatching;
        return {isMatched: false, diffs};
      }
    } else if (pnrTraveller.profile.type == 'I') {
      // FC-24085 - DOB matching for infants is removed
      if (isLastNameMatching && isFirstNameMatching) {
        diffs.hasMatch = true;
        diffs.dateOfBirth = isDobMatching;
        diffs.firstName = isFirstNameMatching;
        diffs.lastName = isLastNameMatching;
        return {isMatched: true, diffs};
      }
    } else {
      if (isLastNameMatching && isFirstNameMatching) {
        diffs.hasMatch = true;
        diffs.dateOfBirth = false;
        diffs.firstName = isFirstNameMatching;
        diffs.lastName = isLastNameMatching;
        return {isMatched: true, diffs};
      }
    }

/*    if (pnrTraveller.profile.type == 'C') {
      const isAgeMatching = pnrTraveller.profile.age && bkgTraveller.profile.age && pnrTraveller.profile.age === bkgTraveller.profile.age;
      if (isLastNameMatching && isFirstNameMatching) {
        diffs.hasMatch = true;
        diffs.age = isAgeMatching;
        diffs.firstName = isFirstNameMatching;
        diffs.lastName = isLastNameMatching;
        return {isMatched: true, diffs};
      }
    } else {
      const isDobMatching = pnrTraveller.profile.dob && bkgTraveller.profile.dob && pnrTraveller.profile.dob === bkgTraveller.profile.dob;
      if (isDobMatching) {
        if (isLastNameMatching || isFirstNameMatching) {
          diffs.hasMatch = true;
          diffs.dateOfBirth = true;
          diffs.firstName = isFirstNameMatching;
          diffs.lastName = isLastNameMatching;
          return {isMatched: true, diffs};
        }
      } else {
        if (isLastNameMatching && isFirstNameMatching) {
          diffs.hasMatch = true;
          diffs.dateOfBirth = false;
          diffs.firstName = isFirstNameMatching;
          diffs.lastName = isLastNameMatching;
          return {isMatched: true, diffs};
        }
      }
    }*/

    return {isMatched: false, diffs: null};
  }

  /**
   * Check whether the Phone number and Frequent flyer numbers are matching and update the diffs
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   * @param diffs - bkg and pnr passenger differences
   */
  updateSecondaryFields(pnrTraveller: Traveller, bkgTraveller: Traveller, diffs: PnrTravellerDiff): boolean {
    diffs.phoneNumber = this.validatePhoneNumber(pnrTraveller, bkgTraveller, true);
    diffs.freqFlyer = this.validateFreqFlyerNumbers(pnrTraveller, bkgTraveller);
    return (diffs.phoneNumber && diffs.freqFlyer);
  }
  /**
   * Check whether the tile, middle name and passport details are matching and update the diffs
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   * @param diffs - bkg and pnr passenger differences
   */
  updateOtherFields(pnrTraveller: Traveller, bkgTraveller: Traveller, diffs: PnrTravellerDiff) {
    diffs.middleName = this.validateMiddleName(pnrTraveller, bkgTraveller);
    diffs.email = this.validateEmails(pnrTraveller, bkgTraveller);
    diffs.title = this.validateTitle(pnrTraveller, bkgTraveller);
    this.validatePpDetails(pnrTraveller, bkgTraveller, diffs);
  }
  /**
   * If there pnr are travellers that are identified as not matching with the booking travellers
   * and remaining unmatched booking travellers
   * will be added to a separate array
   * @see unmatchedTravellers
   * @param traveller - traveller bkg or pnr
   * @param isPnrTraveller - whether to tell this is a pnr or a bkg traveller
   */
  addUnmatchedTraveller(traveller: Traveller, isPnrTraveller: boolean, tempDiff?) {
    const diffs = new PnrTravellerDiff();
    diffs.hasMatch = false;

    if (tempDiff) {
      diffs.hasPrimaryMatch = tempDiff.hasPrimaryMatch;
      diffs.age = tempDiff.age;
      diffs.dateOfBirth = tempDiff.dateOfBirth;
    }

    const matchingTraveller = new MatchingTraveller();
    if (isPnrTraveller) {
      matchingTraveller.pnrTraveller = traveller;
      this.removeMatched(traveller, null);
    } else {
      matchingTraveller.bkgTraveller = traveller;
      this.removeMatched(null, traveller);
    }
    matchingTraveller.diffs = diffs;
    this.unmatchedTravellers.push(matchingTraveller);
  }

  /**
   * pnr and bkg traveller pair is matched! add them here
   * still the phone number or freq. flyer number (secondary fields) can be different
   * @see matchingTravellers
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   * @param diffs - bkg and pnr passenger differences
   */
  addMatchingTraveller(pnrTraveller: Traveller, bkgTraveller: Traveller, diffs: PnrTravellerDiff): void {
    const matchingTraveller = new MatchingTraveller();
    matchingTraveller.pnrTraveller = pnrTraveller;
    matchingTraveller.bkgTraveller = bkgTraveller;
    matchingTraveller.diffs = diffs;
    this.matchingTravellers.push(matchingTraveller);
  }


  /**
   * This parameter consists of the matching or unmatched pnr travellers <traveller.profile.no>|<travellerKey>
   *     eg: 1|MR#TOM#PERERA#A_2|MRS#KATE#PERERA#A
   * this will be used in flight results page to update <profile.no> when creating cart
   * matching pnrTravellers will use the matched bkgTraveller <profile.no>
   * and other unmatched travellers will use a new number
   * @see getUniqueTravellerKey
   * @see getHighestTravellerNo
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   */
  addResultsPageHelperParam(pnrTraveller: Traveller, bkgTraveller: Traveller): void {
    this.resultsPageHelperParam += (this.resultsPageHelperParam.length > 0) ? TRAVELLER_MAP_SPLITTERS.MAIN_SPLITTER : '';
    if (bkgTraveller) {
      this.resultsPageHelperParam += bkgTraveller.no;
    } else {
      this.resultsPageHelperParam += this.newTravellerNo;
      this.newTravellerNo += 1;
    }
    this.resultsPageHelperParam += TRAVELLER_MAP_SPLITTERS.SUB_SPLITTER;
    this.resultsPageHelperParam += GrabPnrUtil.getUniqueTravellerKey(pnrTraveller);
  }

  removeMatched(pnrTraveller: Traveller, bkgTraveller: Traveller) {
    if (pnrTraveller) {
      this.pnrTravellers = this.pnrTravellers.filter((val) => (val.key !== pnrTraveller.key));
    }
    if (bkgTraveller) {
      this.bkgTravellers = this.bkgTravellers.filter((val) => (val.key !== bkgTraveller.key));
    }
  }

  /**
   * validates the phone number of a traveller pair
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   * @param ifEmpty - if both are empty (true/false)
   */
  validatePhoneNumber(pnrTraveller: Traveller, bkgTraveller: Traveller, ifEmpty: boolean) {
    const phone = this.getTravellerContact(bkgTraveller, 'Telephone');
    const pnrPhone = this.getTravellerContact(pnrTraveller, 'Telephone');

    // if both phone numbers are available check whether they are equal
    if (pnrPhone && pnrPhone.value && pnrPhone.value.length > 0 && phone && phone.value && phone.value.length > 0) {
      // FC-35870: Need to remove '+' value here due to a inconsistency in sabre and amadeus grab flows
      return pnrPhone.value.replace('+', '') === phone.value.replace('+', '');
    }

    // if both phone numbers are empty return falseIfEmpty
    if ((!pnrPhone || !pnrPhone.value || pnrPhone.value.length === 0) && (!phone || !phone.value || phone.value.length === 0)) {
      return ifEmpty;
    }
    // one is present and other one is not then false
    return false;
  }

  getTravellerContact( traveller: Traveller, contactType: string): TravellerContact {
    if (traveller && traveller.profile && traveller.profile.addresses && traveller.profile.addresses[0] &&
      traveller.profile.addresses[0].contacts && traveller.profile.addresses[0].contacts.length > 0) {
      return traveller.profile.addresses[0].contacts.find((contact) => (contact.contactType === contactType));
    }
    return null;
  }
  /**
   * validates the email of a traveller pair
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   */
  validateEmails(pnrTraveller: Traveller, bkgTraveller: Traveller) {
    const email = this.getTravellerContact(bkgTraveller, 'E-mail');
    const pnrEmail = this.getTravellerContact(pnrTraveller, 'E-mail');

    // if both emails are available check whether they are equal
    if (pnrEmail && pnrEmail.value && pnrEmail.value.length > 0 && email && email.value && email.value.length > 0) {
      return pnrEmail.value.toUpperCase() === email.value.toUpperCase();
    }

    // if both emails are empty return true
    if ((!pnrEmail || !pnrEmail.value || pnrEmail.value.length === 0) && (!email || !email.value || email.value.length === 0)) {
      return true;
    }
    // one is present and other one is not then false
    return false;
  }

  /**
   * validates the middle names of a traveller pair
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   */
  validateMiddleName(pnrTraveller: Traveller, bkgTraveller: Traveller) {
    // if both middle names are available check whether they are equal
    if (pnrTraveller.profile && pnrTraveller.profile.middleName && pnrTraveller.profile.middleName.length > 0
      && bkgTraveller.profile && bkgTraveller.profile.middleName  && bkgTraveller.profile.middleName.length > 0) {
      return this.removeCharactersInString(pnrTraveller.profile.middleName.toUpperCase()) === this.removeCharactersInString(bkgTraveller.profile.middleName.toUpperCase());
    }

    // if both middle names are empty return true
    if ((!pnrTraveller.profile.middleName || pnrTraveller.profile.middleName.length === 0) && (!bkgTraveller.profile.middleName || bkgTraveller.profile.middleName.length === 0)) {
      return true;
    }
    // one is present and other one is not then false
    return false;
  }

  removeCharactersInString( text: string) {
    if (text && text.length > 0) {
      return text.replace(/[^A-Z]/ig,'');
    }
  }

  /**
   * validates the title of a traveller pair
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   */
  validateTitle(pnrTraveller: Traveller, bkgTraveller: Traveller) {
    // infant title is not grabbed in pnr flow
    if (pnrTraveller.profile.type === 'I') {
      return true;
    } else {
      // if both titles are available check whether they are equal
      if (pnrTraveller.profile && pnrTraveller.profile.title && pnrTraveller.profile.title.length > 0
        && bkgTraveller.profile && bkgTraveller.profile.title  && bkgTraveller.profile.title.length > 0) {
        return pnrTraveller.profile.title.toUpperCase() === bkgTraveller.profile.title.toUpperCase();
      }

      // if both titles are empty return true
      if ((!pnrTraveller.profile.title || pnrTraveller.profile.title.length === 0) && (!bkgTraveller.profile.title || bkgTraveller.profile.title.length === 0)) {
        return true;
      }
      // one is present and other one is not then false
      return false;
    }
  }

  /**
   * validates the passport details of a traveller pair
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   * @param diffs - bkg and pnr passenger differences
   */
  validatePpDetails(pnrTraveller: Traveller, bkgTraveller: Traveller, diffs: PnrTravellerDiff) {
    // if both passport numbers are available check whether they are equal
    if (pnrTraveller.profile && pnrTraveller.profile.primaryPpNo && pnrTraveller.profile.primaryPpNo.length > 0
      && bkgTraveller.profile && bkgTraveller.profile.primaryPpNo  && bkgTraveller.profile.primaryPpNo.length > 0) {
      diffs.ppNumber = pnrTraveller.profile.primaryPpNo.toUpperCase() === bkgTraveller.profile.primaryPpNo.toUpperCase();
    }
    // if both passport numbers are empty return true
     if ((!pnrTraveller.profile.primaryPpNo || pnrTraveller.profile.primaryPpNo.length === 0) && (!bkgTraveller.profile.primaryPpNo || bkgTraveller.profile.primaryPpNo.length === 0)) {
      diffs.ppNumber = true;
    } else {
      // one is present and other one is not then false
       diffs.ppNumber = false;
    }

    // if both passport countries are available check whether they are equal
    if (pnrTraveller.profile && pnrTraveller.profile.primaryPpIssueCountry && pnrTraveller.profile.primaryPpIssueCountry.length > 0
      && bkgTraveller.profile && bkgTraveller.profile.primaryPpIssueCountry  && bkgTraveller.profile.primaryPpIssueCountry.length > 0) {
      diffs.ppCountry = pnrTraveller.profile.primaryPpIssueCountry.toUpperCase() === bkgTraveller.profile.primaryPpIssueCountry.toUpperCase();
    }
    // if both passport countries are empty return true
    if ((!pnrTraveller.profile.primaryPpIssueCountry || pnrTraveller.profile.primaryPpIssueCountry.length === 0) && (!bkgTraveller.profile.primaryPpIssueCountry || bkgTraveller.profile.primaryPpIssueCountry.length === 0)) {
      diffs.ppCountry = true;
    } else {
      // one is present and other one is not then false
      diffs.ppCountry = false;
    }

    // if both passport expiry dates are available check whether they are equal
    if (pnrTraveller.profile && pnrTraveller.profile.primaryPpExpDate && pnrTraveller.profile.primaryPpExpDate != null
      && bkgTraveller.profile && bkgTraveller.profile.primaryPpExpDate  && bkgTraveller.profile.primaryPpExpDate != null) {
      diffs.ppExpiry = new Date(pnrTraveller.profile.primaryPpExpDate).getTime() === new Date(bkgTraveller.profile.primaryPpExpDate).getTime();
    }
    // if both passport expiry dates are empty return true
    if ((!pnrTraveller.profile.primaryPpExpDate || pnrTraveller.profile.primaryPpExpDate == null) && (!bkgTraveller.profile.primaryPpExpDate || bkgTraveller.profile.primaryPpExpDate == null)) {
      diffs.ppExpiry = true;
    } else {
      // one is present and other one is not then false
      diffs.ppExpiry = false;
    }

    // if both nationalities are available check whether they are equal
    if (pnrTraveller.profile && pnrTraveller.profile.primaryCitizenship && pnrTraveller.profile.primaryCitizenship.length > 0
      && bkgTraveller.profile && bkgTraveller.profile.primaryCitizenship  && bkgTraveller.profile.primaryCitizenship.length > 0) {
      diffs.nationality = pnrTraveller.profile.primaryCitizenship.toUpperCase() === bkgTraveller.profile.primaryCitizenship.toUpperCase();
    }
    // if both nationalities are empty return true
    if ((!pnrTraveller.profile.primaryCitizenship || pnrTraveller.profile.primaryCitizenship.length === 0) && (!bkgTraveller.profile.primaryCitizenship || bkgTraveller.profile.primaryCitizenship.length === 0)) {
      diffs.nationality = true;
    } else {
      // one is present and other one is not then false
      diffs.nationality = false;
    }
  }
  /**
   * validates freq. flyer numbers of a traveller pair
   * @param pnrTraveller - traveller from the pnr grab
   * @param bkgTraveller - traveller in the booking
   */
  validateFreqFlyerNumbers(pnrTraveller: Traveller, bkgTraveller: Traveller): boolean {
    const freqFlyerNumbers =  bkgTraveller.profile ? bkgTraveller.profile.frequentFlyerNumbers : [];
    const pnrFreqFlyerNumbers =  pnrTraveller.profile ? pnrTraveller.profile.frequentFlyerNumbers : [];
    if (!freqFlyerNumbers || !pnrFreqFlyerNumbers || freqFlyerNumbers.length === 0 || pnrFreqFlyerNumbers.length === 0) {
      return ((!freqFlyerNumbers || freqFlyerNumbers.length === 0) && (!pnrFreqFlyerNumbers || pnrFreqFlyerNumbers.length === 0));
    } else if (freqFlyerNumbers.length !== pnrFreqFlyerNumbers.length) {
      return false;
    } else {
      return pnrFreqFlyerNumbers.every( (pnrFreq) =>
        (!!freqFlyerNumbers.find((freq) =>
          (freq.airline === pnrFreq.airline && freq.flyerNo === pnrFreq.flyerNo)
        ))
      );
    }
  }

  /**
   * continue to flight results page with selected PNR number
   */
  public continueToFlightResults(flightCriteria: FlightGETCriteria, passengerCount: number, bkgId: number) {
    let params = '';
    if (flightCriteria === null || flightCriteria === undefined) {
      return params;
    }
    for (const prop in flightCriteria) {
      if (Object.prototype.hasOwnProperty.call(flightCriteria, prop)) {
        if (flightCriteria[prop] && flightCriteria[prop] !== '') {
          params += '&' + prop + '=' + flightCriteria[prop];
        }
      }
    }
    if (params && params[0] === '&') {
      params = params.slice(1);
    }
    params += '&pnrPaxCount=' + passengerCount;
    params += '&travellerMapping=' + this.resultsPageHelperParam;
    params += '&pnrBkgId=' + bkgId;


    const basePath = '/';
    window.location.href = basePath + 'b2b-flights/?' + params;
  }

  /**
   * URL param string to navigate to dynamic package results page with selected PNR number for multi validating carriers
   */
  public getDPParamString(flightCriteria: FlightGETCriteria, passengerCount: number, bkgId: number) {
    let params = '';
    params += '&pnrPaxCount=' + passengerCount;
    params += '&travellerMapping=' + this.resultsPageHelperParam;
    params += '&pnrBkgId=' + bkgId;

    return params;
  }

  /**
   * To add mismatching PNR passengers into booking <traveller.profile.no> should be different from exiting booking passengers
   * this method finds the highest number of traveller.profile.no 's in booking travellers
   * and new passengers will be added counting from highest number
   */
  getHighestTravellerNo(): number {
    if (this.bkgTravellers && this.bkgTravellers.length > 0) {
      return Math.max.apply(Math, this.bkgTravellers.map((traveller) => (traveller.no)));
    }
    return 1;
  }
}
