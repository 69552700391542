import {AbstractModuleConfigParser} from '../moduleConfigParsers/abstract-module-config-parser';
import {ModuleConfigEnum} from '../moduleConfigParsers/module-config-enum';
import {ModuleConfigOtherParametersEnum} from '../moduleConfigParsers/module-config-other-parameters-enum';
import {DpTemplateConfig} from '../moduleConfigParsers/dp-template.config';
// to generate configuration details from module config
export class ModuleConfigParserFactory {

  /**
   *
   * @param code - module config code to run specific implementation
   * @param moduleConfig - module config value
   * @param otherParameters - if there is any other parameters to run logic
   */
  public static getModuleConfigParsedResult(code: ModuleConfigEnum, moduleConfigs: Map<ModuleConfigEnum, string>, otherParameters: Map<ModuleConfigOtherParametersEnum, any>): AbstractModuleConfigParser {
    switch (code) {
      case ModuleConfigEnum.DP_TEMPLATES_ENABLE:
        return new DpTemplateConfig(moduleConfigs, otherParameters);
      default:
        return null;
    }
  }
}
