import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  CALENDAR_VIEW,
  ConfigService,
  DataShareService,
  DestinationType, FetchCriteriaDataExchangeModel,
  GENERAL_SEPARATORS,
  KeyCodes,
  OntologyResponse,
  ProductType,
  SPLITTER,
  StringConcatenationClass,
  SurfAuthorizationService,
  SurfCalendarStartDateService,
  SurfCityPipe,
  SurfCriteriaUtil,
  SurfErrorMessages,
  SurfScrollDirective,
  SurfStaticDataService,
  SurfTextCasePipe, SurfTourUtilService,
  SurfTravellerEarliestDepartureDateService,
  SurfUtilService,
  TourCriteria
} from '@surf/surf-components-core';
import { MultiFieldInputData, MultiFieldInputPropertyFactory } from '@surf/surf-multi-field-input';
import { PaxAllocationItem } from '@surf/surf-pax-selection';
import { NameCodePair } from '@tc-core/model/it/codegen/tbx/api/v2/commons/name-code-pair';
import { DataServiceHandler } from '@tc-core/service/service-handlers';
import { TC } from '@tc-core/util';
import { ConfigLoader, DataKey, DataStore } from '@tc-core/util/framework';
import { Subscription } from 'rxjs';
import { PermissionKeys } from '../security/permission-constants';
import surfOperations from '../security/tour-criteria-operations.json';

@Component({
  selector: 'surf-tour-criteria',
  templateUrl: './tour-criteria.component.html'
})
export class TourCriteriaComponent implements OnInit, OnChanges {

  constructor(
    protected commonService: DataShareService,
    protected dataServiceHandler: DataServiceHandler,
    protected surfStaticDataService: SurfStaticDataService,
    protected configService: ConfigService,
    protected surfUtilService: SurfUtilService,
    public surfCriteriaUtil: SurfCriteriaUtil,
    protected configLoader: ConfigLoader,
    protected surfCalendarStartDateService: SurfCalendarStartDateService,
    protected surfTourUtilService: SurfTourUtilService,
    protected dataStore: DataStore,
    protected surfAuthorizationService: SurfAuthorizationService,
    protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService
  ) {
    this.cityPipe = new SurfCityPipe(surfStaticDataService);
  }

  @Input() isDPSearch = false;
  @Input() index: number;
  @Input() unfreeze = true;
  @Input() isServiceAddComponent = false;
  @Input() isRefine = false;
  @Input() setRoomWise = false;
  @Input() windowScrollEnable = true;
  @Input() selectedPax: any;
  @Input() cartPaxSelection: any;
  @Input() isAmendmentFlow = false;
  @Input() isItemCloseable = true;
  @Input() triggerExternalSearch = '';
  @Input() isDisableGrabTourButton = false;
  @Input() keyControls;
  @Input() isDpGrab = false;
  @Input() grabBlockDetails: NameCodePair ;
  @Input() grabbedTourDetailList: FetchCriteriaDataExchangeModel[];
  @Input() dpTourRefineCriteria: any;
  @Input() cachingItemMap = new Map<string, number>();
  @Input() allowNgrxCaching = false;
  @Input() isEpToDpConversion = false;
  @Input() destNames = '';
  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter();
  @Output() getCurrentTourCriteria = new EventEmitter<TourCriteria>();
  @Output() isUnfreeze = new EventEmitter<boolean>();
  displayingGrabbedTourDetailList: FetchCriteriaDataExchangeModel[];

  /**
   * variables
   */
  paxDisplay: string;
  subscription = new Subscription();
  tourCriteria: TourCriteria = new TourCriteria();
  guestComponentFocus = false;
  currentPaxOption = 0;
  enterPressed = false;
  defaultPaxSet: Array<PaxAllocationItem> = [];
  TEEN_PAX_ACTIVE = true;
  DISABLE_DP_SWITCH = false;
  keyParams: string;
  destinationList = [];
  tourSuppliers = [];
  isRefineSupplier = false;
  initialDates = [];
  resetCalendar = false;
  defaultActiveCalendar = CALENDAR_VIEW.SINGLE_YEAR_VIEW;

  isDateNotSelected = false;
  durationError = false;
  durationProperty;
  durationValues;
  msg = '';
  selectedStop: OntologyResponse = new OntologyResponse();
  selectedDestination: OntologyResponse = new OntologyResponse();
  selectedTourName: OntologyResponse = new OntologyResponse();
  selectedTourCategory: OntologyResponse = new OntologyResponse();

  tourStopCityURL = '';
  destinationCityURL = '';
  tourNameURL = '';
  destinationCategoryURL = '';
  filteredTourStop = [];
  filteredDestinationCity = [];
  filteredTourName = [];
  filteredTourCategory = [];

  MIN_SEARCH_QUERY_LENGTH = 3;
  TOU_SEARCH_RESULT_COUNT = 200;
  stopSearchInitialized = false;
  destSearchInitiated = false;
  nameSearchInitialized = false;
  categorySearchInitialized = false;
  cityPipe: SurfCityPipe;

  depPortPartiallyTyped = false;
  initialCategory = 'Any supplier';
  readonly NONE_SELECTED = '-1';
  FIELD_SEPERATOR = GENERAL_SEPARATORS.FIELD_SEPARATOR;
  AGE_SEPERATOR = '__';
  LESS_THAN_ONE = '< 1';
  DEFAULT_ADULT_AGE = 30;
  MAX_INFANT_AGE = 23;
  MAX_CHILD_AGE = 12;
  MAX_TEEN_AGE = 17;
  TOU_MAX_CABIN_PAX_COUNT = 20;
  TOU_MAX_CHILD = this.TOU_MAX_CABIN_PAX_COUNT - 1;
  TOU_MAX_TEEN = this.TOU_MAX_CABIN_PAX_COUNT - 1;
  TOU_MAX_INFANTS = this.TOU_MAX_CABIN_PAX_COUNT - 1;
  TOU_MIN_ADULT = 1;
  TOU_MAX_ADULT = 20;
  TOU_MAX_PAX_COUNT = 40;
  calendarRange = 2;
  calendarView = CALENDAR_VIEW.SINGLE_DATE_MONTH_VIEW;
  monthCalendarLabel = 'Travel Month/Date*';
  monthCalendarPlaceholder = 'DD/MM/YYYY';
  calendarMinDate = new Date();
  DURATION_CATEGORIES = {code: 'D', value: 'Days', selected: true};
  STATIC_DATA_URL_BASE = '/data/data-service/v2/static-data';
  STATIC_DATA_FETCH_SIZE = 20;
  TOUR_STOP_SELECT_MAX = 5;
  TOUR_CATEGORY_SELECT_MAX = 5;
  SURF_ERROR_MESSAGES = SurfErrorMessages;
  stopOntologies: OntologyResponse[] = [];
  categoryOntologies: OntologyResponse[] = [];
  h2hSystemIds = 'TBX';
  moduleConfigs;
  OPERATOR = 'OPERATOR';
  SUPPLIER_LOAD = 'SUP_LOAD';
  H2H_SYSTEMS_LOAD = 'H2H_LOAD';

  grabbedTourId;
  isGrabbed = false;
  supplierSubscription;
  enableSearchFlow = true;
  permissionKeys;
  freeze: boolean;

  hideErrorMsg = true;
  validateDisabledDateSelection = false;

  categoryPlaceholderText = 'Search and select multiple categories';
  tourStopPlaceholderText = 'Search and select multiple cities';
  tourNamePlaceholderText = 'Search tour name';
  departureCityPlaceholderText = 'Search city';


  ngOnInit() {
    this.loadConfigs();
    this.doInitialSetup();
    if (this.isRefine) {
      this.setRefineDate();
    }
    this.setAuthenticationParameters();
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'TOU');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.unfreeze && this.unfreeze !== undefined) {
      this.freeze = !this.unfreeze;
    }
    if (changes.selectedPax) {
      this.selectedPax = changes.selectedPax.currentValue;
      if (this.isRefine) {
        this.defaultPaxSet = this.selectedPax;
      }
      if (this.isServiceAddComponent) {
        const adults = this.selectedPax.reduce( (acc, pax ) => acc + pax.adult , 0 ) ;
        const child = this.selectedPax.reduce( (acc, pax) => acc + pax.child.length , 0) ;
        const teen = this.selectedPax.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
        const infant = this.selectedPax.reduce( (acc, pax) => acc + pax.infant.length , 0 ) ;
        this.paxDisplay = this.generateDisplayString(adults, child, infant, teen) ;
      }
    }
    if (changes.cartPaxSelection && this.cartPaxSelection) {
      const adults = this.cartPaxSelection.reduce( (acc, pax ) => acc + pax.adult , 0 );
      const child = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.child.length , 0);
      const teen = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
      const infant = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.infant.length , 0 );
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getPassengerSelection(this.cartPaxSelection);
    }
    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === ProductType.TOU) {
      this.searchTour(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }
    if (changes.grabbedTourDetailList && this.grabbedTourDetailList) {
      this.tourGrabResultOutput(this.grabbedTourDetailList);
    }
  }

  /**
   * set Permission details
   */
  setAuthenticationParameters() {
    this.permissionKeys = PermissionKeys;
    this.surfAuthorizationService.initPermissions(surfOperations);
    const tourCriteriaEnabled = this.surfAuthorizationService
                                                .getPermissionResponse(PermissionKeys.TOUR_CRITERIA);
    this.enableSearchFlow = (tourCriteriaEnabled && tourCriteriaEnabled.allowed);
  }

  /**
   * generate the display string to show the selected pax numbers in surf-pax-selection component
   */
  generateDisplayString(adult, child, infant, teen) {
    let str = '';
    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    return str;
  }

  /**
   * load module configs
   */
  loadConfigs() {
    this.STATIC_DATA_URL_BASE = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA] ?
      this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA] : this.STATIC_DATA_URL_BASE;

    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();

    this.moduleConfigs.forEach(mc => {
      switch (mc.code) {
        case 'STATIC_DATA_FETCH_SIZE':
          this.STATIC_DATA_FETCH_SIZE = +mc.name;
          break;
        case 'TEEN_PAX_ACTIVE':
          this.TEEN_PAX_ACTIVE = ((mc.name as string).toLowerCase() === 'true');
          break;
        case 'DISABLE_DP_SWITCH':
          this.DISABLE_DP_SWITCH = ((mc.name as string).toLowerCase() === 'true');
          break;
        case 'TOUR_STOP_SELECT_MAX':
          this.TOUR_STOP_SELECT_MAX = +mc.name;
          break;
        case 'TOUR_CATEGORY_SELECT_MAX':
          this.TOUR_CATEGORY_SELECT_MAX = +mc.name;
          break;
        case 'MAX_CABIN_PAX_COUNT':
          this.TOU_MAX_CABIN_PAX_COUNT = +mc.name;
          break;
        case 'MIN_ADULT':
          this.TOU_MIN_ADULT = +mc.name;
          break;
        case 'MAX_ADULT':
          this.TOU_MAX_ADULT = +mc.name;
          break;
        case 'MAX_PAX_COUNT':
          this.TOU_MAX_PAX_COUNT = mc.name;
          break;
        case 'MIN_SEARCH_QUERY_LENGTH':
          this.MIN_SEARCH_QUERY_LENGTH = +mc.name;
          break;
        case 'TOU_SEARCH_RESULT_COUNT':
          this.TOU_SEARCH_RESULT_COUNT = mc.name;
          break;
      }
    });
    this.tourStopCityURL = this.STATIC_DATA_URL_BASE +
      '?dataTypes=CITY&userInput=[UI]&aggrLevel=COUNTRY&locale=en&matchType=CODE~NAME&expand=all&resultCount='
      + this.STATIC_DATA_FETCH_SIZE;
    this.destinationCityURL = this.STATIC_DATA_URL_BASE +
      '?dataTypes=CITY&userInput=[UI]&aggrLevel=COUNTRY&locale=en&matchType=CODE~NAME&expand=all&resultCount='
      + this.STATIC_DATA_FETCH_SIZE;
    this.tourNameURL = this.STATIC_DATA_URL_BASE +
      '?dataTypes=TOUR_NAME&userInput=[UI]&aggrLevel=NA&locale=en&matchType=NAME&expand=all&resultCount='
      + this.STATIC_DATA_FETCH_SIZE;
    this.destinationCategoryURL = this.STATIC_DATA_URL_BASE +
      '?dataTypes=TOUR_CATEGORY&userInput=[UI]&aggrLevel=NA&locale=en&matchType=NAME&expand=all&resultCount='
      + this.STATIC_DATA_FETCH_SIZE;
  }

  /**
   * load needed static data
   */
  loadAndSetupStaticData(lock = true) {
    this.supplierSubscription = this.dataServiceHandler.getSuppliers(TC.Product.TOURS, this.OPERATOR).subscribe(
      data => {
        this.fillUpStaticData(data, this.SUPPLIER_LOAD);

        // set initial selection in refine window
        if (data && data.length > 0 && this.isRefine && this.tourCriteria && this.tourCriteria.supplier) {
          this.selectTourSupplier({code: this.tourCriteria.supplier}, true);
          this.supplierSubscription.unsubscribe();
        }
        if (data && data.length > 0) {
          lock = false;
        }
      },
      error => console.error('Error in fetching Tour Suppliers', error)
    );
  }

  /**
   * Initial setup the data needed for components
   */
  doInitialSetup() {
    this.loadAndSetupStaticData();
    this.subscription.add(
      this.commonService.getComponentCriteria(TC.Product.TOURS).subscribe(criteria => {
        this.tourCriteria = criteria as TourCriteria;
        if (this.isRefine && this.tourCriteria && this.tourSuppliers && !this.isRefineSupplier &&
          this.tourSuppliers.length > 0 && this.tourCriteria.supplier) {
          this.selectTourSupplier({code: this.tourCriteria.supplier}, true);
        }
      }));

    this.subscription.add(
      this.commonService.getCurrentMsg().subscribe(msg => {
        if (msg == 'triggerReset') {
          this.resetInputs();
        }
      }));

    this.subscription.add(this.commonService.getKeyParamString().subscribe(keyParams => {
      if (keyParams) {
        this.keyParams = this.surfCriteriaUtil.overrideResultCount(keyParams, this.TOU_SEARCH_RESULT_COUNT);
      }
    }));
    if (this.dpTourRefineCriteria) {
      this.tourCriteria = this.dpTourRefineCriteria;
      if ( ( this.isAmendmentFlow || this.isDpGrab || this.isEpToDpConversion ) && this.tourCriteria && this.tourSuppliers && !this.isRefineSupplier &&
        this.tourSuppliers.length > 0 && this.tourCriteria.supplier) {
        this.selectTourSupplier({code: this.tourCriteria.supplier}, true);
      }
    }
    this.setMultiFieldInputProperties();

    this.setInitialRefineValues();
  }

  /**
   * remove item
   */
  removeItem() {
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.updateComponentCriteria(null, 'TOU');
    }
    this.itemRemoved.emit(this.index);
  }

  /**
   * validate input field for dp flow
   */
  validateInputsForDP() {
    this.isValid.emit( this.validateInputs() );
  }

  /**
   * set initial values in refine panel here
   */
  setInitialRefineValues() {
    if (this.isRefine || this.isAmendmentFlow || this.isDpGrab || this.isEpToDpConversion) {
      if (this.tourCriteria && this.tourCriteria.destinationName) {
        this.selectedDestination.displayText = this.tourCriteria.destinationName +
          (this.tourCriteria.destinationParentCode ? ', ' + this.tourCriteria.destinationParentCode : '');
      }

      if (this.tourCriteria && this.tourCriteria.itineraryName) {
        this.selectedTourName.displayText = this.tourCriteria.itineraryName;
      }

      if (this.tourCriteria && this.tourCriteria.tourDestCities) {
        const tourDestCitySplit = this.tourCriteria.tourDestCities.split(SPLITTER.COMMA_SPLITTER);
        tourDestCitySplit.forEach((city, index) => {
          this.cityPipe.transform(city).subscribe(res => {
            if (res) {
              const nameCodePair = new NameCodePair();
              nameCodePair.code = city;
              nameCodePair.name = res;
              const temp = this.createOntologyData(nameCodePair, index);
              this.stopOntologies.push(temp);
            }
          });
        });
      }

      if (this.tourCriteria && this.tourCriteria.tourCategory) {
        const tourCategorySplit = this.tourCriteria.tourCategory.split(GENERAL_SEPARATORS.FIELD_SEPARATOR);
        tourCategorySplit.forEach((category, index) => {
          this.surfStaticDataService.getTourCategories(category, this.STATIC_DATA_FETCH_SIZE).subscribe(res => {
            if (res && res.data && res.data.length > 0) {
              const nameCodePair = new NameCodePair();
              nameCodePair.code = category;
              nameCodePair.name = res.data[0].name;
              const temp = this.createOntologyData(nameCodePair, index, false);
              this.categoryOntologies.push(temp);
            }
          });
        });
      }
      if (!this.initialDates || this.initialDates?.length === 0 ) {
        this.setRefineDate();
      }
    }
  }

  /**
   * set static data
   */
  fillUpStaticData(data, type) {
      if (!this.tourCriteria) {
          this.tourCriteria = new TourCriteria();
      }
      if (data && data.length > 0) {
          data.forEach(item => {
            if (type === this.SUPPLIER_LOAD) {
              // find and filter the TOUR_RADAR_SEARCH_AND_BOOK_ENABLED suppliers
              const tourRadarAttr = item?.detail?.attributes?.find(attr => attr.name === 'TOUR_RADAR_SEARCH_AND_BOOK_ENABLED');
              if (tourRadarAttr && tourRadarAttr.value === 'yes') {
                this.tourSuppliers.push({code: item.code, name: item.name, value: item.name, selected: false});
              }
            }
          });
          if (type === this.SUPPLIER_LOAD) {
              this.tourSuppliers.sort((a, b) => {
                  if (a.value < b.value) { return -1; }
                  if (a.value > b.value) { return 1; }
                  return 0;
              });
              this.tourSuppliers = this.addSelectAnyOption(this.tourSuppliers, this.initialCategory);
          }
          if (this.supplierSubscription) {
            this.supplierSubscription.unsubscribe();
          }
      }
  }

  /**
   * set 'select any' option to dropdowns
   */
  addSelectAnyOption(list, defaultValue: string) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    if (list && list.length > 0) {
      if (!(list[0].code === -1 || list[0].name === defaultValue)) {
        const defaultItem = JSON.parse(JSON.stringify(list[0]));
        defaultItem.value = defaultValue;
        defaultItem.name = defaultValue;
        defaultItem.selected = false;
        defaultItem.code = this.NONE_SELECTED;
        defaultItem.tourDefaultCode = this.NONE_SELECTED;
        list.unshift(defaultItem);
      }
      list.forEach(dropdownItem => {
        if (dropdownItem.value !== defaultValue) {
          dropdownItem.value = surfTextCasePipe.transform(dropdownItem.value, 'title');
        }
      });
    }
    return list;
  }

  /**
   *
   * @param $event
   * common passenger selection logic
   */
  getPassengerSelection($event: Array<PaxAllocationItem>) {
    if ($event != null && this.surfCriteriaUtil.validateChildAges($event) && this.surfCriteriaUtil.validateTeenAges($event)) {
      this.tourCriteria.adult = this.surfCriteriaUtil.processAdultPaxAllocation($event, this.DEFAULT_ADULT_AGE);

      const children = this.surfCriteriaUtil.processChildAllocation($event);
      const teens = this.surfCriteriaUtil.processTeenAllocation($event);
      const infant = this.surfCriteriaUtil.processInfantAllocation($event);
      if (children.CHD && children.CHD.length > 0) {
        this.tourCriteria.child = children.CHD;
      } else {
        this.tourCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.tourCriteria.teen = teens;
      } else {
        this.tourCriteria.teen = null;
      }
      this.tourCriteria.infant = infant && infant.length > 0 ? infant : null;

      this.commonService.updateComponentCriteria(this.tourCriteria, TC.Product.TOURS);
    } else {
      this.tourCriteria.adult = null;
      this.tourCriteria.child = null;
      this.tourCriteria.teen = null;
      this.tourCriteria.infant = null;
      this.commonService.updateComponentCriteria(this.tourCriteria, TC.Product.TOURS);
    }

  }

  onKeyDown($event, component) {
    if ($event.keyCode === KeyCodes.ENTER) {
      if (component === 0) {
        setTimeout(() => {
          this.guestComponentFocus = false;
        }, 200);
      } else if (component === 1) {
        if (this.currentPaxOption === 3) {
          if (this.enterPressed && this.tourCriteria &&
            (this.tourCriteria.nights === undefined || this.tourCriteria.nights === 0)) {
            setTimeout(() => {
              this.guestComponentFocus = false;
            }, 200);
          }
          this.enterPressed = true;
        }
        if (this.currentPaxOption !== 3 && this.tourCriteria &&
          (this.tourCriteria.nights === undefined || this.tourCriteria.nights === 0)) {
          setTimeout(() => {
            this.guestComponentFocus = false;
          }, 200);
          this.enterPressed = false;
        }
      }
    }

    if ($event.keyCode === KeyCodes.UPARROW || $event.keyCode === KeyCodes.DOWNARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = false;
      }
    }

    if ($event.keyCode === KeyCodes.RIGHTARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = true;
      }
    }
  }

  /**
   * scroll to logic
   */
  scrollTo(selector) {
    if (!this.isDPSearch) {
      if (this.windowScrollEnable) {
        SurfScrollDirective.scrollTo(selector, null, false, false, 0, 60);
      }
    }
  }

  getCurrentOption($event) {
    this.currentPaxOption = $event;
  }

  closeCriteriaBox() {
    if (!this.isRefine && !this.isDPSearch) {
      this.commonService.changeMessage('TOU_close');
      this.commonService.updateComponentCriteria(null, TC.Product.TOURS);
    }
    if (this.isRefine) {
      this.cancelRefineSearch();
    }
  }

  cancelRefineSearch() {
    this.cancelRefine.emit(this.isRefine);
  }

  /**
   * move to dp flow when add another item from the component flow.
   */
  moveToDP(position: string) {
    if (!this.isDPSearch) {
      this.msg = 'DP_Switch~TOU~' + position;
    }
  }

  // todo when DP flow, update criteria using these methods
  /**
   * error validation with typeahead results
   */
  returnedSearchQueryForStops(event) {
    this.stopSearchInitialized = event && event.length >= 3;
    this.depPortPartiallyTyped = !!event;
  }

  /**
   * error validation with typeahead results
   */
  returnedSearchQueryForDepartureCity(event) {
    this.destSearchInitiated = event && event.length >= 3;
    this.depPortPartiallyTyped = !!event;
  }

  /**
   * onClickInsideDestTypeAhead selectedDestination
   * @param event
   */
  onClickInsideDestTypeAheadDepartureCity(event) {

    const tourCri = this.tourCriteria;
    if (tourCri) {
      if (tourCri.destinationType === DestinationType.CITY) {
        this.selectedDestination.displayText = tourCri.destinationName.trim();
      }
    }
  }

  /**
   * onClickInsideDestTypeAhead TourName
   * @param event
   */
  onClickInsideDestTypeAheadTourName(event) {

    const tourCri = this.tourCriteria;
    if (tourCri) {
      if ( this.tourCriteria.itineraryName ) {
        this.selectedTourName.displayText = this.tourCriteria.itineraryName.split(' ')[0].trim();
      }
    }
  }

  /**
   * onClickOutsideDestTypeAhead TourName
   * @param event
   */
  onClickOutsideDestTypeAheadTourName(event) {
    this.selectedTourName.displayText = this.tourCriteria?.itineraryName?.trim();
  }


  /**
   * onClickOutsideDestTypeAhead selectedDestination
   * @param event
   */
  onClickOutsideDestTypeAheadDepartureCity(event) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);

    const tourCri = this.tourCriteria;

    if (tourCri) {
      if (tourCri.destinationType === DestinationType.CITY && tourCri.destinationName && tourCri.destinationName !== 'undefined') {
        this.selectedDestination.displayText = tourCri.destinationName.trim() + ((tourCri.destinationParentCode &&
          !tourCri.destinationParentCode.includes('undefined')) ? ', ' + tourCri.destinationParentCode : '');
      }
      if (this.isRefine && tourCri.destinationType === DestinationType.COUNTRY && tourCri.destinationName
        && tourCri.destinationName !== 'undefined') {
        this.selectedDestination.displayText = surfTextCasePipe.transform(tourCri.destinationName, 'title').trim();
      }
    }
    if (this.isDPSearch || this.isAmendmentFlow) {
      this.validateInputsForDP();
    }
  }


  /**
   * error validation with typeahead results
   */
  returnedSearchQueryForTourName(event) {
    this.nameSearchInitialized = event && event.length >= 3;
    this.depPortPartiallyTyped = !!event;
  }

  /**
   * error validation with typeahead results
   */
  returnedSearchQueryForCategory(event) {
    this.categorySearchInitialized = event && event.length >= 3;
    this.depPortPartiallyTyped = !!event;
  }

  // ----------------------------------------------- typeahead data set to display list----------------------

  /**
   * criteria set to empty when no result, display hide
   */
  returnedStop(data) {
    if (data && data.length > 0) {
      this.filteredTourStop = data;
    } else {
      this.filteredTourStop = [];
      if (this.tourCriteria && this.stopOntologies && this.stopOntologies.length === 0) {
        this.tourCriteria.tourDestCities = null;
      }
    }
  }

  /**
   * criteria set to empty when no result, display hide
   */
  returnedDepartureCity(data) {
    if (data && data.length > 0) {
      this.filteredDestinationCity = data;
    } else {
      this.filteredDestinationCity = [];
      if (this.tourCriteria) {
        this.tourCriteria.destinationName = null;
        this.tourCriteria.destinationCode = null;
        this.tourCriteria.city = null;
        this.tourCriteria.country = null;
      }
    }
  }

  /**
   * criteria set to empty when no result, display hide
   */
  returnedTourName(data) {
    if (data && data.length > 0) {
      this.filteredTourName = data;
      // this.filteredTourName = this.tourCriteria.supplier ? data : data.filter(item => {
      //   const tourRadarAttr = item.attributes.find(attr => attr.name === 'TOUR_RADAR_SEARCH_AND_BOOK_ENABLED');
      //   return tourRadarAttr && (tourRadarAttr.value === 'yes');
      // });
    } else {
      this.filteredTourName = [];
      if (this.tourCriteria) {
        this.tourCriteria.itineraryName = null;
      }
    }
  }

  /**
   * criteria set to empty when no result, display hide
   */
  returnedTourCategory(data) {
    if (data && data.length > 0) {
      this.filteredTourCategory = data;
    } else {
      this.filteredTourCategory = [];
      if (this.tourCriteria && this.categoryOntologies && this.categoryOntologies.length === 0) {
        this.tourCriteria.tourCategory = null;
      }
    }
  }

  /**
   * add parent location details to child location object.
   */
  addParentToLocationGroup(locationGroup, city) {
    const parent = [];
    if (city) {
      parent.push({code: city.code, name: city.name, type: city.type});  /*add city*/
    }
    if (city.parent && city.parent[0]) {
      parent.push({code: city.parent[0].code, name: city.parent[0].name, type: city.parent[0].type});  /*add country*/
    }
    locationGroup.parent = parent;
    return locationGroup;
  }

  /**
   * set tour suppliers
   */
  selectTourSupplier(event, isForced = false) {
    if (event && event.code) {
      // update tourNameURL when supplier changes
      if (event.code === '-1') {
        this.tourNameURL = `${this.STATIC_DATA_URL_BASE}?dataTypes=TOUR_NAME&userInput=[UI]&aggrLevel=NA&locale=en&matchType=NAME&expand=all&resultCount=${this.STATIC_DATA_FETCH_SIZE}`;
      } else {
        // reset tour name if supplier changes
        if (event.code !== this.tourCriteria.supplier) {
          this.resetTourName();
        }
        this.tourNameURL = `${this.STATIC_DATA_URL_BASE}?dataTypes=TOUR_NAME&userInput=[UI]&aggrLevel=NA&locale=en&matchType=NAME&expand=all&resultCount=${this.STATIC_DATA_FETCH_SIZE}&parentType=${this.OPERATOR}&parentCode=${event.code}`;
      }

      this.tourSuppliers.forEach(line => {
        if (event.code !== this.NONE_SELECTED) {
          if (event.code === line.code) {
            line.selected = true;
            if (this.tourCriteria) {
              this.tourCriteria.supplier = event.code;
            }
          } else {
            line.selected = false;
          }
        } else {
          line.selected = false;
          if (this.tourCriteria) {
            this.tourCriteria.supplier = null;
          }
        }
      });
      if (isForced && this.tourSuppliers && this.tourSuppliers.length > 0) {
        this.tourSuppliers = JSON.parse(JSON.stringify(this.tourSuppliers));
        this.isRefineSupplier = true;
      }
      if (!this.isRefine && !this.isDPSearch) {
        this.commonService.updateComponentCriteria(this.tourCriteria, TC.Product.TOURS);
      }
    }
  }

  // -------------------------Date Picker logics ------------------------------------
  /**
   * get selected months from calendar month
   */
  getMonthSelection($event) {
    if ($event !== null && $event[0] !== '') {
      this.isDateNotSelected = false;
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_MONTH_VIEW;
      if (this.tourCriteria) {
        this.tourCriteria.startDate = null;
        this.tourCriteria.startMonth = this.surfCriteriaUtil.getMonthStr($event[0]);
        this.tourCriteria.startYear = this.surfCriteriaUtil.getYearStr($event[0]);
      }
      if (!this.isDPSearch ) {
        this.commonService.updateBaseCriteria(this.tourCriteria);
      }
      if ( this.isDPSearch ) {
        this.validateInputsForDP();
      }
    }
  }

  /**
   * get selected dates from calendar
   */
  getDateSelection($event) {
    if ($event) {
      this.isDateNotSelected = false;
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_DATE_VIEW;
      if (this.tourCriteria) {
        this.tourCriteria.startDate = this.surfCriteriaUtil.convertDateObjToStr($event[0]);
        this.tourCriteria.startMonth = null;
        this.tourCriteria.startYear = null;
      }
      if ( this.isDPSearch ) {
        this.validateInputsForDP();
      }
      if (!this.isDPSearch ) {
        this.commonService.updateBaseCriteria(this.tourCriteria);
      }
    } else {
      this.isDateNotSelected = true;
      if ( this.isDPSearch ) {
        this.validateInputsForDP();
      }
    }
  }

  /**
   * set multi field details
   */
  createMultiSelectCriteriaString(ontologies, isCommaSeparated = true) {
    let separator = SPLITTER.COMMA_SPLITTER;
    if (!isCommaSeparated) {
      separator = GENERAL_SEPARATORS.FIELD_SEPARATOR;
    }
    if (ontologies.length > 0) {
      let returningText = '';
      ontologies.forEach(ontology => {
        if (returningText.length > 0) {
          returningText += separator;
        }
        if (ontology && ontology.query) {
          returningText += ontology.query;
        } else {
          console.error('Error in setting data codes');
        }
      });
      return returningText;
    }
    return null;
  }

  // ------------------------------destination typeahhead --------------------------------
  /**
   * select data logic for stops
   */
  selectStops(data) {
    if (this.stopOntologies && this.stopOntologies.filter(ontology =>
      ontology.displayText === this.getOntologyDisplayText(data)).length === 0) {
      this.stopOntologies.push(this.createOntologyData(data, this.stopOntologies.length));
      this.setCalendarMinDate(data);
      this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, TC.Product.TOURS);
    }
    if ((data.type === 'CITY' || data.type === 40) && this.tourCriteria && this.stopOntologies && this.stopOntologies.length > 0) {
      this.tourCriteria.tourDestCities = this.createMultiSelectCriteriaString(this.stopOntologies);
    }
    this.selectedStop.displayText = null;
    this.filteredTourStop = [];

    this.stopSearchInitialized = false;
    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.tourCriteria, TC.Product.TOURS);
    }
    if ( this.isDPSearch ) {
      this.validateInputsForDP();
    }
  }

  /**
   * set destination names
   * @param ontologies
   */
  setDestinationNames(ontologies: any[]) {
    this.destNames = ontologies?.map(i => i?.displayText)?.join(',') || '';
  }

  /**
   * select data logic for stops
   */
  removeSelectedStop(event) {
    this.stopOntologies = this.stopOntologies.filter(ontology => ontology.displayText !== event.value);
    if (this.tourCriteria && this.stopOntologies) {
      this.tourCriteria.tourDestCities = this.createMultiSelectCriteriaString(this.stopOntologies);
    }
    if ( this.isDPSearch ) {
      this.validateInputsForDP();
    }
  }

  /**
   * select data logic for stops
   */
  selectDestination(data) {
    this.filteredDestinationCity = [];
    this.setCalendarMinDate(data);
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, TC.Product.TOURS);
    if (data.type === 'CITY' || data.type === 40) {
      this.selectedDestination.displayText = data.name +
        (this.surfCriteriaUtil.getCountry(data) ? ', ' + this.surfCriteriaUtil.getCountry(data) : '');
      if (this.tourCriteria) {
        this.tourCriteria.destinationType = DestinationType.CITY;
        this.tourCriteria.destinationParentCode = this.surfCriteriaUtil.getCountry(data);
        this.tourCriteria.city = data.code;
        this.tourCriteria.country = this.surfCriteriaUtil.getCountryCode(data);
      }
    } else {
      this.selectedDestination.displayText = data.name;
      if (this.tourCriteria) {
        this.tourCriteria.destinationType = DestinationType.COUNTRY;
        this.tourCriteria.country = data.code;
      }
    }
    this.selectedDestination.displayText = this.selectedDestination.displayText.trim();
    this.destSearchInitiated = false;
    if (this.tourCriteria) {
      this.tourCriteria.destinationCode = data.code;
      this.tourCriteria.destinationName = data.name;
    }

    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.tourCriteria, TC.Product.TOURS);
    }
  }

  /**
   * select data logic for stops
   */
  selectTourName(data) {
    this.filteredTourName = [];
    this.setCalendarMinDate(data);
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, TC.Product.TOURS);
    if (data.type === 210 || data.type === 220) {
      this.selectedTourName.displayText = data.name;
      if (this.tourCriteria) {
        this.tourCriteria.itineraryName = data.name;
      }
    }
    this.selectedTourName.displayText = this.selectedTourName.displayText.trim();
    this.nameSearchInitialized = false;

    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.tourCriteria, TC.Product.TOURS);
    }
  }

  /**
   * select data logic for tour category
   */
  selectTourCategory(data) {
    this.filteredTourCategory = [];
    if (this.categoryOntologies && this.categoryOntologies.filter(ontology =>
      ontology.displayText === this.getOntologyDisplayText(data, false)).length === 0) {
      this.categoryOntologies.push(this.createOntologyData(data, this.categoryOntologies.length, false));
      this.setCalendarMinDate(data);
      this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, TC.Product.TOURS);
    }
    if ((data.type === 230 || data.type === 240) && this.tourCriteria && this.categoryOntologies && this.categoryOntologies.length > 0) {
      this.tourCriteria.tourCategory = this.createMultiSelectCriteriaString(this.categoryOntologies, false);
    }
    this.selectedTourCategory.displayText = null;
    this.categorySearchInitialized = false;
    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.tourCriteria, TC.Product.TOURS);
    }
  }

  /**
   * remove category chip
   */
  removeSelectedTourCategory(event) {
    this.categoryOntologies = this.categoryOntologies.filter(ontology => ontology.displayText !== event.value);
    if (this.tourCriteria && this.categoryOntologies) {
      this.tourCriteria.tourCategory = this.createMultiSelectCriteriaString(this.categoryOntologies, false);
    }
  }

  /**
   * set calendar details
   *
   */
  setCalendarMinDate(data) {
    const currentTime = data.attributes.filter(attribute => attribute.name === 'current_time');
    if (currentTime.length > 0 && this.calendarMinDate > this.surfCriteriaUtil.getDateFromString(currentTime[0])) {
      this.calendarMinDate = new Date(currentTime[0].value);
    }
  }

  // --------------------------------------------------------------
  /**
   * Creating ontology data
   *
   */
  createOntologyData(data: any, index: number, isWithCode = false): OntologyResponse {
    const res = new OntologyResponse();
    if (data) {
      res.displayText = this.getOntologyDisplayText(data, isWithCode);
      res.query = data.code;
      res.index = index;
    }
    return res;
  }

  /**
   *  chip display
   */
  getOntologyDisplayText(data, isWithCode = false) {
    return (isWithCode) ? data.name + ' (' + data.code + ')' : data.name;
  }

  /**
   * validate all component inputs
   */
  validateInputs() {
    const isLastMinuteBookingValidationPass = this.surfCalendarStartDateService.lastMinuteBookingErrorSet.size <= 0;
    let isAllInputsPresent = false;
    if ((this.tourCriteria && this.tourCriteria.adult && !this.isDateNotSelected &&
      (this.tourCriteria.startDate || this.tourCriteria.startMonth && this.tourCriteria.startYear) &&
      this.tourCriteria.tourDestCities) || this.isGrabbed) {
      isAllInputsPresent = true;
    }
    if ((this.tourCriteria && this.isDPSearch && !this.isDateNotSelected &&
      (this.tourCriteria.startDate || this.tourCriteria.startMonth && this.tourCriteria.startYear) &&
      this.tourCriteria.tourDestCities)) {
      isAllInputsPresent = true;
    }
    if (this.isGrabbed && this.displayingGrabbedTourDetailList && this.displayingGrabbedTourDetailList &&
      this.displayingGrabbedTourDetailList.filter(displayData => displayData.isBlockedResult && displayData.isOpenToShow).length > 0) {
      isAllInputsPresent = false;
    } else if (this.isGrabbed && this.displayingGrabbedTourDetailList && this.displayingGrabbedTourDetailList &&
      this.displayingGrabbedTourDetailList.filter(displayData => displayData.isBlockedResult && displayData.isOpenToShow).length === 0) {
      isAllInputsPresent = true;
    } else if ( this.isDpGrab && !this.grabBlockDetails?.code) {
      isAllInputsPresent = true;
    }
    if (this.isAmendmentFlow) {
      return isLastMinuteBookingValidationPass && isAllInputsPresent;
    } else {
      return isAllInputsPresent;
    }
  }

  /**
   * validate duration input value
   * @duration
   */
  validateDuration(duration: string) {
    if (duration !== null) {
      const value = duration.toString().replace(/[^0-9]*/g, '');
      if (value === duration) {
        if (parseInt(duration, 10) > 0) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  // ---------------------------- Reset values -------------------------------------
  /**
   * Upon clicking the reset/cancel button, all the input fields and corresponding
   * values in the criteria object will be reset
   */
  resetInputs(isRefine?: boolean) {
    if (!isRefine) {
      this.isGrabbed = false;
      this.resetDisplayItems();
      this.setMultiFieldInputProperties();
    } else {
      this.cancelRefineSearch();
    }
    if (this.isServiceAddComponent) {
      this.cancelRefine.emit(true);
    }
  }

  resetDestination() {
    this.cleanOntology(this.selectedDestination);
    if (this.tourCriteria) {
      this.tourCriteria.destinationName = '';
      this.tourCriteria.destinationCode = null;
      this.tourCriteria.city = null;
      this.tourCriteria.country = null;
    }
  }

  resetTourName() {
    this.cleanOntology(this.selectedTourName);
    if (this.tourCriteria) {
      this.tourCriteria.itineraryName = null;
    }
  }

  resetStops() {
    this.cleanOntology(this.selectedStop);
    this.stopOntologies = [];
    if (this.tourCriteria) {
      this.tourCriteria.tourDestCities = null;
    }
  }

  resetDate() {
    this.resetCalendar = true;   // todo: try this without timeout using this.resetCalendar = !this.resetCalendar
    setTimeout(() => {
      this.resetCalendar = false;
    }, 0);
    if (this.tourCriteria) {
      this.tourCriteria.startDate = null;
      this.tourCriteria.startMonth = null;
      this.tourCriteria.startYear = null;
    }
    this.initialDates = [];
  }

  resetCategory() {
    this.cleanOntology(this.selectedTourCategory);
    this.categoryOntologies = [];
    if (this.tourCriteria) {
      this.tourCriteria.tourCategory = null;
    }
  }

  resetTourSupplier() {
    this.tourSuppliers.forEach(ele => {
      ele.selected = ele.code === this.NONE_SELECTED;
    });
    this.tourSuppliers = JSON.parse(JSON.stringify(this.tourSuppliers));
    if (this.tourCriteria) {
      this.tourCriteria.supplier = null;
    }
  }

  resetDuration() {
    if (this.tourCriteria) {
      this.tourCriteria.durationRange = null;
    }
  }

  resetDisplayItems() {
    this.grabbedTourId = '';
    this.defaultPaxSet = [];
    this.stopSearchInitialized = false;
    this.destSearchInitiated = false;
    this.nameSearchInitialized = false;
    this.categorySearchInitialized = false;
    this.resetDate();
    this.resetDuration();
    this.resetDestination();
    this.resetStops();
    this.resetCategory();
    this.resetTourSupplier();
    this.resetTourName();
  }

  cleanOntology(ontology: OntologyResponse) {
    ontology.displayText = '';
    ontology.index = 0;
    ontology.query = '';
  }

  // ---------------------------------------------------------------------
  /**
   * set properties for duration selection multi-field-input
   */
  setMultiFieldInputProperties() {
    this.durationProperty = [];
    this.durationValues = [];

    this.durationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true, 'Any', false, false, 'number', '', 'durationValue', ''));
    this.durationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(
      true,
      '',
      false,
      true,
      'text',
      '',
      'durationType',
      ''
    ));

    if (this.isRefine || this.isGrabbed || ( ( this.isAmendmentFlow || this.isDpGrab || this.isEpToDpConversion ) && this.tourCriteria && this.tourCriteria.durationRange )) {
      let currentDurationArr;
      if (this.tourCriteria && this.tourCriteria.durationRange) {
        currentDurationArr = this.tourCriteria.durationRange.split(GENERAL_SEPARATORS.FIELD_SEPARATOR);
      }

      this.durationValues.push(new MultiFieldInputData('', (currentDurationArr && currentDurationArr[1]) ? currentDurationArr[1] : ''));
    } else {
      this.durationValues.push(new MultiFieldInputData('', ''));
    }

    this.durationValues.push(new MultiFieldInputData(this.DURATION_CATEGORIES.code, this.DURATION_CATEGORIES.value));
  }
  getNewDurationValue(data) {
    const durationValues = [];
    durationValues.push(new MultiFieldInputData('', data ? data : ''));
    durationValues.push(new MultiFieldInputData(this.DURATION_CATEGORIES.code, this.DURATION_CATEGORIES.value));
    return durationValues;
  }

  /**
   *  set duration
   */
  setDuration(event) {
    if (event && event[0] && event[0].value && this.tourCriteria) {
      this.tourCriteria.durationRange = event[0].value + GENERAL_SEPARATORS.FIELD_SEPARATOR + event[0].value;
      this.durationError = !this.validateDuration(event[0].value);
    } else {
      if (this.tourCriteria) {
        this.tourCriteria.durationRange = null;
      }
    }
  }

  /**
   * Construct the tour search URL and redirect to the results page
   */
  searchTour(isExternal?) {
    const basePath = '/';
    if (this.isServiceAddComponent && this.tourCriteria) {
      const criteriaUrl = basePath + 'b2b-tours/?' + this.keyParams + this.getTourSearchUrlParams();
      this.getUrlCriteria.emit({url: criteriaUrl, isExternal});
    } else {
      if (this.tourCriteria) {
        window.location.href = basePath + 'b2b-tours/?' + this.keyParams + this.getTourSearchUrlParams();
      }
    }
  }

  /**
   * create URL parameters
   */
  getTourSearchUrlParams() {
    if (this.stopOntologies?.length) {
      this.setDestinationNames(this.stopOntologies);
    }
      if (this.tourCriteria.adult) {
          this.tourCriteria.adult = this.tourCriteria.adult.replace(/DOB/g, ''); // removing DOB from adult
      }
      // get child guest related params
      let childParams = '';
      if (this.tourCriteria.teen && this.tourCriteria.teen.length > 0) {
          this.tourCriteria.teen = this.tourCriteria.teen.replace(/DOB/g, ''); // removing DOB from child
          childParams = '&child=' + this.tourCriteria.teen;
      }
      if (this.tourCriteria.child && this.tourCriteria.child.length > 0) {
          if (childParams.toString().includes('&child')) {
              this.tourCriteria.child = this.tourCriteria.child.replace(/DOB/g, ''); // removing DOB from child
              childParams = childParams + this.AGE_SEPERATOR + this.tourCriteria.child;
          } else {
              this.tourCriteria.child = this.tourCriteria.child.replace(/DOB/g, ''); // removing DOB from child
              childParams = '&child=' + this.tourCriteria.child;
          }
      }
      if (this.tourCriteria.infant && this.tourCriteria.infant.length > 0) {
          childParams += '&infant=' + this.tourCriteria.infant;

      }
      const loginUserToday = new Date();
      if (this.isGrabbed) {
          loginUserToday.setDate(loginUserToday.getDate() + 7);
      }
      let returningQueryString = new StringConcatenationClass()
          .appendString('&adult=' + this.tourCriteria.adult + childParams, !!(this.tourCriteria.adult + childParams))
          .appendString('&tourDestCities=' + this.tourCriteria.tourDestCities, !!this.tourCriteria.tourDestCities)
          .appendString('&city=' + this.tourCriteria.city, !!this.tourCriteria.city)
          .appendString('&startDate=' + this.tourCriteria.startDate, !!this.tourCriteria.startDate
              && !this.tourCriteria.startMonth && !this.isGrabbed)
          .appendString('&startDate=' + this.surfUtilService.getDateString(loginUserToday), this.isGrabbed)
          .appendString('&durationType=' + 'D', true)
          .appendString('&startMonth=' + this.tourCriteria.startMonth, !!this.tourCriteria.startMonth)
          .appendString('&startYear=' + this.tourCriteria.startYear, !!this.tourCriteria.startYear)
          .appendString('&bookingDate=' + this.tourCriteria.bookingDate, !!this.tourCriteria.bookingDate)
          .appendString('&durationRange=' + this.tourCriteria.durationRange, !!this.tourCriteria.durationRange)
          .appendString('&supplier=' + this.tourCriteria.supplier, !!this.tourCriteria.supplier)
          .appendString('&privateTour=' + this.tourCriteria.privateTour, !!this.tourCriteria.privateTour)
          .appendString('&sourceInfo=' + this.tourCriteria.sourceInfo, !!this.tourCriteria.sourceInfo)
          .appendString('&sources=' + this.tourCriteria.sources, !!this.tourCriteria.sources && this.isGrabbed)
          .appendString(
              '&itineraryName=' + this.surfUtilService.encodeSpecialCharacters(this.tourCriteria.itineraryName, '&'),
              !!this.tourCriteria.itineraryName
          )
          .appendString('&destNames=' + this.destNames, !!this.destNames)
          .appendString('&destinationName=' + this.tourCriteria.destinationName, !!this.tourCriteria.destinationName)
          .appendString('&destinationParentCode=' + this.tourCriteria.destinationParentCode, !!this.tourCriteria.destinationParentCode)
          .appendString('&tourCategory=' + this.tourCriteria.tourCategory, !!this.tourCriteria.tourCategory).getString();

      returningQueryString += '&expand=all';

      return returningQueryString;
  }

  /**
   * set refine search attributes
   */
  private setRefineDate() {
    if (this.tourCriteria.startMonth && this.tourCriteria.startYear) {
      const month = this.tourCriteria.startMonth;
      const year = this.tourCriteria.startYear;
      this.initialDates = [this.createDummyDateUsingMonthAndYear(year, month)];
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_MONTH_VIEW;
    } else if (this.tourCriteria.startYear) {
      const year = this.tourCriteria.startYear;
      this.initialDates = [this.createDummyDateUsingYear(year)];
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_YEAR_VIEW;
    } else if (this.tourCriteria && this.tourCriteria.startDate) {
      this.initialDates = [this.surfUtilService.getDateFromString(this.tourCriteria.startDate)];
      this.defaultActiveCalendar = CALENDAR_VIEW.SINGLE_DATE_VIEW;
    }
    if (this.tourCriteria && this.tourCriteria.startDate) {
      this.travellerEarliestDepartureDateService
          .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.tourCriteria.startDate);
      if (this.isAmendmentFlow) {
        this.doCheckLastMinuteBookingDate(true);
      }
    }
  }

  /**
   * create dummy date to set moth date picker
   */
  createDummyDateUsingMonthAndYear(year, month) {
    if (year && month && year > 0 && month > 0) {
      return new Date(year, month - 1, 1);
    }
    return new Date();
  }

  /**
   * create dummy date to set year date picker
   */
  createDummyDateUsingYear(year) {
    if (year && year > 0) {
      return new Date(year, 0, 1);
    }
    return new Date();
  }

  convertStrToDateObj(str) {
    if (str) {
      const dates = str.split('-');
      const  formattedDate = new Date(dates[0], dates[1] - 1, dates[2]);
      return formattedDate;
    }
    return  '';
  }

  tourGrabResultOutput(event) {
    if (event && event[0]) {
      this.grabbedTourId = '';
      this.stopSearchInitialized = false;
      this.destSearchInitiated = false;
      this.nameSearchInitialized = false;
      this.categorySearchInitialized = false;
      this.defaultPaxSet = [];
      this.resetDuration();
      this.resetDestination();
      this.resetStops();
      this.resetCategory();
      this.resetTourSupplier();
      this.resetTourName();
      this.isGrabbed = true;
      if (event[0].grabbedTourId) {
        this.grabbedTourId = event[0].grabbedTourId;
      }
      if (event[0].sourceInfo) {
        this.tourCriteria.sourceInfo = event[0].sourceInfo;
      }
    }
    this.displayingGrabbedTourDetailList = event;
    if (this.displayingGrabbedTourDetailList && this.displayingGrabbedTourDetailList.length > 0) {
      this.displayingGrabbedTourDetailList.forEach(item => {
        if (item && item.durationRange) {
          item.durationValue = this.getNewDurationValue(item.durationRange);
        }
        item.isOpenToShow = true;
      });
    }
    console.log(event);
    console.log(this.tourCriteria);
  }
  removeIfBlockedGrabbedResult(grabTourItem) {
    if (grabTourItem.isBlockedResult) {
      grabTourItem.isOpenToShow = false;
    }
    if (this.displayingGrabbedTourDetailList && this.displayingGrabbedTourDetailList.length ===
      this.displayingGrabbedTourDetailList.filter(result => result && result.isOpenToShow === false).length) {
      this.isGrabbed = false;
      this.resetDisplayItems();
    }
  }

  /**
   * handle freeze-unfreeze checkbox
   */
  checkValue(event) {
    this.unfreeze = event.target.checked;
    this.freeze = !this.unfreeze;
    this.isUnfreeze.emit(event.target.checked);
  }

  doCheckLastMinuteBookingDate(isAmendmentOldItemDateSelection: boolean) {
    const checkingDate = new Date(this.tourCriteria.startDate);
    if (this.surfCalendarStartDateService.validateWithCheckingIsEarlierDate(new Date(), checkingDate, 'TOU')) {
      this.hideErrorMsg = true;
      this.validateDisabledDateSelection = false;
      this.surfCalendarStartDateService.lastMinuteBookingErrorSet.delete(this.index);
      if (isAmendmentOldItemDateSelection) {
        this.surfCalendarStartDateService.hasValidUnfreezeItem = true;
      }
    } else {
      if (isAmendmentOldItemDateSelection) {
        this.surfCalendarStartDateService.hasValidUnfreezeItem = false;
      }
      this.surfCalendarStartDateService.lastMinuteBookingErrorSet.add(this.index);
      this.hideErrorMsg = false;
      this.validateDisabledDateSelection = true;
    }
  }
}
