import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ConfigService,
  DataShareService,
  DestinationType,
  GenericCriteria,
  KeyCodes,
  OntologyResponse, SurfCalendarStartDateService, SurfCityPipe,
  SurfCriteriaUtil, SurfErrorMessages,
  SurfScrollDirective,
  SurfTextCasePipe, SurfTravellerEarliestDepartureDateService,
  SurfUtilService
} from '@surf/surf-components-core';
import {
  MultiFieldInputData,
  MultiFieldInputProperty,
  MultiFieldInputPropertyFactory
} from '@surf/surf-multi-field-input';
import { PaxAllocationItem } from '@surf/surf-pax-selection';
import { DataServiceHandler } from '@tc-core/service/service-handlers';
import { TC } from '@tc-core/util';
import { ConfigLoader, DataKey, DataStore } from '@tc-core/util/framework';
import { Subscription } from 'rxjs';
import {MasterDataStoreService, StoreConstants} from '@surf/surf-state-manage';

@Component({
  selector: 'surf-generic-criteria',
  templateUrl: './generic-criteria.component.html',
  styleUrls: ['./generic-criteria.component.css']
})
export class GenericCriteriaComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() isDPSearch = false;
  @Input() index: number;
  @Input() isRefine = false;
  @Input() windowScrollEnable = true;
  @Input() selectedPax: any;
  @Input() originalRefineCriteria: any;
  @Input() selectedElementGrpCode;
  @Input() dpGenDestinations: any;
  // new impl
  @Input() dpEnableDate: any;
  @Input() dpInitialDateStr: any;
  @Input() dpInitialDestStr: any;
  @Input() dpGenericRefineCriteria: any; // used for dp dashboard and dp refine flow
  @Input() isServiceAddComponent = false;
  @Input() cartPaxSelection: any;
  @Input() unfreeze = false;
  @Input() isAmendmentFlow = false;
  @Input() isItemCloseable = true;
  @Input() oldCriteriaItem;
  @Input() oldDPDateStr;
  @Input() oldDPElementGrpCode;
  @Input() oldDPCategoryName;
  @Input() oldDPDestStr;
  dpCalEnableDate: Date;

  // related to amendment flow
  @Input() bookingId: string;
  @Input() productKey: string;
  @Input() amendmentReasonCode = null;
  @Input() amendmentCauseCode = null;
  @Input() paxSplitArray = [];
  @Input() enablePaxSplit = false;
  @Input() triggerExternalSearch = '';
  @Input() paxTypeAges = [];
  @Input() cachingItemMap = new Map<string, number>();
  @Input() allowNgrxCaching = false;
  @Input() enableRecommendLocations = true;
  @Input() recommendingLocations;

  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyDownLast = new EventEmitter<boolean>();
  @Output() getCurrentGenericCriteria = new EventEmitter<GenericCriteria>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isUnfreeze = new EventEmitter<boolean>();
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter();
  @Output() isPaxSplitChanged = new EventEmitter<boolean>();

  DEFAULT_ADULT_AGE = 30;
  DEFAULT_OFFSET = 10;
  GEN_MAX_CABIN_PAX_COUNT = 20;
  GEN_MAX_ADULT = 20;
  GEN_MIN_ADULT = 1;
  GEN_MAX_PAX_COUNT = 40;
  MAX_INFANT_AGE = 23;
  MAX_CHILD_AGE = 12;
  MAX_TEEN_AGE = 17;
  TEEN_PAX_ACTIVE = true;
  GEN_ENABLE_ACTIVITY_NAME_FILTERING_BY_LOCATION = true;
  GEN_MAX_CHILD = this.GEN_MAX_CABIN_PAX_COUNT - 1;
  GEN_MAX_TEEN = this.GEN_MAX_CABIN_PAX_COUNT - 1;
  GEN_MAX_INFANTS = this.GEN_MAX_CABIN_PAX_COUNT - 1;
  DISABLE_DP_SWITCH = false;
  STATIC_DATA_URL_BASE = '/data/data-service/v2/static-data';
  STATIC_DATA_FETCH_SIZE = 20;
  GEN_SEARCH_RESULT_COUNT: string;

  locationUrl = '';
  activityUrl = '';
  resetCalendar = false;
  durationProperty: MultiFieldInputProperty[];
  durationValues = [];
  durationError = false;
  selectedDate: Date;

  elementGroups = [];
  dpElementGroups = [];

  MIN_SEARCH_QUERY_LENGTH = 3;
  isFilterListError = false;

  isQueryError = false;
  FIELD_SEPARATOR = '~';
  AGE_SEPARATOR = '__';

  LESS_THAN_ONE = '< 1';
  CALENDAR_RANGE = 2;
  defaultPaxSet: Array<PaxAllocationItem> = [];
  datePickerFocus = false;

  enterPressed = false;

  genericCriteria: GenericCriteria = new GenericCriteria();
  productCriteriaList = [];
  moduleConfigs: any;
  keyParams: string;
  destinations = [];
  citiesLoaded = false;
  countriesLoaded = false;

  cityCode: string;
  guestComponentFocus = false;
  currentPaxOption = 0;
  initialDates = [];
  currentDate = new Date();
  triggerChange: boolean;
  showTypeAheadContent = true;
  selectedDest: OntologyResponse = new OntologyResponse();
  selectedActivity: OntologyResponse = new OntologyResponse();
  destSearchInitiated = false;
  activitySearchInitiated = false;
  filteredList = [];
  filteredActivityList = [];
  DURATION_CATEGORIES = [{code: 'D', value: 'Days', selected: true}];
  DURATION_ACCEPTABLE_CATEGORIES = [];
  initFocus = false;
  paxDisplay: string;
  subscriptions = new Subscription();
  isDurationDisabled = true; //enables the duration field while making it mandatory
  isDurationEnabledAll = false; //enables duration field for all activities
  freeze: boolean; // enable/disable all input fields during amendment search
  locationWarning = false;
  activityWarning = false;
  dateWarning = false;
  typeWarning = false;
  calendarMinDate: Date = new Date();
  paxSplitDisplayText = 'All Guests';
  ENABLE_PAX_SPLIT = false;
  ENABLE_GEN_NFR = false;
  EP_PRICE_ADJ_ELEMENT_GROUP_CODE = '';
  hideErrorMsg = true;
  validateDisabledDateSelection = false;
  SURF_ERROR_MESSAGES = SurfErrorMessages;
  enableGenericRecommendation = false;
  showRecommendedContent = false;

  constructor(protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected dataServiceHandler: DataServiceHandler,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService,
              protected surfUtilService: SurfUtilService,
              protected masterDataStoreService: MasterDataStoreService) {
    this.subscriptions.add(
      this.commonService.getProductCriteriaList().subscribe(list => {
        this.productCriteriaList = list;
      }));
  }

  ngOnInit() {
    let surfTextCasePipe = new SurfTextCasePipe(this.configService);
    this.loadConfigs();
    if (!this.paxTypeAges || this.paxTypeAges.length === 0) {
      this.loadPaxTypeAges();
    } else {
      this.mapPaxSubTypeAges();
    }
    this.subscriptions.add(
      this.commonService.getCurrentMsg().subscribe(msg => {
        if (msg != undefined && msg.split('_')[0] === 'resetDest') {
          let indices = msg.split('_')[1].split('~');
          if (indices && indices.length > 0) {
            indices.forEach(i => {
              if (i == this.index.toString()) {
                let changeParam = msg.split('_')[2];
                if (changeParam) {
                  this.selectedDest.displayText = '';
                  setTimeout(() => {
                    this.selectedDest.displayText = changeParam;
                    this.validateInputsForDP();
                  }, 100);
                  if (this.isAmendmentFlow && !this.isItemCloseable) {
                    this.checkLocationChange(null);
                  }
                }
              }
            });
          }
        }
      }));
    this.durationProperty = [];
    this.durationValues = [];
    this.durationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(true, 'Any No.', false, false, 'text', '' , 'durationValue', ''));
    this.durationValues.push(new MultiFieldInputData('',''));

    this.durationProperty.push(MultiFieldInputPropertyFactory.getDropdownProperties(false, '', true, false, 'button', 'durationType', ''));
    this.durationValues.push([]);
    this.DURATION_CATEGORIES.forEach( category => {
      this.durationValues[1].push(new MultiFieldInputData(category.code, category.value, category.selected));
    });

    if (!this.isDPSearch || this.isAmendmentFlow) {
      // getComponentCriteria is used only in component flow
      this.subscriptions.add(
        this.commonService.getComponentCriteria('GEN').subscribe(cri => {
          this.genericCriteria = cri as GenericCriteria;
        }));
    }
    if (this.dpGenericRefineCriteria) {
      this.genericCriteria = this.dpGenericRefineCriteria;
      if (this.isAmendmentFlow && this.cartPaxSelection) {
        this.getPassengerSelection(this.cartPaxSelection);
      }
    }

    this.subscriptions.add(
      this.commonService.getKeyParamString().subscribe(keyParams => {
        // update resultsCount
        if (this.GEN_SEARCH_RESULT_COUNT && this.GEN_SEARCH_RESULT_COUNT.length > 0) {
          const tempList = keyParams.split('resultsCount=');
          if (tempList[1]) {
            const sliceIndex = tempList[1].indexOf('&') >= 0 ? tempList[1].indexOf('&') : tempList[1].length;
            tempList[1] = this.GEN_SEARCH_RESULT_COUNT + tempList[1].slice(sliceIndex);
          } else {
            tempList[1] = this.GEN_SEARCH_RESULT_COUNT;
          }
          keyParams = tempList.join('resultsCount=');
        }
        this.keyParams = keyParams;
      }));

    this.subscriptions.add(
      this.commonService.getCurrentMsg().subscribe(msg => {
        if (msg == 'triggerReset') {
          this.resetInputs();
        }
      }));
/*    this.showRecommendedContent = true;*/
    if (this.genericCriteria && this.genericCriteria.destinationName && this.genericCriteria.destinationName !== 'undefined') {
      if (this.isRefine || this.isDPSearch || this.isAmendmentFlow) {
        let destName = this.genericCriteria.destinationName;
        let destParent = this.genericCriteria.destinationParentCode;
        this.selectedDest.displayText = destParent && destParent != 'undefined' ? destName + ', ' + destParent : destName;
        if (this.selectedDest.displayText.split(',').length > 2) {
          this.selectedDest.displayText = this.selectedDest.displayText.split(',')[0] + ', ' + this.selectedDest.displayText.split(',')[1];
        }
        this.selectedDest.displayText = this.selectedDest.displayText.trim();
      }
      this.dpInitialDestStr = this.selectedDest.displayText;
    } else {
      this.selectedDest.displayText = "";
    }

    if (this.genericCriteria.categoryName) {
      this.selectedActivity.displayText = this.genericCriteria.categoryName;
    }

    if (this.genericCriteria.elementGroup) {
      this.selectedElementGrpCode = this.genericCriteria.elementGroup;
    }

    if (this.isRefine || ((this.isDPSearch || this.isAmendmentFlow) && this.genericCriteria)) {

      this.setInitialDates(this.genericCriteria.startDate);
      this.setInitialDuration(this.genericCriteria.duration);
      if (this.isDPSearch || this.isAmendmentFlow) {
        this.validateInputsForDP();
      }
    }
    if (this.genericCriteria && this.genericCriteria.elementGroup) {
      this.isDurationDisabled = this.handleDurationTypes(this.genericCriteria.elementGroup);
    }
    this.loadElementGroups();
    if (!this.selectedElementGrpCode && !this.genericCriteria.elementGroup) {
      this.selectedElementGrpCode = '-1';
      this.genericCriteria.elementGroup = this.selectedElementGrpCode;
      this.resetElementGroup();
    }
    this.checkInitialChanges();
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkLocationChange(this.genericCriteria);
      this.checkActivityTypeChange(true);
      this.checkActivityNameChange();
    }
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'GEN');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unfreeze && this.unfreeze !== undefined) {
      this.freeze = !this.unfreeze;
    }
    if (this.genericCriteria && this.genericCriteria.elementGroup) {
      this.isDurationDisabled = this.handleDurationTypes(this.genericCriteria.elementGroup);
    }
    if (changes['selectedPax']) {
      this.selectedPax = changes['selectedPax'].currentValue;
      if (this.isRefine) {
        this.defaultPaxSet = this.selectedPax;
      }
      if (this.isServiceAddComponent) {
        const adults = this.selectedPax.reduce( (acc, pax ) => acc + pax.adult , 0 ) ;
        const child = this.selectedPax.reduce( (acc, pax) => acc + pax.child.length , 0) ;
        const teen = this.selectedPax.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
        const infant = this.selectedPax.reduce( (acc, pax) => acc + pax.infant.length , 0 ) ;
        this.paxDisplay = this.generateDisplayString(adults, child, infant, teen) ;
      }
    }

    if (changes['paxSplitArray']) {
      const selectedPaxArray = this.paxSplitArray.filter(pax => {
        return pax.selected;
      });
      if (selectedPaxArray && selectedPaxArray.length === this.paxSplitArray.length) {
        this.paxSplitDisplayText = 'All Guests';
      } else {
        this.paxSplitDisplayText = selectedPaxArray.length.toString() + (selectedPaxArray.length === 1 ? ' Guest' : ' Guests');
      }
    }

    if (changes['dpEnableDate']) {
      this.dpEnableDate = changes['dpEnableDate'].currentValue;
      if (this.dpEnableDate) {
        if (this.isDPSearch && !this.isAmendmentFlow) {
          this.dpCalEnableDate = new Date();
        } else {
          this.dpCalEnableDate = new Date(this.dpEnableDate);
        }
      } else {
        this.dpCalEnableDate = new Date();
      }
      this.validateInputsForDP();
    }

    if (changes['dpInitialDateStr']) {
      this.dpInitialDateStr = changes['dpInitialDateStr'].currentValue;
      if (this.dpInitialDateStr) {
        this.setInitialDates(this.dpInitialDateStr);
        this.validateInputsForDP();
      }
    }

    if (changes['dpInitialDestStr']) {
      this.dpInitialDestStr = changes['dpInitialDestStr'].currentValue;
        // this.selectedDest.displayText = this.dpInitialDestStr;
      // if (this.dpInitialDestStr != 'undefined, undefined') {
      //   this.selectedDest.displayText = '';
      //   setTimeout(() => {
      //     this.selectedDest.displayText = this.dpInitialDestStr;
      //     this.validateInputsForDP();
      //   }, 100);
      // } else if (this.dpInitialDestStr === 'undefined, undefined') {
      //   this.selectedDest.displayText = '';
      // }
    }

    if (changes['index'] && changes['index'].currentValue  != undefined) {
      this.index = changes['index'].currentValue;
      if( changes['index'].previousValue != undefined && this.dpElementGroups && this.dpElementGroups[changes['index'].previousValue]){
        let elmGrp = JSON.parse(JSON.stringify(this.dpElementGroups[changes['index'].previousValue]));
        this.dpElementGroups = [];
        this.dpElementGroups[this.index] = elmGrp;
      }
    }

    if (changes.cartPaxSelection && this.cartPaxSelection){
      const adults = this.cartPaxSelection.reduce( (acc, pax ) => acc + pax.adult , 0 );
      const child = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.child.length , 0);
      const teen = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.teen.length , 0);
      const infant = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.infant.length , 0 );
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getPassengerSelection(this.cartPaxSelection);
    }

    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === 'GEN') {
      this.searchGenric(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }
  }

  ngAfterViewInit() {
    console.log('after view init');
    this.initFocus = true;
  }

  public ngOnDestroy(): void {
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }
  }

  showContent() {
    this.showRecommendedContent = true;
  }

  private loadElementGroups() {
    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_ELEMENT_GROUPS) !== null
      && this.cachingItemMap.get(StoreConstants.CACHE_ELEMENT_GROUPS) > 0)) {
      this.getElementGroupsFromCache(this.cachingItemMap.get(StoreConstants.CACHE_ELEMENT_GROUPS));
    } else {
      this.subscriptions.add(
        this.dataServiceHandler.getElementGroups().subscribe(
          res => {
            if (res) {
              this.mapElementGroups(res);
            }
          },
          error => {
            console.log('Error in getting activity types');
          }
        ));
    }
  }
  private getElementGroupsFromCache(timeout: number) {
    this.masterDataStoreService.getElementGroups(timeout).subscribe(
      res => {
        if (res) {
          this.mapElementGroups(res);
        }
      },
      error => {
        console.log('Error in getting element groups');
      });
  }

  private mapElementGroups(res: any) {
    const elementGroups = this.updateEleGroups(res);
    elementGroups.forEach(eleGrp => {
      this.elementGroups.push(eleGrp);
      if (this.isRefine && (this.genericCriteria.elementGroup === eleGrp.code)) {
        this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
      }
    });
    if (this.isDPSearch || this.isAmendmentFlow) {
      this.dpElementGroups[this.index] = JSON.parse(JSON.stringify(this.elementGroups));
    }
  }
  loadPaxTypeAges() {
    this.subscriptions.add(
      this.dataServiceHandler.getPaxSubTypeAges().subscribe(
        res => {
          if (res == null || !(Array.isArray(res)) || res.length === 0) {
            console.log('Could not fetch Pax Type Ages');
            return;
          }

          this.paxTypeAges = res[0].paxTypes;
          this.mapPaxSubTypeAges();
        },
        error => {
          console.log('Error in getting pax sub type ages');
        }
      ));
  }
  private mapPaxSubTypeAges() {
    this.MAX_CHILD_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'C').age.upper / 12);
    this.MAX_TEEN_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'T').age.upper / 12);
    this.MAX_INFANT_AGE = this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
  }
  loadConfigs(){
    this.STATIC_DATA_URL_BASE = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA]? this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA]: this.STATIC_DATA_URL_BASE;
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();

    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'MIN_SEARCH_QUERY_LENGTH':
            this.MIN_SEARCH_QUERY_LENGTH = mc.name;
            break;
          case 'MIN_ADULT':
            this.GEN_MIN_ADULT = mc.name;
            break;
          case 'MAX_ADULT':
            this.GEN_MAX_ADULT = mc.name;
            break;
          case 'MAX_PAX_COUNT':
            this.GEN_MAX_PAX_COUNT = mc.name;
            break;
          case 'MAX_CABIN_PAX_COUNT':
            this.GEN_MAX_CABIN_PAX_COUNT = mc.name;
            break;
          case 'DEFAULT_ADULT_AGE':
            this.DEFAULT_ADULT_AGE = mc.name;
            break;
          case 'DEFAULT_CALENDAR_OFFSET':
            this.DEFAULT_OFFSET = mc.name;
            break;
          case 'CALENDAR_RANGE':
            this.CALENDAR_RANGE = mc.name;
            break;
          case 'DISABLE_DP_SWITCH':
            this.DISABLE_DP_SWITCH = (mc.name === 'true');
            break;
          // case 'MAX_CHILD_AGE':
          //   this.MAX_CHILD_AGE = +mc.name;
          //   break;
          // case 'MAX_INFANT_AGE':
          //   this.MAX_INFANT_AGE = +mc.name;
          //   break;
          case 'GENERIC_DURATION_CATEGORIES':
            this.setDurationCategories(mc.name);
            break;
          case 'STATIC_DATA_FETCH_SIZE':
            this.STATIC_DATA_FETCH_SIZE = mc.name;
            break;
          case 'GEN_SEARCH_RESULT_COUNT':
            this.GEN_SEARCH_RESULT_COUNT = mc.name;
            break;
          case 'GENERIC_DURATION_CATEGORIES_ACCEPTABLE_TYPES':
            this.setDurationCategoriesAcceptableTypes(mc.name);
            break;
          case 'TEEN_PAX_ACTIVE':
            this.TEEN_PAX_ACTIVE = ((mc.name as string) === 'true');
            break;
          case 'GEN_ENABLE_ACTIVITY_NAME_FILTERING_BY_LOCATION':
            this.GEN_ENABLE_ACTIVITY_NAME_FILTERING_BY_LOCATION = ((mc.name as string).toUpperCase() === 'TRUE');
            break;
          case 'ENABLE_ACTIVITY_LOCATION_RECOMMENDATION':
            this.enableGenericRecommendation = ((mc.name) ? mc.name.toUpperCase() === 'YES' : false);
            break;
        }
      });
    }
    this.ENABLE_GEN_NFR = this.configLoader.getModuleConfig('ENABLE_GEN_NFR',TC.MODULE_NAME.SURF_B2B).toString().toUpperCase() === 'TRUE';
    if (this.configLoader.getModuleConfig('EP_PRICE_ADJ_ELEMENT_GROUP_CODE',TC.MODULE_NAME.SURF_B2B)) {
      this.EP_PRICE_ADJ_ELEMENT_GROUP_CODE = this.configLoader.getModuleConfig('EP_PRICE_ADJ_ELEMENT_GROUP_CODE',TC.MODULE_NAME.SURF_B2B);
    }
    const ENABLE_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('ENABLE_DP_PASSENGER_SPLIT', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_PAX_SPLIT;
    if (ENABLE_PAX_SPLIT_CONFIG) {
      ENABLE_PAX_SPLIT = ENABLE_PAX_SPLIT_CONFIG.toString().toUpperCase() === 'TRUE';
    }
    const ENABLE_GEN_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('DP_SPLIT_PAX_ENABLED_PRODUCTS', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_GEN_PAX_SPLIT;
    if (ENABLE_GEN_PAX_SPLIT_CONFIG) {
      ENABLE_GEN_PAX_SPLIT = ENABLE_GEN_PAX_SPLIT_CONFIG.toString().split(',').includes('GEN');
    }
    if (ENABLE_PAX_SPLIT && ENABLE_GEN_PAX_SPLIT) {
      this.ENABLE_PAX_SPLIT = true;
    }
    this.locationUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=CITY&userInput=[UI]&aggrLevel=NA&locale=en&matchType=CODE~NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;
    this.activityUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=ACTIVITY&userInput=[UI]&aggrLevel=NA&locale=en&matchType=NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;
  }

  setDurationCategories(name: string) {
    this.DURATION_CATEGORIES = [];
    let alreadySelected = false;
    let categories = name.split('|');
    categories.forEach(category => {
      let values = category.split(',');
      this.DURATION_CATEGORIES.push({'code': values[0], 'value': values[1], 'selected': (alreadySelected ? false : values[2] === 'true')});
      if (values[2] === 'true') {
        alreadySelected = true;
      }
    });
  }

  setDurationCategoriesAcceptableTypes(name: string) {
    this.DURATION_ACCEPTABLE_CATEGORIES = [];
    const acceptableCategories = name.split(',');
    acceptableCategories.forEach(acceptableCategory => {
      this.DURATION_ACCEPTABLE_CATEGORIES.push(acceptableCategory);
    });
    this.isDurationEnabledAll = this.DURATION_ACCEPTABLE_CATEGORIES?.includes('ANY');
  }
  /**
   * set calendar initial dates based on the config values
   */
  setInitialDates(sd: any) {
    this.initialDates = [];
    if (sd !== undefined && sd !== null) {
      const startDate = new Date(sd);
      this.initialDates.push(startDate);
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkDateChange();
    }

    if (this.isAmendmentFlow) {
      this.doCheckLastMinuteBookingDate(false);
    }
  }

  updateLocationCriteria(displayText, type, code, name, parent) {
    this.selectedDest.displayText = displayText;
    this.genericCriteria.destinationType = type;
    this.genericCriteria.destinationCode = code;
    this.genericCriteria.destinationName = name;
    this.genericCriteria.destinationParentCode = parent;
    if (displayText !== 'undefined, undefined') {
      this.selectedDest.displayText = '';
      setTimeout(() => {
        this.selectedDest.displayText = displayText;
        this.validateInputsForDP();
      }, 100);
    }
  }

  updateEleGroups(parsedList) {
    let surfTextCasePipe = new SurfTextCasePipe(this.configService);
    if (parsedList && parsedList.length > 0) {
      const list = parsedList.map((item) =>
        Object.assign({}, item)
      );
      if (!(list[0].code == -1 || list[0].name == 'Select activity type')) {
        let defaultItem = JSON.parse(JSON.stringify(list[0]));
        defaultItem.value = 'Select activity type';
        defaultItem.name = 'Select activity type';
        if (!this.selectedElementGrpCode) {
          defaultItem.selected = true;
        }
        defaultItem.code = '-1';
        list.unshift(defaultItem);
      }

      list.forEach( (d, index) => {
        d.value = surfTextCasePipe.transform(d.name, 'sentence').trim();
        d.selected = this.selectedElementGrpCode == d.code;
        if(this.EP_PRICE_ADJ_ELEMENT_GROUP_CODE && this.EP_PRICE_ADJ_ELEMENT_GROUP_CODE.indexOf(d.code) > -1) list.splice(index,1);
      });

      list.forEach(d => {
        d.value = surfTextCasePipe.transform(d.name, 'sentence').trim();
        d.selected = this.selectedElementGrpCode == d.code;
      });
      return list;
    }
    return parsedList;
  }

  resetElementGroup(){
    if(this.isDPSearch || this.isAmendmentFlow){
      let eg = this.dpElementGroups[this.index];
      if(eg && eg.length > 0){
        eg.forEach(ele=>{
          if (ele.code == "-1"){
            ele.selected = true;
          } else {
            ele.selected = false;
          }
        });
      }
    }else {
      this.elementGroups.forEach(ele=>{
        if (ele.code == "-1"){
          ele.selected = true;
        } else {
          ele.selected = false;
        }
      });
    }
  }

  scrollTo(selector) {
    if (!this.isDPSearch) {
      if (this.windowScrollEnable) {
        SurfScrollDirective.scrollTo(
          selector,
          null,
          false,
          false,
          0,
          60
        );
      }
    }
  }

  /**
   * Upon clicking the reset button, all the input fields and corresponding
   * values in the criteria object will be reset
   */
  resetInputs(isRefine?: boolean) {
    if (!isRefine) {
      this.selectedDest.displayText = '';
      this.selectedActivity.displayText = '';
      this.destSearchInitiated = false;
      this.activitySearchInitiated = false;
      this.genericCriteria.destinationType = null;
      this.genericCriteria.destinationCode = null;
      this.genericCriteria.destinationName = null;
      this.genericCriteria.categoryName = null;
      this.genericCriteria.destinationParentCode = null;
      this.genericCriteria.adult = '';
      this.genericCriteria.child = '';
      this.genericCriteria.teen = '';
      // this.setSelectedDatesDefault();
      this.genericCriteria.startDate = null;
      this.genericCriteria.elementGroup = null;
      this.genericCriteria.duration = null;
      this.initialDates = [];
      this.filteredActivityList = [];
      this.cityCode = undefined;
      this.resetElementGroup();
      this.resetDisplayItems();
      this.validateInputs();
      this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
    } else {
      this.cancelRefineSearch();
    }
    if (this.isServiceAddComponent) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }
  }

  resetDisplayItems() {
    this.defaultPaxSet = [];
    this.resetCalendar = true;
    this.durationValues[0].code = '';
    this.durationValues[0].value = '';
    setTimeout(() => {
      this.resetCalendar = false;
    }, 0);
  }

  /**
   * Construct the generic search URL and redirect to the listing page
   */
  searchGenric(isExternal?) {
    // if (this.validateInputs()) {
    if (true) {
      const basePath = '/'; // this.isRefine ? window.location.origin + '/' : window.location.href;
      if (this.isServiceAddComponent || this.isAmendmentFlow) {
        this.travellerEarliestDepartureDateService.addNewDepartureDate(this.genericCriteria.startDate);
        this.travellerEarliestDepartureDateService.checkAndUpdateEarliestDepartureDate();
        const searchURL = basePath + 'b2b-generic/?' + this.keyParams + this.getGenericSearchUrlParams();
        this.getUrlCriteria.emit({url: searchURL, isExternal});
      } else {
        window.location.href = basePath + 'b2b-generic/?' + this.keyParams + this.getGenericSearchUrlParams(); // 'http://localhost:4210' +
      }
    }
  }

  validateInputs(): boolean {
    let isAllInputsPresent = false;
    if (this.genericCriteria && this.genericCriteria.elementGroup && !this.handleDurationTypes(this.genericCriteria.elementGroup)) {
      if (this.genericCriteria && this.genericCriteria.destinationName && this.genericCriteria.duration && this.selectedDest.displayText &&
        this.genericCriteria.startDate && this.genericCriteria.adult) {
        isAllInputsPresent = true;
      }
    } else {
      if (this.genericCriteria && this.genericCriteria.destinationName && this.selectedDest.displayText &&
        this.genericCriteria.startDate && this.genericCriteria.adult) {
        isAllInputsPresent = true;
      }
    }
    const isLastMinuteBookingValidationPass = this.surfCalendarStartDateService.lastMinuteBookingErrorSet.size <= 0;
    if (this.isAmendmentFlow) {
      return isLastMinuteBookingValidationPass && isAllInputsPresent;
    } else {
      return isAllInputsPresent;
    }
  }

  validateInputsForDP() {
    if (this.selectedDest.displayText) {
      this.isValid.emit(true);
    } else {
      this.isValid.emit(false);
    }
  }

  getGenericSearchUrlParams() {
    /*Consider check-in date when calculating DOB*/
    if (this.genericCriteria.adult) {
      // this.genericCriteria.adult = this.surfCriteriaUtil.replaceDOB(this.genericCriteria.adult, this.genericCriteria.startDate, 'GEN');
      this.genericCriteria.adult = this.genericCriteria.adult.replace(/DOB/g, ''); // removing DOB from adult
    }
    let childParams = '';
    if (this.genericCriteria.teen && this.genericCriteria.teen.length > 0) {
      // this.genericCriteria.child = this.surfCriteriaUtil.replaceDOB(this.genericCriteria.child, this.genericCriteria.startDate, 'GEN');
      this.genericCriteria.teen = this.genericCriteria.teen.replace(/DOB/g, ''); // removing DOB from child
      childParams = '&child=' + this.genericCriteria.teen;
    }
    if (this.genericCriteria.child && this.genericCriteria.child.length > 0) {
      if (childParams.toString().includes('&child')) {
        this.genericCriteria.child = this.genericCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = childParams + this.AGE_SEPARATOR + this.genericCriteria.child;
      } else {
        this.genericCriteria.child = this.genericCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = '&child=' + this.genericCriteria.child;
      }
    }
    if (this.genericCriteria.infant && this.genericCriteria.infant.length > 0) {
      // this.genericCriteria.infant = this.surfCriteriaUtil.replaceDOB(this.genericCriteria.infant, this.genericCriteria.startDate, 'GEN');
      // this.genericCriteria.infant = this.genericCriteria.infant.replace(/DOB/g, ''); // removing DOB from infant
      childParams += '&infant=' + this.genericCriteria.infant;

    }

    //get eneric related URL params
    let queryStr = '&adult=' + this.genericCriteria.adult + childParams + '&startDate=' + this.genericCriteria.startDate +'&dn=' + this.genericCriteria.destinationName
    + '&destinationType=' + this.genericCriteria.destinationType;

    if (this.genericCriteria.city && this.genericCriteria.destinationType == DestinationType.CITY){
      queryStr += '&city=' + this.genericCriteria.city;
    }
    if (this.genericCriteria.country){
      queryStr += '&country=' + this.genericCriteria.country;
    }
    if (this.genericCriteria.destinationParentCode) {
      queryStr += '&dp=' + this.genericCriteria.destinationParentCode;
    }
    if (this.genericCriteria.elementGroup) {
      queryStr += '&elementGrp=' + this.genericCriteria.elementGroup;
    }
    if (this.genericCriteria.categoryName) {
      queryStr += '&categoryName=' + this.surfUtilService.encodeSpecialCharacters(this.genericCriteria.categoryName, '&').replaceAll('+', '%2B');
    }
    if (this.genericCriteria.duration) {
      if (this.genericCriteria.duration !== null && this.genericCriteria.duration !== '' && this.genericCriteria.duration !== '0') {
        queryStr += '&duration=' + this.genericCriteria.duration;
      }
    }
    if (this.isAmendmentFlow) {
      queryStr += '&bkgId=' + this.bookingId;
      queryStr += '&productKey=' + this.productKey;
      queryStr += '&reason=' + this.amendmentReasonCode;
      queryStr += '&cause=' + this.amendmentCauseCode;
      queryStr += '&isAmendment=true';
    }

    if (this.ENABLE_GEN_NFR) {
      queryStr += '&expand=summary,source,category,rate,,detail,choices';
    } else {
      queryStr += '&expand=all';
    }

    return queryStr;
  }

  getSelectedDate(event){
    if (event && event.length > 0 && event[0]) {
      this.resetCalendar = false;
      this.selectedDate = event[0];
      // if (!this.isDPSearch) { // in dp flow date will be updated by the dp criteria
        this.genericCriteria.startDate = this.surfCriteriaUtil.convertDateObjToStr(this.selectedDate, true);
      // }
      if (this.isAmendmentFlow && !this.isItemCloseable) {
        this.checkDateChange();
      }
    } else {
      this.selectedDate = null;
      this.genericCriteria.startDate = null;
    }

    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
    }
    if (this.isDPSearch || this.isAmendmentFlow) {
    //   /*NEW IMPL - notify the change
    //   * change value : for DATE ->  STARTDATE*/
    //   let changeObj = {
    //     index : this.index,
    //     productCode : 'GEN',
    //     changeType : 'DATE',
    //     value : this.surfCriteriaUtil.convertDateObjToStr(this.selectedDate, true)
    //   };
    //   this.commonService.updateChangeItem(changeObj);
      this.validateInputsForDP();
    }
    if (this.genericCriteria && this.genericCriteria.startDate) {
      this.travellerEarliestDepartureDateService
        .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.genericCriteria.startDate);
      if(this.isAmendmentFlow){
        this.doCheckLastMinuteBookingDate(true);
      }
    }
  }

  closeCriteriaBox() {
    this.commonService.updateComponentCriteria(null, 'GEN');
    this.commonService.changeMessage('GEN_close');
    if (this.isRefine) {
      this.cancelRefineSearch();
    }
  }

  cancelRefineSearch() {
    this.cancelRefine.emit(this.isRefine);
    // this.resetToOriginalRefineCriteria();
  }

  getPassengerSelection($event: Array<PaxAllocationItem>) {
    if ($event != null && this.validateChildAges($event) && this.validateTeenAges($event)) {
      this.genericCriteria.adult = this.processAdultPaxAllocation($event);

      const children = this.processChildAllocation($event);
      let teens = this.processTeenAllocation($event);
      let infant = this.processInfantAllocation($event);
      if (children.CHD && children.CHD.length > 0) {
        this.genericCriteria.child = children.CHD;
      } else {
        this.genericCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.genericCriteria.teen = teens;
      } else {
        this.genericCriteria.teen = null;
      }
      this.genericCriteria.infant = infant && infant.length > 0 ? infant : null;

      this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
    } else {
      this.genericCriteria.adult = null;
      this.genericCriteria.child = null;
      this.genericCriteria.teen = null;
      this.genericCriteria.infant = null;
      this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
    }

  }

  /**
   * check for invalid child ages
   * @param selectedPax
   */
  validateChildAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => acc && ( pax.child.indexOf(-1) > -1 ? false : true ), true);
  }
  /**
   * check for invalid teen ages
   * @param selectedPax
   */
  validateTeenAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => acc && ( pax.teen.indexOf(-1) > -1 ? false : true ), true);
  }
  processAdultPaxAllocation(paxArray: Array<PaxAllocationItem>) {

    const defaultAdultAge =  this.DEFAULT_ADULT_AGE;
    const fieldSeperator = '~';

    let paxStr = '';

    for (let i = 0 ; i < paxArray.length ; i++) {
      const paxItem = paxArray[i];
      // paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + this.convertDateObjToStr(this.getDOB(defaultAdultAge)) + fieldSeperator +  (i + 1);
      paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + 'DOB';
    }

    return paxStr;
  }
  processTeenAllocation(paxArray: Array<PaxAllocationItem>) {

    let teens = '';
    // teens
    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.teen.length > 0) {
        let teenAgeMap = {};
        const teenOrder = [];
        paxRoom.teen.forEach(teenAge => {
          if (teenOrder.findIndex(age => age === teenAge) < 0) {
            teenOrder.push(teenAge);
          }
        });
        paxRoom.teen.forEach(teenAge => {
          teenAgeMap[teenAge] ? teenAgeMap[teenAge]++ : teenAgeMap[teenAge] = 1;
        });
        for (let age of teenOrder) {
          const key = age + '';
          let count = teenAgeMap[key] + '';
          /* TODO update this logic based on guest age setup */
          /*teens*/
          teens += count + this.FIELD_SEPARATOR + key + this.FIELD_SEPARATOR + 'DOB';
          teens += this.AGE_SEPARATOR;
        }
      }
      roomNo++;
    });

    if (teens.length > 2) {
      teens = teens.slice(0, teens.length - 2);
    }
    return teens;
  }
  processChildAllocation(paxArray: Array<PaxAllocationItem>) {

    const children = {
      INF: '',
      CHD: '',
    };
    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.child.length > 0) {

        const childAgeMap = {};
        const childOrder = [];
        paxRoom.child.forEach(childAge => {
          if (childOrder.findIndex(age => age === childAge) < 0) {
            childOrder.push(childAge);
          }
        });
        paxRoom.child.forEach(childAge => {
          childAgeMap[childAge] ? childAgeMap[childAge]++ : childAgeMap[childAge] = 1;
        });

        for (const age of childOrder) {
          const key = age + '';
          const count = childAgeMap[key] + '';
          if (key.trim() === this.LESS_THAN_ONE) {
            /*Infants*/
            // children.INF += count + fieldSeperator + 0 + fieldSeperator + this.convertDateObjToStr(this.getDOB(0)) + fieldSeperator + roomNo + ageSeperator;
            children.INF += count + this.FIELD_SEPARATOR + 0 + this.FIELD_SEPARATOR + 'DOB' + this.FIELD_SEPARATOR + roomNo + this.AGE_SEPARATOR;
          } else {
            /*child*/
            // children.CHD += count + fieldSeperator + key + fieldSeperator + this.convertDateObjToStr(this.getDOB(key)) + fieldSeperator + roomNo + ageSeperator;
            children.CHD += count + this.FIELD_SEPARATOR + key + this.FIELD_SEPARATOR + 'DOB' + this.FIELD_SEPARATOR + roomNo + this.AGE_SEPARATOR;
          }
        }
      }
      roomNo++;
    });

    if (children.INF.length > 2) {
      children.INF = children.INF.slice(0, children.INF.length - 2);
    }
    if (children.CHD.length > 2) {
      children.CHD = children.CHD.slice(0, children.CHD.length - 2);
    }

    return children;
  }

  processInfantAllocation(paxArray: Array<PaxAllocationItem>) {
    let roomNo = 1;
    let inf = '';
    if (paxArray.length > 1) {
      paxArray.forEach(paxRoom => {
        inf += (paxRoom.infant.length) + this.FIELD_SEPARATOR + this.FIELD_SEPARATOR + '__' + roomNo + '__' ;
        roomNo ++;
      });
    } else {
      inf = (paxArray[0].infant.length) + this.FIELD_SEPARATOR + this.FIELD_SEPARATOR ;
    }
    return inf;
  }

  getCurrentOption($event) {
    this.currentPaxOption = $event;
  }

  onClickInsideDestTypeAhead(event) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    this.showRecommendedContent = true;

    const genCri = this.genericCriteria;

    if (genCri) {
      if (genCri.destinationType === DestinationType.CITY) {
        this.selectedDest.displayText = genCri.destinationName.trim();
      }
    }
  }

  onClickOutsideDestTypeAhead(event) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    this.showRecommendedContent = false;

    const genCri = this.genericCriteria;

    if (genCri) {
      if (genCri.destinationType === DestinationType.CITY && genCri.destinationName && genCri.destinationName !== 'undefined') {
        this.selectedDest.displayText = genCri.destinationName.trim() + ((genCri.destinationParentCode &&
          !genCri.destinationParentCode.includes('undefined')) ? ', ' + genCri.destinationParentCode : '');
      }
      if (this.isRefine && genCri.destinationType === DestinationType.COUNTRY && genCri.destinationName
        && genCri.destinationName !== 'undefined') {
        this.selectedDest.displayText = surfTextCasePipe.transform(genCri.destinationName, 'title').trim();
      }
    }
    if (this.isDPSearch || this.isAmendmentFlow) {
      this.validateInputsForDP();
    }
  }

  onClickInsideActivityTypeAhead(event) {
    const genCri = this.genericCriteria;
    if (genCri && genCri.startDate) {
      const startDate = genCri.startDate.split('T')[0];
      this.activityUrl = this.STATIC_DATA_URL_BASE +
        '?dataTypes=ACTIVITY&userInput=[UI]&aggrLevel=NA&locale=en&matchType=NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;
      this.activityUrl = this.activityUrl + '&attributes=DEPDATE~' + startDate;
    } else {
      this.activityUrl = this.STATIC_DATA_URL_BASE +
        '?dataTypes=ACTIVITY&userInput=[UI]&aggrLevel=NA&locale=en&matchType=NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;
    }
    if (genCri && genCri.categoryName) {
      this.selectedActivity.displayText = genCri.categoryName.trim();
    }
  }

  onClickOutsideActivityTypeAhead(event) {
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);

    const genCri = this.genericCriteria;

    if (genCri) {
      if (genCri.categoryName && genCri.categoryName !== 'undefined') {
        this.selectedActivity.displayText = surfTextCasePipe.transform(genCri.categoryName, 'title').trim();
      }
    }
    if (this.isDPSearch || this.isAmendmentFlow) {
      this.validateInputsForDP();
    }
  }

  returnedDestSearchQuery(event) {
    this.destSearchInitiated = event && event.length >= 3;
    if (this.isDPSearch && event !== undefined && event.length < 3 && this.selectedDest.displayText.length < 3) {
      this.validateInputsForDP();
    }
  }

  returnDestination(data) {
    // console.log("return data : ",data);
    if (data && data.length > 0) {
      this.filteredList = data;
    } else {
      this.cityCode = undefined;
      this.filteredList = [];
      this.genericCriteria.destinationCode = null;
      this.genericCriteria.destinationName = null;
      this.genericCriteria.destinationParentCode = null;
    }
  }

  returnedActivitySearchQuery(event) {
    this.activitySearchInitiated = event && event.length >= 3;
    this.genericCriteria.categoryName = event.trim();
    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkActivityNameChange();
    }
  }

  /**
   * filter activity names respective of its location
   * @param data
   */
  returnedActivity(data) {
    this.filteredActivityList = [];
    if (data && data.length > 0 && this.GEN_ENABLE_ACTIVITY_NAME_FILTERING_BY_LOCATION && this.cityCode !== undefined) {
      data.forEach(p => {
        p.parent.forEach(c => {
          if (c.code === this.cityCode) {
            this.filteredActivityList.push(p);
          }
        });
      });
    } else if (data && data.length > 0) {
      this.filteredActivityList = data;
    } else {
      this.filteredActivityList = [];
    }
  }

  /**
   * handle key down
   * @param $event, component
   */
  onKeyDown($event, component) {
    if ($event.keyCode === KeyCodes.ENTER ) {
      if (component === 0) {
        setTimeout(() => {
          this.datePickerFocus = true;
          this.guestComponentFocus = false;
        }, 200);
      } else if (component === 1) {
        if (this.currentPaxOption === 3) {
          if (this.enterPressed && (this.genericCriteria.nights === undefined || this.genericCriteria.nights === 0)) {
            setTimeout(() => {
              this.guestComponentFocus = false;
              this.datePickerFocus = false;
            }, 200);
          }
          this.enterPressed = true;
        }
        if (this.currentPaxOption !== 3 && (this.genericCriteria.nights === undefined || this.genericCriteria.nights === 0)) {
          setTimeout(() => {
            this.guestComponentFocus = false;
            this.datePickerFocus = false;
          }, 200);
          this.enterPressed = false;
        }
      } else if (component === 2) {
        setTimeout(() => {
          this.guestComponentFocus = false;
          this.datePickerFocus = false;
          this.onKeyDownLast.emit(true);
        }, 200);
      }
    } else {
      this.initFocus = false;
      this.datePickerFocus = false;
      this.guestComponentFocus = false;
    }

    if ($event.keyCode === KeyCodes.UPARROW || $event.keyCode === KeyCodes.DOWNARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = false;
      }
    }

    if ($event.keyCode === KeyCodes.RIGHTARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = true;
      }
    }
  }

  handleSuggestionSelect(event) {
    if (event && event.tempDetails) {
      this.selectDestination(event.tempDetails);
      this.showRecommendedContent = false;
    }
  }

  selectDestination(data) {
    this.cityCode = data.code;
    const surfTextCasePipe = new SurfTextCasePipe(this.configService);
    this.filteredList = [];
    this.recommendingLocations = [];
    this.calendarMinDate = new Date();
    this.showRecommendedContent = false;
    if (!this.datePickerFocus) {
      setTimeout(() => {
        this.datePickerFocus = true;
        this.guestComponentFocus = false;
      }, 200);
    }
    for (let i = 0; i < data.attributes?.length ; i++) {
      if (data.attributes[i].name === 'current_time') {
        this.calendarMinDate = new Date(data.attributes[i].value);
      }
    }
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'GEN');
    if(data.type == "CITY" || data.type == 40){
      this.selectedDest.displayText = data.name + (this.surfCriteriaUtil.getCountry(data) ? ', ' + this.surfCriteriaUtil.getCountry(data) : '');
      this.genericCriteria.destinationType = DestinationType.CITY;
      this.genericCriteria.destinationParentCode = this.surfCriteriaUtil.getCountry(data);
      this.genericCriteria.city = data.code;
      this.genericCriteria.country = this.surfCriteriaUtil.getCountryCode(data);
      // this.genericCriteria.destinationName = data.name;
    }
    else {
      this.selectedDest.displayText = data.name;
      this.genericCriteria.destinationType = DestinationType.COUNTRY;
      this.genericCriteria.country = data.code;
    }
    this.selectedDest.displayText = this.selectedDest.displayText.trim();
    this.destSearchInitiated = false;
    this.genericCriteria.destinationCode = data.code;
    this.genericCriteria.destinationName = data.name;


    if (this.genericCriteria.productCode == undefined) {
      this.genericCriteria.productCode = 'GEN';
    }

    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
    }

    if(this.isDPSearch || this.isAmendmentFlow){
      /*NEW IMPL - notify the change
      * change value : for DEST ->  DISPLAYTEXT~LOCTYPE~LOCCODE~LOCNAME~PARENTNAME~~*/

      // let value = this.selectedDest.displayText + '~' + this.genericCriteria.destinationType + '~' + this.genericCriteria.destinationCode
      //   + '~' + this.genericCriteria.destinationName + '~' + this.genericCriteria.destinationParentCode;
      //
      // let changeObj = {
      //   index : this.index,
      //   productCode : 'GEN',
      //   changeType : 'DEST',
      //   value : value
      // };
      // this.commonService.updateChangeItem(changeObj);
      this.validateInputsForDP();
    }
    this.getCurrentGenericCriteria.emit(this.genericCriteria);
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkLocationChange(data);
    }
  }

  /**
   * set activity name selected via the 'Activity name' typeahead
   */
  selectActivity(data) {
    this.filteredActivityList = [];
    this.selectedActivity.displayText = data.name + (this.surfCriteriaUtil.getCountry(data) ? ', '
      + this.surfCriteriaUtil.getCountry(data) : '');
    this.selectedActivity.displayText = this.selectedActivity.displayText.trim();
    this.activitySearchInitiated = false;
    this.genericCriteria.categoryName = data.name;

    if (this.genericCriteria.productCode === undefined) {
      this.genericCriteria.productCode = 'GEN';
    }

    if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
      this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
    }

    if (this.isDPSearch || this.isAmendmentFlow) {
      this.validateInputsForDP();
    }
    this.getCurrentGenericCriteria.emit(this.genericCriteria);
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkActivityNameChange();
    }
  }

  handleDurationTypes(code: string): boolean {
    if (this.DURATION_ACCEPTABLE_CATEGORIES.filter(x => x === code).length > 0) {
      return false;
    } else {
      return true;
    }
  }
  resetDurationSelection() {
    // this.isDurationDisabled = true;
    if (this.genericCriteria && this.genericCriteria.elementGroup) {
      this.genericCriteria.elementGroup = null;
    }
    if (this.genericCriteria && this.genericCriteria.duration) {
      this.genericCriteria.duration = null;
    }
    this.durationProperty = [];
    this.durationValues = [];
    this.durationProperty.push(MultiFieldInputPropertyFactory.getInputProperties(true, 'Any No.', false, false, 'text', '' , 'durationValue', ''));
    this.durationValues.push(new MultiFieldInputData('',''));
    this.durationProperty.push(MultiFieldInputPropertyFactory.getDropdownProperties(false, '', true, false, 'button', 'durationType', ''));
    this.durationValues.push([]);
    this.DURATION_CATEGORIES.forEach( category => {
      this.durationValues[1].push(new MultiFieldInputData(category.code, category.value, category.selected));
    });
    this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
  }

  selectElementGroup(event) {
    if (event.code && event.code !== '-1') {
      this.isDurationDisabled = this.handleDurationTypes(event.code);
      if (this.isDurationDisabled || !this.isDurationEnabledAll) {
        this.durationError = false;
        this.resetDurationSelection();
      }
      this.selectedElementGrpCode = event.code;
      this.genericCriteria.elementGroup = this.selectedElementGrpCode;
      if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
        this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
      }
      // if(this.isDPSearch){
      /*NEW IMPL - notify the change
      * change value : for ELEMENTGROUP ->  ELEMENTGROUP*/
      // let changeObj = {
      //   index: this.index,
      //   productCode: 'GEN',
      //   changeType: 'ELEMENTGROUP',
      //   value: event.code
      // };
      // this.commonService.updateChangeItem(changeObj);
      // }
      if (this.isAmendmentFlow && !this.isItemCloseable) {
        this.checkActivityTypeChange(true);
      }
    } else {
      this.isDurationDisabled = !this.isDurationEnabledAll;
      this.resetDurationSelection();
      if (this.isAmendmentFlow && !this.isItemCloseable) {
        this.checkActivityTypeChange(false);
      }
    }
  }


  updateDPCriteria(event) {
    /*in the DP flow, update the relavent product criteria inside the product criteria list*/
    let idx = this.surfCriteriaUtil.getCriteriaItemArrayIndex(this.productCriteriaList, 'GEN', this.index);
    if (this.productCriteriaList && this.productCriteriaList[idx]) {
      this.mapToDPCriteriaItem(this.productCriteriaList[idx], this.genericCriteria, event);
    }
    else {
      let genCriItem = this.surfCriteriaUtil.getNewCriItem('GEN', this.index);
      this.mapToDPCriteriaItem(genCriItem, this.genericCriteria, event);
      this.productCriteriaList.push(genCriItem);
    }
    this.validateInputsForDP();
    this.commonService.updateProductCriteriaList(this.productCriteriaList);
  }

  mapToDPCriteriaItem(dpCriItem, criItem, event) {

    if (event == 'dest-name') {
      if (criItem.destinationName) {
        dpCriItem.destinationName = JSON.parse(JSON.stringify(criItem.destinationName));
      }
    }

    if (event == 'dest-all') {
      if (criItem.destinationType) {
        dpCriItem.destinationType = JSON.parse(JSON.stringify(criItem.destinationType));
      }
      if (criItem.destinationParentCode) {
        dpCriItem.destinationParentCode = JSON.parse(JSON.stringify(criItem.destinationParentCode));
      }
      if (criItem.destinationCode) {
        dpCriItem.destinationCode = JSON.parse(JSON.stringify(criItem.destinationCode));
      }
      if (criItem.destinationName) {
        dpCriItem.destinationName = JSON.parse(JSON.stringify(criItem.destinationName));
      }
      if (criItem.country) {
        dpCriItem.country = JSON.parse(JSON.stringify(criItem.country));
      }
      if (criItem.city) {
        dpCriItem.city = JSON.parse(JSON.stringify(criItem.city));
      }
    }

    if (event == 'start-date') {
      if (criItem.startDate) {
        dpCriItem.startDate = JSON.parse(JSON.stringify(criItem.startDate));
      }
    }

    if (event == 'element-group') {
      if (criItem.elementGroup) {
        dpCriItem.elementGroup = JSON.parse(JSON.stringify(criItem.elementGroup));
      }
    }
  }

  getDuration(event) {
    if (event !== null) {
      this.durationError = false;
      let selectedDurationType = event[1].find(x => x.selected);
      if (selectedDurationType) {
        let durationType = selectedDurationType.code;
        let value = this.keepNumbers(event[0].value);
        if(value && value !== null && value !== ''){
          if (value.length === event[0].value.length) {
            this.genericCriteria.duration = value + durationType;
            this.checkDurationChange();
          } else {
            this.genericCriteria.duration = null;
            this.durationError = true;
          }
        } else if (event[0].value?.length > 0) {
          this.genericCriteria.duration = null;
          this.durationError = true;
        } else {
          this.genericCriteria.duration = null;
          this.durationError = !this.isDurationDisabled;
          // this.isDurationDisabled = true;
        }

      } else {
        this.genericCriteria.duration = null;
        this.durationError = true;
        this.isDurationDisabled = true;
      }
      if (!this.isRefine && !this.isDPSearch && !this.isAmendmentFlow) {
        this.commonService.updateComponentCriteria(this.genericCriteria, 'GEN');
      }
      if (this.isDPSearch || this.isAmendmentFlow) {
        //   /*duraiton pattern :  value+Type => eg : 2D (2 days)*/
        //   let changeObj = {
        //     index : this.index,
        //     productCode : 'GEN',
        //     changeType : 'DURATION',
        //     value : this.genericCriteria.duration
        //   };
        //   this.commonService.updateChangeItem(changeObj);
        this.validateInputsForDP();
      }
    }
  }

  keepNumbers(duration: string) {
    if (duration !== null) {
      const value = duration.replace(/[^0-9]*/g, '');
      return value;
    }
    return '';
  }

  setInitialDuration(duration: string) {
    let durationValue;
    let durationTypeCode;
    if (duration) {
      if (duration === null || duration === '0') {
        durationValue = duration.slice(0, duration.length - 1);
        durationTypeCode = duration[duration.length - 1];
      } else {
        const durationNumber = duration.match(/\d+/g);
        const durationType =  duration.match(/[a-zA-Z]+/g);
        durationValue = durationNumber[0];
        if (durationType[0] && durationType[0].substring(0, 1)) {
          durationTypeCode = durationType[0].substring(0, 1);
        }
      }


      if(this.durationValues[0]) {
        this.durationValues[0] = new MultiFieldInputData(durationValue, durationValue);
      }
      if(this.durationValues[1]) {
        this.durationValues[1].forEach( dur => {
          if(dur.code === durationTypeCode) {
            dur.selected = true;
          }
          else {
            dur.selected = false;
          }
        });
      }
    }
  }

  generateDisplayString(adult, child, infant, teen) {

    // should handle
    let str = '';

    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    return str;
  }

  /**
   * handle freeze-unfreeze checkbox
   */
  checkValue(event) {
    this.unfreeze = event.target.checked;
    this.freeze = !this.unfreeze;
    this.isUnfreeze.emit(event.target.checked);
  }

  /**
   * set warning true and false with the location input change, by comparing with the initial value.
   */
  checkLocationChange(data) {
    if (this.oldDPDestStr) {
      this.locationWarning =  !(this.genericCriteria.destinationCode.toUpperCase() === this.oldDPDestStr.toUpperCase());
    }
    else if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.destinationName && this.genericCriteria &&
      this.genericCriteria.destinationName && (this.genericCriteria.destinationCode === this.oldCriteriaItem.criItem.destinationCode) &&
      ((this.genericCriteria.destinationName.toUpperCase() === this.oldCriteriaItem.criItem.destinationName.toUpperCase()) ||
        this.genericCriteria.destinationType.toString() === 'HOTEL')) {
      if (data && (data.type === 'CITY' || data.type === 40)) {
        this.locationWarning = this.genericCriteria.destinationParentCode !== this.oldCriteriaItem.criItem.destinationParentCode;
      } else {
        this.locationWarning = false;
      }
    } else if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.destinationName && this.genericCriteria
      && this.genericCriteria.destinationName) {
      this.locationWarning = true;
    }
  }

  checkInitialChanges(){
    let typeCode;
    if(this.oldDPDateStr){
      this.dateWarning = !(this.genericCriteria.startDate.split('T')[0] === this.oldDPDateStr.split('T')[0]);
    }
    if (this.oldDPElementGrpCode) {
      typeCode = this.oldDPElementGrpCode;
      this.typeWarning = !(this.selectedElementGrpCode === typeCode);
    }
    if (this.oldDPCategoryName) {
      this.activityWarning = !(this.genericCriteria.categoryName.toUpperCase() === this.oldDPCategoryName.toUpperCase());
    }
    if (this.oldDPDestStr) {
      this.locationWarning =  !(this.genericCriteria.destinationCode.toUpperCase() === this.oldDPDestStr.toUpperCase());
    }
  }

  /**
   * set warning true and false with the date input change, by comparing with the initial value.
   */
  checkDateChange() {
    if (this.genericCriteria && this.genericCriteria.startDate && this.oldCriteriaItem && this.oldCriteriaItem.criItem &&
      this.oldCriteriaItem.criItem.startDate) {
      this.dateWarning = !(this.genericCriteria.startDate.split('T')[0] === this.oldCriteriaItem.criItem.startDate.split('T')[0]);
    }
    else if(this.oldDPDateStr){
      this.dateWarning = !(this.genericCriteria.startDate.split('T')[0] === this.oldDPDateStr.split('T')[0]);
    }
  }

  /**
   * set warning true and false with the duration change, by comparing with the initial value.
   */
  checkDurationChange() {
    if (this.genericCriteria && this.genericCriteria.duration && this.oldCriteriaItem && this.oldCriteriaItem.criItem) {
      if (this.oldCriteriaItem.criItem.duration) {
        this.durationProperty[0].warning = !(this.genericCriteria.duration.substr(0, this.genericCriteria.duration.length - 1)
          === this.oldCriteriaItem.criItem.duration.substr(0, this.oldCriteriaItem.criItem.duration.length - 1));
        this.durationProperty[1].warning = !(this.genericCriteria.duration.substr(this.genericCriteria.duration.length - 1, 1)
          === this.oldCriteriaItem.criItem.duration.substr(this.oldCriteriaItem.criItem.duration.length - 1, 1));
      } else {
        this.durationProperty[0].warning = true;
        this.durationProperty[1].warning = true;
      }
    }
  }

  /**
   * set warning true and false with the Activity Type input change, by comparing with the initial value.
   */
  checkActivityTypeChange(selected) {
    let typeCode;
    if (selected) {
      if (this.oldDPElementGrpCode) {
        typeCode = this.oldDPElementGrpCode;
        this.typeWarning = !(this.selectedElementGrpCode === typeCode);
      } else {
        if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && !this.oldCriteriaItem.criItem.elementGroup) {
          typeCode = '-1';
        } else if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.elementGroup) {
          typeCode = this.oldCriteriaItem.criItem.elementGroup;
        }
        this.typeWarning = !(this.oldCriteriaItem && (this.selectedElementGrpCode === typeCode));
      }

    } else {
      this.typeWarning = true;
    }
  }

  /**
   * set warning true and false with the Activity Name input change, by comparing with the initial value.
   */
  checkActivityNameChange() {
    let activityName;
    if (this.oldDPCategoryName) {
      activityName = this.oldDPCategoryName;
      this.activityWarning = !(this.genericCriteria.categoryName.toUpperCase() === activityName.toUpperCase());
    } else {
      if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && !this.oldCriteriaItem.criItem.categoryName) {
        activityName = '';
      } else if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.categoryName) {
        activityName = this.oldCriteriaItem.criItem.categoryName;
      }
      this.activityWarning = !(this.oldCriteriaItem && (this.genericCriteria.categoryName.toUpperCase() === activityName.toUpperCase()));
    }
  }

  doCheckLastMinuteBookingDate(isAmendmentOldItemDateSelection: boolean) {
    if (this.genericCriteria && this.genericCriteria.startDate) {
      let checkingDate = new Date(this.genericCriteria.startDate);
      if (this.surfCalendarStartDateService.validateWithCheckingIsEarlierDate(new Date(), checkingDate, 'GEN')) {
        this.hideErrorMsg = true;
        this.validateDisabledDateSelection = false;
        this.surfCalendarStartDateService.lastMinuteBookingErrorSet.delete(this.index);
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = true;
        }
      } else {
        if (this.unfreeze) {
          this.surfCalendarStartDateService.lastMinuteBookingErrorSet.add(this.index);
        }
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = false;
        }
        this.hideErrorMsg = false;
        this.validateDisabledDateSelection = true;
      }
    }
  }
}
