import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {DataServiceHandler} from '@tc-core/service/service-handlers/data-service-handler.service';
import {
  CarCriteria,
  ConfigService,
  DataShareService, DestinationType,
  FlightComponentCriteria, FlightComponentCriteriaAirline, FlightComponentCriteriaViaPoint,
  FlightCriteria, KeyCodes,
  OntologyResponse, SurfCalendarStartDateService,
  SurfCriteriaUtil, SurfErrorMessages, SurfTravellerEarliestDepartureDateService
} from '@surf/surf-components-core';
import {MultiFieldInputProperty, MultiFieldInputPropertyFactory} from '@surf/surf-multi-field-input';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';
import {PaxAllocationItem} from '@surf/surf-pax-selection';
import {TC} from '@tc-core/util';
// import {FlightGETCriteria} from '@tc-core/model/it/codegen/ui/search/criteria/flight-GET-criteria';
import {FlightGETCriteria} from '@surf/surf-grab-pnr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {MasterDataStoreService, StoreConstants} from '@surf/surf-state-manage';


@Component({
  selector: 'surf-flight-criteria',
  templateUrl: './flight-criteria.component.html',
  styleUrls: ['./flight-criteria.component.css']
})
export class FlightCriteriaComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isRefine = false;
  @Input() isDPSearch = false;
  @Input() dpGrab = false;
  @Input() index: number;
  @Input() originalRefineCriteria: FlightCriteria;
  @Input() selectedPax: any;
  //new impl
  @Input() dpEnableDate: any;
  @Input() dpInitialDateStr: any;
  @Input() dpInitialDestStr: any;
  @Input() refineSearchScrollId: string;
  @Input() isPnr = false; // whether the current criteria is PNR or not
  @Input() isServiceAddComponent = false;
  @Input() cartPaxSelection: any;
  @Input() initialFlightItemListLength = 2;
  @Input() dpFlightRefineCriteria: any;
  @Input() unfreeze = false;
  @Input() isAmendmentFlow = false;
  @Input() isItemCloseable = true;
  @Input() paxSplitArray = [];
  @Input() enablePaxSplit = false;
  dpCalEnableDate: Date;
  @Input() isForEpToDpConversion = false;
  @Input() paxTypeAges = [];
  @Input() cachingItemMap = new Map<string, number>();
  @Input() allowNgrxCaching = false;

  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() getCurrentFlightCriteria: EventEmitter<FlightCriteria> = new EventEmitter<FlightCriteria>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isUnfreeze = new EventEmitter<boolean>();
  @Output() isPaxSplitChanged = new EventEmitter<boolean>();

  calendarMinDate: Date = new Date();
  FLIGHT_MAX_CABIN_PAX_COUNT = 20;
  FLIGHT_MAX_CHILD = this.FLIGHT_MAX_CABIN_PAX_COUNT - 1;
  FLIGHT_MAX_TEEN = this.FLIGHT_MAX_CABIN_PAX_COUNT - 1;
  FLIGHT_MAX_INFANTS = this.FLIGHT_MAX_CABIN_PAX_COUNT - 1;
  FLIGHT_MIN_ADULT = 1;
  FLIGHT_MAX_ADULT = 20;
  FLIGHT_MAX_PAX_COUNT = 40;
  MAX_FLIGHT_LEGS = 99;
  FLT_SEARCH_RESULT_COUNT: string;
  moduleConfigs: any;
  DEFAULT_ADULT_AGE = 30;
  FIELD_SEPERATOR = '~';
  AGE_SEPERATOR = '__';
  SEARCH_SEGMENTS_SEPERATOR = '__';
  LESS_THAN_ONE = '< 1';
  CALENDAR_RANGE = 2;
  anyCabinClassString = '--ANY--';
  DISABLE_DP_SWITCH = false;
  DISABLE_UI = false;
  keyParams: string;
  defaultPaxSet: Array<PaxAllocationItem> = [];
  airportList = [];
  airportCodesList = [];
  airlineList = [];
  cabinClassList = [];
  filteredDepartureList = [];

  filteredAirlineList = [];
  filteredViaPointList = [];
  url = '';
  MIN_SEARCH_QUERY_LENGTH: number = 3;
  MIN_SEARCH_QUERY_LENGTH_AIRLINE: number = 2;
  MIN_SEARCH_QUERY_LENGTH_VIA_POINTS: number = 3;
  MAX_CHILD_AGE = 11;
  MAX_TEEN_AGE = 17;
  MAX_INFANT_AGE = 23;
  TEEN_PAX_ACTIVE = true;
  departureSelectedDeparture: OntologyResponse = new OntologyResponse();
  departureSelectedArrival: OntologyResponse = new OntologyResponse();
  flightCriteria: FlightCriteria = new FlightCriteria();
  flightCriteriaDefault: FlightCriteria;
  flightPnrCriteria: FlightGETCriteria;
  productCriteriaList = [];
  flightItemList: FlightSearchLeg[] = [];
  departureTypeAheadComponentValue = [];
  departureReturnedProperty: MultiFieldInputProperty;
  STATIC_DATA_URL_BASE = "/data/data-service/v2/static-data";
  STATIC_DATA_FETCH_SIZE = 20;
  viaPointsUrl = '';
  enableGrabPnr: boolean;
  isLastItemInMultiInput = false; // check is focusing input is last input in multi input
  paxDisplay: string;
  subscriptions: Map<string, Subscription>;
  msg = '';
  freeze: boolean; // enable/disable all input fields during amendment search
  paxSplitDisplayText = 'All Guests';
  ENABLE_PAX_SPLIT = false;
  hideErrorMsg = true;
  validateDisabledDateSelection = false;
  SURF_ERROR_MESSAGES = SurfErrorMessages;
  setTimeoutValue = 200;

  constructor(protected dataServiceHandler: DataServiceHandler,
              protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService,
              protected surfCalendarStartDateService: SurfCalendarStartDateService,
              protected masterDataStoreService: MasterDataStoreService
  ) {
    this.subscriptions = new Map<string, Subscription>();
    this.commonService.getProductCriteriaList().pipe(take(1)).subscribe(list => {
      if (list) {
        this.productCriteriaList = list;
        this.removeSubscription('ProductCriteriaList');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unfreeze && this.unfreeze !== undefined) {
      this.freeze = !this.unfreeze;
    }
    let tempCriteria = this.originalRefineCriteria as any;
    if (tempCriteria && tempCriteria.arrObj && tempCriteria.depObj && this.isDPSearch) {
      if (this.flightItemList[0] && !this.flightItemList[0].arrObj) {
        this.flightItemList[0].arrObj = tempCriteria.arrObj;
      }
      if (this.flightItemList[0] && !this.flightItemList[0].depObj) {
        this.flightItemList[0].depObj = tempCriteria.depObj;
      }
    }
    if (changes['selectedPax']) {
      this.selectedPax = changes['selectedPax'].currentValue;
      if (this.isRefine) {
        this.defaultPaxSet = this.selectedPax;
      }
    }

    if (changes['paxSplitArray']) {
      const selectedPaxArray = this.paxSplitArray.filter(pax => {
        return pax.selected;
      });
      if (selectedPaxArray && selectedPaxArray.length === this.paxSplitArray.length) {
        this.paxSplitDisplayText = 'All Guests';
      } else {
        this.paxSplitDisplayText = selectedPaxArray.length.toString() + (selectedPaxArray.length === 1 ? ' Guest' : ' Guests');
      }
    }

    if (changes['dpEnableDate']) {
      this.dpEnableDate = changes['dpEnableDate'].currentValue;
      if(this.dpEnableDate != undefined){
        this.dpCalEnableDate = new Date(this.dpEnableDate);
      }
      this.validateInputsForDP();
    }

    if (changes['dpInitialDateStr']) {
      this.dpInitialDateStr = changes['dpInitialDateStr'].currentValue;
      if (this.dpInitialDateStr) {
        this.setInitialDates(this.dpInitialDateStr);
        this.validateInputsForDP();
      }
    }

    if (changes['dpInitialDestStr']) {
      this.dpInitialDestStr = changes['dpInitialDestStr'].currentValue;
      this.adjustLocations();
      this.validateInputsForDP();
    }


    if (this.isServiceAddComponent) {
      const adults = this.selectedPax.reduce( (acc, pax ) => acc + pax.adult , 0 ) ;
      const child = this.selectedPax.reduce( (acc, pax) => acc + pax.child.length , 0) ;
      const infant = this.selectedPax.reduce( (acc, pax) => acc + pax.infant.length , 0 ) ;
      const teen = this.selectedPax.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen) ;
    }
  }

  ngOnInit() {

    this.departureSelectedDeparture.displayText = '';
    this.departureSelectedArrival.displayText = '';
    this.loadConfigs();
    if (!this.paxTypeAges || this.paxTypeAges.length === 0) {
      this.loadPaxTypeAges();
    } else {
      this.mapPaxSubTypeAges();
    }
    this.DISABLE_UI = !this.surfCriteriaUtil.getProductEnableStatus('FLT', 'products');
    this.subscriptions.set('ComponentCriteria', new Subscription());
    this.subscriptions.get('ComponentCriteria').add(this.commonService.getComponentCriteria('FLT').subscribe(cri => {
      this.flightCriteria = cri as FlightCriteria;
      this.flightCriteriaDefault = this.flightCriteria;
      if (this.dpFlightRefineCriteria && this.dpFlightRefineCriteria.legs && this.dpFlightRefineCriteria.legs.length > 0) {
        this.flightCriteria = this.dpFlightRefineCriteria as FlightCriteria;
        this.removeSubscription('ComponentCriteria');
      }
    }));
    this.subscriptions.set('keyParams', new Subscription());
    this.subscriptions.get('keyParams').add(this.commonService.getKeyParamString().subscribe(keyParams => {
      if (keyParams) {
        // update resultsCount and availability
        if (this.FLT_SEARCH_RESULT_COUNT && this.FLT_SEARCH_RESULT_COUNT.length > 0) {
          const tempList = keyParams.split('resultsCount=');
          if (tempList[1]) {
            const sliceIndex = tempList[1].indexOf('&') >= 0 ? tempList[1].indexOf('&') : tempList[1].length;
            tempList[1] = this.FLT_SEARCH_RESULT_COUNT + tempList[1].slice(sliceIndex);
          } else {
            tempList[1] = this.FLT_SEARCH_RESULT_COUNT;
          }
          keyParams = tempList.join('avail=true&resultsCount=');
        }
        this.keyParams = keyParams;
        this.removeSubscription('keyParams');
      }
    }));
    if ( (this.airportCodesList != undefined || (this.airportCodesList && this.airportCodesList.length == 0)) && !this.isRefine ) {
      this.getAirports();
    }
    this.createInitialFlightItemList();



    // if(this.isDPSearch && this.preItemLocation){
    //   this.adjustLocations();
    // }
    this.subscriptions.set('cabinClasses', new Subscription());

    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_CABIN_CLASSES) !== null
      && this.cachingItemMap.get(StoreConstants.CACHE_CABIN_CLASSES) > 0)) {
      this.getCabinClassesFromCache(this.cachingItemMap.get(StoreConstants.CACHE_CABIN_CLASSES));
    } else {
      this.subscriptions.get('cabinClasses').add(this.dataServiceHandler.getCabinClasses().subscribe(
        res => {
          this.mapCabinClassList(res);
        },
        error => {
          console.log('Error in getting cabin classes');
          this.removeSubscription('cabinClasses');
        }));
    }
    this.subscriptions.set('airlines', new Subscription());
    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_AIRLINES) !== null
      && this.cachingItemMap.get(StoreConstants.CACHE_AIRLINES) > 0)) {
      this.getAirlinesFromCache(this.cachingItemMap.get(StoreConstants.CACHE_AIRLINES));
    } else {
      this.subscriptions.get('airlines').add(this.dataServiceHandler.getAirlines().subscribe(
        res => {
          this.mapAirLineList(res);

        },
        error => {
          console.log('Error in getting airlines');
          this.removeSubscription('airlines');
        }));
    }
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'FLT');
  }

  ngOnDestroy(): void {
    Array.from(this.subscriptions.keys()).forEach((subsKey) => {
      this.removeSubscription(subsKey);
    });
  }

  private getCabinClassesFromCache(timeout: number) {
    this.subscriptions.get('cabinClasses').add(
      this.masterDataStoreService.getCabinClasses(timeout).subscribe(
        res => {
          if (res) {
            this.mapCabinClassList(res);
          }
        },
        error => {
          console.log('Error in getting cabinClasses');
          this.removeSubscription('cabinClasses');
        }));
  }
  private mapCabinClassList(res: any) {
    this.cabinClassList = [];

    if (res && res.length > 0) {
      res.forEach(a => {
        const cabinClass = {
          name: a.name,
          code: a.code,
          value: a.name,
          selected: false
        };
        this.cabinClassList.push(cabinClass);
      });

      this.flightItemList.forEach(x => {
        // create a copy of cabinClass item and store in each flightItem cabin class list
        x.cabinClassList.push({value: this.anyCabinClassString, selected: false});
        this.cabinClassList.forEach(item => {
          x.cabinClassList.push(Object.assign({}, item));
        });
      });
      this.removeSubscription('cabinClasses');
    }
  }

  private getAirlinesFromCache(timeout: number) {
    this.subscriptions.get('airlines').add(
      this.masterDataStoreService.getAirlines(timeout).subscribe(
        res => {
          if (res) {
            this.mapAirLineList(res);
          }
        },
        error => {
          console.log('Error in getting airlines');
          this.removeSubscription('airlines');
        }));
  }

  private mapAirLineList(res: any) {
    this.airlineList = [];

    if (res && res.length > 0) {
      res.forEach(a => {
        const airline = {
          name: a.name,
          code: a.code,
          value: a.name,
          selected: false
        };
        this.airlineList.push(airline);
      });
      this.removeSubscription('airlines');
    }
  }

  loadPaxTypeAges() {
    this.dataServiceHandler.getPaxSubTypeAges().subscribe(
      res => {
        if (res == null || !(Array.isArray(res)) || res.length === 0) {
          console.log('Could not fetch Pax Type Ages');
          return;
        }

        this.paxTypeAges = res[0].paxTypes;
        this.mapPaxSubTypeAges();
      },
      error => {
        console.log('Error in getting pax sub type ages');
      });
  }
  private mapPaxSubTypeAges() {
    this.MAX_CHILD_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'C').age.upper / 12);
    this.MAX_TEEN_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'T').age.upper / 12);
    this.MAX_INFANT_AGE = this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
  }

  createInitialFlightItemList(isPnr?: boolean) {
    if (this.isRefine || isPnr) {
      this.flightItemList = JSON.parse(JSON.stringify(this.flightCriteria.legs));
    } else {
      this.flightItemList = [];
      if (this.isDPSearch) {
        if (this.flightCriteria.legs && this.flightCriteria.legs.length > 0 && this.dpInitialDestStr) {
          this.flightItemList = JSON.parse(JSON.stringify(this.flightCriteria.legs));
        } else {
          this.flightItemList.push(new FlightSearchLeg());
        }
      } else {
        for (let i = 0; i < this.initialFlightItemListLength; i++) {
          this.flightItemList.push(new FlightSearchLeg());
        }
      }
    }
    this.flightItemList.forEach((x, index) => {
      x.showTypeAheadContent = false;
      x.showAirlineTypeAheadContent = false;
      x.showViaPointTypeAheadContent = false;
      if (this.dpGrab) {
        this.setFlightErrors(x);
      } else {
        x.typeAheadHasError = false;
        x.typeAheadDepartureHasError = false;
        x.typeAheadArrivalHasError = false;
        x.typeAheadErrorMsg = '';
      }
      x.fromOntology = new OntologyResponse();
      x.toOntology = new OntologyResponse();
      x.airlineOntology = new OntologyResponse();
      x.viaPointOntology = new OntologyResponse();
      x.airlineOntologies = [];
      if (this.isRefine || isPnr || this.isAmendmentFlow || this.isForEpToDpConversion) {
        let index = 0;
        if (x.airlines && x.airlines.length > 0) {
          for (const airline of x.airlines) {
            const temp = this.createAirlineOntology(airline, index);
            x.airlineOntologies.push(temp);
            index++;
          }
        }
      }
      x.viaPointOntologies = [];
      if(this.isRefine || isPnr) {
        let index = 0;
        if (x.viaPoints && x.viaPoints.length > 0) {
          for (let viaPoint of x.viaPoints) {
            let temp = this.createViaPointOntology(viaPoint, index);
            x.viaPointOntologies.push(temp);
            index++;
          }
        }
      }
      x.multiFieldInputProperty = [];
      x.fromOntology.displayText = '';
      x.toOntology.displayText = '';
      x.airlineOntology.displayText = '';
      x.viaPointOntology.displayText = '';
      x.isAirlineSearchInitiated = false;
      x.isViaPointSearchInitiated = false;
      x.departureDateObj = [];
      x.cabinClassList = [];
      if (this.cabinClassList && this.cabinClassList.length > 0) {
        x.cabinClassList.push({value: this.anyCabinClassString, selected: false});
        this.cabinClassList.forEach(item => {
          x.cabinClassList.push(Object.assign({}, item));
        });
      }
      if (this.isRefine || isPnr || (this.isDPSearch && this.flightCriteria.legs && this.flightCriteria.legs.length > 0)) {
        if (x.departureAirportCity && x.departureAirportCode && x.departureAirportCity !== 'undefined' && x.departureAirportCode !== 'undefined') {
          x.fromOntology.displayText = x.departureAirportCity + ' (' + x.departureAirportCode + ')';
        }
        if (x.arrivalAirportCity && x.arrivalAirportCode && x.arrivalAirportCity !== 'undefined' && x.arrivalAirportCode !== 'undefined') {
          x.toOntology.displayText = x.arrivalAirportCity + ' (' + x.arrivalAirportCode + ')';
        }
        if (x.airlineName) {
          x.airlineOntology.displayText = x.airlineName;
        }
        if (x.departureDate) {
          let convertedDate = new Date();
          let stringDate = '';
          if (x.departureDate && x.departureDate.indexOf('T')) {
            stringDate = x.departureDate.split('T')[0];
          } else {
            stringDate = x.departureDate;
          }
          convertedDate = this.getDateFromString(stringDate);
          x.departureDateObj.push(convertedDate);
        }

        // x.departureDateObj = [...x.departureDateObj];
      }

      x.isSelected = false;

      let depDesUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=AIRPORT&userInput=[UI]&aggrLevel=NA&locale=en&matchType=ALL&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;

      x.multiFieldInputProperty.push(MultiFieldInputPropertyFactory.getTypeAheadProperties('Search airport', true, false,
        'DEPARTURE_FROM', 'Departure*', 'strict', x.fromOntology, this.MIN_SEARCH_QUERY_LENGTH, true, depDesUrl));

      x.multiFieldInputProperty.push(MultiFieldInputPropertyFactory.getTypeAheadProperties('Search airport', true, false,
        'DEPARTURE_TO', 'Arrival*', 'strict', x.toOntology, this.MIN_SEARCH_QUERY_LENGTH, true, depDesUrl));

      x.index = index;
      x.productCode = 'FLT';
      x.dateReset = false;
    });

    if (this.isDPSearch) {
      if (this.dpInitialDateStr) {
        this.setInitialDates(this.dpInitialDateStr);
      }
      if (this.flightCriteria && this.flightCriteria.legs && this.flightCriteria.legs.length > 0
        && this.flightCriteria.legs[0].groupNumber) {
        this.flightItemList[0].groupNumber = this.flightCriteria.legs[0].groupNumber;
      }
      if (!(this.isRefine || isPnr || this.isAmendmentFlow) && this.flightItemList && this.flightItemList[0]) {
        if (this.flightCriteria && this.flightCriteria.legs && this.flightCriteria.legs[0] &&
          this.flightCriteria.legs[0].airlineOntologies && this.flightCriteria.legs[0].airlineOntologies.length > 0) {
          this.flightItemList[0].airlineOntologies = this.flightCriteria.legs[0].airlineOntologies;
        }
        if (this.flightCriteria && this.flightCriteria.legs && this.flightCriteria.legs[0] &&
          this.flightCriteria.legs[0].viaPointOntologies && this.flightCriteria.legs[0].viaPointOntologies.length > 0) {
          this.flightItemList[0].viaPointOntologies = this.flightCriteria.legs[0].viaPointOntologies;
        }
      }
      this.adjustLocations();
      this.validateInputsForDP();
    }
  }

  getAirports() {
    this.subscriptions.set('airports', new Subscription());
    if (this.allowNgrxCaching && (this.cachingItemMap.get(StoreConstants.CACHE_AIRPORTS) !== null && this.cachingItemMap.get(StoreConstants.CACHE_AIRPORTS) > 0)) {
      this.getAirportsFromCache(this.cachingItemMap.get(StoreConstants.CACHE_AIRPORTS));
    } else {
      this.subscriptions.get('airports').add(
      this.dataServiceHandler.getAirports().subscribe(
        res => {
          this.mapAirportsList(res);
          this.subscriptions.get('airports').unsubscribe();
        },
        error => {
          console.log('Error in getting airports');
          this.subscriptions.get('airports').unsubscribe();
        }));
    }
  }

  private getAirportsFromCache(timeout: number) {
    this.subscriptions.get('airports').add(
    this.masterDataStoreService.getAirports(timeout).subscribe(
      res => {
        if (res) {
          this.mapAirportsList(res);
          this.subscriptions.get('airports').unsubscribe();
        }
      },
      error => {
        console.log('Error in getting airports');
        this.subscriptions.get('airports').unsubscribe();
      }));
  }

  private mapAirportsList(res: any) {
    this.airportCodesList = [];
    this.airportList = res;
    if (this.airportList && this.airportList.length > 0) {
      this.airportList.forEach(d => {
        if (d.code) {
          this.airportCodesList.push(d.code);
        }

      });
    }
  }

  setFlightErrors(flight) {
    if (!(flight.departureAirportCode && this.airportCodesList.includes(flight.departureAirportCode))) {
      flight.typeAheadDepartureHasError = true;
    } else {
      flight.typeAheadDepartureHasError = false;
    }
    if (!(flight.arrivalAirportCode && this.airportCodesList.includes(flight.arrivalAirportCode))) {
      flight.typeAheadArrivalHasError = true;
    } else {
      flight.typeAheadArrivalHasError = false;
    }
    if (flight.typeAheadDepartureHasError || flight.typeAheadArrivalHasError) {
      if (flight.typeAheadDepartureHasError && flight.typeAheadArrivalHasError) {
        flight.typeAheadHasError = true;
        flight.typeAheadErrorMsg = 'Please enter valid departure and arrival airports ';
      } else if (flight.typeAheadDepartureHasError) {
        flight.typeAheadHasError = true;
        flight.typeAheadErrorMsg = 'Please enter valid departure airport';
      } else if (flight.typeAheadArrivalHasError) {
        flight.typeAheadHasError = true;
        flight.typeAheadErrorMsg = 'Please enter valid arrival airport';
      }
    } else {
      flight.typeAheadHasError = false;
      flight.typeAheadErrorMsg = '';
    }

  }
  /**
   * convertStrToDateObj
   */
  getDateFromString(date) {
    try {
      const dates = date.split('-');
      return new Date(dates[0], +dates[1] - 1, dates[2]);
    } catch (e) {
      return new Date(date);
    }
  }
  addAnotherCriteriaItem(event, position) {
    if (event && event.newItemType === 'FLT') {
      let preItems = [];
      let postItems = [];
      this.cabinClassList.forEach(x=> {
        x.selected = false;
      });
      let existingItems = [...this.flightItemList];
      this.flightItemList.forEach((item, index) => {
        if (index <= event.preItemIndex) {
          preItems.push(item);
        }
        if (index >= event.postItemIndex) {
          postItems.push(item);
        }
      });
      /*1...pushing pre items first*/
      let finalList = [...preItems];
      let newFlightItem = this.createEmptyFlightItemObject();
      //consider only existingItems[0] if adding to first or if only one item is available
      if(event.preItemIndex < 0 || existingItems.length == 1) {
        newFlightItem.departureAirportCode = existingItems[0].arrivalAirportCode;
        newFlightItem.arrivalAirportCode = existingItems[0].departureAirportCode;
        newFlightItem.departureAirportCity = existingItems[0].arrivalAirportCity;
        newFlightItem.arrivalAirportCity = existingItems[0].departureAirportCity;
        newFlightItem.fromOntology.displayText = existingItems[0].toOntology.displayText;
        newFlightItem.toOntology.displayText = existingItems[0].fromOntology.displayText;
      }
      //consider above and below elements if new element in the middle
      else if(event.postItemIndex < existingItems.length && event.preItemIndex >= 0) {
        newFlightItem.departureAirportCode = existingItems[event.preItemIndex].arrivalAirportCode;
        newFlightItem.arrivalAirportCode = existingItems[event.postItemIndex].departureAirportCode;
        newFlightItem.departureAirportCity = existingItems[event.preItemIndex].arrivalAirportCity;
        newFlightItem.arrivalAirportCity = existingItems[event.postItemIndex].departureAirportCity;
        newFlightItem.fromOntology.displayText = existingItems[event.preItemIndex].toOntology.displayText;
        newFlightItem.toOntology.displayText = existingItems[event.postItemIndex].fromOntology.displayText;
      }
      //consider above element if new element at the bottom
      else if(event.postItemIndex >= existingItems.length) {
        newFlightItem.departureAirportCode = existingItems[event.preItemIndex].arrivalAirportCode;
        newFlightItem.arrivalAirportCode = existingItems[event.preItemIndex].departureAirportCode;
        newFlightItem.departureAirportCity = existingItems[event.preItemIndex].arrivalAirportCity;
        newFlightItem.arrivalAirportCity = existingItems[event.preItemIndex].departureAirportCity;
        newFlightItem.fromOntology.displayText = existingItems[event.preItemIndex].toOntology.displayText;
        newFlightItem.toOntology.displayText = existingItems[event.preItemIndex].fromOntology.displayText;
      }
      newFlightItem.airlines = [];
      newFlightItem.viaPoints = [];
      newFlightItem.index = -1;
      newFlightItem.productCode = event.newItemType;
      finalList.push(newFlightItem);    /*2...pushing the new item*/
      finalList = finalList.concat(postItems);                        /*3...append post items*/

      /*reorder indexes*/
      this.reorderIndexes(finalList);

      this.flightItemList = [];
      this.flightItemList = [...finalList];
    } else {
      //move to DP
      if (!this.isRefine) {                         /*TODO: Implemented for refine*/
        if (!this.isDPSearch) {                     /*Move to DP search panel if only; was in the component flow previously*/
          this.msg = 'DP_Switch~FLT~' + position;
          // this.commonService.changeMessage(msg);
        }
      }
    }
  }

  createEmptyFlightItemObject() :FlightSearchLeg {
    let fromOntTemp = new OntologyResponse();
    let toOntTemp = new OntologyResponse();
    let airlineOntTemp = new OntologyResponse();
    let viaPointOntTemp = new OntologyResponse();
    let multiFieldInpPropTemp = [];
    fromOntTemp.displayText = '';
    toOntTemp.displayText = '';
    airlineOntTemp.displayText = '';
    viaPointOntTemp.displayText = '';
    let cabinClassListTemp = [];

    let depDesUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=AIRPORT&userInput=[UI]&aggrLevel=NA&locale=en&matchType=ALL&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;

    multiFieldInpPropTemp.push(MultiFieldInputPropertyFactory.getTypeAheadProperties('Search airport', true, false,
      'DEPARTURE_FROM', 'Departure*', 'strict', fromOntTemp, this.MIN_SEARCH_QUERY_LENGTH, true, depDesUrl));

    multiFieldInpPropTemp.push(MultiFieldInputPropertyFactory.getTypeAheadProperties('Search airport', true, false,
      'DEPARTURE_TO', 'Arrival*', 'strict', toOntTemp, this.MIN_SEARCH_QUERY_LENGTH, true, depDesUrl));

    cabinClassListTemp.push({value: this.anyCabinClassString, selected: false});
    this.cabinClassList.forEach(item => {
      cabinClassListTemp.push(Object.assign({}, item));
    });
    let flightSearchLeg = new FlightSearchLeg();
    flightSearchLeg.showTypeAheadContent = false;
    flightSearchLeg.showAirlineTypeAheadContent = false;
    flightSearchLeg.showViaPointTypeAheadContent = false;
    flightSearchLeg.typeAheadHasError = false;
    flightSearchLeg.typeAheadDepartureHasError = false;
    flightSearchLeg.typeAheadArrivalHasError = false;
    flightSearchLeg.typeAheadErrorMsg = '';
    flightSearchLeg.fromOntology = fromOntTemp;
    flightSearchLeg.toOntology = toOntTemp;
    flightSearchLeg.airlineOntology = airlineOntTemp;
    flightSearchLeg.viaPointOntology = viaPointOntTemp;
    flightSearchLeg.multiFieldInputProperty = multiFieldInpPropTemp;
    flightSearchLeg.isSelected = false;
    flightSearchLeg.viaPoints = [];
    flightSearchLeg.airlines = [];
    flightSearchLeg.airlineOntologies = [];
    flightSearchLeg.viaPointOntologies = [];
    flightSearchLeg.dateReset = false;
    flightSearchLeg.isAirlineSearchInitiated = false;
    flightSearchLeg.isViaPointSearchInitiated = false;
    flightSearchLeg.cabinClassList = cabinClassListTemp;
    return flightSearchLeg;
  }

  reorderIndexes(itemList) {
    if (itemList != undefined && itemList.length > 0) {
      itemList.forEach((item, index) => {
        item.index = index;
      });
    }
  }

  loadConfigs() {
    this.STATIC_DATA_URL_BASE = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA]? this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA]: this.STATIC_DATA_URL_BASE;
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();

    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'DEFAULT_ADULT_AGE':
            this.DEFAULT_ADULT_AGE = +mc.name;
            break;
          case 'CALENDAR_RANGE':
            this.CALENDAR_RANGE = +mc.name;
            break;
          case 'MIN_SEARCH_QUERY_LENGTH':
            this.MIN_SEARCH_QUERY_LENGTH = +mc.name;
            break;
          case 'DISABLE_DP_SWITCH':
            if (this.isServiceAddComponent) {
              this.DISABLE_DP_SWITCH = true;
            } else {
              this.DISABLE_DP_SWITCH = ((mc.name as string) === 'true');
            }
            break;
          case 'MAX_CHILD_AGE':
            this.MAX_CHILD_AGE = +mc.name;
            break;
          case 'MAX_INFANT_AGE':
            this.MAX_INFANT_AGE = +mc.name;
            break;
          case 'FLIGHT_MIN_ADULT':
            this.FLIGHT_MIN_ADULT = +mc.name;
            break;
          case 'FLIGHT_MAX_ADULT':
            this.FLIGHT_MAX_ADULT = +mc.name;
            break;
          case 'FLIGHT_MAX_PAX_COUNT':
            this.FLIGHT_MAX_PAX_COUNT = +mc.name;
            break;
          case 'MAX_FLIGHT_LEGS':
            this.MAX_FLIGHT_LEGS = +mc.name;
            break;
          case 'FLIGHT_MAX_CABIN_PAX_COUNT':
            this.FLIGHT_MAX_CABIN_PAX_COUNT = +mc.name;
            break;
          case 'STATIC_DATA_FETCH_SIZE':
            this.STATIC_DATA_FETCH_SIZE = +mc.name;
            break;
          case 'GRAB_PNR_ENABLE':
            this.enableGrabPnr = ((mc.name as string) === 'true');
            break;
          case 'FLT_SEARCH_RESULT_COUNT':
            this.FLT_SEARCH_RESULT_COUNT = mc.name;
            break;
          case 'TEEN_PAX_ACTIVE':
            this.TEEN_PAX_ACTIVE = ((mc.name as string) === 'true');
            break;
        }
      });
    }
    const ENABLE_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('ENABLE_DP_PASSENGER_SPLIT', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_PAX_SPLIT;
    if (ENABLE_PAX_SPLIT_CONFIG) {
      ENABLE_PAX_SPLIT = ENABLE_PAX_SPLIT_CONFIG.toString().toUpperCase() === 'TRUE';
    }
    const ENABLE_FLT_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('DP_SPLIT_PAX_ENABLED_PRODUCTS', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_FLT_PAX_SPLIT;
    if (ENABLE_FLT_PAX_SPLIT_CONFIG) {
      ENABLE_FLT_PAX_SPLIT = ENABLE_FLT_PAX_SPLIT_CONFIG.toString().split(',').includes('FLT');
    }
    if (ENABLE_PAX_SPLIT && ENABLE_FLT_PAX_SPLIT) {
      this.ENABLE_PAX_SPLIT = true;
    }
    this.viaPointsUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=AIRPORT&userInput=[UI]&aggrLevel=NA&locale=en&matchType=CODE~NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;
  }

  setInitialDates(startDate) {
    if (this.flightItemList && this.flightItemList[0]) {
      this.flightItemList[0].departureDate = startDate;
      // if (this.flightItemList[0].departureDateObj) {
        this.flightItemList[0].departureDateObj = [];
        this.flightItemList[0].departureDateObj.push(new Date(startDate));
      // }
    }
    this.doCheckLastMinuteBookingDate(false);
  }

  createAirlineOntology(airline: FlightComponentCriteriaAirline, index: number): OntologyResponse {
    let res = new OntologyResponse();
    if(airline) {
      res.displayText = airline.airlineName;
      res.index = index;
    }
    return res;
  }

  createViaPointOntology(viapoint: FlightComponentCriteriaViaPoint, index: number): OntologyResponse {
    let res = new OntologyResponse();
    if(viapoint) {
      res.displayText = viapoint.viaPointCity + ' (' + viapoint.viaPointCode + ')';
      res.index = index;
    }
    return res;
  }

  adjustLocations() {
    //only adjust location when the pre item is a flight
    if(this.dpInitialDestStr && this.flightItemList && this.flightItemList[0]){
      let airportDepartureData = this.dpInitialDestStr.split('|')[0];
      let airportArrivalData = this.dpInitialDestStr.split('|')[1];
      const tempRefineCriteria = this.originalRefineCriteria as any;

      if (!(airportDepartureData == undefined || airportDepartureData.split('~')[0] == 'undefined' || airportDepartureData.split('~')[1] == 'undefined' ||
        airportDepartureData.split('~')[2] == 'undefined' || airportDepartureData.split('~')[3] == 'undefined')) {
        if (tempRefineCriteria.arrObj) {
          this.flightItemList[0].arrObj = tempRefineCriteria.arrObj;
        }
        this.flightItemList[0].toOntology.displayText =
          ((airportDepartureData.split('~')[2] !== '') && (airportDepartureData.split('~')[0] !== ''))
            ? (airportDepartureData.split('~')[2] + ' (' + airportDepartureData.split('~')[0] + ')') : '';
        this.flightItemList[0].arrivalAirportCode = airportDepartureData.split('~')[0];
        this.flightItemList[0].arrivalAirportCity = airportDepartureData.split('~')[1];
        this.flightItemList[0].arrivalAirportCityName = airportDepartureData.split('~')[2];
        this.flightItemList[0].arrivalAirportCountryName = airportDepartureData.split('~')[3];
      }


      if (!(airportArrivalData == undefined || airportArrivalData.split('~')[0] == 'undefined' || airportArrivalData.split('~')[1] == 'undefined' ||
        airportArrivalData.split('~')[2] == 'undefined' || airportArrivalData.split('~')[3] == 'undefined')) {
        if (tempRefineCriteria.depObj) {
          this.flightItemList[0].depObj = tempRefineCriteria.depObj;
        }
        this.flightItemList[0].fromOntology.displayText =
          ((airportArrivalData.split('~')[2] !== '') && (airportArrivalData.split('~')[0] !== ''))
            ? (airportArrivalData.split('~')[2] + ' (' + airportArrivalData.split('~')[0] + ')') : '';
        this.flightItemList[0].departureAirportCode = airportArrivalData.split('~')[0];
        this.flightItemList[0].departureAirportCity = airportArrivalData.split('~')[1];
        this.flightItemList[0].departureAirportCityName = airportArrivalData.split('~')[2];
        this.flightItemList[0].departureAirportCountryName = airportArrivalData.split('~')[3];
      }
    }
  }

  validateInputsForDP() {
    if (this.flightItemList && this.flightItemList[0] &&
      this.flightItemList[0].toOntology.displayText && this.flightItemList[0].fromOntology.displayText) {
      this.isValid.emit(true);
    } else {
      this.isValid.emit(false);
    }
  }

  addParentToAirport(airport, city) {
    const parent = [];
    if (city) {
      parent.push({code: city.code, name: city.name, type: city.type});  /*add city*/
    }
    if (city.parent && city.parent[0]) {
      parent.push({code: city.parent[0].code, name: city.parent[0].name, type: city.parent[0].type});  /*add country*/
    }
    airport.parent = parent;
    return airport;
  }

  handleDepartureSelection(selection, selectedFlightItem) {
    selectedFlightItem.showTypeAheadContent = false;
    setTimeout(() => {
      selectedFlightItem.isFocus = false;
    }, this.setTimeoutValue);
    if (selection) {
      // this.showTypeAheadContent = false;
      if (this.departureReturnedProperty.id === 'DEPARTURE_FROM') {

        selectedFlightItem.depObj = selection;
        selectedFlightItem.isPressEscFrom = selection.isPressEsc;
        this.calendarMinDate = new Date();
        this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'FLT');
        if(selection.type == 60){
          selectedFlightItem.fromOntology.displayText = selection.parent[0].name + ' (' + selection.code + ')';
          selectedFlightItem.departureAirportCode = selection.code;
          selectedFlightItem.departureAirportCity = selection.parent[0].name;
          selectedFlightItem.departureAirportCityName = selection.parent[0].name;
          for (let i = 0; i < selection.attributes.length ; i++) {
            if (selection.attributes[i].name === 'current_time') {
              this.calendarMinDate = new Date(selection.attributes[i].value);
              this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'FLT');
            }
          }
        }
        else if(selection.type == 40){
          selectedFlightItem.fromOntology.displayText = selection.name + ' (' + selection.code + ')';
          selectedFlightItem.departureAirportCode = selection.code;
          selectedFlightItem.departureAirportCity = selection.name;
          selectedFlightItem.departureAirportCityName = selection.name;

          let date: Date;
          let firstDate = true;
          for (let i = 0; i < selection.children.length; i++) {
            for (let j = 0; j < selection.children[i].attributes.length ; j++) {
              if (selection.children[i].attributes[j].name === 'current_time') {
                date = new Date(selection.children[i].attributes[j].value);
                if (firstDate) {
                  this.calendarMinDate = new Date(date);
                  this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'FLT');
                  firstDate = false;
                }
                if (date < this.calendarMinDate) {
                  this.calendarMinDate = new Date(date);
                  this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'FLT');
                }
              }
            }
          }
        }

        if(selectedFlightItem.index < this.flightItemList.length -1) {
          if(!this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCode) {

            this.flightItemList[selectedFlightItem.index + 1].arrObj = selection;
            if(selection.type == 60){
              this.flightItemList[selectedFlightItem.index + 1].toOntology.displayText = selection.parent[0].name + ' (' + selection.code + ')';
              this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCode = selection.code;
              this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCity = selection.parent[0].name;
              this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCityName = selection.parent[0].name;
              this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCountryName = selection.parent[(selection.parent.length - 1)].name;
            }
            else if(selection.type == 40){
              this.flightItemList[selectedFlightItem.index + 1].toOntology.displayText = selection.name + ' (' + selection.code + ')';
              this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCode = selection.code;
              this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCity = selection.name;
              this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCityName = selection.name;
              this.flightItemList[selectedFlightItem.index + 1].arrivalAirportCountryName = selection.parent[(selection.parent.length - 1)].name;
            }

          }
        }

      } else if (this.departureReturnedProperty.id === 'DEPARTURE_TO') {
        if (!selectedFlightItem.departureDate) {
          setTimeout(() => {
            selectedFlightItem.isFocus = true;
          }, this.setTimeoutValue);
        }
        selectedFlightItem.arrObj = selection;
        selectedFlightItem.isPressEscTo = selection.isPressEsc;
        if(selection.type == 60){
          selectedFlightItem.toOntology.displayText = selection.parent[0].name + ' (' + selection.code + ')';
          selectedFlightItem.arrivalAirportCode = selection.code;
          selectedFlightItem.arrivalAirportCity = selection.parent[0].name;
          selectedFlightItem.arrivalAirportCityName = selection.parent[0].name;
          selectedFlightItem.arrivalAirportCountryName = selection.parent[(selection.parent.length - 1)].name;
        }
        else if(selection.type == 40){
          selectedFlightItem.toOntology.displayText = selection.name + ' (' + selection.code + ')';
          selectedFlightItem.arrivalAirportCode = selection.code;
          selectedFlightItem.arrivalAirportCity = selection.name;
          selectedFlightItem.arrivalAirportCityName = selection.name;
          selectedFlightItem.arrivalAirportCountryName = selection.parent[(selection.parent.length - 1)].name;
        }

        if(selectedFlightItem.index < this.flightItemList.length -1) {
          if(!this.flightItemList[selectedFlightItem.index + 1].departureAirportCode) {

            this.flightItemList[selectedFlightItem.index + 1].depObj = selection;

            if(selection.type == 60){
              this.flightItemList[selectedFlightItem.index + 1].fromOntology.displayText = selection.parent[0].name + ' (' + selection.code + ')';
              this.flightItemList[selectedFlightItem.index + 1].departureAirportCode = selection.code;
              this.flightItemList[selectedFlightItem.index + 1].departureAirportCity = selection.parent[0].name;
              this.flightItemList[selectedFlightItem.index + 1].departureAirportCityName = selection.parent[0].name;
            }
            else if(selection.type == 40){
              this.flightItemList[selectedFlightItem.index + 1].fromOntology.displayText = selection.name + ' (' + selection.code + ')';
              this.flightItemList[selectedFlightItem.index + 1].departureAirportCode = selection.code;
              this.flightItemList[selectedFlightItem.index + 1].departureAirportCity = selection.name;
              this.flightItemList[selectedFlightItem.index + 1].departureAirportCityName = selection.name;
            }

          }
        }
      }
      this.flightCriteria.legs = [...this.flightItemList];
      if (this.flightCriteria.productCode == undefined) {
        this.flightCriteria.productCode = 'FLT';
      }
      this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
      if(this.isDPSearch){
        /*NEW IMPL - notify the change
        * change value : for DEST ->  DEPAP~DEPCITYCODE~DEPCITYNAME~DEPCOUNTRYNAME|ARRAP~ARRCITYCODE~ARRCITYNAME~ARRCOUNTRYNAME*/
        let depCity;
        let depCity2 = {
          code : null,
          name : null,
        };
        let depCountry;
        let arrCity;
        let arrCountry;

        if (this.flightItemList && this.flightItemList[0].depObj) {
          if (this.flightItemList[0].depObj.type == 40) {
            depCity = this.flightItemList[0].depObj;
          } else {
            depCity = this.surfCriteriaUtil.getCity(this.flightItemList[0].depObj);
          }
          depCountry = this.surfCriteriaUtil.getCountry(this.flightItemList[0].depObj);
        }
        if (this.flightItemList && !this.flightItemList[0].depObj) {
          depCity2.code = selectedFlightItem.departureAirportCity;
          depCity2.name = selectedFlightItem.departureAirportCityName;
          depCountry = selectedFlightItem.departureAirportCountryName;
        }
        if (this.flightItemList && this.flightItemList[0].arrObj) {
          if (this.flightItemList[0].arrObj.type == 40) {
            arrCity = this.flightItemList[0].arrObj;
            //bug FC-14097
            this.flightItemList[0].arrivalAirportCity = arrCity.code;
            this.flightItemList[0].arrivalAirportCityName = arrCity.name;
          } else {
            arrCity = this.surfCriteriaUtil.getCity(this.flightItemList[0].arrObj);
          }
          arrCountry = this.surfCriteriaUtil.getCountry(this.flightItemList[0].arrObj);
        }

        let value;
        if (this.flightItemList[0].depObj) {
          value = (this.flightItemList[0].departureAirportCode ? this.flightItemList[0].departureAirportCode : '') + '~'
            + (depCity ? (depCity.code + '~' + depCity.name + '~') : '~~') + (depCountry ? depCountry : '') + '~'
            + '|'
            + (this.flightItemList[0].arrivalAirportCode ? this.flightItemList[0].arrivalAirportCode : '') + '~'
            + ( arrCity ? (arrCity.code + '~' + arrCity.name + '~') : '~~') + (arrCountry ? arrCountry : '');
        } else {
          value = (this.flightItemList[0].departureAirportCode ? this.flightItemList[0].departureAirportCode : '') + '~'
            + (depCity2 ? (depCity2.code + '~' + depCity2.name + '~') : '~~') + (depCountry ? depCountry : '') + '~'
            + '|'
            + (this.flightItemList[0].arrivalAirportCode ? this.flightItemList[0].arrivalAirportCode : '') + '~'
            + ( arrCity ? (arrCity.code + '~' + arrCity.name + '~') : '~~') + (arrCountry ? arrCountry : '');
        }
        let amendTypeString = '';
        if (this.isAmendmentFlow) {
          if (this.departureReturnedProperty.id == 'DEPARTURE_FROM') {
            amendTypeString = 'PICKUP_LOCATION';
          } else {
            amendTypeString = 'DROP_OFF_LOCATION';
          }
        }
        let changeObj = {
          index : this.index,
          productCode : 'FLT',
          changeType : 'DEST',
          amendmentChangeType : amendTypeString,
          value : value
        };
        if ( (depCity || depCity2.code != null) && arrCity) { this.commonService.updateChangeItem(changeObj); }
        // this.commonService.updateChangeItem(changeObj);
        this.validateInputsForDP();
      }
      this.getCurrentFlightCriteria.emit(this.flightCriteria);
    }

    // this.filteredDepartureList = [];
    // this.departureSelectedDeparture.displayText = data.value;
  }

  handleCabinClassSelection(data, selectedFlightItem) {
    // console.log(data);
      // this.selectedDepartureCabinClass.displayText = data.value;
    if(data.value === this.anyCabinClassString) {
      selectedFlightItem.cabinClass = null;
    }
    else {
      selectedFlightItem.cabinClass = data.value;
    }
    this.flightCriteria.legs = [...this.flightItemList];
    if (this.flightCriteria.productCode == undefined) {
      this.flightCriteria.productCode = 'FLT';
    }
    this.commonService.updateComponentCriteria(this.flightCriteria, 'FLT');
    if(this.isDPSearch){
      /*NEW IMPL - notify the change
      * change value : for CABINCLASS ->  AIRLINESEARCHINITIATED~DISPLAYTEXT~AIRLINECODE*/
      let changeObj = {
        index : this.index,
        productCode : 'FLT',
        changeType : 'CABINCLASS',
        value : selectedFlightItem.cabinClass
      };
      this.commonService.updateChangeItem(changeObj);
    }
    this.getCurrentFlightCriteria.emit(this.flightCriteria);
  }

  // handleAirlineSelection(data) {
  //   console.log(data);
  //   this.airlineList.forEach(a => {
  //     if (a.name === data) {
  //       a.selected = !a.selected;
  //     }
  //   });
  //   if (!this.selectedAirlines.includes(data)) {
  //     this.selectedAirlines.push(data);
  //   } else {
  //     const index: number = this.selectedAirlines.indexOf(data);
  //     if (index !== -1) {
  //       this.selectedAirlines.splice(index, 1);
  //     }
  //   }
  //   console.log(this.selectedAirlines);
  // }


  closeCriteriaBox() {
    this.commonService.changeMessage('FLT_close');
    this.commonService.updateComponentCriteria(null, 'TRS');
    if (this.isRefine) {
      this.cancelRefineSearch();
    }
  }

  cancelRefineSearch() {
    this.cancelRefine.emit(this.isRefine);
  }

  getCountry(city): string {
    let parentName = null;
    if (city.parent && city.parent.length > 0) {
      city.parent.forEach(p => {
        if (p.type == 20) {
          parentName = p.name;
        }
      });
    }
    return parentName;
  }

  updateDPCriteria(event) {
    /*in the DP flow, update the relavent product criteria inside the product criteria list*/
    let idx = this.surfCriteriaUtil.getCriteriaItemArrayIndex(this.productCriteriaList, 'FLT', this.index);
    if (this.productCriteriaList && this.productCriteriaList[idx]) {
      this.mapToDPCriteriaItem(this.productCriteriaList[idx], this.flightCriteria, event);
    }
    else {
      let fltCriItem = this.surfCriteriaUtil.getNewCriItem('FLT', this.index);
      this.mapToDPCriteriaItem(fltCriItem, this.flightCriteria, event);
      this.productCriteriaList.push(fltCriItem);
    }
    this.validateInputsForDP();
    this.commonService.updateProductCriteriaList(this.productCriteriaList);
  }

  mapToDPCriteriaItem(dpCriItem, criItem, event) {
    if (criItem && criItem.legs && criItem.legs.length > 0 && dpCriItem.legs == undefined) {
      /*create legs array initially for dp if not exist*/
      dpCriItem.legs = [];
      criItem.legs.forEach(criLeg => {
        let leg = this.createEmptyFlightItemObject();
        leg.index = criLeg.index;
        dpCriItem.legs.push(leg);
      });
    }

    if (criItem && criItem.legs && criItem.legs.length > 0 && dpCriItem && dpCriItem.legs && dpCriItem.legs.length > 0) {
      // dpCriItem.legs = JSON.parse(JSON.stringify(criItem.legs));

      if (event == 'locations') {
        criItem.legs.forEach((criLeg, criLegIdx) => {
          dpCriItem.legs.forEach((dpCriLeg, dpCriLegIdx) => {
            if (criLegIdx == dpCriLegIdx) {
              dpCriLeg.fromOntology.displayText = JSON.parse(JSON.stringify(criLeg.fromOntology && criLeg.fromOntology.displayText != undefined ? criLeg.fromOntology.displayText : ''));
              dpCriLeg.toOntology.displayText = JSON.parse(JSON.stringify(criLeg.toOntology && criLeg.toOntology.displayText != undefined ? criLeg.toOntology.displayText : ''));
              dpCriLeg.depObj = JSON.parse(JSON.stringify(criLeg.depObj != undefined ? criLeg.depObj : null));
              dpCriLeg.arrObj = JSON.parse(JSON.stringify(criLeg.arrObj != undefined ? criLeg.arrObj : null));
              dpCriLeg.departureAirportCode = JSON.parse(JSON.stringify(criLeg.departureAirportCode != undefined ? criLeg.departureAirportCode : ''));
              dpCriLeg.departureAirportCity = JSON.parse(JSON.stringify(criLeg.departureAirportCity != undefined ? criLeg.departureAirportCity : ''));
              dpCriLeg.arrivalAirportCode = JSON.parse(JSON.stringify(criLeg.arrivalAirportCode != undefined ? criLeg.arrivalAirportCode : ''));
              dpCriLeg.arrivalAirportCity = JSON.parse(JSON.stringify(criLeg.arrivalAirportCity != undefined ? criLeg.arrivalAirportCity : ''));
            }
          });
        });
      }

      if (event == 'cabin-class') {
        criItem.legs.forEach((criLeg, criLegIdx) => {
          dpCriItem.legs.forEach((dpCriLeg, dpCriLegIdx) => {
            if (criLegIdx == dpCriLegIdx) {
              dpCriLeg.cabinClass = JSON.parse(JSON.stringify(criLeg.cabinClass != undefined ? criLeg.cabinClass : ''));
            }
          });
        });
      }

      if (event == 'dates') {
        criItem.legs.forEach((criLeg, criLegIdx) => {
          dpCriItem.legs.forEach((dpCriLeg, dpCriLegIdx) => {
            if (criLegIdx == dpCriLegIdx) {
              dpCriLeg.departureDate = JSON.parse(JSON.stringify(criLeg.departureDate != undefined ? criLeg.departureDate : ''));
              dpCriLeg.departureDateObj = JSON.parse(JSON.stringify(criLeg.departureDateObj != undefined ? criLeg.departureDateObj : ''));
            }
          });
        });
      }

      if (event == 'typeahead-data') {
        criItem.legs.forEach((criLeg, criLegIdx) => {
          dpCriItem.legs.forEach((dpCriLeg, dpCriLegIdx) => {
            if (criLegIdx == dpCriLegIdx) {
              dpCriLeg.typeAheadDepartureHasError = JSON.parse(JSON.stringify(criLeg.typeAheadDepartureHasError != undefined ? criLeg.typeAheadDepartureHasError : ''));
              dpCriLeg.departureAirportCode = JSON.parse(JSON.stringify(criLeg.departureAirportCode != undefined ? criLeg.departureAirportCode : ''));
              dpCriLeg.typeAheadArrivalHasError = JSON.parse(JSON.stringify(criLeg.typeAheadArrivalHasError != undefined ? criLeg.typeAheadArrivalHasError : ''));
              dpCriLeg.arrivalAirportCode = JSON.parse(JSON.stringify(criLeg.arrivalAirportCode != undefined ? criLeg.arrivalAirportCode : ''));
              dpCriLeg.arrObj = JSON.parse(JSON.stringify(criLeg.arrObj != undefined ? criLeg.arrObj : null));
              dpCriLeg.depObj = JSON.parse(JSON.stringify(criLeg.depObj != undefined ? criLeg.depObj : null));
              dpCriLeg.typeAheadHasError = JSON.parse(JSON.stringify(criLeg.typeAheadHasError != undefined ? criLeg.typeAheadHasError : ''));
              dpCriLeg.showTypeAheadContent = JSON.parse(JSON.stringify(criLeg.showTypeAheadContent != undefined ? criLeg.showTypeAheadContent : ''));
              dpCriLeg.typeAheadErrorMsg = JSON.parse(JSON.stringify(criLeg.typeAheadErrorMsg != undefined ? criLeg.typeAheadErrorMsg : ''));
            }
          });
        });
      }

      if (event == 'airline') {
        criItem.legs.forEach((criLeg, criLegIdx) => {
          dpCriItem.legs.forEach((dpCriLeg, dpCriLegIdx) => {
            if (criLegIdx == dpCriLegIdx) {
              dpCriLeg.isAirlineSearchInitiated = JSON.parse(JSON.stringify(criLeg.isAirlineSearchInitiated != undefined ? criLeg.isAirlineSearchInitiated : ''));
              dpCriLeg.airlineOntology.displayText = JSON.parse(JSON.stringify(criLeg.airlineOntology && criLeg.airlineOntology.displayText != undefined ? criLeg.airlineOntology.displayText : ''));
              dpCriLeg.airlineCode = JSON.parse(JSON.stringify(criLeg.airlineCode != undefined ? criLeg.airlineCode : ''));
            }
          });
        });
      }

    }
  }

  /**
   * set first input focus in input list
   * params index
   */
  returnFirstItem(index): boolean {
    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * check focusing input is the last input of multi field input
   * param isLastItem
   */
  ShowLastElement(isLastItem) {
    this.isLastItemInMultiInput = isLastItem;
  }

  onPaxChange($event) {
    console.log($event);
    let adult = 0;
    let child = [];
    let teen = [];
    let infant = [];
    $event.forEach(item => {
      if(item.type === 'Adult'){
        adult += 1;
      }
      else if (item.type === 'Teen' ) {
        teen.push(6);
      }
      else if (item.type === 'Child' ) {
        child.push(6);
      }
      else {
        infant.push(1);
      }
    });
    const paxAllocationItem = new PaxAllocationItem(adult, child, infant , teen);
    this.selectedPax = [];
    this.selectedPax.push(paxAllocationItem);
    console.log(this.selectedPax);
    // this.setServicePax();
  }

  generateDisplayString(adult, child, infant, teen) {

    // should handle
    let str = '';

    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    console.log(str);
    return str;
  }

  /**
   * unsubscribe the subscription which is mapped to the given key
   * @see subscriptions
   * @param subsKey - subscriptionMap key
   */
  removeSubscription(subsKey: string): void {
    const subs = this.subscriptions.has(subsKey) ? this.subscriptions.get(subsKey) : undefined;
    if (subs && !subs.closed) {
      subs.unsubscribe();
      this.subscriptions.delete(subsKey);
    }
  }

  /**
   * handle freeze-unfreeze checkbox
   */
  checkValue(event) {
    this.unfreeze = event.target.checked;
    this.freeze = !this.unfreeze;
    this.isUnfreeze.emit(event.target.checked);
  }

  doCheckLastMinuteBookingDate(isAmendmentOldItemDateSelection: boolean) {

    if (this.flightCriteria && this.flightCriteria.legs && this.flightCriteria.legs.length > 0 &&
      this.flightCriteria.legs[0].departureDate) {
      let checkingDate = new Date(this.flightCriteria.legs[0].departureDate);
      if (this.surfCalendarStartDateService.validateWithCheckingIsEarlierDate(new Date(), checkingDate, 'FLT')) {
        this.hideErrorMsg = true;
        this.validateDisabledDateSelection = false;
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = true;
        }
      } else {
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = false;
        }
        this.hideErrorMsg = false;
        this.validateDisabledDateSelection = true;
      }
    }
  }
}

export class FlightSearchLeg extends FlightComponentCriteria {
  showTypeAheadContent: boolean;
  showAirlineTypeAheadContent: boolean;
  showViaPointTypeAheadContent: boolean;
  typeAheadHasError: boolean;
  typeAheadDepartureHasError: boolean;
  typeAheadArrivalHasError: boolean;
  typeAheadErrorMsg : string;
  fromOntology: OntologyResponse;
  toOntology: OntologyResponse;
  airlineOntology: OntologyResponse;
  viaPointOntology: OntologyResponse;
  multiFieldInputProperty: MultiFieldInputProperty[];
  isSelected: boolean;
  airlineOntologies: OntologyResponse[];
  viaPointOntologies: OntologyResponse[];
  dateReset : boolean;
  isAirlineSearchInitiated: boolean;
  isViaPointSearchInitiated: boolean;
  cabinClassList: any[];
  index: number;
  productCode: string;
  departureDateObj: any[];
  arrivalAirportCityName: string;
  departureAirportCityName: string;
  arrivalAirportCountryName: string;
  departureAirportCountryName: string;
  isFocus: boolean = false; // add to focus to specific date picker
}
