import { NgModule } from '@angular/core';
import { SurfClientSearchModalComponent } from './surf-client-search-modal/surf-client-search-modal.component';
import {CommonModule} from "@angular/common";
import {SurfPaginationModule} from "@surf/surf-pagination";
import {SurfInputModule} from "@surf/surf-input";
import {SurfTextCasePipeModule} from "@surf/surf-components-core";

@NgModule({
  declarations: [SurfClientSearchModalComponent],
  imports: [
    CommonModule,
    SurfPaginationModule,
    SurfInputModule,
    SurfTextCasePipeModule
  ],
  exports: [SurfClientSearchModalComponent]
})
export class SurfClientSearchModalModule { }
